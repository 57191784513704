import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { AnimeButton } from "../../../../common/AnimeButton";
import { HR } from "../../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  /* padding: 15%; */
  margin-top:60px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    /* padding: 10%; */
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  margin-right:80px;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
  `;

const Content = styled(Link)`
  margin: 0 !important
  font-weight: 400 !important
  font-size: 18px !important
  font-family: Drlcircular !important
  margin-bottom: 20px !important
  text-decoration: none !important
  color: #000 !important
  cursor: pointer !important;
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px;
  flex-direction: row;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;

const ReportItemDiv = styled.div`
  width: 50%;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ReportItem = styled(Link)`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;

export const MoaAndAoa = (props) => {

  const [moaandaoareports, setmoaandaoareports] = useState([]);

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = [];
    var tempArr = [];
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/moa_and_aoa?include=field_file_moa",
    })
      .then((res) => {
        for (const item of res.data.included) {
          tempIncludedObj[item.id] =
            config.serverUrl.imageBase + item.attributes.uri.url;
        }
        for (const report of res.data.data) {
          tempReports.push({
            title: report.attributes.title,
            fileUrl: tempIncludedObj[report.relationships.field_file_moa.data.id],
          })
        }
        console.log(tempReports);
        setmoaandaoareports(tempReports);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  return <MainContainer>
    {moaandaoareports.map((item, index) => {
      return <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginBottom:'20px' }} >
        <TextContent
          to={{ pathname: item.fileUrl }}
          target="_blank"
          style={{ font: "18px" }}
          key={index}
          // onClick={() => {
          //   downloadFile(item.fileUrl);
          // }}
        >
          {item.title}
        </TextContent>
        <AnimeButton href={item.fileUrl} target="_blank" 
        // onClick={() => {
        //     downloadFile(item.fileUrl);
        //   }}
          />
      </span>
    })
    }
  </MainContainer>
}