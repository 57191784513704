import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "images/Rectangle_Copy_1111.png",
        "heading": "",
        "desc": "Tele-consultation facilities are set up with doctors, nutritionists and counsellors to assist employees."
    },
    {
        "id": "slider_2",
        "img": "images/Rectangle_Copy_1112.png",
        "heading": "",
        "desc": "In-house testing and COVID-19 insurance for employees and their dependents are provided."
    },
    {
        "id": "slider_3",
        "img": "images/Rectangle_Copy_1113.png",
        "heading": "",
        "desc": "A Home Isolation Programme is in place and the care kit is free for employees via insurance."
    },
    {
        "id": "slider_4",
        "img": "images/Rectangle_Copy_1116.png",
        "heading": "",
        "desc": "In case employee contracts the virus, they can avail COVID–19 leaves & a salary advance​."
    },
]
    ;

const CovidSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 100);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                {/* <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px' }} >{item.heading}</div> */}
                {/* {
                    readMore.indexOf(item.id) !== -1
                        ? */}
                        <div style={{ fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        {/* : */}
                        {/* <div style={{ fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div> */}
                {/* } */}
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default CovidSlider;