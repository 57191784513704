import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import App1 from './App1';
import { ApolloClient, InMemoryCache } from '@apollo/client';
// import {ApolloProvider} from '@apollo/client/react'
import reportWebVitals from './reportWebVitals';

import { CookiesProvider } from 'react-cookie';
import ReactGA from "react-ga4";

import Config from './config';

// const client = new ApolloClient({
//   uri: 'http://localhost:8888/drupalTest/custom_schema',
//   // uri: 'http://localhost:8888/drupalTest/graphql',
//   cache: new InMemoryCache(),
//   headers: {
//     // authorization: localStorage.getItem('token'),
//   }
// });

ReactGA.initialize(Config.serverUrl.googleAnalyticsKey);

ReactDOM.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client} > */}
    <CookiesProvider>
      <App />
    </CookiesProvider>
    {/* <App1/> */}
    {/* Adding following for exported Webflow code */}
    {/* <IndexView/> */}
    {/* </ApolloProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// https://drupal-graphql.gitbook.io/graphql/
// https://github.com/DAB0mB/Appfairy
// https://colekillian.com/posts/converting-webflow-site-to-react-with-appfairy/
// https://medium.com/the-guild/how-to-create-a-react-app-out-of-a-webflow-project-309b696a0533
// https://blog.chrismitchellonline.com/posts/custom_graphql_data/
// https://www.specbee.com/blogs/graphQL-with-drupal-8-what-is-graphql-Advantages-need-to-know-Guide
// https://www.drupov.com/blog/apollo-client-react-hooks-drupal-graphql-create-schema-and-resolvers-server-part-1



//Design
// https://dr-reddy.webflow.io/

// http://localhost:8888/drupalTest/

// add it to package.json
// "proxy": "http://localhost:8888",