import React from "react";
import styled from "styled-components";

import { HR } from "../../common/horizontalLine";

const MainContainer = styled.div`
  margin-bottom: 0px;
`;

const SubHeading = styled.h5`
  color: #000000 !important ;
  font-weight: bold !important ;
  font-family: Drlcircular;
  font-size: 18px !important ;
  margin-top: 45px !important;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    margin-top: 24px !important;
  }
`;

const Content = styled.p`
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  margin-bottom: 30px !important;
  font-size: 18px !important;
  line-height: 1.4 !important;
  @media (max-width: 820px) {
    margin-bottom: 15px !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
`;

const LoginButton = styled.a`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 700 !important;
  font-family: Drlcircular !important;
  cursor: pointer !important;
  text-decoration:none !important;
  &:hover{
    text-decoration:underline !important;
  }
  @media (max-width: 820px) {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-decoration:underline !important;
  }
`;

export function LoginForCurrentPartnerView(props) {
  return (
    <MainContainer>
      <SubHeading>Business Partners </SubHeading>
      <Content>
        This portal provides instant access to RFx, Purchase Orders, ASN’s,
        GRN’s, Invoice statuses, and more.
      </Content>
      <LoginButton target="_blank" href="https://vikretaconnect.mydrreddys.com/irj/portal" >Login here</LoginButton>
    </MainContainer>
  );
}
