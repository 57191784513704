/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false },
]

let Controller

class ScienceResearchView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ScienceResearchController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ScienceResearchView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60bcaccde01779dc0abe0478'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = ScienceResearchView.Controller !== ScienceResearchView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a3ce977b-2c04-01a6-1b59-87844d16abc9\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1f160383-fb0c-3365-47bc-ae046037c59c\"] {display:none;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a3ce977b-2c04-01a6-1b59-87844d16abc9\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1f160383-fb0c-3365-47bc-ae046037c59c\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a3ce977b-2c04-01a6-1b59-87844d16abc9\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1f160383-fb0c-3365-47bc-ae046037c59c\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a3ce977b-2c04-01a6-1b59-87844d16abc9\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1f160383-fb0c-3365-47bc-ae046037c59c\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        <span className="af-view">
          <div>
            <nav id="Navbar" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d12" className="af-class-navbar">
              <div className="af-class-navbar-container">
                <a href="index.html" className="af-class-nav-logo w-inline-block"><img src="images/DrR_Logo_Primary_RGB.svg" loading="lazy" alt /></a>
                <div className="af-class-menu-options">
                  <div data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d17" className="af-class-menu-button">
                    <div className="af-class-menu-bar af-class-_1" />
                    <div className="af-class-menu-bar af-class-_2" />
                  </div>
                </div>
                <div className="af-class-nav-menu-wrapper">
                  <div className="w-layout-grid af-class-menu-grid">
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d1c-23f25d12" className="af-class-main-menu-links">
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d1d" href="#" className="af-class-menu-link w-inline-block">
                        <div>People &amp;&nbsp;Stories</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d21" href="#" className="af-class-menu-link w-inline-block">
                        <div>Science &amp; Research</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d25" href="#" className="af-class-menu-link w-inline-block">
                        <div>Impact &amp; Sustainability</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d29" href="#" className="af-class-menu-link w-inline-block">
                        <div>About Us</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d2d" href="#" className="af-class-menu-link w-inline-block">
                        <div>Businesses &amp; Products</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d31" href="#" className="af-class-menu-link af-class-no-bottom-margin w-inline-block">
                        <div>Life at DRL</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                    </div>
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d35-23f25d12" className="af-class-middle-menu-container">
                      <div className="af-class-mid-menu-block af-class-_1"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="people-stories.html" className="af-class-sub-menu-heading">People &amp;&nbsp;Stories</a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Patients</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Healthcare Professionals</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Partners</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Benefits of Partnering With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_2"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="science-research.html" aria-current="page" className="af-class-sub-menu-heading w-inline-block w--current">
                          <div>Science &amp; Research</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Development Streams</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Research Philosophy</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Capabilities</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_3"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="impact-and-sustainability.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>Impact &amp; Sustainability</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Environmental Initiatives</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>DRL Foundation &amp; Affiliates</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>HCP Programmes</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_4"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="about-us.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>ABOUT us</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Who We Are</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Values</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Heritage</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Journey And Vision</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Founder And Leaders</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_6"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="life-at-drl.html" className="af-class-sub-menu-heading">LIFE AT DR. REDDY’S</a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>ASPIRE</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our People</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Benefits Of Working With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Work With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_5"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="businesses-products.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>BUSINESS &amp; PRODUCTS</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Business Highlight &amp; Stories</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Business Portfolio</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Focus Areas</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Quality &amp; Compliance</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div className="af-class-text-block">Global Manufacturing &amp; Supply Chains</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>API Product Portal</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                    </div>
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dac-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dac" className="af-class-side-menu">
                      <div data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dad" className="af-class-main-menu-links">
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Business Partners</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Investors</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Job Seekers</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Contact Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small af-class-no-bottom-margin w-inline-block">
                          <div>Microsites</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-countries-wrapper">
                        <a id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dc3-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dc3" href="#" className="af-class-country-link af-class-india w-inline-block"><img src="images/Icon-feather-globe.svg" loading="lazy" alt className="af-class-globe-icon" />
                          <div>India</div>
                        </a>
                        <div className="af-class-country-links-block">
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>Australia</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>brazil</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>Canada</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>chile</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>china</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>france</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>germany</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>netherlands</div>
                          </a>
                        </div>
                        <div className="af-class-country-links-block">
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>new zealand</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>romania</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>russia</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>south korea</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>spain</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>ukraine</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>united kingdom</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>united states</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <section id="Science-Research-Hero" className="af-class-section af-class-science-research-hero">
              <div className="af-class-container w-container">
                <div className="af-class-leadership-image-block af-class-placeholder"><img src="images/water-neutrality.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="images/water-neutrality-p-1080.jpeg 1080w, images/water-neutrality.jpg 1157w" alt className="af-class-image" /></div>
                <div className="af-class-leadership-image-block af-class-horizontal af-class-_1"><img src="images/Science-research-hero2x.jpg" loading="lazy" style={{opacity: 0}} data-w-id="c53087d2-2d45-e2c3-1759-f61352eeb5a7" srcSet="images/Science-research-hero2x-p-1080.jpeg 1080w, images/Science-research-hero2x-p-1600.jpeg 1600w, images/Science-research-hero2x-p-2000.jpeg 2000w, images/Science-research-hero2x.jpg 2314w" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" alt className="af-class-image af-class-full-height" /></div>
                <div data-w-id="c53087d2-2d45-e2c3-1759-f61352eeb5b1" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="af-class-white-text-overlay af-class-_1">
                  <h2 className="af-class-h2-section-heading af-class-_450">Making cancer treatment accessible for everyone</h2>
                  <p style={{WebkitTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-paragraph">An innovative approach helped us develop and launch an affordable generic version of Azacitidine in the US</p>
                  <a href="https://dr-reddy.webflow.io/articles/making-cancer-treatment-accessible-for-everyone" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                    <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                  </a>
                </div>
              </div>
            </section>
            <div data-w-id="c597c166-30dc-cd3e-607a-edf5c0d62af0" className="af-class-section af-class-padding-120-mobile">
              <div className="af-class-container w-container">
                <div className="af-class-delivering-health-wrapper">
                  <div className="af-class-scroll-image-stack-block af-class-hide-mobile">
                    <div className="af-class-stacked-images-wrapper"><img src="images/raising-capacity-thumbnail2x.jpg" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 40vw, 600px" srcSet="images/raising-capacity-thumbnail2x-p-500.jpeg 500w, images/raising-capacity-thumbnail2x-p-800.jpeg 800w, images/raising-capacity-thumbnail2x-p-1080.jpeg 1080w, images/raising-capacity-thumbnail2x.jpg 1144w" alt className="af-class-stack_image af-class-_3 af-class-relative" /><img src="images/future-ready-with-vr-thumbnail2x.jpg" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 40vw, 600px" srcSet="images/future-ready-with-vr-thumbnail2x-p-500.jpeg 500w, images/future-ready-with-vr-thumbnail2x-p-800.jpeg 800w, images/future-ready-with-vr-thumbnail2x-p-1080.jpeg 1080w, images/future-ready-with-vr-thumbnail2x.jpg 1144w" alt className="af-class-stack_image af-class-_2" /><img src="images/we-shape-scientific-thumbnail2x.jpg" loading="lazy" style={{opacity: 0}} data-w-id="c597c166-30dc-cd3e-607a-edf5c0d62af8" srcSet="images/we-shape-scientific-thumbnail2x-p-500.jpeg 500w, images/we-shape-scientific-thumbnail2x-p-800.jpeg 800w, images/we-shape-scientific-thumbnail2x-p-1080.jpeg 1080w, images/we-shape-scientific-thumbnail2x.jpg 1144w" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 40vw, 600px" alt className="af-class-stack_image af-class-_1" /></div>
                  </div>
                  <div className="af-class-scroll-text-wrapper">
                    <div className="w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        <div role="listitem" className="w-dyn-item">
                          <div className="af-class-scroll-text-block">
                            <div className="af-class-scroll-text-div">
                              <div className="af-class-scroll-animation-box">
                                <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" />
                                <p className="af-class-paragraph" />
                                <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                                  <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-dyn-empty">
                        <div>No items found.</div>
                      </div>
                    </div>
                    <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                          <div data-w-id="1f160383-fb0c-3365-47bc-ae046037c563" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div"><img src="images/we-shape-scientific-thumbnail2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 42vw, 100vw" srcSet="images/we-shape-scientific-thumbnail2x-p-500.jpeg 500w, images/we-shape-scientific-thumbnail2x-p-800.jpeg 800w, images/we-shape-scientific-thumbnail2x-p-1080.jpeg 1080w, images/we-shape-scientific-thumbnail2x.jpg 1144w" alt className="af-class-tall-slide-thumbnail" />
                              <div className="af-class-delivering-health-slider-heading">We reshape scientific boundaries to meet patient needs<br /></div>
                              <a href="https://dr-reddy.webflow.io/articles/we-reshape-scientific-boundaries-to-meet-patient-needs" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="1f160383-fb0c-3365-47bc-ae046037c570" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div"><img src="images/future-ready-with-vr-thumbnail2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 42vw, 100vw" srcSet="images/future-ready-with-vr-thumbnail2x-p-500.jpeg 500w, images/future-ready-with-vr-thumbnail2x-p-800.jpeg 800w, images/future-ready-with-vr-thumbnail2x-p-1080.jpeg 1080w, images/future-ready-with-vr-thumbnail2x.jpg 1144w" alt className="af-class-tall-slide-thumbnail" />
                              <div className="af-class-delivering-health-slider-heading">Future ready with virtual reality<br /></div>
                              <a href="https://dr-reddy.webflow.io/articles/future-ready-with-virtual-reality" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="1f160383-fb0c-3365-47bc-ae046037c57c" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div"><img src="images/raising-capacity-thumbnail2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 42vw, 100vw" srcSet="images/raising-capacity-thumbnail2x-p-500.jpeg 500w, images/raising-capacity-thumbnail2x-p-800.jpeg 800w, images/raising-capacity-thumbnail2x-p-1080.jpeg 1080w, images/raising-capacity-thumbnail2x.jpg 1144w" alt className="af-class-tall-slide-thumbnail" />
                              <div className="af-class-delivering-health-slider-heading">Raising capacity for the future<br /></div>
                              <a href="https://dr-reddy.webflow.io/articles/raising-capacity-for-the-future" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div data-w-id="1f160383-fb0c-3365-47bc-ae046037c596" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="1f160383-fb0c-3365-47bc-ae046037c59a" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="1f160383-fb0c-3365-47bc-ae046037c59c" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                    <div className="af-class-drag-progress-fill af-class-fifty" />
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="18774bd3-f705-a515-3f32-d98801f54422" className="af-class-section af-class-always-innovating">
              <div className="af-class-always-innovating-heading-block">
                <div className="af-class-container">
                  <h1 data-w-id="18774bd3-f705-a515-3f32-d98801f54425" style={{opacity: 0}} className="af-class-h2-section-heading af-class-title af-class-embracing-quality">Always innovating to deliver good health</h1>
                </div>
              </div>
              <div className="af-class-science-research-fullbg-wrapper">
                <div className="af-class-full-bg-block">
                  <div className="af-class-section af-class-form-formulations">
                    <div className="af-class-container w-container">
                      <div className="w-layout-grid af-class-_2-col-grid af-class-wide-heading">
                        <h2 id="w-node-_2c329a73-f299-3311-1978-19d5e57ce8fc-0abe0478" data-w-id="2c329a73-f299-3311-1978-19d5e57ce8fc" style={{opacity: 0}} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">From formulations to finished-dosage forms</h2>
                        <div id="w-node-_2c329a73-f299-3311-1978-19d5e57ce8fe-0abe0478">
                          <p data-w-id="2c329a73-f299-3311-1978-19d5e57ce8ff" style={{opacity: 0}} className="af-class-paragraph">Our state-of-the-art R&amp;D centres across the globe offer a wide-ranging suite of services including synthetic organic chemistry, formulations development, IP management and biopharmaceutics. For our industrial customers, we offer solutions with regard to starting material, intermediates, active ingredients and finished-dosage forms.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-section af-class-focused-market">
                    <div className="af-class-container w-container">
                      <div className="w-layout-grid af-class-_2-col-grid af-class-wide-heading">
                        <h2 id="w-node-_268e04b7-f1bc-400e-cc51-fd2bba82f993-0abe0478" data-w-id="268e04b7-f1bc-400e-cc51-fd2bba82f993" style={{opacity: 0}} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">Focused on being the first to market</h2>
                        <div id="w-node-_268e04b7-f1bc-400e-cc51-fd2bba82f997-0abe0478">
                          <p data-w-id="268e04b7-f1bc-400e-cc51-fd2bba82f998" style={{opacity: 0}} className="af-class-paragraph">Our R&amp;D team is devoted to improving patients’ lives and contributing to the ever-evolving world of holistic healthcare. We work hard to develop complex products and aim at being first to market.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-id="29b7b325-ede2-b9f4-82fd-8fd51047e27e" className="af-class-section af-class-numbers-lottie">
              <div className="af-class-container w-container">
                <div className="af-class-lottie-animation-wrapper">
                  <div>IPDO spread over</div>
                  <div className="af-class-lottie-digit-wrapper">
                    <div data-w-id="7cf01e17-8eaf-6b1c-bc5d-f63fab326e95" data-animation-type="lottie" data-src="documents/300.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-less-right-margin" />
                    <div>,000</div>
                  </div>
                  <div>sq.ft. with</div>
                  <div data-w-id="20109063-7f76-2b71-0822-d8bb574cb6ac" data-animation-type="lottie" data-src="documents/70.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>laboratories and</div>
                  <div data-w-id="a1d57186-025f-f743-7fc5-7afbd72f81af" data-animation-type="lottie" data-src="documents/800.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>research scientists.</div>
                  <div data-w-id="0dac0bcd-a8e2-9368-291d-77109d2e8a91" data-animation-type="lottie" data-src="documents/9.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>R&amp;D facilities across the world. &nbsp;&nbsp;&nbsp;Over</div>
                  <div data-w-id="4a40a117-4914-d06e-90ae-100cb129f5b9" data-animation-type="lottie" data-src="documents/86.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>ANDAs</div>
                  <div className="af-class-margin-left-20">+ NDAs in the last</div>
                  <div data-w-id="90b3caf2-8e52-3b06-a935-68318d6d50bf" data-animation-type="lottie" data-src="documents/5.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div className="af-class-text-block-8">years.</div>
                  <div data-w-id="631307b7-7f4b-7fb2-a7b9-9db210c195b5" data-animation-type="lottie" data-src="documents/1100.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>active DMFs.</div>
                </div>
                <div className="af-class-lottie-animation-wrapper af-class-no-upper-margin">
                  <div data-w-id="80a41759-fc56-bff0-6254-0e28d8af263e" data-animation-type="lottie" data-src="documents/500.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>patents filed in the last</div>
                  <div data-w-id="80a41759-fc56-bff0-6254-0e28d8af2641" data-animation-type="lottie" data-src="documents/5.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={1} className="af-class-big-number-lottie" />
                  <div>years.</div>
                </div>
              </div>
            </div>
            <div data-w-id="5e1529f0-0f60-e70c-45f7-b14d9da3a655" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center">Nurturing original thinking to solve patient needs</h1>
                </div>
              </div>
            </div>
            <div data-w-id="a3ce977b-2c04-01a6-1b59-87844d16ab50" className="af-class-section af-class-science-reasearch-horizontal">
              <div className="af-class-horizontal-scroll-wrapper">
                <div className="af-class-container w-container">
                  <div className="af-class-horizontal-scroll-content af-class-_5-articles">
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid af-class-left-margin-0">
                      <div className="af-class-horintal-scroll-grid-block"><img src="images/image_12x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="images/image_12x-p-500.jpeg 500w, images/image_12x-p-800.jpeg 800w, images/image_12x-p-1080.jpeg 1080w, images/image_12x-p-1600.jpeg 1600w, images/image_12x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="a3ce977b-2c04-01a6-1b59-87844d16ab59" className="af-class-horizontal-scroll-text-block af-class-auto-height">
                        <div data-w-id="a3ce977b-2c04-01a6-1b59-87844d16ab5a" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">R&amp;D beyond borders</h3>
                          <p className="af-class-paragraph af-class-_450">We have state-of-the art research and development centres in India, Europe and the US. They give us the means to handle advanced physico-chemical and biological characterization with ease. We also have some of the brightest scientists and researchers in the world working in tandem to create complex pharmaceutical ingredients, advanced particle engineering solutions, and complex products or dosage forms.<br /><br /><strong>Centres in:</strong> Hyderabad, Bangalore, USA, UK, Netherlands</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="images/image_22x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="images/image_22x-p-800.jpeg 800w, images/image_22x-p-1080.jpeg 1080w, images/image_22x-p-1600.jpeg 1600w, images/image_22x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="0cda83a7-b541-e3f0-e694-b31075f9776e" className="af-class-horizontal-scroll-text-block af-class-auto-height">
                        <div data-w-id="0cda83a7-b541-e3f0-e694-b31075f9776f" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Generics R&amp;D</h3>
                          <p className="af-class-paragraph af-class-_450">Generic versions of innovator drugs raise the possibility of every patient having access to quality healthcare because of their affordability. Our team ambitiously strives to develop high-quality generics, bringing expensive medicines within the reach of patients globally.<br />‍<br /><strong>Product portfolio:</strong> Novel crystalline and amorphous forms, Semi-synthetic APIs, Chirals, Prostaglandins, peptides and carbohydrates, Nano particle-based products</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="images/image_32x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="images/image_32x-p-500.jpeg 500w, images/image_32x-p-800.jpeg 800w, images/image_32x-p-1080.jpeg 1080w, images/image_32x-p-1600.jpeg 1600w, images/image_32x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="c6388289-4d6f-ce84-9ef4-872d8f763be1" className="af-class-horizontal-scroll-text-block af-class-auto-height">
                        <div data-w-id="c6388289-4d6f-ce84-9ef4-872d8f763be2" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Biologics R&amp;D</h3>
                          <p className="af-class-paragraph af-class-_450">We have end-to-end research capabilities in developing vaccines, blood components, cells, allergens, genes, tissues and recombinant proteins. Our Centres of Excellence in Hyderabad, Basel and Princeton have state-of-the-art technology in cell line and process development, and robust capabilities in bio-analytics. We have extensive experience with complex healthy volunteer and patient studies. Our teams are well-versed in liaising with key regulatory agencies around the world.</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="images/image_42x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="images/image_42x-p-500.jpeg 500w, images/image_42x-p-800.jpeg 800w, images/image_42x-p-1080.jpeg 1080w, images/image_42x-p-1600.jpeg 1600w, images/image_42x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="3f55959d-0db8-bc5f-cf5c-2a3af79d828a" className="af-class-horizontal-scroll-text-block af-class-auto-height">
                        <div data-w-id="3f55959d-0db8-bc5f-cf5c-2a3af79d828b" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Proprietary Products R&amp;D</h3>
                          <p className="af-class-paragraph af-class-_450">We focus on developing novel differentiated formulations to be used primarily in the dermatology and neurology markets. Our aim is to develop affordable products and design different delivery technologies to help patients manage their diseases better.</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="images/image_52x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="images/image_52x-p-800.jpeg 800w, images/image_52x-p-1080.jpeg 1080w, images/image_52x-p-1600.jpeg 1600w, images/image_52x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="1f1bb054-0a68-5ac0-fa48-3578a00f87e7" className="af-class-horizontal-scroll-text-block af-class-auto-height">
                        <div data-w-id="1f1bb054-0a68-5ac0-fa48-3578a00f87e8" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Analytical capabilities</h3>
                          <p className="af-class-paragraph af-class-_450">Over the years, our team has developed the ability to handle advanced physico-chemical and biological characterisations such as particle morphology, sequencing, and secondary and tertiary structures with ease. We also have the capability to utilise advanced particle engineering solutions and complex scale-ups (such as those using microsphere and liposomal technologies) to create complex products and dosage forms.</p>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                          <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-drag-slider-item-thumbnail"><img src="images/image_12x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="images/image_12x-p-500.jpeg 500w, images/image_12x-p-800.jpeg 800w, images/image_12x-p-1080.jpeg 1080w, images/image_12x-p-1600.jpeg 1600w, images/image_12x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                              <div className="af-class-drag-slider-item-heading">R&amp;D beyond borders<br /></div>
                              <div>We have state-of-the art research and development centres in India, Europe and the US. They give us the means to handle advanced physico-chemical and biological characterization with ease. We also have some of the brightest scientists and researchers in the world working in tandem to create complex pharmaceutical ingredients, advanced particle engineering solutions, and complex products or dosage forms.<br /><br /><strong>Centres in:</strong> Hyderabad, Bangalore, USA, UK, Netherlands<br /></div>
                            </div>
                          </div>
                          <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-drag-slider-item-thumbnail"><img src="images/image_22x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="images/image_22x-p-800.jpeg 800w, images/image_22x-p-1080.jpeg 1080w, images/image_22x-p-1600.jpeg 1600w, images/image_22x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                              <div className="af-class-drag-slider-item-heading">Generics R&amp;D<br /></div>
                              <div>Generic versions of innovator drugs raise the possibility of every patient having access to quality healthcare because of their affordability. Our team ambitiously strives to develop high-quality generics, bringing expensive medicines within the reach of patients globally.<br /><br /><strong>Product portfolio:</strong> Novel crystalline and amorphous forms, Semi-synthetic APIs, Chirals, Prostaglandins, peptides and carbohydrates, Nano particle-based products<br /></div>
                            </div>
                          </div>
                          <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-drag-slider-item-thumbnail"><img src="images/image_32x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="images/image_32x-p-500.jpeg 500w, images/image_32x-p-800.jpeg 800w, images/image_32x-p-1080.jpeg 1080w, images/image_32x-p-1600.jpeg 1600w, images/image_32x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                              <div className="af-class-drag-slider-item-heading">Biologics R&amp;D<br /></div>
                              <div>We have end-to-end research capabilities in developing vaccines, blood components, cells, allergens, genes, tissues and recombinant proteins. Our Centres of Excellence in Hyderabad, Basel and Princeton have state-of-the-art technology in cell line and process development, and robust capabilities in bio-analytics. We have extensive experience with complex healthy volunteer and patient studies. Our teams are well-versed in liaising with key regulatory agencies around the world.<br /></div>
                            </div>
                          </div>
                          <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-drag-slider-item-thumbnail"><img src="images/image_42x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="images/image_42x-p-500.jpeg 500w, images/image_42x-p-800.jpeg 800w, images/image_42x-p-1080.jpeg 1080w, images/image_42x-p-1600.jpeg 1600w, images/image_42x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                              <div className="af-class-drag-slider-item-heading">Proprietary Products R&amp;D<br /></div>
                              <div>We focus on developing novel differentiated formulations to be used primarily in the dermatology and neurology markets. Our aim is to develop affordable products and design different delivery technologies to help patients manage their diseases better.<br /></div>
                            </div>
                          </div>
                          <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-drag-slider-item-thumbnail"><img src="images/image_52x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="images/image_52x-p-800.jpeg 800w, images/image_52x-p-1080.jpeg 1080w, images/image_52x-p-1600.jpeg 1600w, images/image_52x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                              <div className="af-class-drag-slider-item-heading">Analytical capabilities<br /></div>
                              <div>Over the years, our team has developed the ability to handle advanced physico-chemical and biological characterisations such as particle morphology, sequencing, and secondary and tertiary structures with ease. We also have the capability to utilise advanced particle engineering solutions and complex scale-ups (such as those using microsphere and liposomal technologies) to create complex products and dosage forms.<br /></div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id="a3ce977b-2c04-01a6-1b59-87844d16abc3" className="af-class-left-arrow w-slider-arrow-left">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="a3ce977b-2c04-01a6-1b59-87844d16abc7" className="af-class-right-arrow w-slider-arrow-right">
                          <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="a3ce977b-2c04-01a6-1b59-87844d16abc9" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                    <div className="af-class-drag-slider-progress-bar af-class-mobile-only af-class-margin-130">
                      <div className="af-class-drag-progress-fill af-class-fifty" />
                    </div>
                  </div>
                </div>
              </div>
              <a href="#Science-Research-Hero" data-w-id="b3a6c90d-f184-91ea-349f-6a5485733b18" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{display: 'none'}} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            <footer className="af-class-section af-class-footer">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-footer-grid">
                  <a id="w-node-f3bf3626-7fae-7d0a-e267-a3cedb32a0e0-db32a0dd" href="#" className="af-class-footer-logo w-inline-block"><img src="images/Dr._Reddys_Laboratories_Logo.svg" loading="lazy" alt /></a>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">PEOPLE &amp; Stories</a>
                    <a href="#" className="af-class-footer-link">Patients</a>
                    <a href="#" className="af-class-footer-link">Healthcare Professionals</a>
                    <a href="#" className="af-class-footer-link">Partners</a>
                    <a href="#" className="af-class-footer-link">Benefits of Partnering With Us</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">IMPACT &amp; SUSTAINABILITY</a>
                    <a href="#" className="af-class-footer-link">Environmental Initiatives</a>
                    <a href="#" className="af-class-footer-link">DRL Foundation &amp; Affiliates</a>
                    <a href="#" className="af-class-footer-link">HCP Programmes</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">BUSINESS &amp; PRODUCTS</a>
                    <a href="#" className="af-class-footer-link">Business Highlights &amp; Stories</a>
                    <a href="#" className="af-class-footer-link">Business Portfolio</a>
                    <a href="#" className="af-class-footer-link">Our Focus Areas</a>
                    <a href="#" className="af-class-footer-link">Quality &amp; Compliance</a>
                    <a href="#" className="af-class-footer-link">Global Manufacturing &amp; Supply Chains</a>
                    <a href="#" className="af-class-footer-link">API Product Portal</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">SCIENCE &amp; research</a>
                    <a href="#" className="af-class-footer-link">Development Streams</a>
                    <a href="#" className="af-class-footer-link">Research Philosophy</a>
                    <a href="#" className="af-class-footer-link">Capabilities</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">ABOUT&nbsp;US</a>
                    <a href="#" className="af-class-footer-link">Who We Are</a>
                    <a href="#" className="af-class-footer-link">Our Values</a>
                    <a href="#" className="af-class-footer-link">Our Heritage</a>
                    <a href="#" className="af-class-footer-link">Our Journey and Vision</a>
                    <a href="#" className="af-class-footer-link">Our Founder and Leaders</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">LIFE AT DR. REDDY’S</a>
                    <a href="#" className="af-class-footer-link">ASPIRE </a>
                    <a href="#" className="af-class-footer-link">Our People</a>
                    <a href="#" className="af-class-footer-link">Benefits of Working With Us</a>
                    <a href="#" className="af-class-footer-link">Work With Us</a>
                  </div>
                </div>
                <div className="af-class-secondary-footer-links-wrapper">
                  <a href="#" className="af-class-secondary-footer-links">BUSINESS PARTNERS</a>
                  <a href="#" className="af-class-secondary-footer-links">INVESTORS</a>
                  <a href="#" className="af-class-secondary-footer-links">JOBSEEKERS</a>
                  <a href="#" className="af-class-secondary-footer-links">CONTACT US</a>
                  <a href="#" className="af-class-secondary-footer-links">MICROSITES</a>
                </div>
                <div className="af-class-policies-and-social-wrapper">
                  <div className="af-class-polices-links-wrapper">
                    <a href="#" className="af-class-policies-links">Terms of Use</a>
                    <a href="#" className="af-class-policies-links">Privacy Notice</a>
                    <a href="#" className="af-class-policies-links">Accessibility</a>
                    <a href="#" className="af-class-policies-links">Cookie Policy</a>
                  </div>
                  <div className="af-class-social-icons-wrapper">
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/facebook-logo-png-white-i6-300x300.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/1300173_linkedin-logo-white-png.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/46-461780_twitter-twitter-logo-round-vector.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/46-462383_youtube-youtube-play-icon-white.svg" loading="lazy" alt /></a>
                  </div>
                </div>
                <div className="af-class-footer-note">© 2021 Dr. Reddy’s Laboratories Ltd. All rights reserved.</div>
              </div>
            </footer>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ScienceResearchView

/* eslint-enable */