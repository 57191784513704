import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../common/dropdown";
import { HR } from "../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../config";
import { downloadFile } from "../../../../Utils/Utils";

import { AnnualReports } from "./SubModules/AnnualReport";
import { QuarterlyReports } from "./SubModules/QuarterlyReports";
import { SecFiling } from "./SubModules/SecFiling";
import { MoaAndAoa } from "./SubModules/MoaAndAoa";
import { AnnualGeneralMeeting } from "./SubModules/AnnualGeneralMeeting";
import { SustainabilityReports } from "./SubModules/SustainabilityReports";
import { Disclosures } from "./SubModules/Disclosures";
import { OtherESGReports } from "./SubModules/OtherESGReports";

const Main = styled.div`
  width: 100%;
  min-height: 50vh;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-left: 50px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

const Content = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px;
  flex-direction: row;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;

const ReportItemDiv = styled.div`
  width: 50%;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ReportItem = styled(Link)`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;

export function ReportsAndFillingView(props) {

  const minWidthBool = useMediaQuery({ query: "(min-device-width: 900px)" });


  const renderSubMenuContent = () => {
    // props.state.selectedSubTab.id
    switch (props.state.selectedSubTab.id) {
      case 99:
        return <Disclosures state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 1:
        return <AnnualReports state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 2:
        return <QuarterlyReports state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 3:
        return <SecFiling state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 4:
        return <MoaAndAoa state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 5:
        return <AnnualGeneralMeeting state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 6:
        return <SustainabilityReports state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      case 7:
        return <OtherESGReports state={props.state} updateState={(changeState) => props.setState(changeState)} />;
      default:
        return <p>{props.state.selectedSubTab.id}</p>;
    }
  }

  return (
    <Main>

      {renderSubMenuContent()}

      {/* <MainContainer backgroundColor="#fff">
        <Heading>Reports</Heading>
        <HR />
        <SubContainer>
          <DropDownDiv>
            <Dropdown
              data={dropdownData}
              placeholder="Year"
              id="reports"
              selectedValue={selectedFilterDate}
              onChangeValue={(val) => setSelectedFilterDate(val)}
            />
          </DropDownDiv>
          <TextContainer>
            {minWidthBool ? (
              <TextHeading>
                Annual Report <br /> {selectedFilterDate}
              </TextHeading>
            ) : (
              <TextHeading>Annual Report {selectedFilterDate}</TextHeading>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {reports?.[selectedFilterDate]?.annual &&
                reports?.[selectedFilterDate]?.annual.map((item, index) => {
                  return (
                    <TextContent
                      key={selectedFilterDate + index}
                      onClick={() => {
                        downloadFile(item.url);
                      }}
                    >
                      {item.title}
                    </TextContent>
                  );
                })}
              {reports?.[selectedFilterDate]?.subsidiary &&
                reports?.[selectedFilterDate]?.subsidiary.map((item, index) => {
                  return (
                    <TextContent
                      key={selectedFilterDate + index}
                      onClick={() => {
                        downloadFile(item.url);
                      }}
                    >
                      {item.title}
                    </TextContent>
                  );
                })}
            </div>
          </TextContainer>
        </SubContainer>
      </MainContainer> */}

      {/* <MainContainer backgroundColor="#EDEDED">
        <Heading>Annexure to Board’s Report</Heading>
        <HR />
        <ReportItemContainer>
          {Annexurereports.map((item, index) => {
            return (
              <ReportItemDiv key={"Annexurereports" + index}>
                <ReportItem
                  onClick={() => {
                    downloadFile(item.url);
                  }}
                >
                  {item.title}
                </ReportItem>
              </ReportItemDiv>
            );
          })}
        </ReportItemContainer>
      </MainContainer> */}

      {/* <MainContainer backgroundColor="#fff">
        <Heading>Quarterly Reports</Heading>
        <HR />
        <SubContainer>
          <DropDownDiv>
            <Dropdown
              data={DropdowndataQuarterly}
              placeholder="Year"
              id="Quarterlyreports"
              selectedValue={SelectedQuarterlyDate}
              onChangeValue={(val) => setSelectedQuarterlyDate(val)}
            />
          </DropDownDiv>
          <TextContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                align: "Left",
              }}
            >
              {Quarterlyreports?.[SelectedQuarterlyDate] &&
                Quarterlyreports?.[SelectedQuarterlyDate].map((item, index) => {
                  return (
                    <Content
                      key={SelectedQuarterlyDate + index}
                      onClick={() => {
                        downloadFile(item.url);
                      }}
                    >
                      {item.title}
                    </Content>
                  );
                })}
            </div>
          </TextContainer>
        </SubContainer>
      </MainContainer> */}



      {/* <MainContainer backgroundColor="#EDEDED">
        <Heading>SEC Filings</Heading>
        <HR />
        <SubContainer>
          <Text>
            <TextHeading> SAFE HARBOUR</TextHeading>
            <TextLight>
              The documents contain forward-looking statements, as defined in
              the U.S. Private Securities Litigation Reform Act of 1995 . We
              have based these forward-looking statements on our current
              expectations and projections about future events. Such statements
              involve known and unknown risks, uncertainties and other factors
              that may cause actual result to differ materially. Such factors
              include, but are not limited to, changes in general economic
              conditions, our ability to successfully implement our strategy,
              the market acceptance of and demand for our products, our growth
              and expansion, technological change and our exposure to market
              risks. By their nature, these expectations and projections are
              only estimates and could be materially different from actual
              results in the future. This communication is not an offer to sell
              or the solicitation of an offer to buy securities
            </TextLight>
          </Text>
          <br />
          <DropDownDiv>
            <Dropdown
              data={Dropdowndatasecfilling}
              placeholder="Year"
              id="secreports"
              selectedValue={Selectedsecdate}
              onChangeValue={(val) => setSelectedsecdate(val)}
            />
          </DropDownDiv>
          <TextContainer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {secreports?.[Selectedsecdate] &&
                secreports?.[Selectedsecdate].map((item, index) => {
                  return (
                    <Content
                      key={Selectedsecdate + index}
                      onClick={() => {
                        downloadFile(item.url);
                      }}
                    >
                      {item.title}
                    </Content>
                  );
                })}
            </div>
          </TextContainer>
        </SubContainer>
      </MainContainer> */}




      {/* <MainContainer backgroundColor="#fff">
        {moaandaoareports?.[
          "Memorandum and articles of Association (Adopted on September 18, 2015)"
        ] &&
          moaandaoareports?.[
            "Memorandum and articles of Association (Adopted on September 18, 2015)"
          ].map((item, index) => {
            return (
              <Heading
                style={{ font: "18px" }}
                key={index}
                onClick={() => {
                  downloadFile(item.url);
                }}
              >
                MoA and AoA
              </Heading>
            );
          })}
        <HR />
      </MainContainer> */}
    </Main>
  );
}
