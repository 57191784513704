import React from 'react';

const ImageTextSlider = ({ }) => {


                        {/* <section style={{ display: 'flex', height: '230vh', backgroundColor: '#fff', flexDirection: 'column' }} className='af-class-section af-class-peple-stories-hero' >
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="af-class-stacked-images-wrapper">
                                <div style={{ width: '45%', height: '100vh' }} >
                                    <img src="images/Mask_Group_2681.png" loading="lazy" alt style={{ width: '100%', height: '100vh' }} />
                                </div>
                                <div style={{ width: '45%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                                    <h2 style={{ fontSize: '40px', fontWeight: '300' }} >Employee Initiatives</h2>
                                    <h6 style={{ color: '#000', fontSize: '18px', marginTop: '35px', marginBottom: '25px', fontWeight: 700 }} >Pre-Hospitalisation COVID-19 Care Units</h6>
                                    <p>Dr. Reddy’s set up COVID-19 care facilities at three locations - Hyderabad, Vizag and Baddi. Each facility was provisioned with all necessary equipment, oxygen and medicines as well as doctors and nurses. <br /><br /> These units were set up for employees and their families.</p>
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className="af-class-stacked-images-wrapper">
                                <div style={{ width: '45%', height: '100vh' }} >
                                    <img src="images/Mask_Group_2413.png" loading="lazy" alt style={{ width: '100%', height: '100vh' }} />
                                </div>
                                <div style={{ width: '45%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                                    <h2 style={{ fontSize: '40px', fontWeight: '300' }} >Employee Initiatives</h2>
                                    <h6 style={{ color: '#000', fontSize: '18px', marginTop: '35px', marginBottom: '25px', fontWeight: 700 }} >Active Testing</h6>
                                    <p>We are constantly vigilant through regular thermal screening, active testing and contact tracing. Proactive tests and tracking to identify asymptomatic cases are done through KeepSafe, an in-house app developed for employee health monitoring.</p>
                                </div>
                            </div>
                        </section> */}





    return (
        <div className="af-class-delivering-health-wrapper">
            <div className="af-class-scroll-image-stack-block">
                <div className="af-class-stacked-images-wrapper">

                    <img src="images/Mask_Group_2413.png" loading="lazy" alt data-w-id="f887d200-5ac6-9488-999b-a054378cd6b0" className="af-class-stack_image af-class-_2" style={{position: 'relative'}} />
                    <img src="images/Mask_Group_2681.png" loading="lazy" style={{ opacity: 0 }} data-w-id="f887d200-5ac6-9488-999a-a054378cd6b0" alt className="af-class-stack_image af-class-_1" />
                    {/* <div className="af-class-stickey-video-text-overlay">                    
                        <div className="af-class-stacked-images-text-wrapper" style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}>                       
                        <h2 data-w-id="f887d200-5ac6-9488-999c-a054378cd6b0" style={{position: 'absolute'}} className="af-class-h2-section-heading af-class-title af-class-stack_text af-class-_1">Environment</h2>
                        <h2 data-w-id="f887d200-5ac6-9488-999d-a054378cd6b0" style={{position: 'absolute', opacity: 0}} className="af-class-h2-section-heading  af-class-title af-class-stack_text af-class-_2">Employee volunteering</h2>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="af-class-scroll-text-wrapper">
                <div className="w-dyn-list">

                    <div role="list" className="w-dyn-items">

                        <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        {/* <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Action for Climate and Environment (ACE)</h2>
                                        <p className="af-class-paragraph" >Our ACE programme focuses on preserving the livelihoods of small and marginal farmers from the effects of climate change by encouraging them to adopt climate-friendly technologies and farming practices. ACE promotes and implements practices that focus on sustainably increasing agricultural productivity and incomes; lowering and/or removing greenhouse gas emissions; and adapting and building resilience to climate change through lead farmer platforms. This project is implemented in partnership with the Dr. Reddy’s Foundation.</p> */}
                                    <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ fontSize: '40px', fontWeight: '300' }} >Employee Initiatives</h2>
                                    <h6 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ color: '#000', fontSize: '18px', marginTop: '35px', marginBottom: '25px', fontWeight: 700 }} >Pre-Hospitalisation COVID-19 Care Units</h6>
                                    <p className="af-class-paragraph" >Dr. Reddy’s set up COVID-19 care facilities at three locations - Hyderabad, Vizag and Baddi. Each facility was provisioned with all necessary equipment, oxygen and medicines as well as doctors and nurses. <br /><br /> These units were set up for employees and their families.</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        {/* <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Dr K. Anji Reddy’s Spirit of Giving</h2>
                                        <p className="af-class-paragraph" >The anniversary of our founder’s passing is commemorated as Dr. K. Anji Reddy’s Spirit of Giving. Our employees across the world volunteer and contribute their time and effort to make a difference to their communities. Helping school students, spending time with senior citizens, bringing cheer to orphans, spreading awareness about traffic rules, working to restore a cyclone-ravaged zoo — the causes are many and diverse, but the commitment is the same.</p> */}

                                    {/* <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ fontSize: '40px', fontWeight: '300' }} >Employee Initiatives</h2> */}
                                    <h6 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ color: '#000', fontSize: '18px', marginTop: '35px', marginBottom: '25px', fontWeight: 700 }} >Active Testing</h6>
                                    <p className="af-class-paragraph" >We are constantly vigilant through regular thermal screening, active testing and contact tracing. Proactive tests and tracking to identify asymptomatic cases are done through KeepSafe, an in-house app developed for employee health monitoring.</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6c7" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Employee Initiatives</div>
                                    <h4 className="af-class-heading-4-bold">Pre-Hospitalisation COVID-19 Care Units</h4>
                                    <div>Dr. Reddy’s set up COVID-19 care facilities at three locations - Hyderabad, Vizag and Baddi. Each facility was provisioned with all necessary equipment, oxygen and medicines as well as doctors and nurses. <br /><br /> These units were set up for employees and their families.</div>
                                    <a href="https://api.drreddys.com/" className="af-class-link-arrow af-class-left-side w-inline-block">
                                        <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                    </a>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6d3" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <h4 className="af-class-heading-4-bold">Active Testing<br /></h4>
                                    <div>We are constantly vigilant through regular thermal screening, active testing and contact tracing. Proactive tests and tracking to identify asymptomatic cases are done through KeepSafe, an in-house app developed for employee health monitoring.</div>
                                </div>
                            </div>
                            {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6da" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Global Generics<br /></div>
                                    <div>Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.<br /></div>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6e2" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Proprietary Products<br /></div>
                                    <div>Patients today are looking for more than just safe, effective medicines. They require information, coaching, and customized support to help them adhere to complex treatment protocols.<br /><br />Our Proprietary Products division aims to fulfil these unmet patient needs. In some cases, this involves developing a new dosage of a current drug. In other cases, it may involve developing new combinations of synergistic medications. The focus is on initiatives to improve patient experience with our products. Better experience results in better compliance, which means better health and outcomes for patients.<br /></div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6ed" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f1" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f3" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" /> */}
                    </div>
                </div>
            </div>
            
            {/* <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                <div className="af-class-drag-progress-fill af-class-fifty" />
            </div> */}
        </div>
    )
}

export default ImageTextSlider;