import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const OurPromises = ({ data }) => {
  const [leftDisabled, SetLeftDisabled] = React.useState(true);
  const [rightDisabled, SetRightDisabled] = React.useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

  return (
    !isMobile && <div id="promises" data-w-id="ac2b7f71-6c4e-be07-c418-51677fd64972" className="af-class-section af-class-drag-slider-section" style={{ zIndex: 999,paddingTop:'120px' }}>
      <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" style={{ opacity: 0 }} className="af-class-container w-container">
        <h2 className="af-class-h2-section-heading">Promises</h2>
        <div className="af-class-drag-slider-wrapper">
          <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
            <div className="af-class-mask w-slider-mask">
              <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                <h6 style={{ fontSize: '18px', fontWeight: 200, lineHeight: 1.6, marginTop: 0 }} >Our five promises clarify what we do, what we offer and the commitments we make to our stakeholders. Our patients trust our medicines. We focus our energies on renewing this trust every day. As we keep the interests of our patients at the centre of all that we do, our promises drive us to reach higher levels of excellence.</h6>
              </div>
              {
                data.map((item, index) => {
                  if (index < 2) {
                    {/* <div key={"OurPromises:" + index} > */ }
                    return (
                      <div data-w-id={"93c978e6-0303-b12f-c2a5-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                        {/* <a
                          href={`/articles?${item.contentId}#${item.id}`}
                          style={{ width: '100%' }}
                          className="af-class-slide-div w-inline-block"> */}
                          <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                            <div className="af-class-drag-slider-item-thumbnail"><img src={item.thumbnail} loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                            <div className="af-class-drag-slider-item-heading">{item.title}</div>
                          </div>
                        {/* </a> */}
                      </div>
                    )
                  }
                  else {
                    return (
                      <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                        {/* <a
                          href={`/articles?${item.contentId}#${item.id}`}
                          style={{ width: '100%' }}
                          className="af-class-slide-div w-inline-block"> */}
                          <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                            <div className="af-class-drag-slider-item-thumbnail"><img src={item.thumbnail} loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                            <div className="af-class-drag-slider-item-heading">{item.title}</div>
                          </div>
                        {/* </a> */}
                      </div>
                    )
                  }
                  {/* </div> */ }
                })
              }
              {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a7" className="af-class-drag-slider-item af-class-promise w-slide">
                <h6 style={{ fontSize: '18px', fontWeight: 200, lineHeight: 1.6 }} >Our five promises clarify what we do, what we offer and the commitments we make to our stakeholders. Our patients trust our medicines. We focus our energies on renewing this trust every day. As we keep the interests of our patients at the centre of all that we do, our promises drive us to reach higher levels of excellence.</h6>
              </div>

              <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a7" className="af-class-drag-slider-item af-class-promise w-slide">
                <a href="https://dr-reddy.webflow.io/articles/bringing-expensive-medicines-within-reach" className="af-class-slide-div w-inline-block">
                  <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                    <div className="af-class-drag-slider-item-thumbnail"><img src="/images/trust-tru-katsande-HQM5jm12etE-unsplash.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    <div className="af-class-drag-slider-item-heading">Bringing expensive medicines within reach</div>
                  </div>
                </a>
              </div>

              <div className="af-class-drag-slider-item af-class-promise w-slide">
                <a href="https://dr-reddy.webflow.io/articles/address-unmet-patient-needs" className="af-class-slide-div w-inline-block">
                  <div className="af-class-slide-image-and-text-block">
                    <div className="af-class-slide-image-and-text-block">
                      <div className="af-class-drag-slider-item-thumbnail"><img src="/images/shashank-thapa-MkPINODL-Tw-unsplash2x.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                      <div className="af-class-drag-slider-item-heading">Addressing unmet patient needs</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="af-class-drag-slider-item af-class-promise w-slide">
                <a href="https://dr-reddy.webflow.io/articles/help-patients-manage-disease-better" className="af-class-slide-div w-inline-block">
                  <div className="af-class-slide-image-and-text-block">
                    <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Mask-Group-21.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    <div className="af-class-drag-slider-item-heading">Helping patients manage disease better</div>
                  </div>
                </a>
              </div>
              <div data-w-id="ae2c330c-3b91-6fe2-293a-e87e004b1901" className="af-class-drag-slider-item af-class-promise w-slide">
                <a href="https://dr-reddy.webflow.io/articles/work-with-partners-and-help-them-succeed" className="af-class-slide-div w-inline-block">
                  <div className="af-class-slide-image-and-text-block">
                    <div className="af-class-drag-slider-item-thumbnail"><img src="/images/ground-group-growth-hands-4610492x.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    <div className="af-class-drag-slider-item-heading">Working with partners to help them succeed</div>
                  </div>
                </a>
              </div>
              <div className="af-class-drag-slider-item af-class-promise w-slide">
                <a href="https://dr-reddy.webflow.io/articles/enable-and-help-our-partners-ensure-that-our-medicines-are-available-where-needed" className="af-class-slide-div w-inline-block">
                  <div className="af-class-slide-image-and-text-block">
                    <div className="af-class-drag-slider-item-thumbnail"><img src="/images/adam-niescioruk-RSeZVZLWnuc-unsplash2x.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    <div className="af-class-drag-slider-item-heading">Enabling and helping our partners ensure that our medicines are available where needed</div>
                  </div>
                </a>
              </div> */}
            </div>
            {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
            </div>
            <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
            </div> */}

            <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: leftDisabled ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={(e) => { SetRightDisabled(false); SetLeftDisabled(true); }}>
              {
                leftDisabled ?
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                  </>
                  :
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
              }

            </div>
            <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: rightDisabled ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={(e) => { SetRightDisabled(true); SetLeftDisabled(false); }}>
              {
                rightDisabled ?
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
                  :
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
              }
            </div>


            <div className="af-class-slide-nav w-slider-nav w-round" />
          </div>
        </div>
        <div className="af-class-drag-slider-progress-bar">
          <div className="af-class-drag-progress-fill af-class-fifty" />
        </div>
      </div>
    </div>
  )
}

export default OurPromises;