import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";

const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const IcsTextContainer = styled.div`
  width: 80%;
  margin-top: 40px;
  line-spacing:30px;
  @media (max-width: 900px) {
    width: 100%;
    line-spacing:24px;
  }
`;
const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 1030px) {
    font-size: 12px;
    width: 40%;
  }
`;
const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const TextLight = styled.p`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  line-height: 1.7 !important;
  @media (max-width: 750px) {
    font-size: 14px !important;
    line-height: 24px !important;
  }
`;

const TextBook = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;
const TextClick = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 12px;
    margin-bottom: 25px;
  }
`;
const CGRSection = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

const ReportHeading = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  width: 15%;
  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
  }
`;

const ICSSection = styled.div`
  width: 90%;
`;

const content = styled.p`
  display: flex;
  flex-direction: column;
`;

export const InternalControlSystems = (props) => {

  const [internalcontrol, setinternalcontrol] = useState({});

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempArr = [];
    axios({
        method: "get",
        url: config.serverUrl.base + "/jsonapi/node/internal_control_systems",
    })
        .then((res) => {
            console.log(res);
            for (const report of res.data.data) {
                if (tempReports?.[report.attributes.title]) {
                    tempArr = tempReports[report.attributes.title];
                    tempArr.push({
                        title: report.attributes.title,
                        description: report.attributes.field_description_internalcontro,
                    });
                    tempReports[report.attributes.title] = tempArr;
                } else {
                    tempArr = [];
                    tempArr.push({
                        title: report.attributes.title,
                        description: report.attributes.field_description_internalcontro,
                    });
                    tempReports[report.attributes.title] = tempArr;
                }
            }
            console.log(tempReports);
            setinternalcontrol(tempReports);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

    return (
            <IcsTextContainer>
                {internalcontrol?.["Internal Control Systems"] &&
                    internalcontrol?.["Internal Control Systems"].map((item, index) => {
                        return <TextLight>{item.description}</TextLight>;
                    })}
            </IcsTextContainer>
    )

}