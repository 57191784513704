import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import Cdropdown from "../../../../common/Cdropdown";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { scroller } from 'react-scroll';
import { FiChevronDown } from 'react-icons/fi';

import { isValidEmail, isValidZipcode, isValidIndianMobile, isEmpty, isValidFolioNo, isValidPancode } from "../../../../../Utils/Utils";
import { useMediaQuery } from 'react-responsive';

import axios from 'axios';
import config from '../../../../../config';

const TextInputRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 35px;
`;

const TextInput = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 50%;
`;

const TextInputEnd = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
width: 50%;
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    `;

const FormHeading = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 70px;
`;

const TextLight = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: 300;
    font-family: Drlcircular;
    margin: 0;
    margin-top: 10px;
    `;

const TextBold = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    font-family: Drlcircular;
    margin: 0;
    `;

const Label = styled.p`
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
    `;

const Input = styled.input`
    border: 0px;
    background-color: #fff;
    height: 50px;
    width: 100%;
    font-family: Drlcircular;
    letter-spacing:0px !important;
    &:focus{
        outline: none !important;
        border:1px solid #5225B5;
        border-radius: 5px;
    }
    padding: 20px 15px;
    border-radius: 5px;

`;

const TextArea = styled.div`
    display: flex;
flex-direction: row;
align-items: center;
width: 100%;
letter-spacing:0px !important;
`;

const TextAreaInput = styled.textarea`
border: 0px;
background-color: #fff;
height: 100px;
font-family: Drlcircular;
resize: none;
width: 100%;
padding:10px;
border-radius: 5px;
&:focus{
        outline: none !important;
        border:1px solid #5225B5;
    }
`;

const DropDown = styled.a`
    background-color: #fff !important;
    height: 50px !important;
    width: 50% !important;
    display: flex !important;
    flex-direction: row !important;
    padding: 15px !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: pointer !important;
    text-decoration: none !important;
    /* border:1px solid #5225B5;
    border-radius: 5px; */
`;

const DropDwonLabel = styled.p`
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
`;

const AcceptPolicySection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    margin: 24px 0px;
`;

const AcceptTextLight = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-weight: 300!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    width:90%;
`;

const AcceptTextLink = styled.a`
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    font-family: Drlcircular;
    margin: 0;
    cursor: pointer;
    text-decoration: none !important;
    &:hover{
        text-decoration: underline !important;
        color: #000000;
    }
`;

const RadioButton = styled.a`
    cursor: pointer!important;
    text-decoration: none !important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    background-color: #fff !important;
    border: #5225B5 solid 0.5px!important;
    width: 20px!important;
    height: 20px!important;
    border-radius: 20px!important;
    margin-right: 15px!important;
`;

const RadioActive = styled.div`
    background-color: #FF5046;
    width: 12px;
    height: 12px;
    border-radius: 12px;
`;

const RobotText = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-weight: 300!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    margin-top: 10px !important;
    text-align:'center';
`;

const ErrorText = styled.span`
    font-size: 12px!important;
    color: #FF5046!important;
    font-weight: 400!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    line-height: 14px !important;
    margin-top:8px !important;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 24px;
`;

const ClearButton = styled.a`
    font-size: 20px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #9090905D !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    min-width:60vw;
    max-width:60vw;
    align-items:center;
    justify-content:center;
    text-align:center;
    margin-bottom:20px;
`;

const SubmitButton = styled.a`
    font-size: 20px !important;
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #5225B5 !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    min-width:190px;
    max-width:190px;
    align-items:center;
    justify-content:center;
    text-align:center;
    margin-top:20px;
`;

const StyledToastContainer = styled(ToastContainer)`
        .Toastify__toast-body {
            font-family: Drlcircular !important;
            font-size:20px !important;
            font-weight:400 !important;
        }
        .Toastify__toast-icon {
            width:32px;
            height:32px;
            margin-left:10px;
        }
        @media (max-width: 900px) {
            align-self:center;
            .Toastify__toast-icon {
            width:24px;
            height:24px;
            margin-left:10px;
        }
            .Toastify__toast-body {
            font-size:14px !important;
        }
        }
    `;

export function EnquiryFormTab(props) {

    const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

    const recaptchaRef = React.createRef();

    const [errorToastBool, setErrorToastBool] = useState(false);
    const [physicalBool, setPhysicalBool] = useState(true);

    const [formData, setFormData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        officeAddress: '',
        pan: '',
        folioNo: '',
        address: '',
    })
    const [formErrData, setFormErrData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        officeAddress: '',
        pan: '',
        folioNo: '',
        address: '',
    })

    const clearForm = () => {
        setStep(1);
        setPhysicalBool(true);
        setFormErrData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            officeAddress: '',
            pan: '',
            folioNo: '',
            address: '',
        })
        setFormData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            officeAddress: '',
            pan: '',
            folioNo: '',
            address: '',
        })
        scroller.scrollTo('formStart', { smooth: true, duration: 100, offset: -200 });
    }

    const SubmitForm = () => {
        var submitBool = true;
        var errorMsg = '';
        var formErrDataTemp = { ...formErrData };
        if (!isEmpty(formData.fName).status) {
            formErrDataTemp = ({ ...formErrDataTemp, fName: isEmpty(formData.fName).message })
            submitBool = false;
        }
        if (!isEmpty(formData.lName).status) {
            formErrDataTemp = ({ ...formErrDataTemp, lName: isEmpty(formData.lName).message })
            submitBool = false;
        }
        if (!isValidIndianMobile(formData.phNo).status) {
            formErrDataTemp = ({ ...formErrDataTemp, phNo: isEmpty(formData.phNo).message })
            submitBool = false;
        }
        if (!isEmpty(formData.officeAddress).status) {
            formErrDataTemp = ({ ...formErrDataTemp, officeAddress: isEmpty(formData.officeAddress).message })
            submitBool = false;
        }
        if (!isValidEmail(formData.email).status) {
            formErrDataTemp = ({ ...formErrDataTemp, email: isEmpty(formData.email).message })
            submitBool = false;
        }
        if (!isValidPancode(formData.pan).status) {
            formErrDataTemp = ({ ...formErrDataTemp, pan: isValidPancode(formData.pan).message })
            submitBool = false;
        }
        if (!isValidFolioNo(formData.folioNo).status) {
            formErrDataTemp = ({ ...formErrDataTemp, folioNo: isValidFolioNo(formData.folioNo).message })
            submitBool = false;
        }
        if (!isEmpty(formData.address).status) {
            formErrDataTemp = ({ ...formErrDataTemp, address: isEmpty(formData.address).message })
            submitBool = false;
        }
        setFormErrData({ ...formErrDataTemp })
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!(recaptchaValue && recaptchaValue.length)) {
            errorMsg = 'Please complete the Captcha';
        }
        // if (!acceptBool) {
        //     errorMsg = 'Please accept and agree the terms and privacy.';
        // }
        if (submitBool && errorMsg.length === 0) {
            setErrorToastBool(false);
            axios({
                method: "get",
                url: config.serverUrl.base + "/api/form/token"
            }).then((res) => {
                if (res.status == 200) {
                    axios({
                        method: "post",
                        url: config.serverUrl.base + "/jsonapi/node/form1", data: {
                            "data": {
                                "type": "node--form1",
                                "attributes": {
                                    "title": "form data 1",
                                    "field_fname": formData.fName,
                                    "field_lname": formData.lName,
                                    "field_email": formData.email,
                                    "field_phno": formData.phNo,
                                    "field_officeresidence": formData.officeAddress,
                                    "field_pan": formData.pan,
                                    "field_foliono": formData.folioNo,
                                    "field_address": formData.address,
                                    "field_modeofholding": physicalBool ? "Physical" : "Electronic",
                                }
                            }
                        },
                        headers: {
                            'Content-Type': 'application/vnd.api+json',
                            'Accept': 'application/vnd.api+json',
                            'Applications': res.data.key,
                        },
                    }).then((res) => {
                        console.log(res, " =-=-=-=  ")
                        toast.success('Your details have been submitted successfully', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            toastId: 'message'
                        })
                    }).catch((err) => { })
                }
            }).catch((err) => { })
        } else {
            setErrorToastBool(true);
            errorMsg = !submitBool ? 'Please check the errors and retry again' : errorMsg;
            toast.warning(errorMsg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: 'message'
            })
        }
        // else {
        //     toast.error('Please check and fill form with proper values', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         toastId: 'message'
        //     })
        // }
    }

    const [step, setStep] = useState(1);

    const onNextPress = () => {
        var submitBool = true;
        var errorMsg = '';
        var formErrDataTemp = { ...formErrData };
        if (step === 1) {
            if (!isEmpty(formData.fName).status) {
                formErrDataTemp = ({ ...formErrDataTemp, fName: isEmpty(formData.fName).message })
                submitBool = false;
            }
            if (!isEmpty(formData.lName).status) {
                formErrDataTemp = ({ ...formErrDataTemp, lName: isEmpty(formData.lName).message })
                submitBool = false;
            }
            if (!isValidEmail(formData.email).status) {
                formErrDataTemp = ({ ...formErrDataTemp, email: isValidEmail(formData.email).message })
                submitBool = false;
            }
            if (!isValidIndianMobile(formData.phNo).status) {
                formErrDataTemp = ({ ...formErrDataTemp, phNo: isValidIndianMobile(formData.phNo).message })
                submitBool = false;
            }
            if (!isValidPancode(formData.pan).status) {
                formErrDataTemp = ({ ...formErrDataTemp, pan: isValidPancode(formData.pan).message })
                submitBool = false;
            }
            if (!isEmpty(formData.officeAddress).status) {
                formErrDataTemp = ({ ...formErrDataTemp, officeAddress: isEmpty(formData.officeAddress).message })
                submitBool = false;
            }
        } else if (step === 2) {
            if (!isValidFolioNo(formData.folioNo).status) {
                formErrDataTemp = ({ ...formErrDataTemp, folioNo: isValidFolioNo(formData.folioNo).message })
                submitBool = false;
            }
            if (!isEmpty(formData.address).status) {
                formErrDataTemp = ({ ...formErrDataTemp, address: isEmpty(formData.address).message })
                submitBool = false;
            }
            const recaptchaValue = recaptchaRef.current.getValue();
            if (!(recaptchaValue && recaptchaValue.length)) {
                errorMsg = 'Please complete the Captcha';
            }
        }
        setFormErrData({ ...formErrDataTemp })
        if (submitBool && errorMsg.length === 0) {
            setErrorToastBool(false);
            setStep(step + 1);
        }
        // else {
        //     setErrorToastBool(true);
        //     errorMsg = !submitBool ? 'Please check the errors and retry again' : errorMsg;
        //     toast.warning(errorMsg, {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         toastId: 'message'
        //     })
        // }
    }

    const pocData = ["Business Development Opportunity",
        "Company Information",
        "API Manufacturing",
        "Unit Dose Bar Code Database Request",
        "IIT Application Request ",
        "Formulation Manufacturing ",
        "Process Request ",
        "Printed Materials Request ",
        "Production Question or Comment ",
        "Website Question or Comment ",
        "Other"];

    const CloseButton = ({ closeToast }) => (
        <img loading="lazy" src="/images/close-fff.svg" height="18px" width="18px" style={{ margin: '10px', marginTop: '14px' }} />
    );


    return <FormSection id={"formStart"} >

        {step === 1 && <>

            <div style={{ display: 'flex', flexDirection: 'row', }} >

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', marginRight: '70px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >First Name<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.fName} onChange={(ev) => {
                        var validation = isEmpty(ev.target.value);
                        setFormData({ ...formData, fName: ev.target.value })
                        setFormErrData({ ...formErrData, fName: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.fName}</ErrorText>
                </span>

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >Last Name<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.lName} onChange={(ev) => {
                        var validation = isEmpty(ev.target.value);
                        setFormData({ ...formData, lName: ev.target.value })
                        setFormErrData({ ...formErrData, lName: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.lName}</ErrorText>
                </span>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', }} >

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', marginRight: '70px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >Email<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.email} onChange={(ev) => {
                        var validation = isValidEmail(ev.target.value);
                        setFormData({ ...formData, email: ev.target.value })
                        setFormErrData({ ...formErrData, email: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.email}</ErrorText>
                </span>

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >Mobile Number<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input maxLength={10} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} type="input" value={formData.phNo} onChange={(ev) => {
                        var validation = isValidIndianMobile(ev.target.value, 'Mobile Number');
                        setFormData({ ...formData, phNo: ev.target.value })
                        setFormErrData({ ...formErrData, phNo: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.phNo}</ErrorText>
                </span>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', }} >

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', marginRight: '70px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >Office/Residence<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.officeAddress} onChange={(ev) => {
                        var validation = isEmpty(ev.target.value);
                        setFormData({ ...formData, officeAddress: ev.target.value })
                        setFormErrData({ ...formErrData, officeAddress: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.officeAddress}</ErrorText>
                </span>

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >PAN<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.pan} onChange={(ev) => {
                        var validation = isValidPancode(ev.target.value);
                        setFormData({ ...formData, pan: ev.target.value })
                        setFormErrData({ ...formErrData, pan: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.pan}</ErrorText>
                </span>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', }} >

                <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', marginRight: '70px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >DP ID/Client ID/Folio No<span style={{ color: '#FF5046' }} >*</span></Label>
                    <Input type="input" value={formData.folioNo} onChange={(ev) => {
                        var validation = isValidFolioNo(ev.target.value);
                        setFormData({ ...formData, folioNo: ev.target.value })
                        setFormErrData({ ...formErrData, folioNo: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.folioNo}</ErrorText>
                </span>

                <span style={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'flex-start', marginBottom: '20px', flex: 1 }} >
                    <Label style={{ fontSize: "18px" }} >Mode of holding<span style={{ color: '#FF5046' }} >*</span></Label>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }} >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%' }} >
                            <RadioButton onClick={() => {
                                setPhysicalBool(true)
                            }} >
                                {physicalBool && <RadioActive />}
                            </RadioButton>
                            <AcceptTextLight>Physical</AcceptTextLight>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%' }} >
                            <RadioButton onClick={() => {
                                setPhysicalBool(false)
                            }} >
                                {!physicalBool && <RadioActive />}
                            </RadioButton>
                            <AcceptTextLight>Electronic</AcceptTextLight>
                        </div>
                    </div>
                </span>

            </div>

        </>}

        {step === 2 && <>

            <span style={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'flex-start', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px", }} >Address<span style={{ color: '#FF5046' }} >*</span></Label>
                <TextAreaInput placeholder="Address" value={formData.address} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, address: ev.target.value })
                    setFormErrData({ ...formErrData, address: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.address}</ErrorText>
            </span>

            <span style={{ width: '100%', marginBottom: '24px' }} >
                <ReCAPTCHA sitekey="6LfbvfkhAAAAAECKD1X5cTSQMbMD94PstmwxsMHx" ref={recaptchaRef} />
                <RobotText>Please confirm that you are not a robot</RobotText>
            </span>

        </>}

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', marginTop: '70px' }} >
            <a style={{ fontSize: '18px', fontWeight: 'bold', color: step === 1 ? '#909090' : '#5225B5', textDecoration: 'none', margin: '0 24px' }} onClick={() => {
                if (step !== 1) {
                    setStep(step - 1);
                }
                scroller.scrollTo('formStart', { smooth: true, duration: 100, offset: -200 });
            }} ><img loading="lazy" alt="leftArr" src={step === 1 ? 'images/leftAshArrow.svg' : 'images/leftPrimaryArrow.svg'} /></a>
            <p style={{ margin: 0, fontSize: '18px', fontWeight: 'bold', color: '#5225B5' }} >{step} of 2</p>
            <a style={{ fontSize: '18px', fontWeight: 'bold', color: step === 2 ? '#909090' : '#5225B5', textDecoration: 'none', margin: '0 24px' }} onClick={() => {
                // onNextPress();
                if (step === 1) {
                    setStep(step + 1);
                    scroller.scrollTo('formStart', { smooth: true, duration: 100, offset: -200 });
                }
            }} ><img  loading="lazy" alt="leftArr" src={step === 2 ? 'images/rightAshArrow.svg' : 'images/rightPrimaryArrow.svg'} /></a>
        </div>

        {step === 2 && <ButtonSection>
            {/* <ClearButton onClick={clearForm} >Clear</ClearButton> */}
            <SubmitButton onClick={SubmitForm}>Submit</SubmitButton>
            <StyledToastContainer
                position="top-center"
                // autoClose={3000}
                autoClose={3000000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                toastStyle={{ backgroundColor: errorToastBool ? '#FF5046' : '#25B987', color: 'white', borderRadius: '4px' }}
                style={{ zIndex: 999999999999999, width: '565px', fontFamily: 'Drlcircular !important', fontSize: '20px', fontWeight: '500', bottom: '100px', borderRadius: '4px' }}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={CloseButton}
            />
        </ButtonSection>}

    </FormSection>
}
