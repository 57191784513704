import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Modal from 'react-modal';

import { isTab } from '../Utils/Utils';
import CloseIconFFF from './images/Icon-feather-menu-close-fff.svg';

const customStyles = {
    content: {
        borderRadius: "5px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const customStylesTab = {
    content: {
        borderRadius: "5px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const customStylesMob = {
    content: {
        borderRadius: "5px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    width:100vw;
    height:100vh;
`;

const Text = styled.p`
    color: #000000;
    font-size: 18px;
    font-weight: 200;
    font-family: Drlcircular;
    margin: 0;
    margin-top:45px;
    margin-bottom:70px;
    align-self:center;
    width:520px;
    max-height:50vh;
    overflow: scroll;
    word-break: break-all;
    @media (max-width: 767px) {
        font-size: 14px;
        margin-top:30px;
        margin-bottom:35px;
        width:240px;
    }
    @media (max-width: 820px) {
        font-size: 16px;
        margin-top:38px;
        margin-bottom:35px;
        width:240px;
    }
    `;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction:row;
    align-self: center;
    margin-bottom:50px;
    @media (max-width: 767px) {
        flex-direction:column;
        margin-bottom:40px;
    }
    @media (max-width: 820px) {
        flex-direction:column;
        margin-bottom:40px;
    }
`;

const Img = styled.img`
    height: 18px;
    width: 18px;
    @media (max-width: 820px) {
        height: 15px;
        width: 15px;
    }
`;

const CancelButton = styled.a`
    text-decoration:none;
    text-align:center;
    width:190px;
    cursor: pointer;
    color: #000;
    background-color:#C6C6C6;
    font-size: 20px;
    font-weight: 400;
    font-family: Drlcircular;
    padding: 15px;
    border-radius: 5px;
    margin-right:55px;
    @media (max-width: 820px) {
        margin-right:0px;
        margin-bottom:20px;
        font-size: 16px;
    }
    `;

const OkButton = styled.a`
    text-decoration:none !important;
    text-align:center;
    width:190px;
    cursor: pointer;
    color: #fff !important;
    background-color:#5225B5;
    font-size: 20px;
    font-weight: 400;
    font-family: Drlcircular;
    padding: 15px;
    border-radius: 5px;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

export function CPopUp({ enableAlert, onClose, href }) {
    // return <Modal
    //     isOpen={enableAlert}
    //     onRequestClose={onClose}
    //     style={window.matchMedia('(max-width: 767px)').matches ? customStylesMob : isTab ? customStylesTab : customStyles}
    // >
    //     {/* <Container  > */}
    //     <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999999999, backgroundColor: 'rgba(0,0,0,0.9)', height: '100vh', width: '100vw' }} >
    //         <div className="af-class-section" style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px', overflow: 'auto' }} >
    //             <div className="af-class-container" style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px' }}>
    //                 <iframe src={href} height="100%" width="100%" />
    //             </div>
    //         </div>
    //     </div>
    //     {/* </Container> */}
    // </Modal>
    return enableAlert && <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999999999, backgroundColor: 'rgba(0,0,0,0.9)', height: '100vh', width: '100vw' }} >
        <div className='af-class-section' style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px', overflow: 'auto' }} >
            <div className="af-class-container" style={{ backgroundColor: 'transparent', height: '100%', width: '100%', marginBottom: '0px', paddingBottom: '0px',display:'flex',flex:1 }} >
                <a onClick={onClose} style={{ alignItems: 'center', justifyContent: 'center', justifyItems: 'center', position: 'absolute', top: window.matchMedia('(max-width: 820px)').matches ? -30 : -90, right: window.matchMedia('(max-width: 820px)').matches ? 0 : 10, cursor: 'pointer' }} >
                    <img loading="lazy" src={CloseIconFFF} style={{ height: window.matchMedia('(max-width: 820px)').matches ? '18px' : '24px', }} />
                </a>
                <div style={{ height: '100%', width: '100%', flex: 1, display: 'flex', flexDirection: 'column',overflow:'hidden' }} >
                    <iframe src={href} height="100%" width="100%" style={{marginBottom:'20px'}} />
                </div>
            </div>
        </div>
    </div>
}