// import React from 'react';

// const ImgSlider = ({ data }) => {
//     return (
//         <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d35a" className="af-class-section af-class-horizontal-move">
//               <div className="af-class-horizontal-transition-wrapper">
//                 <div className="af-class-container w-container">
//                   <div className="af-class-horizontal-image-slider">
//                   <img src="/images/water-neutrality.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/water-neutrality-p-1080.jpeg 1080w, /images/water-neutrality.jpg 1157w" alt className="af-class-horizontal-slider-image af-class-placeholder" />
//                   {/* <img src="/images/amazon.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/amazon-p-500.jpeg 500w, /images/amazon-p-800.jpeg 800w, /images/amazon-p-1080.jpeg 1080w, /images/amazon.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_2 af-class-amazon" /> */}
//                   {/* <img src="/images/russia.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/russia-p-500.jpeg 500w, /images/russia-p-800.jpeg 800w, /images/russia-p-1080.jpeg 1080w, /images/russia.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" /> */}
//                   {
//                             data[1] && <img src={data[1].thumbnail} loading="lazy"  alt className="af-class-horizontal-slider-image af-class-_1 af-class-amazon" />
//                   }
//                   {
//                             data[0] && <img src={data[0].thumbnail} loading="lazy"  alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" />
//                   }
//                   </div>
//                   <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d361" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="af-class-white-text-overlay af-class-_2">
//                     <h2 className="af-class-h2-section-heading af-class-_450">Learning to sell directly to consumers through Amazon</h2>
//                     <a href="https://dr-reddy.webflow.io/articles/learning-to-sell-directly-to-consumers-through-amazon" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
//                       <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
//                     </a>
//                   </div>
//                   <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d36a" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="af-class-white-text-overlay af-class-_1">
//                     <h2 className="af-class-h2-section-heading af-class-_450">Delivering the right product at the right time to rural Russia</h2>
//                     <a href="https://dr-reddy.webflow.io/articles/delivering-the-right-product-at-the-right-time-to-rural-russia" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
//                       <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//         // <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d35a" className="af-class-section af-class-horizontal-move">
//         //     <div className="af-class-horizontal-transition-wrapper">
//         //         <div className="af-class-container w-container">
//         //             <div className="af-class-horizontal-image-slider">
//         //                 {/* <img src="/images/water-neutrality.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/water-neutrality-p-1080.jpeg 1080w, /images/water-neutrality.jpg 1157w" alt className="af-class-horizontal-slider-image af-class-placeholder" />
//         //             <img src="/images/amazon.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/amazon-p-500.jpeg 500w, /images/amazon-p-800.jpeg 800w, /images/amazon-p-1080.jpeg 1080w, /images/amazon.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_2 af-class-amazon" />
//         //             <img src="/images/russia.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/russia-p-500.jpeg 500w, /images/russia-p-800.jpeg 800w, /images/russia-p-1080.jpeg 1080w, /images/russia.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" /> */}
//         //                 {
//         //                     data[1] && <img src={data[1].thumbnail} loading="lazy" alt className="af-class-horizontal-slider-image af-class-_2 af-class-amazon" />
//         //                 }
//         //                 {
//         //                     data[0] && <img src={data[0].thumbnail} loading="lazy" alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" />
//         //                 }
//         //             </div>
//         //             {
//         //                 data[1] && <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d361" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay">
//         //                     <h2 className="af-class-h2-section-heading af-class-_450">{data[1].title}</h2>
//         //                     <a href={"/articles"}
//         //                         onClick={() => {
//         //                             // this.props.history.push("/articles", { data: item })
//         //                             localStorage.setItem("data", JSON.stringify(data[1]))
//         //                         }}
//         //                         className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
//         //                         <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
//         //                     </a>
//         //                 </div>
//         //             }
//         //             {
//         //                 data[0] && <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d36a" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-_1">
//         //                     <h2 className="af-class-h2-section-heading af-class-_450">{data[0].title}</h2>
//         //                     <a href={"/articles"}
//         //                         onClick={() => {
//         //                             // this.props.history.push("/articles", { data: item })
//         //                             localStorage.setItem("data", JSON.stringify(data[0]))
//         //                         }} className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
//         //                         <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
//         //                     </a>
//         //                 </div>
//         //             }
//         //         </div>
//         //     </div>
//         // </div>
//     )
// }

// export default ImgSlider;



import React from 'react';

const ImgSlider = ({ data }) => {
    return (
        <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d35a" className="af-class-section af-class-horizontal-move">
            <div className="af-class-horizontal-transition-wrapper">
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                    <div className="af-class-horizontal-image-slider"><img src="/images/water-neutrality.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/water-neutrality-p-1080.jpeg 1080w, /images/water-neutrality.jpg 1157w" alt className="af-class-horizontal-slider-image af-class-placeholder" />
                        {/* <img src="/images/amazon.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/amazon-p-500.jpeg 500w, /images/amazon-p-800.jpeg 800w, /images/amazon-p-1080.jpeg 1080w, /images/amazon.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_2 af-class-amazon" />
                    <img src="/images/russia.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/russia-p-500.jpeg 500w, /images/russia-p-800.jpeg 800w, /images/russia-p-1080.jpeg 1080w, /images/russia.jpg 1159w" alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" /> */}
                        {
                            data[1] && <img src={data[1].thumbnail} loading="lazy" alt className="af-class-horizontal-slider-image af-class-_2 af-class-amazon" />
                        }
                        {
                            data[0] && <img src={data[0].thumbnail} loading="lazy" alt className="af-class-horizontal-slider-image af-class-_1 af-class-russia" />
                        }
                    </div>
                    {
                        data[1] && <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d361" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay">
                            <h2 className="af-class-h2-section-heading af-class-_450">{data[1].title}</h2>
                            <a
                                href={`/articles?${data[1].contentId}#${data[1].id}`}
                                // href={"/articles"}
                                //     onClick={() => {
                                //         // this.props.history.push("/articles", { data: item })
                                //         localStorage.setItem("data", JSON.stringify(data[1]))
                                //     }}
                                className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                                <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                            </a>
                        </div>
                    }
                    {
                        data[0] && <div data-w-id="90ffe15e-bb27-39b0-7938-31f58524d36a" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-_1">
                            <h2 className="af-class-h2-section-heading af-class-_450">{data[0].title}</h2>
                            <a
                                href={`/articles?${data[0].contentId}#${data[0].id}`}
                                // href={"/articles"}
                                //     onClick={() => {
                                //         // this.props.history.push("/articles", { data: item })
                                //         localStorage.setItem("data", JSON.stringify(data[0]))
                                //     }} 
                                className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                                <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ImgSlider;