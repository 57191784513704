export const FileContentMachineNamesCatalog = ["field_upload_file",
    "field_video",
    "field_pireports",
    "field_speech1",
    "field_speech2",
    "field_creditrating",
    "field_file",
    "field_file_",
    "field_file_buyback",
    "field_file_dailyreporting",
    "field_file_governance",
    "field_file_governancelist",
    "field_file_governancereports",
    "field_file_moa",
    "field_file_other_stock_exchange_",
    "field_file_quarterly",
    "field_file_secfillings",
    "field_file_shares",
    "field_file_unpaid",
    "field_file_view",
    "field_flile",
    "field_governanceview"];

export const newsArticleContentNames = {
    //1 for both landing news and covid news
    1: {
        name: 'landing_page_news',
        addOnToUrl: '',
        makeUpData: [
            { key: 'title', value: 'attributes.title' },
            { key: 'content', value: 'attributes.field_content.processed' },
            { key: 'sourceName', value: 'attributes.field_source_name' },
            { key: 'publishDate', value: 'attributes.field_date_and_time' },
            { key: 'sourceUrl', value: 'attributes.field_source_url.uri' },
            { key: 'yearCategory', value: 'attributes.field_landing_news_year' },
            { key: 'description', value: 'attributes.field_short_description' },
        ]
    },
    2: {
        name: 'landingpagearticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' : '?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'thumbnail', value: 'data.attributes.title' },
            { key: 'description', value: 'data.attributes.field_description.processed' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
            { key: 'synopsis', value: 'data.attributes.field_synopsis.processed' },
        ]
    },
    3: {
        name: 'aboutusarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' : '?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'thumbnail', value: 'data.attributes.title' },
            { key: 'description', value: 'data.attributes.field_description.processed' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
        ]
    },
    4: {
        name: 'businessproductarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' : '?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'thumbnail', value: 'data.attributes.title' },
            { key: 'description', value: 'data.attributes.field_description.processed' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
            { key: 'synopsis', value: 'data.attributes.field_synopsis.processed' },
        ]
    },
    5: {
        name: 'impactarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' : '?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'thumbnail', value: 'data.attributes.title' },
            { key: 'description', value: 'data.attributes.field_description.processed' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
            { key: 'externalLink', value: 'data.attributes.field_external_link.uri' },
        ]
    },
    6: {
        name: 'peoplestoriesarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' : '?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'thumbnail', value: 'data.attributes.title' },
            { key: 'description', value: 'data.attributes.field_description.processed' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
            { key: 'synopsis', value: 'data.attributes.field_synopsis.processed' },
        ]
    },
    7: {
        name: 'lifeatdrlcarousalarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' :'?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
        ]
    },
    8: {
        name: 'covid_news',
        addOnToUrl: '',
        makeUpData: [
            { key: 'title', value: 'attributes.title' },
            { key: 'content', value: 'attributes.field_content.processed' },
            { key: 'sourceName', value: 'attributes.field_source_name' },
            { key: 'publishDate', value: 'attributes.field_date_and_time' },
            { key: 'sourceUrl', value: 'attributes.field_source_url.uri' },
            { key: 'yearCategory', value: 'attributes.field_landing_news_year' },
            { key: 'description', value: 'attributes.field_short_description' },
        ]
    },
    9: {
        name: 'lifeatdrlarticles',
        addOnToUrl: window.matchMedia('(max-width: 820px)').matches ? '?include=field_image' :'?include=field_coverimage',
        makeUpData: [
            { key: 'title', value: 'data.attributes.title' },
            { key: 'coverImage', value: 'included[0].attributes.uri.url' },
            { key: 'content', value: 'data.attributes.field_content.processed' },
        ]
    },
    10: {
        name: 'boardofdirectors',
        addOnToUrl: '?include=field_image',
        makeUpData: [
            { key: 'name', value: 'data.attributes.title' },
            { key: 'field_title', value: 'data.attributes.field_title' },
            { key: 'bio', value: 'data.attributes.field_bio.processed' },
            { key: 'image', value: 'included[0].attributes.uri.url' },
        ]
    },
}

export const nodeNamesToContentIds = {
    "node--landing_page_news": { id: 1, navPath: '/detail-news' },
    "node--landingpagearticles": { id: 2, navPath: '/articles' },
    "node--aboutusarticles": { id: 3, navPath: '/articles' },
    "node--businessproductarticles": { id: 4, navPath: '/articles' },
    "node--impactarticles": { id: 5, navPath: '/articles' },
    "node--peoplestoriesarticles": { id: 6, navPath: '/articles' },
    "node--lifeatdrlcarousalarticles": { id: 7, navPath: '/articles' },
    "node--covid_news": { id: 8, navPath: '/detail-news' },
    "node--lifeatdrlarticles": { id: 9, navPath: '/articles' },
    "node--boardofdirectors": { id: 10, navPath: '/Board-of-directors' },
}