/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import { getNewsArticleEndPoint, getMakeUpData,isTab,mediaQueryRangeTab } from '../Utils/Utils';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class DetailBoardOfDirectorsView extends React.Component {

  state = {
    renderPageBool: false,
    cookieBool: true,
    data: {},
    // data: localStorage.getItem("data") ? JSON.parse(localStorage.getItem("data")) : {},
    // data: this.props.location.state ? this.props.location.state.data : {},
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/DetailBoardOfDirectorsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = DetailBoardOfDirectorsView

        return Controller
      }

      throw e
    }
  }

  async componentWillMount() {
    console.log(this.props.location, "-=-=-=-")
    const { hash, search } = this.props.location;
    var tempObj = await getNewsArticleEndPoint(hash, search);
    axios({ method: "get", url: config.serverUrl.base + tempObj.url, })
      .then((res) => {
        var tempData = { ...res.data };
        this.setState({ data: getMakeUpData(tempData, tempObj.makeUpData) });
      })
      .catch((err) => {
        console.log(err, "-=-=-=  ERR")
      })
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e2655a1e1fde15'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)
        if (active.lastOne) {
          this.setState({ renderPageBool: true })
        }
        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = DetailBoardOfDirectorsView.Controller !== DetailBoardOfDirectorsView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    const imageUrl = config.serverUrl.imageUrlPrefix;
    var content = this.state.data.bio ? this.state.data.bio.split(imageUrl).join(config.serverUrl.imageBase + imageUrl) : "";

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span className="af-view">
          <div>
            <MenuSection backButton={true} />
            <div id="Founder-Section" className="af-class-section af-class-article-section af-class-wf-section" style={{paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '180px'}}>
              <div className="af-class-container w-container">
                <div className="af-class-bio-wrapper"><img style={{ marginLeft:isTab ? '0px' : '8px',marginBottom:isTab && 'unset' }} src={this.state.data.image ? config.serverUrl.imageBase + this.state.data.image : null} loading="lazy" alt className="af-class-bio-thumbnail" />
                  <div style={{ maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : '50%' }} >
                    <h2 className="af-class-h2-section-heading" style={{marginTop:isTab && '20px',fontSize:isTab && '36px',lineHeight:isTab && '33px',marginBottom: isTab && '15px' }} >{this.state.data.name}</h2>
                    {
                      isTab
                      ?
                    <h3 style={{fontSize:'20px',margin:0,lineHeight:'24px',fontWeight:'bold',marginBottom:'25px'}} >{this.state.data.field_title}</h3>
                    :
                    <h3  >{this.state.data.field_title}</h3>
                    }
                    <div className="af-class-rich-text-block af-class-bio w-richtext" style={{ fontSize: isTab && '18px',lineHeight: isTab && '32px',textAlign:isTab && 'initial' }} dangerouslySetInnerHTML={{
                      __html: content
                    }} />
                  </div>
                </div>
              </div>
              <a href="#Founder-Section" data-w-id="cecbbe3d-f864-2d13-181a-b62fc6f6611a" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(DetailBoardOfDirectorsView)

/* eslint-enable */