import React, { useRef } from 'react';

import { isTab } from '../../Utils/Utils';

const HeroBannder = () => {

    let mask = useRef(null);

    var timer, resizeTimer;

    const markResize = () => {
        resizeTimer = setInterval(() => {
            if (mask.current.clientWidth > 250) {
                let mask_dimentions = mask.current.clientWidth - 5;
                mask.current.style.width = `${mask_dimentions}px`;
                mask.current.style.height = `${mask_dimentions}px`;
            }
            else {
                clearInterval(resizeTimer);
            }
        }, 20);
    }

    return (
        // <div data-w-id="d68d44e0-b690-6939-01bc-5295fef1e9d4" className="af-class-about-hero-circle-wrapper af-class-life-at-drl">
        //     <div href="#about-us-main" className="af-class-hero-circle" ref={mask} />
        //     <div className="af-class-section af-class-hero-about-us">
        //         <div className="af-class-container w-container">
        //             <div className="af-class-h1-block af-class-center af-class-bigger">
        //                 <h1 data-w-id="f599b3a8-960c-f4a1-8f51-0c041806c009" style={{ WebkitTransform: 'translate3d(0, 160px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 160px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 160px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 160px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="af-class-h1-heading af-class-center af-class-font-80">Our Culture</h1>
        //             </div>
        //         </div>
        //         <a href="#life-at-drl-hero" className="af-class-fake-click w-inline-block"
        //             onMouseMove={() => {
        //                 clearTimeout(timer);
        //                 if (mask.current.clientWidth < 300) {
        //                     setTimeout(() => {
        //                         let mask_dimentions = mask.current.clientWidth + 5;
        //                         mask.current.style.width = `${mask_dimentions}px`;
        //                         mask.current.style.height = `${mask_dimentions}px`;
        //                     }, 200);
        //                 }
        //                 timer = setTimeout(() => markResize(), 300);
        //             }}
        //             onMouseOut={() => {
        //                 mask.current.style.width = "250px";
        //                 mask.current.style.height = "250px";
        //             }}
        //         />
        //     </div>
        <div id="our-culture" className="af-class-about-hero-circle-wrapper-life-at-drl" style={{ backgroundImage: window.matchMedia('(max-width: 820px)').matches ? 'url("/images/Mask_Group_296_Mobile.png")' :'url("/images/Mask_Group_5.png")', cursor: 'auto',height:isTab && '100vh' }} >
            <div id="life-at-drl-hero" className="af-class-section af-class-life-at-drl-hero" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} >
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container" style={{ marginTop: !window.matchMedia('(max-width: 820px)').matches && '120px'}}>
                    <div className={!window.matchMedia('(max-width: 820px)').matches && "w-layout-grid af-class-_2-col-grid af-class-_40px-gap"} style={{gridRowGap:window.matchMedia('(max-width: 820px)').matches && '24px'}} >
                        <h2 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className="af-class-h2-section-heading af-class-title af-class-negative-margin" style={{ fontSize: window.matchMedia('(max-width: 820px)').matches ? isTab ? '46px' : '36px' : '60px',margin:window.matchMedia('(max-width: 820px)').matches && '0px',lineHeight:window.matchMedia('(max-width: 820px)').matches && '48px',marginBottom: isTab && '20px' }} >Our Culture</h2>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                if(isTab){
                                el.style.setProperty('font-size', '20px', 'important');
                                el.style.setProperty('line-height', '36px', 'important');
                                } else {
                                el.style.setProperty('line-height', '28px', 'important');
                                }
                            }
                        }} id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e5-bdd5ac66" style={{ fontSize: window.matchMedia('(max-width: 820px)').matches ? '16px' : '20px' }} >Ask any of the 24,000+ employees at Dr. Reddy’s why they come to work every day and they’ll say, because Good Health Can’t Wait. This is our credo as well as the guiding principle behind all our actions. We see healthcare solutions not only as scientific formulations, but as a means to help patients lead healthier lives, and we’re always attuned to the new and the next to empower people to stay fit. And to do this, we foster a culture of empathy and dynamism.</p>
                    </div>
                </div>
            </div>
        </div>
        // </div>




        
    )
}

export default HeroBannder;
