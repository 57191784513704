import React from 'react';

const ImageTextSlider = ({ }) => {
    return (
        <div className="af-class-delivering-health-wrapper">
            <div className="af-class-scroll-image-stack-block">
                <div className="af-class-stacked-images-wrapper">
                    {/* <img src="/images/nikhita-s-NsPDiPFTp4c-unsplash.png" loading="lazy" alt className="af-class-stack_image af-class-_4" />
                    <img src="/images/nikhita-s-NsPDiPFTp4c-unsplash.png" loading="lazy" alt className="af-class-stack_image af-class-_3" /> */}
                    <img src="/images/syna-tiger-resort-Ed5aHMSzs-c-unsplash.png" loading="lazy" alt data-w-id="f887d200-5ac6-9488-999b-a054378cd6b0" className="af-class-stack_image af-class-_2" style={{position: 'relative'}} />
                    <img src="/images/gyan-shahane-3C7zhY3gcQc-unsplash.png" loading="lazy" style={{ opacity: 0 }} data-w-id="f887d200-5ac6-9488-999a-a054378cd6b0" alt className="af-class-stack_image af-class-_1" />
                    <div className="af-class-stickey-video-text-overlay">                    
                    {/* <div className="af-class-scroll-image-text-block"> */}
                        <div className="af-class-stacked-images-text-wrapper" style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}>                       
                        <h2 data-w-id="f887d200-5ac6-9488-999c-a054378cd6b0" style={{position: 'absolute'}} className="af-class-h2-section-heading af-class-title af-class-stack_text af-class-_1">Environmental Sustainability</h2>
                        <h2 data-w-id="f887d200-5ac6-9488-999d-a054378cd6b0" style={{position: 'absolute', opacity: 0}} className="af-class-h2-section-heading  af-class-title af-class-stack_text af-class-_2">Environmental Sustainability</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="af-class-scroll-text-wrapper">
                <div className="w-dyn-list">

                    <div role="list" className="w-dyn-items">

                        <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Action for Climate and Environment (ACE)</h2>
                                        <p className="af-class-paragraph" >Our ACE programme focuses on preserving the livelihoods of small and marginal farmers from the effects of climate change by encouraging them to adopt climate-friendly technologies and farming practices. ACE promotes and implements practices that focus on sustainably increasing agricultural productivity and incomes; lowering and/or removing greenhouse gas emissions; and adapting and building resilience to climate change through leading farmer platforms. This project is implemented in partnership with the Dr. Reddy’s Foundation.</p>
                                        {/* <a href={"/articles"}
                                            onClick={() => {
                                                localStorage.setItem("data", JSON.stringify(item))
                                            }} className="af-class-link-arrow af-class-left-side w-inline-block">
                                            <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Action for Climate and Environment (ACE)</h2>
                                        <p className="af-class-paragraph" >Our ACE programme focuses on preserving the livelihoods of small and marginal farmers from the effects of climate change by encouraging them to adopt climate-friendly technologies and farming practices. ACE promotes and implements practices that focus on sustainably increasing agricultural productivity and incomes; lowering and/or removing greenhouse gas emissions; and adapting and building resilience to climate change through lead farmer platforms. This project is implemented in partnership with the Dr. Reddy’s Foundation.</p>
                                        
                                        
                                        <a href={"/articles"}
                                            onClick={() => {
                                                localStorage.setItem("data", JSON.stringify(item))
                                            }} className="af-class-link-arrow af-class-left-side w-inline-block">
                                            <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Dr K. Anji Reddy’s Spirit of Giving</h2>
                                        <p className="af-class-paragraph" >The anniversary of our founder’s passing is commemorated as Dr. K. Anji Reddy’s Spirit of Giving. Our employees across the world volunteer and contribute their time and effort to make a difference to their communities. Helping school students, spending time with senior citizens, bringing cheer to orphans, spreading awareness about traffic rules, working to restore a cyclone-ravaged zoo — the causes are many and diverse, but the commitment is the same.</p>
                                        <a href={"/articles"}
                                            onClick={() => {
                                                localStorage.setItem("data", JSON.stringify(item))
                                            }} className="af-class-link-arrow af-class-left-side w-inline-block">
                                            <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div role="listitem" className="w-dyn-item">
                            <div className="af-class-scroll-text-block">
                                <div className="af-class-scroll-text-div">
                                    <div className="af-class-scroll-animation-box">
                                        <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" >Conserving Tigers in the Eastern Ghats</h2>
                                        <p className="af-class-paragraph" >The project aims at conserving tigers and their habitats in the Nagarjunasagar Srisailam Tiger Reserve and Amrabad Tiger Reserve through an integrated stakeholder approach. It helps staff elevate management and effectiveness within the reserves, increase knowledge on the use of key corridors by tigers and other wildlife, and promotes sustainable practices among local communities in select villages in the corridor areas.</p>
                                        {/* <a href={"/articles"}
                                            onClick={() => {
                                                localStorage.setItem("data", JSON.stringify(item))
                                            }} className="af-class-link-arrow af-class-left-side w-inline-block">
                                            <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="af-class-drag-slider-wrapper af-class-mobile-only" style={{paddingBottom:'0px'}} >
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6c7" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    {/* <div className="af-class-delivering-health-slider-heading">Pharmaceutical Services and Active Ingredients</div> */}
                                    <h4 className="af-class-heading-4-bold">Action for Climate and Environment (ACE)</h4>
                                    <div>Our ACE programme focuses on preserving the livelihoods of small and marginal farmers from the effects of climate change by encouraging them to adopt climate-friendly technologies and farming practices. ACE promotes and implements practices that focus on sustainably increasing agricultural productivity and incomes; lowering and/or removing greenhouse gas emissions; and adapting and building resilience to climate change through leading farmer platforms. This project is implemented in partnership with the Dr. Reddy’s Foundation.</div>
                                    {/* <a href="https://api.drreddys.com/" className="af-class-link-arrow af-class-left-side w-inline-block">
                                        <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                    </a> */}
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6d3" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <h4 className="af-class-heading-4-bold">Dr K. Anji Reddy’s Spirit of Giving</h4>
                                    <div>The anniversary of our founder’s passing is commemorated as Dr. K. Anji Reddy’s Spirit of Giving. Our employees across the world volunteer and contribute their time and effort to make a difference to their communities. Helping school students, spending time with senior citizens, bringing cheer to orphans, spreading awareness about traffic rules, working to restore a cyclone-ravaged zoo — the causes are many and diverse, but the commitment is the same.</div>
                                </div>
                            </div>
                            {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6da" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Global Generics<br /></div>
                                    <div>Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.<br /></div>
                                </div>
                            </div> */}
                            {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6e2" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Proprietary Products<br /></div>
                                    <div>Patients today are looking for more than just safe, effective medicines. They require information, coaching, and customized support to help them adhere to complex treatment protocols.<br /><br />Our Proprietary Products division aims to fulfil these unmet patient needs. In some cases, this involves developing a new dosage of a current drug. In other cases, it may involve developing new combinations of synergistic medications. The focus is on initiatives to improve patient experience with our products. Better experience results in better compliance, which means better health and outcomes for patients.<br /></div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6ed" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f1" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f3" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" /> */}
                    </div>
                </div>
            </div>
            
            {/* <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                <div className="af-class-drag-progress-fill af-class-fifty" />
            </div> */}
        </div>
    )
}

export default ImageTextSlider;