import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import BackIcon from '../../Common/images/backArrow.svg';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px !important;
    color: #000000 !important;
    font-family: Drlcircular !important;
    font-weight: 300 !important;
    margin-top: 60px !important;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        margin-top: 30px !important;
        margin-bottom: 15px !important;
        font-size: 24px !important;
    }
`;

const TextSection = styled.p`
    width: 90%!important;
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 300!important;
    margin-top: 40px !important;
    line-height: 1.8!important;
    @media (max-width: 900px) {
        margin-top: 24px !important;
        width: 100%!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
`;

const TextBold = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;

export function MainSection(props) {
    return <div className="af-class-section" style={{paddingTop: '180px'}}>
        <div className="af-class-container">
            <MainContainer>
                <BodySection>
                    <Heading>Accessibility</Heading>
                    <HR />
                    <TextSection>
                        Dr. Reddy’s is committed to ensure that the content on this website is accessible to the widest range of visitors, regardless of disability. <br /><br />
                        <TextBold>Access Keys </TextBold>
                        Access keys are keyboard shortcuts that provide quick links to common website features, without the need to use a mouse. <br /><br />
                        <TextBold>Magnifying Content </TextBold>
                        To magnify content on any given page please click the zoom options given on each page with a ‘+’ symbol or to zoom out the ‘-‘ symbol. To return to the original font size click on the ‘Text Resize’ option. <br /><br />
                        <TextBold>Images </TextBold>
                        All images on this site are provided with descriptive ALT attributes. <br /><br />
                        <TextBold>Navigation </TextBold>
                        All pages contain a link to the home page (access key 1), sitemap (access key 3) & search box (access key 4).The Information architecture implemented here has been logically constructed in a consistent fashion to provide clear navigation pathways throughout the website. <br /><br />
                        <TextBold>Sitemap </TextBold>
                        This website is facilitated with a detailed sitemap for easier navigation. Each of the pages are hyperlinked. <br /><br />
                        <TextBold>PDFs </TextBold>
                        In many cases we have provided an HTML version of PDFs on this website, however if you would like to use a screen reader to read PDF documents you can find out more at the Adobe access website.<br /><br />
                    </TextSection>
                </BodySection>
            </MainContainer>
        </div>
    </div>
}