/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class UnfinishedAgendaView extends React.Component {
  state = {
    renderPageBool: false,
    cookieBool: true,
  }
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/UnfinishedAgendaController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = UnfinishedAgendaView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e265c4771fde19'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true })
      }
      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = UnfinishedAgendaView.Controller !== UnfinishedAgendaView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span id="backToTop" className="af-view">
          <div>
            <MenuSection className="af-class-transparent" logoColor="white" backButton />
            <div className="af-class-section af-class-unfinished-agenda">
              <div className="af-class-container w-container">
                <div className="af-class-text-wrapper-675-width">
                  <h1 className="af-class-h1-article">An Unfinished Agenda</h1>
                  <p className="af-class-heading-lead-text">My Life in the Pharmaceutical Industry</p>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-article-section">
              <div className="af-class-container af-class-article w-container">
                <div className="af-class-rich-text-block w-richtext">
                  <p>Dr Anji Reddy became an entrepreneur at a time when India was woefully short of technology to manufacture many basic medicines. Then, in barely three decades, the Indian pharmaceutical industry had grown to the point that India not only became self-sufficient in medicine, but also a supplier of affordable generic medicines to the world. Dr Anji Reddy provides a ringside view of this remarkable transformation, with fascinating anecdotes about those who made it happen.The history of modern medicine is a gripping story of triumphs and failures. An Unfinished Agenda takes the reader on a whirlwind tour of the science of medicine over the last hundred years and reminds us of the stark challenges that remain.</p>
                </div>
                <div className="af-class-buy-book-wrapper">
                  <div>Buy the book</div>
                  <a href="https://www.amazon.in/An-Unfinished-Agenda-Pharmaceuticals-Industry/dp/0670087807" target="_blank" className="af-class-book-link w-inline-block"><img src="images/amazon-icon-1_1amazon-icon-1.png" loading="lazy" alt /></a>
                  <a href="https://www.flipkart.com/unfinished-agenda-my-life-pharmaceuticals-industry/p/itme34fzcrcgkbkf?pid=9780670087808&srno=b_3&ref=e2e4cc7a-0c9b-44bc-b1f7-07892020991b" target="_blank" className="af-class-book-link w-inline-block"><img src="images/flipkart_1flipkart.png" loading="lazy" alt /></a>
                </div>
                <h3 className="af-class-h3-heading">REVIEWS</h3>
                <div className="af-class-book-review-block">
                  <p>“The book has the feel of a scientist’s personal diary, rather than that of a slick business autobiography …..but that is perhaps its charm”</p>
                  <div className="af-class-reviewer-name">Anvar Alikhan</div>
                  <a href="https://magazine.outlookindia.com/story/a-glitazar-in-the-sky/293395" target="_blank" className="af-class-review-link">Outlook India</a>
                </div>
                <div className="af-class-book-review-block">
                  <p>”…. written with rare candour…… the book, peppered with anecdotes and medicinal history, shows that the pharmaceutical world is not just about chemical equations, and reading about it can be fun. An Unfinished Agenda should be compulsory reading for all those who want to know how India became a supplier of inexpensive medicine to the whole world.”</p>
                  <div className="af-class-reviewer-name">Bhupesh Bhandari</div>
                  <a href="https://www.business-standard.com/article/opinion/medicine-man-115021001663_1.html" target="_blank" className="af-class-review-link">Business Standard</a>
                </div>
                <div className="af-class-book-review-block">
                  <p>“Despite frequent deviations in narration, Reddy’s memoir never plods with monstrous monotony. Instead, it retains a sort of welcome restraint in storytelling that is both intimate and detached.”</p>
                  <a href="https://www.financialexpress.com/" target="_blank" className="af-class-review-link">Financial Express</a>
                </div>
                <div className="af-class-book-review-block">
                  <p>“…the story of Dr Reddy’s life is interspersed with little known facts and anecdotes about other leaders in the global pharmaceutical world …. The book is not only mandatory reading for those in the pharmaceutical industry, it also offers wisdom and valuable lessons to those pursuing other professions.”</p>
                  <div className="af-class-reviewer-name">Gina S. Krishnan</div>
                  <a href="http://www.businessworld.in/news/economy/chance-and-choice/1829324/page-1.html" target="_blank" className="af-class-review-link">Business World</a>
                </div>
                <div className="af-class-book-review-block">
                  <p>This autobiography of Dr. Anji Reddy tells the personal story of one of India’s most important scientific and business leaders during the second half of the 20th century. For those interested in the relationship between public health and industrial policy, it provides valuable insight into the development path of the pharmaceutical industry in India, and how India emerged as the pharmacy of the developing world. Reflecting on committing R&amp;D funding to the pursuit of new drugs, Dr. Reddy stresses the central importance of making sure that whatever is developed is made available at prices the Indian public can afford. It would be good to think that his perspective might influence other leaders in the global pharmaceutical industry. In all events, very worthwhile both for appreciating Dr. Reddy’s individual achievements, and for understanding the Indian pharmaceutical industry. The author paid excellent attention to technological detail without compromising the readability of the work.</p>
                  <div className="af-class-reviewer-name">Frederick M. Abbott</div>
                </div>
                <div className="af-class-book-review-block">
                  <p>There are a number of lessons that one can draw from what the great man had to say on the subject of corporate social responsibility.</p>
                  <div className="af-class-reviewer-name">Mudar Patherya</div>
                  <a href="https://mumbaimirror.indiatimes.com/opinion/columnists/mudar-patherya/not-just-another-day-in-paradise/articleshow/46658403.cms?" target="_blank" className="af-class-review-link">Mumbai Mirror</a>
                </div>
                <div className="af-class-book-review-block">
                  <p>“But what perhaps comes out as the best part of the book is that it brings out the true researcher that Dr. Anji Reddy was.”</p>
                  <div className="af-class-reviewer-name">Vikas Dandekar</div>
                  <a href="https://www.biomedtracker.com/" target="_blank" className="af-class-review-link">Pharma &amp; Medtech Business Intelligence</a>
                </div>
              </div>
              <a href="#backToTop" data-w-id="24d4dc46-525c-b88f-a3c3-7ed3e0494709" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
            const { cookies } = this.props;
            cookies.set('EnableCookie', JSON.stringify(data));
          }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(UnfinishedAgendaView)

/* eslint-enable */