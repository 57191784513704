import styled from "styled-components";

const SubContainer = styled.div`
  width: 100%;
  margin-top:40px;
`;

const Header = styled.h2`
  margin: 0 !important ;
  color: #000000 !important ;
  font-size: 18px !important ;
  font-family: Drlcircular !important ;
  font-weight: bold !important ;
  line-height: 1 !important ;
  margin-bottom: 30px !important ;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    font-weight: 300 !important ;
    margin-bottom: 15px !important ;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    overflow: auto;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

export const Compliants = () => {
 
    return(
        <SubContainer>
            <Header>For any complaints or grievances Investors can contact at:</Header>
            <TableContainer>
                <table>
                    <tr>
                        <td style={{ padding: '20px 15px', }}>
                            Registrar and Share Transfer Agent (RTA)
                        </td>
                        <td style={{ padding: '20px 15px', }}>
                            <strong>Bigshare Services Private Limited</strong> <br />
                            <strong>Address:</strong>
                            <p>
                            Unit – Dr. Reddy’s Laboratories Limited <br />
                            306, Right Wing, 3rd Floor, Amrutha Ville,,<br />
                            Opp. Yashoda Hospital, Somajiguda, Rajbhavan Road,<br />
                            Hyderabad, Telangana – 500 082, India<br />
                            Contact Person: Mr. Prabhakar S.D <br />
                            Tel.: +91-40-4014 4967/ 2337 0295/ 4014 4582 <br />
                            Email:  <a href="mailto:bsshyd@bigshareonline.com">bsshyd@bigshareonline.com</a> <br />
                            Website: <a href="https://www.bigshareonline.com/Index.aspx">https://www.bigshareonline.com/Index.aspx</a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '20px 15px', }}>
                        Dr. Reddy’s Corporate Secretarial Department
                        </td>
                        <td style={{ padding: '20px 15px', }}>
                            <strong>Address:</strong>
                            <p>
                                Dr. Reddy’s Laboratories Limited <br />
                                8-2-337, Road No.3 Banjara Hills <br />
                                Hyderabad, Telangana – 500034, India <br />
                                Tel.: +91-40-49002900 <br />
                                Email: <a href="mailto:shares@drreddys.com"> shares@drreddys.com</a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '20px 15px', }}>
                        Exchanges
                        </td>
                        <td style={{ padding: '20px 15px', }}>
                        In case the Complaint/ grievance stands unresolved / not resolved up to the satisfaction of Investor(s), may lodge a complaint with: <br /> <br />
                        <strong>NSE:</strong> <a href="https://investorhelpline.nseindia.com/NICEPLUS/">https://investorhelpline.nseindia.com/NICEPLUS/</a> <br />
                        <strong>BSE:</strong> <a href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx">https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx</a>
                        </td>
                    </tr>
                </table>
            </TableContainer>
            <br />
            <Header>Online Dispute Resolution Portal</Header>
            <TableContainer>
                <table>
                <tr>
                        <td style={{ padding: '20px 15px', }}>
                        SEBI
                        </td>
                        <td style={{ padding: '20px 15px', }}>
                        In case the Complaint/ grievance stands unresolved / not resolved up to the satisfaction of Investor(s), may lodge a complaint with: <br /> <br />
                        <strong>1.SEBI SCORES Portal: </strong> <a href="https://scores.gov.in/scores/Welcome.html">https://scores.gov.in/scores/Welcome.html</a> <br />
                        <strong>2. Online Dispute Resolution (Smart ODR) Platform:</strong> <a href="https://smartodr.in/login">https://smartodr.in/login</a>
                        </td>
                    </tr>
                </table>
            </TableContainer>
        </SubContainer>
    );
}