import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { ProspectivePartnersView } from './MainSection/prospectivePartner';
import { PostAnEnquiryView } from './MainSection/postAnEnquiry';
import { LoginForCurrentPartnerView } from './MainSection/loginForCurrentPartner';

import Utils from '../../Utils/Utils'; 

import rightArrowPrimary from '../../assets/rightArrowPrimary.svg';

import { HR } from "../common/horizontalLine";

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
  /* padding: 20px 8%; */
`;

const Title = styled.h2`
  font-size: 22px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabItem = styled.a`
  cursor: pointer;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#000000' : '#909090')};
  text-decoration:none !important;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    opacity: 1;
    text-decoration: none;
      color: inherit;
  }
  &:focus {
    text-decoration: none;
      color: inherit;
  }
  &:active {
    text-decoration: none;
      color: inherit;
  }
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const HambergerButton = styled.a`
  /* using following code for increasing clickable area */
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 2em;
  margin: -2em;
  cursor: pointer;
`;

const HambergerIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  display: block;
  background-color: #ededed;
  top: 0;
  left: 0;
  padding-top: 20%;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  margin-top: 10px;
  &::before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(-45deg);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TabMenuItem = styled.a`
  cursor: pointer;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  padding-bottom: 5px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;


export function SubMenuSection(props) {
  const [openMenuBool, setOpenMenuBool] = useState(false);
  const isTabsEnable = useMediaQuery({ query: "(min-device-width: 1000px)" });

  const renderMainComponents = () => {
    switch (props.state.selectedTab.id) {
      case 1:
        return <ProspectivePartnersView state={props.state} updateState={props.updateState} />;
      case 2:
        return <PostAnEnquiryView state={props.state} updateState={props.updateState} />;
      case 3:
        return <LoginForCurrentPartnerView state={props.state} updateState={props.updateState} />;
      default:
        return <p>{props.state.selectedTab.name}</p>;
    }
  }

  return (
    <>
      <div className="af-class-section" style={{ backgroundColor: props.state.selectedTab.bgColor }} >
        <div className="af-class-container">
        {!window.matchMedia('(max-width: 820px)').matches && <MainContainer isTabsEnable={isTabsEnable}>
            <TabsSection>
              {isTabsEnable &&
                props.tabData.map((item, index) => {
                  return (
                    <TabItem
                      href={"#" + item.hashValue}
                      key={"Rendering_Tab_BusinessPartners_" + item.name + index}
                      onClick={() => {
                        props.updateState({ selectedTab: item });
                      }}
                      selected={props.state.selectedTab.name === item.name}
                    >
                      {item.name.toProperCase()}
                    </TabItem>
                  );
                })}
              {!isTabsEnable && (
                <HambergerButton
                  onClick={() => {
                    setOpenMenuBool(!openMenuBool);
                  }}
                >
                  <HambergerIcon />
                </HambergerButton>
              )}
            </TabsSection>
          </MainContainer>}

          {!window.matchMedia('(max-width: 820px)').matches && <HR />}

          {
            window.matchMedia('(max-width: 820px)').matches && props.tabData.map((item, index) => {
              return (
                <div key={"MenuListView:"+index} >
                  <a style={{ textDecoration: 'none',color:'#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} href={"/detail-sub-menu#"+item.hashValue} >
                    <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name.toProperCase()}</p>
                    <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                  </a>
                  <HR />
                </div>
              )
            })
          }

          { !window.matchMedia('(max-width: 820px)').matches && renderMainComponents()}

        </div>
      </div>
      {openMenuBool && (
        <MenuContainer>
          <CloseButton
            onClick={() => {
              setOpenMenuBool(false);
            }}
          />
          <MenuItemContainer>
            {props.tabData.map((item, index) => {
              return (
                <TabMenuItem
                  key={
                    "Rendering_TabMenuItem_BusinessPartners_" +
                    item.name +
                    index
                  }
                  onClick={() => {
                    props.updateState({ selectedTab: item });
                    setOpenMenuBool(false);
                  }}
                  selected={props.state.selectedTab.name === item.name}
                >
                  {item.name}
                </TabMenuItem>
              );
            })}
          </MenuItemContainer>
        </MenuContainer>
      )}
    </>
  );
}
