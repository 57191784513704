import React from "react";
import styled from "styled-components";

import { HR } from "../../common/horizontalLine";
import { MedicalInformationTable } from "../../utils/staticContent";

const MainContainer = styled.div`
  margin-bottom: 200px;
`;

const Heading = styled.h2`
  margin: 0!important;
  color: #000000!important;
  font-family: Drlcircular!important;
  font-weight: 300!important;
  line-height: 1!important;
  font-size: 40px!important;
  margin-bottom: 35px!important;
  @media (max-width: 900px) {
    line-height: 24px!important;
    font-size: 24px!important;
    margin-bottom: 20px!important;
  }
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 95%;
`;

const SubHeading = styled.p`
  color: #000000!important;
  font-weight: 700 !important;
  font-family: Drlcircular;
  font-size: 18px !important;
  margin: 40px 0 !important;
  line-height: 1.6 !important;
  @media (max-width: 900px) {
    font-size: 14px !important;
    margin: 20px 0 !important;
    line-height: 24px !important;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    overflow-x: auto;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      padding: 20px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-weight: 500;
      font-size: 18px;
      text-align: left;
      line-height: 1.5;
      :last-child {
        border-right: 0;
      }
      @media (max-width: 900px) {
        font-size: 14px;
        line-height:24px;
      }
    }

    th {
      border-bottom: 1px solid black;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      padding: 20px;
      @media (max-width: 900px) {
        font-size: 14px;
        line-height:24px;
      }
    }
  }
`;

export function MedicalInformationView(props) {
  return (
    <MainContainer>
      <SubContainer>
        <SubHeading>
          For adverse event reporting and medical information on our products and services, please contact us on below numbers/emails.
          { /*For product complaints or reporting on adverse events, please visit the relevant country site. */}
        </SubHeading>
        <TableContainer>
          <table>
            <tr>
              <th>Country</th>
              { /*<th>Name</th> */}
              <th>Email ID</th>
              <th>Phone</th>
            </tr>
            {MedicalInformationTable.map((item, index) => {
              return (
                <tr key={"RenderingTR_" + index}>
                  <td>{item.name}</td>
                  {/*<td>{item.contact}</td> */}
                  <td>{item.email == null ? '-' : item.email} </td>
                  <td>
                    {item.tel == null ? '-' : item.tel} {<br />} {item.timings}
                  </td>
                </tr>
              );
            })}
          </table>
        </TableContainer>
      </SubContainer>
    </MainContainer>
  );
}
