import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

export const ShareTransferInformation = (props) => {

  const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
  const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1x solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    table {
      border-spacing: 0;
      border: 0.1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 0.1;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 0.1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 0.1x solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 10% 15%;
`;
  const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

  const Header = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 570px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
`;

  const TextContent = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-left: 50px;
  margin-bottom: 25px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
`;
  const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 4px;
  margin-left: 10px;
  justify-content: space-between;
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
    flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  text-transform: uppercase;
  @media (max-width: 1180px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
  const content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right:150px;
  @media (max-width: 900px) {
    margin-right:0px;
    margin-bottom:20px;
  }
`;

  const AddressHeading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  font-weight: bold !important;
  line-height: 1 !important;
  text-transform: uppercase !important;
  margin-bottom: 20px !important;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

  const TextBook = styled.p`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  font-weight: 400 !important;
  line-height:30px !important;
  @media (max-width: 900px) {
    font-size: 14px !important;
    line-height:24px !important;
  }
`;

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  return (
    <AddressContainer>
      <AddressDiv>
        <AddressHeading>Share Transfer Agent</AddressHeading>
        <TextBook>
          Bigshare Services Pvt. Ltd.
          <br />
          306, Right Wing,
          <br /> Amrutha Ville,
          <br /> Opp. Yashoda Hospital,
          <br /> Rajbhavan Road,
          <br /> Hyderabad – 500 082
          <br /> Contact Person: <br />
          Mr. S D Prabhakar <br />
          Tel: +91 (40) 23374967 <br />
          Fax: +91 (40) 23370295 <br />
          Email: bsshyd@bigshareonline.com
          <br />
        </TextBook>
      </AddressDiv>
      <AddressDiv>
        <AddressHeading>Depositary for ADS</AddressHeading>
        <TextBook>
          JP Morgan Chase Bank NA <br /> 383 Madison Avenue, Floor <br />
          11 New York, New York 10179 <br />
          United States of America <br />
          Toll free number: Phone: +1 800 990 1135 <br />
          Hearing impaired: Phone: +1 866 700 1652
          <br /> Outside the U.S.: Phone: +1 651 453 2128 <br />
          Email: stockTransfer@equiniti.com
          <br />
        </TextBook>
      </AddressDiv>
    </AddressContainer>
  )

}