/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import axios from 'axios';
import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';
import styled from 'styled-components';

const scripts = [
  { loading: fetch("/webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("/webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

const TextHeading = styled.a`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-bottom:8px !important;
  line-height:30px !important;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
      color: #000;
      text-decoration: underline !important;
  }
`;

class MeetOurLeadershipView extends React.Component {

  state = {
    directorsData: {},
    renderPageBool: false,
    leadershipVids: [],
    cookieBool: true,
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/MeetOurLeadershipController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = MeetOurLeadershipView

        return Controller
      }

      throw e
    }
  }

  fetchDirectorData() {
    var tempVids = [];
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/leadershipvideos?sort=-field_date_for_order2", })
      .then((res) => {
        for (let item of res.data.data) {
          tempVids.push({
            title: item.attributes.title,
            sourceName: item.attributes.field_source_name,
            dateTime: item.attributes.field_date_and_time,
            externalLink: item.attributes.field_external_link.uri,
          })
        }
        this.setState({ leadershipVids: [...tempVids] })
      })
      .catch((err) => { console.log(err) })

    var result = {};
    var managementCouncils = [];
    var wholeTimeDirectors = [];
    var independentDirectors = [];
    var tempObj = {};
    // axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/boardofdirectors?include=field_image&sort=field_sequence_order_", })
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/boardofdirectors?include=field_image&sort=created", })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          var item = res.data.data[i];

          tempObj = {
            contentId: '10',
            id: item.id,
            name: item.attributes.title,
            slug: item.attributes.field_slug,
            field_title: item.attributes.field_title ? item.attributes.field_title : "",
            bio: item.attributes.field_bio.processed,
            image: config.serverUrl.imageBase + res.data.included[i].attributes.uri.url,
          };
          if (item.attributes.field_category === "Independent Directors") {
            independentDirectors.push(tempObj);
          } else if (item.attributes.field_category === "Management Council") {
            managementCouncils.push(tempObj);
          } else if (item.attributes.field_category === "Whole Time Directors") {
            wholeTimeDirectors.push(tempObj)
          }
        }
        result.wholeTimeDirs = [...wholeTimeDirectors];
        result.managementCouncils = [...managementCouncils];
        result.independentDirectors = [...independentDirectors];
        this.setState({ directorsData: { ...result } })
      }).catch((Err) => {
        console.log(Err, "-=-=-=  ERR")
      })
  }

  componentDidMount() {
    this.fetchDirectorData();
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e26553e11fde16'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true }, () => {
          // var navData = sessionStorage.getItem("navPath");
          // var tempNav = "";
          // if (navData) {
          //   tempNav = JSON.parse(navData).id;
          // }
          // console.log(this.props.location.hash && this.props.location.hash.length !== 0 ? this.props.location.hash.substring(1) : "");
          // scroller.scrollTo(tempNav, {
          //   duration: 1000,
          //   delay: 100,
          //   offset:-20,
          //   smooth: true,
          // });
          // sessionStorage.removeItem("navPath");
          // setTimeout(()=>{
          // //   // window.scrollTo({top: window.scrollY-150,left: 0,behavior: 'smooth'});
          // //   // window.scrollTo({top: window.scrollY+150,left: 0,behavior: 'smooth'});
          // },200)
        })
      }
      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = MeetOurLeadershipView.Controller !== MeetOurLeadershipView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span id="back-to-top" className="af-view">
          <div>
            <MenuSection backButton={true} onBackNavPath="/who-we-are#leadership" />
            <div className="af-class-section af-class-leadership-hero" style={{height:window.matchMedia('(max-width: 820px)').matches && 'auto', marginTop:window.matchMedia('(max-width: 820px)').matches && '40px'}} >
              <div className="af-class-container w-container">
                <div className="af-class-text-wrapper-675-width">
                  <h1 className="af-class-h1-heading af-class-small af-class-center" style={{ margin: 0, }}>Our Leadership</h1>
                  <p ref={(el) => {
              if (el && window.matchMedia('(max-width: 820px)').matches) {
                el.style.setProperty('line-height', '24px', 'important');
              }
            }} className="af-class-heading-lead-text" style={{ marginTop: window.matchMedia('(max-width: 820px)').matches ? '20px' : '30px',fontSize:window.matchMedia('(max-width: 820px)').matches && '14px' }} >Leaders who keep us on the path of success</p>
                </div>
              </div>
            </div>
            <div id="whole-time-directors" className="af-class-section af-class-board-directors af-class-wf-section">
              <div className="af-class-container w-container">
                <div className="af-class-directors-grid-wrapper">
                  <h1 className="af-class-h3-heading" style={{fontSize:window.matchMedia('(max-width: 820px)').matches && '16px',lineHeight: window.matchMedia('(max-width: 820px)').matches && '28px'}} >WHOLE TIME DIRECTORS</h1>
                  <div className="w-dyn-list">
                    {
                      this.state.directorsData.wholeTimeDirs && this.state.directorsData.wholeTimeDirs.length
                        ?
                        <div role="list" className="af-class-_4-col-grid af-class-board-of-directors w-dyn-items" style={{gridColumnGap:window.matchMedia('(max-width: 820px)').matches && '10px',gridRowGap:window.matchMedia('(max-width: 820px)').matches && '36px'}} >
                          {
                            this.state.directorsData.wholeTimeDirs.map((item, index) => {
                              return <div id={"whole-time-directors" + index} role="listitem" className="w-dyn-item">
                                <a
                                  href={`/Board-of-directors?${item.contentId}#${item.id}`}
                                  // onClick={() => {
                                  //   this.props.history.push("/Board-of-directors", { data: item })
                                  // }}
                                  onClick={() => {
                                    sessionStorage.setItem("navPath", JSON.stringify({ id: "whole-time-directors" + index }))
                                    // props.history.push(`/Board-of-directors?${item.contentId}#${item.id}`);
                                  }}
                                  className="af-class-directos-div w-inline-block">
                                  <div className="af-class-directors-thumbnail-div"><img src={item.image} loading="lazy" alt className="af-class-directors-image" /></div>
                                  <div className='af-class-director'>{item.name}</div>
                                </a>
                              </div>
                            })
                          }
                        </div>
                        :
                        <div className="w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                    }
                  </div>
                </div>
                <div id="independent-directors" className="af-class-directors-grid-wrapper">
                  <h1 className="af-class-h3-heading" style={{fontSize:window.matchMedia('(max-width: 820px)').matches && '16px',lineHeight: window.matchMedia('(max-width: 820px)').matches && '28px'}} >INDEPENDENT DIRECTORS</h1>
                  {/* <h1 className="af-class-h3-heading" style={{fontSize:window.matchMedia('(max-width: 820px)').matches && '16px',lineHeight: window.matchMedia('(max-width: 820px)').matches && '28px'}} >INDEPENDENT AND NON-WHOLE TIME DIRECTORS</h1> */}
                  <div className="w-dyn-list">
                    {
                      this.state.directorsData.independentDirectors && this.state.directorsData.independentDirectors.length
                        ?
                        <div role="list" className="af-class-_4-col-grid af-class-board-of-directors w-dyn-items" style={{gridColumnGap:window.matchMedia('(max-width: 820px)').matches && '10px',gridRowGap:window.matchMedia('(max-width: 820px)').matches && '36px'}} >
                          {
                            this.state.directorsData.independentDirectors.map((item, index) => {
                              return <div id={"independent-directors" + index} role="listitem" className="w-dyn-item">
                                <a
                                  href={`/Board-of-directors?${item.contentId}#${item.id}`}
                                  // onClick={() => {
                                  //   this.props.history.push("/Board-of-directors", { data: item })
                                  // }}
                                  onClick={() => {
                                    sessionStorage.setItem("navPath", JSON.stringify({ id: "independent-directors" + index }))
                                    // props.history.push(`/Board-of-directors?${item.contentId}#${item.id}`);
                                  }}
                                  className="af-class-directos-div w-inline-block">
                                  <div className="af-class-directors-thumbnail-div"><img src={item.image} loading="lazy" alt className="af-class-directors-image" /></div>
                                  <div />
                                  {item.name}
                                </a>
                              </div>
                            })
                          }
                        </div>
                        :
                        <div className="w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                    }
                  </div>
                </div>
                <div id="management-council" className="af-class-directors-grid-wrapper">
                  <h1 className="af-class-h3-heading" style={{fontSize:window.matchMedia('(max-width: 820px)').matches && '16px',lineHeight: window.matchMedia('(max-width: 820px)').matches && '28px'}} >MANAGEMENT COUNCIL</h1>
                  <div className="w-dyn-list">
                    {
                      this.state.directorsData.managementCouncils && this.state.directorsData.managementCouncils.length
                        ?
                        <div role="list" className="af-class-_4-col-grid af-class-board-of-directors w-dyn-items" style={{gridColumnGap:window.matchMedia('(max-width: 820px)').matches && '10px',gridRowGap:window.matchMedia('(max-width: 820px)').matches && '36px'}} >
                          {
                            this.state.directorsData.managementCouncils.map((item, index) => {
                              return <div id={"management-council" + index} role="listitem" className="w-dyn-item">
                                <a
                                  href={`/Board-of-directors?${item.contentId}#${item.id}`}
                                  // onClick={() => {
                                  //   this.props.history.push(`/Board-of-directors?${item.contentId}#${item.id}`)
                                  // }} 
                                  onClick={() => {
                                    sessionStorage.setItem("navPath", JSON.stringify({ id: "management-council" + index }))
                                    // props.history.push(`/Board-of-directors?${item.contentId}#${item.id}`);
                                  }}
                                  // onClick={() => {
                                  //   this.props.history.push("/Board-of-directors", { data: item })
                                  // }} 
                                  className="af-class-directos-div w-inline-block">
                                  <div className="af-class-directors-thumbnail-div"><img src={item.image} loading="lazy" alt className="af-class-directors-image" /></div>
                                  <div />
                                  {item.name}
                                </a>
                              </div>
                            })
                          }
                        </div>
                        :
                        <div className="w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {this.state.directorsData?.wholeTimeDirs && <div className="af-class-section" style={{ backgroundColor: "#EDEDED" }} >
              <div className="af-class-container w-container">

                <h2 className='af-class-h2-section-heading' style={{ fontWeight: '300', paddingBottom: '15px', borderBottomColor: '#FF5046', borderBottomWidth: '1px', borderBottomStyle: 'solid' }} >Leader Speak</h2>
                {
                  this.state.leadershipVids.map((item, index) => {
                    return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '15px 0', alignItems: 'center', borderBottomColor: '#FF5046', borderBottomWidth: '1px', borderBottomStyle: 'solid', }} >
                      <div style={{flex:1,marginRight:'15px'}} >
                        <TextHeading target="_blank" href={item.externalLink} style={{ fontSize: window.matchMedia('(max-width: 820px)').matches?'16px':'18px', fontWeight: '700' }} >{item.title}</TextHeading>
                        <p style={{ fontSize: window.matchMedia('(max-width: 820px)').matches?'14px':'18px', fontWeight: '200' }} >{item.sourceName}, {item.dateTime}</p>
                      </div>
                      <a target="_blank" href={item.externalLink}
                        className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                      </a>
                    </div>
                  })
                }
              </div>
            </div>}
            <div className="af-class-section af-class-knowledge-bank" style={{ backgroundColor: "#EDEDED" }}>
              <a href="#back-to-top" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(MeetOurLeadershipView)

/* eslint-enable */