import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";

const Main = styled.div`
  width: 100%;
  margin-top:40px;
  `;

const TextLight = styled.p`
margin: 0 !important ;
font-weight: 300 !important ;
font-family: Drlcircular !important ;
font-size: 18px !important ;
line-height: 30px !important ;
margin-bottom:5px !important;
@media (max-width: 820px) {
  font-size: 14px !important ;
  line-height: 24px !important ;
}
  `;

const TableContainer = styled.div`
  width: 100%;
  overflow:auto;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
  }
  tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }

    td{
        font-size: 18px !important;
        font-family: Drlcircular !important;
        border: 0.1px solid #707070 !important;
        font-weight: 500 !important;
        width:50% !important;
        padding: 20px 15px !important;
        line-height:30px !important;
        @media (max-width: 820px) {
          border: 1px solid #707070 !important;
        }
    }
    th{
        font-size: 18px !important;
        font-family: Drlcircular !important;
        border: 0.1px solid #707070 !important;
        width:50% !important;
        font-weight: bold !important;
        text-align:left !important;
        padding: 20px 15px !important;
        @media (max-width: 820px) {
          border: 1px solid #707070 !important;
          font-size: 14px !important;
          line-height: 24px !important;

        }
    }
  `;

export const ComplianceWithNyse = (props) => {

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  return (
    <Main>
      <div style={{ width:minWidthBool? '100%' :'80%' }} >
        <TextLight>
          Companies listed on the New York Stock Exchange (“NYSE”) must comply with certain standards regarding corporate governance as codified in Section 303A of the NYSE’s Listed Company Manual. Listed companies that are foreign private issuers (as such term is defined in Rule 3b-4 under the Securities Exchange Act of 1934, as amended (the “Exchange Act”)) are permitted to follow home country practice in lieu of the provisions of Section 303A, except that such companies are required to comply with the requirements of Sections 303A.06, 303A.11 and 303A.12(b) and (c), which are as follows:<br />
          (i)   establish an independent audit committee that has specified responsibilities;<br />
          (ii)   provide prompt certification by its chief executive officer of any non-compliance with any corporate governance rules;<br />
          (iii)  provide periodic written affirmations to the NYSE with respect to its corporate governance practices; and<br />
          (iv)  provide a brief description of significant differences between its corporate governance practices and those followed by U.S. companies.<br />
          <br />
          The following table compares our principal corporate governance practices to those required of U.S. NYSE listed companies.<br />
        </TextLight>
      </div>
      <TableContainer>
        <table>
          <tr>
            <th>Standard for U.S. NYSE Listed Companies</th>
            <th>Our practice</th>
          </tr>
          <tr>
            <td>
              <TextLight>
                Listed companies must have a majority of “independent directors,” as defined by the NYSE.<br />
              </TextLight>
              <TextLight>
                The non-management directors of each listed company must meet at regularly scheduled executive sessions without management.<br />
              </TextLight>
              <TextLight>
                Listed companies must have a nominating/corporate governance committee composed entirely of independent directors. The nominating/corporate governance committee must have a written charter that is made available on the listed company’s website and that addresses the committee’s purpose and responsibilities, subject to the minimum purpose and responsibilities established by the NYSE, and an annual evaluation of the committee.<br />
              </TextLight>
              <TextLight>
                Listed companies must have a compensation committee composed entirely of independent directors. The compensation committee must have a written charter that is made available on the listed company’s website and that addresses the committee’s purpose and responsibilities, subject to the minimum purpose and responsibilities established by the NYSE, and an annual evaluation of the committee.<br />
              </TextLight>
              <TextLight>
                Listed companies must have an audit committee that satisfies the requirements of Rule 10A-3 under the Exchange Act.<br />
              </TextLight>
              <TextLight>
                The audit committee must have a minimum of three members all being independent directors. The audit committee must have a written charter that is made available on the listed company’s website and that addresses the committee’s purpose and responsibilities, subject to the minimum purpose and responsibilities established by the NYSE, and an annual evaluation of the committee.<br />
              </TextLight>
              <TextLight>
                Each listed company must have an internal audit function.<br />
              </TextLight>
              <TextLight>
                Shareholders must be given the opportunity to vote on all equity-compensation plans and material revisions there to, with limited exceptions.<br />
              </TextLight>
              <TextLight>
                Listed companies must adopt and disclose corporate governance guidelines.<br />
              </TextLight>
              <TextLight>
                All listed companies, U.S. and foreign, must adopt and disclose a code of business conduct and ethics for directors, officers and employees that is made available on the listed company’s website and, and promptly disclose any waivers of the code for directors or executive officers.<br />
              </TextLight>
              <TextLight>
                Listed companies must solicit proxies for all meetings of shareholders.<br />
              </TextLight>
              <TextLight>
                Listed foreign private issuers must disclose any significant ways in which their corporate governance practices differ from those followed by domestic companies under NYSE listing standards.<br />
              </TextLight>
              <TextLight>
                Each listed company CEO must certify to the NYSE each year that he or she is not aware of any violation by the company of NYSE corporate governance listing standards, qualifying the certification to the extent necessary.<br />
              </TextLight>
              <TextLight>
                Each listed company CEO must promptly notify the NYSE in writing after any executive officer of the listed company becomes aware of any non-compliance with any applicable provisions of this Section 303A.<br />
              </TextLight>
              <TextLight>
                Each listed company’s audit committee, or another independent body of the board of directors, shall conduct a reasonable prior review and oversight of all related party transactions for potential conflicts of interest, and will prohibit such a transaction if it determines it to be inconsistent with the interests of the listed company and its shareholders.<br />
              </TextLight>
              <TextLight>
                Each listed company must submit an executed Written Affirmation annually to the NYSE. In addition, each listed company must submit an interim Written Affirmation each time that any of the following occurs:<br />
              </TextLight>
              <TextLight>
                • an audit committee member who was deemed independent is no longer independent;<br />
              </TextLight>
              <TextLight>
                • a member has been added to the audit committee;<br />
              </TextLight>
              <TextLight>
                • the listed company or a member of its audit committee is eligible to rely on and is choosing to rely on a Securities Exchange Act Rule10A-3 (“Rule 10A-3”) exemption;<br />
              </TextLight>
              <TextLight>
                • the listed company or a member of its audit committee is no longer eligible to rely on or is choosing to no longer rely on a previously applicable Rule 10A-3 exemption;<br />
              </TextLight>
              <TextLight>
                • a member has been removed from the listed company’s audit committee resulting in the company no longer having a Rule 10A-3 compliant audit committee; or<br />
              </TextLight>
              <TextLight>
                • the listed company determined that it no longer qualifies as a foreign private issuer and will be considered a domestic company under Section 303A.<br />
              </TextLight>
              <TextLight>
                The annual and interim Written Affirmations must be in the form specified by the NYSE.<br />
              </TextLight>
            </td>
            <td>
              <TextLight>
                We comply with this standard. Eight of our ten directors are “independent directors,” as defined by the NYSE as on March 31, 2021.<br />
              </TextLight>
              <TextLight>
                We comply with this standard. Our non-management directors meet periodically without management directors in scheduled executive sessions.<br />
              </TextLight>
              <TextLight>
                We have a Nomination, Governance and Compensation Committee composed entirely of independent directors that meets these requirements. The committee has a written charter that meets these requirements. We have evaluated the performance of the Nomination, Governance and Compensation Committee.<br />
              </TextLight>
              <TextLight>
                We have a Nomination, Governance and Compensation Committee composed entirely of independent directors that meets these requirements. The committee has a written charter that meets these requirements. We have evaluated the performance of our Nomination, Governance and Compensation Committee.<br />
              </TextLight>
              <TextLight>
                Our Audit Committee satisfies the requirements of Rule 10A-3 under the Exchange Act.<br />
              </TextLight>
              <TextLight>
                We have an Audit Committee composed of four members, all being independent directors as on March 31, 2021. The committee has a written charter that meets these requirements. We have evaluated the performance of our Audit Committee.<br />
              </TextLight>
              <TextLight>
                We have an internal audit function.<br />
              </TextLight>
              <TextLight>
                We comply with this standard. Our Employee Stock Option Plans were approved by our shareholders.<br />
              </TextLight>
              <TextLight>
                We have not adopted corporate governance guidelines.<br />
              </TextLight>
              <TextLight>
                We comply with this standard. More details on our Code of Business Conduct and Ethics are given under Item 16.B.<br />
              </TextLight>
              <TextLight>
                We do not solicit proxies because we are prohibited from doing so under Section 105 of the Indian Companies Act, 2013. However, we give eachof our shareholders written notices of all of our shareholder meetings.<br />
              </TextLight>
              <TextLight>
                This requirement is being addressed by way of this table.<br />
              </TextLight>
              <TextLight>
                We do not have such a practice.<br />
              </TextLight>
              <TextLight>
                There have been no such instances.<br />
              </TextLight>
              <TextLight>
                Under the SEBI’s Listing Regulations and the Indian Companies Act, 2013, our Audit Committee’s prior approval is required for all transactions with related parties or any subsequent modification of such transactions. These laws empower our Audit Committee to grant omnibus approval for our proposed related party transactions, subject to certain prescribed conditions. As a result, our Audit Committee reviews all transactions with related parties at the start of the year and on a quarterly basis. In addition, the Indian Companies Act, 2013 also requires us to seek prior approval of our Board or shareholders (as the case may be) for any material related party transaction, unless such transaction is (i) in the ordinary course of business and on an arms’ length basis, or (ii) with our wholly owned subsidiaries, whose financial statements are consolidated with us.<br />
              </TextLight>
              <TextLight>
                We filed our most recent annual written affirmation, in the form specified by the NYSE, on July 6, 2020. We filed the requisite interim written affirmation consequent to the appointment of Ms. Kalpana Morparia and cessation of Mr. Leo Puri as a member of our audit committee in the form specified by the NYSE, on February 9, 2021.<br />
              </TextLight>
            </td>
          </tr>
        </table>
      </TableContainer>
    </Main>
  )
}