import React from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { isTab } from '../../Utils/Utils';

const HumansOfDrReddysMobile = ({ data }) => {

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        let timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '#gs_reveal-container-one',
                start: "top 20%",
                end: '+=400',
                scrub: true,
                // markers: true,
                toggleActions: "play none none reverse",
            }
        })

        timeline.fromTo('#text-container-one', { height: '0', }, {
            duration: .5,
            height: '550',
            ease: "none",
            zIndex: 9999,
            // ease: "expo",
            // overwrite: "auto",
        }, "-=.2");

        timeline.set("#p-text-container-one", { display: 'block' })

        let timelineTwo = gsap.timeline({
            scrollTrigger: {
                trigger: '#gs_reveal-container-two',
                start: "top 20%",
                end: '+=400',
                scrub: true,
                // markers: true,
                toggleActions: "play none none reverse",
            }
        })

        timelineTwo.fromTo('#text-container-two', { height: '0', }, {
            duration: .5,
            height: '550',
            ease: "none",
            zIndex: 9999,
            // ease: "expo",
            // overwrite: "auto",
        }, "-=.2");

        timelineTwo.set("#p-text-container-two", { display: 'block' })

        let timelineThree = gsap.timeline({
            scrollTrigger: {
                trigger: '#gs_reveal-container-three',
                start: "top 20%",
                end: '+=400',
                scrub: true,
                // markers: true,
                toggleActions: "play none none reverse",
            }
        })

        timelineThree.fromTo('#text-container-three', { height: '0', }, {
            duration: .5,
            height: '550',
            ease: "none",
            zIndex: 9999,
            // ease: "expo",
            // overwrite: "auto",
        }, "-=.2");

        timelineThree.set("#p-text-container-three", { display: 'block' })


        // gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
        //     console.log(elem)

        // gsap.set('#text-container-one', { width: '0%' });

        // ScrollTrigger.create({
        //     trigger: '#gs_reveal-container-one',
        //     start: "top center",
        //     end: 'bottom center',
        //     scrub: true,
        //     onEnter: () => {
        //         gsap.fromTo('#text-container-one', { width: '0%', opacity: 0, }, {
        //             duration: 1.25,
        //             width: '84%',
        //             opacity: 1,
        //             zIndex: 9999,
        //             bottom: '-13vh',
        //             ease: "expo",
        //             overwrite: "auto"
        //         });
        //     }
        // })

        // gsap.set('#text-container-two', { width: '0%' });

        // ScrollTrigger.create({
        //     trigger: '#gs_reveal-container-two',
        //     start: "top center",
        //     end: 'bottom center',
        //     scrub: true,
        //     onEnter: () => {
        //         gsap.fromTo('#text-container-two', { width: '0%', opacity: 0, }, {
        //             duration: 1.25,
        //             width: '84%',
        //             opacity: 1,
        //             zIndex: 9999,
        //             bottom: '-13vh',
        //             ease: "expo",
        //             overwrite: "auto"
        //         });
        //     }
        // })

        // gsap.set('#text-container-three', { width: '0%' });

        // ScrollTrigger.create({
        //     trigger: '#gs_reveal-container-three',
        //     start: "top center",
        //     end: 'bottom center',
        //     scrub: true,
        //     onEnter: () => {
        //         gsap.fromTo('#text-container-three', { width: '0%', opacity: 0, }, {
        //             duration: 1.25,
        //             width: '84%',
        //             opacity: 1,
        //             zIndex: 9999,
        //             bottom: '-13vh',
        //             ease: "expo",
        //             overwrite: "auto"
        //         });
        //     }
        // })
        // })

        // let tl = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: "#container-LandingSection2",
        //         start: "top",
        //         end: "+=1700",
        //         pin: true,
        //         pinSpacing: true,
        //         // markers: true,
        //         scrub: true,
        //     }
        // });

        // tl.set("#text-first-one", { x:'-400px' })
        // tl.set("#text-second-one", { x:'-400px' })
        // tl.set("#text-third-one", { x:'-400px' })

        // tl.fromTo("#text-first-one", { width: '0%' }, { width: '84%', duration: '0.3' })

        // tl.fromTo("#text-first-one", { width: '84%' }, { width: '0%', duration: '0.1' })

        // tl.fromTo("#text-second-one", { width: '0%' }, { width: '84%', duration: '0.3' })

        // tl.fromTo("#text-second-one", { width: '84%' }, { width: '0%', duration: '0.1' })

        // tl.fromTo("#text-third-one", { width: '0%' }, { width: '84%', duration: '0.3' })

        // tl.fromTo("#text-third-one", { width: '84%' }, { width: '0%', duration: '0.1' })

    }, [data])

    return <>
        {data && data.length !== 0 && <div id="container-HumansOfDrReddysMobile" style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
            {/* {data && data.length !== 0 && <div style={{height:'auto'}} > */}
            {data && data.length !== 0 && <>

                <div id='gs_reveal-container-one' style={{ marginLeft: isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px' }} >
                    <img style={{ height: '70vh', width: '100%', objectFit: 'cover', zIndex: 9, marginBottom: isTab ? '35vh' : '55vh' }} src={'/images/humans-of-drreddy2x.jpg'} loading="lazy" />
                    <div id="text-container-one" style={{ backgroundColor: '#fff', padding: isTab ? '40px 80px' : '20px 30px', display: 'flex', flexDirection: 'column', position: 'absolute', width: isTab ? '80%' : '84%', marginLeft: isTab ? '10%' : '8%', marginRight: isTab ? '10%' : '8%', top: '55vh', opacity: 1, height: '0px', justifyContent: 'center' }} >
                        <p ref={(el) => {
                            if (el) {
                                if (isTab) {
                                    el.style.setProperty("line-height", '60px', 'important')
                                }
                                else {
                                    el.style.setProperty("line-height", '35px', 'important')
                                }
                            }
                        }} id="p-text-container-one" style={{ fontSize: isTab ? '40px' : '20px', fontWeight: '200', display: 'none' }} >Humans of Dr. Reddy’s</p>
                        <p id="p-text-container-one" className="af-class-paragraph af-class-_450" style={{ fontSize: isTab ? '16px' : '14px', display: 'none' }} >We don’t just pay attention to the work our people do; we also embrace their challenges as our own. Because a family that sticks together stays together.</p>
                        <div id="p-text-container-one" style={{ display: 'none' }} >
                            {
                                data && data.carousal1 && data.carousal1.length !== 0
                                    ?
                                    data.carousal1.map((item, index) => {
                                        return (
                                            <div key={"section2__carousal1" + index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px' }} >
                                                <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    // href={"/articles"}
                                                    //   onClick={() => {
                                                    //     localStorage.setItem("data", JSON.stringify(item))
                                                    //   }}
                                                    style={{ textDecoration: 'none', flex: 1, marginRight: '30px' }} >
                                                    <div className="af-class-testimonial-text" style={{ lineHeight: '24px', margin: 0, padding: 0, fontSize: isTab ? '16px' : '14px' }} >{item.title}</div>
                                                </a>
                                                <a id="p-text-container-one" href={`/articles?${item.contentId}#${item.id}`} style={{ display: 'none' }} >
                                                    <img src="/images/purple-right-arrow.svg" loading="lazy" />
                                                </a>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="w-dyn-empty">
                                        <div>No items found.</div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div id='gs_reveal-container-two' style={{ marginLeft: isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px' }} >
                    <img style={{ height: '70vh', width: '100%', objectFit: 'cover', zIndex: 9, marginBottom: isTab ? '35vh' : '55vh' }} src={'/images/Mask_Group_269.png'} loading="lazy" />
                    <div id="text-container-two" style={{ backgroundColor: '#fff', padding: isTab ? '40px 80px' : '20px 30px', display: 'flex', flexDirection: 'column', position: 'absolute', width: isTab ? '80%' : '84%', marginLeft: isTab ? '10%' : '8%', marginRight: isTab ? '10%' : '8%', top: '55vh', opacity: 1, height: '0px', justifyContent: 'center' }} >
                        {/* <p id="p-text-container-two" style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', display: 'none' }} >{data[1].title}</p> */}
                        <p id="p-text-container-two" className="af-class-paragraph af-class-_450" style={{ fontSize: isTab ? '16px' : '14px', display: 'none' }} >The key to healthy living is balancing work and life outside work in individual ways that bring out the best in us.</p>
                        <div id="p-text-container-two" style={{ display: 'none' }} >
                            {
                                data && data.carousal2 && data.carousal2.length !== 0
                                    ?
                                    data.carousal2.map((item, index) => {
                                        return (
                                            <div key={"section2__carousal2" + index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px' }}>
                                                <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    // href={"/articles"}
                                                    //   onClick={() => {
                                                    //     localStorage.setItem("data", JSON.stringify(item))
                                                    //   }}
                                                    style={{ textDecoration: 'none', flex: 1, marginRight: '30px' }} >
                                                    <div className="af-class-testimonial-text" style={{ lineHeight: '24px', margin: 0, padding: 0, fontSize: isTab ? '16px' : '14px' }} >{item.title}</div>
                                                </a>
                                                <a id="p-text-container-two" href={`/articles?${item.contentId}#${item.id}`} style={{ display: 'none' }} >
                                                    <img src="/images/purple-right-arrow.svg" loading="lazy" />
                                                </a>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="w-dyn-empty">
                                        <div>No items found.</div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div id='gs_reveal-container-three' style={{ marginLeft: isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px' }} >
                    <img style={{ height: '70vh', width: '100%', objectFit: 'cover', zIndex: 9, marginBottom: isTab ? '35vh' : '55vh', objectPosition: '0% 50%' }} src={'/images/everyone-is-equal2x.jpg'} loading="lazy" />
                    <div id="text-container-three" style={{ backgroundColor: '#fff', padding: isTab ? '40px 80px' : '20px 30px', display: 'flex', flexDirection: 'column', position: 'absolute', width: isTab ? '80%' : '84%', marginLeft: isTab ? '10%' : '8%', marginRight: isTab ? '10%' : '8%', top: '55vh', opacity: 1, height: '0px', justifyContent: 'center' }} >
                        <p id="p-text-container-three" style={{ fontSize: isTab ? '40px' : '20px', fontWeight: '200', display: 'none' }} >Diversity and Us</p>
                        <p id="p-text-container-three" className="af-class-paragraph af-class-_450" style={{ fontSize: isTab ? '16px' : '14px', display: 'none' }} >Dr. Reddy’s is a melting pot of diverse people and cultures. Inclusivity is one of our core values and we celebrate each employee’s uniqueness with pride.</p>
                        <div id="p-text-container-three" style={{ display: 'none' }} >
                            {
                                data && data.carousal3 && data.carousal3.length !== 0
                                    ?
                                    data.carousal3.map((item, index) => {
                                        return (
                                            <div key={"section2__carousal3" + index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px' }}>
                                                <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    // href={"/articles"}
                                                    //   onClick={() => {
                                                    //     localStorage.setItem("data", JSON.stringify(item))
                                                    //   }}
                                                    style={{ textDecoration: 'none', flex: 1, marginRight: '30px' }} >
                                                    <div className="af-class-testimonial-text" style={{ lineHeight: '24px', margin: 0, padding: 0, fontSize: isTab ? '16px' : '14px' }} >{item.title}</div>
                                                </a>
                                                <a id="p-text-container-three" href={`/articles?${item.contentId}#${item.id}`} style={{ display: 'none' }} >
                                                    <img src="/images/purple-right-arrow.svg" loading="lazy" />
                                                </a>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="w-dyn-empty">
                                        <div>No items found.</div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                {/* <div style={{ height: 'auto' }} >
                    <img id="img-first-one" style={{ height: '45vh', objectFit: 'cover', zIndex: 9, marginBottom: '0vh' }} src={data[0].thumbnail} loading="lazy" />

                    <div id="text-first-one" style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%', marginLeft: 0, marginRight: 0, bottom: '-13vh', zIndex: 9999 }} >
                        <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[0].title}</p>
                        <p id="text-first-desc-one" className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px', height: 0, display: 'none' }} dangerouslySetInnerHTML={{
                            __html: data[0].description
                        }} />
                        <a href={`/articles?${data[0].contentId}#${data[0].id}`} style={{ alignSelf: 'end' }} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" />
                        </a>
                    </div> */}

                {/* <div style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 9999, bottom: '-13vh', width: '84%', marginLeft: '8%', marginRight: '8%' }} >
                        <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[0].title}</p>
                        <p className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px' }} dangerouslySetInnerHTML={{
                            __html: data[0].description
                        }} />
                        <a href={`/articles?${data[0].contentId}#${data[0].id}`} style={{ alignSelf: 'end' }} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" />
                        </a>
                    </div> */}

                {/* </div>
                <div style={{ height: 'auto' }} >
                    <img id="img-second-one" style={{ height: '45vh', objectFit: 'cover', zIndex: 9, marginBottom: '0vh' }} src={data[1].thumbnail} loading="lazy" />
                    <div id="text-second-one" style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%', marginLeft: 0, marginRight: 0, bottom: '-13vh', zIndex: 9999 }} >
                        <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[1].title}</p>
                        <p id="text-second-desc-one" className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px', height: 0, display: 'none' }} dangerouslySetInnerHTML={{
                            __html: data[1].description
                        }} />
                        <a href={`/articles?${data[1].contentId}#${data[1].id}`} style={{ alignSelf: 'end' }} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" />
                        </a>
                    </div> */}
                {/* <div style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 9999, bottom: '-13vh', width: '84%', marginLeft: '8%', marginRight: '8%' }} >
                        <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[1].title}</p>
                        <p className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px' }} dangerouslySetInnerHTML={{
                            __html: data[1].description
                        }} />
                        <a href={`/articles?${data[1].contentId}#${data[1].id}`} style={{ alignSelf: 'end' }} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" />
                        </a>
                    </div> */}
                {/* </div> */}
                {/* <div style={{ height: 'auto' }} >
                    <img style={{ height: '45vh', objectFit: 'cover', zIndex: 9, marginBottom: isTab ? '35vh' : '55vh' }} src={data[2].thumbnail} loading="lazy" />
                    <div style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', bottom: '-15vh',width:'100%' }} >
                <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[2].title}</p>
                <p className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px' }} dangerouslySetInnerHTML={{
                    __html: data[2].description
                }} />
                <a href={`/articles?${data[2].contentId}#${data[2].id}`} style={{ alignSelf: 'end' }} >
                    <img src="/images/purple-right-arrow.svg" loading="lazy" />
                </a>
            </div>
                    <div style={{ backgroundColor: '#fff', padding: '20px 12px', display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 9999, bottom: '-13vh', width: '84%', marginLeft: '8%', marginRight: '8%' }} >
                        <p style={{ fontSize: isTab ? '40px' :'20px', fontWeight: '200', }} >{data[2].title}</p>
                        <p className="af-class-paragraph af-class-_450" style={{ fontSize:isTab ? '16px' : '14px' }} dangerouslySetInnerHTML={{
                            __html: data[2].description
                        }} />
                        <a href={`/articles?${data[2].contentId}#${data[2].id}`} style={{ alignSelf: 'end' }} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" />
                        </a>
                    </div>
                </div> */}
            </>
            }
        </div>}
    </>
}

export default HumansOfDrReddysMobile;