import React, { useState } from 'react';
import PeopleSpeakNew from './PeopleSpeakNew';
import PeopleSpeakNewMobile from './PeopleSpeakNewMobile';

import CovidSlider from '../MobileSlider/CovidSlider';

const PeopleSpeakSlider = ({ }) => {

    const [leftDisabled, SetLeftDisabled] = React.useState(true);
    const [rightDisabled, SetRightDisabled] = React.useState(false);


    return (
        <>

            {/* <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf"  className="af-class-container"> */}

            {window.matchMedia('(max-width: 820px)').matches && <div className="af-class-section" >
                <div className="af-class-container w-container">
                    <CovidSlider />
                </div>
            </div>}

            {!window.matchMedia('(max-width: 820px)').matches && <div data-w-id="ac2b7f71-6c4e-be07-c418-51677fd64972" className="af-class-section af-class-drag-slider-section" style={{ height: window.matchMedia('(max-width: 820px)').matches ? 'unset' : '100vh', maxHeight: window.matchMedia('(max-width: 820px)').matches ? 'unset' : '100vh' }} >
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" style={{ opacity: 0 }} className="af-class-container w-container">
                    <div className="af-class-drag-slider-wrapper-non-top-border">
                        <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                            <div className="af-class-mask w-slider-mask">
                                <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a7" className="af-class-drag-slider-item af-class-promise w-slide">
                                    <div>
                                        <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                                            <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1111.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                        </div>
                                        <div>Tele-consultation facilities are set up with doctors, nutritionists and counsellors to assist employees.</div>
                                    </div>
                                </div>

                                <div className="af-class-drag-slider-item af-class-promise w-slide">
                                    <div>
                                        <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                                            <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1112.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                        </div>
                                        <div>In-house testing and COVID-19 insurance for employees and their dependents are provided.</div>
                                    </div>
                                </div>

                                <div className="af-class-drag-slider-item af-class-promise w-slide">
                                    <div>
                                        <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                                            <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1113.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                        </div>
                                        <div>A Home Isolation Programme is in place and the care kit is free for employees via insurance.</div>
                                    </div>
                                </div>

                                {/* <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                <div>
                    <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                        <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1114.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    </div>
                    <div>Road and Air Ambulance are available on Call in Hyderabad, Bangalore, Chandigarh and Vizag.</div>
                </div>
            </div> */}

                                {/* <div className="af-class-drag-slider-item af-class-promise w-slide">
                <div>
                    <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                        <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1115.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                    </div>
                    <div>Apart from these, DRL also setup Pre-hospitalisation care facilities (with therapeutics, oxygen, doctors) and converted the Leadership Academy at Hyderabad and Vizag into COVID care centres with oxygen support.</div>
                </div>
            </div> */}

                                <div data-w-id={"ae2c330c-3b91-6fe2-293a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                                    <div>
                                        <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                                            <div className="af-class-drag-slider-item-thumbnail"><img src="images/Rectangle_Copy_1116.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                                        </div>
                                        <div>In case employee contracts the virus, they can avail COVID–19 leaves & a salary advance​.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" className="af-class-left-arrow af-class-lower w-slider-arrow-left" >
                    <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                </div>
                <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                    <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                </div> */}

                            {!window.matchMedia('(max-width: 820px)').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: leftDisabled ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={(e) => { SetRightDisabled(false); SetLeftDisabled(true); }}>
                                {
                                    leftDisabled ?
                                        <>
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                                        </>
                                        :
                                        <>
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </>
                                }

                            </div>}
                            {!window.matchMedia('(max-width: 820px)').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: rightDisabled ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={(e) => { SetRightDisabled(true); SetLeftDisabled(false); }}>
                                {
                                    rightDisabled ?
                                        <>
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </>
                                        :
                                        <>
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </>
                                }
                            </div>}
                            {!window.matchMedia('(max-width: 820px)').matches && <div className="af-class-slide-nav w-slider-nav w-round" />}


                        </div>
                    </div>
                    {!window.matchMedia('(max-width: 820px)').matches && <div className="af-class-drag-slider-progress-bar">
                        <div className="af-class-drag-progress-fill af-class-fifty" />
                    </div>}
                    {/* </div>
</div> */}

                    {/* <h1 data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ed" style={{ opacity: 0,lineHeight:'1.1' }} className="af-class-h2-section-heading af-class-title af-class-center-align af-class-_1000px">Our engagement<br/>with HCPs</h1> */}
                </div>
            </div>}


            {/* <div id="hcp-stories" data-w-id="dbce085d-578a-999a-d4be-9efe94e545ea" className="af-class-section af-class-always-innovating">
             <div className="af-class-always-innovating-heading-block">

                 </div> */}

            {/* <div  style={{ width: '100vw', minHeight: '100%', height: "100vh",  color: "#fff", display: 'flex'}} >  */}




            {/* chnages start  */}
            {
                window.matchMedia('(max-width: 820px)').matches
                    ?
                    <PeopleSpeakNewMobile />
                    :
                    <PeopleSpeakNew />
            }

            {/* changes end  */}

            {/* <div data-w-id="e40d7c91-3cfc-49f6-999a-4757e49152cf" className="af-class-people-speak-fullbg-wrapper" style={{minHeight:'450vh'}} >
                <div className="af-class-full-bg-block_1">
                    <div className="af-class-section af-class-people-speak af-class-_3 af-class-typec ">
                        <div className="af-class-container w-container">
                            <div className="w-layout-grid af-class-_2-col-grid">
                                <div>
                                    <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0 }} className="af-class-paragraph af-class-_572">
                                        On April 30, Kalyan received a call from Naveen, a friend and alumnus of Dr. Reddy’s, with an urgent request for Remdesivir for his father-in-law. Naveen had brought his father-in-law, already on oxygen support, from Sathupalli to Hyderabad in the hope of getting him treated but Remdesivir was proving to be impossible to source. Kalyan immediately reached out for help and Anand Garg, Head of Supply Chain, and VK Ashok, Head of Marketing, ensured that Naveen got the medicine by the next day. His father-in-law is now out of danger.
                                        <br /><br />
                                        “They have been thanking me, but it is my responsibility to convey this gratitude to the team. With their help, we were able to save a life.”
                                        <br /><br />
                                        <strong>NCh Kalyan Narasimham <br />Sourcing Specialist, Supply Chain Management</strong>
                                    </p>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="af-class-section af-class-people-speak af-class-_2 af-class-typeb" style={{ opacity: 0 }} >
                        <div className="af-class-container w-container">
                            <div className="w-layout-grid af-class-_2-col-grid">
                                <div>
                                    <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0 }} className="af-class-paragraph af-class-_572">
                                        Ashish’s 76-year-old father was admitted to a hospital in Indore on May 1 when his condition deteriorated. He needed Remdesivir, but the situation was critical – for the 50 patients in the hospital who needed the injection, only seven vials were provided. At this time, Ashish reached out through the Dr. Reddy’s employee helpline. Within 12 hours of providing all necessary documentation, Ashish received all the approvals. Several people jumped into action and the local CFA in Madhya Pradesh was cleared to provide Ashish with Remdesivir. His father is now recovering well.
                                        <br /><br />
                                        “I see a lot of people struggling to even get an oxygen bed in Madhya Pradesh, and I feel lucky and proud to be working with an organisation like Dr. Reddy’s that supported me in a situation like this.”
                                        <br /><br />
                                        <strong>Ashish Maheshwari <br />Area Business Manager – KAM HQ Indore</strong>
                                    </p>
                                </div>
                                <div id="w-node-dbce085d-578a-b8c3-d4be-9efe94e545fe-b3513499">
                                    <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0 }} className="af-class-paragraph af-class-_572">
                                        When Srinivas came down with Covid-19 recently, he became extremely stressed. He has two children, both under 10 years, and the intensity of the second wave led to panic. He sent his wife and children away to their hometown and isolated himself, but his landlord, a senior citizen, out of fear asked Srinivas to isolate outside the building premises. Unsure of what to do, he turned to his superiors who recommended he stay at a company-designated isolation centre. Srinivas’s treatment progressed smoothly and he has now recovered.
                                        <br /><br />
                                        “The team checked up on me every day, gave me priceless moral support and encouraged me to stay safe. All necessary assistance was provided to me and it’s clear that I recovered only because of Dr. Reddy’s support.”
                                        <br /><br />
                                        <strong>Srinivas More<br />Area Sales Manager, GG India, Novus Division</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="af-class-section af-class-people-speak af-class-_1 af-class-typea"  >
                        <div className="af-class-container w-container">
                            <h1 data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className='af-class-h1-article' style={{ marginLeft: '0px', marginBottom: '30px', marginTop: '0px' }} >People Speak</h1>
                            <div className="w-layout-grid af-class-_2-col-grid">
                                <div>
                                    <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0 }} className="af-class-paragraph af-class-_572">
                                        Dasari had to travel in the second week of April on personal work, but by the time he reached Vizag, his sister’s family, wife and child were affected. Though asymptomatic, Dasari too got tested and was found to be Covid-19 positive. He immediately informed his superior and started medication at home under a doctor’s supervision. A few days later, he was taking a turn for the worse, but Dr. Reddy’s jumped in to get him admitted to a hospital within an hour.
                                        <br /><br />
                                        “The assurance and support I received at a time of need felt like I was a family member for all my superiors. It gave me confidence and helped me to recover soon.”
                                        <br /><br />
                                        <strong>Dasari Padmanabhan<br />Team Member – CFA Management</strong>
                                    </p>
                                </div>
                                <div>
                                    <p data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0 }} className="af-class-paragraph af-class-_572">
                                        Nilesh developed symptoms for Covid-19 around April 15, and he immediately got himself and his parents tested. They were all positive, but this was not all. Nilesh’s wife and daughter in Nagpur also came down with the disease and he began to panic. He contacted the helpline and got immediate help, including a prescription and medicines. The help team assured him to contact them anytime, irrespective of day or night. Now, Nilesh’s family has recovered fully, and he is almost there.
                                        <br /><br />
                                        “It was a very stressful period, but the team became like an extended family for us. They checked up on me and my family regularly and provided treatment support as well as mental encouragement that helped me to overcome my panic.”
                                        <br /><br />
                                        <strong>Nilesh Vishwas Baraskar<br />Therapy Lead – Marketing CoE</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
 */}
            {/* <div className="af-class-founder-image-3" style={{ zIndex: 9, backgroundColor: 'rgba(0,0,0,0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                                <h1 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className='af-class-h1-article' style={{ maxWidth: '70vw', alignSelf: 'center', zIndex: 999, textAlign: 'center', marginBottom: '30px' }} >We care for our people</h1>
                                <p style={{ maxWidth: '40vw', alignSelf: 'center', zIndex: 999, textAlign: 'center' }} >To help employees and their families, we have created channels for them to seek help for any COVID-19-related issue, and find the right resources.</p>
                    </div> */}


            {/* <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                        <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                            <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                                <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6c7" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                    <div className="af-class-slide-div">
                                        <div className="af-class-delivering-health-slider-heading">Pharmaceutical Services and Active Ingredients</div>
                                        <h4 className="af-class-heading-4-bold">Active Pharmaceutical Ingredients (APIs)</h4>
                                        <div>Our proficiency in developing complex APIs such as steroids and peptides, and complex long chain synthesis helps us deliver high-quality products. It has enabled us to become trusted partners to leading innovator and generic companies in the US, Europe, Latin America, Japan, Korea and other emerging markets.</div>
                                        <a href="https://api.drreddys.com/" className="af-class-link-arrow af-class-left-side w-inline-block">
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                        </a>
                                    </div>
                                </div>
                                <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6d3" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                    <div className="af-class-slide-div">
                                        <h4 className="af-class-heading-4-bold">Custom Pharmaceutical Services (CPS)<br /></h4>
                                        <div>At the CPS division, we offer process and analytical development as well as formulation development in line with regulations set by the USFDA. Our endeavour is to help accelerate the journey from laboratory to market for global pharma and specialty companies worldwide by providing access to our capabilities, knowledge and experience.</div>
                                    </div>
                                </div>
                                <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6da" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                    <div className="af-class-slide-div">
                                        <div className="af-class-delivering-health-slider-heading">Global Generics<br /></div>
                                        <div>Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.<br /></div>
                                    </div>
                                </div>
                                <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6e2" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                    <div className="af-class-slide-div">
                                        <div className="af-class-delivering-health-slider-heading">Proprietary Products<br /></div>
                                        <div>Patients today are looking for more than just safe, effective medicines. They require information, coaching, and customized support to help them adhere to complex treatment protocols.<br /><br />Our Proprietary Products division aims to fulfil these unmet patient needs. In some cases, this involves developing a new dosage of a current drug. In other cases, it may involve developing new combinations of synergistic medications. The focus is on initiatives to improve patient experience with our products. Better experience results in better compliance, which means better health and outcomes for patients.<br /></div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6ed" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f1" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                                <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f3" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </div>
                            <div className="af-class-slide-nav w-slider-nav w-round" />
                        </div>
                    </div> */}
            {/* </div>
            </div> */}
        </>)
}

export default PeopleSpeakSlider;