import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

export const AnalystCoverage = (props) => {

  const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
  const TableContainer = styled.div`
  width: 100%;
  overflow:auto;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      padding: 5px;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
        @media (max-width: 820px) {
          font-size: 14px;
        }
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
        @media (max-width: 820px) {
          font-size: 14px;
        }
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
  width: 100%;
  margin-top:40px;
`;
  const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

  const Header = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 570px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
`;

  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
`;
  const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 4px;
  margin-left: 10px;
  justify-content: space-between;
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
  flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  text-transform: uppercase;
  @media (max-width: 1180px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
  const content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

  const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;
const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  margin-right:80px;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline !important;
    margin-bottom: 15px;
  }
  `;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const minWidthBool = useMediaQuery({ query: "(min-device-width: 1180px)" });
  const [Analysttable, setAnalysttable] = useState({});

  useEffect(() => {
        var tempReports = {};
        var temp = [];
        var tempArr = [];
        axios({
            method: "get",
            url: config.serverUrl.base + "/jsonapi/node/analyst_coverage",
        })
            .then((res) => {
                for (const report of res.data.data) {
                    tempArr = [];
                    if (
                        tempReports?.[report.attributes.title] && [report.attributes.title]
                    ) {
                        tempArr = tempReports[report.attributes.title];
                        tempArr.push({
                            description: temp[report.attributes.field_table_analyst.value],
                        });
                        tempReports[report.attributes.title] = tempArr;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            description: report.attributes.field_table_analyst.value,
                        });
                        tempReports[report.attributes.title] = tempArr;
                    }
                }
                setAnalysttable(tempReports);
            })
            .catch((err) => {
                console.log(err, "err");
            });
    }, []);

  return (<MainContainer>
    <TableContainer style={{ marginTop: "35px" }}>
      <table>
        {Analysttable?.["Analyst Coverage"] &&
          Analysttable?.["Analyst Coverage"].map((item, index) => {
            var result = [];
            var tempArr = [];
            for (const i in item.description) {
              if (i !== "caption") {
                tempArr = [];
                item.description[i].map((item2, index2) => {
                  if (i === "0") {
                    tempArr.push(<th style={{ padding: '20px 15px', }} >{item2}</th>);
                  } else if(index2 === 2) {
                    tempArr.push(<td style={{ padding: '20px 15px', }} ><TextContent to={{pathname:'mailto:'+item2}} target="_blank" >{item2}</TextContent></td>);
                  } else {
                    tempArr.push(<td style={{ padding: '20px 15px', }} >{item2}</td>);
                  }
                });
                result.push(<tr>{tempArr}</tr>);
              }
            }
            return result;
          })}
      </table>
    </TableContainer>
  </MainContainer>

  )

}