import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import BackIcon from '../../Common/images/backArrow.svg';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 8%;
`;

const Header = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    align-self: flex-start;
`;

const BackImg = styled.img`
    width: 20px;
    height: 20px;
`;

const BackText = styled.h6`
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    font-family: Drlcircular;
    margin: 0;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 300;
    margin-top: 60px;
    margin-bottom: 30px;
`;

const TextSection = styled.p`
    width: 90%;
    font-size: 18px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 300;
    margin: 40px 0;
    line-height: 1.8;
`;

const TextBold = styled.p`
    font-size: 18px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 700;
`;

const TextBoldSpan = styled.span`
    font-size: 18px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 700;
`;

const UL = styled.ul`
    margin: 0;
    padding-left: 20px;
`;

export function MainSection(props) {
    const history = useHistory();
    return <MainContainer>
        <Header onClick={() => {
            history.goBack();
        }} >
            <BackImg src={BackIcon} />
            <BackText>Back</BackText>
        </Header>
        <BodySection>
            <Heading>Ombudsperson Policy</Heading>
            <HR />
            <TextSection>
                <TextBold>PURPOSE </TextBold>
                Dr. Reddy’s Laboratories Limited, including its subsidiaries and affiliate companies, (hereinafter referred to as “ Dr. Reddy’s” or the “Company”) is committed to the highest legal, moral and ethical standards in conducting its business. The Company has adopted the Code of Business Conduct and Ethics (“COBE”), which lays down the principles and standards that should govern the actions of the Company, its employees and third parties. <br />
                This policy is intended to provide guidance on raising concerns of any actual or potential violations of COBE, policies and procedures of the Company, any applicable laws and regulations, unprofessional or inappropriate conduct and address them appropriately. <br /><br />
                <TextBold>DEFINITIONS </TextBold>
                All words used in this Policy but not defined, have the same meaning assigned to them in COBE.<br />
                <UL>
                    <li><TextBoldSpan> Audit Committee</TextBoldSpan> means the Audit Committee of the Board constituted as per applicable statutory requirements. </li>
                    <li><TextBoldSpan> Chief Compliance Officer</TextBoldSpan> (CCO) is a designated authority reporting to the Chief Ombudsperson of Dr. Reddy’s for the purpose of compliance with the Ombudsperson Policy. </li>
                    <li> <TextBoldSpan>Chief Ombudsperson</TextBoldSpan> is a designated Independent Director from the Board of Directors of the Company. </li>
                    <li><TextBoldSpan> Complainant</TextBoldSpan> means any employee or third party raising a concern under the purview of this Policy. </li>
                    <li><TextBoldSpan> Third party</TextBoldSpan> includes retainers, consultants, service providers, distributors, vendors, customers or any other person dealing directly or indirectly with the Company. </li>
                    <li><TextBoldSpan> Concern </TextBoldSpan> means any actual or potential violations of COBE, policies and procedures of the Company, any applicable laws and regulations or any other unprofessional and inappropriate conduct by employees or third party. </li>
                </UL><br /><br />
                <TextBold>APPLICABILITY AND SCOPE </TextBold>
                All employees and third parties are obligated to report any actual or potential violations of COBE, policies and procedures of the Company, any applicable laws and regulations or any other unprofessional and inappropriate conduct.This Policy is not intended to address individual employee grievances or complaints relating to job performance and terms and conditions of employment, or individual third party grievances relating to terms and conditions of contract, which will be addressed by the respective departments in line with existing policies. <br /><br />
                <TextBold>GUIDELINES </TextBold>
                This Policy encourages employees and third parties to raise concerns relating to matters, including, but not limited to:
                <UL>
                    <li> Gifts, Bribery and Kickbacks </li>
                    <li> Conflict of interestFinancial issues and Fraud </li>
                    <li> Antitrust and Unfair competition </li>
                    <li> Misuse or Misappropriation of assets or informationInsider trading </li>
                    <li> Sales, Marketing and Promotional practices </li>
                    <li> Employee relations, Discrimination and Inappropriate behavior (not Sexual harassment) </li>
                    <li> Sexual harassment </li>
                    <li> Data privacy </li>
                    <li> Information security </li>
                    <li> Safety, Health & Environment </li>
                </UL>
                Any other violations of policies and procedures <br />
                The Complainant is not required or expected to act as investigator or finder of facts themselves, nor would they determine the appropriate corrective or remedial action that may be warranted in a given case. <br /><br />
                <TextBold>Raising of concern: </TextBold>
                a) You may raise concern by:
                <UL>
                    <li> Accessing https://drreddys.ethicspoint.com. The link can be accessed through intranet and the Company’s website. </li>
                    <li> Calling on the hotline number. List of hotline numbers are available in “Raise a Concern by Phone” section of https://drreddys.ethicspoint.com. The hotline is available 24x7 in multiple languages. </li>
                    <li> Writing to complianceofficer@drreddys.com or chiefombudsperson@drreddys.com. </li>
                    <li> By Post / Courier to: </li>
                </UL>
                Chief Compliance Officer / Chief Ombudsperson, <br />
                Dr. Reddy’s Laboratories Limited, <br />
                8-2-337, Road No. 3, Banjara Hills, <br />
                Hyderabad – 500 034, Telangana, India <br /><br />
                <TextBoldSpan> Note: </TextBoldSpan> When you report your concern, you may choose to remain anonymous, wherever allowed by local laws. However, you are encouraged to identify yourself, as doing so can help resolve the situation, especially if there is a need to follow up for additional information. When you identify yourself, every reasonable effort will be made to keep your identity confidential. <br /><br />
                b) If any person other than CCO or Chief Ombudsperson receives a concern made by any Complainant, then the same must be forwarded to the CCO without fail. In such cases, appropriate care must be taken to ensure the confidentiality of Complainant. <br /><br />
                c) Any past employee / third party needs to raise the Concern within a period of 180 days from the termination of their association with the Company. <br /><br />
                d) Complainant shall provide below mentioned information while raising the concern:
                <UL>
                    <li> Nature of concern </li>
                    <li> Name of person against whom the concern is raised </li>
                    <li> Location or business unit related to the concernDetails of the concern </li>
                    <li> Period during which misconduct occurred, including specific dates and times of the violation </li>
                    <li> Necessary supporting evidences/proofs, if any </li>
                    <li> Contact details </li>
                </UL><br /><br />
                <TextBold>Handling of concerns: </TextBold>
                a) The CCO, at his discretion, based on preliminary facts available may decide to address the concern either by investigating internally or through identified external third party service providers. Complainants who identify themselves may be invited to provide leads and evidences (including circumstantial) to supplement information gathered in the course of investigation. <br /><br />
                b) The purpose of the investigation of a concern includes:
                <UL>
                    <li> To determine if the specific allegations of misconduct are substantiated by the available oral and documentary evidences. </li>
                    <li> To determine whether other misconduct may have occurred. </li>
                    <li> To identify areas of potential risks to the Company as a result of misconduct. </li>
                    <li> To identify areas of business operations that may require improvement as a result of misconduct. </li>
                </UL><br /><br />
                c) CCO, at his discretion, may consider the concern closed if there are no specific details provided by the Complainant and there was no response from the Complainant for a reasonable period of time. <br /><br />
                d) Based on findings supported by necessary evidences, appropriate recommendations will be finalized by the CCO after consultation with the appropriate management and / or HR. <br /><br />
                e) Person against whom the concern is raised shall be provided with a reasonable opportunity of being heard, before taking any disciplinary action in this regard. <br /><br />
                f) No one shall create, withhold, tamper or destroy any evidence or other document(s) related to the concern and also should not interfere in the fair proceedings of the investigation. <br /><br />
                g) CCO may, at his discretion, decide whether the outcome of investigation should be communicated back to Complainant. <br /><br />
                h) ‘Procedure for handling Concerns under Ombudsperson Policy’ shall be adhered to while handing concerns. <br /><br />
                <TextBold>NON-RETALIATION </TextBold>
                Dr. Reddy’s will not tolerate any retaliation against an employee or third party who in good faith raises concerns or who assists in an investigation of suspected wrongdoing, in line with the Company’s Non- Retaliation Policy. Reporting “in good faith” means making a genuine attempt to provide honest, complete, and accurate information, even if it later proves to be unsubstantiated or mistaken. Disciplinary action may be initiated if an employee knowingly raises a false or misleading concern. For further details refer ‘Non-Retaliation policy’. <br /><br />
                <TextBold>CONFIDENTIALITY AND RETENTION OF DOCUMENTS </TextBold>
                The identity of the Complainant shall be kept confidential to the extent possible and permitted under law. There may be circumstances where the identity may become known for reasons outside the control of the CCO / Chief Ombudsperson.All the documents received from the Complainant and evidence obtained during the investigation and the report shall be kept confidential and retained in accordance with applicable laws, unless disclosure is mandated by a court order or is otherwise necessary to comply with the requirements of applicable laws. <br /><br />
                <TextBold>OVERSIGHT </TextBold>
                CCO will provide a summary of concerns handled under the Ombudsperson policy to the Chief Ombudsperson on a quarterly basis. Chief Ombudsperson may seek additional information on the concerns handled and may order further investigation.In addition to the Chief Ombudsperson being an independent director of the board of directors, the mechanism of concern handling under this policy is overseen by the Audit Committee. <br /><br />
                <TextBold>TRAINING AND SENSITIZATION </TextBold>
                Appropriate training and sensitization to be provided to employees and third parties on Ombudsperson Policy and Non-retaliation Policy. <br /><br />
                <TextBold>PRECEDENCE </TextBold>
                This Policy shall be read in conjunction with COBE. The provisions of COBE shall prevail in case of any contradiction between this Policy and COBE. <br /><br />
                <TextBold>AMENDMENT </TextBold>
                The Company reserves its right to amend or modify this Policy in whole or in part, at any time without assigning any reason whatsoever. <br /><br />
                <TextBold>ANNEXURE: FREQUENTLY ASKED QUESTIONS:</TextBold> <br /><br />
                <TextBold>a) I know of a COBE violation that is not related to the department I belong to. Can I raise the concern under this Policy? </TextBold>
                Yes. Any concern which is pertaining to an actual or potential violation of COBE, policies and procedures of the Company, any applicable laws and regulations or any other unprofessional and inappropriate conduct need to be raised irrespective of whether it is within your department or not. <br /><br />
                <TextBold>b) There is a concern that is known to many people in my department but they have chosen to be silent. Should I raise the concern? </TextBold>
                Yes. The concern should be raised so that investigation is initiated and appropriate action is taken. As a responsible employee of the Company, you have to raise the concern as per this policy. <br /><br />
                <TextBold>c) Can a third party associated with the Company raise a concern? </TextBold>
                Yes. Any third party associated with the Company can raise a concern on actual or potential violation of COBE and applicable policies and procedures. <br /><br />
                <TextBold>d) Where are the hotline numbers available? </TextBold>
                Hotline numbers for each of the country are listed in ‘Raise a Concern by Phone’ section of https://drreddys.ethicspoint.com website. The hotline is available 24x7 in multiple languages operated by specially trained third party representatives. <br /><br />
                <TextBold>e) If I raise a concern, my manager may harass me. What should I do? </TextBold>
                Any concern can be raised under this Policy without fear of retaliation. If any retaliation occurs, the same should be reported by accessing ‘drreddys.ethicspoint.com’. Refer COBE/ Non-Retaliation policy for further details. <br /><br />
                <TextBold>f) I don’t have all the details required to be provided for raising a concern like how long for which the malpractice continued, name of the person who violated the Policy, or other details. What should I do? </TextBold>
                In case complete details are not available, concern can still be raised with the available information. <br /><br />
                <TextBold>g) Is it compulsory to provide the details of the complainant for raising a concern? </TextBold>
                No. It is not compulsory to provide the details of the complainant for raising a concern. However, Dr. Reddy’s encourages complainants to identify themselves, so that additional information required for investigation, if any, can be obtained.
            </TextSection>
        </BodySection>
    </MainContainer>
}