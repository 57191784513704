import React, { useRef, useState, useEffect } from 'react';

import gsap from 'gsap';

import Lottie from 'react-lottie';
import * as arrowAnime from '../../assets/Lottie/White_Arrow.json'

import { isTab } from '../../Utils/Utils';

const HeaderBanner = ({ data }) => {

  const [animeEnd, setAnimeEnd] = useState(false);

  // useEffect(() => {
  //   // window.addEventListener("scroll", handleScroll);
  //   var x = document.getElementById("home-cover");
  //   x.addEventListener("webkitAnimationEnd", myEndFunction);
  //   x.addEventListener("animationend", myEndFunction);
  // }, []);

  // function handleScroll() {
  //   if (window.pageYOffset >= 500 && active === 0) {
  //     setActive(1);
  //   } else if (window.pageYOffset < 500 && active === 1) {
  //     setActive(0);
  //   }
  // };

  function myEndFunction() {
    var x = document.getElementById("home-cover");
    x.style.display = "none";
    setAnimeEnd(true);
  }


  useEffect(() => {

    if (window.matchMedia('(max-width: 820px)').matches) {

      setTimeout(() => {

        gsap.set("#curtain", { height: '100vh', duration: 0.2, delay: 0.2 })

        gsap.set("#header-black", { opacity: 1, color: 'white', delay: 0.5 })

        gsap.fromTo("#curtain", { height: '100vh' }, { height: '0', duration: 0.6, delay: 0.7 })

        gsap.set("#header-black", { opacity: 0, delay: 0.7 })

      }, 2500);

    }

  }, [])



  let mask = useRef(null);

  var timer, resizeTimer;

  const markResize = () => {
    resizeTimer = setInterval(() => {
      if (mask.current.clientWidth > 250) {
        let mask_dimentions = mask.current.clientWidth - 5;
        mask.current.style.width = `${mask_dimentions}px`;
        mask.current.style.height = `${mask_dimentions}px`;
      }
      else {
        clearInterval(resizeTimer);
      }
    }, 20);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowAnime,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div data-w-id="bd96a6f0-b121-df05-c8fd-9a78b0c67316" className="af-class-about-hero-circle-wrapper" style={{ height: '200vh' }} >
      <div href="#main" data-w-id="4510b6d6-032b-12c3-4ef2-01fb448d6c24" className="af-class-hero-circle"
        ref={mask}
      />
      <div className="af-class-section af-class-hero-about-us" style={{ zIndex: 9 }} >
        {window.matchMedia('(max-width: 820px)').matches && <div id="curtain" className='purple-bg-hero-home' style={{ flex: 1, display: 'flex', }} >
          <div data-w-id={"3de728df-e436-5d1f-ff86-77f8d7e66adf"} className="af-class-container w-container" style={{ justifyContent: 'center', alignSelf: 'center' }} >
            {
              isTab
                ?
                <h1 id="header-black" className="af-class-h1-heading af-class-center" style={{ zIndex: 9999999999, color: 'white',fontSize:'64px',lineHeight:'80px' }} >Good Health<br />Can’t Wait</h1>
                :
                <h1 id="header-black" className="af-class-h1-heading af-class-center" style={{ zIndex: 9999999999, color: 'white' }} >Good<br />Health<br />Can’t<br />Wait</h1>
            }
          </div>
        </div>}
        <div data-w-id={window.matchMedia('(max-width: 820px)').matches ? "" : "3de728df-e436-5d1f-ff86-77f8d7e66adf"} className="af-class-container w-container">
          {window.matchMedia('(max-width: 820px)').matches
            ?
            isTab
              ?
              <h1 className="af-class-h1-heading af-class-center" style={{ zIndex: 99999999,fontSize:'64px',lineHeight:'80px' }} >Good Health<br />Can’t Wait</h1>
              :
              <h1 className="af-class-h1-heading af-class-center" style={{ zIndex: 99999999 }} >Good<br />Health<br />Can’t<br />Wait</h1>
            :
            <h1 className="af-class-h1-heading af-class-center" >Good Health<br />Can’t Wait</h1>}
        </div>
        <a href="#main" className="af-class-fake-click w-inline-block"
          onMouseMove={() => {
            clearTimeout(timer);
            if (mask.current.clientWidth < 300) {
              setTimeout(() => {
                let mask_dimentions = mask.current.clientWidth + 5;
                mask.current.style.width = `${mask_dimentions}px`;
                mask.current.style.height = `${mask_dimentions}px`;
              }, 200);
            }
            timer = setTimeout(() => markResize(), 300);
          }}
          onMouseOut={() => {
            mask.current.style.width = "250px";
            mask.current.style.height = "250px";
          }}
        />
        {/* {
          window.matchMedia('(max-width: 820px)').matches
            ?
            <div style={{ zIndex: 99999, position: 'absolute', bottom: window.matchMedia('(max-width: 820px)').matches?'5%':0, left: window.matchMedia('(max-width: 820px)').matches?'auto':0, right: 0 }} onClick={() => {
              window.location.href = "#main";
            }} >
              <Lottie options={defaultOptions}
                height={150}
                width={150}
              />
            </div>
            : */}
        {
          window.matchMedia('(max-width: 820px)').matches
            ?
            <h1 style={{ zIndex: 99999, position: 'absolute', bottom: 0, left: 0, right: 0, bottom: window.matchMedia('(max-width: 820px)').matches ? '5%' : 0, }} onClick={() => {
              window.location.href = "#main";
            }} >
              <Lottie options={defaultOptions}
                height={150}
                width={150}
              />
            </h1>
            :
            <h1 data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-h1-heading af-class-center" style={{ zIndex: 99999, position: 'absolute', bottom: 0, left: 0, right: 0, bottom: window.matchMedia('(max-width: 820px)').matches ? '5%' : 0, }} onClick={() => {
              window.location.href = "#main";
            }} >
              <Lottie options={defaultOptions}
                height={150}
                width={150}
              />
            </h1>
        }
        {/* } */}
      </div>
      <div id="main" className="af-class-section af-class-about-us-2nd">
        <div className="af-class-container w-container">
          <div ref={(el) => {
              if (el && window.matchMedia('(max-width: 820px)').matches) {
                if(isTab){
                  el.style.setProperty('display', 'flex', 'important');
                  el.style.setProperty('margin-left', '15%', 'important');
                  el.style.setProperty('margin-right', '15%', 'important');
                }
              }
            }} className="w-layout-grid af-class-_2-col-grid af-class-_40px-gap">
            {!window.matchMedia('(max-width: 820px)').matches && <h2 id="w-node-e2075603-5cac-4513-72d7-58d455a5354c-8b74a661" style={{ alignSelf: 'flex-start' }} className="af-class-h2-section-heading af-class-title af-class-negative-margin"  >Good Health<br />Can’t Wait</h2>}
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: 820px)').matches) {
                if(isTab){
                  el.style.setProperty('font-size', '20px', 'important');
                  el.style.setProperty('line-height', '32px', 'important');
                } else {
                  el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} id="w-node-_6607a834-fa75-c1f3-6f9d-3ced6992cd4d-8b74a661" className="af-class-paragraph af-class-lead" style={{ maxWidth: '100%', justifySelf: 'end', fontSize: window.matchMedia('(max-width: 820px)').matches ? '16px' : '20px', lineHeight: '30px' }}>Our credo captures the essence of who we are and what we believe in. We see medicines as not just molecules, but also as a means to help people lead healthy lives. <br /><br />We believe that good health must be delivered speedily to as many patients as possible. We target therapy areas with unmet patient needs and aim to create accessible and affordable solutions. </p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default HeaderBanner;