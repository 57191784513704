import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "/images/award1.png",
        "heading": "CDP",
        "desc": "Our ESG efforts for 2021 were recognised by CDP - in supplier engagement, water security and climate change! For the 2nd year in a row, we were featured on CDP Supplier Engagement Leaderboard with the highest score possible."
    },
    {
        "id": "slider_2",
        "img": "/images/award2.png",
        "heading": "DowJones Sustainability Index",
        "desc": "OWe were positioned 9th among the most sustainable pharma companies globally in the Dow Jones Sustainability Index 2021, featuring for the 6th year in a row in the Emerging Markets category."
    },
    {
        "id": "slider_3",
        "img": "/images/award3.png",
        "heading": "Sustainability Yearbook",
        "desc": "We have also been included as a member of The Sustainability Yearbook 2022, building on our inclusion in The Sustainability Yearbook 2021. The membership is based on the Corporate Sustainability Assessment (CSA) 2021 by S&P Global."
    },
    {
        "id": "slider_4",
        "img": "/images/award4.png",
        "heading": "Science Based Targets initiative",
        "desc": "We joined the Science Based Targets initiative’s (SBTi) business ambition in 2020, becoming the first Indian and the third Asian pharmaceutical company to have set its Science-Based Targets to further minimize environmental impact."
    },
    {
        "id": "slider_5",
        "img": "/images/award5.png",
        "heading": "Sustainable Company of the Year",
        "desc": "Recognised with the prestigious apex award – Sustainable Corporate of the Year award – at the 2021 Frost & Sullivan-TERI Sustainability 4.0 Awards."
    },
    {
        "id": "slider_6",
        "img": "/images/award6.png",
        "heading": "Bloomberg GEI",
        "desc": "For the 5th year in a row, we have been included in the Bloomberg LP Gender-Equality Index (GEI) 2022. We are among the 7 Indian companies and the only Indian pharma company in this global index. Humbled to receive this recognition of the year-on-year enhancement of our effort to create a compassionate and inclusive workplace."
    },
    {
        "id": "slider_7",
        "img": "/images/award7.png",
        "heading": "Indian Green Building Council - IGBA",
        "desc": "We have been awarded with the Green Champion Award 2022 under the category 'Pioneer in large-scale adoption of green factory buildings in India’. This highly coveted distinction makes us part of the distinguished community spearheading the Green and Net Zero Building Movement in India."
    },
    {
        "id": "slider_8",
        "img": "/images/award8.png",
        "heading": "UN Women Empowerment Principles Awards",
        "desc": "In October 2021, we were 2nd runner-up in the United Nations Women’s Empowerment Principles (WEPs) Gender-inclusive Workplace category in Asia-Pacific. We recently joined the United Nations Women Empowerment Principles as a signatory."
    },
    // {
    //     "id": "slider_9",
    //     "img": "/images/jason-goodman-4-iZ147pSAE-unsplash.png",
    //     "heading": "I-Develop",
    //     "desc": "With I-Develop, HR business partners undergo a year-long training for capability building across functions. They’re helped by external experts to develop expertise in various fields, from data analytics to talent development, career planning and employee experience."
    // },
    // {
    //     "id": "slider_10",
    //     "img": "/images/docusign-BbSBf5uv50A-unsplash.png",
    //     "heading": "Digital Capability Building Programmes",
    //     "desc": "As a future-forward company, enabling our employees to be digital savvy is one of our priorities. This aligns with our digitisation campaign to build a business that will flourish for decades. Our courses are customised to the needs of different units, from manufacturing to finance and marketing."
    // },
]
    ;

const AwardsSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 80);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                    marginBottom: '20px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px',height:'50px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                console.log(readMore)
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default AwardsSlider;