import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Img1 from '../../assets/type-a.jpeg';
import Img2 from '../../assets/type-b.jpeg';

import { isTab,mediaQueryRangeTab } from '../../Utils/Utils';

gsap.registerPlugin(ScrollTrigger);

function HcpStoriesMobile({ data }) {

    useEffect(() => {

        // gsap.registerPlugin(ScrollTrigger);
        // if (ScrollTrigger.getById('trigger3')) ScrollTrigger.getById('trigger3').kill();

        // // ScrollTrigger.refresh();

        // console.log(" Enter Point of HCP",ScrollTrigger.getAll());

        // let tl2 = gsap.timeline({
        //     scrollTrigger: {
        //         id: "trigger3",
        //         trigger: "#hcpcontainer2",
        //         start: "top",
        //         end: "+=2000",
        //         pin: true,
        //         pinSpacing: true,
        //         // markers: true,
        //         scrub: true,
        //         refreshPriority:1,
        //     }
        // });

        // tl2.set("#img-HCPMobile-one", { opacity: 1 })
        // tl2.set("#img-HCPMobile-one-two", { opacity: 0 })

        // tl2.set('#img-HCPMobile-one-two', { opacity: 1 }, "+=0.4");
        // tl2.set('#img-HCPMobile-one-two', { opacity: 1 }, "+=0.3");

        // tl2.set("#img-HCPMobile-two", { opacity: 0 })
        // tl2.set('#img-HCPMobile-two', { opacity: 1 }, "+=0.4");
        // tl2.set('#img-HCPMobile-two', { opacity: 1 }, "+=0.3");

        // tl2.set("#img-HCPMobile-two-two", { opacity: 0 })
        // tl2.set('#img-HCPMobile-two-two', { opacity: 1 }, "+=0.4");
        // tl2.set('#img-HCPMobile-two-two', { opacity: 1 }, "+=0.3");

        // console.log(" End Point of HCP",ScrollTrigger.getAll());

    }, [data])

    return <div data-w-id="1cc4cb5c-17be-01d0-1900-788f6370b435" id="hcpcontainer2" style={{ display: 'flex', flexDirection: 'column', width: '100%',zIndex:2, height: '330vh', }} >
        <div style={{ position: 'absolute', left: '0%', right: '0%', bottom: '0%', top: 'auto',height: '330vh', }} >
            <div id="hcp-container" style={{ flex: 1, position: 'sticky', minHeight: '100vh', top: '0px' }}>
                <div className="af-class-founder-image-1" id="img-HCPMobile-one" style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100vw', marginLeft: '-20px', marginRight: '-20px', zIndex: 9, left: 0, top: 0 }}  >
                    <span id='text-HCPMobile-one' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                        <div className="af-class-section" style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: '100%', height: '100%', display: 'flex' }} >
                            <div className="af-class-container w-container" style={{ alignSelf: 'center' }} >
                                {/* <h1 data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className='af-class-h1-article' style={{ marginLeft: '0px', marginBottom: '30px', marginTop: '0px' }} >People Speak</h1> */}
                                <div id="txt-health-one-one">
                                    <p ref={(el) => {
                                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                            if (isTab) {
                                                el.style.setProperty('font-size', '20px', 'important');
                                                el.style.setProperty('line-height', '36px', 'important');
                                            } else {
                                                el.style.setProperty('line-height', '28px', 'important');
                                            }
                                        }
                                    }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                        “Trainings are integral to employees’ skill development. They help in the areas of communication, leadership, time management, motivation, empathy for patients, team building, etc — all essential for healthcare workers. We are fortunate to work with Dr. Reddy’s through trainings like Abhilasha, Sarathi, Mantra and Manthan. Additionally, thanks to their outbound training programme for the senior leadership team, they helped us hone our leadership skills.”  <br /><br /><strong>Nithya Kalyani<br />HR Head | MS Ramaiah Memorial Hospital, Bangalore</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>


                <div className="af-class-founder-image-2" id="img-HCPMobile-one-two" style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100vw', marginLeft: '-20px', marginRight: '-20px', zIndex: 9, left: 0, top: 0 }}  >
                    <span id='text-HCPMobile-one' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                        <div className="af-class-section" style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: '100%', height: '100%', display: 'flex' }} >
                            <div className="af-class-container w-container" style={{ alignSelf: 'center' }} >
                                {/* <h1 data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className='af-class-h1-article' style={{ marginLeft: '0px', marginBottom: '30px', marginTop: '0px' }} >People Speak</h1> */}
                                <div id="txt-health-one-two">
                                    <p ref={(el) => {
                                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                            if (isTab) {
                                                el.style.setProperty('font-size', '20px', 'important');
                                                el.style.setProperty('line-height', '36px', 'important');
                                            } else {
                                                el.style.setProperty('line-height', '28px', 'important');
                                            }
                                        }
                                    }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                        “DRFHE is doing fantastic work with us, such as the Sarathi module for customer care executives, Sanjeevani for pharmacists, Inner Circle for doctors, Abhilasha for nurses, and Manthan and Mantra for heads of departments and managerial staff. These programmes consist of real examples, role plays, etc, which are very effective. They’re building soft skills in our staff and making them more confident. Our patient feedback is positive, and complaints have reduced.”<br /><br /><strong>Megha Gaikwad<br />Deputy HR Manager | Ruby Hall Clinic, Pune</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>



                <div className="af-class-founder-image-3" id="img-HCPMobile-two" style={{ backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100vw', marginLeft: '-20px', marginRight: '-20px', zIndex: 9, left: 0, top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                    <span id='text-HCPMobile-two' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                        <div className="af-class-section" style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: '100%', height: '100%', display: 'flex' }} >
                            <div className="af-class-container w-container" style={{ alignSelf: 'center' }} >
                                <div id="txt-health-two-one">
                                    <p ref={(el) => {
                                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                            if (isTab) {
                                                el.style.setProperty('font-size', '20px', 'important');
                                                el.style.setProperty('line-height', '36px', 'important');
                                            } else {
                                                el.style.setProperty('line-height', '28px', 'important');
                                            }
                                        }
                                    }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                        “We have hosted numerous sessions of DRFHE’s Sarathi and Abhilasha programmes and our employees are satisfied with the learnings, especially with regard to aspects like time management and anger management. We also conducted a Nursing Leadership Programme and have seen a great difference in the way our nurses have been taking care of their teams. And last year, DRFHE rolled out new initiatives tailored to the pandemic situation and people really liked it.”<br /><br /><strong>Vaishali Panchal<br />Training and Development | Rainbow Hospitals, Hyderabad</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>


                <div className="af-class-founder-image-4" id="img-HCPMobile-two-two" style={{ backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100vw', marginLeft: '-20px', marginRight: '-20px', zIndex: 9, left: 0, top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                    <span id='text-HCPMobile-two-two' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                        <div className="af-class-section" style={{ backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', width: '100%', height: '100%', display: 'flex' }} >
                            <div className="af-class-container w-container" style={{ alignSelf: 'center' }} >
                                <div id="txt-health-two-two">
                                    <p ref={(el) => {
                                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                            if (isTab) {
                                                el.style.setProperty('font-size', '20px', 'important');
                                                el.style.setProperty('line-height', '36px', 'important');
                                            } else {
                                                el.style.setProperty('line-height', '28px', 'important');
                                            }
                                        }
                                    }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px', opacity: 0 }} >
                                        “Mahatma Gandhi once said that unless we educate our people, we will not get full freedom. The Awareness of Life initiative by Dr. Reddy’s to educate people is a novel programme, raising awareness among them on issues like diabetes, hypertension, obesity, cancer and osteoporosis. These are well-chosen topics and as a local practitioner who is part of the programme, I am happy to do this work because it will help humanity in the long run.” <br /><br /><strong>Dr. R Govindharajan<br />MD | Madurai</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>

            </div>
        </div>
    </div>

}

export default HcpStoriesMobile;
