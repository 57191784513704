import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { HR } from '../../common/horizontalLine';
import { AnimeButton } from '../../common/AnimeButton';
import AnimeArrowFull from './AnimeArrowFull';
// import { AnimeDownloadButton } from '../../common/AnimeDownloadButton';

import YearFilterScroller from './YearFilterScroller';

import axios from 'axios';
import config from '../../../config';

import Results from './results.json';

import Lottie from 'react-lottie';
import * as loaderLottie from '../../../assets/Lottie/loader.json';

const Main = styled.div`
    width:100%;
    min-height:50vh;
    display: flex;
    flex-direction:column;
`;

const FileMain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`;

const TextHeading = styled.a`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-bottom:8px !important;
  line-height:30px !important;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
      color: #000;
      text-decoration: underline !important;
  }
  @media (max-width: 1030px) {
    font-size: 16px !important;
    line-height:24px !important;
    width: 40% !important;
  }
`;

const TextLight = styled.p`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  @media (max-width: 1030px) {
    font-size: 12px !important;
    width: 40% !important;
  }
`;

// const scripts = [
//     { loading: fetch("webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
// ]

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const MediaNotes = (props) => {

  const [pressReleases, setPressReleases] = useState({});
  let currentYear = new Date().getFullYear();
  let tempArr = [];
  for (let i = Number(currentYear); i >= 2022; i--) {
    tempArr.push(i.toString());
  }
  const [yearsList, setYearsList] = useState([...tempArr]);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  useEffect(() => {
    triggerApiCall(selectedYear);
  }, []);

  const triggerApiCall = (year) => {
    if (!pressReleases.hasOwnProperty(year)) {
      var tempObj = {};
      var tempYears = [];
      var tempPR = {};
      axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/media_notes?include=field_file&sort=-field_date_for_order2&filter[field_covid_news_years]=" + year, })
        .then((res) => {
          const { data, included } = res.data;
          var imgPathsObj = {};
          for (var imgObj of included || []) {
            imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
          }
          for (var item of data) {
            if (tempYears.indexOf(item.attributes.field_covid_news_years) === -1) {
              tempYears.push(item.attributes.field_covid_news_years);
            }
            tempObj = {
              title: item.attributes.title,
              year: item.attributes.field_covid_news_years,
              dateAndTime: item.attributes.field_date_and_time,
              file: item.relationships.field_file.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_file.data.id] : "",
            }
            if (tempPR.hasOwnProperty(item.attributes.field_covid_news_years)) {
              var tempArr = [...tempPR[item.attributes.field_covid_news_years]];
              tempArr.push(tempObj);
              tempPR[item.attributes.field_covid_news_years] = [...tempArr]
            } else {
              tempPR[item.attributes.field_covid_news_years] = [tempObj]
            }
          }
          tempYears = tempYears.sort().reverse();
          setPressReleases(tempPR);
        }).catch((Err) => {
          console.log(Err, "-=-=-=  ERR")
        })
    }
  }


  return <Main>
    {selectedYear && selectedYear.length !== 0 && <>
      <YearFilterScroller id="MediaNotes" selectedYear={selectedYear} onChange={(year) => {
        setSelectedYear(year)
        triggerApiCall(year);
      }} data={yearsList} />
      <HR />
    </>}

    {
      selectedYear && selectedYear.length !== 0 && pressReleases[selectedYear] && pressReleases[selectedYear].map((item, index) => {
        return <>
          <FileMain>
            <span style={{ flex: 1, maxWidth: '80%' }} >
              <TextHeading target="_blank" href={item.file} >{item.title}</TextHeading>
              <TextLight>{item.dateAndTime}</TextLight>
            </span>
            <AnimeArrowFull target="_blank" href={item.file} />
          </FileMain>
          <HR />
        </>
      })
    }

    {
      !(selectedYear && selectedYear.length !== 0 && pressReleases[selectedYear] && pressReleases[selectedYear].length !== 0) && <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <Lottie options={defaultOptions}
          // height={150}
          width={"40vw"}
        />
      </div>
    }

  </Main>
}
