import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";
import { BsArrowRight } from "react-icons/bs";
import { downloadFile,SortMonthNames } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";
import { useMediaQuery } from "react-responsive";
import { ComposableMap } from "react-simple-maps";
import { isTab,mediaQueryRangeTab } from "../../../../../Utils/Utils";

const Label = styled.p`
padding-top: 15px;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  padding-left: 15%;
`;
const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 5px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownMain = styled.div`
  display:flex;
  flex-direction:row;
`;

const DropDownDiv = styled.div`
  width:30%;
  @media (max-width: 900px) {
    width:60%;
  }
`;

// const TextContainer = styled.div`
  
   
// `;

const TextContainer = styled.div`
  display: flex;
    flex-direction: column;
    margin-top: 50px ! important;
    margin-bottom: 50px ! important;
`;

const TextHeading = styled.h5`
display: flex !important;
flex-direction: row !important;
font-weight: 300 !important;
font-size: 25px !important;
font-family: Drlcircular !important;
margin-bottom: 40px !important;
@media (max-width: 900px) {
    font-size: 24px !important;
    margin-bottom: 20px !important;
}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 40px;
  padding-left: 25px;
  // justify-content: space-between;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.8;
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const TextContent = styled.a`
margin: 0 !important;
font-weight: 400 !important;
font-size: 18px !important;
font-family: Drlcircular !important;
margin-bottom: 20px !important;
text-decoration: none !important;
color: #000 !important;
cursor: pointer !important;
text-decoration:none !important;
&:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px !important ;
    line-height: 24px !important ;
    margin-bottom: 15px;
    font-weight: 300;
    text-decoration:underline !important;
  }
`;

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 70.5%;
    margin-left: 14%;
    margin-bottom: 15%;
    overflow-x: 40px;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }
    
    th,
    td {
      padding: 5px;
      border: 1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
        font-size: 14px !important ;
        line-height: 24px !important ;
      }
    }
    
    th {
      border: 1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1px solid #707070;
        font-size: 14px !important ;
        line-height: 24px !important ;
      }
      }
    }
  }
`;

export const BioMedicalMonthlyReports = (props) => {

  const [reportsom, setReportsom] = useState({});
    const [dropdownDataom, setDropdowndataom] = useState([]);
    const [selectedFilterDateom, setSelectedFilterDateom] = useState("");
    const [dropdownDataomm, setDropdowndataomm] = useState([]);
    const [selectedFilterDateomm, setSelectedFilterDateomm] = useState("");
    const [dropdownFacilitybm, setDropdownFacilitybm] = useState([]);
    const [selectedFilterFacilitybm, setSelectedFilterFacilitybm] = useState("");
  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  useEffect(() => {
    var tempReports = {};
    var tempDropdownData = [];
    var tempDropdownDatam = [];
    var tempDropdownDataFacility = [];
    var tempObj = {};
    var tempArr = [];
    axios({
        method: "get",
        url:
        config.serverUrl.base +
        "/jsonapi/node/biomedical_monthly_reports",
})
.then((res) => {
  // const res = { ...ApiData };
  console.log(res);
  console.log("result data=", res.data.data);
  // for (const report of res.data) {
  for (const report of res.data.data) {

    
      tempObj = {};
      tempArr = [];
      if (
          tempDropdownData.indexOf(
              report.attributes.field_year_bm
          ) === -1
      ) {
          tempDropdownData.push(report.attributes.field_year_bm);
      }
      tempDropdownData = tempDropdownData.sort().reverse();

      if (
          tempDropdownDatam.indexOf(
              report.attributes.field_month_bm
          ) === -1
      ) {
          tempDropdownDatam.push(report.attributes.field_month_bm);
      }
      tempDropdownData = tempDropdownData.sort().reverse();
      tempDropdownDatam = SortMonthNames(tempDropdownDatam) ;

      if (
        tempDropdownDataFacility.indexOf(
            report.attributes.field_facility_bm
        ) === -1
      ) {
          tempDropdownDataFacility.push(report.attributes.field_facility_bm);
      }
      tempDropdownData = tempDropdownData.sort().reverse();
      // tempDropdownDataFacility = SortMonthNames(tempDropdownDataFacility) ;
  

      ///logic change-----
      if (
        tempReports?.[report.attributes.field_year_bm] &&
        tempReports[report.attributes.field_year_bm]?.[
        report.attributes.field_month_bm
        ] &&  tempReports[report.attributes.field_year_bm]?.[
          report.attributes.field_month_bm
          ]?.[report.attributes.field_facility_bm]
    ) {          
      console.log('came to year and month and facility exist - additional record')

        tempArr =
            tempReports[report.attributes.field_year_bm]?.[
            report.attributes.field_month_bm
            ]?.[report.attributes.field_facility_bm];
        tempArr.push({
            title: report.attributes.title,
            description:
                report.attributes.field_table_bm.value,
        });
        tempObj[report.attributes.field_year_bm] = tempArr;
        tempReports[report.attributes.field_year_bm][
            report.attributes.field_month_bm
        ][report.attributes.field_facility_bm] = tempArr;
        // console.log(tempObj)
    }

    else if (tempReports?.[report.attributes.field_year_bm] &&
      tempReports[report.attributes.field_year_bm]?.[
      report.attributes.field_month_bm
      ]) {
        console.log('came to year and month exist but no facility')
        
        tempArr = [];
        
        tempArr.push({
            title: report.attributes.title,
            description:
                report.attributes.field_table_bm.value,
        });

        tempObj[report.attributes.field_facility_bm] = tempArr;
        tempReports[report.attributes.field_year_bm][
          report.attributes.field_month_bm][report.attributes.field_facility_bm] = tempArr;

      console.log("Report object= ", tempReports);

    }
    else if (tempReports?.[report.attributes.field_year_bm]) {
        console.log('came to year exist but no month / facility')
        tempObj = {};

        tempArr = [];
        tempArr.push({
            title: report.attributes.title,
            description:
                report.attributes.field_table_bm.value,
        });

        tempObj[report.attributes.field_facility_bm] = tempArr;
        console.log("temp object  ===========", tempObj); 

        tempReports[report.attributes.field_year_bm][
          report.attributes.field_month_bm] = tempObj;

          console.log("Report object= ", tempReports);

    }
    else {
      console.log('came to initialize temp reports for the first time')

      tempArr = [];
        tempObj = {};
        tempArr.push({
            title: report.attributes.title,
            description:
                report.attributes.field_table_bm.value,
      });

      tempObj[report.attributes.field_facility_bm] = tempArr;
      console.log("temp object  ===========", tempObj); 

      var tempObj2 = {}
      tempObj2[report.attributes.field_month_bm] = tempObj;
      console.log("temp object 2 ===========", tempObj2); 

      tempReports[report.attributes.field_year_bm] = tempObj2;

      console.log("Report object= ", tempReports);
    }  
    // logic change end
  }

  if (tempDropdownData && tempDropdownData.length) {
      setSelectedFilterDateom(tempDropdownData[0]);
  }
  if (tempDropdownDatam && tempDropdownDatam.length) {
      setSelectedFilterDateomm(tempDropdownDatam[0]);
  }
  if (tempDropdownDataFacility && tempDropdownDataFacility.length) {
    setSelectedFilterFacilitybm(tempDropdownDataFacility[0]);
  }
  // console.log(tempDropdownData);
  console.log(tempReports, "     setReportsom");
  setDropdowndataom(tempDropdownData);
  setReportsom(tempReports);
  setDropdowndataomm(tempDropdownDatam);
  setDropdownFacilitybm(tempDropdownDataFacility);
  })
  .catch((err) => {
      console.log(err, "err");
  });
}, []);

const MainContainer = styled.div`
position: relative;
z-index: 1;
width: 100vw;
background-color: #fff;
`;

const renderTableData = () => {
  if(reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm]?.[selectedFilterFacilitybm] && reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm]?.[selectedFilterFacilitybm][0].description){
    var keys = Object.keys(reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm]?.[selectedFilterFacilitybm][0].description);
    var tableData = reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm]?.[selectedFilterFacilitybm][0].description;
    // console.log([selectedFilterFacilitybm][0])

    // console.log(tableData)
    keys.pop();
    var result1 = [];
    keys.map(
      (item, index) => {
        var result = [];
        // console.log(result1)
          for (let rowIndex=0;rowIndex < tableData[item].length;rowIndex++) {
            if(item === "0"){
              result.push(<th style={{padding: '20px 15px',textAlign: 'left',}} >{tableData[item][rowIndex]}</th>)
            } else if(rowIndex === 2){
              result.push(<td style={{padding: '20px 15px'}} ><TextContent
              target="_blank"
                href={
                  config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/biomedicalreports/" +tableData[item][rowIndex]
                }
              >
                Click here
              </TextContent></td>)
            } else {
              result.push(<td style={{padding: '20px 15px', wordBreak: 'break-all', whiteSpace: 'pre-wrap'}} >{tableData[item][rowIndex]}</td>)
            }
          }
          result1.push(<tr>{result}</tr>)
      }
    )}
    return result1;
}
const isPhoneScreen = useMediaQuery({ query: '(max-width: 500px)' })
return (<SubContainer >
  {/* <SubSubContainer> */}
    <TextContainer style={{ marginInline: "auto"}}>
  {/* <TextHeading > BIO MEDICAL  ANNUAL REPORTS</TextHeading> */}
    <div style={{display: "flex", flexDirection:minWidthBool?'column': "row", width: '95%',marginBottom:'60px' }}>
      <Label
      style={{
        margin: "-0.1em", 
        display: "flex",
        fontSize: "18px",
        alignSelf: minWidthBool?'start':"center",
        width:minWidthBool?'80%': '40%'
      }}>
        SELECT YEAR AND FACILITY
      </Label>
      <DropDownMain
        style={{
          display: "flex",
          flexDirection:minWidthBool?'column': "row",
          width:minWidthBool? '200%': '95%'
        }}
      >
        <DropDownDiv  style={{ width:'150px' , marginRight: minWidthBool ? '0' :'30px',marginBottom: minWidthBool ? '30px' :'0'}} >
          <Cdropdown
            data={dropdownDataom}
            placeholder="Year"
            id="reportsbmyear"
            selectedValue={selectedFilterDateom}
            onChangeValue={(val) => setSelectedFilterDateom(val)}
          ></Cdropdown>
        </DropDownDiv>
        <DropDownDiv style={{  width:'160px',marginRight: minWidthBool ? '0' :'30px',marginBottom: minWidthBool ? '30px' :'0' }}>
          <Cdropdown
            data={dropdownDataomm}
            placeholder="Month"
            id="reportsbmmonth"
            selectedValue={selectedFilterDateomm}
            onChangeValue={(val) => setSelectedFilterDateomm(val)}
          ></Cdropdown>
        </DropDownDiv>
        <DropDownDiv  style={{ width: '450px'}} >
          <Cdropdown
            data={dropdownFacilitybm}
            placeholder="Facility"
            id="reportsbmfacility"
            selectedValue={selectedFilterFacilitybm}
           
            onChangeValue={(val) => setSelectedFilterFacilitybm(val)}
          ></Cdropdown>
        </DropDownDiv>
      </DropDownMain>
    </div>
    </TextContainer>
    <TableContainer>
      <table>
        {renderTableData()}
      </table>
    </TableContainer>
  {/* </SubSubContainer>  */}
</SubContainer>
)

}