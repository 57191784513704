import React from 'react';
import styled from 'styled-components';
import {isTab} from '../../../Utils/Utils';

const WhiteImg = styled.img`
display: none;
 height:14px;
    width:28px;
    max-width:28px;
`;
const PrimaryImg = styled.img`
display: inline;
 height:14px;
    width:28px;
    max-width:28px;
`;

const HoverADiv = styled.a`
display:flex;
width:90px;
height:90px;
border-radius:90px;
align-items:center;
justify-items:center;
        -webkit-transition: all 0.2s linear;
-moz-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
-ms-transition: all 0.2s linear;
transition: all 0.2s linear;
&:hover{
    align-items:center;
    justify-items:center;
    width:90px;
    height:90px;
    border-radius:90px;
    background-color:#5225b5 ;
}
&:hover ${WhiteImg} {
    display: inline;
}

&:hover ${PrimaryImg} {
    display: none;
}
    `;


const AnimeArrowFull = (props) => {

    return <HoverADiv {...props} 
    style={{ paddingLeft: window.matchMedia('(max-width: 820px)').matches || isTab ? '21px' : '0px', }}
    >
        <WhiteImg src="/images/white-arrow_right.svg" style={{ width: '27px', height: '13px', maxWidth: '27px',left:'31.5px' }} loading="lazy" alt className="af-class-white-arrow af-class-hide" />
        <PrimaryImg src="/images/purple-right-arrow.svg" loading="lazy" style={{ left: '2px', width: '27px', height: '13px', maxWidth: '27px' }} alt className="af-class-black-arrow af-class-reversed" />
    </HoverADiv>
}

export default AnimeArrowFull;