import React from 'react';

const HeroBanner = () => {

  var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                   navigator.userAgent &&
                   navigator.userAgent.indexOf('CriOS') == -1 &&
                   navigator.userAgent.indexOf('FxiOS') == -1;

    return (<div data-w-id={isSafari && "3de728df-e436-5d1f-ff86-77f8d7e66adf"} id="who-we-are-main" className="af-class-section af-class-homepage" style={{minHeight:'70vh',maxHeight:'70vh',paddingBottom:'9vh'}} >
    <div data-w-id={!isSafari && "3de728df-e436-5d1f-ff86-77f8d7e66adf"} className="af-class-container w-container">
      {/* <div className="af-class-h1-tag" > */}
        {/* <h1 className="af-class-h1-heading af-class-small af-class-_80" style={{margin:0}} >Our purpose</h1> */}
      {/* </div> */}
      <div className="af-class-h1-block">
        {/* <h1 className="af-class-h1-heading af-class-small af-class-_40" style={{margin:0,marginTop:'10vh'}} >We accelerate access to affordable and innovative medicines to help patients lead healthier lives</h1> */}
        <h1 className="af-class-h1-heading af-class-small af-class-_40" style={{margin:0,marginTop:'10vh'}} >We accelerate access to affordable and innovative medicines because Good Health Can’t Wait.</h1>
      </div>
      {/* <a href="https://dr-reddy.webflow.io/articles/imagining-the-impossible" className="af-class-link-arrow af-class-home-hero w-inline-block">
        <div className="af-class-link-circle af-class-purple" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
      </a> */}
    </div>
  </div>
    )
}

export default HeroBanner;