import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import Cdropdown from "../../../../common/Cdropdown";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { EnquiryFormMobile } from './ContactFormMobile';
import { EnquiryFormTab } from './ContactFormTab';

import { FiChevronDown } from 'react-icons/fi';
import { isValidEmail, isValidZipcode, isValidIndianMobile, isEmpty, isValidFolioNo, isValidPancode } from "../../../../../Utils/Utils";

import axios from 'axios';
import config from '../../../../../config';

const TextInputRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 35px;
`;

const TextInput = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 50%;
`;

const TextInputEnd = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
width: 50%;
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    `;

const FormHeading = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 70px;
`;

const TextLight = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: 300;
    font-family: Drlcircular;
    margin: 0;
    margin-top: 10px;
    `;

const TextBold = styled.p`
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
    margin-bottom:50px !important;
    `;

const Label = styled.p`
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
    margin-right: 40px !important;
    width: 140px !important;
    `;

const Input = styled.input`
    border: 0px;
    background-color: #fff;
    height: 50px;
    width: 50%;
    font-family: Drlcircular;
    letter-spacing:0px !important;
    &:focus{
        outline: none !important;
        border:1px solid #5225B5;
        border-radius: 5px;
    }
    padding: 20px 15px;
    border-radius: 5px;

`;

const TextArea = styled.div`
    display: flex;
flex-direction: row;
align-items: center;
letter-spacing:0px !important;
width: 100%;
`;

const TextAreaInput = styled.textarea`
border: 0px;
background-color: #fff;
height: 250px;
font-family: Drlcircular;
resize: none;
width: 85%;
padding:10px;
border-radius: 5px;
&:focus{
        outline: none !important;
        border:1px solid #5225B5;
    }
`;

const DropDown = styled.a`
    background-color: #fff !important;
    height: 50px !important;
    width: 50% !important;
    display: flex !important;
    flex-direction: row !important;
    padding: 15px !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: pointer !important;
    text-decoration: none !important;
    /* border:1px solid #5225B5;
    border-radius: 5px; */
`;

const DropDwonLabel = styled.p`
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
`;

const AcceptPolicySection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    margin: 45px 0px;
`;

const AcceptTextLight = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: 300;
    font-family: Drlcircular;
    margin: 0;
`;

const AcceptTextLink = styled.a`
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    font-family: Drlcircular;
    margin: 0;
    cursor: pointer;
    text-decoration: none !important;
    &:hover{
        text-decoration: underline !important;
        color: #000000;
    }
`;

const RadioButton = styled.a`
    cursor: pointer;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    border: #5225B5 solid 0.5px;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    margin-right: 15px;
    margin-top: -12px;
`;

const RadioActive = styled.div`
    background-color: #FF5046;
    width: 12px;
    height: 12px;
    border-radius: 12px;
`;

const RobotText = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: 300;
    font-family: Drlcircular;
    margin: 0;
    margin-top: 20px !important;
`;

const ErrorText = styled.span`
    font-size: 12px!important;
    color: #FF5046!important;
    font-weight: 400!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    line-height: 14px !important;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
`;

const ClearButton = styled.a`
    font-size: 20px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #9090905D !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    margin-right: 25px !important;
    text-decoration: none !important;
    min-width:190px;
    max-width:190px;
    align-items:center;
    justify-content:center;
    text-align:center;
`;

const SubmitButton = styled.a`
    font-size: 20px !important;
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #5225B5 !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    min-width:190px;
    max-width:190px;
    align-items:center;
    justify-content:center;
    text-align:center;
`;

const StyledToastContainer = styled(ToastContainer)`
        .Toastify__toast-body {
            font-family: Drlcircular !important;
            font-size:20px !important;
            font-weight:400 !important;
        }
        .Toastify__toast-icon {
            width:32px;
            height:32px;
            margin-left:10px;
        }
    `;

export function EnquiryForm(props) {

    const recaptchaRef = React.createRef();

    const [errorToastBool, setErrorToastBool] = useState(false);
    const [physicalBool, setPhysicalBool] = useState(true);

    const [formData, setFormData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        officeAddress: '',
        pan: '',
        folioNo: '',
        address: '',
    })
    const [formErrData, setFormErrData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        officeAddress: '',
        pan: '',
        folioNo: '',
        address: '',
    })

    const clearForm = () => {
        setPhysicalBool(true);
        setFormErrData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            officeAddress: '',
            pan: '',
            folioNo: '',
            address: '',
        })
        setFormData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            officeAddress: '',
            pan: '',
            folioNo: '',
            address: '',
        })
    }

    const SubmitForm = () => {
        var submitBool = true;
        var errorMsg = '';
        var formErrDataTemp = { ...formErrData };
        if (!isEmpty(formData.fName).status) {
            formErrDataTemp = ({ ...formErrDataTemp, fName: isEmpty(formData.fName).message })
            submitBool = false;
        }
        if (!isEmpty(formData.lName).status) {
            formErrDataTemp = ({ ...formErrDataTemp, lName: isEmpty(formData.lName).message })
            submitBool = false;
        }
        if (!isValidIndianMobile(formData.phNo).status) {
            formErrDataTemp = ({ ...formErrDataTemp, phNo: isEmpty(formData.phNo).message })
            submitBool = false;
        }
        if (!isEmpty(formData.officeAddress).status) {
            formErrDataTemp = ({ ...formErrDataTemp, officeAddress: isEmpty(formData.officeAddress).message })
            submitBool = false;
        }
        if (!isValidEmail(formData.email).status) {
            formErrDataTemp = ({ ...formErrDataTemp, email: isEmpty(formData.email).message })
            submitBool = false;
        }
        if (!isValidPancode(formData.pan).status) {
            formErrDataTemp = ({ ...formErrDataTemp, pan: isValidPancode(formData.pan).message })
            submitBool = false;
        }
        if (!isValidFolioNo(formData.folioNo).status) {
            formErrDataTemp = ({ ...formErrDataTemp, folioNo: isValidFolioNo(formData.folioNo).message })
            submitBool = false;
        }
        if (!isEmpty(formData.address).status) {
            formErrDataTemp = ({ ...formErrDataTemp, address: isEmpty(formData.address).message })
            submitBool = false;
        }
        setFormErrData({ ...formErrDataTemp })
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!(recaptchaValue && recaptchaValue.length)) {
            errorMsg = 'Please complete the Captcha';
        }
        // if (!acceptBool) {
        //     errorMsg = 'Please accept and agree the terms and privacy.';
        // }
        if (submitBool && errorMsg.length === 0) {
            setErrorToastBool(false);
            axios({
                method: "get",
                url: config.serverUrl.base + "/api/form/token"
            }).then((res) => {
                if (res.status == 200) {

            axios({
                method: "post",
                url: config.serverUrl.base + "/jsonapi/node/form1", data: {
                    "data": {
                        "type": "node--form1",
                        "attributes": {
                            "title": "form data 1",
                            "field_fname": formData.fName,
                            "field_lname": formData.lName,
                            "field_email": formData.email,
                            "field_phno": formData.phNo,
                            "field_officeresidence": formData.officeAddress,
                            "field_pan": formData.pan,
                            "field_foliono": formData.folioNo,
                            "field_address": formData.address,
                            "field_modeofholding": physicalBool ? "Physical" : "Electronic",
                        }
                    }
                },
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Applications': res.data.key,
                },
            }).then((res) => {
                console.log(res, " =-=-=-=  ")
                toast.success('Your details have been submitted successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: 'message'
                })
            }).catch((err) => { })
        }}).catch((err) => { })
        } else {
            setErrorToastBool(true);
            errorMsg = !submitBool ? 'Please check the errors and retry again' : errorMsg;
            toast.warning(errorMsg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: 'message'
            })
        }
        // else {
        //     toast.error('Please check and fill form with proper values', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         toastId: 'message'
        //     })
        // }
    }

    const pocData = ["Business Development Opportunity",
        "Company Information",
        "API Manufacturing",
        "Unit Dose Bar Code Database Request",
        "IIT Application Request ",
        "Formulation Manufacturing ",
        "Process Request ",
        "Printed Materials Request ",
        "Production Question or Comment ",
        "Website Question or Comment ",
        "Other"];

    const CloseButton = ({ closeToast }) => (
        <img loading="lazy" src="/images/close-fff.svg" height="18px" width="18px" style={{ margin: '10px', marginTop: '14px' }} />
    );

    return window.matchMedia('(max-width: 767px)').matches ? <EnquiryFormMobile /> : window.matchMedia('(max-width: 820px)').matches ? <EnquiryFormTab/> :  <FormSection>

        <TextBold>Name of the sole/first shareholder:</TextBold>

        <TextInputRow>
            <TextInput>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >First Name<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.fName}</ErrorText>
                </span>
                <Input type="input" value={formData.fName} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, fName: ev.target.value })
                    setFormErrData({ ...formErrData, fName: validation.status ? '' : validation.message })
                }} />
            </TextInput>
            <TextInputEnd>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >Mobile Number<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.phNo}</ErrorText>
                </span>
                <Input maxLength={10} onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }} type="input" value={formData.phNo} onChange={(ev) => {
                    var validation = isValidIndianMobile(ev.target.value, 'Mobile Number');
                    setFormData({ ...formData, phNo: ev.target.value })
                    setFormErrData({ ...formErrData, phNo: validation.status ? '' : validation.message })
                }} />
            </TextInputEnd>
        </TextInputRow>

        <TextInputRow>
            <TextInput>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >Last Name<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.lName}</ErrorText>
                </span>
                <Input type="input" value={formData.lName} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, lName: ev.target.value })
                    setFormErrData({ ...formErrData, lName: validation.status ? '' : validation.message })
                }} />
            </TextInput>
            <TextInputEnd>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >Office/Residence<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.officeAddress}</ErrorText>
                </span>
                <Input type="input" value={formData.officeAddress} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, officeAddress: ev.target.value })
                    setFormErrData({ ...formErrData, officeAddress: validation.status ? '' : validation.message })
                }} />
            </TextInputEnd>
        </TextInputRow>

        <TextInputRow>
            <TextInput>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >Email<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.email}</ErrorText>
                </span>
                <Input type="input" value={formData.email} onChange={(ev) => {
                    var validation = isValidEmail(ev.target.value);
                    setFormData({ ...formData, email: ev.target.value })
                    setFormErrData({ ...formErrData, email: validation.status ? '' : validation.message })
                }} />
            </TextInput>
            <TextInputEnd>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >PAN<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.pan}</ErrorText>
                </span>
                <Input type="input" value={formData.pan} onChange={(ev) => {
                    var validation = isValidPancode(ev.target.value);
                    setFormData({ ...formData, pan: ev.target.value })
                    setFormErrData({ ...formErrData, pan: validation.status ? '' : validation.message })
                }} />
            </TextInputEnd>
        </TextInputRow>

        <TextInputRow>
            <TextInput>
                <span style={{ display: 'flex', flexDirection: 'column', width: '31%' }} >
                    <Label style={{ fontSize: "18px" }} >DP ID/Client ID/Folio No<span style={{ color: '#FF5046' }} >*</span></Label>
                    <ErrorText>{formErrData.folioNo}</ErrorText>
                </span>
                <Input type="input" value={formData.folioNo} onChange={(ev) => {
                    var validation = isValidFolioNo(ev.target.value);
                    setFormData({ ...formData, folioNo: ev.target.value })
                    setFormErrData({ ...formErrData, folioNo: validation.status ? '' : validation.message })
                }} />
            </TextInput>
            <TextInputEnd>
                <Label style={{ fontSize: "18px" }} >Mode of holding<span style={{ color: '#FF5046' }} >*</span></Label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '50%' }} >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                        <RadioButton onClick={() => {
                            setPhysicalBool(true)
                        }} >
                            {physicalBool && <RadioActive />}
                        </RadioButton>
                        <AcceptTextLight>Physical</AcceptTextLight>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                        <RadioButton onClick={() => {
                            setPhysicalBool(false)
                        }} >
                            {!physicalBool && <RadioActive />}
                        </RadioButton>
                        <AcceptTextLight>Electronic</AcceptTextLight>
                    </div>
                </div>
            </TextInputEnd>
        </TextInputRow>

        <TextArea>
            <span style={{ display: 'flex', flexDirection: 'column', width: '15.8%', alignSelf: 'flex-start' }} >
                <Label style={{ fontSize: "18px", }} >Address<span style={{ color: '#FF5046' }} >*</span></Label>
                <ErrorText>{formErrData.address}</ErrorText>
            </span>
            <TextAreaInput placeholder="Address" value={formData.address} onChange={(ev) => {
                var validation = isEmpty(ev.target.value);
                setFormData({ ...formData, address: ev.target.value })
                setFormErrData({ ...formErrData, address: validation.status ? '' : validation.message })
            }} />
        </TextArea>

        <span style={{ marginTop: '80px' }} />

        <ReCAPTCHA sitekey="6LfbvfkhAAAAAECKD1X5cTSQMbMD94PstmwxsMHx" ref={recaptchaRef} />
        <RobotText>Please confirm that you are not a robot</RobotText>

        <ButtonSection>
            <ClearButton onClick={clearForm} >Clear</ClearButton>
            <SubmitButton onClick={SubmitForm}>Submit</SubmitButton>
            <StyledToastContainer
                position="top-center"
                // autoClose={3000}
                autoClose={3000000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                toastStyle={{ backgroundColor: errorToastBool ? '#FF5046' : '#25B987', color: 'white' }}
                style={{ zIndex: 99999999, width: '565px', fontFamily: 'Drlcircular !important', fontSize: '20', fontWeight: '500', bottom: '100px' }}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={CloseButton}
            />
        </ButtonSection>

    </FormSection>
}
