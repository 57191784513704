/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'
import { CAlert } from '../Common/CAlert'
import { CPopUp } from '../Common/CPopUp'
import PurpleBack from "../Common/images/backArrowFullPrimary.svg";

import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import { getNewsArticleEndPoint, getMakeUpData, isTab, mediaQueryRangeTab } from '../Utils/Utils';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class DetailArticlesView extends React.Component {
  state = {
    renderPageBool: false,
    cookieBool: true,
    data: {},
    alertBool: false,
    countrySiteDesc: "",
    popupUrl: "",
    popupBool: false,
    // data: localStorage.getItem("data") ? JSON.parse(localStorage.getItem("data")) : {},
    // data: this.props.location.state ? this.props.location.state.data : {},
    landingSection3: false
  }

  constructor(props) {
    super(props);
    this.getOffset = this.getOffset.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/DetailArticlesController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = DetailArticlesView

        return Controller
      }

      throw e
    }

    // function onClickAnchorLink(ev) {
    //   ev.preventDefault();
    //   this.setState({ alertBool: true, countrySiteDesc: ev.currentTarget.href })
    // }

    // this.onClickAnchorLink = onClickAnchorLink.bind(this);
  }

  async componentWillMount() {
    console.log(this.props.location, "-=-=-=-", this.props.location.state?.navPath)
    const { hash, search } = this.props.location;
    var tempObj = await getNewsArticleEndPoint(hash, search);
    axios({ method: "get", url: config.serverUrl.base + tempObj.url, })
      .then((res) => {
        var tempData = { ...res.data };
        if (tempData && tempData.data && tempData.data.attributes && tempData.data.attributes.field_articlecategory && tempData.data.attributes.field_articlecategory === 'Landing-Section-3') {
          this.setState({ landingSection3: true })
        }
        else {
          this.setState({ landingSection3: false })
        }
        this.setState({ data: getMakeUpData(tempData, tempObj.makeUpData) }, () => {
          var anchors = document.getElementsByClassName('w-richtext')[0].getElementsByTagName('a');
          for (var i = 0; i <= anchors.length; i++) {
            anchors[i] && anchors[i].setAttribute('target', '_blank');
            if (anchors[i]?.getAttribute("href").indexOf(".pdf") === -1) {
              console.log(anchors[i], " =-=-  ", anchors[i]?.getAttribute("href"));

              var onClick = anchors[i]?.getAttribute("onclick");

              // if(typeof(onClick) != "function") {
              // anchors[i]?.setAttribute('onClick','this.onClickAnchorLink');
              anchors[i]?.addEventListener('click', (ev) => {
                ev.preventDefault();
                this.setState({ alertBool: true, countrySiteDesc: ev.currentTarget.href })
              });
            } else if (anchors[i]?.getAttribute("href").indexOf(".pdf") !== -1 && anchors[i]?.getAttribute("href").indexOf(config.serverUrl.imageUrlPrefix) !== -1) {
              anchors[i]?.addEventListener('click', (ev) => {
                ev.preventDefault();
                console.log(ev.currentTarget.href, "  =-=-=-=--=-==-   ")
                this.setState({ popupBool: true, popupUrl: ev.currentTarget.href })
              });
            }
            // } else {
            // if(anchors[i]){
            //   anchors[i].onClick = function (ev) {
            //       onClickAnchorLink(ev,anchors[i]?.getAttribute("href"));
            //       onClick();
            //     };
            // }
            // }

          }
          var imgs = document.getElementsByClassName('w-richtext')[0].getElementsByTagName('img');
          for (var i = 0; i <= imgs.length; i++) {
            imgs[i] && imgs[i].removeAttribute('height');
            imgs[i] && imgs[i].removeAttribute('width');
          }
        });
      })
      .catch((err) => {
        console.log(err, "-=-=-=  ERR")
      })
  }


  componentWillUnmount()  {
    window.removeEventListener('scroll', this.handleScroll);
  };

  getOffset( element ) {
      var rect = element.getBoundingClientRect();
      return {
          x: rect.left,
          y: rect.top
      };
  }

  handleScroll(event) {
    // console.log('event triggered...');
    // let scrollTop = this.getOffset(document.getElementById("content-section")).y;
    // if (scrollTop < 50) {
    //     document.getElementById("back").style.opacity=0
    //     document.getElementById("back2").style.opacity=1
    // }
    // else {
    //     document.getElementById("back").style.opacity=1
    //     document.getElementById("back2").style.opacity=0
    // }
    // console.log('scrolltop=', scrollTop);
  };

  componentDidMount() {

    window.addEventListener('scroll', this.handleScroll);

    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60952b8da0e265c96c1fde14'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
        with (this) {
          eval(arguments[0])
        }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true })
      }
      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = DetailArticlesView.Controller !== DetailArticlesView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    const imageUrl = config.serverUrl.imageUrlPrefix;
    var content = this.state.data.content ? this.state.data.content.split(imageUrl).join(config.serverUrl.imageBase + imageUrl) : "";

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }

            .back-button-sticky {
              position: -webkit-sticky;
              position: sticky;
              top: 200px;
            }

        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span id="Navbar" className="af-view">
          <MenuSection />
          <div style={{ top: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '120px': '48px' }}>
            <CAlert enableAlert={this.state.alertBool} onClose={() => { this.setState({ alertBool: false }) }} countrySiteDesc={this.state.countrySiteDesc} />
            <CPopUp enableAlert={this.state.popupBool} onClose={() => { this.setState({ popupBool: false }) }} href={this.state.popupUrl} />
            {/* <MenuSection backButton={true} logoColor="white" className="af-class-transparent" /> */}
            <div className="af-class-sticky-article-container">
              <div className="af-class-section af-class-article-hero af-class-wf-section" style={{ backgroundImage: this.state.data.coverImage ? "url(" + config.serverUrl.imageBase + this.state.data.coverImage + ")" : null, height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '35vh' : '55vh', backgroundPosition: "0% 0%" }}>
                <div className="af-class-black-overlay af-class-articles-header" />
                <div className="af-class-container w-container">
                  <h1 className="af-class-h1-article" style={{ paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '60px', fontSize: isTab && '36px', lineHeight: isTab && '42px' }} >{this.state.landingSection3 ? '#WeAreDrReddys' : this.state.data.title}</h1>
                </div>
              </div>
              <div id="content-section" className="af-class-section af-class-article-section af-class-wf-section" style={{ paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '60px' }} >
                <div className="af-class-container af-class-article w-container">
                  <div className="back-button-sticky">
                  <a id="back" onClick={() => {
                    this.props.history.goBack();
                  }} style={{ position: 'absolute', left: '1%', top: '1%',  marginLeft: '-200px', zIndex: 999, display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
                    <img loading="lazy" src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
                    <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
                  </a>
                  </div>
                  {/* <a id="back2" onClick={() => {
                    this.props.history.goBack();
                  }} style={{ opacity: 0, position: 'fixed', left: '11%', top: '20%', zIndex: 999, display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
                    <img loading="lazy"  src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
                    <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
                  </a> */}
                  <div style={{ fontSize: isTab && '18px', lineHeight: isTab && '32px', margin: isTab && '0 10vw' }} className="af-class-rich-text-block w-richtext" dangerouslySetInnerHTML={{
                    __html: content
                  }} />
                </div>
                <a href="#Navbar" data-w-id="6dfa7d23-fc03-1778-9347-705299f4749e" className="af-class-back-to-top-wrapper w-inline-block">
                  <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                  <div>Back to Top</div>
                </a>
              </div>
            </div>

            {this.state.data.synopsis && this.state.data.synopsis.length && this.state.data.synopsis.length !== 0 && <div id="extendable-section" style={{ paddingTop: '0px', paddingBottom: '32px', backgroundColor: '#EDEDED', height: !this.state.expandingDivBool && this.state.openExpandingDivBool && "90%" }} className={"af-class-section af-class-extendable-section" + (this.state.expandingDivBool ? "-reached" : "")}>
              <div className="af-class-container w-container" style={{ backgroundColor: '#EDEDED' }}>
                {/* <div style={{ display: 'flex', justifyContent: 'end', marginTop: this.state.expandingDivBool ? '60px' : '5px', }} > */}
                {!this.state.expandingDivBool && <a onClick={() => {
                  if (this.state.openExpandingDivBool) {
                    document.body.style.overflow = "scroll"
                  } else {
                    document.body.style.overflow = "hidden"
                  }
                  this.setState({ openExpandingDivBool: !this.state.openExpandingDivBool })
                }} style={{ alignSelf: 'flex-end', cursor: 'pointer', position: 'absolute', top: '10px', right: 0, zIndex: 999 }} >
                  {
                    this.state.openExpandingDivBool
                      ?
                      <img style={{ alignSelf: 'flex-end', cursor: 'pointer' }} src="images/AccordionOpen.svg" loading="lazy" alt />
                      :
                      <img style={{ alignSelf: 'flex-end', cursor: 'pointer' }} src="images/AccordionClose.svg" loading="lazy" alt />
                  }
                </a>}
                {/* </div> */}
                <div style={{ overflow: this.state.openExpandingDivBool && "scroll", height: this.state.openExpandingDivBool && "84vh", marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '15px' }} >
                  <div style={{ fontSize: '16px', lineHeight: isTab && '32px' }} className="af-class-rich-text-block w-richtext" dangerouslySetInnerHTML={{
                    __html: this.state.data.synopsis
                  }} />
                </div>
              </div>
            </div>}

            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(DetailArticlesView)

/* eslint-enable */
