import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import config from '../../config';

const VideoSection = () => {

  const [videoPopup, showVideoPopup] = useState(false);

  const _video = useRef(null);

  useEffect(() => {
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/landingpagevideo?include=field_video", })
      .then((res) => {
        console.log(res, " -=-=-=-=   res  ")
      })
      .catch((Err) => {
        console.log(Err, " -=-=-=-=   Err  ")
      })
  }, []);

  useEffect(() => {
    let overflow = videoPopup ? "hidden" : "auto";
    document.body.style.overflow = overflow;
  }, [videoPopup]);

  return (
    <>
      {videoPopup &&
        <>
          <div id="lightbox"
            style={{
              position: "absolute",
              zIndex: 10001,
              overflow: "visible",
              width: "100%",
              top: `${window.pageYOffset}px`
            }}
            onClick={() => {
              showVideoPopup(false); _video.current.play();
            }}
          >
            <a href="/#" className="boxclose" onClick={(e) => { e.preventDefault(); }}>X</a>
            <video autoPlay controls loop muted playsInline data-wf-ignore="true" data-object-fit="cover"
              style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + "/videos/Landing-BG-video-poster-00001.jpg)",
                width: "100%",
                height: `${window.innerHeight}px`
              }}
            >
              <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.mp4"} data-wf-ignore="true" />
              <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.webm"} data-wf-ignore="true" />
            </video>
          </div>
          <div onClick={() => { showVideoPopup(false); _video.current.pause() }} style={{
            position: "fixed",
            top: "0%",
            left: "0%",
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            zIndex: 10000,
            opacity: "0.8",
            filter: "alpha(opacity=80)"
          }}></div>
        </>
      }
      <div className="af-class-homepage-bg-video-section"
        onClick={() => {
          _video.current.pause();
          showVideoPopup(true);
        }}
      >
        <div data-poster-url={"url(" + process.env.PUBLIC_URL + "/videos/Landing-BG-video-poster-00001.jpg)"} data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-homepage-bg-video w-background-video w-background-video-atom">
          <video autoPlay loop ref={_video} style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/videos/Landing-BG-video-poster-00001.jpg)" }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
            <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.mp4"} data-wf-ignore="true" />
            <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.webm"} data-wf-ignore="true" />
          </video>
          <a href="/#" onClick={() => {
            _video.current.pause();
            showVideoPopup(true);
          }} className="af-class-play-circle w-inline-block w-lightbox"
            // onClick={(e) => { e.preventDefault(); }}
            >
            <img src="/images/Icon-feather-play.svg" loading="lazy" alt="" className="af-class-play-icon" />
          </a>
        </div>
      </div>
      {/* <div
        className={`cursor-dot-outline ${videoPopup ? "close" : "play"}`}
        ref={cursor}
        id="cursor"
        style={{
          top: pos.y,
          left: pos.x,
          opacity: visible ? 1 : 0,
          // transform: `translate(${pos.x}, ${pos.y}) translateZ(0px)`,
          transition: "opacity 0.6s cubic-bezier(0.165,0.84,0.44,1);"
        }}
      >
        <a href="/#" className="af-class-play-circle w-inline-block w-lightbox"
          onClick={(e) => { e.preventDefault(); }}>
          <img src="images/Icon-feather-play.svg" loading="lazy" alt="" className="af-class-play-icon" />
        </a>
      </div> */}
    </>
  );
}

export default VideoSection;




// import React, { useEffect, useRef, useState } from 'react'
// import axios from 'axios';
// import config from '../../config';

// const VideoSection = () => {

//   const [videoPopup, showVideoPopup] = useState(false);
//   const [pos, setPos] = useState({ x: 0, y: 0 });

//   const [visible, setVisible] = useState(false);

//   const _video = useRef(null);
//   const cursor = useRef(null);

//   useEffect(() => {
//     axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/landingpagevideo?include=field_video", })
//       .then((res) => {
//         console.log(res, " -=-=-=-=   res  ")
//       })
//       .catch((Err) => {
//         console.log(Err, " -=-=-=-=   Err  ")
//       })
//   }, []);

//   useEffect(() => {
//     cursor.current = requestAnimationFrame(animate);
//     return () => cancelAnimationFrame(cursor.current);
//   }, []);

//   useEffect(() => {
//     let overflow = videoPopup ? "hidden" : "auto";
//     document.body.style.overflow = overflow;
//   }, [videoPopup]);

//   const animate = timer => {
//     cursor.current = requestAnimationFrame(animate);
//   }

//   return (
//     <>
//       {videoPopup &&
//         <>
//           <div id="lightbox"
//             style={{
//               position: "absolute",
//               zIndex: 10001,
//               overflow: "visible",
//               width: "100%",
//               top: `${window.pageYOffset}px`
//             }}
//             onClick={() => {
//               showVideoPopup(false); _video.current.play();
//             }}
//           >
//             <a href="/#" className="boxclose" onClick={(e) => { e.preventDefault(); }}>X</a>
//             <video autoPlay controls loop style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/videos/Landing-BG-video-poster-00001.jpg)" }} muted playsInline data-wf-ignore="true" data-object-fit="cover"
//               style={{
//                 width: "100%",
//                 height: `${window.innerHeight}px`
//               }}
//             >
//               <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.mp4"} data-wf-ignore="true" />
//               <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.webm"} data-wf-ignore="true" />
//             </video>
//           </div>
//           <div onClick={() => { showVideoPopup(false); _video.current.pause() }} style={{
//             position: "fixed",
//             top: "0%",
//             left: "0%",
//             width: "100%",
//             height: "100%",
//             backgroundColor: "black",
//             zIndex: 10000,
//             opacity: "0.8",
//             filter: "alpha(opacity=80)"
//           }}></div>
//         </>
//       }
//       <div className="af-class-homepage-bg-video-section"
//         onClick={() => {
//           _video.current.pause();
//           showVideoPopup(true);
//         }}
//         onMouseOver={() => { setTimeout(() => { setVisible(true) }, 100); }}
//         onMouseMove={(e) => {
//           setTimeout(() => {
//             setPos({
//               x: e.pageX - 10,
//               y: e.pageY - 10
//             });
//           }, 120);
//         }}
//         onMouseOut={() => {
//           setVisible(false);
//         }}
//       >
//         <div data-poster-url="https://uploads-ssl.webflow.com/602c9aff04a981415df03c05/605007832b547e7aaa59efdd_Landing BG video-poster-00001.jpg" data-video-urls="https://uploads-ssl.webflow.com/602c9aff04a981415df03c05/605007832b547e7aaa59efdd_Landing BG video-transcode.mp4,https://uploads-ssl.webflow.com/602c9aff04a981415df03c05/605007832b547e7aaa59efdd_Landing BG video-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-homepage-bg-video w-background-video w-background-video-atom">
//           <video autoPlay loop ref={_video} style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/videos/Landing-BG-video-poster-00001.jpg)" }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
//             <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.mp4"} data-wf-ignore="true" />
//             <source src={process.env.PUBLIC_URL + "/videos/Landing-BG-video-transcode.webm"} data-wf-ignore="true" />
//           </video>
//         </div>
//       </div>
//       <div
//         className={`cursor-dot-outline ${videoPopup ? "close" : "play"}`}
//         ref={cursor}
//         id="cursor"
//         style={{
//           top: pos.y,
//           left: pos.x,
//           opacity: visible ? 1 : 0,
//           // transform: `translate(${pos.x}, ${pos.y}) translateZ(0px)`,
//           transition: "opacity 0.6s cubic-bezier(0.165,0.84,0.44,1);"
//         }}
//       >
//         <a href="/#" className="af-class-play-circle w-inline-block w-lightbox"
//           onClick={(e) => { e.preventDefault(); }}>
//           <img src="images/Icon-feather-play.svg" loading="lazy" alt="" className="af-class-play-icon" />
//         </a>
//       </div>
//     </>
//   );
// }

// export default VideoSection;