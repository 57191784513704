import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";
import { TextSlideAnimeButton } from "../../../../common/TextSlideAnimeButton";

import { animateScroll as scroll } from 'react-scroll';

const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-bottom:32px !important;
  font-family: Drlcircular;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Text = styled.p`
  font-size: 18px !important ;
  color: #000000 !important ;
  font-weight: 300 !important ;
  font-family: Drlcircular !important ;
  line-height: 30px !important ;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    line-height: 24px !important ;
  }
`;

const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBook = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;
const TextClick = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 12px;
    margin-bottom: 25px;
  }
`;
const CGRSection = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width:30%;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    width:80%;
    margin-bottom: 24px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

const ReportHeading = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  width: 15%;
  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
  }
`;

const ICSSection = styled.div`
  width: 100%;
  margin-top:40px;
`;

const content = styled.p`
  display: flex;
  flex-direction: column;
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration: underline !important;
  }
`;

export const PoliciesAndDocuments = (props) => {

  const [reportsge, setReportsge] = useState({});
    const [dropdownDatage, setDropdowndatage] = useState([]);
    const [selectedFilterDatege, setSelectedFilterDatege] = useState("");

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempArr = [];
    axios({
        method: "get",
        url:
            config.serverUrl.base +
            "/jsonapi/node/governanceearningscall?include=field_file_governance",
    })
        .then((res) => {
            console.log(res);
            for (const item of res.data.included) {
                tempIncludedObj[item.id] =
                    config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
                tempArr = [];
                if (tempDropdownData.indexOf(report.attributes.field_year) === -1) {
                    tempDropdownData.push(report.attributes.field_year);
                }
                tempDropdownData = tempDropdownData.sort().reverse();
                if (tempReports?.[report.attributes.field_year]) {
                    tempArr = tempReports[report.attributes.field_year];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[
                            report.relationships.field_file_governance.data.id
                        ],
                    });
                    tempReports[report.attributes.field_year] = tempArr;
                } else {
                    tempArr = [];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[
                            report.relationships.field_file_governance.data.id
                        ],
                    });
                    tempReports[report.attributes.field_year] = tempArr;
                }
            }
            if (tempDropdownData && tempDropdownData.length) {
                setSelectedFilterDatege(tempDropdownData[0]);
            }
            console.log(tempDropdownData);
            console.log(tempReports);
            setDropdowndatage(tempDropdownData);
            setReportsge(tempReports);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

  const [acceptedBool, setAcceptedBool] = useState((localStorage.getItem("acceptancePolicies") && JSON.parse(localStorage.getItem("acceptancePolicies")))?.policiesAndDocs ? true : false);

  return (
    <ICSSection>
    {
      acceptedBool
        ?
        <>
      <DropDownDiv>
        <Cdropdown
          data={dropdownDatage}
          placeholder="Fiscal Year"
          id="reportsge"
          selectedValue={selectedFilterDatege}
          onChangeValue={(val) => setSelectedFilterDatege(val)}
        />
      </DropDownDiv>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {reportsge?.[selectedFilterDatege] &&
          reportsge?.[selectedFilterDatege].map((item, index) => {
            return (
              <TextContent
              to={{ pathname: item.url }}
                            target="_blank"
                key={selectedFilterDatege + index}
                // onClick={() => {
                //   downloadFile(item.url);
                // }}
              >
                {item.title}
              </TextContent>
            );
          })}
      </div>
      </>
      :
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
          {/* <img loading="lazy" src={"/images/sec-filing.png"} style={{ height: '600px', width: '40%' }} /> */}
          <div style={{ width: window.matchMedia('(max-width: 820px)').matches?'100%':'48%' }} >
            <TextHeading>Safe Harbour</TextHeading>
            <Text>
              The documents contain forward-looking statements, as defined in the U.S. Private Securities Litigation Reform Act of 1995 . We have based these forward-looking statements on our current expectations and projections about future events.<br />
            </Text>
            <Text>
              Such statements involve known and unknown risks, uncertainties and other factors that may cause actual result to differ materially. Such factors include, but are not limited to, changes in general economic conditions, our ability to successfully implement our strategy, the market acceptance of and demand for our products, our growth and expansion, technological change and our exposure to market risks.<br />
            </Text>
            <Text>
              By their nature, these expectations and projections are only estimates and could be materially different from actual results in the future.<br />
            </Text>
            <Text>
              This communication is not an offer to sell or the solicitation of an offer to buy securities
              <br />
              <br />
              Click below if you accept these conditions
            </Text>
            <TextSlideAnimeButton onClick={() => {
              scroll.scrollTo('main-container')
              setAcceptedBool(true);
              var ls = localStorage.getItem("acceptancePolicies");
              ls = ls ? JSON.parse(ls) : { };
              ls.policiesAndDocs = true;
              localStorage.setItem("acceptancePolicies",JSON.stringify(ls));
            }} />
          </div>
        </div>
    }
    </ICSSection>
  )
}