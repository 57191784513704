import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import './bootstrap.min.css';
import './reset.min.css';
import './Common/customStyles.css';
import "./styles"
import "./scripts";
import { AboutUsView, MediaView, IndexView, AllNewsView, CovidNewsView, BusinessCapabilitiesView, DetailNewsArticle, DetailArticlesView, BusinessResponsibilityAndSustainabilityView, UnfinishedAgendaView, MeetOurLeadershipView, DetailBoardOfDirectorsView, ScienceResearchView, LifeAtDrlView, DetailTestimonialsView, PeopleStoriesView, CovidView, NotFoundView } from "./views";

import InvesterView from './pages/Investor/IndexView';
import ContactUsView from './pages/ContactUs/IndexView';
import BusinessPartnerView from './pages/BusinessPartner/IndexView';
import JobSeekerView from './pages/Job Seekers/indexView';

import PrivacyPolicyView from './pages/PrivacyPolicy/IndexView';
import RecruitmentFraud from "./pages/RecruitmentFraud";
import AccessibilityView from './pages/Accessibility/IndexView';
import CookiePolicyView from './pages/CookiePolicy/IndexView';
import TermsOfUseView from './pages/TermsOfUse/IndexView';
import ComplianceWithNYSEView from './pages/ComplianceWithNYSE/IndexView';
import CommitteesBoardView from './pages/CommitteesBoard/IndexView';
import OmbudspersonPolicyView from './pages/OmbudspersonPolicy/IndexView';

import DetailSubMenuPage from './pages/DetailSubMenuPage';
import DetailGlobalLocationMobile from './pages/DetailGlobalLocationMobile';

import PiReportNav from './views/PiReportNav';

import ReactGA from "react-ga4";

import ColombiaPrivacyPolicy from './pages/ColombiaPrivacyPolicy';

import ProductInfoView from './pages/ProductInfo';

import BioMedicalWasteMgmt from './pages/Investor/MainSection/ReportsAndFiling/SubModules/BioMedicalWasteMgmt';
import BioMedical from './pages/Investor/MainSection/ReportsAndFiling/SubModules/BioMedical';
import BioMedicalAnnual from './pages/Investor/MainSection/ReportsAndFiling/SubModules/BioMedicalAnnual';

const filePaths = ["/pi","/pi*","/pil","/pil*","/pel","/pel*", "/medguide","/medguide*", "/Nuroday*", "/SildooM25*", "/Vegaz*"];

function App() {

  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={IndexView} />
        <Route path="/drreddys-media" component={MediaView} />
        <Route path="/detail-news" component={DetailNewsArticle} />
        <Route path="/all-news" exact component={AllNewsView} />

        <Route path={["/who-we-are","/about-us","/our-story/about-us",'/our-story/about-us/*']} component={AboutUsView} />

        <Route path={["/business-and-capabilities","/our-products","/our-products/*"]} component={BusinessCapabilitiesView} />

        <Route path={["/business-responsibility-and-sustainability","/our-people-and-our-citizenship/sustainability","/our-people-and-our-citizenship/community/environmental-sustainability","/our-people-and-our-citizenship/planet/initiatives","/our-people-and-our-citizenship/planet/commitment-to-the-planet","/our-people-and-our-citizenship/planet/commitment-to-the-planet/*","/our-people-and-our-citizenship/community/*","/our-people-and-our-citizenship/community","/our-people-and-our-citizenship/sustainability/*"]} component={BusinessResponsibilityAndSustainabilityView} />

        {/* <Route path="/unfinished-agenda" component={UnfinishedAgendaView} /> */}
        <Route path="/articles" component={DetailArticlesView} />

        <Route path="/meet-our-leadership" component={MeetOurLeadershipView} />
        <Redirect push from="/our-story/leadership/board-of-directors" to="/meet-our-leadership" />
        <Redirect push from="/our-story/leadership/management-council" to="/meet-our-leadership" />

        <Route path="/Board-of-directors" component={DetailBoardOfDirectorsView} />
        <Route path="/ScienceResearch" component={ScienceResearchView} />

        <Route path={["/LifeAtDrReddys","/our-people-and-our-citizenship/humans-of-dr-reddy-s",'/our-people-and-our-citizenship/humans-of-dr-reddy-s/*',"/our-people-and-our-citizenship/life-at-drreddys","/our-people-and-our-citizenship/life-at-drreddys/*"]} component={LifeAtDrlView} />

        {/* <Route path={["/PeopleStories","/our-people-and-our-citizenship/patients","/our-people-and-our-citizenship/patients/*","/our-people-and-our-citizenship/healthcare-professionals","/our-people-and-our-citizenship/healthcare-professionals/*"]} component={PeopleStoriesView} /> */}

        {/* <Route path="/covid-news" component={CovidNewsView} />
        <Route path="/covid" component={CovidView} /> */}
        <Route path="/testimonials" component={DetailTestimonialsView} />

        <Route path={["/investor", "/investors/*"]} component={InvesterView} />
        {/* <Route path="/BioMedicalAnnualReports" component={BioMedicalAnnualReports} />*/}

         {/* <Route path="/BioMedicalWasteManagementReport" component={BioMedicalWasteMgmtSystem} />  */}

        <Route path="/biomedicalwastemanagementreport" exact component={BioMedicalWasteMgmt} />
        <Route path="/biomedicalwastemanagementreport/biomedicalwastereportmonthly" exact component={BioMedical} />
        <Route path="/biomedicalwastemanagementreport/biomedicalwastereportannual" exact component={BioMedicalAnnual} />

        {/* <Route path="/BioMedicalWasteManagementReport" component={BioMedicalWasteMgmt} />
        <Route path= "/biomedicalwastereportmonthly/" component={BioMedical} />
        <Route path="/biomedicalwastereportannual/" component={BioMedicalAnnual} /> */}

        {/* <Redirect path="/biomedicalwastemanagementreport/biomedicalwastereportannual/" push from="/biomedicalwastemanagementreport/biomedicalwastereportannual/" to="/#biomedical-abbual" /> */}

       {/* <Redirect push from="/biomedicalwastemanagementreport" to="/investor#reports-and-filing#biomedical-waste-monthly" />  */}

        {/* < Redirect push from="/biomedicalwastemanagementreport/biomedicalwastereportmonthly/" to="/investor#reports-and-filing#biomedical-waste-monthly" />

        <Redirect push from="/biomedicalwastemanagementreport/biomedicalwastereportannual/" to="/investor#reports-and-filing#biomedical-waste-annual" />

        <Redirect push from="/biomedicalwastemanagementreport" to="/investor#reports-and-filing#biomedical-waste-monthly" /> */}

        {/* <Redirect push from="/our-people-and-our-citizenship/sustainability" to="/investor#reports-and-filing#sustainability-reports" />
        <Redirect push from="/our-people-and-our-citizenship/community/environmental-sustainability" to="/investor#reports-and-filing#sustainability-reports" />
        <Redirect push from="/our-people-and-our-citizenship/planet/initiatives" to="/investor#reports-and-filing#sustainability-reports" /> */}

        <Redirect push from="/investors/presentations/presentations" to="/investor" />
        <Redirect push from="/investors/reports-and-filings/annual-reports" to="/investor" />

        <Route path="/contact-us" component={ContactUsView} />
        <Route path="/business-partner" component={BusinessPartnerView} />
        <Route path="/job-seeker" component={JobSeekerView} />
        <Route path="/privacy-policy" component={PrivacyPolicyView} />
        <Route path="/caution-notice-recruitment-fraud" component={RecruitmentFraud} />
        <Route path="/accessibility" component={AccessibilityView} />
        <Route path="/cookie-policy" component={CookiePolicyView} />
        <Route path="/terms-of-use" component={TermsOfUseView} />
        <Route path="/compliance-With-nyse" component={ComplianceWithNYSEView} />
        <Route path="/committees-of-the-board" component={CommitteesBoardView} />
        <Route path="/ombudsperson-policy" component={OmbudspersonPolicyView} />
        <Route path="/detail-sub-menu" component={DetailSubMenuPage} />
        <Route path="/detail-global-location" component={DetailGlobalLocationMobile} />

        <Route path={filePaths} component={PiReportNav} />

        <Route path="/productinfo/:gs1/:gtin" component={ProductInfoView} />

        <Route path={['/colombia/privacy-policy']} component={ColombiaPrivacyPolicy} />
        <Route path="/spain" component={() => { global.window && (window.location.href = "https://www.drreddys.es"); return null; }} />
        <Route path="/united-kingdom" component={() => { global.window && (window.location.href = "https://www.drreddys.uk"); return null; }} />
        <Route path="/germany" component={() => { global.window && (window.location.href = "https://www.betapharm.de"); return null; }} />
        <Route path="/cookie-policy.aspx" component={() => { global.window && (window.location.pathname = "cookie-policy"); return null; }} />
        <Route component={NotFoundView} />

      </Switch>
    </Router>
  );
}

export default App;
