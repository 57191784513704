export const MedicalInformationTable = [
    {
        name: "Algeria",
        contact: "Mehdi Oukrine",
        email: "mehdioukrine@drreddys.com",
        tel: '+213 770 57 1006'
    },
    {
        name: "Australia",
        tel: "Tel: 1-800-733-397",
        contact: "MarkLupi",
        email: "sales.au@drreddys.com",
        timings: "Timings: Mon – Fri 8am – 6pm AEST"

    },
    {
        name: "Bahrain",
        contact: "Alok Kumar",
        email: "alokk@drreddys.com"
    },
    {
        name: "Belarus",
        contact: "HrabouskayaVolha",
        email: "adverse@drreddys.com",
        tel: '+375 17 336 17 24'
    },
    {
        name: "Bolivia",
        contact: "Alex PatricioMatus Mandiola",
        email: "alexm@drreddys.com",
        tel: "+56-973354882"
    },
    {
        name: "Brazil",
        contact: "Roland Sousa",
        email: "carebrasil@drreddys.com",
        tel: "+55-800-878-9055"
    },
    {
        name: 'Brunei',
        contact: 'Nitin Mehrotra',
        email: 'nitin.mehrotra@drreddys.com'
    },
    {
        name: "Cambodia",
        contact: "Hau Bui Trung",
        email: "Trunghau@drreddys.com",
        tel: ' +84834066068'
    },
    {
        name: "Chile",
        contact: "Alex PatricioMatus Mandiola",
        email: "direccion.tecnica@drreddys.com",
        tel: '+56-973354882'
    },
    {
        name: "China",
        contact: "Lucy GUAN",
        email: "pv.china@drreddys.com",
        tel: '+86-106-5623099'
    },
    {
        name: "Canada",
        contact: 'Dr. Foram Vaishnav',
        email: "druginfo@drreddys.com",
        tel: "Tel: 1-855-845-1739",
        timings: "Timings: Monday to Friday, 9:00 AM to 5:00 PM, Eastern Time"
    },
    {
        name: "Colombia",
        contact: "Carolina Molano",
        email: "Farmacovigilancia.colombia@drreddys.com",
        tel: '+57-31-76407395'
    },
    {
        name: "Dominican republic",
        contact: "Prithwiraj Chaterjee",
        email: "prithwirajc@drreddys.com",
    },
    {
        name: "Ecuador",
        contact: "Alex PatricioMatus Mandiola",
        email: "alexm@drreddys.com",
        tel: "+56-973354882"
    },
    {
        name: "Ghana",
        contact: "Alok Kumar",
        email: "alokk@drreddys.com"
    },
    {
        name: "Guatemala",
        contact: "Prithwiraj Chaterjee",
        email: "prithwirajc@drreddys.com"
    },
    {
        name: "France",
        email: "pv-infomedfrance@drreddys.com",
        tel: "+ 33 (0) 1 85 78 17 34",
        timings: "(Monday through Friday 9.00 AM to 13.00 PM and 14.00 AM to 18.00 PM, local time)"
    },
    {
        name: "Germany",
        contact: 'Frau.Monika Haidinger',
        email: "pharmacovigilance@betapharm.de",
        tel: "Tel: +49 (0) 821 74881 0",
        timings: "Timings: 24 hrs"
    },
    {
        name: "India",
        contact: "Customer Services",
        email: "customerservices@drreddys.com",
        tel: "Tel: 1800 425 0014",
        timings: "Timings: Mondays through Friday, 8:00 AM to 6:00 PM local time"
    },
    {
        name: "Italy",
        contact: 'Luciana Gliatta',
        email: "farmacovigilanza@drreddys.com",
        tel: "Tel: +39 3926038707",
        timings: "Timings: Monday through Friday 9:00 AM to 1:00 PM and 2:00 PM to 6:00 PM local time"
    },
    {
        name: "Jamaica",
        contact: "Preetdarshi Patnaik",
        email: "preetpatnaik@drreddys.com",
        tel: '+1876 2810444'
    },
    {
        name: "Kazakhstan",
        contact: "MairaZhagiparova",
        email: "pharmacovigilance.kz@drreddys.com",
        tel: '7 727 394 13 05'
    },
    {
        name: "Malaysia",
        contact: "Nitin Mehrotra",
        email: "info.mys@drreddys.com"
    },
    {
        name: "Maldives",
        contact: "Rahul Shroti",
        email: "rahul.shroti@drreddys.com"
    },
    {
        name: "Mauritius",
        contact: "Rahul Shroti",
        email: "rahul.shroti@drreddys.com"
    },
    {
        name: "Myanmar",
        contact: "ZawSu Su",
        email: "MsSuSuZaw@drreddys.com",
        tel: ' +9 5 9797933518'
    },
    {
        name: "Nambia",
        contact: "HeatherDoody",
        email: "AdverseEvents.SA@drreddys.com",
        tel: '+27 636889916'
    },
    {
        name: "Nepal",
        contact: "Manish Pratap Singh",
        email: "manishpratapsingh@drreddys.com"
    },
    {
        name: "New Zealand",
        contact: "MarkLupi",
        email: "Customerservices.nz@drreddys.com",
        tel: "Tel: +64 9 2383447",
        timings: "Timings: 7:30- AM to 9:00 PM, New Zealand Time"
    },
    {
        name: "Paraguay",
        contact: "Alex PatricioMatus Mandiola   ",
        email: "alexm@drreddys.com",
        tel: "+56-973354882",
        timings: "Timings: 7:30- AM to 9:00 PM, Paraguay Time"
    },
    {
        name: "Peru",
        contact: "Alex PatricioMatus Mandiola   ",
        email: "alexm@drreddys.com",
        tel: "+56-973354882",
        timings: "Timings: 7:30- AM to 9:00 PM, Peru"
    },
    {
        name: "Philippines",
        contact: "Ma.Krishna Magalang",
        email: "Ma.KrishnaMagalang@drreddys.com",
        tel: '+639175582155'
    },
    {
        name: "Romania",
        contact: 'Corneliu Stanciu',
        email:"office@drreddys.ro",
        tel: "+40 212240032",
        timings: "Timings: 9:00 AM to 6:00 PM, local time"
    },
    {
        name: "Russia",
        contact: "Elena Zelenskaya",
        email: "adverse@drreddys.com",
        tel: "Tel: + 7 (495) 783 29 01 or + 7 (495) 795 39 39",
        timings: "Timings: 9:00 AM to 6:00 PM, Moscow Time"
    },
    {
        name: "Singapore",
        contact: 'Alok Kumar',
        email: "alokk@drreddys.com",
        timings: "Timings: Mondays to Fridays, 9:00 AM to 5:00 PM, local time"
    },
    {
        name: "Spain",
        contact: 'Susanna Tomas Nogue',
        email: "farmacovigilancia-es@drreddys.com",
        tel: "+34 933554916",
        timings: "Timings: Mondays to Fridays, 9:00 AM to 5:00 PM, local time"
    },
    {
        name: "South Africa",
        contact: "HeatherDoody",
        email: "AdverseEvents.SA@drreddys.com",
        tel: "Tel: +27-11 324 2100",
        timings: "Timings: Mondays to Fridays, 8:00 AM to 5:00 PM , local time"
    },
    {
        name: "Taiwan",
        contact: "WinnieHsu",
        email: "Maxine.Su@drreddys.com",
        tel: '+886 2 87299690'
    },
    {
        name: "Thailand",
        contact: "Natalapol",
        email: "drl.thailand@drreddys.com",
        tel: '+66 (0)2 115 1107'
    },
    {
        name: "Trinidad",
        contact: "DavidMackay",
        email: "David.mackay@drreddys.com",
        tel: '+18687416620'
    },
    {
        name: "Tunisia",
        contact: "Mehdi Oukrine",
        email: "mehdioukrine@drreddys.com"
    },
    {
        name: "Ukraine",
        contact: "SvitlanaMatiukha",
        email: "DrugSafetyUa@drreddys.com",
        tel: "Tel: +38 (044) 207-51-97/98 Fax: +38 (044) 07-51-96",
        timings: "Timings: Eastern Europe Summer Time zone"
    },
    {
        name: "Uraguay",
        contact: "Alex PatricioMatus Mandiola",
        email: "alexm@drreddys.com",
        tel: '+56-973354882'
    },
    {
        name: "Uzbekistan",
        contact: "Mirkasimova Gulnora",
        email: "pharmacovigilance@drreddys.com",
        tel: '(99871) 252-24-41'
    },

    {
        name: "United Kingdom",
        contact: 'Kalyani Vattikuti',
        email: "drreddysGB@EU.ProPharmaGroup.com",
        tel: "+44 (0)1748 828873",
        timings: "Timings: 9:00 AM to 5:00 PM, local time"
    },
    {
        name: "Czech Republic",
        email: "SafetyDRL.CzechRepublic@pharmalex.com",
    },
    {
        name: "Finland",
        email: "SafetyDRL.Finland@pharmalex.com",
    },
    {
        name: "Ireland",
        email: "SafetyDRL.Ireland@pharmalex.com",
    },
    {
        name: "The Netherlands",
        email: "SafetyDRL.Netherlands@pharmalex.com",
    },
    {
        name: "Portugal",
        email: "SafetyDRL.Portugal@pharmalex.com",
    },
    {
        name: "Slovakia",
        email: "SafetyDRL.Slovakia@pharmalex.com",
    },
    {
        name: "Sweden",
        email: "SafetyDRL.Sweden@pharmalex.com",
    },
    {
        name: "Republic of Moldova",
        email: "office.artismedica@gmail.com",
    },
    {
        name: "United States",
        contact: 'Dr. Foram Vaishnav',
        email: "medinfo@drreddys.com",
        tel: "Tel: 1-888-375-3784",
        timings: "Timings: Mondays through Friday, 8:00 AM to 8:00 PM, Eastern Time"
    },
    {
        name: "United States - Promius Pharma",
        tel: "Tel: 1-888-966-8766",
        timings: "Timings: Monday through Friday 9:00 AM-7:00 PM Eastern Time"
    },
    {
        name: "Venezuela",
        contact: "Country Head",
        email: "camilabonilla@drreddys.com",
    },
    {
        name: "Vietnam",
        contact: "HauBui Trung",
        email: "trunghau@drreddys.com",
        tel: ' +84834066068'
    },

];
