import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import BackgroundImg from "../../Common/images/BusinessPartner1.png";

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 55vh;
  display: flex;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // opacity: 75%;
  background-color: #282828;
  background-blend-mode: Multiply;
  margin-top: 120px;
  @media (max-width: 900px) {
    height: 400px;
    height: 35vh;
    margin-top: 60px;
  }
`;
const Main = styled.div`
  padding: 0% 5%;
`;
const TitleContainer = styled.div`
  // background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
`;

const Title = styled.h2`
  font-size: ${({ isBigScreen, isMobile }) => {
    if (isBigScreen) {
      return "80px !important";
    }
    return isMobile ? "30px" : "45px";
  }};
  color: #ffffff;
  font-family: Drlcircular;
  font-weight: 300!important;
  line-height: 1;
`;

export function HeroSection(props) {
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1000px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <MainContainer>
      <TitleContainer>
        <Title isBigScreen={isBigScreen} isMobile={isMobile}>
          {/* {props.state.selectedTab.name} */}
          Business Partners
        </Title>
      </TitleContainer>
    </MainContainer>
  );
}
