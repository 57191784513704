import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";
import { AnimeButton } from "../../../../common/AnimeButton";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";
import { Link } from "react-router-dom";

const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;
const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
margin-top:40px;
width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  margin-top: 20px;
  width:30%;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom: 30px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 40px;
  padding-left: 25px;
  // justify-content: space-between;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.8;
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  margin-right:80px;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
  `;

export const Amalgamation = (props) => {

  const [reports, setReports] = useState({});
    const [dropdownData, setDropdowndata] = useState([]);
    const [selectedFilterDate, setSelectedFilterDate] = useState("");

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempArr = [];
    axios({
        method: "get",
        url:
            config.serverUrl.base +
            "/jsonapi/node/amalgamationreports?include=field_flile&sort=field_sequence_order_",
    })
        .then((res) => {
            // console.log(res);
            for (const item of res.data.included) {
                // console.log(item);
                tempIncludedObj[item.id] =
                    config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
                tempArr = [];
                if (
                    tempDropdownData.indexOf(
                        report.attributes.field_amalgamationyear
                    ) === -1
                ) {
                    tempDropdownData.push(report.attributes.field_amalgamationyear);
                }
                tempDropdownData = tempDropdownData.sort().reverse();
                if (tempReports?.[report.attributes.field_amalgamationyear]) {
                    tempArr = tempReports[report.attributes.field_amalgamationyear];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[report.relationships.field_flile.data.id],
                    });
                    tempReports[report.attributes.field_flile] = tempArr;
                } else {
                    tempArr = [];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[report.relationships.field_flile.data.id],
                    });
                    tempReports[report.attributes.field_amalgamationyear] = tempArr;
                }
            }
            if (tempDropdownData && tempDropdownData.length) {
                setSelectedFilterDate(tempDropdownData[0]);
            }
            // console.log(tempDropdownData[0]);
            setDropdowndata(tempDropdownData);
            setReports(tempReports);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

  return (<MainContainer backgroundColor="#fff">
    <SubContainer>
      <DropDownDiv>
        <Cdropdown
          data={dropdownData}
          placeholder="Year"
          id="reports"
          selectedValue={selectedFilterDate}
          onChangeValue={(val) => setSelectedFilterDate(val)}
        />
      </DropDownDiv>
      {/* <FilesContainer>
                <FileLink>Outcome of NCLT convened Meeting and voting results <BsArrowRight style={{ marginLeft: "50px", alignSelf: 'center' }} size={25} color="#5225B5" /> </FileLink>
                <FileLink>Newspaper Publication – Notice of Petition <BsArrowRight style={{ marginLeft: "50px", alignSelf: 'center' }} size={25} color="#5225B5" /> </FileLink>
            </FilesContainer> */}
      <FilesContainer>
        {reports?.[selectedFilterDate] &&
          reports?.[selectedFilterDate].map((item, index) => {
            return (
              <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }} >
                <TextContent
                to={{ pathname: item.url }}
                            target="_blank"
                  style={{ font: "18px" }}
                  key={index}
                  // onClick={() => {
                  //   downloadFile(item.url);
                  // }}
                >
                  {item.title}
                </TextContent>
                {/* <AnimeButton onClick={() => {
                  downloadFile(item.url);
                }} /> */}
              </span>
            );
          })}
      </FilesContainer>
    </SubContainer>
  </MainContainer>
  )
}