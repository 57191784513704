import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { isTab } from '../../Utils/Utils';

const DeliveringHealthMobile = ({ data, history }) => {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        // let images = document.querySelectorAll('img');
        // let z = -1;

        // images.forEach(image => {
        //     console.log(image.clientHeight);

        //     gsap.set(image, {yPercent: -100, zIndex: () => z});
        //     gsap.to(image, {
        //       scrollTrigger: {
        //         trigger: image,
        //         start: "top bottom",
        //         end: `+=${image.clientHeight}`,
        //         pin: true,
        //         pinSpacing: false,
        //         markers: true
        //       }
        //     });
        //     z = z - 1;
        // });

        if (ScrollTrigger.getById('trigger_DeliveringHealth')) ScrollTrigger.getById('trigger_DeliveringHealth').kill();

        // ScrollTrigger.refresh();

        let tl = gsap.timeline({
            scrollTrigger: {
                id: 'trigger_DeliveringHealth',
                trigger: "#container_DeliveringHealth",
                start: "top",
                end: "+=2000",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        // tl.set("#text_DeliveringHealth-one", { opacity: 1, zIndex: 9999 })
        // tl.set("#text_DeliveringHealth-two", { opacity: 0, zIndex: -140 })
        // tl.set("#text_DeliveringHealth-three", { opacity: 0, zIndex: -140 })
        // tl.set("#text_DeliveringHealth-four", { opacity: 0, zIndex: -140 })
        // // tl.set("#img-two", { opacity: 0 })
        // // tl.set("#img-three", { opacity: 0 })
        // // tl.set("#img-four", { opacity: 0 })

        // // tl.to('#img-one', {
        // //   opacity: 0})

        // tl.to('#text_DeliveringHealth-one', {
        //     opacity: 0,
        //     zIndex: -140,
        //     duration: 0.2
        // }, "<")

        // tl.set("#text_DeliveringHealth-one", { opacity: 0, zIndex: -140 })
        // // tl.set("#img-one", {opacity: 0}, "<")
        // // tl.set("#img-two", { opacity: 1 }, "<")

        // // tl.fromTo('#img-two', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text_DeliveringHealth-two', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text_DeliveringHealth-two", { opacity: 0, zIndex: -140 }, ">")
        // // tl.set("#img-two", {opacity: 0}, "<")
        // // tl.set("#img-three", { opacity: 1 }, "<")


        // // tl.fromTo('#img-three', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text_DeliveringHealth-three', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text_DeliveringHealth-three", { opacity: 0, zIndex: -140 }, ">")
        // // tl.set("#img-two", {opacity: 0}, "<")
        // // tl.set("#img-four", { opacity: 1 }, "<")


        // // tl.fromTo('#img-four', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text_DeliveringHealth-four', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")


        tl.set("#text_DeliveringHealth-one", { opacity: 1, zIndex: 9999, })

        tl.set("#text_DeliveringHealth-two", { opacity: 0, zIndex: -140 })
        tl.set("#text_DeliveringHealth-one", { opacity: 0, zIndex: -140 }, "+=0.4")
        tl.set("#text_DeliveringHealth-two", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text_DeliveringHealth-two", { opacity: 1, zIndex: 9999, }, "+=0.2")


        tl.set("#text_DeliveringHealth-three", { opacity: 0, zIndex: -140 })
        tl.set("#text_DeliveringHealth-two", { opacity: 0, zIndex: -140 }, "+=0.4")
        tl.set("#text_DeliveringHealth-three", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text_DeliveringHealth-three", { opacity: 1, zIndex: 9999, }, "+=0.2")

        tl.set("#text_DeliveringHealth-four", { opacity: 0, zIndex: -140 })
        tl.set("#text_DeliveringHealth-three", { opacity: 0, zIndex: -140 }, "+=0.4")
        tl.set("#text_DeliveringHealth-four", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text_DeliveringHealth-four", { opacity: 1, zIndex: 9999, }, "+=0.2")

    }, [data])


    return (
        data && data.length !== 0 && <>
            <div id="container_DeliveringHealth" className="af-class-section" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', height: '100vh', backgroundColor: '#EDEDED' }} >
                <div id="img-container_DeliveringHealth" style={{ flex: 1, overflow: 'hidden', position: 'relative', }} data-poster-url={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-poster-00001.jpg"} data-autoplay="true" data-loop="true" data-wf-ignore="true" >
                    {/* <div className="af-class-delivering-health-video w-background-video w-background-video-atom"> */}
                    <video autoPlay loop style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/videos/Delivering-good-health-section-GIFs-poster-00001.jpg)',height:'100%' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-transcode.mp4"} data-wf-ignore="true" />
                        <source src={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-transcode.webm"} data-wf-ignore="true" />
                    </video>
                    <div data-w-id="0e8e8995-b7c2-b906-d561-acfb2ab0414d" className="af-class-stickey-video-text-overlay">
                        <h2 className="af-class-h2-section-heading af-class-title af-class-left" style={{ fontSize: isTab ? '60px' : '36px',textAlign:'center',margin:0 }} >Delivering good <br/> health around <br/> the world</h2>
                    </div>
                    {/* </div> */}
                </div>
                <div id="txt-container_DeliveringHealth" style={{ minHeight: isTab ? '280px' :'200px', height: isTab ? '280px' :'200px', maxHeight: isTab ? '280px' :'200px', position: 'relative', bottom: 0 }}>
                    <div id="text_DeliveringHealth-one" style={{ position: 'absolute', minHeight: isTab ? '280px' :'200px', height: isTab ? '280px' :'200px', maxHeight: isTab ? '280px' :'200px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <span>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '33px', 'important');
                                    }
                                }
                            }} style={{ fontSize: isTab ? '36px':'24px', fontWeight: '200', marginTop: '18px', }} >{data[0].title}</p>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '30px', 'important');
                                    el.style.setProperty('max-width', '80%', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '24px', 'important');
                                    }
                                }
                            }} className="af-class-paragraph" style={{ fontSize: isTab ? '18px' :'14px' }} dangerouslySetInnerHTML={{ __html: data[0].description }} />
                        </span>
                        <span>
                            <a style={{}} href={`/articles?${data[0].contentId}#${data[0].id}`} >
                                <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[0].title} />
                            </a>
                        </span>
                    </div>
                    <div id="text_DeliveringHealth-two" style={{ opacity: 0, position: 'absolute', minHeight: isTab ? '280px' :'200px', height: isTab ? '280px' :'200px', maxHeight: isTab ? '280px' :'200px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <span>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '33px', 'important');
                                    }
                                }
                            }} style={{ fontSize: isTab ? '36px' :'24px', fontWeight: '200', marginTop: '18px', }} >{data[1].title}</p>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '30px', 'important');
                                    el.style.setProperty('max-width', '80%', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '24px', 'important');
                                    }
                                }
                            }} className="af-class-paragraph" style={{ fontSize: isTab ? '18px' :'14px' }} dangerouslySetInnerHTML={{ __html: data[1].description }} />
                        </span>
                        <span>
                            <a style={{}} href={`/articles?${data[1].contentId}#${data[1].id}`} >
                                <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[1].title} />
                            </a>
                        </span>
                    </div>
                    <div id="text_DeliveringHealth-three" style={{ opacity: 0, position: 'absolute', minHeight: isTab ? '280px' :'200px', height: isTab ? '280px' :'200px', maxHeight: isTab ? '280px' :'200px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <span>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '33px', 'important');
                                    }
                                }
                            }} style={{ fontSize: isTab ? '36px' :'24px', fontWeight: '200', marginTop: '18px', }} >{data[2].title}</p>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '30px', 'important');
                                    el.style.setProperty('max-width', '80%', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '24px', 'important');
                                    }
                                }
                            }} className="af-class-paragraph" style={{ fontSize: isTab ? '18px' :'14px' }} dangerouslySetInnerHTML={{ __html: data[2].description }} />
                        </span>
                        <span>
                            <a style={{}} href={`/articles?${data[2].contentId}#${data[2].id}`} >
                                <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[2].title} />
                            </a>
                        </span>
                    </div>
                    <div id="text_DeliveringHealth-four" style={{ opacity: 0, position: 'absolute', minHeight: isTab ? '280px' :'200px', height: isTab ? '280px' :'200px', maxHeight: isTab ? '280px' :'200px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <span>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '33px', 'important');
                                    }
                                }
                            }} style={{ fontSize: isTab?'36px':'24px', fontWeight: '200', marginTop: '18px', }} >{data[3].title}</p>
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                    if(isTab){
                                    el.style.setProperty('line-height', '30px', 'important');
                                    el.style.setProperty('max-width', '80%', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '24px', 'important');
                                    }
                                }
                            }} className="af-class-paragraph" style={{ fontSize: isTab ? '18px' :'14px' }} dangerouslySetInnerHTML={{ __html: data[3].description }} />
                        </span>
                        <span>
                            <a style={{}} href={`/articles?${data[3].contentId}#${data[3].id}`} >
                                <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[3].title} />
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );

}

export default DeliveringHealthMobile;