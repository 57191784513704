import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

import { isTab } from '../../Utils/Utils';

const EmployeeInitiativesMobile = ({ }) => {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        // let images = document.querySelectorAll('img');
        // let z = -1;

        // images.forEach(image => {
        //     console.log(image.clientHeight);

        //     gsap.set(image, {yPercent: -100, zIndex: () => z});
        //     gsap.to(image, {
        //       scrollTrigger: {
        //         trigger: image,
        //         start: "top bottom",
        //         end: `+=${image.clientHeight}`,
        //         pin: true,
        //         pinSpacing: false,
        //         markers: true
        //       }
        //     });
        //     z = z - 1;
        // });

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#container-EmployeeInitiativesMobile",
                start: "top",
                end: "+=1200",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        // tl.set("#text-EmployeeInitiativesMobile-one", { opacity: 1, zIndex: 9999 })
        // tl.set("#text-EmployeeInitiativesMobile-two", { opacity: 0, zIndex: -140 })
        // // tl.set("#text-EmployeeInitiativesMobile-three", { opacity: 0, zIndex: -140 })
        // // tl.set("#text-EmployeeInitiativesMobile-four", { opacity: 0, zIndex: -140 })
        // tl.set("#img-EmployeeInitiativesMobile-two", { opacity: 0 })
        // // tl.set("#img-EmployeeInitiativesMobile-three", { opacity: 0 })
        // // tl.set("#img-EmployeeInitiativesMobile-four", { opacity: 0 })

        // // tl.to('#img-EmployeeInitiativesMobile-one', {
        // //   opacity: 0})

        // tl.to('#text-EmployeeInitiativesMobile-one', {
        //     opacity: 0,
        //     zIndex: -140,
        //     duration: 0.2
        // }, "<")

        // tl.set("#text-EmployeeInitiativesMobile-one", { opacity: 0, zIndex: -140 })
        // // tl.set("#img-EmployeeInitiativesMobile-one", {opacity: 0}, "<")
        // tl.set("#img-EmployeeInitiativesMobile-two", { opacity: 1 }, "<")

        // // tl.fromTo('#img-EmployeeInitiativesMobile-two', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EmployeeInitiativesMobile-two', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text-EmployeeInitiativesMobile-two", { opacity: 0, zIndex: -140 }, ">")
        // tl.set("#img-EmployeeInitiativesMobile-two", {opacity: 0}, "<")
        // tl.set("#img-EmployeeInitiativesMobile-three", { opacity: 1 }, "<")


        // tl.fromTo('#img-EmployeeInitiativesMobile-three', {
        //     yPercent: 100
        // },
        //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EmployeeInitiativesMobile-three', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text-EmployeeInitiativesMobile-three", { opacity: 0, zIndex: -140 }, ">")
        // tl.set("#img-EmployeeInitiativesMobile-two", {opacity: 0}, "<")
        // tl.set("#img-EmployeeInitiativesMobile-four", { opacity: 1 }, "<")


        // tl.fromTo('#img-EmployeeInitiativesMobile-four', {
        //     yPercent: 100
        // },
        //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EmployeeInitiativesMobile-four', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")


        tl.set("#img-EmployeeInitiativesMobile-one", { opacity: 1 })
        tl.set("#text-EmployeeInitiativesMobile-one", { opacity: 1, zIndex: 9999, })

        tl.set("#img-EmployeeInitiativesMobile-two", { opacity: 0 })
        tl.set("#text-EmployeeInitiativesMobile-two", { opacity: 0, zIndex: -140 })
        tl.set("#img-EmployeeInitiativesMobile-two", { opacity: 1 }, "+=0.4")
        tl.set("#text-EmployeeInitiativesMobile-one", { opacity: 0, zIndex: -140 }, "<")
        tl.set("#text-EmployeeInitiativesMobile-two", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text-EmployeeInitiativesMobile-two", { opacity: 1, zIndex: 9999, }, "+=0.2")



    }, [])


    return (
        <>
            <div id="container-EmployeeInitiativesMobile" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
                <div id="img-EmployeeInitiativesMobile-container" style={{ flex: 1, overflow: 'hidden', position: 'relative', }}>
                    <div id="img-EmployeeInitiativesMobile-one" class="img" style={{ position: 'absolute', height: '100%', height: '100%', width: '100%',top: 0, left: 0 }}><img  loading="lazy"id="img1" style={{ height: '100%', width: '100%', objectFit: 'cover', }} src={'images/Mask_Group_2681.png'} alt="image1" /></div>
                    <div id="img-EmployeeInitiativesMobile-two" class="img" style={{ position: 'absolute', height: '100%', height: '100%', width: '100%',top: 0, left: 0, opacity: 0 }}><img  loading="lazy" id="img2" style={{ height: '100%', width: '100%', objectFit: 'cover', }} src={'images/Mask_Group_2413.png'} alt="image2" /></div>
                    {/* <div id="img-EmployeeInitiativesMobile-three" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img  loading="lazy" id="img3" style={{ height: '100%', objectFit: 'cover', }} src={data[2].thumbnail} alt="image3" /></div> */}
                    {/* <div id="img-EmployeeInitiativesMobile-four" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img loading="lazy"  id="img4" style={{ height: '100%', objectFit: 'cover', }} src={data[3].thumbnail} alt="image4" /></div> */}
                    <div style={{ position: 'absolute', zIndex: '99', height: '100%', width: '100%', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex',justifyContent:'center' }} >
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if(isTab){
                                el.style.setProperty('font-size', '48px', 'important');
                                el.style.setProperty('line-height', '60px', 'important');
                                } else {
                                el.style.setProperty('line-height', '48px', 'important');
                                }
                            }
                        }} style={{ fontSize: '36px', fontWeight: '300', color: '#fff', margin: 0, lineHeight: '48px',alignSelf:'center' }} >Employee <br/> Initiatives</p>
                    </div>
                </div>
                <div id="txt-container" style={{ minHeight: isTab? '350px' : '320px', maxHeight: isTab? '350px' : '320px', position: 'relative', bottom: 0 }}>
                    <div id="text-EmployeeInitiativesMobile-one" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                        {/* <h2 style={{ fontSize: '24px', fontWeight: '300', margin: 0 }} >Employee Initiatives</h2> */}
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if(isTab){
                                el.style.setProperty('font-size', '28px', 'important');
                                el.style.setProperty('line-height', '40px', 'important');
                                } else {
                                el.style.setProperty('line-height', '33px', 'important');
                                }
                            }
                        }} style={{ color: '#000', fontSize: '24px',lineHeight:'33px', marginTop: '21px', marginBottom: '26px', fontWeight: 300 }} >Pre-Hospitalisation COVID-19 Care Units</p>
                        <p className="af-class-paragraph" style={{ fontSize: isTab? '18px' : '14px' }} >Dr. Reddy’s set up COVID-19 care facilities at three locations - Hyderabad, Vizag and Baddi. Each facility was provisioned with all necessary equipment, oxygen and medicines as well as doctors and nurses. <br /><br /> These units were set up for employees and their families.</p>
                    </div>
                    <div id="text-EmployeeInitiativesMobile-two" style={{ opacity: 0, position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if(isTab){
                                el.style.setProperty('font-size', '28px', 'important');
                                el.style.setProperty('line-height', '40px', 'important');
                                } else {
                                el.style.setProperty('line-height', '33px', 'important');
                                }
                            }
                        }} style={{ color: '#000', fontSize: '24px',lineHeight:'33px', marginTop: '21px', marginBottom: '26px', fontWeight: 300 }} >Active Testing</p>
                        <p className="af-class-paragraph" style={{ fontSize: isTab? '18px' : '14px' }} >We are constantly vigilant through regular thermal screening, active testing and contact tracing. Proactive tests and tracking to identify asymptomatic cases are done through KeepSafe, an in-house app developed for employee health monitoring.</p>
                    </div>
                    {/* <div id="text-EmployeeInitiativesMobile-three" style={{ opacity: 0, position: 'absolute', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <p style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', }} >{data[2].title}</p>
                        <p className="af-class-paragraph" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: data[2].description }} />
                        <a style={{ alignSelf: 'end' }} href={`/articles?${data[2].contentId}#${data[2].id}`} >
                            <img src="images/purple-right-arrow.svg" loading="lazy" alt={data[2].title} />
                        </a>
                    </div> */}
                    {/* <div id="text-EmployeeInitiativesMobile-four" style={{ opacity: 0, position: 'absolute', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', width: '100%' }}>
                        <p style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', }} >{data[3].title}</p>
                        <p className="af-class-paragraph" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: data[3].description }} />
                        <a style={{ alignSelf: 'end' }} href={`/articles?${data[3].contentId}#${data[3].id}`} >
                            <img src="images/purple-right-arrow.svg" loading="lazy" alt={data[3].title} />
                        </a>
                    </div> */}
                </div>
            </div>
        </>
    );

}

export default EmployeeInitiativesMobile;
