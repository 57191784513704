import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import BackIcon from '../../Common/images/backArrow.svg';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    width: 100%;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px !important;
    color: #000000 !important;
    font-family: Drlcircular !important;
    font-weight: 300 !important;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        font-size: 24px !important;
        margin-bottom: 20px !important;
    }
`;

const TextSection = styled.p`
    width: 90%!important;
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 300!important;
    margin: 40px 0!important;
    line-height: 1.8!important;
    @media (max-width: 900px) {
        width: 100%!important;
        font-size: 16px!important;
        margin: 24px 0!important;
        line-height: 24px!important;

    }
`;

const TextBold = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;

export function MainSection(props) {
    return <div className="af-class-section" style={{paddingTop: '180px'}}>
        <div className="af-class-container">
            <MainContainer>
                <BodySection>
                    <Heading>Cookie Policy</Heading>
                    <HR />
                    <TextSection>
                        {/* Cookie Policy <br /><br /> */}
                        <TextBold> What is a cookie? </TextBold>
                        A cookie is a small data file stored on your computer or mobile device at the request to your browser from a website when you visit this website. The cookie allows the website to “remember” your actions or preference for a certain period of time. <br /><br />
                        Most browsers allow cookies. However, user can set your browsers to refuse or remove them, if they wish. <br /><br />
                        Some cookies are deleted when you close your browser. They are known as session cookies. Others remain on your device until they expire or until you delete them from your cache. They are called permanent cookies and enable certain information about your use to be recalled when you return to the website. <br /><br />
                        A cookie can temporarily store anonymous information about the use and users of a website. <br /><br />
                        Some cookies are required in order to use a website, others allow customization and optimization of the displayed content, the collection of user preferences or the monitoring of the website’s audience, website errors and the securing of its access. <br /><br />
                        <TextBold>The use of cookies when you visit our website </TextBold>
                        Cookies allow us to store information on the server to help make the Web experience better for you and for web site performance review. <br /><br />
                        <TextBold>How to control cookies? </TextBold>
                        If you don’t want to receive cookies, you can modify your browser so that it notifies you when cookies are sent to it or you can refuse cookies altogether. You can also delete cookies that have already been set. If you wish to restrict or block web browser cookies which are set on your device then you can do this through your browser settings. <br /><br />
                        We draw your attention to the fact that disabling cookies may prevent you from accessing certain features on our websites
                    </TextSection>
                </BodySection>
            </MainContainer>
        </div>
    </div>
}