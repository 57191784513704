import React, { useEffect, useState } from 'react';
import config from '../config';

const PiReportNav = () => {

  const [pdfFileUrl, setPdfFileUrl] = useState("");

  useEffect(() => {
    const baseUrl = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "media-library/";
    if (window.location.href.indexOf("/pi/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pi/" + window.location.href.split("/pi/")[1];
      //setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pi/" + window.location.href.split("/pi/")[1]);
      // window.location.href = window.location.href.split("/pi/").join(config.serverUrl.piFilesPrefix +"pi/");
      //window.location.href = baseUrl + "pi/" + window.location.href.split("/pi/")[1];
      setPdfFileUrl(baseUrl + "pi/" + window.location.href.split("/pi/")[1]);
    }
    if (window.location.href.indexOf("/pil/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pil/" + window.location.href.split("/pil/")[1];
      //setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "pil/" + window.location.href.split("/pil/")[1]);
      //   window.location.href = window.location.href.split("/pil/").join(config.serverUrl.piFilesPrefix+"pil/");
      // window.location.href = baseUrl + "pil/" + window.location.href.split("/pil/")[1];
      setPdfFileUrl(baseUrl + "pil/" + window.location.href.split("/pil/")[1]);
    }
    if (window.location.href.indexOf("/medguide/") !== -1) {
      // window.location.href = config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "medguide/" + window.location.href.split("/medguide/")[1];
      // setPdfFileUrl(config.serverUrl.imageBase + config.serverUrl.piFilesPrefix + "medguide/" + window.location.href.split("/medguide/")[1]);
      //   window.location.href = window.location.href.split("/medguide/").join(config.serverUrl.piFilesPrefix+"medguide/");
      //window.location.href = baseUrl + "medguide/" + window.location.href.split("/medguide/")[1];
      setPdfFileUrl(baseUrl + "medguide/" + window.location.href.split("/medguide/")[1]);
    }
    if (window.location.href.indexOf("/Nuroday/") !== -1) {
      setPdfFileUrl(baseUrl + "Nuroday/" + window.location.href.split("/Nuroday/")[1]);
    }
    if (window.location.href.indexOf("/SildooM25/") !== -1) {
      setPdfFileUrl(baseUrl + "SildooM25/" + window.location.href.split("/SildooM25/")[1]);
    }
    if (window.location.href.indexOf("/Vegaz/") !== -1) {
      setPdfFileUrl(baseUrl + "Vegaz/" + window.location.href.split("/Vegaz/")[1]);
    }
  }, []);

  return <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }} >
    {pdfFileUrl && pdfFileUrl.length !== 0 && <iframe src={`${pdfFileUrl}#view=fitH`} title="pdf" height="100%" width="100%" />}
  </div>
}

export default PiReportNav;