import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "/images/sharath-kumar-Mb2LErousEY-unsplash.png",
        "heading": "Kallam Anji Reddy Vidyalaya",
        "desc": "Established in 2001, Kallam Anji Reddy Vidyalaya strives to enhance the quality of education for children from underprivileged communities. The school follows the SSC syllabus and offers instruction in English from Nursery to Class 10. Currently the initiative supports around 2300 children."
    },
    {
        "id": "slider_2",
        "img": "/images/yogendra-singh-RgDVFbXllVA-unsplash.png",
        "heading": "Kallam Anji Reddy Junior Vocational College",
        "desc": "The vocational college was started in 2003 to enable matriculate students from low income families attain employment orientated technical education. Along with eight employment oriented courses, KARVJC offers bridge courses to enable interested students to develop a strong foundation for higher education."
    },
    {
        "id": "slider_4",
        "img": "/images/akshar-dave-b5UILRPGV_g-unsplash.png",
        "heading": "School Improvement Programme (SIP)",
        "desc": "SIP was conceived to enhance the quality of education imparted in government schools. SIP focuses on the holistic development of the students and undertakes a number of initiatives in the area of Foundational Literacy, Numeracy & English language, Maths, Science, Sports, and WASH to enhance overall learning outcomes. SIP is implemented in 100 government schools in Telangana and Andhra Pradesh. The project is implemented in partnership with the Dr. Reddy’s Foundation."
    },
    {
        "id": "slider_3",
        "img": "/images/Sashakt-mobile.png",
        "heading": "Sashakt",
        "desc": "Sashakt Scholarship supports female students from low socio-economic backgrounds to pursue a career in science. They are offered financial support and mentorship throughout their undergraduate studies. Junior Sashakt Scholarship supports academically bright girls who have completed 10th class. This initiative is implemented in partnership with Dr. Reddy’s Foundation."
    },
]
    ;

const EducationSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 85);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                    marginBottom: '20px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px',height:'50px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', height: '80px', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu  >
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default EducationSlider;