import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { scroller } from 'react-scroll';
import { minimizeString, isTab, mediaQueryRangeTab } from '../../Utils/Utils';


import { HR } from "../../pages/common/horizontalLine";

import rightArrowPrimary from '../../assets/rightArrowPrimary.svg';

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;

const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%; 
  margin-right: 4%;
`;

const TabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const SubTabItem = styled.li`
  position: relative;
  left: -10px !important;
  text-decoration:none !important;
  cursor: pointer;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color: #5225B5;
  }
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const SubTabItemATag = styled.a`
  position: relative;
  text-decoration:none !important;
  cursor: pointer;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color: #5225B5;
  }
`;

const HambergerButton = styled.a`
  /* using following code for increasing clickable area */
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 2em;
  margin: -2em;
  cursor: pointer;
`;

const HambergerIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  display: block;
  background-color: #ededed;
  top: 0;
  left: 0;
  padding-top: 20%;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  margin-top: 10px;
  &::before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(-45deg);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TabMenuItem = styled.a`
  cursor: pointer;
  font-size: 16px;
  color:  ${({ selected }) => (selected ? "#5225B5" : "#000")};;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  padding-bottom: 5px;
  text-decoration:none !important;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export function SustainabilityTabs(props) {
  const [openMenuBool, setOpenMenuBool] = useState(false);
  const isTabsEnable = useMediaQuery({ query: "(min-device-width: 1000px)" });

  const renderMainComponents = () => {
    switch (props.state.selectedTab.id) {
      case 1:
        return (<div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
            <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', 
                marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
            }}>
              <div>
                {/* <h2 ref={(el)=>{
                  if(el && isTab){
                    el.style.setProperty('font-size','36px','important')
                    el.style.setProperty('margin-bottom','20px','important')
                  }
                }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if(isTab){
                    el.style.setProperty('font-size', '20px', 'important');
                    el.style.setProperty('line-height', '32px', 'important');
                    el.style.setProperty('max-width', '100%', 'important');
                    } else {
                    el.style.setProperty('line-height', '28px', 'important');
                    }
                  }
                }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                    Reducing Carbon Emissions
                </p>
                <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
                    <li style={{marginBottom: '7px'}}>100% renewable power by 2030</li>
                    <li style={{marginBottom: '7px'}}>Carbon neutrality in our direct operations (scope 1 and 2 emissions) by 2030</li>
                    <li style={{marginBottom: '7px'}}>12.5% reduction in indirect carbon emissions (Scope 3 emissions) by 2030. </li>
                </ul>
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if(isTab){
                    el.style.setProperty('font-size', '20px', 'important');
                    el.style.setProperty('line-height', '32px', 'important');
                    el.style.setProperty('max-width', '100%', 'important');
                    } else {
                    el.style.setProperty('line-height', '28px', 'important');
                    }
                  }
                }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                    Water Positivity
                </p>
                <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
                    <li style={{marginBottom: '7px'}}>Be water positive by 2025.</li>
                </ul>
                {/* <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if(isTab){
                    el.style.setProperty('font-size', '20px', 'important');
                    el.style.setProperty('line-height', '32px', 'important');
                    el.style.setProperty('max-width', '100%', 'important');
                    } else {
                    el.style.setProperty('line-height', '24px', 'important');
                    }
                  }
                  else {
                    el && el.style.setProperty('line-height', '24px', 'important');

                  }
                  
                }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                    Learn more about our environment, social and governance (ESG) agenda.
                </p> */}
                <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" style={{marginTop: '30px'}}>
                  <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                  <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'}} >LEARN&nbsp;MORE</div>
                </a>
              </div>
            </div>
            <div ref={(el)=>{
              if(el && isTab){
                el.style.setProperty('height','280px','important')
              }
            }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '180px' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%", 
                marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
             }}>
              <img loading="lazy" src= {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/pexels-pixabay-257775.png" : "/images/pexels-pixabay-257775.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
            </div>
          </div>
        )
      case 2:
        return (<div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
        <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <div>
            {/* <h2 ref={(el)=>{
              if(el && isTab){
                el.style.setProperty('font-size','36px','important')
                el.style.setProperty('margin-bottom','20px','important')
              }
            }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Access
            </p>
            <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
                <li style={{marginBottom: '7px'}}>Serve 1.5BN+ patients by 2030</li>
            </ul>
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Affordability
            </p>
            <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
                <li style={{marginBottom: '7px'}}>25% of new-launches to be first to market by 2027</li>
            </ul>
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Innovation
            </p>
            <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
                <li style={{marginBottom: '7px'}}>3 innovative solutions to improve the standard of treatment every year from 2027</li>
            </ul>
            {/* <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '24px', 'important');
                }
              }
              else {
                el && el.style.setProperty('line-height', '24px', 'important');

              }
              
            }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Learn more about our environment, social and governance (ESG) agenda.
            </p> */}
            <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" style={{marginTop: '30px'}}>
              <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
              <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'}} ></div>
            </a>
          </div>
        </div>
        <div ref={(el)=>{
          if(el && isTab){
            el.style.setProperty('height','280px','important')
          }
        }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '180px' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%", 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
         }}>
          <img loading="lazy" src= {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/shashank-thapa-MkPINODL-Tw-unsplash.png" : "/images/shashank-thapa-MkPINODL-Tw-unsplash.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      </div>
    )        
      case 3:
        return (<div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
        <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <div>
            {/* <h2 ref={(el)=>{
              if(el && isTab){
                el.style.setProperty('font-size','36px','important')
                el.style.setProperty('margin-bottom','20px','important')
              }
            }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Equity, Diversity and Inclusion
            </p>
            <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
            <li style={{marginBottom: '7px'}}>At least 35% women in senior leadership by 2030</li>
            <li style={{marginBottom: '7px'}}>Gender parity by 2035</li>
            <li style={{marginBottom: '7px'}}>Include at least 3% persons with disabilities (PwD) in our workforce by 2030</li>
            <li style={{marginBottom: '7px'}}>Ensure 100% living wages for our on-premise extended workforce by 2025</li>
            </ul>
           
            {/* <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '24px', 'important');
                }
              }
              else {
                el && el.style.setProperty('line-height', '24px', 'important');

              }
              
            }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Learn more about our environment, social and governance (ESG) agenda.
            </p> */}
            <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" style={{marginTop: '30px'}}>
              <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
              <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'}} ></div>
            </a>
          </div>
        </div>
        <div ref={(el)=>{
          if(el && isTab){
            el.style.setProperty('height','280px','important')
          }
        }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '180px' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%", 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
         }}>
          <img loading="lazy" src= {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/_DSC2209-Edit.png" : "/images/_DSC2209-Edit.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      </div>
    )        
      case 4:
        return (<div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
        <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <div>
            {/* <h2 ref={(el)=>{
              if(el && isTab){
                el.style.setProperty('font-size','36px','important')
                el.style.setProperty('margin-bottom','20px','important')
              }
            }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Good Health Can’t Wait</h2> */}
            <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '28px', 'important');
                }
              }
            }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Enhancing trust with our stakeholders 
            </p>
            <ul style={{margin: 0, padding: 0, lineHeight: '24px'}}>
            <li style={{marginBottom: '7px'}}>Highest standards on compliance and ethics backed by robust corporate governance</li>
            <li style={{marginBottom: '7px'}}>Progressively enhance disclosure on our ESG progress to reach top quartile by 2025</li>
            <li style={{marginBottom: '7px'}}>100% of our strategic suppliers to be compliant with our chosen ESG framework by 2030</li>
            </ul>
           
            {/* <p ref={(el) => {
              if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                if(isTab){
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
                } else {
                el.style.setProperty('line-height', '24px', 'important');
                }
              }
              else {
                el && el.style.setProperty('line-height', '24px', 'important');

              }
              
            }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
                Learn more about our environment, social and governance (ESG) agenda.
            </p> */}
            <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" style={{marginTop: '30px'}}>
              <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
              <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'}} ></div>
            </a>
          </div>
        </div>
        <div ref={(el)=>{
          if(el && isTab){
            el.style.setProperty('height','280px','important')
          }
        }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '180px' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%", 
            marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
            marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
         }}>
          <img  loading="lazy" src= {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/pexels-karolina-grabowska-7875996.png" : "/images/pexels-karolina-grabowska-7875996.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      </div>
    )        
      default:
        return <p>{props.state.selectedTab.name}</p>;
    }
  }

  return (
    <>
      <div id="main-container" className="af-class-section"  >
        <div className="af-class-container" data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7">
            
           {<h2 ref={(el)=>{
                  if(el && isTab){
                    el.style.setProperty('font-size','36px','important')
                    el.style.setProperty('margin-bottom','20px','important')
                  }
                }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin af-class-center" 
                style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '-10px', 
                marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '40px', 
                fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', textAlign: 'center' }} >Our current ESG goals</h2> }


          {!window.matchMedia('(max-width: 820px)').matches && <MainContainer isTabsEnable={isTabsEnable}>

            <TabsSection>
              {isTabsEnable &&
                props.tabData.map((item, index) => {
                    return (
                      <TabItem
                        href={"#" + item.hashValue}
                        key={"Rendering_Tab_contactUs_" + item.id + item.name + index}
                        onClick={() => {
                          props.updateState({ selectedTab: item, selectedSubTab: 0 });
                        }}
                        selected={props.state.selectedTab.id === item.id}
                      >
                        {item.name}
                      </TabItem>
                    );
                })}
              {!isTabsEnable && (
                <HambergerButton
                  onClick={() => {
                    setOpenMenuBool(!openMenuBool);
                  }}
                >
                  <HambergerIcon />
                </HambergerButton>
              )}
            </TabsSection>
          </MainContainer>}

          {!window.matchMedia('(max-width: 820px)').matches && <HR />}

          {/* {!window.matchMedia('(max-width: 820px)').matches && <div style={{ width: '100%' }} >
            <ul style={{ display: 'flex', flexWrap: 'wrap', lineHeight: 1.5, paddingLeft: '25px' }} >

            </ul>
          </div>} */}

          {/* {!window.matchMedia('(max-width: 820px)').matches && <HR />} */}

          {
            window.matchMedia('(max-width: 820px)').matches && <div className="disable-scroll-indicator" style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', marginBottom: '50px', width: 'calc(calc(100% + 20px + 20px))', marginLeft: '-20px', marginRight: '-20px', }} >
              {
                props.tabData.map((item, index) => {
                  return (
                    item.hasOwnProperty("url") && item.url.length !== 0
                      ?
                      <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: props.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: props.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                        target="_blank" href={item.url}
                      >
                        <p style={{ fontSize: '16px', fontWeight: 'bold', color: props.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                      </a>
                      :
                      <a key={item.id} id={"MainTabs:" + item.id} style={{ textDecoration: props.state.selectedTab?.name === item.name ? 'underline' : 'none', textUnderlineOffset: '4px', color: '#000', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', marginRight: '15px', marginRight: props.tabData.length - 1 === index ? '20px' : '15px', marginLeft: index === 0 && '20px' }}
                        onClick={() => {
                          props.updateState({ selectedTab: item })
                        }}
                      >
                        <p style={{ fontSize: '16px', fontWeight: 'bold', color: props.state.selectedTab?.name === item.name ? '#5225B5' : '#909090', margin: '0px', whiteSpace: 'nowrap' }} >{item.name}</p>
                      </a>
                  )
                })
              }
            </div>
          }

          {
            window.matchMedia('(max-width: 820px)').matches && props.state.selectedTab?.subMenuTabData && props.state.selectedTab.subMenuTabData.map((item, index) => {
              return (
                item.hasOwnProperty("url") && item.url.length !== 0
                  ?
                  <div key={"MenuListView:" + index} >
                    <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} target="_blank" href={item.url} >
                      <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                      <img  loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                    </a>
                    <HR />
                  </div>
                  :
                  <div key={"MenuListView:" + index} >
                    <a style={{ textDecoration: 'none', color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between' }} href={"/detail-sub-menu#" + item.hashValue} >
                      <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px' }} >{item.name}</p>
                      <img  loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                    </a>
                    <HR />
                  </div>
              )
            })
          }

          {!window.matchMedia('(max-width: 820px)').matches && renderMainComponents()}


        </div>
      </div>
      {openMenuBool && (
        <MenuContainer>
          <CloseButton
            onClick={() => {
              setOpenMenuBool(false);
            }}
          />
          <MenuItemContainer>
            {props.tabData.map((item, index) => {
              return (
                <TabMenuItem
                  key={"Rendering_TabMenuItem_contactUs_" + item.name + index}
                  onClick={() => {
                    props.updateState({ selectedTab: item });
                    setOpenMenuBool(false);
                  }}
                  selected={props.state.selectedTab.name === item.name}
                >
                  {item.name}
                </TabMenuItem>
              );
            })}
          </MenuItemContainer>
        </MenuContainer>
      )}
    </>
  );
}
