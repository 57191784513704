import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { Link } from "react-router-dom";

import { AnimeButton } from '../../../../common/AnimeButton';

import TrangleIcon from '../img2.png';

export const SharePrice = (props) => {

  const IcsTextContainer = styled.div`
  width: 100%;
`;
  const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    /* overflow-x: 40px; */

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      /* line-width: 1.25; */
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  line-height: 1.7 !important;
  margin: 0 !important;
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBook = styled.h6`
  font-size: 18px !important;
  line-height: 1.7 !important;
  color: #000000 !important;
  font-weight: 400 !important;
  font-family: Drlcircular !important;
  margin:0 !important;
  margin-bottom:30px !important;
  @media (max-width: 820px) {
    font-size: 16px !important;
    line-height: 28px !important;
  }
`;

  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
  width:100%;
  margin-top:40px;
`;
  const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const SharePriceText = styled.h2`
  margin: 0 !important ;
  color: #000000 !important ;
  font-size: 40px !important ;
  font-family: Drlcircular !important ;
  font-weight: 500 !important ;
  line-height: 1 !important ;
  margin-right:20px;
`;

  const Header = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 570px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
`;

  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
  &:first-child{
    margin-bottom:70px;
  }
  @media (max-width: 820px) {
    &:first-child{
      margin-bottom:30px;
    }
  }
`;
  const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 4px;
  margin-left: 10px;
  justify-content: space-between;
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
    flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  width: 20% !important;
  text-transform: uppercase !important;
  margin-top:5px !important;
  @media (max-width: 1180px) {
    font-size: 16px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }
`;
  const Content = styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content:space-between;
  font-size: 16px;
  font-weight: 300;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    font-size: 14px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

  const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline !important;
    margin-bottom: 15px;
  }
`;

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  const [stocktable, setstocktable] = useState({});

  useEffect(() => {
        var tempReports = {};
        var temp = [];
        var tempArr = [];
        axios({
            method: "get",
            url: config.serverUrl.base + "/jsonapi/node/stock_tickers",
        })
            .then((res) => {
                for (const report of res.data.data) {
                    tempArr = [];
                    if (
                        tempReports?.[report.attributes.title] && [report.attributes.title]
                    ) {
                        tempArr = tempReports[report.attributes.title];
                        tempArr.push({
                            description: temp[report.attributes.field_table_stock.value],
                        });
                        tempReports[report.attributes.title] = tempArr;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            description: report.attributes.field_table_stock.value,
                        });
                        tempReports[report.attributes.title] = tempArr;
                    }
                }
                setstocktable(tempReports);
            })
            .catch((err) => {
                console.log(err, "err");
            });
    }, []);

  return (<MainContainer>
    <SubContainer>
      <TextContainer>

        <TextHeading>STOCK EXCHANGES</TextHeading>
        <Content>
          <span style={{ display: 'flex', flexDirection: 'column',marginTop:minWidthBool && '20px' }} >
            <TextBook>
              New York Stock Exchange (NYSE) (ADR listed)
            </TextBook>
            <TextBook>National Stock Exchange (NSE)</TextBook>
            <TextBook>Bombay Stock Exchange (BSE)</TextBook>
            <TextBook>NSE IFSC Limited (NIL)</TextBook>
          </span>
          
        </Content>
      </TextContainer>
    </SubContainer>
    <SubContainer>
      <TextContainer>
        <TextHeading>STOCK Tickers</TextHeading>
        <span style={{ display: 'flex', flexDirection: 'column' }} >
          <TableContainer>
            <table>
              {stocktable?.["STOCK TICKERS"] &&
                stocktable?.["STOCK TICKERS"].map((item, index) => {
                  var result = [];
                  var tempArr = [];
                  for (const i in item.description) {
                    if (i !== "caption") {
                      tempArr = [];
                      item.description[i].map((item2, index) => {
                        {
                          tempArr.push(<td style={{ padding: '20px 15px', }} >{item2}</td>);
                        }
                      });
                      result.push(<tr>{tempArr}</tr>);
                    }
                  }
                  return result;
                })}
            </table>
          </TableContainer>
          <TextContent
            style={{ marginTop: minWidthBool? '20px':'60px' }}
            target="_blank"
            to={{ pathname: "https://www1.nseindia.com/companytracker/cmtracker.jsp?symbol=DRREDDY" }}
          >
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
              NSE Market Tracker
              <AnimeButton
                style={{ marginLeft: '60px',marginTop:minWidthBool?'10px':'0' }}
                target="_blank"
                href={"https://www1.nseindia.com/companytracker/cmtracker.jsp?symbol=DRREDDY"}
              />
            </span>
          </TextContent>
        </span>
      </TextContainer>
    </SubContainer>
  </MainContainer>
  )
}

/* <div style={{ display: 'flex', flexDirection: 'column',alignItems:minWidthBool?'flex-start':'flex-end',marginBottom:minWidthBool && '20px' }} >
            <TextLight>As on May 10, 2021</TextLight>
            <span style={{ display:'flex',flexDirection: 'row', alignItems: 'center',marginTop:'15px' }} >
              <SharePriceText>5078.00</SharePriceText>
              <span style={{ padding: '15px 20px', backgroundColor: '#1DFF8E', fontSize: '20px', fontWeight: 400,marginLeft:'20px' }} >
                <img loading="lazy" src={TrangleIcon} style={{ height: '15px', width: '15px',marginRight:'10px' }} />
                0.41%
              </span>
            </span>
          </div> */