/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false },
  { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false },
]

let Controller

class DetailTestimonialsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/DetailTestimonialsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = DetailTestimonialsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60d5fc56782b643c4b81520a'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = DetailTestimonialsView.Controller !== DetailTestimonialsView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        <span className="af-view">
          <div>
            <nav id="Navbar" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d12" className="af-class-navbar">
              <div className="af-class-navbar-container">
                <a href="index.html" className="af-class-nav-logo w-inline-block"><img src="images/DrR_Logo_Primary_RGB.svg" loading="lazy" alt /></a>
                <div className="af-class-menu-options">
                  <div data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d17" className="af-class-menu-button">
                    <div className="af-class-menu-bar af-class-_1" />
                    <div className="af-class-menu-bar af-class-_2" />
                  </div>
                </div>
                <div className="af-class-nav-menu-wrapper">
                  <div className="w-layout-grid af-class-menu-grid">
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d1c-23f25d12" className="af-class-main-menu-links">
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d1d" href="#" className="af-class-menu-link w-inline-block">
                        <div>People &amp;&nbsp;Stories</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d21" href="#" className="af-class-menu-link w-inline-block">
                        <div>Science &amp; Research</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d25" href="#" className="af-class-menu-link w-inline-block">
                        <div>Impact &amp; Sustainability</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d29" href="#" className="af-class-menu-link w-inline-block">
                        <div>About Us</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d2d" href="#" className="af-class-menu-link w-inline-block">
                        <div>Businesses &amp; Products</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                      <a data-w-id="83343092-5fee-32e4-6c13-ebcf23f25d31" href="#" className="af-class-menu-link af-class-no-bottom-margin w-inline-block">
                        <div>Life at DRL</div>
                        <div className="af-class-menu-link-underline" />
                      </a>
                    </div>
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25d35-23f25d12" className="af-class-middle-menu-container">
                      <div className="af-class-mid-menu-block af-class-_1"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="people-stories.html" className="af-class-sub-menu-heading">People &amp;&nbsp;Stories</a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Patients</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Healthcare Professionals</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Partners</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Benefits of Partnering With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_2"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="science-research.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>Science &amp; Research</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Development Streams</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Research Philosophy</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Capabilities</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_3"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="impact-and-sustainability.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>Impact &amp; Sustainability</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Environmental Initiatives</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>DRL Foundation &amp; Affiliates</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>HCP Programmes</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_4"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="about-us.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>ABOUT us</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Who We Are</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Values</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Heritage</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Journey And Vision</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Founder And Leaders</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_6"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="life-at-drl.html" className="af-class-sub-menu-heading">LIFE AT DR. REDDY’S</a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>ASPIRE</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our People</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Benefits Of Working With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Work With Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-mid-menu-block af-class-_5"><img src="images/Icon-ionic-ios-arrow-round-forward.svg" loading="lazy" alt className="af-class-sub-menu-back" />
                        <a href="businesses-products.html" className="af-class-sub-menu-heading w-inline-block">
                          <div>BUSINESS &amp; PRODUCTS</div>
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Business Highlight &amp; Stories</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Business Portfolio</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Our Focus Areas</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>Quality &amp; Compliance</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div className="af-class-text-block">Global Manufacturing &amp; Supply Chains</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-sub-menu-link w-inline-block">
                          <div>API Product Portal</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                    </div>
                    <div id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dac-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dac" className="af-class-side-menu">
                      <div data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dad" className="af-class-main-menu-links">
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Business Partners</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Investors</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Job Seekers</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small w-inline-block">
                          <div>Contact Us</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                        <a href="#" className="af-class-menu-link af-class-small af-class-no-bottom-margin w-inline-block">
                          <div>Microsites</div>
                          <div className="af-class-menu-link-underline" />
                        </a>
                      </div>
                      <div className="af-class-countries-wrapper">
                        <a id="w-node-_83343092-5fee-32e4-6c13-ebcf23f25dc3-23f25d12" data-w-id="83343092-5fee-32e4-6c13-ebcf23f25dc3" href="#" className="af-class-country-link af-class-india w-inline-block"><img src="images/Icon-feather-globe.svg" loading="lazy" alt className="af-class-globe-icon" />
                          <div>India</div>
                        </a>
                        <div className="af-class-country-links-block">
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>Australia</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>brazil</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>Canada</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>chile</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>china</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>france</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>germany</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>netherlands</div>
                          </a>
                        </div>
                        <div className="af-class-country-links-block">
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>new zealand</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>romania</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>russia</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>south korea</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>spain</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>ukraine</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>united kingdom</div>
                          </a>
                          <a href="#" className="af-class-country-link w-inline-block">
                            <div>united states</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="af-class-sticky-article-container">
              <div id="Article-Hero" className="af-class-section af-class-article-hero af-class-white">
                <div className="af-class-container w-container">
                  <h1 className="af-class-h1-article" />
                  <div className="af-class-author" />
                  <div className="af-class-author-designation" />
                </div>
              </div>
              <div className="af-class-section af-class-article-section">
                <div className="af-class-container af-class-article w-container">
                  <div className="af-class-rich-text-block w-richtext" />
                </div>
              </div>
            </div>
            <footer className="af-class-section af-class-footer">
              <div className="af-class-container w-container">
                <div className="w-layout-grid af-class-footer-grid">
                  <a id="w-node-f3bf3626-7fae-7d0a-e267-a3cedb32a0e0-db32a0dd" href="#" className="af-class-footer-logo w-inline-block"><img src="images/Dr._Reddys_Laboratories_Logo.svg" loading="lazy" alt /></a>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">PEOPLE &amp; Stories</a>
                    <a href="#" className="af-class-footer-link">Patients</a>
                    <a href="#" className="af-class-footer-link">Healthcare Professionals</a>
                    <a href="#" className="af-class-footer-link">Partners</a>
                    <a href="#" className="af-class-footer-link">Benefits of Partnering With Us</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">IMPACT &amp; SUSTAINABILITY</a>
                    <a href="#" className="af-class-footer-link">Environmental Initiatives</a>
                    <a href="#" className="af-class-footer-link">DRL Foundation &amp; Affiliates</a>
                    <a href="#" className="af-class-footer-link">HCP Programmes</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">BUSINESS &amp; PRODUCTS</a>
                    <a href="#" className="af-class-footer-link">Business Highlights &amp; Stories</a>
                    <a href="#" className="af-class-footer-link">Business Portfolio</a>
                    <a href="#" className="af-class-footer-link">Our Focus Areas</a>
                    <a href="#" className="af-class-footer-link">Quality &amp; Compliance</a>
                    <a href="#" className="af-class-footer-link">Global Manufacturing &amp; Supply Chains</a>
                    <a href="#" className="af-class-footer-link">API Product Portal</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">SCIENCE &amp; research</a>
                    <a href="#" className="af-class-footer-link">Development Streams</a>
                    <a href="#" className="af-class-footer-link">Research Philosophy</a>
                    <a href="#" className="af-class-footer-link">Capabilities</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">ABOUT&nbsp;US</a>
                    <a href="#" className="af-class-footer-link">Who We Are</a>
                    <a href="#" className="af-class-footer-link">Our Values</a>
                    <a href="#" className="af-class-footer-link">Our Heritage</a>
                    <a href="#" className="af-class-footer-link">Our Journey and Vision</a>
                    <a href="#" className="af-class-footer-link">Our Founder and Leaders</a>
                  </div>
                  <div className="af-class-footer-link-block">
                    <a href="#" className="af-class-footer-link-category">LIFE AT DR. REDDY’S</a>
                    <a href="#" className="af-class-footer-link">ASPIRE </a>
                    <a href="#" className="af-class-footer-link">Our People</a>
                    <a href="#" className="af-class-footer-link">Benefits of Working With Us</a>
                    <a href="#" className="af-class-footer-link">Work With Us</a>
                  </div>
                </div>
                <div className="af-class-secondary-footer-links-wrapper">
                  <a href="#" className="af-class-secondary-footer-links">BUSINESS PARTNERS</a>
                  <a href="#" className="af-class-secondary-footer-links">INVESTORS</a>
                  <a href="#" className="af-class-secondary-footer-links">JOBSEEKERS</a>
                  <a href="#" className="af-class-secondary-footer-links">CONTACT US</a>
                  <a href="#" className="af-class-secondary-footer-links">MICROSITES</a>
                </div>
                <div className="af-class-policies-and-social-wrapper">
                  <div className="af-class-polices-links-wrapper">
                    <a href="#" className="af-class-policies-links">Terms of Use</a>
                    <a href="#" className="af-class-policies-links">Privacy Notice</a>
                    <a href="#" className="af-class-policies-links">Accessibility</a>
                    <a href="#" className="af-class-policies-links">Cookie Policy</a>
                  </div>
                  <div className="af-class-social-icons-wrapper">
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/facebook-logo-png-white-i6-300x300.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/1300173_linkedin-logo-white-png.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/46-461780_twitter-twitter-logo-round-vector.svg" loading="lazy" alt /></a>
                    <a href="#" className="af-class-social-icon w-inline-block"><img src="images/46-462383_youtube-youtube-play-icon-white.svg" loading="lazy" alt /></a>
                  </div>
                </div>
                <div className="af-class-footer-note">© 2021 Dr. Reddy’s Laboratories Ltd. All rights reserved.</div>
              </div>
            </footer>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default DetailTestimonialsView

/* eslint-enable */