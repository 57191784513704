import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import axios from 'axios';
import config from '../../config';

import Img1 from '../../Common/images/productInfo.svg';
import Img2 from '../../Common/images/productInfo1.png';
import ImgMobile from '../../Common/images/productInfoMobile1.png';
import DownloadIcon from '../../Common/images/downloadPrimary.svg';
import ProductInfoCoverImg from '../../Common/images/productInfoCoverImg.png';
import QrErrorImg from '../../Common/images/qrErrorImg.svg';
import ReactGA from "react-ga4";
import Config from "../../config";

const ImgContainer = styled.div`
    display:flex;
    height: 100vh;
    width: 45vw;
    overflow: hidden;
`;

const Img = styled.img`
    height: 100vh;
    width: 45vw;
    object-fit: cover;
    /* height: 100%;
    width:50%; */
`;

const ContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding:40px;
`;

const ImgBlock = styled.div`
    box-shadow: 0px 8px 24px -4px rgba(48, 48, 48, 0.1);
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh/5.6);
    max-height:calc(100vh/5.6);
    flex:1;
    margin-bottom:24px;
    background: #fff;
`;

const ImgTag = styled.img.attrs({
    src: ProductInfoCoverImg
})`
    object-fit: contain;
    height:calc(100vh/5.6);
    max-height:calc(100vh/5.6);
`;

const ImgBlock1 = styled.div`
    border: 2px solid #EBE0F9;
    box-shadow: 0px 8px 24px -4px rgba(48, 48, 48, 0.1);
    border-radius: 10px;
    display:flex;
    height: 226px;
    max-height: 226px;
    align-items:center;
    justify-content:center;
    flex:1;
    margin: 0px 18px;
    background: #fff;
    margin-top: -113px;
`;

const ImgTag1 = styled.img.attrs({
    src: ProductInfoCoverImg
})`
    object-fit: contain;
    height: 226px;
    max-height: 226px;
`;

const Row = styled.div`
    display: flex;
    flex-direction:row;
`;

const RowClone = styled.div`
    display: flex;
    flex-direction:row;
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction:row;
    flex:1
`;

const Column = styled.div`
    display: flex;
    flex-direction:column;
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction:column;
    flex:1;
`;

const ColumnFlex12 = styled.div`
    display: flex;
    flex-direction:column;
    flex:1.2;
`;

const LogoAnchor = styled.a`
    margin-bottom:50px;
    cursor:pointer;
    display: flex;
    align-self: flex-end;
`;

const LogoAnchorSmall = styled.a`
    cursor:pointer;
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
`;

const DRLLogo = styled.img.attrs({
    src: "/images/DrR_Logo_Primary_RGB.svg"
})`
    height: 28px;
    width: 120px;
`;

const DRLLogoFFF = styled.img.attrs({
    src: "/images/Dr._Reddys_Laboratories_Logo.svg"
})`
    height: 28px;
    width: 120px;
`;

const Label = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #676767;
`;

const ErrorMsg = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: red;
`;

const Value = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #2d2d2d;
`;

const Value1 = styled.p`
    font-family: Drlcircular;
    color: #676767;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
`;

const InputLabel1 = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #2D2D2DFC;
    margin-top:40px;
`;

const InputLabel2 = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #676767;
    margin-bottom:8px;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #959595;
    border-radius: 6px;
    width:260px;
    flex:unset;
    padding:0px 10px;
    outline: none;
    text-transform: uppercase;
`;

const NextButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${({ isBigScreen }) => isBigScreen ? '12px 50px' : '12px 24px'};
    background: ${({ valueBool }) => valueBool ? '#6900EF' : '#e2e2e2'};
    border-radius: 22px;
    cursor: ${({ valueBool }) => valueBool ? 'pointer' : 'default'};
    border:0px;
    margin-left:18px;
    outline: none !important;

    font-family: Drlcircular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #FFFFFF;

    &:hover{
        background: ${({ valueBool }) => valueBool ? '#3A0085' : '#e2e2e2'};
    }
`;

const PilSection = styled.div`
    padding:20px 10px;
    background: #F7F3FF;
    border-radius: 8px;
    display: flex;
    margin-top:30px;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    margin-bottom:60px;
`;

const PilText = styled.p`
    font-family: Drlcircular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #2d2d2d;
`;

const PilDownloadButton = styled.a`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    border: 1px solid #6900EF;
    border-radius: 22px;
    cursor:pointer;
    text-decoration:'none';

    font-family: Drlcircular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #6900EF !important;
`;

const BlurLayer = styled.div`
    height:280px;
    width: 100vw;
    position: absolute;
    display: flex;
    background: linear-gradient(219.35deg, #5F5D5D 3.75%, rgba(255, 255, 255, 0) 77.47%);
    mix-blend-mode: multiply;
`;

const DownloadImg = styled.img.attrs({
    src: DownloadIcon
})`
    width:18px;
    height:18px;
    margin-right:12px;
`;

const Spacer = styled.span`
    width: 30px;
    height: 0px;
`;

const Spacer1 = styled.span`
    width: 0px;
    height: 35px;
`;

const Section = styled.div`
    display:flex;
    flex-direction:${({ isBigScreen }) => isBigScreen ? 'row' : 'column'};
    flex:1;

    ${ImgContainer}{
        height:${({ isBigScreen }) => isBigScreen ? '100vh' : '280px'};
        width: ${({ isBigScreen }) => isBigScreen ? '45vw' : '100vw'};
    }    
    
    ${Img}{
        height:${({ isBigScreen }) => isBigScreen ? '100vh' : '360px'};
        width: ${({ isBigScreen }) => isBigScreen ? '45vw' : '100vw'};
    }
    
    ${ImgBlock}{
        margin-top: ${({ isBigScreen }) => isBigScreen ? 'unset' : '-44%'};
    }
    
    ${ContentContainer}{
        padding: ${({ isBigScreen }) => isBigScreen ? '40px' : '0px'};
    }
    
    ${Value}{
        font-size: ${({ isBigScreen }) => isBigScreen ? '20px' : '16px'};
        line-height: ${({ isBigScreen }) => isBigScreen ? '27px' : '22px;'};
    }
    
    ${RowClone}{
        flex-direction: ${({ isBigScreen }) => isBigScreen ? 'row' : 'column'};
    }
    
    ${Spacer}{
        width: ${({ isBigScreen }) => isBigScreen ? '30px' : '0px'};
        height: ${({ isBigScreen }) => isBigScreen ? '0px' : '20px'};
    }
    
    ${Spacer1}{
        height: ${({ isBigScreen }) => isBigScreen ? '35px' : '20px'};
    }

    ${InputLabel1}{
        margin-top:${({ isBigScreen }) => isBigScreen ? '40px' : '24px'};
    }
    
    ${InputLabel2}{
        font-size:${({ isBigScreen }) => isBigScreen ? '14px' : '12px'};
    }
    
    ${PilSection}{
        margin-top:${({ isBigScreen }) => isBigScreen ? '30px' : '24px'};
        flex-direction:${({ isBigScreen }) => isBigScreen ? 'row' : 'column'};
        align-items:${({ isBigScreen }) => isBigScreen ? 'center' : 'flex-end'};
    }
    
    ${PilDownloadButton}{
        margin-top:${({ isBigScreen }) => isBigScreen ? '0px' : '16px'};
    }
`;

const MobileInfoContainer = styled.div`
    position: absolute;
    background: #fff;
    border-radius: 10px;
    padding:50px 18px;
    /* top: -120px; */
    display: grid;
    margin: 0px 10px;
    width: calc(100% - 20px);
`;

const QrErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
`;

const QrErrorImage = styled.img.attrs({
    src: QrErrorImg
})`
    align-self:center;
`;

const QrErrorMsg = styled.p`
    font-family: Drlcircular;
    color: #676767;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0em;
`;

const QrErrorMsg1 = styled.span`
    font-family: Drlcircular;
    color: #676767;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0em;
`;

const ErrorContainer = styled.div`
    display:flex;
    flex-direction:column;
    /* flex-direction:${({ isBigScreen }) => isBigScreen ? 'row' : 'column'}; */
    flex:1;
    padding: ${({ isBigScreen }) => isBigScreen ? '28px 40px' : '40px 15px'};

    ${QrErrorMsg}{
        font-size: ${({ isBigScreen }) => isBigScreen ? '24px' : '20px'};
        line-height: ${({ isBigScreen }) => isBigScreen ? '25px' : '110%'};
    }

    ${QrErrorMsg1}{
        font-size: ${({ isBigScreen }) => isBigScreen ? '24px' : '20px'};
        line-height: ${({ isBigScreen }) => isBigScreen ? '25px' : '110%'};
    }

    ${QrErrorImage}{
        margin-bottom: ${({ isBigScreen }) => isBigScreen ? '40px' : '70px'};
        width: ${({ isBigScreen }) => isBigScreen ? '35%' : '100%'};
    }

    `;

const ProductInfoView = () => {
    const params = useParams();
    const { gtin, gs1 } = params;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
    const [fullInfoBool, setFullInfoBool] = useState(false);
    const [batchNo, setBatchNo] = useState('');
    const [productInfo, setProductInfo] = useState({});
    const [noDataBool, setNoDataBool] = useState(false);
    const [errorBool, setErrorBool] = useState(false);

    useEffect(() => {
        axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/product_master?include=field_flile,field_image,field_plant_name&filter[title]=(" + gs1 + ")" + gtin, })
            .then((res) => {
                makeData(res);
            }).catch((Err) => {
                console.log(Err, "-=-=-=  ERR")
            })
    }, [])

    const makeData = (apiResp) => {
        if (apiResp.data.data && apiResp.data.data.length) {
            setNoDataBool(false);
            let tempObj = {};
            let plantId = apiResp.data.data[0].relationships.field_plant_name.data.id;
            let productImgId = apiResp.data.data[0].relationships.field_image.data?.id ? apiResp.data.data[0].relationships.field_image.data.id : '';
            let pilFileId = apiResp.data.data[0].relationships.field_flile.data?.id ? apiResp.data.data[0].relationships.field_flile.data.id : '';
            for (let include of apiResp.data.included) {
                if (include.id === plantId) {
                    tempObj.plantName = include.attributes.title;
                    tempObj.plantAddress = include.attributes.field_address;
                } else if (include.id === productImgId) {
                    tempObj.productImg = config.serverUrl.imageBase + include.attributes.uri.url;
                } else if (include.id === pilFileId) {
                    tempObj.pilFile = config.serverUrl.imageBase + include.attributes.uri.url;
                }
            }
            setProductInfo({
                ...tempObj,
                uniquePICode: apiResp.data.data[0].attributes.title,
                skuCode: apiResp.data.data[0].attributes.field_sku_code,
                brandName: apiResp.data.data[0].attributes.field_brand_name,
                genericDrugName: apiResp.data.data[0].attributes.field_generic_name_of_the_drug,
                manufacturingLicenseNo: apiResp.data.data[0].attributes.field_text,
            });
        } else {
            setNoDataBool(true);
            setProductInfo({});
        }
    }

    const getImpDates = () => {
        /* https://websiteqa.mydrreddys.com:8443/api/product/details/(01)8901148251120?batchNo=TD22010 */
        axios({ method: "get", url: config.serverUrl.base + "/api/product/details/" + "(" + gs1 + ")" + gtin + "?batchNo=" + batchNo.toUpperCase(), })
            .then((res) => {
                ReactGA.initialize(Config.serverUrl.googleAnalyticsKey);
                ReactGA.set({ product_name: "(" + gs1 + ")" + gtin });
                if (res.data && !Array.isArray(res.data) && res.data?.DATE_OF_MANUFACTURING) {
                    setProductInfo({
                        ...productInfo,
                        manufacturingDate: res.data.DATE_OF_MANUFACTURING,
                        expiryDate: res.data.DATE_OF_EXPIRY,
                    })
                    setBatchNo(batchNo.toUpperCase());
                    setFullInfoBool(true);
                    setErrorBool(false);

                    ReactGA.set({ status: "Success" });
                    ReactGA.event({
                        category: "Batch Success",
                        label: "(" + gs1 + ")" + gtin,
                        action: batchNo,
                        nonInteraction: true,
                        transport: "xhr"
                    });

                } else {
                    setErrorBool(true);

                    ReactGA.set({ status: "Failed" });
                    ReactGA.event({
                        category: "Batch Failed",
                        label: "(" + gs1 + ")" + gtin,
                        action: batchNo,
                        nonInteraction: true,
                        transport: "xhr"
                    });
                }
            }).catch((Err) => {
                setErrorBool(true);
                console.log(Err, "-=-=-=  ERR")
            })
    }

    const renderContent = () => {
        if (!fullInfoBool) {
            return <>
                <RowClone>
                    <ColumnFlex>
                        <Label>Generic Drug:</Label>
                        <Value>{productInfo?.genericDrugName}</Value>
                    </ColumnFlex>
                    <Spacer />
                    <ColumnFlex>
                        <Label>Brand Name:</Label>
                        <Value>{productInfo?.brandName}</Value>
                    </ColumnFlex>
                </RowClone>

                <InputLabel1>Batch/Lot number:</InputLabel1>
                <InputLabel2>for Product Information Authentication</InputLabel2>
                <Row>
                    <Input value={batchNo} placeholder='Type Batch/Lot number' type="text" onChange={(ev) => {
                        const value = ev.currentTarget.value;
                        const regex = /^[0-9a-zA-Z]+$/;
                        if (regex.test(value) || value === "") {
                            setBatchNo(value);
                        }
                    }} />
                    <NextButton isBigScreen={isBigScreen} valueBool={batchNo.length !== 0} onClick={() => {
                        if (batchNo.length !== 0) {
                            getImpDates()
                        }
                    }} >Next</NextButton>
                </Row>
                {errorBool && <ErrorMsg>Enter a valid Batch/Lot number</ErrorMsg>}

                <div style={{ flex: 1 }} />

                {!!productInfo?.pilFile && <PilSection>
                    <PilText>Click here to get Patient Information Leaflet (PIL)</PilText>
                    <PilDownloadButton target="_blank" href={productInfo?.pilFile} >
                        <DownloadImg />View / Download PIL
                    </PilDownloadButton>
                </PilSection>}
            </>
        } else {
            return <>
                <RowClone>
                    <ColumnFlex>
                        <Label>Unique Product Identification Code:</Label>
                        <Value>{productInfo?.uniquePICode}</Value>
                    </ColumnFlex>
                    <Spacer />
                    {isBigScreen
                        ?
                        <ColumnFlex>
                            <Label>Batch Number:</Label>
                            <Value>{batchNo}</Value>
                        </ColumnFlex>
                        :
                        <RowFlex>
                            <ColumnFlex>
                                <Label>Brand Name:</Label>
                                <Value>{productInfo?.brandName}</Value>
                            </ColumnFlex>
                            <Spacer />
                            <ColumnFlex>
                                <Label>Batch Number:</Label>
                                <Value>{batchNo}</Value>
                            </ColumnFlex>
                        </RowFlex>
                    }
                </RowClone>
                <Spacer1 />
                <RowClone>
                    <ColumnFlex>
                        <Label>Generic Drug:</Label>
                        <Value>{productInfo?.genericDrugName}</Value>
                    </ColumnFlex>
                    <Spacer />
                    <ColumnFlex>
                        <Label>Manufactured In India By:</Label>
                        <Value1>{productInfo?.plantAddress}</Value1>
                    </ColumnFlex>
                </RowClone>
                <Spacer1 />
                <RowClone>
                    {isBigScreen && <>
                        <ColumnFlex>
                            <Label>Brand Name:</Label>
                            <Value>{productInfo?.brandName}</Value>
                        </ColumnFlex>
                        <Spacer />
                    </>
                    }
                    <ColumnFlex>
                        <Label>Manufacturing License Number:</Label>
                        <Value>M.L: {productInfo?.manufacturingLicenseNo}</Value>
                    </ColumnFlex>
                </RowClone>
                <Spacer1 />
                {isBigScreen ? <RowClone>
                    <ColumnFlex>
                        <Label>Date of Manufacturing:</Label>
                        <Value>{productInfo?.manufacturingDate}</Value>
                    </ColumnFlex>
                    <Spacer />
                    <ColumnFlex>
                        <Label>Date of Expiry:</Label>
                        <Value>{productInfo?.expiryDate}</Value>
                    </ColumnFlex>
                </RowClone> :
                    <RowFlex>
                        <ColumnFlex12>
                            <Label>Date of Manufacturing:</Label>
                            <Value>{productInfo?.manufacturingDate}</Value>
                        </ColumnFlex12>
                        <Spacer />
                        <ColumnFlex>
                            <Label>Date of Expiry:</Label>
                            <Value>{productInfo?.expiryDate}</Value>
                        </ColumnFlex>
                    </RowFlex>
                }

            </>
        }
    }
    return (
        noDataBool
            ?
            <ErrorContainer isBigScreen={isBigScreen} >
                <LogoAnchor href="/" >
                    <DRLLogo />
                </LogoAnchor>
                <QrErrorContent>
                    <QrErrorImage />
                    <QrErrorMsg>Wrong QR code scanned
                        <br /><QrErrorMsg1>or</QrErrorMsg1><br />
                        QR code application not responding</QrErrorMsg>
                </QrErrorContent>
            </ErrorContainer>
            :
            <Section isBigScreen={isBigScreen} >
                <ImgContainer>
                    <Img src={isBigScreen ? Img2 : ImgMobile} />
                    {
                        isBigScreen
                            ?
                            null
                            :
                            <BlurLayer />
                    }
                    {
                        isBigScreen
                            ?
                            null
                            :
                            <LogoAnchorSmall href="/" >
                                <DRLLogoFFF />
                            </LogoAnchorSmall>
                    }
                </ImgContainer>
                <ContentContainer>
                    {
                        isBigScreen
                            ?
                            <LogoAnchor href="/" >
                                <DRLLogo />
                            </LogoAnchor>
                            :
                            null
                    }

                    {
                        isBigScreen
                            ?
                            renderContent()
                            :
                            <MobileInfoContainer>
                                {renderContent()}
                            </MobileInfoContainer>
                    }

                </ContentContainer>
            </Section>
    )
};

export default ProductInfoView;