import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";
import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { Link } from "react-router-dom";

const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  display: flex;
  width:30%;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom:30px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row; 
  @media (max-width: 1030px) {
    flex-direction: column;
`;

const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 1030px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
const TextContent = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-left: 50px;
  margin-bottom: 20px;
  @media (max-width: 1030px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const ReportItem = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
`;

const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1030px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

export const EarningsCalls = (props) => {

  const [dropdownDataec, setDropdowndataec] = useState([]);
    const [selectedFilterDateec, setSelectedFilterDateec] = useState("");
    const [reportsec, setReportsec] = useState({});

    useEffect(() => {
        var tempIncludedObj = {};
        var tempReports = {};
        var tempDropdownData = [];
        var tempArr = [];
        axios({
            method: "get",
            url:
                config.serverUrl.base +
                "/jsonapi/node/earningcallreports?include=field_file&sort=-created",
        })
            .then((res) => {
                console.log(res);
                for (const item of res.data.included) {
                    tempIncludedObj[item.id] =
                        config.serverUrl.imageBase + item.attributes.uri.url;
                }
                for (const report of res.data.data) {
                    tempArr = [];
                    if (
                        tempDropdownData.indexOf(
                            report.attributes.field_earningcallfiscalyear
                        ) === -1
                    ) {
                        tempDropdownData.push(
                            report.attributes.field_earningcallfiscalyear
                        );
                    }
                    tempDropdownData = tempDropdownData.sort().reverse();
                    if (tempReports?.[report.attributes.field_earningcallfiscalyear]) {
                        tempArr =
                            tempReports[report.attributes.field_earningcallfiscalyear];
                        tempArr.push({
                            title: report.attributes.title,
                            url: tempIncludedObj[report.relationships.field_file.data.id],
                        });
                        tempReports[report.attributes.field_earningcallfiscalyear] =
                            tempArr;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            title: report.attributes.title,
                            url: tempIncludedObj[report.relationships.field_file.data.id],
                        });
                        tempReports[report.attributes.field_earningcallfiscalyear] =
                            tempArr;
                    }
                }
                if (tempDropdownData && tempDropdownData.length) {
                    setSelectedFilterDateec(tempDropdownData[0]);
                }
                console.log(tempDropdownData);
                console.log(tempReports);
                setDropdowndataec(tempDropdownData);
                setReportsec(tempReports);
            })
            .catch((err) => {
                console.log(err, "err");
            });
    }, []);

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  return (
    <Main >
      <div
        style={{
          display: "flex",
          flexDirection:minWidthBool?'column-reverse': "row",
          align: "Left",
          marginTop: '40px'
        }}
      >
        {/* <DropDownDiv>
                <div>
                    <Label>Fiscal Year</Label>
                </div>
                <div>
                    <FiChevronDown  data={dropdownDataec}  id="reportsec" selectedValue={selectedFilterDateec} onChangeValue={(val)=>setSelectedFilterDateec(val)} size={25} />
                </div>
            </DropDownDiv> */}
        <ReportItemContainer>
          {reportsec?.[selectedFilterDateec] &&
            reportsec?.[selectedFilterDateec].map((item, index) => {
              return (
                <ReportItem
                to={{ pathname: item.url }}
                            target="_blank"
                  key={selectedFilterDateec + index}
                  /* onClick={() => {
                    downloadFile(item.url);
                  }} */
                >
                  {item.title}
                </ReportItem>
              );
            })}
        </ReportItemContainer>
        <DropDownDiv>
          <Cdropdown
            data={dropdownDataec}
            placeholder="Fiscal Year"
            id="reportsec"
            selectedValue={selectedFilterDateec}
            onChangeValue={(val) => setSelectedFilterDateec(val)}
          />
        </DropDownDiv>
      </div>
    </Main>
  );

}