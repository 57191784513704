import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "images/deep-science.png",
        "heading": "Deep Science",
        "desc": "Our deep science capabilities form the bedrock of our work and enable us to innovate for patients. We were among the earliest in India to export APIs at scale and to bring several new molecules to the country. We were the first in India to initiate novel drug discovery in the 1990s and to receive 180-day exclusivity from the USFDA in the 2000s. We focus on being first to market. This helps us deliver on our promises to patients, partners and stakeholders."
    },
    {
        "id": "slider_2",
        "img": "images/ground-group-growth-hands-461049.png",
        "heading": "Progressive People Practices",
        "desc": "We have pioneered practices such as ‘Self-Managed Teams’ in pharma. We develop leaders through our investment in world-class learning facilities. Our investment in people capabilities serves not just our company but also  the industry. We have set bold goals for ourselves in our diversity, equity and inclusion journey. We aspire to create an environment in which people can realise their full potential through their work and continuous learning."
    },
    {
        "id": "slider_3",
        "img": "images/home-page-image-21.png",
        "heading": "Rigor in Governance",
        "desc": "We are committed to the highest standards of compliance and ethics in every activity that we undertake. We are consistently recognised by our stakeholders for our governance. We endeavour to set the bar high and maintain the trust our stakeholders repose in us. We continue to work on progressively enhancing our disclosures through progressive and timely disclosures, transparent policies and processes."
    },
]
    ;

const HomeSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }
        let limitText = 100;
        if(index === 1){
            limitText = 118;
        }
        var title = renderText(item.id, item.desc, 85);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                    marginBottom: '20px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '20px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px',height:'25px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', height: '80px', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px',fontWeight:'bold' }} onClick={() => {
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative',marginTop:'20px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default HomeSlider;