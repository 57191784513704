import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const DropDownContainer = styled("div")`
  width: 100%;
  min-height: 50px;
  max-height: 58px;
  margin: 0 auto;
  `;

const DropDownHeader = styled("div")`
  /* margin-bottom: 0.8em; */
  /* padding: 0.4em 2em 0.4em 1em; */
  /* box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15); */

  width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 58px;
  margin: 0 auto;

  background-color:${props => props.backgroundColor};
  padding:5px 10px !important;
  margin:0 !important;
  font-weight: 500;
  border: ${props => props.activeBool ? '1px solid #5225B5' : ('1px solid ' + props.borderColor)} ;
  border-radius: 5px;
  color: #000;
  display: flex;
  align-items:center;
  overflow: hidden;
  cursor:pointer;
  &:hover {
    border: 1px solid #5225B5;
  }
`;

const Label = styled.label`
  font-size: ${props => props.isSelectedValue ? "12px !important" : "16px !important"} ;
  color:  ${props => props.isSelectedValue ? "#909090" : '#000'};
  font-weight: 400 !important;
  font-family: Drlcircular;
  text-align: left;
  letter-spacing: 0px;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom:10px;
  margin-top:5px;
  /* following code for disabling copy text */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  `;

const Value = styled("p")`
  font-size: 18px !important;
  line-height:20px !important;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
  text-align: left;
  letter-spacing: 0px;
  margin: 0 !important;
  padding: 0 !important;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  /* following code for disabling copy text */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  `;

const DropDownListContainer = styled("div")`
  position: absolute;
  z-index: 100;
  width: 100%;
  top:${props => props.isSelectedValue ? "58px" : '50px'};
  border-radius:4px;
  border-collapse: separate; 
  `;

const DropDownListSection = styled("div")`
padding:8px 12px;
background: #EDEDED;
border: 1px solid #d9d9d9;
  `;

const DropDownList = styled("ul")`
  padding: 0 !important;
  margin: 0 !important;
  /* padding-left: 1em; */
  /* border: 2px solid #e5e5e5; */
  box-sizing: border-box;
  color: #000;
  font-size: 1.3rem;
  font-weight: 500;
  height:auto;
  max-height:200px;
  overflow: scroll;
  &:first-child {
    padding-top: 0.4em;
  }
`;

const ListItemFixed = styled("li")`
  list-style: none;
  border-radius: 5px;
  padding: 10px 20px;
  padding-left: 10px !important;
  font-family: Drlcircular;
  font-size:16px!important;
  font-weight:400!important;
  line-height:22px !important;
  background-color:${props => props.selected ? '#5225B5' : '#EDEDED'};
  color:${props => props.selected ? '#fff' : '#000'};
    &:hover {
      background-color: ${props => props.selected ? '#5225B5' : 'rgba(150, 150, 150, 0.15)'};
    }
    /* following code for disabling copy text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const ListItem = styled("li")`
  list-style: none;
  display:flex;
  border-radius: 5px;
  padding: 10px 20px;
  padding-left: 10px !important;
  font-family: Drlcircular;
  font-size:16px!important;
  font-weight:400!important;
  line-height:22px !important;
  flex:1;
  flex-direction:row;
  background-color:${props => props.selected ? '#5225B5' : '#EDEDED'};
  color:${props => props.selected ? '#fff' : '#000'};
    &:hover {
      background-color: ${props => props.selected ? '#5225B5' : 'rgba(150, 150, 150, 0.15)'};
    }
    /* following code for disabling copy text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export default function Cdropdown(props) {

  const { data, placeholder, id, selectedValue, onChangeValue, bgColor, borderColor, mouseEventRef } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   document.getElementById("cdropdown-list") && document.getElementById("cdropdown-list").addEventListener("mouseleave", (ev) => {
  //     if (isOpen) {
  //       // setIsOpen(false);
  //     }
  //   })
  // }, [isOpen])
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (isOpen) {
        if (document.getElementById("cdropdown-list"+id) && !document.getElementById("cdropdown"+id).contains(event.target) && !document.getElementById("cdropdown-list"+id).contains(event.target)) {
          // document.getElementById("cdropdown").click();
          setIsOpen(false);
        }
      }
    })
  }, [isOpen])

  return (
    <>
      <DropDownHeader id={"cdropdown"+id} onClick={toggling} activeBool={isOpen} borderColor={borderColor || '#707070'} backgroundColor={bgColor || '#fff'} >
        <span style={{ width: '90%' }} >
          <Label isSelectedValue={selectedValue && selectedValue.length !== 0} >{placeholder}</Label>
          <Value>{selectedValue}</Value>
        </span>
        {
          isOpen
            ?
            <FiChevronUp size={25} color="#5225B5" />
            :
            <FiChevronDown size={25} color="#5225B5" />
        }
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer isSelectedValue={selectedValue && selectedValue.length !== 0} id={"cdropdown-list"+id} >
          <DropDownListSection>
            <ListItemFixed ref={(el) => {
              if (el) {   
                el.style.setProperty('list-style-type', 'none', 'important'); 
                }}} 
                selected={!selectedValue} key={Math.random()}>
              <img  loading="lazy" src={'/images/tick-fff.svg'} style={{ marginRight: '15px', height: '15px', width: '21px', opacity: !selectedValue ? 1 : 0 }} />
              {placeholder}
            </ListItemFixed>
            <DropDownList >
              {data.map(option => (
                <ListItem selected={option === selectedValue} onClick={() => {
                  onChangeValue(option)
                  setIsOpen(false);
                }} key={Math.random()}>
                  <img  loading="lazy" src={'/images/tick-fff.svg'} style={{ marginRight: '15px', height: '15px', width: '21px', opacity: option === selectedValue ? 1 : 0 }} />
                  <div style={{ flex: 1, }} >
                    {option}
                  </div>
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListSection>
        </DropDownListContainer>
      )}
    </>
  );
}