import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { isTab } from '../../Utils/Utils';

const ImageSliderMobile = ({ data, history }) => {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        if (ScrollTrigger.getById('peopleStories_3')) ScrollTrigger.getById('peopleStories_3').kill();

        // ScrollTrigger.refresh();

        // let images = document.querySelectorAll('img');
        // let z = -1;

        // images.forEach(image => {
        //     console.log(image.clientHeight);

        //     gsap.set(image, {yPercent: -100, zIndex: () => z});
        //     gsap.to(image, {
        //       scrollTrigger: {
        //         trigger: image,
        //         start: "top bottom",
        //         end: `+=${image.clientHeight}`,
        //         pin: true,
        //         pinSpacing: false,
        //         markers: true
        //       }
        //     });
        //     z = z - 1;
        // });

        console.log(" Enter Point of ImageSlider",ScrollTrigger.getAll());

        let tl = gsap.timeline({
            scrollTrigger: {
                id: 'peopleStories_3',
                trigger: "#peopleStories-container",
                start: "top top",
                end: "+=1000",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        // tl.set("#text-peopleStories-one", { opacity: 1, zIndex: 9999, })
        // tl.set("#text-peopleStories-two", { opacity: 0, zIndex: -140 })
        // // tl.set("#text-peopleStories-three", { opacity: 0, zIndex: -140 })
        // tl.set("#img-peopleStories-two", { opacity: 0 })
        // // tl.set("#img-peopleStories-three", { opacity: 0 })

        // // tl.to('#img-peopleStories-one', {
        // //   opacity: 0})

        // tl.to('#text-peopleStories-one', {
        //     opacity: 0,
        //     zIndex: -140,
        //     duration: 0.2
        // }, "<")

        // tl.set("#text-peopleStories-one", { opacity: 0, zIndex: -140 })
        // // tl.set("#img-peopleStories-one", {opacity: 0}, "<")
        // tl.set("#img-peopleStories-two", { opacity: 1 }, "<")

        // // tl.fromTo('#img-peopleStories-two', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-peopleStories-two', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text-peopleStories-two", { opacity: 0, zIndex: -140 }, ">")
        // tl.set("#img-peopleStories-two", {opacity: 0}, "<")
        // tl.set("#img-peopleStories-three", { opacity: 1 }, "<")


        // tl.fromTo('#img-peopleStories-three', {
        //     yPercent: 100
        // },
        //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-peopleStories-three', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        tl.set("#img-peopleStories-one", { opacity: 1 })
        tl.set("#text-peopleStories-one", { opacity: 1, zIndex: 9999, })

        tl.set("#img-peopleStories-two", { opacity: 0 })
        tl.set("#text-peopleStories-two", { opacity: 0, zIndex: -140 })
        tl.set("#img-peopleStories-two", { opacity: 1 }, "+=0.4")
        tl.set("#text-peopleStories-one", { opacity: 0, zIndex: -140 }, "<")
        tl.set("#text-peopleStories-two", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text-peopleStories-two", { opacity: 1, zIndex: 9999, }, "+=0.2")

        console.log(" End Point of ImageSlider",ScrollTrigger.getAll());

    }, [data])


    return (
        data && data.length !== 0 && <>
            <div id="peopleStories-container" className="af-class-section" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
                <div id="img-peopleStories-container" style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
                    <div id="img-peopleStories-one" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0 }}><img  loading="lazy" id="img1" style={{ height: '100%', objectFit: 'cover', }} src={data[0].thumbnail} alt="image1" /></div>
                    <div id="img-peopleStories-two" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img loading="lazy"  id="img2" style={{ height: '100%', objectFit: 'cover',objectPosition:'75%' }} src={data[1].thumbnail} alt="image2" /></div>
                    {/* <div id="img-peopleStories-three" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img  loading="lazy" id="img3" style={{ height: '100%', objectFit: 'cover', }} src={data[2].thumbnail} alt="image3" /></div> */}
                </div>
                <div id="txt-container" style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: 'white', position: 'relative', bottom: 0 }}>
                    <div id="text-peopleStories-one" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if(isTab){
                                el.style.setProperty('font-size', '36px', 'important');
                                el.style.setProperty('line-height', '60px', 'important');
                                } else {
                                el.style.setProperty('line-height', '36px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', width: '100%' }} >{data[0].title}</p>
                        <a style={{marginTop:'20px'}} href={`/articles?${data[0].contentId}#${data[0].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[0].title} />
                        </a>
                    </div>
                    <div id="text-peopleStories-two" style={{ opacity: 0, position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if(isTab){
                                el.style.setProperty('font-size', '36px', 'important');
                                el.style.setProperty('line-height', '60px', 'important');
                                } else {
                                el.style.setProperty('line-height', '36px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', width: '100%' }} >{data[1].title}</p>
                        <a style={{marginTop:'20px'}} href={`/articles?${data[1].contentId}#${data[1].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[1].title} />
                        </a>
                    </div>
                    {/* <div id="text-peopleStories-three" style={{ opacity: 0, position: 'absolute', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                        <p style={{ fontSize: '20px', fontWeight: '200', marginTop: '18px', width: '80%' }} >{data[2].title}</p>
                        <a href={`/articles?${data[2].contentId}#${data[2].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[2].title} />
                        </a>
                    </div> */}
                </div>
            </div>
        </>
    );

}

export default ImageSliderMobile;