/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import styled from 'styled-components';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("/webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

import MenuSection from '../Common/MenuSection'
import OurBusinesses from '../Common/BusinessCapabilities/OurBusinesses'
import ImageSlider from '../Common/BusinessCapabilities/ImageSlider'
import ImageSliderMobile from '../Common/BusinessCapabilities/ImageSliderMobile'
// import ImageSliderGsap from '../Common/BusinessCapabilities/ImageSliderGsap'
import FooterSection from '../Common/FooterSection'

import { EnquiryForm } from '../Common/ContactForm';

import axios from 'axios';
import config from '../config';

import OurBusinessSlider from '../Common/MobileSlider/OurBusinessSlider'

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import { isTab, mediaQueryRangeTab } from '../Utils/Utils';

let Controller

const RenderText = styled.p`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
   font-size: 14px;
   color:#000;
   fontWeight: 200;
  `;

const RenderTextWithOutImg = styled(RenderText).attrs({
  as: "img"
})`
  display: :none;
`;

class BusinessCapabilitiesView extends React.Component {

  state = {
    articleData: [],
    renderPageBool: false,
    cookieBool: true,
    readMore: '',
  }


  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/BusinessesProductsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = BusinessCapabilitiesView

        return Controller
      }

      throw e
    }
  }

  loadScripts() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60a1060067e8ec0f6ca419f6'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true }, () => {
          window.scrollTo({ top: window.scrollY - 1, left: 0, });
          window.scrollTo({ top: window.scrollY + 1, left: 0, });
        })
      }

      return active.isAsync ? next : loading
    }))
  }

  UNSAFE_componentWillMount() {

    var link = document.createElement('meta');
    link.setAttribute('name', 'keywords');
    link.content = "Product Development, compliance, Regulatory, Intellectual property, pharmacopeia, Regulatory Affairs, Certificate of Suitability, Generics player, Generics, Dr. Reddy’s generics, Branded Generics, non-branded generics, global generics, drugs, medicines, pharmaceutical products,Generics, Europe generics, Europe, Europe drugs";
    document.getElementsByTagName('head')[0].appendChild(link);

    var tempObj = {};
    var cacheData = sessionStorage.getItem("cacheData-BusinessCapabilities");
    if (cacheData) {
      var tempCacheData = JSON.parse(cacheData);
      this.setState({
        articleData: { ...tempCacheData }
      }, () => {
        this.loadScript();
      })
    } else {
      axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/businessproductarticles?sort=-created&&include=field_thumbnail,field_coverimage", })
        .then((res) => {
          const { data, included } = res.data;
          var section1 = [];
          var section2 = [];
          var section3 = [];
          var imgPathsObj = {};
          for (var imgObj of included) {
            imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
          }
          for (var item of data) {
            tempObj = {
              id: item.id,
              contentId: '4',
              title: item.attributes.title,
              coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
              thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
              description: item.attributes.field_description ? item.attributes.field_description.processed : "",
              content: item.attributes.field_content ? item.attributes.field_content.processed : "",
            }
            if (item.attributes.field_businessprodcategory === "BusinessProducts-Section-1") {
              section1.push(tempObj);
            }
            if (item.attributes.field_businessprodcategory === "BusinessProducts-Section-2") {
              section2.push(tempObj);
            }
            if (item.attributes.field_businessprodcategory === "BusinessProducts-Section-3") {
              section3.push(tempObj);
            }
          }
          console.log({
            section1,
            section2,
            section3
          })
          this.setState({
            articleData: {
              section1,
              section2,
              section3
            }
          }, () => {
            this.loadScripts();
          })
        }).catch((Err) => {
          this.loadScripts();
          console.log(Err, "-=-=-=  ERR")
        })
    }
  }

  render() {
    const proxies = BusinessCapabilitiesView.Controller !== BusinessCapabilitiesView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"10e55cc7-6744-327e-5d4b-5236a348f771\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e8ac6975-9faa-6c9b-e4f1-bc6635a7d11e\"] {display:none;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"10e55cc7-6744-327e-5d4b-5236a348f771\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e8ac6975-9faa-6c9b-e4f1-bc6635a7d11e\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"10e55cc7-6744-327e-5d4b-5236a348f771\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e8ac6975-9faa-6c9b-e4f1-bc6635a7d11e\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"10e55cc7-6744-327e-5d4b-5236a348f771\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e8ac6975-9faa-6c9b-e4f1-bc6635a7d11e\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
            .af-class-article-text-thumbnail-wrapper:hover h3 {
              text-decoration: underline;
            }

            .box {
              height: 570px;
              width: 373px;
              overflow: hidden;
              background: #EDEDED;
              position: relative;
            }

            .hid-box {
              top: 100%;
              transition: all .3s ease-out;
              background: #EDEDED;
              height: 100%;
              position: absolute;
            }

            .box-face {
              top: 0;
              transition: all .3s ease-out;
            }

            .box:hover > .box-face img {
              top: -100%;
              opacity: 0;
            }

            .box:hover > .hid-box{
              top: 0;
              transition: all .3s ease-out;
            }

            .box:hover > .box-face p {
              top: -100%;
              opacity: 0;
            }

            .box:hover > .box-face{
              top: -100%;
              transition: all .5s ease-out;
            }

        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span id="back-to-top" className="af-view">
          <div>

            <MenuSection />

            <div className="af-class-about-hero-wrapper af-class-life-at-drl" style={{ backgroundImage: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'url("/images/businessCapabilitiesSec1Mobile.jpg")' : 'url("/images/Mask_Group_287.png")', height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vh' : 'auto' }} >
              <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute', width: '100%', height: '100%' }} />
              <div className="af-class-section af-class-life-at-drl-hero">
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container" style={{ marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '120px' }} >
                  <div className="w-layout-grid af-class-_2-col-grid af-class-_40px-gap" style={{ gridRowGap: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', display: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'column' }} >
                    {
                      window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                        ?
                        <h2 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className="af-class-h2-section-heading af-class-title af-class-negative-margin" style={{ fontSize: isTab ? '48px' : '36px', lineHeight: isTab ? '60px' : '48px', margin: 0, textAlign: 'start', maxWidth: '80%', alignSelf: 'start' }} >Pursuing Purposeful <br /> Strategy</h2>
                        :
                        <h2 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className="af-class-h2-section-heading af-class-title af-class-negative-margin" >Pursuing Purposeful Strategy</h2>
                    }
                    <p ref={(el) => {
                      if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                        el.style.setProperty('align-self', 'start', 'important');
                        if (isTab) {
                          el.style.setProperty('padding-right', '15%', 'important');
                          el.style.setProperty('line-height', '32px', 'important');
                          el.style.setProperty('font-size', '20px', 'important');
                        } else {
                          el.style.setProperty('line-height', '28px', 'important');
                        }
                      }
                    }} className="af-class-paragraph af-class-lead" style={{ maxWidth: '100%', justifySelf: 'end', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '20px' }} >Our business strategy rests on three core pillars of leadership in chosen spaces, operational excellence and continuous improvement, along with patient-centric product innovation underpinned by sustainability. </p>
                    {/* <p id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e5-bdd5ac66" className="af-class-paragraph af-class-lead">Ask any of the 23,704 employees at Dr. Reddy’s why they come to work every day and they’ll say, because Good Health Can’t Wait. This is our credo as well as the guiding principle behind all our actions. We see healthcare solutions not only as scientific formulations, but as a means to help patients lead healthier lives, and we’re always attuned to the new and the next to empower people to stay fit. And to do this, we foster a culture of empathy and dynamism.</p> */}
                  </div>
                </div>
              </div>
            </div>

            <OurBusinessSlider data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} />
            <OurBusinesses data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} />

            <div id="our-capabilities" data-w-id="8b77eb71-4078-dfa5-9dc2-6a816a3fa874" className="af-class-section af-class-embracing-quality">
              <div className="af-class-values-section-title-block">
                <div className="af-class-container" style={{ alignItems: 'center' }} >
                  {/* <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality">Embracing quality to deliver excellence on time, every time</h1> */}
                  <h1 className="af-class-h2-section-heading af-class-title af-class-embracing-quality" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Our Capabilities</h1>
                  <p ref={(el) => {
                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                      if (isTab) {
                        el.style.setProperty('font-size', '24px', 'important');
                        el.style.setProperty('line-height', '40px', 'important');
                        el.style.setProperty('max-width', 'unset', 'important');
                      } else {
                        el.style.setProperty('line-height', '30px', 'important');
                      }
                    }
                  }} className="af-class-paragraph af-class-_572" style={{ textAlign: 'center', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '340px' }} >We leverage our research and development expertise, end-to-end manufacturing know-how and progressive digital technology to deliver on our promises to patients around the world</p>
                </div>
              </div>
            </div>

            {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
              &&
              <ImageSliderMobile data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} history={this.props.history} />
              // :
              // <ImageSlider data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} history={this.props.history} />
            }


            {/* <div className='af-class-section'>
              {
                this.state.articleData.section2 && this.state.articleData.section2.length !== 0 && <ImageSliderGsap data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} history={this.props.history} />
              }
            </div> */}

            {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&<div className="af-class-section af-class-tall-blocks" style={{position: 'relative', backgroundColor: 'white'}}>
                <div className="af-class-container af-class-tall-bloks w-container">
                  <div className="w-dyn-list">
                    <div role="list" className="af-class-_3-col-grid af-class-tall-bloks w-dyn-items">

                    {
                    this.state.articleData?.section2 && this.state.articleData?.section2.map((item, index) => {
                      return <div key={"tall-slider" + index} role="listitem" className="w-dyn-item">
                          <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64cccf" className="af-class-tall-block" style={{backgroundColor: "#EDEDED"}}>
                            <div style={{position: 'absolute', left: 0, top: 0, margin: '30px', marginTop: '60px', display: 'flex', flexDirection: 'column'}}>
                            <h2 style={{fontSize: '32px', fontWeight: '300'}}>{item.title}</h2>
                            <p style={{fontSize: '18px', fontWeight: '300',  paddingTop: '20px'}} dangerouslySetInnerHTML={{__html: item.description}}></p>
                            </div>
                          <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd0" className="af-class-tall-block-text-content"
                            style={{margin: 0, padding: 0, backgroundColor: '#EDEDED', backgroundImage: `url(${item.thumbnail})`, backgroundRepeat: 'no-repeat', backgroundPositionX:
                              index == 0? '35%': '50%' }}>
                            {/* <img loading="lazy" className="af-class-image" src={item.thumbnail } alt="image" style={{height: '80%', objectFit: 'cover'}}/> */}
                            <p className="af-class-paragraph af-class-less-line-height" style={{display: 'flex', alignItems: 'center', justifyItems: 'center',
                              backgroundColor: "#EDEDED", position: 'absolute', bottom: 0, height: '120px', padding: '30px', paddingRight: '60px',
                              marginBottom: 0, width: '100%'}}>{item.title}</p>
                            <div data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd3" className="af-class-tall-block-sec-heading"
                              style={{position: 'absolute', left: 0, top: 0, margin: '30px', marginTop: '60px'}}>
                                <h2 className='af-class-h2-section-heading' style={{fontSize: '32px'}}>{item.title}</h2>
                                <p style={{fontSize: '18px', fontWeight: '300',  paddingTop: '20px'}} dangerouslySetInnerHTML={{__html: item.description}}></p>
                            </div>
                          </div>
                          <a href={`/articles?${item.contentId}#${item.id}`} className="af-class-tall-block-link w-inline-block">
                            <div className="af-class-tall-block-circle" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64ccd8" alt className="af-class-white-arrow" />
                          </a>

                        </div>
                      </div>
                    })
                  }
                    </div>

                  </div>
                </div>
              </div>}

              {/* {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
              <div className="af-class-section af-class-tall-blocks" style={{position: 'relative', backgroundColor: 'white'}}>
                <div className="af-class-container af-class-tall-bloks w-container">
                  <div className="af-class-_3-col-grid ">

                {
                    this.state.articleData?.section2 && this.state.articleData?.section2.map((item, index) => {
                      return  <div className="box" onMouseEnter={console.log('entered with ', index)} onMouseLeave={console.log('Left with index', index)}>
                                <div className="hid-box" style={{display: 'flex', flexDirection: 'column', left: 0, top: 0, margin: '30px', marginTop: '60px'}}>
                                  <h2 className='af-class-h2-section-heading' style={{fontSize: '32px'}}>{item.title}</h2>
                                  <p style={{fontSize: '18px', fontWeight: '300',  paddingTop: '20px'}} dangerouslySetInnerHTML={{__html: item.description}}></p>
                                </div>
                                <div className="box-face" style={{zIndex: 9, backgroundColor: '#EDEDED', display: 'flex', flexDirection: 'column', height: '100%'}}>
                                  <img  loading="lazy" src={item.thumbnail } alt="image" style={{height: '80%', objectFit: 'cover'}}/>
                                  <p className="af-class-paragraph af-class-less-line-height" style={{marginLeft: '30px', position: 'absolute', bottom: '3%', maxWidth: '250px'}}>{item.title}</p>
                                  <a data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64cccf" href={`/articles?${item.contentId}#${item.id}`} className="af-class-tall-block-link w-inline-block">
                                    <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                                </a>
                                </div>
                              </div>
                    })
                  }
                </div>
                </div>
                </div>
              } */}

            {/* {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
              <div className="af-class-section af-class-tall-blocks" style={{position: 'relative', backgroundColor: 'white'}}>
                <div className="af-class-container af-class-tall-bloks w-container">
                  <div className="af-class-_3-col-grid ">

                {
                    this.state.articleData?.section2 && this.state.articleData?.section2.map((item, index) => {
                      return  <div className="box">
                                <div className="hid-box" style={{display: 'flex', flexDirection: 'column', left: 0, top: 0, margin: '30px', marginTop: '60px'}}>
                                  <h2 className='af-class-h2-section-heading' style={{fontSize: '32px'}}>{item.title}</h2>
                                  <p style={{fontSize: '18px', fontWeight: '300',  paddingTop: '20px'}} dangerouslySetInnerHTML={{__html: item.description}}></p>
                                </div>
                                <div className="box-face" style={{zIndex: 9, backgroundColor: '#EDEDED', display: 'flex', flexDirection: 'column', height: '100%'}}>
                                  <img  loading="lazy"src={item.thumbnail } alt="image" style={{height: '80%', objectFit: 'cover'}}/>
                                  <p className="af-class-paragraph af-class-less-line-height" style={{marginLeft: '30px', position: 'absolute', bottom: '3%', maxWidth: '250px'}}>{item.title}</p>
                                  <a data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64cccf"  href={`/articles?${item.contentId}#${item.id}`} className="af-class-tall-block-link w-inline-block">
                                    <div className="af-class-tall-block-circle" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                                </div>
                                <a data-w-id="07b040d3-b31d-fcc5-35fd-cf96bf64cccf"  href={`/articles?${item.contentId}#${item.id}`} className="af-class-tall-block-link w-inline-block">
                                    <div className="af-class-tall-block-circle" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>
                    })
                  }
                </div>
                </div>
                </div>
              }
 */}

            <div data-w-id="19cc00fd-e1b3-336b-5837-c6cf2129d595" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container" style={{ display: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && "flex", flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'column' }} >
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && isTab
                      ?
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: isTab ? '60px' : '36px' }} >Where our goals <br /> and purpose <br /> come together<br /></h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ marginBottom: '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '36px' : '60px' }} >Where our goals and <br /> purpose come together<br /></h1>
                  }
                  <p ref={(el) => {
                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                      if (isTab) {
                        el.style.setProperty('font-size', '24px', 'important');
                        el.style.setProperty('line-height', '40px', 'important');
                      } else {
                        el.style.setProperty('line-height', '30px', 'important');
                      }
                    }
                  }} style={{ textAlign: 'center', paddingLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '15%' : '0' : '22%', paddingRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '15%' : '0' : '22%', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && !isTab && '335px', alignSelf: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'center' }} >Our people work towards fulfilling our promises to patients through innovative thinking, persistence and a passion to make the world a healthier place</p>
                </div>
              </div>
            </div>

            <div id="stories" className="af-class-section">
              <div className="af-class-container w-container">
                <div className="w-dyn-list">
                  <div role="list" className="af-class-articles-wrapper w-dyn-items">
                    {
                      this.state.articleData.section3 && this.state.articleData.section3.length !== 0
                        ?
                        this.state.articleData.section3.map((item, index) => {
                          return <div key={"Section_3_" + index} role="listitem" className="w-dyn-item">
                            <div data-w-id="a3de4921-36ea-576e-520a-3d4b12993d0b" style={{ opacity: 1 }} className="af-class-articles-block">
                              <a
                                href={`/articles?${item.contentId}#${item.id}`}
                                ref={(el) => {
                                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                    if (isTab) {
                                      el.style.setProperty('display', 'flex', 'important');
                                      el.style.setProperty('flex-direction', 'row', 'important');
                                    }
                                  }
                                }}
                                onClick={(ev) => {
                                  if (window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                    ev.preventDefault()
                                  }
                                  //   sessionStorage.setItem("navPath", JSON.stringify({ id: "/business-and-capabilities#stories" }))
                                }}
                                className="af-class-article-text-thumbnail-wrapper w-inline-block">
                                <div className="af-class-articles-thumbnail" ref={(el) => {
                                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                    if (isTab) {
                                      el.style.setProperty('width', '285px', 'important');
                                      el.style.setProperty('height', '400px', 'important');
                                    }
                                  }
                                }} >
                                  <img ref={(el) => {
                                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                      if (isTab) {
                                        el.style.setProperty('width', '285px', 'important');
                                        el.style.setProperty('height', '400px', 'important');
                                        el.style.setProperty('object-fit', 'cover', 'important');
                                      }
                                    }
                                  }} src={item.thumbnail} loading="lazy" alt className="af-class-articles-image" />
                                </div>
                                <div className="af-class-article-blurb-text" style={{ alignSelf: isTab && 'center' }} >
                                  <h3 className="af-class-article-title-text" >{item.title}</h3>
                                  {/* {
                                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.readMore !== item.id
                                      ?
                                      <span>
                                        <RenderText className="af-class-paragraph af-class-_600" dangerouslySetInnerHTML={{ __html: item.description }} />
                                        <span style={{ color: '#5225B5', fontSize: '14px', fontWeight: 'bold' }} onClick={() => {
                                          this.setState({ readMore: item.id })
                                        }} >Read More</span>
                                      </span>
                                      :
                                      <p className="af-class-paragraph af-class-_600" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                  } */}
                                  <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                                      if (isTab) {
                                        el.style.setProperty('font-size', '16px', 'important');
                                      }
                                    }
                                  }} className="af-class-paragraph af-class-_600" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px' }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                              </a>
                              {/* {item.externalLink && item.externalLink.length && <div className="af-class-article-link-block-wrapper">
                                <a href={item.externalLink} target="_blank" className="af-class-link-arrow af-class-below w-inline-block">
                                  <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>} */}
                              <div className="af-class-article-link-block-wrapper" style={{ justifyContent: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'flex-end' : 'flex-start', alignSelf: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'flex-end' : 'auto' }} >
                                <a
                                  href={`/articles?${item.contentId}#${item.id}`}
                                  onClick={() => {
                                    sessionStorage.setItem("navPath", JSON.stringify({ id: "/business-and-capabilities#stories" }))
                                  }}
                                  className="af-class-link-arrow af-class-below w-inline-block">
                                  <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                </a>
                              </div>
                            </div>
                          </div>
                        })
                        :
                        <>
                          {/* <div className="w-dyn-empty">
                          <div>No items found.</div>
                        </div> */}
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>

            {this.state.articleData && this.state.articleData?.section1 && <div id="jobs" className="af-class-section af-class-business-hero">
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                {
                  window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                    ?
                    isTab
                      ?
                      <h2 style={{ maxWidth: '100vw', paddingLeft: '0', paddingRight: '0', textAlign: 'center', marginBottom: '40px', fontSize: '60px' }} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">Careers at Dr. Reddy’s</h2>
                      :
                      <h2 style={{ maxWidth: '100vw', paddingLeft: '0', paddingRight: '0', textAlign: 'center', marginBottom: '24px', fontSize: isTab ? '46px' : '36px' }} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">Careers at <br /> Dr. Reddy’s</h2>
                    :
                    <h2 style={{ maxWidth: '80vw', paddingLeft: '10vw', paddingRight: '10vw', textAlign: 'center', marginBottom: '40px', fontSize: '60px' }} className="af-class-h2-section-heading af-class-title af-class-negative-margin af-class-show-mobile">Careers at Dr. Reddy’s</h2>
                }
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if (isTab) {
                      el.style.setProperty('font-size', '20px', 'important');
                      el.style.setProperty('line-height', '32px', 'important');
                    } else {
                      el.style.setProperty('line-height', '28px', 'important');
                    }
                  }
                }} style={{ paddingLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : '27%', paddingRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : '27%', textAlign: 'center', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >Our dynamic business offers opportunities for driven, ambitious people with diverse skill-sets people who want to make a difference in patients’ lives. If you’d like to be one of them, join us!</p>
                <a data-w-id="fc71a714-0e6a-5ae6-eb20-79cd8eacadb5" href="/job-seeker#refer" className="af-class-link-arrow-white af-class-center-align w-inline-block">
                  <div className="af-class-link-circle af-class-white" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-hide-mobile" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                </a>
              </div>
            </div>}


            {/* <div data-w-id="080f12b1-a28c-3852-8f4d-8f260169da53" className="af-class-section af-class-horizontal-move">
              <div className="af-class-horizontal-transition-wrapper">
                <div className="af-class-container w-container">
                  <div className="af-class-horizontal-image-slider"><img src="/images/water-neutrality.jpg" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/water-neutrality-p-1080.jpeg 1080w, /images/water-neutrality.jpg 1157w" alt className="af-class-horizontal-slider-image af-class-placeholder" /><img src="/images/digitalisation2x.jpg" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/digitalisation2x-p-500.jpeg 500w, /images/digitalisation2x-p-800.jpeg 800w, /images/digitalisation2x-p-1600.jpeg 1600w, /images/digitalisation2x-p-2000.jpeg 2000w, /images/digitalisation2x.jpg 2314w" alt className="af-class-horizontal-slider-image af-class-_2" /><img src="/images/graph2x.jpg" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 80vw, 1200px" srcSet="/images/graph2x-p-500.jpeg 500w, /images/graph2x-p-800.jpeg 800w, /images/graph2x-p-2000.jpeg 2000w, /images/graph2x.jpg 2314w" alt className="af-class-horizontal-slider-image af-class-_1" /></div>
                  <div data-w-id="080f12b1-a28c-3852-8f4d-8f260169da5a" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-desktop-only">
                    <h2 className="af-class-h2-section-heading af-class-_450">Adopting digitalisation to enhance productivity and relationships</h2>
                    <a href="https://dr-reddy.webflow.io/articles/adopting-digitalisation-to-enhance-productivity-and-relationships" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                      <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                    </a>
                  </div>
                  <div data-w-id="080f12b1-a28c-3852-8f4d-8f260169da63" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-_1 af-class-desktop-only">
                    <h2 className="af-class-h2-section-heading af-class-_450">Our three-pronged strategy to success</h2>
                    <a href="https://dr-reddy.webflow.io/articles/our-three-pronged-strategy-to-success" className="af-class-link-arrow af-class-left-side af-class-wide-cta af-class-bottom w-inline-block">
                      <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                    </a>
                  </div>
                  <div className="af-class-slider-image-mobile"><img src="/images/graph2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, 100vw" srcSet="/images/graph2x-p-500.jpeg 500w, /images/graph2x-p-800.jpeg 800w, /images/graph2x-p-2000.jpeg 2000w, /images/graph2x.jpg 2314w" alt className="af-class-horizontal-slider-image af-class-_1" />
                    <div data-w-id="72badc5a-c299-2bfc-faf5-07c6180b3ce0" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-_1">
                      <h2 className="af-class-h2-section-heading af-class-_450">Our three-pronged strategy to success</h2>
                      <a href="https://dr-reddy.webflow.io/articles/our-three-pronged-strategy-to-success" className="af-class-link-arrow af-class-left-side af-class-wide-cta af-class-bottom w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                      </a>
                    </div>
                  </div>
                  <div className="af-class-slider-image-mobile"><img src="/images/digitalisation2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 88vw, 100vw" srcSet="/images/digitalisation2x-p-500.jpeg 500w, /images/digitalisation2x-p-800.jpeg 800w, /images/digitalisation2x-p-1600.jpeg 1600w, /images/digitalisation2x-p-2000.jpeg 2000w, /images/digitalisation2x.jpg 2314w" alt className="af-class-horizontal-slider-image af-class-_2" />
                    <div data-w-id="ce23c683-be77-cce2-d9a8-198fa75645cd" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-white-text-overlay af-class-_1">
                      <h2 className="af-class-h2-section-heading af-class-_450">Adopting digitalisation to enhance productivity and relationships</h2>
                      <a href="https://dr-reddy.webflow.io/articles/adopting-digitalisation-to-enhance-productivity-and-relationships" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section id="making-healthcare-affordable" className="af-class-section af-class-delivering-health">
              <div className="af-class-container w-container">
                <div className="af-class-delivering-health-wrapper">
                  <div className="af-class-scroll-video-block">
                    <div data-poster-url="videos/DRL_BusinessGIFs-poster-00001.jpg" data-video-urls="videos/DRL_BusinessGIFs-transcode.mp4,videos/DRL_BusinessGIFs-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-making-good-health-video w-background-video w-background-video-atom"><video autoPlay loop style={{ backgroundImage: 'url("videos/DRL_BusinessGIFs-poster-00001.jpg")' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                      <source src="videos/DRL_BusinessGIFs-transcode.mp4" data-wf-ignore="true" />
                      <source src="videos/DRL_BusinessGIFs-transcode.webm" data-wf-ignore="true" />
                    </video>
                      <div className="af-class-stickey-video-text-overlay">
                        <h2 data-w-id="10e55cc7-6744-327e-5d4b-5236a348f72e" style={{ opacity: 0 }} className="af-class-h2-section-heading af-class-title af-class-left">Making good health available to billions around the world</h2>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-scroll-text-wrapper">
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Pharmaceutical Services and Active Ingredients</h2>
                          <h4 className="af-class-heading-4-bold">Active Pharmaceutical Ingredients (APIs)</h4>
                          <p className="af-class-paragraph">Our proficiency in developing complex APIs such as steroids and peptides, and complex long chain synthesis helps us deliver high-quality products. It has enabled us to become trusted partners to leading innovator and generic companies in the US, Europe, Latin America, Japan, Korea and other emerging markets.</p>
                          <a href="https://api.drreddys.com/" target="_blank" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h4 className="af-class-heading-4-bold">Custom Pharmaceutical Services (CPS)</h4>
                          <p className="af-class-paragraph">At the CPS division, we offer process and analytical development as well as formulation development in line with regulations set by the USFDA. Our endeavour is to help accelerate the journey from laboratory to market for global pharma and specialty companies worldwide by providing access to our capabilities, knowledge and experience.</p>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Global Generics</h2>
                          <p className="af-class-paragraph">Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.</p>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Proprietary Products</h2>
                          <p className="af-class-paragraph">Patients today are increasingly looking for comfort and convenience in taking medicines and adhering to treatment protocol. Our Proprietary Products division aims to fulfil their unmet and under-met needs in these aspects. We leverage our product development, translational medicine, clinical development, regulatory and commercial expertise to do so, and over the years, we’ve built a robust portfolio of innovative products. Our primary R&amp;D focus is on dermatology and neurology. We’ve created differentiated products for conditions such as acne, psoriasis, atopic dermatitis and warts, as well as in the areas of pain management, epilepsy and antibacterials.</p>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                          <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f743" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Pharmaceutical Services and Active Ingredients</div>
                              <h4 className="af-class-heading-4-bold">Active Pharmaceutical Ingredients (APIs)</h4>
                              <div>Our proficiency in developing complex APIs such as steroids and peptides, and complex long chain synthesis helps us deliver high-quality products. It has enabled us to become trusted partners to leading innovator and generic companies in the US, Europe, Latin America, Japan, Korea and other emerging markets.</div>
                              <a href="https://api.drreddys.com/" className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                              </a>
                            </div>
                          </div>
                          <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f74d" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <h4 className="af-class-heading-4-bold">Custom Pharmaceutical Services (CPS)<br /></h4>
                              <div>At the CPS division, we offer process and analytical development as well as formulation development in line with regulations set by the USFDA. Our endeavour is to help accelerate the journey from laboratory to market for global pharma and specialty companies worldwide by providing access to our capabilities, knowledge and experience.</div>
                            </div>
                          </div>
                          <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f757" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Global Generics<br /></div>
                              <div>Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.<br /></div>
                            </div>
                          </div>
                          <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f761" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div className="af-class-delivering-health-slider-heading">Proprietary Products<br /></div>
                              <div>Patients today are looking for more than just safe, effective medicines. They require information, coaching, and customized support to help them adhere to complex treatment protocols.<br /><br />Our Proprietary Products division aims to fulfil these unmet patient needs. In some cases, this involves developing a new dosage of a current drug. In other cases, it may involve developing new combinations of synergistic medications. The focus is on initiatives to improve patient experience with our products. Better experience results in better compliance, which means better health and outcomes for patients.<br /></div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f76b" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="10e55cc7-6744-327e-5d4b-5236a348f76f" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="10e55cc7-6744-327e-5d4b-5236a348f771" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                    <div className="af-class-drag-progress-fill af-class-fifty" />
                  </div>
                </div>
              </div>
            </section>
            <div data-w-id="5eeb2915-2035-03ad-a453-06bc59ceb179" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  <h1 className="af-class-h2-section-heading af-class-title af-class-width-800">Affordable, accessible solutions to fulfil patients’ unmet needs</h1>
                </div>
              </div>
              <div data-w-id="5eeb2915-2035-03ad-a453-06bc59ceb17e" className="af-class-affordable-healthcare">
                <div className="af-class-container w-container">
                  <div className="af-class-full-height-line" />
                  <div className="af-class-healthcare-types-wrapper">
                    <div className="af-class-healthcare-type-block">
                      <div className="af-class-purple-circle af-class-healthcare" />
                      <div data-w-id="c292214e-009d-95d1-5e58-eb0672afce08" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>ONCOLOGY</div>
                        <div data-w-id="247a6392-0f9b-5d99-353a-f06e47364d49" style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                        <div data-w-id="986e5a35-8656-97b2-d60a-722eca8d4bb8" style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                      </div>
                      <div data-w-id="d62d9593-7b8e-23e3-d30f-3e8c92bcf7e0" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>GASTROENTEROLOGY</div>
                        <div style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                      </div>
                      <div data-w-id="9ef5289c-62a5-ed1d-dfdf-da6b7630545b" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>Cardiovascular Diseases</div>
                        <div style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                      </div>
                      <div data-w-id="a841333e-915d-e57d-c767-dc91b6b9b93a" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>Diabetes</div>
                        <div style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                      </div>
                      <div data-w-id="e4cc87bd-f7c5-4441-fec5-9cb6db550c11" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>Dermatology</div>
                        <div style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                      </div>
                      <div data-w-id="dc0b92fa-0bc0-3783-7e96-ab2399b782f3" style={{ color: 'rgb(158,158,158)' }} className="af-class-healthcare-name">
                        <div>Pain Management</div>
                        <div style={{ width: '0%' }} className="af-class-hearlthcare-underline" />
                        <div style={{ WebkitTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-purple-circle af-class-healthcare-mobile" />
                      </div>
                    </div>
                    <div className="af-class-healthcare-description-wrapper">
                      <div style={{ WebkitTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0 }} className="af-class-healthcare-description-block af-class-_1">
                        <div data-w-id="9d0c9a1f-5cb8-bf32-9cf2-393ef06fb98d" data-animation-type="lottie" data-src="documents/Cancer-care.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">Our cancer care portfolio includes cytotoxics, targeted therapies and supportive care designed to combat the disease and address side effects of treatments. Some of our achievements like the development of the world’s first recombinant human GCSF biosimilar protein, the world’s first bio-similar therapeutic monoclonal antibody, and the single-source pegfilgrastim demonstrate our cutting-edge research and development capabilities in oncology.</p>
                        <a href="https://oncology.drreddys.com/" target="_blank" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                          <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        </a>
                      </div>
                      <div className="af-class-healthcare-description-block af-class-_2">
                        <div data-w-id="92dabe0b-a3d6-ef51-a5ce-37bb2cf0a9d9" data-animation-type="lottie" data-src="documents/Gastric-relief.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">We have a broad range of products to treat various gastroesophageal disorders. Our flagship product Omez is one of the world’s leading brands of omeprazoles. Over the years, the R&amp;D team has developed multiple products that treat irritable bowel syndrome, diarrhoea and constipation in adults and children. Recently, we started producing medicines to address the needs of patients suffering from Hepatitis B and Hepatitis C.</p>
                      </div>
                      <div className="af-class-healthcare-description-block af-class-_3">
                        <div data-w-id="f33d7894-74c4-ffe4-4891-480173db3d9e" data-animation-type="lottie" data-src="documents/Heart-health.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">We have developed a number of drugs to support patients suffering from hypertension, including Amlodipine, Amlodipine+Atenolol combination, ACE inhibitors like Enalapril, as well as Angiotensin Receptor Blockers like Telmisartan. We also strive to make patients’ lives more comfortable with beyond-the-pill solutions for treating their conditions. For instance, we launched monthly packs of Atorvastatin and Telmisartan to improve patient compliance in managing hypertension.</p>
                      </div>
                      <div className="af-class-healthcare-description-block af-class-_4">
                        <div data-w-id="066f9300-36d7-a1da-0f7e-2109e3d94fa6" data-animation-type="lottie" data-src="documents/Diabetic-care_new.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">Metformin, Glimepride, Glicazide and their combinations are just a few of the more well-known products in our extensive portfolio that contributes to the global war against diabetes. Our commitment to addressing patients’ concerns prompted us to develop a user-friendly dosage form of Metformin that can be swallowed easily. It helps them stick to the prescribed treatment regimen.</p>
                      </div>
                      <div className="af-class-healthcare-description-block af-class-_5">
                        <div data-w-id="ee2af654-b34a-931d-e1fe-dde7e7b46581" data-animation-type="lottie" data-src="documents/Skin-care.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">Our solutions for seborrheic dermatitis, psoriasis, corticosteroid-responsive dermatoses, various types of dermatitis, actinic keratosis, rosacea, warts and acne are among the best in the world. Over-the-counter products we produce for trichology and hair regrowth are also leading brands in the segment. Strictly following treatment regimens is essential to successfully treat skin diseases like acne. With this in mind, we have deployed patient programmes that make it easier for them to adhere to their prescribed medications.</p>
                      </div>
                      <div className="af-class-healthcare-description-block af-class-_6">
                        <div data-w-id="6431df2d-a008-5392-61af-77efd2c3ebb3" data-animation-type="lottie" data-src="documents/Pain-relief.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="1.0010009602293968" data-duration={0} data-ix2-initial-state={0} className="af-class-healthcare-name-lottie" />
                        <p className="af-class-paragraph af-class-_572">Nise, our brand of Nimesulide, is one of the top 300 brands in the Indian pharmaceutical market. Apart from Nimesulide, we have a number of other products including Ketorolac as well as hyaluronic acid injections that form a significant part of our generic and branded generic portfolio. Our R&amp;D efforts focus on creating new combinations alongside exploring alternative delivery mechanisms to improve patient comfort.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-z-index-2 af-class-padding-120">
              <div className="af-class-container w-container">
                <div className="af-class-drag-slider-wrapper af-class-padding-240-mobile">
                  <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                    <div className="af-class-mask w-slider-mask">
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_12x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_12x-p-500.jpeg 500w, /images/slide_12x.jpg 720w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>We assure product safety and efficacy through our robust Quality Management System, and uniform standards for all products across geographies. Our policy of continuous process and product improvement drives us to surpass industry standards.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_22x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_22x-p-500.jpeg 500w, /images/slide_22x.jpg 722w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>Integrated planning and excellence in execution help us maintain top-decile benchmark performance in all areas of manufacturing, quality control and supply chain management. The quality in our products and processes is reflected in our high Right First Time.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_32x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_32x-p-500.jpeg 500w, /images/slide_32x.jpg 720w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>We have incorporated a culture of all-time audit readiness and compliance, and ensure Quality by Design in all our products and processes. This enables us to manufacture and validate new products, and deliver high-quality medicines consistently.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_42x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_42x-p-500.jpeg 500w, /images/slide_42x.jpg 720w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>Our global network gives us the capability to scale up and create capacity ahead of the demand curve, and meet any sudden surges. Our long-term supply assurance is the reason we have abiding and meaningful partnerships with clients.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_52x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_52x-p-500.jpeg 500w, /images/slide_52x.jpg 722w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>Digitalising our manufacturing and quality control processes gives us a distinct competitive advantage in delivery, quality and cost. Deploying future-ready innovations on the ground to upskill our employees in real time allows us to constantly improve productivity.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_62x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_62x-p-500.jpeg 500w, /images/slide_62x.jpg 720w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>We have cultivated a culture of autonomous way of working that enables employees to carry out their functions with a sense of ownership as they run and maintain all operations in their domain.</div>
                          </div>
                        </div>
                      </div>
                      <div className="af-class-drag-slider-item af-class-promise w-slide">
                        <div className="af-class-slide-div">
                          <div>
                            <div className="af-class-drag-slider-item-thumbnail"><img src="/images/slide_72x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 300px, (max-width: 1279px) 350px, (max-width: 1439px) 27vw, (max-width: 1919px) 25vw, 372px" srcSet="/images/slide_72x-p-500.jpeg 500w, /images/slide_72x.jpg 720w" alt className="af-class-drag-slider-item-image" /></div>
                            <div>Concern for safety and the environment is reflected in all our operations. It resonates with our organisation’s goal of fostering the well-being of our employees and society.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-w-id="e8ac6975-9faa-6c9b-e4f1-bc6635a7d118" className="af-class-left-arrow af-class-lower af-class-down--145px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="e8ac6975-9faa-6c9b-e4f1-bc6635a7d11c" className="af-class-right-arrow af-class-lower af-class-down--145px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="e8ac6975-9faa-6c9b-e4f1-bc6635a7d11e" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div className="af-class-slide-nav w-slider-nav w-round" />
                  </div>
                </div>
                <div className="af-class-drag-slider-progress-bar">
                  <div className="af-class-drag-progress-fill af-class-fifty" />
                </div>
              </div>
            </div> */}

            {this.state.articleData && this.state.articleData?.section1 && <div id="contact-us" className="af-class-section af-class-z-index-2" style={{ backgroundColor: '#EDEDED' }} >
              <div className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading" >We’re always looking to grow our partnerships around the world, so if you’d like to join hands with us, contact us here</h2>
                <div style={{ borderTopColor: "#ff5046", borderTopWidth: '1px', borderTopStyle: 'solid', marginBottom: '40px' }} ></div>
                <EnquiryForm />
              </div>
            </div>}

            <div className="af-class-section af-class-knowledge-bank" style={{ backgroundColor: '#EDEDED', margin: 0, padding: 0 }} >
              <a href="#back-to-top" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="/images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="/images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(BusinessCapabilitiesView)

/* eslint-enable */
