import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import Cdropdown from '../pages/common/Cdropdown';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FiChevronDown } from 'react-icons/fi';
import {scroller} from 'react-scroll';

import { isValidEmail, isValidZipcode, isValidIndianMobile, isEmpty } from '../Utils/Utils';
import { useMediaQuery } from 'react-responsive';

import axios from "axios";
import config from '../config';

const TextInputRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 35px;
`;

const TextInput = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 50%;
`;

const TextInputEnd = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
width: 50%;
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    `;

const FormHeading = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 70px;
`;

const TextLight = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: 300;
    font-family: Drlcircular;
    margin: 0;
    margin-top: 10px;
    `;

const TextBold = styled.p`
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    font-family: Drlcircular;
    margin: 0;
    `;

const Label = styled.p`
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: bold !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
    `;

const Input = styled.input`
    border: 0px;
    background-color: #fff;
    height: 50px;
    width: 100%;
    font-family: Drlcircular;
    letter-spacing:0px !important;
    &:focus{
        outline: none !important;
        border:1px solid #5225B5;
        border-radius: 5px;
    }
    padding: 20px 15px;
    border-radius: 5px;

`;

const TextArea = styled.div`
    display: flex;
flex-direction: row;
align-items: center;
width: 100%;
letter-spacing:0px !important;
`;

const TextAreaInput = styled.textarea`
border: 0px;
background-color: #fff;
height: 100px;
font-family: Drlcircular;
resize: none;
width: 100%;
padding:10px;
border-radius: 5px;
&:focus{
        outline: none !important;
        border:1px solid #5225B5;
    }
`;

const DropDown = styled.a`
    background-color: #fff !important;
    height: 50px !important;
    width: 50% !important;
    display: flex !important;
    flex-direction: row !important;
    padding: 15px !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: pointer !important;
    text-decoration: none !important;
    /* border:1px solid #5225B5;
    border-radius: 5px; */
`;

const DropDwonLabel = styled.p`
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    margin: 0 !important;
`;

const AcceptPolicySection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    margin: 24px 0px;
`;

const AcceptTextLight = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-weight: 300!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    width:90%;
`;

const AcceptTextLink = styled.a`
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    font-family: Drlcircular;
    margin: 0;
    cursor: pointer;
    text-decoration: none !important;
    &:hover{
        text-decoration: underline !important;
        color: #000000;
    }
`;

const RadioButton = styled.a`
    cursor: pointer!important;
    text-decoration: none !important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    background-color: #fff !important;
    border: #5225B5 solid 0.5px!important;
    width: 20px!important;
    height: 20px!important;
    border-radius: 20px!important;
    margin-right: 15px!important;
`;

const RadioActive = styled.div`
    background-color: #FF5046;
    width: 12px;
    height: 12px;
    border-radius: 12px;
`;

const RobotText = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-weight: 300!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    margin-top: 10px !important;
    text-align:'center';
`;

const ErrorText = styled.span`
    font-size: 12px!important;
    color: #FF5046!important;
    font-weight: 400!important;
    font-family: Drlcircular!important;
    margin: 0!important;
    line-height: 14px !important;
    margin-top:8px !important;
`;

const ButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 24px;
`;

const ClearButton = styled.a`
    font-size: 20px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #9090905D !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    min-width:60vw;
    max-width:60vw;
    align-items:center;
    justify-content:center;
    text-align:center;
    margin-bottom:20px;
`;

const SubmitButton = styled.a`
    font-size: 20px !important;
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-family: Drlcircular !important;
    background-color: #5225B5 !important;
    border-radius: 5px !important;
    padding: 15px 0px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    min-width:60vw;
    max-width:60vw;
    align-items:center;
    justify-content:center;
    text-align:center;
    margin-top:20px;
`;

const StyledToastContainer = styled(ToastContainer)`
        .Toastify__toast-body {
            font-family: Drlcircular !important;
            font-size:20px !important;
            font-weight:400 !important;
        }
        .Toastify__toast-icon {
            width:32px;
            height:32px;
            margin-left:10px;
        }
        @media (max-width: 900px) {
            align-self:center;
            .Toastify__toast-icon {
            width:24px;
            height:24px;
            margin-left:10px;
        }
            .Toastify__toast-body {
            font-size:14px !important;
        }
        }
    `;

export function EnquiryFormMobile(props) {

    const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

    const recaptchaRef = React.createRef();

    const [errorToastBool, setErrorToastBool] = useState(false);

    const [formData, setFormData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        organisation: '',
        jobTitle: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        poc: '',
        subject: '',
        msg: ''
    })
    const [formErrData, setFormErrData] = useState({
        fName: '',
        lName: '',
        email: '',
        phNo: '',
        organisation: '',
        jobTitle: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        poc: '',
        subject: '',
        msg: ''
    })

    const clearForm = () => {
        setAcceptBool(false);
        setStep(1);
        setFormErrData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            organisation: '',
            jobTitle: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            poc: '',
            subject: '',
            msg: ''
        })
        setFormData({
            fName: '',
            lName: '',
            email: '',
            phNo: '',
            organisation: '',
            jobTitle: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            poc: '',
            subject: '',
            msg: ''
        })
        scroller.scrollTo('formStart',{smooth: true, duration: 100,offset:-200 });
    }

    const [acceptBool, setAcceptBool] = useState(false);
    const [dropDownBool, setDropDownBool] = useState(false);

    const [step, setStep] = useState(1);

    const SubmitForm = () => {
        var submitBool = true;
        var errorMsg = '';
        var formErrDataTemp = { ...formErrData };
        if (!isEmpty(formData.fName).status) {
            formErrDataTemp = ({ ...formErrDataTemp, fName: isEmpty(formData.fName).message })
            submitBool = false;
        }
        // if (!isEmpty(formData.lName).status) {
        //     formErrDataTemp = ({ ...formErrDataTemp, lName: isEmpty(formData.lName).message })
        //     submitBool = false;
        // }
        if (!isValidEmail(formData.email).status) {
            formErrDataTemp = ({ ...formErrDataTemp, email: isEmpty(formData.email).message })
            submitBool = false;
        }
        if (!isValidIndianMobile(formData.phNo).status) {
            formErrDataTemp = ({ ...formErrDataTemp, phNo: isEmpty(formData.phNo).message })
            submitBool = false;
        }
        // if (!isValidZipcode(formData.zipCode).status) {
        //     formErrDataTemp = ({ ...formErrDataTemp, zipCode: isEmpty(formData.zipCode).message })
        //     submitBool = false;
        // }
        if (!isEmpty(formData.country).status) {
            formErrDataTemp = ({ ...formErrDataTemp, country: isEmpty(formData.country).message })
            submitBool = false;
        }
        if (!isEmpty(formData.poc).status) {
            formErrDataTemp = ({ ...formErrDataTemp, poc: isEmpty(formData.poc).message })
            submitBool = false;
        }
        if (!isEmpty(formData.msg).status) {
            formErrDataTemp = ({ ...formErrDataTemp, msg: isEmpty(formData.msg).message })
            submitBool = false;
        }
        setFormErrData({ ...formErrDataTemp })
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!(recaptchaValue && recaptchaValue.length)) {
            errorMsg = 'Please complete the Captcha';
        }
        if (!acceptBool) {
            errorMsg = 'Please accept and agree the terms and privacy.';
        }
        if (submitBool && errorMsg.length === 0) {
            setErrorToastBool(false);
            axios({
                method: "get",
                url: config.serverUrl.base + "/api/form/token"
            }).then((res) => {
                if (res.status == 200) {
            axios({
                method: "post",
                url: config.serverUrl.base + "/jsonapi/node/form", data: {
                    "data": {
                        "type": "node--form",
                        "attributes": {
                            "title": "form data",
                            "field_fname": formData.fName,
                            "field_lname": formData.lName,
                            "field_email": formData.email,
                            "field_phno": formData.phNo,
                            "field_organisation": formData.organisation,
                            "field_jobtitle": formData.jobTitle,
                            "field_city": formData.city,
                            "field_state": formData.state,
                            "field_zipcode": formData.zipCode,
                            "field_country": formData.country,
                            "field_subject": formData.subject,
                            "field_purposeofcontact": formData.poc,
                            "field_message": formData.msg,
                        }
                    }
                },
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    'Accept': 'application/vnd.api+json',
                    'Applications': res.data.key,
                },
            }).then((res) => {
                console.log(res, " =-=-=-=  ")
                toast.success('Your details have been submitted successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: 'message'
                })
            }).catch((err) => { })
        }}).catch((err) => { })

        } else {
            setErrorToastBool(true);
            errorMsg = !submitBool ? 'Please check the errors and retry again' : errorMsg;
            toast.warning(errorMsg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: 'message'
            })
        }
        // else {
        //     toast.error('Please check and fill form with proper values', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         toastId: 'message'
        //     })
        // }
    }

    const onNextPress = () => {
        var submitBool = true;
        var errorMsg = '';
        var formErrDataTemp = { ...formErrData };
        if (step === 1) {
            if (!isEmpty(formData.fName).status) {
                formErrDataTemp = ({ ...formErrDataTemp, fName: isEmpty(formData.fName).message })
                submitBool = false;
            }
            // if (!isEmpty(formData.lName).status) {
            //     formErrDataTemp = ({ ...formErrDataTemp, lName: isEmpty(formData.lName).message })
            //     submitBool = false;
            // }
            if (!isValidEmail(formData.email).status) {
                formErrDataTemp = ({ ...formErrDataTemp, email: isValidEmail(formData.email).message })
                submitBool = false;
            }
            if (!isValidIndianMobile(formData.phNo).status) {
                formErrDataTemp = ({ ...formErrDataTemp, phNo: isValidIndianMobile(formData.phNo).message })
                submitBool = false;
            }
            if (!isEmpty(formData.country).status) {
                formErrDataTemp = ({ ...formErrDataTemp, country: isEmpty(formData.country).message })
                submitBool = false;
            }
        } else if (step === 2) {
            // if (!isValidZipcode(formData.zipCode).status) {
            //     formErrDataTemp = ({ ...formErrDataTemp, zipCode: isValidZipcode(formData.zipCode).message })
            //     submitBool = false;
            // }
        } else if (step === 2) {
            if (!isEmpty(formData.poc).status) {
                formErrDataTemp = ({ ...formErrDataTemp, poc: isEmpty(formData.poc).message })
                submitBool = false;
            }
            if (!isEmpty(formData.msg).status) {
                formErrDataTemp = ({ ...formErrDataTemp, msg: isEmpty(formData.msg).message })
                submitBool = false;
            }
            const recaptchaValue = recaptchaRef.current.getValue();
            if (!(recaptchaValue && recaptchaValue.length)) {
                errorMsg = 'Please complete the Captcha';
            }
            if (!acceptBool) {
                errorMsg = 'Please accept and agree the terms and privacy.';
            }
        }
        setFormErrData({ ...formErrDataTemp })
        if (submitBool && errorMsg.length === 0) {
            setErrorToastBool(false);
            setStep(step + 1);
        }
        // else {
        //     setErrorToastBool(true);
        //     errorMsg = !submitBool ? 'Please check the errors and retry again' : errorMsg;
        //     toast.warning(errorMsg, {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         toastId: 'message'
        //     })
        // }
    }

    const pocData = ["Business Development Opportunity",
        "Company Information",
        "API Manufacturing",
        "Unit Dose Bar Code Database Request",
        "IIT Application Request ",
        "Formulation Manufacturing ",
        "Process Request ",
        "Printed Materials Request ",
        "Production Question or Comment ",
        "Website Question or Comment ",
        "Other"];

    const CloseButton = ({ closeToast }) => (
        <img loading="lazy" src="/images/close-fff.svg" height="18px" width="18px" style={{ margin: '10px', marginTop: '14px' }} />
    );


    return <FormSection id={"formStart"} >

        {step === 1 && <>
            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Full Name</Label>
                <Input type="input" value={formData.fName} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, fName: ev.target.value })
                    setFormErrData({ ...formErrData, fName: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.fName}</ErrorText>
            </span>

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Last Name<span style={{ color: '#FF5046' }} >*</span></Label>
                <Input type="input" value={formData.lName} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, lName: ev.target.value })
                    setFormErrData({ ...formErrData, lName: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.lName}</ErrorText>
            </span> */}

            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Email Address</Label>
                <Input type="input" value={formData.email} onChange={(ev) => {
                    var validation = isValidEmail(ev.target.value);
                    setFormData({ ...formData, email: ev.target.value })
                    setFormErrData({ ...formErrData, email: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.email}</ErrorText>
            </span>

            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Phone Number</Label>
                <Input maxLength={10} onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }} type="input" value={formData.phNo} onChange={(ev) => {
                    var validation = isValidIndianMobile(ev.target.value, 'Phone Number');
                    setFormData({ ...formData, phNo: ev.target.value })
                    setFormErrData({ ...formErrData, phNo: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.phNo}</ErrorText>
            </span>

            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Country</Label>
                <Input type="input" value={formData.country} onChange={(ev) => {
                    var validation = isEmpty(ev.target.value);
                    setFormData({ ...formData, country: ev.target.value })
                    setFormErrData({ ...formErrData, country: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.country}</ErrorText>
            </span>

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Organisation</Label>
                <Input type="input" value={formData.organisation} onChange={(ev) => {
                    setFormData({ ...formData, organisation: ev.target.value })
                }} />
            </span>

            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Job Title</Label>
                <Input type="input" value={formData.jobTitle} onChange={(ev) => {
                    setFormData({ ...formData, jobTitle: ev.target.value })
                }} />
            </span> */}
        </>}

        {step === 2 && <>
            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >City</Label>
                <Input type="input" value={formData.city} onChange={(ev) => {
                    setFormData({ ...formData, city: ev.target.value })
                }} />
            </span> */}

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >State</Label>
                <Input type="input" value={formData.state} onChange={(ev) => {
                    setFormData({ ...formData, state: ev.target.value })
                }} />
            </span>

            <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Zip Code<span style={{ color: '#FF5046' }} >*</span></Label>
                <Input type="input" value={formData.zipCode} onChange={(ev) => {
                    var validation = isValidZipcode(ev.target.value);
                    setFormData({ ...formData, zipCode: ev.target.value })
                    setFormErrData({ ...formErrData, zipCode: validation.status ? '' : validation.message })
                }} />
                <ErrorText>{formErrData.zipCode}</ErrorText>
            </span> */}

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Country</Label>
                <Input type="input" value={formData.country} onChange={(ev) => {
                    setFormData({ ...formData, country: ev.target.value })
                }} />
            </span> */}

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Purpose of contacting</Label>
                <div style={{ width: '100%' }}>
                    <Cdropdown
                        borderColor={"transparent"}
                        data={pocData}
                        placeholder="Select"
                        id="POC"
                        selectedValue={formData.poc}
                        onChangeValue={(val) => { setFormData({ ...formData, poc: val }) }}
                    />
                </div>
            </span> */}

            {/* <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }} >
                <Label style={{ fontSize: "18px" }} >Subject</Label>
                <Input type="input" value={formData.subject} onChange={(ev) => {
                    setFormData({ ...formData, subject: ev.target.value })
                }} />
            </span> */}
        </>}

        {step === 2 &&
            <>
               <span style={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'flex-start' }} >
                    <Label style={{ fontSize: "18px", }} >Purpose of Contacting</Label>
                    <TextAreaInput placeholder="Type your msg here" value={formData.poc} onChange={(ev) => {
                        var validation = isEmpty(ev.target.value);
                        setFormData({ ...formData, poc: ev.target.value })
                        setFormErrData({ ...formErrData, poc: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.msg}</ErrorText>
                </span>

                <span style={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'flex-start' }} >
                    <Label style={{ fontSize: "18px", }} >Your Message Or question</Label>
                    <TextAreaInput placeholder="Type your msg here" value={formData.msg} onChange={(ev) => {
                        var validation = isEmpty(ev.target.value);
                        setFormData({ ...formData, msg: ev.target.value })
                        setFormErrData({ ...formErrData, msg: validation.status ? '' : validation.message })
                    }} />
                    <ErrorText>{formErrData.msg}</ErrorText>
                </span>

                <AcceptPolicySection>
                    <RadioButton onClick={() => {
                        setAcceptBool(!acceptBool)
                    }} >
                        {acceptBool && <RadioActive />}
                    </RadioButton>
                    <AcceptTextLight>I agree and accept the <AcceptTextLink href="/privacy-policy" >Privacy Notice</AcceptTextLink> and <AcceptTextLink href="terms-of-use" >Terms of Use</AcceptTextLink> of this website</AcceptTextLight>
                </AcceptPolicySection>

                <span style={{ width: '100%',marginBottom:'24px' }} >
                    <ReCAPTCHA sitekey="6LfbvfkhAAAAAECKD1X5cTSQMbMD94PstmwxsMHx" ref={recaptchaRef} />
                    <RobotText>Please confirm that you are not a robot</RobotText>
                </span>
            </>
        }

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }} >
            <a style={{  textDecoration: 'none', margin: '0 24px' }} onClick={() => {
                if (step !== 1) {
                    setStep(step - 1);
                }
                scroller.scrollTo('formStart',{smooth: true, duration: 100,offset:-200 });
            }} ><img loading="lazy" alt="leftArr" src={step === 1 ? 'images/leftAshArrow.svg' : 'images/leftPrimaryArrow.svg' } /></a>
            <p style={{ margin: 0, fontSize: '18px', fontWeight: 'bold', color: '#5225B5' }} >{step} of 2</p>
            <a style={{ textDecoration: 'none', margin: '0 24px' }} onClick={() => {
                // onNextPress();
                if (step !== 2) {
                setStep(step + 1);
                }
                scroller.scrollTo('formStart',{smooth: true, duration: 100,offset:-200 });
            }} ><img loading="lazy" alt="leftArr" src={step === 2 ? 'images/rightAshArrow.svg' : 'images/rightPrimaryArrow.svg' } /></a>
        </div>

        { step === 2 && <ButtonSection>
                    {/* <ClearButton onClick={clearForm} >Clear</ClearButton> */}
                    <SubmitButton onClick={SubmitForm}>Submit</SubmitButton>
                    <StyledToastContainer
                        position="top-center"
                        // autoClose={3000}
                        autoClose={3000000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        toastStyle={{ backgroundColor: errorToastBool ? '#FF5046' : '#25B987', color: 'white',borderRadius:'4px' }}
                        style={{ zIndex: 999999999999999, width: minWidthBool ? '80%' : '565px', fontFamily: 'Drlcircular !important', marginLeft: minWidthBool ? '10vw' : 0, fontSize: minWidthBool ? '14px' : '20', fontWeight: '500', bottom: '100px',borderRadius:'4px' }}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        closeButton={CloseButton}
                    />
                </ButtonSection>}

    </FormSection>
}
