import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

import { isTab } from '../../Utils/Utils';

const EnvironmentMobile = ({ data, history }) => {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        console.log('enter environment mobile..', ScrollTrigger.getAll()); 

        if (ScrollTrigger.getById('trigger3')) ScrollTrigger.getById('trigger3').refresh();
        // ScrollTrigger.refresh();

        else {
        // let images = document.querySelectorAll('img');
        // let z = -1;

        // images.forEach(image => {
        //     console.log(image.clientHeight);

        //     gsap.set(image, {yPercent: -100, zIndex: () => z});
        //     gsap.to(image, {
        //       scrollTrigger: {
        //         trigger: image,
        //         start: "top bottom",
        //         end: `+=${image.clientHeight}`,
        //         pin: true,
        //         pinSpacing: false,
        //         markers: true
        //       }
        //     });
        //     z = z - 1;
        // });

        let tl = gsap.timeline({
            scrollTrigger: {
                id: "trigger3",
                trigger: "#container-EnvironmentMobile",
                start: "top",
                // end: "+=1200",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
                invalidateOnRefresh: true,
                preventOverlaps: true,
                onEnter: () => {console.log('enter environment ... ', ScrollTrigger.getAll())},
                onLeave: () => {console.log('leave environment ... ', ScrollTrigger.getAll())},
                onEnterBack: () => {console.log('enter back environment ... ', ScrollTrigger.getAll())},
                onLeaveBack: () => {console.log('leave back environment ... ', ScrollTrigger.getAll())},
            },
            // onComplete: () => ScrollTrigger.refresh()
        });

        // tl.set("#text-EnvironmentMobile-one", { opacity: 1})
        // tl.set("#text-EnvironmentMobile-two", { opacity: 0})
        // // tl.set("#text-EnvironmentMobile-three", { opacity: 0, zIndex: -140 })
        // // tl.set("#text-EnvironmentMobile-four", { opacity: 0, zIndex: -140 })
        // tl.set("#img-EnvironmentMobile-two", { opacity: 0 })
        // // tl.set("#img-EnvironmentMobile-three", { opacity: 0 })
        // // tl.set("#img-EnvironmentMobile-four", { opacity: 0 })

        // // tl.to('#img-EnvironmentMobile-one', {
        // //   opacity: 0})

        // tl.to('#text-EnvironmentMobile-one', {
        //     opacity: 0,
        //     duration: 0.2
        // }, "<")

        // tl.set("#text-EnvironmentMobile-one", { opacity: 0})
        // // tl.set("#img-EnvironmentMobile-one", {opacity: 0}, "<")
        // tl.set("#img-EnvironmentMobile-two", { opacity: 1 }, "<")

        // // tl.fromTo('#img-EnvironmentMobile-two', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EnvironmentMobile-two', {
        //     opacity: 0
        // },
        //     { opacity: 1, duration: 0.2 }, "<")

        // tl.set("#text-EnvironmentMobile-two", { opacity: 0, zIndex: -140 }, ">")
        // tl.set("#img-EnvironmentMobile-two", {opacity: 0}, "<")
        // tl.set("#img-EnvironmentMobile-three", { opacity: 1 }, "<")


        // tl.fromTo('#img-EnvironmentMobile-three', {
        //     yPercent: 100
        // },
        //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EnvironmentMobile-three', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text-EnvironmentMobile-three", { opacity: 0, zIndex: -140 }, ">")
        // tl.set("#img-EnvironmentMobile-two", {opacity: 0}, "<")
        // tl.set("#img-EnvironmentMobile-four", { opacity: 1 }, "<")


        // tl.fromTo('#img-EnvironmentMobile-four', {
        //     yPercent: 100
        // },
        //     { yPercent: 0 }, ">")

        // tl.fromTo('#text-EnvironmentMobile-four', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        tl.set("#img-EnvironmentMobile-one", { opacity: 1 })
        tl.set("#text-EnvironmentMobile-one", { opacity: 1, zIndex: 9999, })

        tl.set("#img-EnvironmentMobile-two", { opacity: 0 })
        tl.set("#text-EnvironmentMobile-two", { opacity: 0, zIndex: -140 })
        tl.set("#img-EnvironmentMobile-two", { opacity: 1 }, "+=0.4")
        tl.set("#text-EnvironmentMobile-one", { opacity: 0, zIndex: -140 }, "<")
        tl.set("#text-EnvironmentMobile-two", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text-EnvironmentMobile-two", { opacity: 1, zIndex: 9999, }, "+=0.2")
        }
        console.log('leave environment mobile..', ScrollTrigger.getAll()); 


    }, [data])


    return (
        <>
            <div id="container-EnvironmentMobile" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
                <div id="img-EnvironmentMobile-container" style={{ flex: 1, overflow: 'hidden', position: 'relative', }}>
                    <div id="img-EnvironmentMobile-one" class="img" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
                        <img  loading="lazy" id="img1" style={{ height: '100%', width: '100%', objectFit: 'cover', }} src={"/images/gyan-shahane-3C7zhY3gcQc-unsplash.png"} alt="image1" />
                        <div style={{ position: 'absolute', left: 0, top: 0, zIndex: '99', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex', }} >
                            <p id="text-EnvironmentMobile-one" ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches) {
                                    if(isTab){
                                    el.style.setProperty('font-size', '40px', 'important');
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '48px', 'important');
                                    }
                                }
                            }} style={{ fontSize: '36px', fontWeight: '300', color: '#fff', margin: 0, lineHeight: '48px', textAlign: 'left',textAlign:'center' }} >Environmental Sustainability</p>
                        </div>
                    </div>
                    <div id="img-EnvironmentMobile-two" class="img" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, opacity: 0 }}>
                        <img  loading="lazy" id="img2" style={{ height: '100%', width: '100%', objectFit: 'cover', }} src={"/images/syna-tiger-resort-Ed5aHMSzs-c-unsplash_mobile.jpg"} alt="image2" />
                        <div style={{ position: 'absolute', left: 0, top: 0, zIndex: '99', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex', }} >
                            <p ref={(el) => {
                                if (el && window.matchMedia('(max-width: 820px)').matches) {
                                    if(isTab){
                                    el.style.setProperty('font-size', '40px', 'important');
                                    el.style.setProperty('line-height', '60px', 'important');
                                    } else {
                                    el.style.setProperty('line-height', '48px', 'important');
                                    }
                                }
                            }} style={{ fontSize: '36px', fontWeight: '300', color: '#fff', margin: 0, lineHeight: '48px', textAlign: 'left',textAlign:'center' }} >Environmental Sustainability</p>
                        </div>
                    </div>
                </div>
                <div id="txt-container" style={{ minHeight: isTab?'440px':'380px', maxHeight: isTab?'440px':'380px', position: 'relative', bottom: 0 }}>
                    <div id="text-EnvironmentMobile-one" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%', width: '100%', top: 0, left: 0 }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                if(isTab){
                                el.style.setProperty('font-size', '32px', 'important');
                                el.style.setProperty('line-height', '60px', 'important');
                                } else {
                                el.style.setProperty('line-height', '33px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', }} >Action for Climate and Environment (ACE)</p>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                if(isTab){
                                el.style.setProperty('font-size', '18px', 'important');
                                el.style.setProperty('line-height', '32px', 'important');
                                el.style.setProperty('max-width', 'unset', 'important');
                                } else {
                                el.style.setProperty('line-height', '24px', 'important');
                                }
                            }
                        }} className="af-class-paragraph" style={{ fontSize: '14px' }} >Our ACE programme focuses on preserving the livelihoods of small and marginal farmers from the effects of climate change by encouraging them to adopt climate-friendly technologies and farming practices. ACE promotes and implements practices that focus on sustainably increasing agricultural productivity and incomes; lowering and/or removing greenhouse gas emissions; and adapting and building resilience to climate change through leading farmer platforms. This project is implemented in partnership with the Dr. Reddy’s Foundation.</p>
                    </div>
                    <div id="text-EnvironmentMobile-two" style={{ opacity: 0, position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%', width: '100%', top: 0, left: 0 }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                if(isTab){
                                el.style.setProperty('font-size', '32px', 'important');
                                el.style.setProperty('line-height', '60px', 'important');
                                } else {
                                el.style.setProperty('line-height', '33px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', }} >Conserving Tigers in the Eastern Ghats</p>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                if(isTab){
                                el.style.setProperty('font-size', '18px', 'important');
                                el.style.setProperty('line-height', '32px', 'important');
                                el.style.setProperty('max-width', 'unset', 'important');
                                } else {
                                el.style.setProperty('line-height', '24px', 'important');
                                }
                            }
                        }} className="af-class-paragraph" style={{ fontSize: '14px' }} >The project aims at conserving tigers and their habitats in the Nagarjunasagar Srisailam Tiger Reserve and Amrabad Tiger Reserve through an integrated stakeholder approach. It helps staff elevate management and effectiveness within the reserves, increase knowledge on the use of key corridors by tigers and other wildlife, and promotes sustainable practices among local communities in select villages in the corridor areas.</p>
                    </div>
                </div>
            </div>
        </>
    );

}

export default EnvironmentMobile;