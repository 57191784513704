import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import styled from 'styled-components';
import styles from '../../../styles';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}`, title: (2001 + ind) }));

const ScrollContainer = styled.div`
            width:100%;
        `;

const WhiteImg = styled.img`
    display: none;
     height:17px;
        width:8px;
        max-width:8px;
`;
const PrimaryImg = styled.img`
    display: inline;
     height:17px;
        width:8px;
        max-width:8px;
`;

const HoverADiv = styled.div`
display:flex;
width:90px;
height:90px;
border-radius:90px;
align-items:center;
justify-items:center;
            -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    &:hover{
        align-items:center;
        justify-items:center;
        width:90px;
        height:90px;
        border-radius:90px;
        background-color:#5225b5 ;
    }
    &:hover ${WhiteImg} {
        display: inline;
    }

    &:hover ${PrimaryImg} {
        display: none;
    }
        `;



const YearFilterScroller = (props) => {

    const { selectedYear, onChange, data, id } = props;

    return <div>
        <div style={{ width: '100%', height: '60px', margin: window.matchMedia('(max-width: 820px)').matches ? '20px 0' : '40px 0' }} >
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} >
                {data.map((item, index) => (
                    <Card
                        itemId={id + index} // NOTE: itemId is required for track items
                        title={item}
                        key={index}
                        selected={selectedYear === item}
                        onChange={onChange}
                    />
                ))}
            </ScrollMenu>
        </div>
    </div>
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        window.matchMedia('(max-width: 820px)').matches
            ?
            <a disabled={isFirstItemVisible} onClick={() => scrollPrev()}
                // className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" 
                style={{ cursor: 'pointer', alignSelf: 'center', paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '20px' : '40px', paddingRight: window.matchMedia('(max-width: 820px)').matches ? '20px' : '40px', pointerEvents: isFirstItemVisible ? 'none' : 'auto' }} >
                {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </div> */}
                {/* <div className="af-class-link-circle af-class-purple af-class-hide" /> */}
                {
                    isFirstItemVisible
                        ?
                        <div className="" style={{ pointerEvents: 'none', }}>
                            <img src="images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow af-class-reversed" />
                        </div>
                        :
                        <img src="images/chevron_left_purple.svg" style={{ display: 'inline' }} loading="lazy" alt className="af-class-black-arrow af-class-reversed" />
                }
                {/* <HoverADiv> */}
                {/* <WhiteImg src="images/Path-237.svg" style={{display:'none'}} loading="lazy" alt className="af-class-white-arrow af-class-reversed af-class-hide" /> */}
                {/* </HoverADiv> */}
            </a>
            :
            <a disabled={isFirstItemVisible} onClick={() => scrollPrev()}
                // className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" 
                style={{ cursor: 'pointer', alignSelf: 'center', display: 'flex', pointerEvents: isFirstItemVisible ? 'none' : 'auto', marginTop: '-15px' }} >
                {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </div> */}
                {/* <div className="af-class-link-circle af-class-purple af-class-hide" /> */}
                {
                    isFirstItemVisible
                        ?
                        <div className="" style={{ paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', paddingRight: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', }}>
                            <img src="images/Grey-right-arrow.svg" style={{ width: '8px', height: '17px', maxWidth: '8px' }} loading="lazy" alt className="af-class-black-arrow af-class-reversed" />
                        </div>
                        :
                        <HoverADiv style={{ paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', }} >
                            <WhiteImg src="images/Path-237.svg" style={{width: '8px', height: '17px', maxWidth: '8px'}} loading="lazy" alt className="af-class-white-arrow af-class-reversed af-class-hide" />
                            <PrimaryImg src="images/chevron_left_purple.svg" loading="lazy" style={{ left: '-7px',width: '8px', height: '17px', maxWidth: '8px' }} alt className="af-class-black-arrow af-class-reversed" />
                        </HoverADiv>
                }
            </a>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        window.matchMedia('(max-width: 820px)').matches
            ?
            <a disabled={isLastItemVisible} onClick={() => scrollNext()}
                // className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" 
                style={{ cursor: 'pointer', alignSelf: 'center', paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '20px' : '40px', paddingRight: window.matchMedia('(max-width: 820px)').matches ? '20px' : '40px', pointerEvents: isLastItemVisible ? 'none' : 'auto' }} >

                {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </div> */}
                {/* <div className="af-class-link-circle af-class-purple af-class-hide" /> */}
                {
                    isLastItemVisible
                        ?
                        <img src="images/Grey_arrow.svg" loading="lazy" alt className="af-class-grey-arrow" />
                        :
                        <img src="images/chevron_arrow_purple.svg" style={{ display: 'inline' }} loading="lazy" alt className="af-class-black-arrow" />
                }
                {/* <HoverADiv>
            <WhiteImg src="images/Path-237.svg" style={{display:'none'}} loading="lazy" alt className="af-class-white-arrow af-class-hide" />
            </HoverADiv> */}
            </a>
            :
            <a disabled={isLastItemVisible} onClick={() => scrollNext()}
                // className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block" 
                style={{ cursor: 'pointer', alignSelf: 'center', display: 'flex', pointerEvents: isLastItemVisible ? 'none' : 'auto', marginTop: '-15px' }} >

                {/* <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                                            <div className="af-class-link-circle af-class-purple" /><img src="images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                                        </div> */}
                {/* <div className="af-class-link-circle af-class-purple af-class-hide" /> */}
                {
                    isLastItemVisible
                        ?
                        <div className="" style={{ paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', paddingRight: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', }}>
                            <img src="images/Grey_arrow.svg" loading="lazy" style={{ width: '8px', height: '17px', maxWidth: '8px' }} alt className="af-class-grey-arrow" />
                        </div>
                        :
                        <HoverADiv style={{ paddingLeft: window.matchMedia('(max-width: 820px)').matches ? '21px' : '41px', }} >
                            <WhiteImg src="images/Path-237.svg" style={{width: '8px', height: '17px', maxWidth: '8px'}} loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                            <PrimaryImg src="images/chevron_arrow_purple.svg" style={{ left: '-7px',width: '8px', height: '17px', maxWidth: '8px' }} loading="lazy" alt className="af-class-black-arrow" />
                        </HoverADiv>
                }
            </a>
    );
}

function Card({ selected, title, itemId, onChange }) {
    const visibility = React.useContext(VisibilityContext);

    return (<div key={itemId} style={{ width: window.matchMedia('(max-width: 820px)').matches ? '16.5vw' : '8.3vw', marginTop: '15px', textAlign: 'center' }} >
        <p onClick={() => {
            onChange(title)
        }} style={{ fontSize: window.matchMedia('(max-width: 820px)').matches ? '24px' : '40px', color: selected ? '#5225B5' : '#909090', cursor: 'pointer', fontWeight: selected ? '500' : '300' }} >{title}</p>
    </div>
        // <div
        //     onClick={() => onClick(visibility)}
        //     style={{
        //         width: '160px',
        //     }}
        //     tabIndex={0}
        // >
        //     <div className="card">
        //         <div>{title}</div>
        //         <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //         <div>selected: {JSON.stringify(!!selected)}</div>
        //     </div>
        //     <div
        //         style={{
        //             height: '200px',
        //         }}
        //     />
        // </div>
    );
}
{/* <a href="" onClick={handleClick(id)} selected={isItemSelected(id)} style={{ fontSize: '40px', fontWeight: 200, textDecoration: 'none', margin: '0 10px' }} >{id}</a> */ }

export default YearFilterScroller;