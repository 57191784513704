import React from 'react';
import styled from 'styled-components';

import { AiOutlineClose } from 'react-icons/ai';

const PopupContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    opacity: 1;
    z-index: 999999;
`;

const PopupWrapper = styled.div`
    background-color: #5225B5;
    width: 100%;
    padding: 20px 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 767px) {
        padding: 20px 15px;
    }
`;

const CookieContent = styled.p`
    font-size: 16px !important ;
    color: #fff !important ;
    font-weight: 300 !important ;
    line-height:30px !important ;
    margin-right:30px;
    @media screen and (max-width: 767px) {
        font-size: 12px !important ;
        margin-right:10px;
    }
`;

const AcceptButton = styled.a`
    background: #EDEDED !important ;
    /* border: 1px solid #573e96 !important ; */
    border-radius: 5px !important ;
    color: #000 !important ;
    font-size: 16px !important ;
    font-weight: 400 !important ;
    margin-right:50px;
    /* position: relative !important ;
    overflow: hidden !important ;
    z-index: 0 !important ; */
    text-align: center !important ;
    transition: all .25s ease-out !important ;
    cursor: pointer !important ;
    text-decoration:none !important;
    @media screen and (max-width: 767px) {
        margin-right:0px;
    }
    `;
const CloseButton = styled.a`
    font-size: 0;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
`;

const AnchorTag = styled.a`
    color: #fff !important ;
    font-size: 16px !important ;
    font-weight: bold !important ;
    cursor: pointer !important ;
    text-decoration:none !important;
    @media screen and (max-width: 767px) {
        font-size: 12px !important ;
    }
`;

export function CookiesPopup(props) {
    return <PopupContainer>
        <PopupWrapper>
            <CookieContent>
                This website uses cookies to help us give you the best experience when you visit. By using this website you consent to our use of cookies. For more information please review our cookie policy.{"  "}
                <AnchorTag style={{}} href="/cookie-policy">Cookie Policy</AnchorTag>
            </CookieContent>
            <AcceptButton onClick={() => {
                props.setCookeAccpet({ enable: true });
            }} ><div style={{margin:window.matchMedia('(max-width: 820px)').matches?'10px 15px':'10px 40px',fontSize:window.matchMedia('(max-width: 820px)').matches?'12px':'16px'}} >Accept</div></AcceptButton>
            <CloseButton onClick={() => {
                props.setCookieBool(false);
                sessionStorage.setItem("CookieBool", false);
            }} ><AiOutlineClose size={"25px"} color="#fff" /></CloseButton>
        </PopupWrapper>
    </PopupContainer>
}