import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "/images/acidityfree.jpg",
        "heading": "Acidityfree",
        "desc": "Billions of people suffer from chronic acidity but don't take it seriously enough. This initiative is for patients who are only getting short-term relief."
    },
    {
        "id": "slider_2",
        "img": "/images/mintop.jpg",
        "heading": "Mintop Privilege Club",
        "desc": "Hair loss can be tough. So, we help patients through their hair recovery journeys."
        // "desc": "In a world where looking good is everything, hair loss is tough. So, we hand-hold patients through their hair recovery journeys."
    },
    {
        "id": "slider_3",
        "img": "/images/pexels-thirdman-7659367.png",
        "heading": "Circle of Trust",
        "desc": "Cancer changes the lives of patients and those closest to them. Through this programme, we raise awareness among cancer patients and caregivers screening and diagnosis."
    },
    {
        "id": "slider_4",
        "img": "/images/dietlogues.jpg",
        "heading": "Dietlogues",
        "desc": "Everywhere you look, there's diet advice, which can be overwhelming. With this initiative, we facilitate nutrition-focused tele-counselling."
    },
    {
        "id": "slider_5",
        "img": "/images/healthy-bones.jpg",
        "heading": "Healthy Bones",
        "desc": "There are a bunch of things to do to stay fit. This programme tells you how to keep your bones healthy and strong."
    },
    {
        "id": "slider_6",
        "img": "/images/manage-before-damage.jpg",
        "heading": "Manage Before Damage",
        "desc": "Diabetes can be dangerous, but it doesn't have to be. This initiative educates diabetics and encourages them to get regular checks and keep readings under control."
    },
    {
        "id": "slider_7",
        "img": "/images/hepatitis-a.jpg",
        "heading": "Hepatitis A Awareness Drive",
        "desc": "We take our fight against Hep A to the masses through this programme to make young parents aware of the disease and consider vaccination."
    },
]
    ;

const OurPatientInitiativesSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 90);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', height: '80px', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                console.log(readMore)
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px',  marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default OurPatientInitiativesSlider;