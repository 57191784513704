import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import config from '../../../config';

import { HR } from '../../common/horizontalLine';
// import { AnimeDownloadButton } from '../../common/AnimeDownloadButton';

const Main = styled.div`
    width:100%;
`;

const FileMain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  @media (max-width: 900px) {
    font-size: 14px !important;
    width: 75% !important;
  }
`;

export const MediaKit = (props) => {


    return <Main>
        {/* <FileMain>
            <TextHeading>Fact Sheet</TextHeading>
            <a target="_blank" href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/static/mediakit/factsheet.pdf"} style={{ marginRight:window.matchMedia('(max-width: 820px)').matches? '0' : '30px' }}
                className="af-class-link-arrow af-class-left-side w-inline-block">
                <div className="af-class-link-circle af-class-purple" />
                <img src="images/downloadPrimary.svg" loading="lazy" alt className="af-class-black-arrow" />
                <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" />
            </a>
        </FileMain>
        <HR /> */}
        <FileMain>
            <TextHeading>Corporate Presentation </TextHeading>
            <a target="_blank" href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/static/mediakit/corporate-presentation.pdf"} style={{ marginRight: window.matchMedia('(max-width: 820px)').matches ? '0' : '30px' }}
                className="af-class-link-arrow">
                {/* <div className="af-class-link-circle af-class-purple" /> */}
                <img src="images/downloadPrimary.svg" loading="lazy" alt />
                {/* <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" /> */}
            </a>
        </FileMain>
        <HR />
        <FileMain>
            <TextHeading>Dr. Reddy’s Annual Report </TextHeading>
            <a target="_blank" href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/static/Annual_Report_FY2022.pdf"} style={{ marginRight: window.matchMedia('(max-width: 820px)').matches ? '0' : '30px' }}
                className="af-class-link-arrow">
                {/* <div className="af-class-link-circle af-class-purple" /> */}
                <img src="images/downloadPrimary.svg" loading="lazy" alt />
                {/* <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" /> */}
            </a>
        </FileMain>
        <HR />
        <FileMain>
            <TextHeading>Dr. Reddy’s Sustainability Report</TextHeading>
            <a target="_blank" href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/static/BRSR_FY2022.pdf"} style={{ marginRight: window.matchMedia('(max-width: 820px)').matches ? '0' : '30px' }}
                className="af-class-link-arrow">
                {/* <div className="af-class-link-circle af-class-purple" /> */}
                <img src="images/downloadPrimary.svg" loading="lazy" alt />
                {/* <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" /> */}
            </a>
        </FileMain>
        <HR />
        <FileMain>
            <TextHeading>Corporate Logo</TextHeading>
            <a target="_blank" href={config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/static/mediakit/corporate-logo.zip"} style={{ marginRight: window.matchMedia('(max-width: 820px)').matches ? '0' : '30px' }}
                className="af-class-link-arrow">
                {/* <div className="af-class-link-circle af-class-purple" /> */}
                <img src="images/downloadPrimary.svg" loading="lazy" alt />
                {/* <img src="images/downloadFFF.svg" loading="lazy" alt className="af-class-white-arrow" /> */}
            </a>
        </FileMain>
        <HR />
    </Main>
}
