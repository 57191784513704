import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

export const Disclosures = (props) => {


  const TableContainer = styled.div`
  width: 100%;
  overflow:hidden;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      padding: 5px;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
        @media (max-width: 820px) {
          font-size: 14px;
        }
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
        @media (max-width: 820px) {
          font-size: 14px;
        }
      }
    }
  }
`;

 
  const MainContainer = styled.div`
  width: 100%;
  margin-top:40px;
`;
 
 
const TextContent = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  margin-right:80px;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline !important;
    margin-bottom: 15px;
  }
  `;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const [Disclosures, setDisclosures] = useState({});

  useEffect(() => {
        var tempReports = {};
        var temp = [];
        var tempArr = [];
        axios({
            method: "get",
            url: config.serverUrl.base + "/jsonapi/node/disclosures_46",
        })
            .then((res) => {
                for (const report of res.data.data) {
                    tempArr = [];
                    if (
                        tempReports?.[report.attributes.title] && [report.attributes.title]
                    ) {
                        tempArr = tempReports[report.attributes.title];
                        tempArr.push({
                            description: temp[report.attributes.field_disclosure_table.value],
                        });
                        tempReports[report.attributes.title] = tempArr;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            description: report.attributes.field_disclosure_table.value,
                        });
                        tempReports[report.attributes.title] = tempArr;
                    }
                }
                setDisclosures(tempReports);
            })
            .catch((err) => {
                console.log(err, "err");
            });
    }, []);

  return (<MainContainer>
    <TableContainer style={{ marginTop: "35px" }}>
      <table width="100%">
        {Disclosures?.["Disclosures Regulation 46"] &&
          Disclosures?.["Disclosures Regulation 46"].map((item, index) => {
            var result = [];
            var tempArr = [];
            for (const i in item.description) {
              if (i !== "caption") {
                tempArr = [];
                item.description[i].map((item2, index2) => {
                  if (i === "0") {
                    tempArr.push(<th style={{ padding: '20px 15px', }} >{item2}</th>);
                  } else if(index2 === 2) {
                    tempArr.push(<td style={{ padding: '20px 15px', width: '15vw'}} >
                      {item2 && (item2.indexOf("http") !== -1) ? <TextContent to={{pathname:item2}} target="_blank" >
                       Click here</TextContent> : item2}
                    </td>);
                  } else {
                    tempArr.push(<td style={{ padding: '20px 15px', wordBreak: 'break-all'}} >{item2}</td>);
                  }
                });
                result.push(<tr>{tempArr}</tr>);
              }
            }
            return result;
          })}
      </table>
    </TableContainer>
  </MainContainer>

  )

}