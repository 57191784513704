const ManufacturingMarkers = [
    {
      markerOffset: -5,
      name: "INDIA",
      mName: "India",
      coordinates: [78.44351609220966, 17.492441287638922],
      show: true,
      tooltipData: {
        title: "INDIA",
        address: [
          "API Hyderabad Plant 1 <br/>Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District<br/> Telangana, 502 325. <br/>INDIA Tel: +91.8458.283 200",
          "API Hyderabad Plant 2 <br/>Dr. Reddy's Laboratories Limited, Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112,<br/> Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, <br/>Pin Code: - 502325.",
          "API Hyderabad 3 <br/>Plot No. 116, 116A & 126C & SY No. 157 IDA Bollaram,<br/> Jinnaram Mandal Medak District<br/> Telangana 502 325.<br/> INDIA Tel: +91.8458.283 700",
          "API Nalgonda Plant<br/> Peddadevulapally Tripuraram Mandal <br/>Nalgonda District Telangana 508 207.<br/> INDIA Tel: +91.8689. 253 150",
          "API Srikakulam Plant<br/> IDA Pydibheemavaram Ransthal Mandal<br/> Srikakulam District Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.288 131",
          "API Srikakulam Plant (SEZ)<br/> Sector No. 28 & 34 Devunipalavalasa Village<br/> Ranastalam Mandal Srikakulam District<br/> Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.304141",
          "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA<br/>Tel: +91.8458.279 669",
          "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA<br/>Tel: +91.40.4464 2200",
          "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA<br/>Tel: +91.40.4464 3440",
          "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 56001",
          "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 650761",
          "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
          "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
          "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91.8942 200046",
          "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91 8942 304300",
          "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA",
        ],
        mAddress: [
            { text: "API Hyderabad Plant 1 <br/>Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District<br/> Telangana, 502 325. <br/>INDIA", telNo: '+91 8458 283 200' },
            { text: "API Hyderabad Plant 2 <br/>Dr. Reddy's Laboratories Limited, Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112,<br/> Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, <br/>Pin Code: - 502325.", },
            { text: "API Hyderabad 3 <br/>Plot No. 116, 116A & 126C & SY No. 157 IDA Bollaram,<br/> Jinnaram Mandal Medak District<br/> Telangana 502 325.<br/> INDIA", telNo: '+91 8458 283 700' },
            { text: "API Nalgonda Plant<br/> Peddadevulapally Tripuraram Mandal <br/>Nalgonda District Telangana 508 207.<br/> INDIA", telNo: '+91 8689 253 150' },
            { text: "API Srikakulam Plant<br/> IDA Pydibheemavaram Ransthal Mandal<br/> Srikakulam District Andhra Pradesh 532 409.<br/> INDIA", telNo: '+91 8942 288 131' },
            { text: "API Srikakulam Plant (SEZ)<br/> Sector No. 28 & 34 Devunipalavalasa Village<br/> Ranastalam Mandal Srikakulam District<br/> Andhra Pradesh 532 409.<br/> INDIA", telNo: '+91 8942 304141' },
            { text: "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA", telNo: '+91 8458 279 669' },
            { text: "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA", telNo: '+91 40 4464 2200' },
            { text: "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA", telNo: '+91 40 4464 3440' },
            { text: "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA", telNo: '+91 1795 56001' },
            { text: "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA", telNo: '+91 1795 650761' },
            { text: "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913 013400' },
            { text: "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913 013400' },
            { text: "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942 200046' },
            { text: "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942 304300' },
            { text: "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA", },
        ]
      },
    },
    {
      markerOffset: -5,
      name: "UNITED KINGDOM",
      mName: "United Kingdom",
      coordinates: [-3.585901210996002, 55.92909145687116],
      tooltipData: {
        title: "UNITED KINGDOM",
        address: [
          "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM",
          "Dr. Reddy's Laboratories (EU) Limited<br/>Steanard Lane<br/>Mirfield, West Yorkshire<br/>WF 14, 8HZ<br/>UNITED KINGDOM",
        ],
        mAddress: [
            { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM", },
            { text: "Dr. Reddy's Laboratories (EU) Limited<br/>Steanard Lane<br/>Mirfield, West Yorkshire<br/>WF 14, 8HZ<br/>UNITED KINGDOM", },
        ]
      },
    },
    {
      markerOffset: -5,
      name: "USA",
      mName: "USA",
      coordinates: [-74.66761139608748, 40.36021222873629],
      tooltipData: {
        title: "USA",
        address: [
          "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport<br/>Louisiana 71106. USA<br/>Tel: +1.318.861 8070",
          "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA<br/>Tel: +1.518.827 7702",
        ],
        mAddress: [
            { text: "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport<br/>Louisiana 71106. USA", telNo: '+1 318 861 8070' },
            { text: "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA", telNo: '+1 518 827 7702' },
        ]
      },
    },
    {
      markerOffset: -5,
      name: "MEXICO",
      mName: "Mexico",
      coordinates: [-102.77017727008456, 23.897694628422407],
      tooltipData: {
        title: "MEXICO",
        address: [
          "API Cuernavaca Plant<br/>Industrias Quimicas Falcon De Mexico S.A. de C.V.<br/>Carretera Federal Cuernavaca-Cuautla<br/>KM 4.5 CIVAC, Jiutepec<br/>Morelos, Mexico 62578",
        ],
        mAddress: [
            { text: "API Cuernavaca Plant<br/>Industrias Quimicas Falcon De Mexico S.A. de C.V.<br/>Carretera Federal Cuernavaca-Cuautla<br/>KM 4.5 CIVAC, Jiutepec<br/>Morelos, Mexico 62578", },
        ]
      },
    },
    {
      markerOffset: -5,
      name: "CHINA",
      mName: "China",
      coordinates: [102.84413692391112, 35.13746209622783],
      tooltipData: {
        title: "CHINA",
        address: [
          "Dr. Reddy's Laboratories Ltd.<br/>Beijing Representative Office<br/>Room 1509-1515, Tower A, Gemdale Center,<br/>No. 91, Jianguo Road Chaoyang District 100026<br/>Beijing, P.R. CHINA<br/>Tel: +86.10.6562 3000/ 6562 3001",
          "Kunshan Rotam Reddy Pharmaceutical Co. Limited<br/>No.258, Huang Pu Jiang (M) Road<br/>Kunshan Development Zone, Jiangsu Province 215 300<br/>P.R. CHINA<br/>www.rotamreddy.com",
        ],
        mAddress: [
            { text: "Dr. Reddy's Laboratories Ltd.<br/>Beijing Representative Office<br/>Room 1509-1515, Tower A, Gemdale Center,<br/>No. 91, Jianguo Road Chaoyang District 100026<br/>Beijing, P.R. CHINA", telNo: '+86.10.6562 3000/ 6562 3001' },
            { text: "Kunshan Rotam Reddy Pharmaceutical Co. Limited<br/>No.258, Huang Pu Jiang (M) Road<br/>Kunshan Development Zone, Jiangsu Province 215 300<br/>P.R. CHINA<br/>www.rotamreddy.com", },
        ]
      },
    },
  ]

  export default ManufacturingMarkers;