import React from "react";
import styled from "styled-components";

import { useEffect, useState } from "react";

import config from "../../../../../config";

import { useMediaQuery } from "react-responsive";

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const TextContainer = styled.div`
  display: flex;
    flex-direction: column;
    margin-bottom: 30px !important;

`;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 30px !important;
  font-family: Drlcircular !important;
  margin-bottom: 40px !important;
  @media (max-width: 900px) {
      font-size: 24px !important;
      margin-bottom: 20px !important;
  }
`;

const TextContent = styled.a`
  width: 500px;
  margin-left: 48%;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px !important;
  font-family: Drlcircular;
  margin-bottom: 10px !important;
  text-decoration: none;
  color: #000 !important;
  cursor: pointer;
  text-decoration:none !important;
  align-self:start;
  &:hover{
    color: #000 !important;
      text-decoration: underline !important;
  }
`;


export const BioMedicalWasteManagementReport = (props) => {

    const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  const isPhoneScreen = useMediaQuery({ query: '(max-width: 500px)' })
  
  return (<>
  
    <SubSubContainer>
        {/* <h2>BIOMEDICAL WASTE MANAGEMENT REPORT</h2> */}
      <div style={{ display: "flex", flexDirection:minWidthBool?'column': "row", width: '80%',marginBottom:'60px', }}>
      {/* <ul>
         <li><a href="biomedicalwastereportmonthly" >BioMedical Waste Report Monthly</a> </li><br/>
         <li><a href="biomedicalwastereportannual">BioMedical Waste Report Annual</a> </li>
      </ul>  */}
      <TextContainer>
                {/* <TextHeading>
                   BIOMEDICAL WASTE MANAGEMENT REPORT
                </TextHeading> */}
                <TextContent
                target="_blank"
                  href={
                     "/biomedicalwastemanagementreport/biomedicalwastereportmonthly" 
                  }
                >
                 BioMedical Waste Report Monthly
                 
                  </TextContent>
                  <TextContent
                target="_blank"
                  href={
                     "/biomedicalwastemanagementreport/biomedicalwastereportannual" 
                  }
                >
                 BioMedical Waste Report Annual
                  </TextContent>
                {/* <TextContent>
                <ul >
                   <li ><a href="biomedicalwastereportmonthly"  style={{color: "black"}} >BioMedical Waste Report Monthly</a> </li><br/>
                   <li ><a href="biomedicalwastereportannual" style={{color: "black"}}>BioMedical Waste Report Annual</a> </li>
                </ul>
                </TextContent> */}
            </TextContainer>
        {/* <Label
          style={{
            fontSize: "18px",
            alignSelf: minWidthBool?'start':"center",
            width:minWidthBool?'70%': '30%'
          }}
        >
         Biomedical Waste Report Monthly

        </Label> */}
        {/* <DropDownMain
          style={{
            display: "flex",
            flexDirection:minWidthBool?'column': "row",
            width:minWidthBool? '100%': '70%'
          }}
        >
        </DropDownMain> */}
      </div>
    </SubSubContainer>
  </>
  )
}