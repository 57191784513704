import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "/images/shutterstock_1181684455.png",
        "heading": "Youth Skilling",
        "desc": "Youth Skilling initiatives focus on better skills and better jobs. It aims at delivering industry-aligned core employability skills, which are domain agnostic and meet the requirements of multiple sectors. GROW helps place youth in quality positions matching their aspirations. The project is implemented in partnership with the Dr. Reddy’s Foundation."
        // "desc": "Developed in 2016, GROW provides industry-aligned core employability skills that are domain agnostic and meet requirements across multiple sectors for 18 to 30-year-old graduates. GROW was earlier knows as LABS. The project is implemented in partnership with the Dr. Reddy’s Foundation."
    },
    {
        "id": "slider_2",
        "img": "/images/Mask_Group_299.png",
        "heading": "Skilling for Persons with Disabilities",
        "desc": "Placement-linked skilling programme for youth with physical challenges and vision, speech and hearing impairment. The programme is implemented in partnership with the Dr. Reddy’s Foundation and Cheshire Disability Trust."
    },
    {
        "id": "slider_3",
        "img": "/images/Mask-Group-297.png",
        "heading": "Healthcare Skilling",
        "desc": "The availability of qualified human resource has emerged as a significant challenge facing healthcare delivery mechanism. To combat the problem, the healthcare skilling program trains youth who seek employment opportunity in the non-medical healthcare sector. The programme is implemented in partnership with Dr Reddy’s Foundation."
        // "desc": "Our HQHCS programme trains youth who seek employment in the non-medical healthcare domain. It has been developed by industry experts and is taught by highly qualified trainers. HQHCS is implemented in partnership with the Dr. Reddy’s Foundation."
    },
    {
        "id": "slider_4",
        "img": "/images/karthik-s-fo5ZvAVvhYk-unsplash.png",
        "heading": "Making Integrated Transformation through Resourceful Agriculture (MITRA)",
        "desc": "MITRA aims at empowering farmers by encouraging them to adopt the latest technology and farming practices. The goal is to enhance productivity and reduce input costs, resulting in an increase in farmers’ incomes. MITRA is implemented in partnership with the Dr. Reddy’s Foundation."
    },
    {
        "id": "slider_5",
        "img": "/images/richard-saunders-_e-KrDQ_M8Q-unsplash.png",
        "heading": "Farmer Field Schools",
        "desc": "In partnership with the Naandi Foundation, we run Farmer Field Schools to empower farmers working at coffee plantations in the Araku Valley region of Andhra Pradesh, India. They are trained in organic biodynamic farming, farmer cooperation and business planning."
    },
]
    ;

const LivelihoodSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 85);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', height: '60px', fontSize: '16px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', height: '80px', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                console.log(readMore)
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default LivelihoodSlider;