import React from 'react';

import img1 from '../../assets/peopleStories/img1.jpeg';
import img2 from '../../assets/peopleStories/img2.jpeg';
import img3 from '../../assets/peopleStories/img3.jpeg';
import img4 from '../../assets/peopleStories/img4.jpeg';

const ImageTextSlider = ({ data, history }) => {

    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                   navigator.userAgent &&
                   navigator.userAgent.indexOf('CriOS') == -1 &&
                   navigator.userAgent.indexOf('FxiOS') == -1;

    return (
        <div className="af-class-delivering-health-wrapper" style={{}} >
            <div className="af-class-scroll-image-stack-block" >
                {/* { data && data.length !== 0 && <div className="af-class-stacked-images-wrapper"> */}
                <div className="af-class-stacked-images-wrapper" style={{overflow:'hidden'}} >
                    {/* <img key={"imgRender_3"} src={img4} loading="lazy" alt className={"af-class-stack_image af-class-_4"} />
                    <img key={"imgRender_2"} src={img3} loading="lazy" alt className={"af-class-stack_image af-class-_3"} />
                    <img key={"imgRender_1"} src={img2} loading="lazy" alt className={"af-class-stack_image af-class-_2"} />
                    <img key={"imgRender_0"} src={img1} loading="lazy" data-w-id={"f887d200-5ac6-9488-d1b1-a054378cd6b0"} alt className={"af-class-stack_image af-class-_1"} /> */}
                    {
                        data.map((item, index) => {
                            const classNames = ["af-class-stack_image af-class-_1", "af-class-stack_image af-class-_2", "af-class-stack_image af-class-_3", "af-class-stack_image af-class-_4"];
                            return (
                                <img key={"imgRender_" + index} src={item.thumbnail} loading="lazy" style={{ opacity: index === 0 ? 1 : 0 }} 
                                // data-w-id={index === 0 ? "f887d200-5ac6-9488-d1b1-a054378cd6b0" : ""} 
                                alt className={classNames[index]} />
                            )
                        })
                    }
                    {/* <img src="/images/new-lease-on-life.jpg" loading="lazy"  alt className="af-class-stack_image af-class-_4" />
                    <img src="/images/committed-to-help.jpg" loading="lazy" alt className="af-class-stack_image af-class-_3" />
                    <img src="/images/digitising-the-road.jpg" loading="lazy" alt className="af-class-stack_image af-class-_2" />
                    <img src="/images/the-human-touch.jpg" loading="lazy" style={{ opacity: 0 }} data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6b0" alt className="af-class-stack_image af-class-_1" /> */}
                    <div className="af-class-stickey-video-text-overlay">
                        {/* <h2 data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6b2" style={{ opacity: 0 }} className="af-class-h2-section-heading af-class-title af-class-left af-class-full-width">Living our<br/>purpose</h2> */}
                        <h2 data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6b2" style={{ opacity: 0,fontSize:window.matchMedia('(max-width: 820px)').matches?'48px':'60px' }} className="af-class-h2-section-heading af-class-title">Living our<br />purpose</h2>
                    </div>
                </div>
                {/* </div>} */}
            </div>
            <div className="af-class-scroll-text-wrapper" >
                <div className="w-dyn-list">
                    {
                        data.length !== 0
                            ?
                            data.map((item, index) => {
                                return (<div role="list" className="w-dyn-items">
                                    <div role="listitem" className="w-dyn-item">
                                        <div className="af-class-scroll-text-block">
                                            <div className="af-class-scroll-text-div">
                                                <div className="af-class-scroll-animation-box">
                                                    <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: index === 0 ?  isSafari ? '80px' : '60px' : '0' }} >{item.title}</h2>
                                                    <p className="af-class-paragraph" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    <a
                                                        style={{ marginBottom: index === 0 ? isSafari ? '80px' : '60px' : '0' }}
                                                        href={`/articles?${item.contentId}#${item.id}`}
                                                        onClick={() => {
                                                            sessionStorage.setItem("navPath", JSON.stringify({ id: "/PeopleStories#People-Stories-Hero" }))
                                                            // history.push(`/articles?${item.contentId}#${item.id}`);
                                                        }}
                                                        // href={"/articles"}
                                                        //     onClick={() => {
                                                        //         localStorage.setItem("data", JSON.stringify(item))
                                                        //     }} 
                                                        className="af-class-link-arrow af-class-left-side w-inline-block" >
                                                        <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                            :
                            <div className="w-dyn-empty">
                                <div>No items found.</div>
                            </div>
                    }
                </div>
                <div className="af-class-drag-slider-wrapper af-class-mobile-only" style={{paddingBottom:'0px'}} >
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                        {
                            data.map((item, index) => {
                                return <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6c7" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <h4 className="af-class-heading-4-bold">{item.title}</h4>
                                    <p className="af-class-paragraph" dangerouslySetInnerHTML={{ __html: item.description }} />
                                    <a href={`/articles?${item.contentId}#${item.id}`} className="af-class-link-arrow af-class-left-side w-inline-block">
                                        <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                    </a>
                                </div>
                            </div>
                            })
                        }
                            {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6c7" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Pharmaceutical Services and Active Ingredients</div>
                                    <h4 className="af-class-heading-4-bold">Active Pharmaceutical Ingredients (APIs)</h4>
                                    <div>Our proficiency in developing complex APIs such as steroids and peptides, and complex long chain synthesis helps us deliver high-quality products. It has enabled us to become trusted partners to leading innovator and generic companies in the US, Europe, Latin America, Japan, Korea and other emerging markets.</div>
                                    <a href="https://api.drreddys.com/" className="af-class-link-arrow af-class-left-side w-inline-block">
                                        <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                    </a>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6d3" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <h4 className="af-class-heading-4-bold">Custom Pharmaceutical Services (CPS)<br /></h4>
                                    <div>At the CPS division, we offer process and analytical development as well as formulation development in line with regulations set by the USFDA. Our endeavour is to help accelerate the journey from laboratory to market for global pharma and specialty companies worldwide by providing access to our capabilities, knowledge and experience.</div>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6da" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Global Generics<br /></div>
                                    <div>Many patients globally cannot afford expensive medicines for treatment. Our Generic Formulations business addresses this urgent need by offering high-quality generic versions of innovator medicines at a fraction of the cost to over 80 countries around the world.<br /></div>
                                </div>
                            </div>
                            <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6e2" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                                <div className="af-class-slide-div">
                                    <div className="af-class-delivering-health-slider-heading">Proprietary Products<br /></div>
                                    <div>Patients today are looking for more than just safe, effective medicines. They require information, coaching, and customized support to help them adhere to complex treatment protocols.<br /><br />Our Proprietary Products division aims to fulfil these unmet patient needs. In some cases, this involves developing a new dosage of a current drug. In other cases, it may involve developing new combinations of synergistic medications. The focus is on initiatives to improve patient experience with our products. Better experience results in better compliance, which means better health and outcomes for patients.<br /></div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6ed" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f1" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6f3" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" /> */}
                    </div>
                </div>
            </div>
            {/* <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                <div className="af-class-drag-progress-fill af-class-fifty" />
            </div> */}
        </div>
    )
}

export default ImageTextSlider;