import React,{useEffect,useState} from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

import { AnimeHeading } from '../../../../common/AnimeHeading';

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top: 40px;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const DropDownDiv = styled.div`
  width:30%;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
    flex-direction: column;
`;

const Content = styled(Link)`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    text-decoration:underline !important;
    margin-bottom: 15px;
  }
`;

const scripts = [
  // { loading: fetch("webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
  // { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  // { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

export const QuarterlyReports = (props) => {

  // React.useEffect(() => {
  //   const htmlEl = document.querySelector('html')
  //   htmlEl.dataset['wfPage'] = '60952b8da0e26580fc1fde12'
  //   htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

  //   scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
  //     const loading = active.loading.then((script) => {
  //       new Function(`
  //         with (this) {
  //           eval(arguments[0])
  //         }
  //       `).call(window, script)
  //       return next
  //     })
  //     // if (active.lastOne) {
  //     //     this.setState({ renderPageBool: true })
  //     // }
  //     return active.isAsync ? next : loading
  //   }))
  // });

  const [Quarterlyreports, setQuarterlyreports] = useState({});
  const [DropdowndataQuarterly, setDropdowndataQuarterly] = useState([]);
  const [SelectedQuarterlyDate, setSelectedQuarterlyDate] = useState("");

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempArr = [];
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/quarterly_reports?include=field_file_quarterly",
    })
      .then((res) => {
        console.log(res);
        for (const item of res.data.included) {
          tempIncludedObj[item.id] =
            config.serverUrl.imageBase + item.attributes.uri.url;
        }
        for (const report of res.data.data) {
          tempArr = [];
          if (
            tempDropdownData.indexOf(report.attributes.field_year_quaterly) ===
            -1
          ) {
            tempDropdownData.push(report.attributes.field_year_quaterly);
          }
          tempDropdownData = tempDropdownData.sort().reverse();
          if (tempReports?.[report.attributes.field_year_quaterly]) {
            tempArr = tempReports[report.attributes.field_year_quaterly];
            tempArr.push({
              title: report.attributes.title,
              url: tempIncludedObj[
                report.relationships.field_file_quarterly.data.id
              ],
            });
            tempReports[report.attributes.field_year_quaterly] = tempArr;
          } else {
            console.log(
              report,
              "-==-=-==-",
              (tempIncludedObj,
                report.relationships.field_file_quarterly.data.id)
            );
            tempArr = [];
            tempArr.push({
              title: report.attributes.title,
              url: tempIncludedObj[
                report.relationships.field_file_quarterly.data.id
              ],
            });
            tempReports[report.attributes.field_year_quaterly] = tempArr;
          }
        }
        if (tempDropdownData && tempDropdownData.length) {
          setSelectedQuarterlyDate(tempDropdownData[0]);
        }
        console.log(tempDropdownData);
        console.log(tempReports);
        setDropdowndataQuarterly(tempDropdownData);
        setQuarterlyreports(tempReports);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  const minWidthBool = useMediaQuery({ query: "(min-device-width: 900px)" });

  return <SubContainer>

    <TextContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          align: "Left",
        }}
      >
        {Quarterlyreports?.[SelectedQuarterlyDate] &&
          Quarterlyreports?.[SelectedQuarterlyDate].map((item, index) => {
            return (
              <TextContent
                key={SelectedQuarterlyDate + index}
                // onClick={() => {
                //   downloadFile(item.url);
                // }}
                to={{ pathname: item.url }}
                target="_blank"
              >
                {item.title}
              </TextContent>
            );
          })}
        {/* <AnimeHeading style={{alignSelf: 'start',marginBottom:'20px'}} >
              </AnimeHeading> */}
      </div>
    </TextContainer>

    <DropDownDiv>
      <Cdropdown
        data={DropdowndataQuarterly}
        placeholder="Year"
        id="Quarterlyreports"
        selectedValue={SelectedQuarterlyDate}
        onChangeValue={setSelectedQuarterlyDate} />
    </DropDownDiv>

  </SubContainer>
}