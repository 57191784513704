import React, { useEffect, useRef } from 'react'

const DeliveringHealth = (props) => {

  const stickySection = useRef(null);
  const scrollTop = useRef(null);

  var visible = false;

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([e]) => {
  //       visible = e.isIntersecting;
  //     }
  //   );

  //   if (stickySection.current) {
  //     observer.observe(stickySection.current);
  //   }
  //   //Commented the following because while navigating getting issue
  //   // return () => observer.unobserve(stickySection.current);
  // }, [stickySection]);

  // useEffect(() => {
  //   let lastScroll = 0;
  //   const handleScroll = () => {
  //     let st = window.pageYOffset || document.documentElement.scrollTop;

  //     if (visible === true) {
  //       if (st < lastScroll) {
  //         visible = false;
  //         if (scrollTop.current) {
  //           scrollTop.current.scrollIntoView({ behavior: 'auto', block: 'start' });
  //         }
  //       }
  //     }
  //     lastScroll = st <= 0 ? 0 : st;
  //   }

  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  return (
    <>
      <div id="delivering-health" className="af-class-section af-class-delivering-health" style={{ backgroundColor: '#ededed', zIndex: 999 }}  >
        <div className="af-class-container w-container">
          <div className="af-class-delivering-health-wrapper">
            <div className="af-class-scroll-video-block">
              <div data-poster-url={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-poster-00001.jpg"} data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-delivering-health-video w-background-video w-background-video-atom"><video autoPlay loop style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/videos/Delivering-good-health-section-GIFs-poster-00001.jpg)' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                <source src={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-transcode.mp4"} data-wf-ignore="true" />
                <source src={process.env.PUBLIC_URL + "/videos/Delivering-good-health-section-GIFs-transcode.webm"} data-wf-ignore="true" />
              </video>
                <div data-w-id="0e8e8995-b7c2-b906-d561-acfb2ab0414d" className="af-class-stickey-video-text-overlay">
                  <h2 className="af-class-h2-section-heading af-class-title af-class-left" style={{fontSize:window.matchMedia('(max-width: 820px)').matches?'36px':'60px'}} >Delivering good health around the world</h2>
                </div>
              </div>
            </div>
            <div className="delivering-health-top" ref={scrollTop}></div>
            <div className="af-class-scroll-text-wrapper">
              <div className="w-dyn-list">
                <div role="list" className="w-dyn-items">
                  {
                    props?.data && props.data.map((item, index) => {
                      return <div key={"DeliveringHealth" + index} role="listitem" className="w-dyn-item">
                        <div className="af-class-scroll-text-block">
                          <div className="af-class-scroll-text-div">
                            <div className="af-class-scroll-animation-box">
                              <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">{item.title}</h2>
                              <p className="af-class-paragraph" dangerouslySetInnerHTML={{ __html: item.description }} />
                              {/* <a href="#" className="af-class-link-arrow af-class-left-side w-inline-block">
                              <div className="af-class-link-circle af-class-purple" style={{ width: "0px", height: "0px" }} />
                              <img src="../../../public/images/purple-right-arrow.svg" loading="lazy" alt="" className="af-class-black-arrow" />
                              <img src="../../../public/images/white-arrow_right.svg" loading="lazy" alt="" className="af-class-white-arrow" />
                            </a> */}
                              <a
                                href={`/articles?${item.contentId}#${item.id}`}
                                onClick={() => {
                                  sessionStorage.setItem("navPath", JSON.stringify({ id: "/who-we-are#delivering-health" }))
                                  // props.history.push(`/articles?${item.contentId}#${item.id}`);
                                }}
                                // href={"/articles"}
                                //   onClick={() => {
                                //     localStorage.setItem("data", JSON.stringify(item))
                                //   }} 
                                className="af-class-link-arrow af-class-left-side w-inline-block">
                                <div className="af-class-link-circle af-class-purple" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>
              <div className="af-class-drag-slider-wrapper af-class-mobile-only" style={{paddingBottom:'0px'}} >
                <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                  <div className="af-class-mask af-class-full w-slider-mask">
                    {
                      props?.data && props.data.map((item, index) => {
                        return <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b1c" className="af-class-drag-slider-item af-class-full w-slide">
                          <a 
                          href={`/articles?${item.contentId}#${item.id}`}
                            onClick={() => {
                              localStorage.setItem("data", JSON.stringify(item))
                            }} className="af-class-slide-div w-inline-block">
                            <div className="af-class-delivering-health-slider-heading" style={{fontSize:'24px'}} >{item.title}</div>
                            <div style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html: item.description }} />
                          </a>
                        </div>
                      })
                    }
                    {/* <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b1c" className="af-class-drag-slider-item af-class-full w-slide">
                      <a href="#" className="af-class-slide-div w-inline-block">
                        <div className="af-class-delivering-health-slider-heading">Braving a snowstorm with passion and persistence</div>
                        <div>A surprise winter storm brought the southern states of the US to a standstill, blocking access to key medicines. But we did not let that get in the way of keeping our promise.</div>
                      </a>
                    </div>
                    <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b24" className="af-class-drag-slider-item af-class-full w-slide">
                      <div>
                        <div className="af-class-delivering-health-slider-heading">Attention to detail is mission critical especially when fighting a pandemic</div>
                        <div>The pandemic had taken millions of lives globally, but research pointed to a few key drugs that could mitigate symptoms. However, getting a good quality product available to everyone in Indonesia in double-quick time required attention to detail and teamwork.</div>
                      </div>
                    </div>
                    <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b2c" className="af-class-drag-slider-item af-class-full w-slide">
                      <div>
                        <div className="af-class-delivering-health-slider-heading">Providing guidance and support on the journey to good health</div>
                        <div>Our German subsidiary betapharm has been distributing educational patient guidebooks under its betaCare initiative for almost two decades.</div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b44" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt="" className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt="" className="af-class-black-arrow" />
                  </div>
                  <div data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b48" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a2a3a560-3238-4fbc-3318-7b96ddc93b4a" alt="" className="af-class-white-arrow" /><img src="/images/chevron-right.svg" loading="lazy" alt="" className="af-class-black-arrow" />
                  </div>
                  <div className="af-class-slide-nav w-slider-nav w-round" /> */}
                </div>
              </div>
              {/* <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                <div className="af-class-drag-progress-fill af-class-_3-slides" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div ref={stickySection}></div>
    </>
  );
}

export default DeliveringHealth;



