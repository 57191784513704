import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import  { useEffect,useState } from 'react';
import BackgroundImg from "../../../../Common/images/contactUs1.png"
// import BackgroundImg from "../../Common/images/contactUs1.png";

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
//   width: 105vw;
  height: 50vh;
  display: flex;
  flex-direction: row;
//   margin-inline: auto;
  background-image: url(${BackgroundImg});
  background-size: cover;
  //   background-repeat: no-repeat;
  background-position: center;
  background-color: #282828;
  background-blend-mode: Multiply;
//    display: flex;
  margin-top: 150px;
  // margin-bottom: 10px;
  @media (max-width: 900px) {
    height: 400px;
    height: 35vh;
    margin-top: 60px;
  }
`;

const TitleContainer = styled.div`
  //   background-color: #fff;
//    margin-inline: auto;
  display: flex;
  flex-direction : row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100vw;
`;

const Title = styled.h2`
  font-size: ${({ isBigScreen, isMobile }) => {
    if (isBigScreen) {
      return "55px !important";
    }
    return isMobile ? "30px" : "45px";
  }};
  color: #ffffff !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
`;

export function HeroSection(props) {
    const [selectedFilterReports, setSelectedFilterReports] = useState("");
console.log(props)
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1000px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  return (
    <MainContainer>
      <TitleContainer>
        <Title isBigScreen={isBigScreen} isMobile={isMobile}>
         {/* BIOMEDICAL WASTE MANAGEMENT REPORT */}
         {props.state.title}
        </Title>
        
      </TitleContainer>
    </MainContainer>
  );
}
