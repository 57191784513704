import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import { HR } from "../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../config";
import { downloadFile } from "../../../../Utils/Utils";

import { Presentations } from './SubModules/Presentations';
import { EarningsCalls } from './SubModules/EarningsCalls';
import { InvestorMeetingDetails } from './SubModules/InvestorMeetingDetails';
import { InvestorDay } from './SubModules/InvestorDay';

const Main = styled.div`
  width: 100%;
  min-height: 50vh;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row; 
  @media (max-width: 1030px) {
    flex-direction: column;
`;

const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 1030px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
const TextContent = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-left: 50px;
  margin-bottom: 20px;
  @media (max-width: 1030px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px;
  flex-direction: row;
`;

const ReportItem = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1030px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

export function PresentationsView(props) {

    const minWidthBool = useMediaQuery({ query: "(min-device-width: 1030px)" });

    const renderSubMenuContent = () => {
        switch (props.state.selectedSubTab.id) {
            case 1:
                return <EarningsCalls state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 2:
                return <Presentations state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 3:
                return <InvestorMeetingDetails state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 4:
                return <InvestorDay state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            default:
                return <p>{props.state.selectedSubTab.name}</p>;
        }
    }

    return (
        <Main>

            {renderSubMenuContent()}

        </Main>
    );
}
