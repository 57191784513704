import React from 'react';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useMediaQuery } from 'react-responsive';

import { isTab } from '../../Utils/Utils';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

const data = [
    {
        "id": "slider_1",
        "img": "/images/Chrysalis.png",
        "heading": "Chrysalis",
        "desc": "Our flagship leadership programme for women in mid-management, Chrysalis upskills high-potential employees for senior roles. Each batch goes through rigorous training for nine months, including group projects, soft skills enhancement, real-time simulation exercises, mentoring by senior leaders and sessions on self-awareness and personal effectiveness."
    },
    {
        "id": "slider_2",
        "img": "/images/YLP.png",
        "heading": "Young Leadership Programme",
        "desc": "One of our best-known courses, the Young Leaders Programme (YLP) grooms fresh hires from India’s top business schools and engineering colleges. It enables us to develop a talent pool capable of taking up general management roles through stint-based rotations in multiple business units. The YLP is also open to our employees under the Internal Young Leadership Programme."
    },
    {
        "id": "slider_3",
        "img": "/images/_DSC2209-Edit.png",
        "heading": "Fantastic Five",
        "desc": "In an effort to balance the gender ratio of women in manufacturing, Dr. Reddy’s Biologics division runs the Fantastic Five programme to groom potential pathbreakers for lead roles. Over the course of a year, five women employees undergo technical and behavioural training so they are equipped to handle shift operations, compliance and documentation, inspections, investigations and people management."
    },
    {
        "id": "slider_4",
        "img": "/images/bram-naus-oqnVnI5ixHg-unsplash.png",
        "heading": "Assurance Leadership Programme",
        "desc": "This year-long programme has been designed to build employee competency in risk management, corporate governance, compliance,data privacy and cybersecurity. Inductees train via rotational stints in the internal audit, risk and information security departments."
    },
    {
        "id": "slider_5",
        "img": "/images/NHWP.png",
        "heading": "New Horizons Programmes",
        "desc": "The 11-month New Horizons Leadership Programme prepares candidates to lead teams and businesses, while The New Horizons Management Programme offers customised all-round coaching through classes and digital sessions for seven months."
    },
    {
        "id": "slider_6",
        "img": "/images/krakenimages-376KN_ISplE-unsplash-copy.png",
        "heading": "Surge",
        "desc": "An intensive year-long module, Surge is focused on building business development capabilities across geographies. Led by internal and external experts, it includes key aspects such as negotiations, relationship building and business intelligence."
    },
    {
        "id": "slider_7",
        "img": "/images/jason-goodman-IQY_q-RqaIo-unsplash.png",
        "heading": "Soar",
        "desc": "Designed for country heads and potential general managers, Soar is a year-long programme that trains candidates in strategic leadership and marketing, and ways to accelerate business. Assessments and high-impact projects are assigned per the course framework."
    },
    {
        "id": "slider_8",
        "img": "/images/muhammad-faiz-zulkeflee-alw-CwGFmwQ-unsplash.png",
        "heading": "KAM",
        "desc": "This nine-month programme targets capability building for key account managers. It offers insights into customer centricity, financial and service orientation, cross-functionality and creating influence."
    },
    {
        "id": "slider_9",
        "img": "/images/jason-goodman-4-iZ147pSAE-unsplash.png",
        "heading": "I-Develop",
        "desc": "With I-Develop, HR business partners undergo a year-long training for capability building across functions. They’re helped by external experts to develop expertise in various fields, from data analytics to talent development, career planning and employee experience."
    },
    {
        "id": "slider_10",
        "img": "/images/docusign-BbSBf5uv50A-unsplash.png",
        "heading": "Digital Capability Building Programmes",
        "desc": "As a future-forward company, enabling our employees to be digital savvy is one of our priorities. This aligns with our digitisation campaign to build a business that will flourish for decades. Our courses are customised to the needs of different units, from manufacturing to finance and marketing."
    },
]
    ;

const EmploymentEnrichmentProgrammesSlider = ({ }) => {
    const [items, setItems] = React.useState(data);
    // const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const [readMore, setReadMore] = React.useState([]);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });


    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const renderText = (id, text, limit) => {
        if (text) {
            return (text.length > limit) || readMore.indexOf(id) !== -1 ? text.substr(0, limit - 1) + '...' : text;
        }
    }

    const Card = ({ onClick, selected, item, itemId, index, setVisisbleCard }) => {
        const visibility = React.useContext(VisibilityContext);

        if (!!visibility.isItemVisible(itemId)) {
            setVisisbleCard(index);
        }

        var title = renderText(item.id, item.desc, 80);

        return (
            <div
                style={{
                    width: isTab?'36vw':'70vw',
                    marginRight: data.length - 1 === index ? isTab ? '10vw' : '20px' : '0px',
                    marginLeft: index === 0 ? isTab ? '10vw' : '20px' : '25px',
                    marginBottom: '20px',
                }}
            >
                <div style={{ overflow: 'hidden', width: '100%', height: '200px', marginBottom: '12px' }} >
                    <img src={item.img} loading="lazy" alt style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div style={{ marginBottom: '10px', fontWeight: 700, color: '#333', textAlign: 'left', fontSize: '16px',height:'50px' }} >{item.heading}</div>
                {
                    readMore.indexOf(item.id) !== -1
                        ?
                        <div style={{ marginBottom: '10px', fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {item.desc}
                        </div>
                        :
                        <div style={{ fontWeight: 300, color: '#333', textAlign: 'left', fontSize: '16px' }} >
                            {title}
                            {title.includes('...') && <span style={{ color: '#5225B5', fontSize: '16px', fontWeight: 'bold' }} onClick={() => {
                                console.log(readMore)
                                setReadMore([...readMore, item.id])
                            }} >Read More</span>}
                        </div>
                }
            </div>
        )

        // return (
        //     <div
        //         onClick={() => onClick(visibility)}
        //         style={{
        //             width: '70vw',
        //             marginRight: '25px'
        //         }}
        //         tabIndex={0}
        //     >
        //         <div className="card">
        //             <div>{title}</div>
        //             <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //             <div>selected: {JSON.stringify(!!selected)}</div>
        //         </div>
        //         <div
        //             style={{
        //                 height: '200px',
        //             }}
        //         />
        //     </div>
        // );
    }

    return (

        isMobile && data.length !== 0 && <>
            <div style={{ borderTop: '1px solid #ff5046' }} >
                <div style={{ marginTop: '24px', marginLeft:isTab ? '-10vw' : '-20px', marginRight: isTab ? '-10vw' : '-20px', width: isTab ? 'calc(100% + 10vw + 10vw)' :'calc(100% + 20px + 20px)' }} >
                    <ScrollMenu>
                        {data.map((item, index) => (
                            <Card
                                itemId={item.id} // NOTE: itemId is required for track items
                                item={item}
                                key={item.id}
                                index={index}
                                onClick={handleClick(item.id)}
                                selected={isItemSelected(item.id)}
                                setVisisbleCard={(index) => {
                                    setPosition(index)
                                }}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
            <div style={{ display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '30px' }} >
            {
                isTab
                ?
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((position-1) * (100 / (data.length-1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (data.length-1)) + "%" }} />
                :
                <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: (position * (100 / data.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / data.length) + "%" }} />
            }
            </div>
        </>
    );
}

export default EmploymentEnrichmentProgrammesSlider;
