import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { FiChevronDown } from "react-icons/fi";
import { Dropdown } from "../../common/dropdown";
import Cdropdown from "../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";
import { HR } from "../../common/horizontalLine";
import axios from "axios";
import config from "../../../config";
import { downloadFile } from "../../../Utils/Utils";
import { TextSlideAnimeButton } from '../../common/TextSlideAnimeButton';

import { animateScroll as scroll } from 'react-scroll';

const Main = styled.div`
  width: 100%;
  min-height: 50vh;
`;

const MainContainer = styled.div`
  margin-top: 40px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width:30%;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom:30px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column; 
`;

const TextHeadingFirst = styled.h5`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  margin-top:0px !important;
  margin-bottom:50px !important;
  @media (max-width: 900px) {
    font-size: 24px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  `;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  margin-top:80px !important;
  margin-bottom:50px !important;
  @media (max-width: 900px) {
    font-size: 24px !important;
    width: 100% !important;
    margin:0 !important;
    margin-bottom: 20px !important;
  }
`;

const TextHeadingBold = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-bottom:32px !important;
  font-family: Drlcircular;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px;
  flex-direction: row;
`;

const ReportItem = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px!important;
  color: #000000!important;
  font-weight: 300!important;
  font-family: Drlcircular!important;
  line-height: 30px!important;
  margin:0 !important;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    line-height: 24px !important ;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

export const Presentations = (props) => {

  const [acceptedBool, setAcceptedBool] = useState((localStorage.getItem("acceptancePolicies") && JSON.parse(localStorage.getItem("acceptancePolicies")))?.presentations ? true : false);

  const [reports, setReports] = useState({});
    const [dropdownData, setDropdowndata] = useState([]);
    const [selectedFilterDate, setSelectedFilterDate] = useState("");

    useEffect(() => {
      var tempIncludedObj = {};
      var tempReports = {};
      var tempDropdownData = [];
      var tempObj = {};
      var tempArr = [];
      var tempProperty = "";
      axios({
          method: "get",
          url:
              config.serverUrl.base +
              "/jsonapi/node/presentationsreports?include=field_file",
      })
          .then((res) => {
              console.log(res);
              for (const item of res.data.included) {
                  console.log(item);
                  tempIncludedObj[item.id] =
                      config.serverUrl.imageBase + item.attributes.uri.url;
              }
              for (const report of res.data.data) {
                  tempProperty = report.attributes.field_presentationtypes;
                  if (tempProperty === "Press Meet") {
                      tempObj = {};
                      tempArr = [];
                      if (
                          tempDropdownData.indexOf(
                              report.attributes.field_presentationsfiscalyear
                          ) === -1
                      ) {
                          tempDropdownData.push(
                              report.attributes.field_presentationsfiscalyear
                          );
                      }
                      tempDropdownData = tempDropdownData.sort().reverse();
                      if (
                          tempReports?.[report.attributes.field_presentationsfiscalyear] &&
                          tempReports[report.attributes.field_presentationsfiscalyear]?.[
                          tempProperty
                          ]
                      ) {
                          tempArr =
                              tempReports[report.attributes.field_presentationsfiscalyear]?.[
                              tempProperty
                              ];
                          tempArr.push({
                              title: report.attributes.title,
                              url: tempIncludedObj[report.relationships.field_file.data.id],
                          });
                          tempObj[tempProperty] = tempArr;
                          tempReports[report.attributes.field_presentationsfiscalyear][
                              tempProperty
                          ] = tempArr;
                      } else {
                          tempArr = [];
                          tempArr.push({
                              title: report.attributes.title,
                              url: tempIncludedObj[report.relationships.field_file.data.id],
                          });
                          tempObj[tempProperty] = tempArr;
                          if (
                              tempReports?.[report.attributes.field_presentationsfiscalyear]
                          ) {
                              tempReports[report.attributes.field_presentationsfiscalyear][
                                  tempProperty
                              ] = tempArr;
                          } else {
                              tempReports[report.attributes.field_presentationsfiscalyear] =
                                  tempObj;
                          }
                      }
                  }
              }
              if (tempDropdownData && tempDropdownData.length) {
                  setSelectedFilterDate(tempDropdownData[0]);
              }
              console.log(tempDropdownData);
              console.log(tempReports, "  FINAL   ");
              setDropdowndata(tempDropdownData);
              setReports(tempReports);
          })
          .catch((err) => {
              console.log(err, "err");
          });
  }, []);

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  return (
    <Main>
      <MainContainer>
        {
          acceptedBool
            ?
            <div
              style={{
                display: "flex",
                flexDirection: minWidthBool ? 'column-reverse' : "row",
                alignItems: "flex-start",
              }}
            >
              <div style={{ width: '100%' }} >

                <TextContainer>
                  {/* {reports?.[selectedFilterDate]?.["Press Meet"] &&
                    reports?.[selectedFilterDate]?.["Press Meet"].length !== 0 && <TextHeadingFirst>Press Meet</TextHeadingFirst>} */}
                  <Content>
                    {reports?.[selectedFilterDate]?.["Press Meet"] &&
                      reports?.[selectedFilterDate]?.["Press Meet"].map(
                        (item, index) => {
                          return (
                            <TextContent
                            to={{ pathname: item.url }}
                            target="_blank"
                              key={selectedFilterDate + index}
                              /* onClick={() => {
                                downloadFile(item.url);
                              }} */
                            >
                              {item.title}
                            </TextContent>
                          );
                        }
                      )}
                  </Content>
                </TextContainer>
                <TextContainer>
                  {reports?.[selectedFilterDate]?.["INVESTOR DECK"] &&
                    reports?.[selectedFilterDate]?.["INVESTOR DECK"].length !== 0 && <TextHeading>Investor Deck</TextHeading>}
                  <Content>
                    {reports?.[selectedFilterDate]?.["INVESTOR DECK"] &&
                      reports?.[selectedFilterDate]?.["INVESTOR DECK"].map(
                        (item, index) => {
                          return (
                            <TextContent
                            to={{ pathname: item.url }}
                            target="_blank"
                              key={selectedFilterDate + index}
                              /* onClick={() => {
                                downloadFile(item.url);
                              }} */
                            >
                              {item.title}
                            </TextContent>
                          );
                        }
                      )}
                  </Content>
                </TextContainer>
                <TextContainer>
                  {reports?.[selectedFilterDate]?.["INVESTOR DAY"] && reports?.[selectedFilterDate]?.["INVESTOR DAY"].length !== 0 && <TextHeading>Investor Day</TextHeading>}
                  <Content>
                    {reports?.[selectedFilterDate]?.["INVESTOR DAY"] &&
                      reports?.[selectedFilterDate]?.["INVESTOR DAY"].map(
                        (item, index) => {
                          return (
                            <TextContent
                            to={{ pathname: item.url }}
                            target="_blank"
                              key={selectedFilterDate + index}
                              /* onClick={() => {
                                downloadFile(item.url);
                              }} */
                            >
                              {item.title}
                            </TextContent>
                          );
                        }
                      )}
                  </Content>
                </TextContainer>

              </div>
              <DropDownDiv>
                <Cdropdown
                  data={dropdownData}
                  placeholder="Fiscal Year"
                  id="reports"
                  selectedValue={selectedFilterDate}
                  onChangeValue={(val) => setSelectedFilterDate(val)}
                />
              </DropDownDiv>

            </div>
            :
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
              <div style={{ width: window.matchMedia('(max-width: 820px)').matches?'100%':'48%' }} >
                <TextHeadingBold>Safe Harbour</TextHeadingBold>
                <Text>
                  The documents contain forward-looking statements, as defined in the U.S. Private Securities Litigation Reform Act of 1995 . We have based these forward-looking statements on our current expectations and projections about future events.<br />
                </Text>
                <Text>
                  Such statements involve known and unknown risks, uncertainties and other factors that may cause actual result to differ materially. Such factors include, but are not limited to, changes in general economic conditions, our ability to successfully implement our strategy, the market acceptance of and demand for our products, our growth and expansion, technological change and our exposure to market risks.<br />
                </Text>
                <Text>
                  By their nature, these expectations and projections are only estimates and could be materially different from actual results in the future.<br />
                </Text>
                <Text>
                  This communication is not an offer to sell or the solicitation of an offer to buy securities
                  <br />
                  <br />
                  Click below if you accept these conditions
                </Text>
                <TextSlideAnimeButton onClick={() => {
                  scroll.scrollTo('main-container')
                  setAcceptedBool(true);
                  var ls = localStorage.getItem("acceptancePolicies");
                  ls = ls ? JSON.parse(ls) : {};
                  ls.presentations = true;
                  localStorage.setItem("acceptancePolicies", JSON.stringify(ls));
                }} />
              </div>
            </div>
        }
      </MainContainer>
    </Main>
  )
}