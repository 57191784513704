import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Img1 from '../../assets/covid1Mobile.jpg';
import Img2 from '../../assets/covid2Mobile.jpg';
import Img3 from '../../assets/covid3Mobile.jpg';

import { isTab } from '../../Utils/Utils';

gsap.registerPlugin(ScrollTrigger);

function PeopleSpeakNewMobile() {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        if (ScrollTrigger.getById('trigger3')) ScrollTrigger.getById('trigger3').kill();

        // ScrollTrigger.refresh();

        let tl2 = gsap.timeline({
            scrollTrigger: {
                id: "trigger3",
                trigger: "#healthcontainer2",
                start: "top",
                end: "+=2000",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        tl2.set("#img-HealthMobile-one", { opacity: 1 })
        tl2.set("#img-HealthMobile-one-two", { opacity: 0 })
               
        tl2.set('#img-HealthMobile-one-two', {opacity: 1}, "+=0.4"); 
        tl2.set('#img-HealthMobile-one-two', {opacity: 1}, "+=0.2"); 

        tl2.set("#img-HealthMobile-two", { opacity: 0 })        
        tl2.set('#img-HealthMobile-two', {opacity: 1}, "+=0.4"); 
        tl2.set('#img-HealthMobile-two', {opacity: 1}, "+=0.2"); 

        tl2.set("#img-HealthMobile-two-two", { opacity: 0 })               
        tl2.set('#img-HealthMobile-two-two', {opacity: 1}, "+=0.4"); 
        tl2.set('#img-HealthMobile-two-two', {opacity: 1}, "+=0.2"); 

        tl2.set("#img-HealthMobile-three", { opacity: 0 })
        tl2.set('#img-HealthMobile-three', {opacity: 1}, "+=0.4"); 
        tl2.set('#img-HealthMobile-three', {opacity: 1}, "+=0.2"); 

    }, [])

    return <div id="healthcontainer2" style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh' }} >
        <div id="health-container" style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
            <div id="img-HealthMobile-one" style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, left: 0, top: 0 }}  >
                <span id='text-HealthMobile-one' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                <div className="af-class-section" style={{backgroundColor: 'rgba(0,0,0,0.5)', color: 'white',height:'100%',display:'flex'}} >
                    <div className="af-class-container w-container" style={{alignSelf:'center'}} >
                        <h1 data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className='af-class-h1-article' style={{ marginLeft: '0px', marginBottom: '30px', marginTop: '0px' }} >People Speak</h1>
                            <div id="txt-health-one-one">
                                <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                        if(isTab){
                                        el.style.setProperty('font-size', '20px', 'important');
                                        el.style.setProperty('line-height', '36px', 'important');
                                        } else {
                                        el.style.setProperty('line-height', '28px', 'important');
                                        }
                                    }
                                }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                    Dasari had to travel in the second week of April on personal work, but by the time he reached Vizag, his sister’s family, wife and child were affected. Though asymptomatic, Dasari too got tested and was found to be Covid-19 positive. He immediately informed his superior and started medication at home under a doctor’s supervision. A few days later, he was taking a turn for the worse, but Dr. Reddy’s jumped in to get him admitted to a hospital within an hour.
                                    <br /><br />
                                    “The assurance and support I received at a time of need felt like I was a family member for all my superiors. It gave me confidence and helped me to recover soon.”
                                    <br /><br />
                                    <strong>Dasari Padmanabhan<br />Team Member – CFA Management</strong>
                                </p>
                        </div>
                    </div>
                </div>
                </span>
            </div>


            <div id="img-HealthMobile-one-two" style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, left: 0, top: 0 }}  >
                <span id='text-HealthMobile-one' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                <div className="af-class-section" style={{backgroundColor: 'rgba(0,0,0,0.5)', color: 'white',height:'100%',display:'flex'}} >
                    <div className="af-class-container w-container" style={{alignSelf:'center'}} >
                        {/* <h1 data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className='af-class-h1-article' style={{ marginLeft: '0px', marginBottom: '30px', marginTop: '0px' }} >People Speak</h1> */}
                            <div id="txt-health-one-two">
                                <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                        if(isTab){
                                        el.style.setProperty('font-size', '20px', 'important');
                                        el.style.setProperty('line-height', '36px', 'important');
                                        } else {
                                        el.style.setProperty('line-height', '28px', 'important');
                                        }
                                    }
                                }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                    Nilesh developed symptoms for Covid-19 around April 15, and he immediately got himself and his parents tested. They were all positive, but this was not all. Nilesh’s wife and daughter in Nagpur also came down with the disease and he began to panic. He contacted the helpline and got immediate help, including a prescription and medicines. The help team assured him to contact them anytime, irrespective of day or night. Now, Nilesh’s family has recovered fully, and he is almost there.
                                    <br /><br />
                                    “It was a very stressful period, but the team became like an extended family for us. They checked up on me and my family regularly and provided treatment support as well as mental encouragement that helped me to overcome my panic.”
                                    <br /><br />
                                    <strong>Nilesh Vishwas Baraskar<br />Therapy Lead – Marketing CoE</strong>
                                </p>
                        </div>
                    </div>
                </div>
                </span>
            </div>



            <div id="img-HealthMobile-two" style={{ opacity: 0, backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, left: 0, top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                <span id='text-HealthMobile-two' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                <div className="af-class-section" style={{backgroundColor: 'rgba(0,0,0,0.5)' , color: 'white',height:'100%',display:'flex'}} >
                    <div className="af-class-container w-container" style={{alignSelf:'center'}} >
                            <div id="txt-health-two-one">
                                <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                        if(isTab){
                                        el.style.setProperty('font-size', '20px', 'important');
                                        el.style.setProperty('line-height', '36px', 'important');
                                        } else {
                                        el.style.setProperty('line-height', '28px', 'important');
                                        }
                                    }
                                }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                    Ashish’s 76-year-old father was admitted to a hospital in Indore on May 1 when his condition deteriorated. He needed Remdesivir, but the situation was critical – for the 50 patients in the hospital who needed the injection, only seven vials were provided. At this time, Ashish reached out through the Dr. Reddy’s employee helpline. Within 12 hours of providing all necessary documentation, Ashish received all the approvals. Several people jumped into action and the local CFA in Madhya Pradesh was cleared to provide Ashish with Remdesivir. His father is now recovering well.
                                    <br /><br />
                                    “I see a lot of people struggling to even get an oxygen bed in Madhya Pradesh, and I feel lucky and proud to be working with an organisation like Dr. Reddy’s that supported me in a situation like this.”
                                    <br /><br />
                                    <strong>Ashish Maheshwari <br />Area Business Manager – KAM HQ Indore</strong>
                                </p>                         
                        </div>
                    </div>
                </div>
                </span>
            </div>


            <div id="img-HealthMobile-two-two" style={{ opacity: 0, backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, left: 0, top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                <span id='text-HealthMobile-two-two' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                <div className="af-class-section" style={{backgroundColor: 'rgba(0,0,0,0.5)' , color: 'white',height:'100%',display:'flex'}} >
                    <div className="af-class-container w-container" style={{alignSelf:'center'}} >
                            <div id="txt-health-two-two">
                                <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                        if(isTab){
                                        el.style.setProperty('font-size', '20px', 'important');
                                        el.style.setProperty('line-height', '36px', 'important');
                                        } else {
                                        el.style.setProperty('line-height', '28px', 'important');
                                        }
                                    }
                                }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px',opacity: 0 }} >
                                    When Srinivas came down with Covid-19 recently, he became extremely stressed. He has two children, both under 10 years, and the intensity of the second wave led to panic. He sent his wife and children away to their hometown and isolated himself, but his landlord, a senior citizen, out of fear asked Srinivas to isolate outside the building premises. Unsure of what to do, he turned to his superiors who recommended he stay at a company-designated isolation centre. Srinivas’s treatment progressed smoothly and he has now recovered.
                                    <br /><br />
                                    “The team checked up on me every day, gave me priceless moral support and encouraged me to stay safe. All necessary assistance was provided to me and it’s clear that I recovered only because of Dr. Reddy’s support.”
                                    <br /><br />
                                    <strong>Srinivas More<br />Area Sales Manager, GG India, Novus Division</strong>
                                </p>
                        </div>
                    </div>
                </div>
                </span>
            </div>

            <div id="img-HealthMobile-three" style={{ opacity: 0, backgroundImage: `url(${Img3})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, left: 0, top: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                <span id='text-HealthMobile-three' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
               <div className="af-class-section" style={{backgroundColor: 'rgba(0,0,0,0.5)', color: 'white',height:'100%',display:'flex'}} >
                        <div className="af-class-container w-container" style={{alignSelf:'center'}} >
                                <div id="txt-health-three-one">
                                    <p ref={(el) => {
                                    if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                        if(isTab){
                                        el.style.setProperty('font-size', '20px', 'important');
                                        el.style.setProperty('line-height', '36px', 'important');
                                        } else {
                                        el.style.setProperty('line-height', '28px', 'important');
                                        }
                                    }
                                }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" className="af-class-paragraph af-class-_572" style={{ fontSize: '16px' }} >
                                        On April 30, Kalyan received a call from Naveen, a friend and alumnus of Dr. Reddy’s, with an urgent request for Remdesivir for his father-in-law. Naveen had brought his father-in-law, already on oxygen support, from Sathupalli to Hyderabad in the hope of getting him treated but Remdesivir was proving to be impossible to source. Kalyan immediately reached out for help and Anand Garg, Head of Supply Chain, and VK Ashok, Head of Marketing, ensured that Naveen got the medicine by the next day. His father-in-law is now out of danger.
                                        <br /><br />
                                        “They have been thanking me, but it is my responsibility to convey this gratitude to the team. With their help, we were able to save a life.”
                                        <br /><br />
                                        <strong>NCh Kalyan Narasimham <br />Sourcing Specialist, Supply Chain Management</strong>
                                    </p>
                                </div>
                        </div>
            </div>
            </span>
        </div>
    </div>
</div>

}

export default PeopleSpeakNewMobile;
