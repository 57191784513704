import axios from 'axios';
import _ from 'underscore';
import config from '../config';
import { newsArticleContentNames, nodeNamesToContentIds, FileContentMachineNamesCatalog } from './DrupalHelper';

export function minimizeString(str, n) {
      if (str) {
            return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
      }
};

export function makeHashKeyFromName(name) {
      return name.trim().toLowerCase().split(" ").join("_");
}

export function arrangeArrObjInAlphabeticalOrder(array, key) {
      if (!!array && array.length) {
          return array.sort(function (a, b) {
              var nameA = a[key].toLowerCase(), nameB = b[key].toLowerCase();
              if (nameA < nameB) //sort string ascending
                  return -1;
              if (nameA > nameB)
                  return 1;
              return 0; //default return value (no sorting)
          });
      }
      return [];
  }

export function downloadFile(src) {
      if (src && src.length) {
            var fileName = src.split("/")[src.split("/").length - 1];
            console.log(fileName, "-=-==-=-")
            console.log(src)
            axios({
                  url: src,
                  method: 'GET',
                  responseType: 'blob'
            })
                  .then((response) => {
                        const url = window.URL
                              .createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        console.log(url);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                  })
      }
}

// ref:https://stackoverflow.com/questions/6906108/in-javascript-how-can-i-dynamically-get-a-nested-property-of-an-object
export function getNestedObjValue(obj, path) {
      if (!path) return obj;
      const properties = path.split('.');
      var temp = properties.shift();
      return getNestedObjValue(temp && temp.includes('[') ? obj[temp.split("[")[0]][0] : obj[temp] && obj.hasOwnProperty(temp) ? obj[temp] : '', properties.join('.'))
}

export async function getNewsArticleEndPoint(hash, search) {
      var contentTypeId = search.substring(1);
      var dataId = hash.substring(1);
      var apiData = newsArticleContentNames[contentTypeId];
      apiData.url = `/jsonapi/node/${apiData.name}/${dataId}${apiData.addOnToUrl}`;
      return apiData;
}

export function getMakeUpData(obj, makeUpArr) {
      var tempResult = {};
      for (let i of makeUpArr) {
            tempResult[i.key] = getNestedObjValue(obj, i.value);
      }
      return tempResult;
}

export async function getNewsResultsUrl(item) {
      if (nodeNamesToContentIds.hasOwnProperty(item.type)) {
            var temp = nodeNamesToContentIds[item.type];
            window.location.href = temp.navPath + "?" + temp.id + "#" + item.id;
      } else {
            let keysList = Object.keys(item.relationships);
            const unwantedKeys = ["node_type", "revision_uid", "uid"];
            let finalFilteredKeys = keysList.filter((item) => { return unwantedKeys.indexOf(item) === -1 })
            if (finalFilteredKeys.length !== 0) {
                  if (finalFilteredKeys.length === 1) {
                        if (FileContentMachineNamesCatalog.indexOf(finalFilteredKeys[0]) !== -1) {
                              let fileEndpoint = item.relationships[finalFilteredKeys[0]].links.related.href;
                              console.log(item.relationships[finalFilteredKeys[0]].links.related.href)
                              axios.get(fileEndpoint.replace(config.serverUrl.publicBlockedPrefix, config.serverUrl.imageBase))
                                    .then((res) => {
                                          console.log(res);
                                          // return config.serverUrl.imageBase + res.data.attributes.uri.url;
                                          window.open(config.serverUrl.imageBase + res.data.data.attributes.uri.url, '_blank')
                                    })
                                    .catch((err) => {

                                    })
                        }
                  } else {
                        for (let key of finalFilteredKeys) {
                              if (FileContentMachineNamesCatalog.indexOf(key) !== -1) {
                                    let fileEndpoint = item.relationships[key].links.related.href;
                                    console.log(item.relationships[key].links.related.href)
                                    axios.get(fileEndpoint.replace(config.serverUrl.publicBlockedPrefix, config.serverUrl.imageBase))
                                          .then((res) => {
                                                console.log(res);
                                                window.open(config.serverUrl.imageBase + res.data.data.attributes.uri.url, '_blank')
                                                // return config.serverUrl.imageBase + res.data.attributes.uri.url;
                                          })
                                          .catch((err) => {

                                          })
                              }
                        }
                  }
            }
      }
      // return { url: '', newTabBool: true, noActionBool: true };
}

export function makeSearchResultsForCategories(results) {
      const newsContentTypes = ["node--landing_page_news", "node--covid_news"];
      var tempPages = [];
      var tempNews = [];
      for (var result of results) {
            if (newsContentTypes.indexOf(result.type) === -1) {
                  tempPages.push(result);
            } else {
                  tempNews.push(result);
            }
      }
      return { pages: [...tempPages], news: [...tempNews] };
}

export function SortMonthNames(data) {
      var monthNames = {
            "january": 1,
            "february": 2,
            "march": 3,
            "april": 4,
            "may": 5,
            "june": 6,
            "july": 7,
            "august": 8,
            "september": 9,
            "october": 10,
            "november": 11,
            "december": 12
      };
      var temp = data.sort(function (a, b) {
            // sort based on the value in the monthNames object
            return monthNames[(a).toLocaleLowerCase()] - monthNames[(b).toLocaleLowerCase()];
      });
      return temp;
}

export function isValidEmail(email) {
      let response = {};
      if (email) {
            email = email.trim();
            const tempVal = /^\S+@\S+\.\S+/.test(email)
            if (tempVal) {
                  response.status = true;
                  response.message = email.toLowerCase().trim();
            } else {
                  response.status = false;
                  response.message = 'Please Enter Valid Email Id';
            }
      } else {
            response.status = false;
            response.message = 'Please Enter Email Id';
      }
      return response;
};

export function isValidIndianMobile(phoneNumber, valueType) {
      if (!valueType) {
            valueType = 'Mobile Number';
      }
      let response = {};
      if (phoneNumber) {
            phoneNumber = phoneNumber.trim();
            if (/^[1-9][0-9]*$/.test(Number(phoneNumber))) {
                  if (phoneNumber.toString().length === 10) {
                        response.status = true;
                        response.message = phoneNumber.trim();
                  } else {
                        response.status = false;
                        response.message = 'Please Enter Valid ' + valueType;
                  }
            } else {
                  response.status = false;
                  response.message = 'Please Enter Valid ' + valueType;
            }
      } else {
            response.status = false;
            response.message = 'Please Enter ' + valueType;
      }
      return response;
}

export function isValidZipcode(zipCode) {
      let response = {};
      if (zipCode) {
            zipCode = zipCode.trim();
            //Indian 
            //     if (/^[1-9][0-9]{5}$/.test(Number(zipCode))) {
            //international for alpha numeric
            // if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(Number(zipCode))) {
            if (zipCode.toString().length >= 3) {
                  response.status = true;
                  response.message = zipCode.trim();
                  //   } else {
                  //       response.status = false;
                  //       response.message = 'Please Enter Valid ' + 'Zip Code';
                  //   }
            } else {
                  response.status = false;
                  response.message = 'Please Enter Valid ' + 'Zip Code';
            }
      } else {
            response.status = false;
            response.message = 'Please Enter ' + 'Zip Code';
      }
      return response;
}

export function isValidPancode(panCode) {
      let response = {};
      if (panCode) {
            panCode = panCode.trim();
            //Indian 
            //     if (/^[1-9][0-9]{5}$/.test(Number(panCode))) {
            //international for alpha numeric
            if (/^([a-zA-Z0-9]{2,10})$/.test(Number(panCode))) {
                  //   if (panCode.toString().length >= 3) {
                  response.status = true;
                  response.message = panCode.trim();
                  //   } else {
                  //       response.status = false;
                  //       response.message = 'Please Enter Valid ' + 'Zip Code';
                  //   }
            } else {
                  response.status = false;
                  response.message = 'Please Enter Valid ' + 'Pan';
            }
      } else {
            response.status = false;
            response.message = 'Please Enter ' + 'Pan';
      }
      return response;
}

export function isValidFolioNo(folioNo) {
      let response = {};
      if (folioNo) {
            folioNo = folioNo.trim();
            //Indian 
            //     if (/^[1-9][0-9]{5}$/.test(Number(folioNo))) {
            //international for alpha numeric
            if (/^([a-zA-Z0-9]{2,15})$/.test(Number(folioNo))) {
                  //   if (folioNo.toString().length >= 3) {
                  response.status = true;
                  response.message = folioNo.trim();
                  //   } else {
                  //       response.status = false;
                  //       response.message = 'Please Enter Valid ' + 'Folio Number';
                  //   }
            } else {
                  response.status = false;
                  response.message = 'Please Enter Valid ' + 'Folio Number';
            }
      } else {
            response.status = false;
            response.message = 'Please Enter ' + 'Folio Number';
      }
      return response;
}

export function isEmpty(value) {
      let response = {};
      if (value && value != null) {
            if (_.isDate(value)) {
                  response.status = value instanceof Date && !isNaN(value);
                  response.message = 'Checking whether Date is Empty';
            } else if (_.isObject(value)) {
                  response.status = !(_.isEmpty(value));
                  response.message = 'Checking whether object is Empty';
            } else if (_.isArray(value)) {
                  response.status = (value.length !== 0);
                  response.message = 'Checking whether array is Empty';
            } else if (value) {
                  value = value.trim();
                  response.status = true;
                  response.message = value.trim();
            } else {
                  response.status = false;
                  response.message = 'Please Enter Value';
            }
      } else {
            response.status = false;
            response.message = 'Please Enter Value';
      }
      return response;
}

String.prototype.toProperCase = function () {
      return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

export const isTab = window.matchMedia('(min-width:767px) and (max-width: 834px)').matches;

export const mediaQueryRangeTab = "834px";

export const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

