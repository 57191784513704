import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import config from "../../../../../config";
import { Link } from 'react-router-dom';

const ICSSection = styled.div`
  width: 100%;
  margin-top:40px;
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration: underline !important;
  }
`;

export const Certificates = () => {

    const [reportsge, setReportsge] = useState([]);

    useEffect(() => {

        var tempIncludedObj = {};
        var tempArr = [];

        axios({
            method: "GET",
            url: config.serverUrl.base + "/jsonapi/node/certifications?include=field_file_certificates"
        }).then((res) => {
            for (let item of res.data.included) {
                tempIncludedObj[item.id] = config.serverUrl.imageBase + item.attributes.uri.url;
            }

            for (let report of res.data.data) {
                tempArr.push({
                    title: report.attributes.title,
                    url: tempIncludedObj[report.relationships.field_file_certificates.data.id]
                });
            }
            setReportsge(tempArr);
        });
    }, []);

    return(
        <ICSSection>
            <div style={{ display: "flex", flexDirection: "column" }}>
                { reportsge.length > 0 && reportsge.map((item, index) => {
                    return (
                        <TextContent
                            to={{ pathname: item.url }}
                            target="_blank"
                            key={index}
                        >
                            {item.title}
                        </TextContent>
                    );
                }) }
            </div>
        </ICSSection>
    );
}
