import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile,SortMonthNames } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";

const Main = styled.div`
  width: 100%;
  margin-top:40px;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 1030px) {
    font-size: 12px;
    width: 40%;
  }
`;
const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBook = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;
const TextClick = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 12px;
    margin-bottom: 25px;
  }
`;
const CGRSection = styled.div`
  width: 100%;
  margin-top: 70px;
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 30%;
  @media (max-width: 900px) {
    width: 80%;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

const ReportHeading = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  width: 15%;
  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
  }
`;

const ICSSection = styled.div`
  width: 90%;
`;

const content = styled.p`
  display: flex;
  flex-direction: column;
`;

const Label = styled.p`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-family: Drlcircular !important;
  margin: 0 !important;
  width:30% !important;
  margin-right:50px !important;
  @media (max-width: 900px) {
    width:100% !important;
    margin-bottom: 20px!important;
  }
`;

const DropDownMain = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000 !important;
    text-decoration:underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
`;

export const CorporateGovernanceReports = (props) => {

  const [reports, setReports] = useState({});
    const [dropdownData, setDropdowndata] = useState([]);
    const [dropdownDataMonth, setDropdowndataMonth] = useState([]);
    const [selectedFilterDate, setSelectedFilterDate] = useState("");
    const [selectedFilterMonth, setSelectedFilterMonth] = useState("");
    const [governancelist, setgovernancelist] = useState({});

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempObj = {};
    var tempArr = [];
    var tempArr1 = [];
    var tempProperty = "";
    var filterDataMap = {};
    axios({
        method: "get",
        url:
            config.serverUrl.base +
            "/jsonapi/node/corporate_governance_reports?include=field_file_governancereports",
    })
        .then((res) => {
            console.log(res);
            for (const item of res.data.included) {
                tempIncludedObj[item.id] =
                    config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
                tempArr = [];
                tempArr1 = [];
                if (
                    tempDropdownData.indexOf(
                        report.attributes.field_year_governance
                    ) === -1
                ) {
                    tempDropdownData.push(report.attributes.field_year_governance);
                }
                if (filterDataMap?.[report.attributes.field_year_governance]) {
                    tempArr1 = filterDataMap[report.attributes.field_year_governance];
                    if (tempArr1.indexOf(report.attributes.field_month) === -1) {
                        tempArr1.push(report.attributes.field_month);
                    }
                    tempArr1 = SortMonthNames(tempArr1);
                    filterDataMap[report.attributes.field_year_governance] = [...tempArr1]
                } else {
                    filterDataMap[report.attributes.field_year_governance] = [report.attributes.field_month];
                }
                tempDropdownData = tempDropdownData.sort().reverse();
                if (tempReports?.[report.attributes.field_year_governance]) {
                    if (tempReports[report.attributes.field_year_governance]?.[report.attributes.field_month]) {
                        tempArr = tempReports[report.attributes.field_year_governance][report.attributes.field_month];
                        tempArr.push({
                            month: report.attributes.field_month,
                            title: report.attributes.title,
                            url: tempIncludedObj[
                                report.relationships.field_file_governancereports.data.id
                            ],
                        });
                        var temp = tempReports[report.attributes.field_year_governance];
                        temp[report.attributes.field_month] = tempArr;
                        tempReports[report.attributes.field_year_governance] = temp;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            month: report.attributes.field_month,
                            title: report.attributes.title,
                            url: tempIncludedObj[
                                report.relationships.field_file_governancereports.data.id
                            ],
                        });
                        var temp = tempReports[report.attributes.field_year_governance];
                        temp[report.attributes.field_month] = tempArr;
                        tempReports[report.attributes.field_year_governance] = temp;
                    }
                } else {
                    tempArr = [];
                    tempArr.push({
                        month: report.attributes.field_month,
                        title: report.attributes.title,
                        url: tempIncludedObj[
                            report.relationships.field_file_governancereports.data.id
                        ],
                    });
                    var temp = {};
                    temp[report.attributes.field_month] = tempArr;
                    tempReports[report.attributes.field_year_governance] = temp;
                }
            }
            if (tempDropdownData && tempDropdownData.length) {
                setSelectedFilterDate(tempDropdownData[0]);
                setSelectedFilterMonth(filterDataMap[tempDropdownData[0]][0])
            }
            console.log(tempDropdownData, "   tempDropdownData");
            console.log(tempReports, "   tempReports");
            console.log(filterDataMap, "   filterDataMap");
            setDropdowndata(tempDropdownData);
            setReports(tempReports);
            setDropdowndataMonth(filterDataMap);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempArr = [];
    axios({
        method: "get",
        url:
            config.serverUrl.base +
            "/jsonapi/node/governance_list?include=field_file_governancelist",
    })
        .then((res) => {
            console.log(res);
            for (const item of res.data.included) {
                tempIncludedObj[item.id] =
                    config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
                if (tempReports?.[report.attributes.title]) {
                    tempArr = tempReports[report.report.attributes.title];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[
                            report.relationships.field_file_governancelist.data.id
                        ],
                    });
                    tempReports[report.attributes.title] = tempArr;
                } else {
                    tempArr = [];
                    tempArr.push({
                        title: report.attributes.title,
                        url: tempIncludedObj[
                            report.relationships.field_file_governancelist.data.id
                        ],
                    });
                    tempReports[report.attributes.title] = tempArr;
                }
            }
            console.log(tempReports);
            setgovernancelist(tempReports);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  return (
    <Main>
      <div style={{ display: "flex", flexDirection:minWidthBool?'column' :"row", alignItems:minWidthBool?'start': 'center', width: '100%' }}>
        <Label>
          SELECT YEAR AND MONTH
        </Label>
        <DropDownMain>
          <DropDownDiv style={{marginRight:'20px'}} >
            <Cdropdown
              data={dropdownData}
              placeholder="Fiscal Year"
              id="reports"
              selectedValue={selectedFilterDate}
              onChangeValue={(val) => {
                setSelectedFilterDate(val)
                setSelectedFilterMonth(dropdownDataMonth[val][0])
                }}
            />
          </DropDownDiv>
          <DropDownDiv>
            <Cdropdown
              data={selectedFilterDate && selectedFilterDate.length !== 0 ? dropdownDataMonth[selectedFilterDate] : []}
              placeholder="Month"
              id="reports Month"
              selectedValue={selectedFilterMonth}
              onChangeValue={(val) => setSelectedFilterMonth(val)}
              style={{ justifyContent: "space-between" }}
            />
          </DropDownDiv>
        </DropDownMain>
      </div>
      <CGRSection>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {reports?.[selectedFilterDate] && reports?.[selectedFilterDate][selectedFilterMonth] &&
            reports?.[selectedFilterDate][selectedFilterMonth].map((item, index) => {
              return (
                <TextContent
                to={{ pathname: item.url }}
                            target="_blank"
                  key={selectedFilterDate + index}
                  // onClick={() => {
                  //   downloadFile(item.url);
                  // }}
                >
                  {item.title}
                </TextContent>
              );
            })}
        </div>
      </CGRSection>
    </Main>
  )
}