/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'
import { CAlert } from '../Common/CAlert'
import PurpleBack from "../Common/images/backArrowFullPrimary.svg";

import config from '../config';

import { getNewsArticleEndPoint, getMakeUpData, isTab, mediaQueryRangeTab } from '../Utils/Utils';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';
import styled from 'styled-components';



const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

const AnchorTag = styled.a`
    &:hover{
      color: #000;
      text-decoration: underline !important;
  }
`;

class DetailNewsArticle extends React.Component {

    state = {
        renderPageBool: false,
        cookieBool: true,
        data: {},
        alertBool: false,
        countrySiteDesc: "",
        // data: localStorage.getItem("newsData") ? JSON.parse(localStorage.getItem("newsData")) : {},
        // data: this.props.location.state ? this.props.location.state.data : {},
    }

    constructor(props) {
        super(props);
        this.getOffset = this.getOffset.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

    }

    static get Controller() {
        if (Controller) return Controller

        try {
            Controller = require('../controllers/DetailArticlesController')
            Controller = Controller.default || Controller

            return Controller
        }
        catch (e) {
            if (e.code == 'MODULE_NOT_FOUND') {
                Controller = DetailNewsArticle

                return Controller
            }

            throw e
        }
    }

    async componentWillMount() {
        console.log(this.props.location, "-=-=-=-")
        const { hash, search } = this.props.location;
        var tempObj = await getNewsArticleEndPoint(hash, search);
        axios({ method: "get", url: config.serverUrl.base + tempObj.url, })
            .then((res) => {
                var tempData = { ...res.data.data };
                this.setState({ data: getMakeUpData(tempData, tempObj.makeUpData) }, () => {
                    var anchors = document.getElementsByClassName('w-richtext')[0].getElementsByTagName('a');
                    for (var i = 0; i <= anchors.length; i++) {
                        anchors[i] && anchors[i].setAttribute('target', '_blank');
                    }
                    var imgs = document.getElementsByClassName('w-richtext')[0].getElementsByTagName('img');
                    for (var i = 0; i <= imgs.length; i++) {
                        imgs[i] && imgs[i].removeAttribute('height');
                        imgs[i] && imgs[i].removeAttribute('width');
                    }
                });
            })
            .catch((err) => {
                console.log(err, "-=-=-=  ERR")
            })
    }
    componentWillUnmount()  {
        window.removeEventListener('scroll', this.handleScroll);
    };

    getOffset( element ) {
        var rect = element.getBoundingClientRect();
        return {
            x: rect.left,
            y: rect.top
        };
    }

    handleScroll(event) {
        // console.log('event triggered...');
        // let scrollTop = this.getOffset(document.getElementById("content-section")).y;
        // if (scrollTop < 50) {
        //     document.getElementById("back").style.opacity=0
        //     document.getElementById("back2").style.opacity=1
        // }
        // else {
        //     document.getElementById("back").style.opacity=1
        //     document.getElementById("back2").style.opacity=0
        // }
        // console.log('scrolltop=', scrollTop);
    };

    componentDidMount() {

        // window.addEventListener('scroll', this.handleScroll);

        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e265c96c1fde14'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

                return next
            })
            if (active.lastOne) {
                this.setState({ renderPageBool: true })
            }
            return active.isAsync ? next : loading
        }))
    }

    render() {
        const proxies = DetailNewsArticle.Controller !== DetailNewsArticle ? transformProxies(this.props.children) : {

        }

        const { cookies } = this.props;
        const { cookieBool } = this.state;
        var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

        const imageUrl = config.serverUrl.imageUrlPrefix;
        var content = this.state.data.content ? this.state.data.content.split(imageUrl).join(config.serverUrl.imageBase + imageUrl) : "";

        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }

            .back-button-sticky {
                position: -webkit-sticky;
                position: sticky;
                top: 200px;
              }

        ` }} />
                {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
                <span id="Navbar" className="af-view">
                    <CAlert enableAlert={this.state.alertBool} onClose={() => { this.setState({ alertBool: false }) }} countrySiteDesc={this.state.countrySiteDesc} />
                    <div>
                        <MenuSection/>
                        <div className="af-class-section" style={{ paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '60px',  paddingBottom: '10px' }} >
                            {/* <div className="af-class-section af-class-article-hero af-class-wf-section" style={{ backgroundImage: this.state.data.coverImage ? "url(" + this.state.data.coverImage + ")" : null }}>
                                <div className="af-class-black-overlay af-class-articles-header" /> */}
                            <div className="af-class-container af-class-article w-container">
                                <h1 className="af-class-h1-article-modified" style={{ paddingTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100px',
                                textAlign: 'center', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px',
                                 lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '32px',
                                 marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '48px',
                                 marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '30px' }} >{this.state.data.title}</h1>
                            </div>
                            <div>
                                <p style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '24px', fontWeight: '700', textAlign: 'center', margin: 0 }} >{this.state.data.sourceName}</p>
                                <p style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '24px', fontWeight: '300', textAlign: 'center', margin: 0 }} >{this.state.data.publishDate}</p>
                            </div>
                        </div>
                        <div id="content-section" className="af-class-section af-class-article-section af-class-wf-section"
                            style={{paddingTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px'}}>
                            <div className="af-class-container af-class-article w-container">
                                <div className='back-button-sticky'>
                                <a id="back" onClick={() => {
                                    this.props.history.goBack();
                                }} style={{ position: 'absolute', left: 0, top: 0,  marginLeft: '-200px', zIndex: 999, display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
                                    <img  loading="lazy" src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
                                    <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
                                </a>
                                </div>
                                {/* <a id="back2" onClick={() => {
                                    this.props.history.goBack();
                                }} style={{ opacity: 0, position: 'fixed', left: '11%', top: '20%', zIndex: 999, display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
                                    <img  loading="lazy" src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
                                    <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
                                </a> */}

                                <div className="af-class-rich-text-block w-richtext" style={{ fontSize: isTab && '18px', lineHeight: isTab && '32px',
                                    margin: isTab && '0 10vw' }} dangerouslySetInnerHTML={{
                                    __html: content
                                }} />
                                {this.state.data.sourceUrl && this.state.data.sourceUrl.length !== 0 && <AnchorTag target="_blank" href={this.state.data.sourceUrl}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        this.setState({ alertBool: true, countrySiteDesc: this.state.data.sourceUrl })
                                    }}
                                    style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none', marginTop: '20px', wordBreak: 'break-all', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (isTab ?  '18px' : '14px'), lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && (isTab ?  '20px' : '24px') }} >
                                    View source: {this.state.data.sourceUrl}
                                </AnchorTag>}
                            </div>
                            <a href="#Navbar" data-w-id="6dfa7d23-fc03-1778-9347-705299f4749e" className="af-class-back-to-top-wrapper w-inline-block">
                                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                                <div>Back to Top</div>
                            </a>
                        </div>
                        {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
                            const { cookies } = this.props;
                            cookies.set('EnableCookie', JSON.stringify(data));
                        }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
                        <FooterSection />
                        {/* [if lte IE 9]><![endif] */}
                    </div>
                </span>
            </span>
        )
    }
}

export default withCookies(DetailNewsArticle)

/* eslint-enable */
