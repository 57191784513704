import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const scripts = [
    { loading: fetch("webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

export const TextSlideAnimeButton = (props) => {

    useEffect(() => {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e26580fc1fde12'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                new Function(`
              with (this) {
                eval(arguments[0])
              }
            `).call(window, script)
                return next
            })
            // if (active.lastOne) {
            //     this.setState({ renderPageBool: true })
            // }
            return active.isAsync ? next : loading
        }))
    });

    return <a {...props} href="#" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
            <div style={{ opacity: 0 }} className="af-class-link-arrow-slide-text af-class-right-to-arrow">ACCEPT</div>
        </a>
}