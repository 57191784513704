import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile,SortMonthNames } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";
import { useMediaQuery } from "react-responsive";

const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;
const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 5px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownMain = styled.div`
  display:flex;
  flex-direction:row;
`;

const DropDownDiv = styled.div`
  width:30%;
  @media (max-width: 900px) {
    width:60%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 40px;
  padding-left: 25px;
  // justify-content: space-between;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.8;
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const TextContent = styled.a`
margin: 0 !important;
font-weight: 400 !important;
font-size: 18px !important;
font-family: Drlcircular !important;
margin-bottom: 20px !important;
text-decoration: none !important;
color: #000 !important;
cursor: pointer !important;
text-decoration:none !important;
&:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px !important ;
    line-height: 24px !important ;
    margin-bottom: 15px;
    font-weight: 300;
    text-decoration:underline !important;
  }
`;

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }
    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }
    
    th,
    td {
      padding: 5px;
      border: 1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
        font-size: 14px !important ;
        line-height: 24px !important ;
      }
    }
    
    th {
      border: 1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1px solid #707070;
        font-size: 14px !important ;
        line-height: 24px !important ;
      }
      }
    }
  }
`;

export const OtherStockExchangeIntimations = (props) => {

  const [reportsom, setReportsom] = useState({});
    const [dropdownDataom, setDropdowndataom] = useState([]);
    const [selectedFilterDateom, setSelectedFilterDateom] = useState("");
    const [dropdownDataomm, setDropdowndataomm] = useState([]);
    const [selectedFilterDateomm, setSelectedFilterDateomm] = useState("");
  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  useEffect(() => {
    var tempReports = {};
    var tempDropdownData = [];
    var tempDropdownDatam = [];
    var tempObj = {};
    var tempArr = [];
    axios({
        method: "get",
        url:
            config.serverUrl.base +
            "/jsonapi/node/other_stock_exchange_intimations",
    })
        .then((res) => {
    // const res = { ...ApiData };
    console.log(res);
    // for (const report of res.data) {
    for (const report of res.data.data) {
        tempObj = {};
        tempArr = [];
        if (
            tempDropdownData.indexOf(
                report.attributes.field_year_otherstock
            ) === -1
        ) {
            tempDropdownData.push(report.attributes.field_year_otherstock);
        }
        tempDropdownData = tempDropdownData.sort().reverse();
        if (
            tempDropdownDatam.indexOf(
                report.attributes.field_month_otherstock
            ) === -1
        ) {
            tempDropdownDatam.push(report.attributes.field_month_otherstock);
        }
        tempDropdownData = tempDropdownData.sort().reverse();
        tempDropdownDatam = SortMonthNames(tempDropdownDatam) ;
        if (
            tempReports?.[report.attributes.field_year_otherstock] &&
            tempReports[report.attributes.field_year_otherstock]?.[
            report.attributes.field_month_otherstock
            ]
        ) {
            tempArr =
                tempReports[report.attributes.field_year_otherstock]?.[
                report.attributes.field_month_otherstock
                ];
            tempArr.push({
                title: report.attributes.title,
                description:
                    report.attributes.field_table_other_stock_exchange.value,
            });
            tempObj[report.attributes.field_month_otherstock] = tempArr;
            tempReports[report.attributes.field_year_otherstock][
                report.attributes.field_month_otherstock
            ] = tempArr;
        } else {
            tempArr = [];
            tempArr.push({
                title: report.attributes.title,
                description:
                    report.attributes.field_table_other_stock_exchange.value,
            });
            tempObj[report.attributes.field_month_otherstock] = tempArr;
            if (tempReports?.[report.attributes.field_year_otherstock]) {
                tempReports[report.attributes.field_year_otherstock][
                    report.attributes.field_month_otherstock
                ] = tempArr;
            } else {
                tempReports[report.attributes.field_year_otherstock] = tempObj;
            }
        }
    }
    if (tempDropdownData && tempDropdownData.length) {
        setSelectedFilterDateom(tempDropdownData[0]);
    }
    if (tempDropdownDatam && tempDropdownDatam.length) {
        setSelectedFilterDateomm(tempDropdownDatam[0]);
    }
    console.log(tempDropdownData);
    console.log(tempReports, "     setReportsom", tempDropdownDatam);
    setDropdowndataom(tempDropdownData);
    setReportsom(tempReports);
    setDropdowndataomm(tempDropdownDatam);
    })
    .catch((err) => {
        console.log(err, "err");
    });
}, []);

  const renderTableData = () => {
    if(reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm] && reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm][0]?.description){
      var keys = Object.keys(reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm][0].description);
      var tableData = reportsom?.[selectedFilterDateom]?.[selectedFilterDateomm][0].description;
      keys.pop();
      var result1 = [];
      keys.map(
        (item, index) => {
          var result = [];
            for (let rowIndex=0;rowIndex < tableData[item].length;rowIndex++) {
              if(item === "0"){
                result.push(<th style={{padding: '20px 15px',textAlign: 'left',}} >{tableData[item][rowIndex]}</th>)
              } else if(rowIndex === 2){
                result.push(<td style={{padding: '20px 15px'}} ><TextContent
                target="_blank"
                  href={
                    config.serverUrl.imageBase + config.serverUrl.imageUrlPrefix + "files/Otherstockexchangepdfs/" +tableData[item][rowIndex]
                  }
                >
                  Click here
                </TextContent></td>)
              } else {
                result.push(<td style={{padding: '20px 15px'}} >{tableData[item][rowIndex]}</td>)
              }
            }
            result1.push(<tr>{result}</tr>)
        }
      )}
      return result1;
  }
      

  return (<>
    <SubSubContainer>
      <div style={{ display: "flex", flexDirection:minWidthBool?'column': "row", width: '100%',marginBottom:'60px' }}>
        <Label
          style={{
            fontSize: "18px",
            alignSelf: minWidthBool?'start':"center",
            width:minWidthBool?'80%': '40%'
          }}
        >
          SELECT YEAR AND MONTH
        </Label>
        <DropDownMain
          style={{
            display: "flex",
            flexDirection:minWidthBool?'column': "row",
            width:minWidthBool? '100%': '60%'
          }}
        >
          <DropDownDiv style={{ marginRight: minWidthBool ? '0' :'20px',marginBottom: minWidthBool ? '20px' :'0' }} >
            <Cdropdown
              data={dropdownDataom}
              placeholder="Year"
              id="reportsom"
              selectedValue={selectedFilterDateom}
              onChangeValue={(val) => setSelectedFilterDateom(val)}
            ></Cdropdown>
          </DropDownDiv>
          <DropDownDiv>
            <Cdropdown
              data={dropdownDataomm}
              placeholder="month"
              id="reportsomm"
              selectedValue={selectedFilterDateomm}
              onChangeValue={(val) => setSelectedFilterDateomm(val)}
            ></Cdropdown>
          </DropDownDiv>
        </DropDownMain>
      </div>
      <TableContainer>
        <table>
          {renderTableData()}
        </table>
      </TableContainer>
    </SubSubContainer>
  </>
  )

}