import React, { useRef } from 'react';

import { isTab } from '../../Utils/Utils';

const HeroBanner = () => {

    // let mask = useRef(null);

    // var timer, resizeTimer;

    // const markResize = () => {
    //     resizeTimer = setInterval(() => {
    //         if (mask.current.clientWidth > 250) {
    //             let mask_dimentions = mask.current.clientWidth - 5;
    //             mask.current.style.width = `${mask_dimentions}px`;
    //             mask.current.style.height = `${mask_dimentions}px`;
    //         }
    //         else {
    //             clearInterval(resizeTimer);
    //         }
    //     }, 20);
    // }

    return (
        <div id="Impact-Hero" className="af-class-about-hero-circle-wrapper-life-at-drl"
                style={{ marginTop: window.matchMedia('(max-width: 820px)').matches ? '60px': '120px', backgroundImage: window.matchMedia('(max-width: 820px)').matches ? 'url("/images/Sustainability_Mobile_Landing.jpg")' :'url("/images/SustainabilityLandingWeb.png")', cursor: 'auto',height:isTab && '100vh' }} >
            <div id="sustainability-hero" className="af-class-section af-class-life-at-drl-hero" style={{ backgroundColor: 'rgba(0,0,0,0.6)', marginBottom: !window.matchMedia('(max-width: 820px)').matches && '-25px' }} >
                <div className="af-class-container w-container">
                    {/* <div className="w-layout-grid af-class-_2-col-grid af-class-_40px-gap" style={{gridRowGap:window.matchMedia('(max-width: 820px)').matches && '24px'}} > */}
                        {/* <h2 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className="af-class-h2-section-heading af-class-title af-class-negative-margin" style={{ fontSize: window.matchMedia('(max-width: 820px)').matches ? '36px' : '60px',margin:window.matchMedia('(max-width: 820px)').matches && '0px',lineHeight:window.matchMedia('(max-width: 820px)').matches && '48px' }} >Our Culture</h2> */}
                        {/* <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches) {
                                el.style.setProperty('line-height', '28px', 'important');
                            }
                        }} id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e5-bdd5ac66" style={{ fontSize: window.matchMedia('(max-width: 820px)').matches ? '16px' : '20px' }} >Sustainability is one of our core values, and we continue to build on our goals aligned with global standards.</p> */}
                    {/* <h1 data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" style={{ opacity: 1, WebkitTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', maxWidth: '88%', fontSize: window.matchMedia('(max-width: 820px)').matches ? isTab ? '48px' : '36px' : '60px',lineHeight: isTab && '60px' }} className="af-class-h2-section-heading af-class-title af-class-center-align" >Sustainability is one of our core values, and we continue to build on our goals aligned with global standards.</h1> */}
                    <h1 data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" style={{ opacity: 1, WebkitTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', maxWidth: '88%', fontSize: window.matchMedia('(max-width: 820px)').matches ? isTab ? '48px' : '36px' : '60px',lineHeight: isTab && '60px' }} className="af-class-h2-section-heading af-class-title af-class-center-align" >Our core value of sustainability drives our resolve to address societal needs and guides our ambitious ESG goals for the next decade.</h1>
                    {/* </div> */}
                {/* </div> */}
            </div>
        </div>
       </div>
    )
}

export default HeroBanner;
