const RAndDMarkers = [
    {
        markerOffset: -5,
        name: "INDIA",
        mName: "India",
        coordinates: [78.44351609220966, 17.492441287638922],
        show: true,
        tooltipData: {
            title: "INDIA",
            address: [
                "API Hyderabad Plant 1<br/> Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District<br/> Telangana, 502 325. INDIA <br/>Tel: +91.8458.283 200",
                "API Hyderabad Plant 2 <br/>Dr. Reddy's Laboratories Limited, Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112,<br/> Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, Pin Code: - 502325.",
                "API Hyderabad 3 Plot No. 116,<br/> 116A & 126C & SY No. 157 IDA Bollaram, Jinnaram Mandal<br/> Medak District Telangana 502 325.<br/> INDIA Tel: +91.8458.283 700",
                "API Nalgonda Plant<br/> Peddadevulapally Tripuraram Mandal<br/> Nalgonda District Telangana 508 207.<br/> INDIA Tel: +91.8689. 253 150",
                "API Srikakulam Plant<br/> IDA Pydibheemavaram Ransthal Mandal <br/>Srikakulam District Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.288 131",
                "API Srikakulam Plant (SEZ)<br/> Sector No. 28 & 34 Devunipalavalasa Village <br/>Ranastalam Mandal Srikakulam District <br/>Andhra Pradesh 532 409.<br/> INDIA Tel: +91.8942.304141",
                "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA<br/>Tel: +91.8458.279 669",
                "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA<br/>Tel: +91.40.4464 2200",
                "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA<br/>Tel: +91.40.4464 3440",
                "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 56001",
                "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA<br/>Tel: +91.1795 650761",
                "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
                "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA<br/>Tel: +91.8913 013400",
                "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91.8942 200046",
                "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA<br/>Tel: +91 8942 304300",
                "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA",
                "Integrated Product Development Organization (IPDO)<br/>Bachupally Village<br/>Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500 123. INDIA<br/>Tel: +91.40.4434 6200",
                "IPDO, BANGALORE<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA<br/>Tel: +91.80.7102 5444",
                "Aurigene Discovery Technologies Limited<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA",
                "ADTL, HYDERABAD<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049. INDIA",
                "Technology Development Center I<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049, INDIA",
                "Technology Development Center II<br/>Plot 31A IDA,<br/>Jeedimetla<br/>Hyderabad 500 050, INDIA<br/>+91.40.2309 5272",
            ],
            mAddress: [
                { text: "API Hyderabad Plant 1<br/> Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District<br/> Telangana, 502 325. INDIA ", telNo: '+91 8458 283 200' },
                { text: "API Hyderabad Plant 2 <br/>Dr. Reddy's Laboratories Limited, Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112,<br/> Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, Pin Code: - 502325.", },
                { text: "API Hyderabad 3 Plot No. 116,<br/> 116A & 126C & SY No. 157 IDA Bollaram, Jinnaram Mandal<br/> Medak District Telangana 502 325.<br/> INDIA", telNo: '+91 8458 283 700' },
                { text: "API Nalgonda Plant<br/> Peddadevulapally Tripuraram Mandal<br/> Nalgonda District Telangana 508 207.<br/> INDIA", telNo: '+91 8689 253 150' },
                { text: "API Srikakulam Plant<br/> IDA Pydibheemavaram Ransthal Mandal <br/>Srikakulam District Andhra Pradesh 532 409.<br/> INDIA", telNo: '+91 8942 288 131' },
                { text: "API Srikakulam Plant (SEZ)<br/> Sector No. 28 & 34 Devunipalavalasa Village <br/>Ranastalam Mandal Srikakulam District <br/>Andhra Pradesh 532 409.<br/> INDIA", telNo: '+91 8942 304141' },
                { text: "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA", telNo: '+91 8458 279 669' },
                { text: "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA", telNo: '+91 40 4464 2200' },
                { text: "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA", telNo: '+91 40 4464 3440' },
                { text: "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA", telNo: '+91 1795 56001' },
                { text: "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA", telNo: '+91 1795 650761' },
                { text: "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913 013400' },
                { text: "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913 013400' },
                { text: "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942 200046' },
                { text: "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942 304300' },
                { text: "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA", },
                { text: "Integrated Product Development Organization (IPDO)<br/>Bachupally Village<br/>Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500 123. INDIA", telNo: '+91 40 4434 6200' },
                { text: "IPDO, BANGALORE<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA", telNo: '+91 80 7102 5444' },
                { text: "Aurigene Discovery Technologies Limited<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA", },
                { text: "ADTL, HYDERABAD<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049. INDIA", },
                { text: "Technology Development Center I<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049, INDIA", },
                { text: "Technology Development Center II<br/>Plot 31A IDA,<br/>Jeedimetla<br/>Hyderabad 500 050, INDIA", telNo: '+91 40 2309 5272' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "USA",
        mName: "USA",
        coordinates: [-74.66761139608748, 40.36021222873629],
        tooltipData: {
            title: "USA",
            address: [
                "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport<br/>Louisiana 71106. USA<br/>Tel: +1.318.861 8070",
                "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA<br/>Tel: +1.518.827 7702",
                "Technology Development Centre, Princeton<br/>303, College Road East<br/>Princeton, New Jersey 08540. USA",
                "Brunswick Research Center, Princeton<br/>2031 US Highway 130. Unit D<br/>Monmouth Junction<br/>New Jersey 08852. USA<br/>Tel: +1.732.422 7042",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport<br/>Louisiana 71106. USA", telNo: '+1 318 861 8070' },
                { text: "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA", telNo: '+1 518 827 7702' },
                { text: "Technology Development Centre, Princeton<br/>303, College Road East<br/>Princeton, New Jersey 08540. USA", },
                { text: "Brunswick Research Center, Princeton<br/>2031 US Highway 130. Unit D<br/>Monmouth Junction<br/>New Jersey 08852. USA", telNo: '+1 732 422 7042' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "NETHERLANDS",
        mName: "Netherlands",
        coordinates: [5.409965522017349, 52.164787278841196],
        tooltipData: {
            title: "NETHERLANDS",
            address: [
                "Dr. Reddy’s Research & Development B.V.<br/>Zernikedreef 12,<br/>2333 CL Leiden.<br/>The Netherlands.",
            ],
            mAddress: [
                { text: "Dr. Reddy’s Research & Development B.V.<br/>Zernikedreef 12,<br/>2333 CL Leiden.<br/>The Netherlands.", telNo: '' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "UNITED KINGDOM",
        mName: "United Kingdom",
        coordinates: [-3.585901210996002, 55.92909145687116],
        tooltipData: {
            title: "UNITED KINGDOM",
            address: [
                "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM",
                "Dr. Reddy's Laboratories (EU) Limited<br/>Steanard Lane<br/>Mirfield, West Yorkshire<br/>WF 14, 8HZ<br/>UNITED KINGDOM",
                "Chirotech Technology Limited<br/>410 Cambridge Science Park<br/>Milton Road, Cambridge<br/>CB4 0PE, UNITED KINGDOM",
            ],
            mAddress: [
                { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM", },
                { text: "Dr. Reddy's Laboratories (EU) Limited<br/>Steanard Lane<br/>Mirfield, West Yorkshire<br/>WF 14, 8HZ<br/>UNITED KINGDOM", },
                { text: "Chirotech Technology Limited<br/>410 Cambridge Science Park<br/>Milton Road, Cambridge<br/>CB4 0PE, UNITED KINGDOM", },
            ]
        }
    },
]

export default RAndDMarkers;