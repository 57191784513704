import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import EmpathyImg from '../../assets/empathy1.png';
import DynamismImg from '../../assets/Dynamism1.png';

import { isTab } from '../../Utils/Utils';

const OurPrinciplesMobile = ({ data }) => {

    async function vh(v) {
        var h = await Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger);

        // let tl = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: "#ourPrinciple-container",
        //         start: "top top",
        //         end: "+=1200",
        //         pin: true,
        //         pinSpacing: true,
        //         // markers: true,
        //         scrub: true,
        //     }
        // });

        // tl.set("#text-one", { opacity: 1, })
        // tl.set("#text-two", { opacity: 0, })
        // tl.set("#img-two", { opacity: 0 })

        // // tl.to('#img-one', {
        // //   opacity: 0})

        // tl.to('#text-one', {
        //     opacity: 0,
        //     duration:0.2
        // }, "<")

        // tl.set("#text-one", { opacity: 0, })
        // // tl.set("#img-one", {opacity: 0}, "<")
        // // tl.set("#img-two", { opacity: 0 }, "<")

        // tl.fromTo('#img-two', {
        //     opacity: 0
        // },
        //     { opacity: 1,duration:0.2 }, ">")

        // tl.fromTo('#text-two', {
        //     opacity: 0
        // },
        //     { opacity: 1,duration:0.2 }, "<")

        // tl.set("#text-two", { opacity: 0, }, ">")
        // tl.set("#img-two", {opacity: 0}, "<")

        gsap.registerPlugin(ScrollTrigger);
        if (ScrollTrigger.getById('trigger_OurPrinciple')) ScrollTrigger.getById('trigger_OurPrinciple').kill();

        // ScrollTrigger.refresh();

        let tl2 = gsap.timeline({
            scrollTrigger: {
                id: "trigger_OurPrinciple",
                trigger: "#ourPrinciple-container",
                start: "top",
                end: "+=800",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        tl2.set("#img-one", { opacity: 1 })
        tl2.set("#img-two", { opacity: 0 })

        // tl2.set('#img-one', {opacity: 1}); 

        // tl2.set("#img-one", { opacity: 0 })        
        tl2.set('#img-two', { opacity: 1 }, "+=0.4");
        tl2.set('#img-two', { opacity: 1 }, "+=0.2");

    }, [data])


    {/* <div id="ourPrinciple-container" style={{ marginTop: '8px', paddingTop: "32px", borderTop: "1px solid #ff5046", display: 'flex', flexDirection: 'column',width:'100%' }} > */ }
    return <div id="ourPrinciple-container" style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh' }} >
        <div id="img-one" style={{ backgroundImage: `url(${EmpathyImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, }}  >
            <span style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', opacity: 1 }} >
                {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                <h2 style={{ color: '#fff', fontSize: isTab ? '50px' : '40px', fontWeight: 300, marginBottom: isTab && '45px', filter: 'opacity(1)' }} >Empathy</h2>
                {isTab
                    ?
                    <h6 style={{ color: '#fff', fontSize: isTab ? '24px' : '18px', fontWeight: 200, paddingLeft: '5%', paddingRight: '5%', textAlign: 'center', filter: 'opacity(1)', lineHeight: '32px',width:'80%' }} >We understand the needs of our patients and partners better than others.</h6>
                    :
                    <h6 style={{ color: '#fff', fontSize: isTab ? '24px' : '18px', fontWeight: 200, paddingLeft: '18%', paddingRight: '18%', textAlign: 'center', filter: 'opacity(1)', lineHeight: '1.6' }} >We understand the needs <br /> of our patients and partners <br /> better than others.</h6>}
            </span>
        </div>
        <div id="img-two" style={{ backgroundImage: `url(${DynamismImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'absolute', backgroundSize: 'cover', height: '100vh', width: '100%', zIndex: 9, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
            <span style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', opacity: 1 }} >
                {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                <h2 style={{ color: '#fff', fontSize: isTab ? '50px' : '40px', fontWeight: 300, marginBottom: isTab && '45px', filter: 'opacity(1)' }} >Dynamism</h2>
                {
                    isTab
                        ?
                        <h6 style={{ color: '#fff', fontSize: isTab ? '24px' : '18px', fontWeight: 200, paddingLeft: '18%', paddingRight: '18%', textAlign: 'center', filter: 'opacity(1)', lineHeight: '32px',width:'80%' }} >We solve challenges that only a few can, and do this with agility.</h6>
                        :
                        <h6 style={{ color: '#fff', fontSize: isTab ? '24px' : '18px', fontWeight: 200, paddingLeft: '18%', paddingRight: '18%', textAlign: 'center', filter: 'opacity(1)', lineHeight: '1.6' }} >We solve challenges that <br /> only a few can, <br /> and do this with agility.</h6>
                }
            </span>
        </div>
    </div>
}

export default OurPrinciplesMobile;