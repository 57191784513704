import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Modal from 'react-modal';

import { isTab } from '../Utils/Utils';

const customStyles = {
    content: {
        borderRadius: "5px",
        inset: "10px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const customStylesTab = {
    content: {
        borderRadius: "5px",
        inset: "10px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const customStylesMob = {
    content: {
        borderRadius: "5px",
        inset: "10px",
        border: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'transparent',
    },
    overlay: {
        zIndex: 9999,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(51 51 51 / 50%)"
    }
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    background-color: #EDEDED;
    width:580px;
    @media (max-width: 820px) {
        width:300px;
    }
`;

const Text = styled.p`
    color: #000000;
    font-size: 18px;
    font-weight: 200;
    font-family: Drlcircular;
    margin: 0;
    margin-top:45px;
    margin-bottom:70px;
    align-self:center;
    width:520px;
    max-height:50vh;
    overflow: scroll;
    word-break: break-all;
    @media (max-width: 767px) {
        font-size: 14px;
        margin-top:30px;
        margin-bottom:35px;
        width:240px;
    }
    @media (max-width: 820px) {
        font-size: 16px;
        margin-top:38px;
        margin-bottom:35px;
        width:240px;
    }
    `;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction:row;
    align-self: center;
    margin-bottom:50px;
    @media (max-width: 767px) {
        flex-direction:column;
        margin-bottom:40px;
    }
    @media (max-width: 820px) {
        flex-direction:column;
        margin-bottom:40px;
    }
`;

const Img = styled.img`
    height: 18px;
    width: 18px;
    @media (max-width: 820px) {
        height: 15px;
        width: 15px;
    }
`;

const CancelButton = styled.a`
    text-decoration:none;
    text-align:center;
    width:190px;
    cursor: pointer;
    color: #000;
    background-color:#C6C6C6;
    font-size: 20px;
    font-weight: 400;
    font-family: Drlcircular;
    padding: 15px;
    border-radius: 5px;
    margin-right:55px;
    @media (max-width: 820px) {
        margin-right:0px;
        margin-bottom:20px;
        font-size: 16px;
    }
    `;

const OkButton = styled.a`
    text-decoration:none !important;
    text-align:center;
    width:190px;
    cursor: pointer;
    color: #fff !important;
    background-color:#5225B5;
    font-size: 20px;
    font-weight: 400;
    font-family: Drlcircular;
    padding: 15px;
    border-radius: 5px;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

export function CAlert({ enableAlert, onClose, countrySiteDesc, }) {
    return <Modal
        isOpen={enableAlert}
        // onRequestClose={onClose}
        style={window.matchMedia('(max-width: 767px)').matches ? customStylesMob : isTab ? customStylesTab : customStyles}
    >
        <Container>
            <a onClick={onClose} style={{ alignSelf: 'flex-end', textDecoration: 'none', cursor: 'pointer' }} >
                <Img loading="lazy" src='/images/closePrimary.svg' />
            </a>
            {
                countrySiteDesc?.name
                    ?
                    <Text>You are now leaving the Dr. Reddy's global website and entering the Dr. Reddy's <span style={{ textTransform: "capitalize" }}>{countrySiteDesc.name.toLowerCase()}</span> website.</Text>
                    :
                    <Text>You are now leaving the Dr. Reddy's global website and entering {countrySiteDesc} website.</Text>
            }
            <ButtonContainer>
                <CancelButton onClick={onClose} >Cancel</CancelButton>
                <OkButton target="_blank" href={countrySiteDesc?.url ? countrySiteDesc.url : countrySiteDesc} onClick={onClose} >OK</OkButton>
            </ButtonContainer>
        </Container>
    </Modal>
}
