import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { TextSlideAnimeButton } from "../../../../common/TextSlideAnimeButton";
import { HR } from "../../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

import { animateScroll as scroll } from 'react-scroll';

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top: 40px;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const DropDownDiv = styled.div`
  width:30%;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
    flex-direction: column;
`;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-bottom:32px !important;
  font-family: Drlcircular;
  @media (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Content = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none !important;
  color: #000;
  cursor: pointer;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
`;

const ContentDesc = styled.p`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: Drlcircular;
  margin-top: -25px !important;
  margin-bottom: 20px!important;
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-top: -18px !important;
    margin-bottom: 15px!important;
  }
`;

const ReportItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px;
  flex-direction: row;
  @media (max-width: 900px) {
    margin-top: 50px;
  }
`;

const ReportItemDiv = styled.div`
  width: 50%;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ReportItem = styled(Link)`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none !important;
  color: #000;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;

const Text = styled.p`
  font-size: 18px !important ;
  color: #000000 !important ;
  font-weight: 300 !important ;
  font-family: Drlcircular !important ;
  line-height: 30px !important ;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    line-height: 24px !important ;
  }
`;

export const SecFiling = (props) => {

  const [secreports, setsecreports] = useState({});
  const [Dropdowndatasecfilling, setDropdowndatasecfilling] = useState([]);
  const [Selectedsecdate, setSelectedsecdate] = useState("");

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempArr = [];
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/sec_filings?include=field_file_secfillings",
    })
      .then((res) => {
        console.log(res);
        for (const item of res.data.included) {
          tempIncludedObj[item.id] =
            config.serverUrl.imageBase + item.attributes.uri.url;
        }
        for (const report of res.data.data) {
          tempArr = [];
          if (
            tempDropdownData.indexOf(
              report.attributes.field_year_secfillings
            ) === -1
          ) {
            tempDropdownData.push(report.attributes.field_year_secfillings);
          }
          tempDropdownData = tempDropdownData.sort().reverse();
          if (tempReports?.[report.attributes.field_year_secfillings]) {
            tempArr = tempReports[report.attributes.field_year_secfillings];
            tempArr.push({
              title: report.attributes.title,
              desc: report.attributes.field_text,
              url: tempIncludedObj[
                report.relationships.field_file_secfillings.data.id
              ],
            });
            tempReports[report.attributes.field_year_secfillings] = tempArr;
          } else {
            console.log(
              report,
              "-==-=-==-",
              (tempIncludedObj,
                report.relationships.field_file_secfillings.data.id)
            );
            tempArr = [];
            tempArr.push({
              title: report.attributes.title,
              desc: report.attributes.field_text,
              url: tempIncludedObj[
                report.relationships.field_file_secfillings.data.id
              ],
            });
            tempReports[report.attributes.field_year_secfillings] = tempArr;
          }
        }
        if (tempDropdownData && tempDropdownData.length) {
          setSelectedsecdate(tempDropdownData[0]);
        }
        console.log(tempDropdownData);
        console.log(tempReports);
        setDropdowndatasecfilling(tempDropdownData);
        setsecreports(tempReports);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  const [acceptedBool, setAcceptedBool] = useState((localStorage.getItem("acceptancePolicies") && JSON.parse(localStorage.getItem("acceptancePolicies")))?.secFiling ? true : false);

  return <SubContainer>
    {
      acceptedBool
        ?
        <>
          <TextContainer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {secreports?.[Selectedsecdate] &&
                secreports?.[Selectedsecdate].map((item, index) => {
                  return (
                    <>
                      <Content
                        key={Selectedsecdate + index}
                        to={{ pathname: item.url }}
                        target="_blank"
                      // onClick={() => {
                      //   downloadFile(item.url);
                      // }}
                      >
                        {item.title}
                      </Content>
                      {item?.desc && item.desc && item.desc.length !== 0 && <ContentDesc>{item.desc}</ContentDesc>}
                    </>
                  );
                })}
            </div>
          </TextContainer>
          <DropDownDiv>
            <Cdropdown
              data={Dropdowndatasecfilling}
              placeholder="Year"
              id="secreports"
              selectedValue={Selectedsecdate}
              onChangeValue={setSelectedsecdate}
            />
          </DropDownDiv>
        </>
        :
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
          {/* <img  loading="lazy" src={"/images/sec-filing.png"} style={{ height: '600px', width: '40%' }} /> */}
          <div style={{ width: window.matchMedia('(max-width: 820px)').matches ? '100%' : '48%' }} >
            <TextHeading>Safe Harbour</TextHeading>
            <Text>
              The documents contain forward-looking statements, as defined in the U.S. Private Securities Litigation Reform Act of 1995 . We have based these forward-looking statements on our current expectations and projections about future events.<br />
            </Text>
            <Text>
              Such statements involve known and unknown risks, uncertainties and other factors that may cause actual result to differ materially. Such factors include, but are not limited to, changes in general economic conditions, our ability to successfully implement our strategy, the market acceptance of and demand for our products, our growth and expansion, technological change and our exposure to market risks.<br />
            </Text>
            <Text>
              By their nature, these expectations and projections are only estimates and could be materially different from actual results in the future.<br />
            </Text>
            <Text>
              This communication is not an offer to sell or the solicitation of an offer to buy securities
              <br />
              <br />
              Click below if you accept these conditions
            </Text>
            <TextSlideAnimeButton onClick={() => {
              scroll.scrollTo('main-container')
              setAcceptedBool(true);
              var ls = localStorage.getItem("acceptancePolicies");
              ls = ls ? JSON.parse(ls) : {};
              ls.secFiling = true;
              localStorage.setItem("acceptancePolicies", JSON.stringify(ls));
            }} />
          </div>
        </div>
    }
  </SubContainer>
}