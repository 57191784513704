/* eslint-disable */

import React from 'react'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import config from '../config';

import {isTab} from '../Utils/Utils'; 

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';
import styled from 'styled-components';

import { HR } from "./common/horizontalLine";

import { PressRelease } from './Media/SubModules/PressRelease';
import { Presentations } from './Media/SubModules/Presentations';
import { MediaKit } from './Media/SubModules/MediaKit';

import { GlobalLocationView } from './ContactUs/MainSection/globalLocation';
import { KeyContactsView } from './ContactUs/MainSection/keyContacts';
import { MedicalInformationView } from './ContactUs/MainSection/medicalInformation';
import { EnquiryForm } from "../Common/ContactForm";

import { ProspectivePartnersView } from './BusinessPartner/MainSection/prospectivePartner';
import { PostAnEnquiryView } from './BusinessPartner/MainSection/postAnEnquiry';
import { LoginForCurrentPartnerView } from './BusinessPartner/MainSection/loginForCurrentPartner';

import { ReportsAndFillingView } from './Investor/MainSection/ReportsAndFiling/IndexView';
import { PresentationsView } from './Investor/MainSection/Presentations/IndexView';
import { SharesView } from './Investor/MainSection/Shares/IndexView';
import { GovernanceView } from './Investor/MainSection/Governance/IndexView';
import { InvestorServiceView } from './Investor/MainSection/InvestorServices/IndexView';

const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  font-size: 40px !important;
  margin-bottom: 35px !important;
  @media (max-width: 820px) {
      line-height: 33px !important;
      font-size: 24px !important;
      margin-bottom: 10px !important;
  }
`;

let Controller

const AnchorTag = styled.a`
    &:hover{
      color: #000;
      text-decoration: underline !important;
  }
`;

const HashData = {
    '#press-releases': {
        id: '#press-releases',
        screenTitle: 'Press Releases',
        breadCrumbData: [
            { id: 'breadCrumbData:Media', name: 'Media', hrefPath: '/drreddys-media' },
            { id: 'breadCrumbData:Press Releases', name: 'Press Releases', hrefPath: '/' },
        ],
        renderComponentCaseId: 1
    },
    '#press-presentations': {
        id: '#press-presentations',
        screenTitle: 'PRESS PRESENTATIONS',
        breadCrumbData: [
            { id: 'breadCrumbData:Media', name: 'Media', hrefPath: '/drreddys-media' },
            { id: 'breadCrumbData:PRESS PRESENTATIONS', name: 'PRESS PRESENTATIONS', hrefPath: '/' },
        ],
        renderComponentCaseId: 14
    },
    '#media-kit': {
        id: '#media-kit',
        screenTitle: 'Media Kit',
        breadCrumbData: [
            { id: 'breadCrumbData:Media', name: 'Media', hrefPath: '/drreddys-media' },
            { id: 'breadCrumbData:Media Kit', name: 'Media Kit', hrefPath: '/' },
        ],
        renderComponentCaseId: 2
    },

    '#global-locations': {
        id: '#global-locations',
        screenTitle: 'Global Locations',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/' },
        ],
        renderComponentCaseId: 3
    },
    '#global-locations#1': {
        id: '#global-locations',
        screenTitle: 'Global Locations',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/' },
        ],
        renderComponentCaseId: 3
    },
    '#global-locations#2': {
        id: '#global-locations',
        screenTitle: 'Global Locations',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/' },
        ],
        renderComponentCaseId: 3
    },
    '#global-locations#3': {
        id: '#global-locations',
        screenTitle: 'Global Locations',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/' },
        ],
        renderComponentCaseId: 3
    },

    '#key-contacts': {
        id: '#key-contacts',
        screenTitle: 'Key Contacts',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Key Contacts', name: 'Key Contacts', hrefPath: '/' },
        ],
        renderComponentCaseId: 4
    },
    '#medical-information': {
        id: '#medical-information',
        screenTitle: 'Medical Information',
        breadCrumbData: [
            { id: 'breadCrumbData:Contact Us', name: 'Contact Us', hrefPath: '/contact-us' },
            { id: 'breadCrumbData:Medical Information', name: 'Medical Information', hrefPath: '/' },
        ],
        renderComponentCaseId: 5
    },

    '#prospective-partners': {
        id: '#prospective-partners',
        screenTitle: 'Prospective Partners',
        breadCrumbData: [
            { id: 'breadCrumbData:Business Partners', name: 'Business Partners', hrefPath: '/business-partner' },
            { id: 'breadCrumbData:Prospective Partners', name: 'Prospective Partners', hrefPath: '/' },
        ],
        renderComponentCaseId: 6
    },
    '#post-an-enquiry': {
        id: '#post-an-enquiry',
        screenTitle: 'Post An Enquiry',
        breadCrumbData: [
            { id: 'breadCrumbData:Business Partners', name: 'Business Partners', hrefPath: '/business-partner' },
            { id: 'breadCrumbData:Post An Enquiry', name: 'Post An Enquiry', hrefPath: '/' },
        ],
        renderComponentCaseId: 7
    },
    '#login-for-current-partner': {
        id: '#login-for-current-partner',
        screenTitle: 'Login for Current Partners',
        breadCrumbData: [
            { id: 'breadCrumbData:Business Partners', name: 'Business Partners', hrefPath: '/business-partner' },
            { id: 'breadCrumbData:Login for Current Partners', name: 'Login for Current Partners', hrefPath: '/' },
        ],
        renderComponentCaseId: 8
    },

    '#annual-report': {
        id: '#annual-report',
        screenTitle: 'Annual Report',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:Annual Report', name: 'Annual Report', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },
    '#quarterly-reports': {
        id: '#quarterly-reports',
        screenTitle: 'Quarterly reports',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:Quarterly reports', name: 'Quarterly reports', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },
    '#sec-filing': {
        id: '#sec-filing',
        screenTitle: 'SEC Filing',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:SEC Filing', name: 'SEC Filing', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },
    '#moa-and-aoa': {
        id: '#moa-and-aoa',
        screenTitle: 'MoA and AoA',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:MoA and AoA', name: 'MoA and AoA', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },
    '#annual-general-meeting': {
        id: '#annual-general-meeting',
        screenTitle: '37th Annual General Meeting',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:37th Annual General Meeting', name: '37th Annual General Meeting', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },
    '#sustainability-reports': {
        id: '#sustainability-reports',
        screenTitle: 'Sustainability Reports',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Reports & Filing', name: 'Reports & Filing', hrefPath: '/investor#reports-and-filing' },
            { id: 'breadCrumbData:Sustainability Reports', name: 'Sustainability Reports', hrefPath: '/' },
        ],
        renderComponentCaseId: 9
    },

    '#earnings-call': {
        id: '#earnings-call',
        screenTitle: 'Earnings Call',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Meet', name: 'Investor Meet', hrefPath: '/investor#investor-meet' },
            { id: 'breadCrumbData:Earnings Call', name: 'Earnings Call', hrefPath: '/' },
        ],
        renderComponentCaseId: 10
    },
    '#investor-decks': {
        id: '#investor-decks',
        screenTitle: 'Investor Decks',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Meet', name: 'Investor Meet', hrefPath: '/investor#investor-meet' },
            { id: 'breadCrumbData:Investor Decks', name: 'Investor Decks', hrefPath: '/' },
        ],
        renderComponentCaseId: 10
    },
    '#investor-meeting-details': {
        id: '#investor-meeting-details',
        screenTitle: 'Investor Meeting Details',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Meet', name: 'Investor Meet', hrefPath: '/investor#investor-meet' },
            { id: 'breadCrumbData:Investor Meeting Details', name: 'Investor Meeting Details', hrefPath: '/' },
        ],
        renderComponentCaseId: 10
    },
    '#investor-day-2022': {
        id: '#investor-day-2022',
        screenTitle: 'Investor Day 2022',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Meet', name: 'Investor Meet', hrefPath: '/investor#investor-meet' },
            { id: 'breadCrumbData:Investor Day 2022', name: 'Investor Day 2022', hrefPath: '/' },
        ],
        renderComponentCaseId: 10
    },

    '#share-price': {
        id: '#share-price',
        screenTitle: 'Share Price',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Share Price', name: 'Share Price', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#equity-and-divident-history': {
        id: '#equity-and-divident-history',
        screenTitle: 'Equity & Dividend History',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Equity & Dividend History', name: 'Equity & Dividend History', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#unpaid-and-unclaimed-divident': {
        id: '#unpaid-and-unclaimed-divident',
        screenTitle: 'Unpaid & Unclaimed Dividend',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Unpaid & Unclaimed Dividend', name: 'Unpaid & Unclaimed Dividend', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#analyst-coverage': {
        id: '#analyst-coverage',
        screenTitle: 'Analyst Coverage',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Analyst Coverage', name: 'Analyst Coverage', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#shareholding-pattern': {
        id: '#shareholding-pattern',
        screenTitle: 'Shareholding Pattern',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Shareholding Pattern', name: 'Shareholding Pattern', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#share-transfer-information': {
        id: '#share-transfer-information',
        screenTitle: 'Share Transfer Information',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Share Transfer Information', name: 'Share Transfer Information', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#buyback': {
        id: '#buyback',
        screenTitle: 'Buyback',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:Buyback', name: 'Buyback', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    '#details-of-shares': {
        id: '#details-of-shares',
        screenTitle: 'IEPF Details',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Shares', hrefPath: '/investor#shares' },
            { id: 'breadCrumbData:IEPF Details', name: 'IEPF Details', hrefPath: '/' },
        ],
        renderComponentCaseId: 11
    },
    
    '#internal-control-systems': {
        id: '#internal-control-systems',
        screenTitle: 'Internal Control Systems',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Internal Control Systems', name: 'Internal Control Systems', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#committees-of-the-board': {
        id: '#committees-of-the-board',
        screenTitle: 'Committees of the Board',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Committees of the Board', name: 'Committees of the Board', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#code-of-business-conduct-and-ethics': {
        id: '#code-of-business-conduct-and-ethics',
        screenTitle: 'Code of Business Conduct and Ethics (COBE)',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Code of Business Conduct and Ethics (COBE)', name: 'Code of Business Conduct and Ethics (COBE)', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#ombudsperson-policy': {
        id: '#ombudsperson-policy',
        screenTitle: 'Ombudsperson Policy',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Ombudsperson Policy', name: 'Ombudsperson Policy', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#compliance-with-nyse': {
        id: '#compliance-with-nyse',
        screenTitle: 'Compliance with NYSE',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Compliance with NYSE', name: 'Compliance with NYSE', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#information-to-stakeholders': {
        id: '#information-to-stakeholders',
        screenTitle: 'Information to Stakeholders',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Information to Stakeholders', name: 'Information to Stakeholders', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#corporate-governance-reports': {
        id: '#corporate-governance-reports',
        screenTitle: 'Corporate Governance Reports',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Corporate Governance Reports', name: 'Corporate Governance Reports', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },
    '#policies-and-documents': {
        id: '#policies-and-documents',
        screenTitle: 'Policies and Documents',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Shares', name: 'Governance', hrefPath: '/investor#governance' },
            { id: 'breadCrumbData:Policies and Documents', name: 'Policies and Documents', hrefPath: '/' },
        ],
        renderComponentCaseId: 12
    },

    '#amalgamation': {
        id: '#amalgamation',
        screenTitle: 'Amalgamation',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Amalgamation', name: 'Amalgamation', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#statutory-communication': {
        id: '#statutory-communication',
        screenTitle: 'Statutory Communication',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Statutory Communication', name: 'Statutory Communication', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#shareholders-contacts': {
        id: '#shareholders-contacts',
        screenTitle: 'Shareholder’s Contacts',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Shareholder’s Contacts', name: 'Shareholder’s Contacts', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#shareholder-information': {
        id: '#shareholder-information',
        screenTitle: 'Shareholder Information',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Shareholder Information', name: 'Shareholder Information', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#investor-handbook': {
        id: '#investor-handbook',
        screenTitle: 'Investor Handbook',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Investor Handbook', name: 'Investor Handbook', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#other-stock-exchange-intimations': {
        id: '#other-stock-exchange-intimations',
        screenTitle: 'Other Stock Exchange Intimations',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Other Stock Exchange Intimations', name: 'Other Stock Exchange Intimations', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
    '#credit-rating': {
        id: '#credit-rating',
        screenTitle: 'Credit Rating',
        breadCrumbData: [
            { id: 'breadCrumbData:Investors', name: 'Investors', hrefPath: '/investor' },
            { id: 'breadCrumbData:Investor Services', name: 'Investor Services', hrefPath: '/investor#investor-services' },
            { id: 'breadCrumbData:Credit Rating', name: 'Credit Rating', hrefPath: '/' },
        ],
        renderComponentCaseId: 13
    },
}

const GetSubTabData = {
    '#annual-report': { id: 1, key: 'subMenuTabData1_1', name: 'Annual Report', bgColor: '#fff', hashValue: 'annual-report', },
    '#quarterly-reports': { id: 2, key: 'subMenuTabData1_2', name: 'Quarterly reports', bgColor: '#fff', hashValue: 'quarterly-reports', },
    '#sec-filing': { id: 3, key: 'subMenuTabData1_3', name: 'SEC Filing', bgColor: '#fff', hashValue: 'sec-filing', },
    '#moa-and-aoa': { id: 4, key: 'subMenuTabData1_4', name: 'MoA and AoA', bgColor: '#fff', hashValue: 'moa-and-aoa', },
    '#annual-general-meeting': { id: 5, key: 'subMenuTabData1_5', name: '37th Annual General Meeting', bgColor: '#fff', hashValue: 'annual-general-meeting', },
    '#sustainability-reports': { id: 6, key: 'subMenuTabData1_6', name: 'Sustainability Reports', bgColor: '#fff', hashValue: 'sustainability-reports', },

    '#earnings-call': { id: 1, key: 'subMenuTabData2_1', name: 'Earnings Call', bgColor: '#fff', hashValue: 'earnings-call', },
    '#investor-decks': { id: 2, key: 'subMenuTabData2_2', name: 'Investor Decks', bgColor: '#fff', hashValue: 'investor-decks', },
    '#investor-meeting-details': { id: 3, key: 'subMenuTabData3_3', name: 'Investor Meeting Details', bgColor: '#fff', hashValue: 'investor-meeting-details', },

    '#share-price': { id: 1, key: 'subMenuTabData3_1', name: 'Share Price', bgColor: '#fff', hashValue: 'share-price', },
    '#equity-and-divident-history': { id: 2, key: 'subMenuTabData3_2', name: 'Equity & Dividend History', bgColor: '#fff', hashValue: 'equity-and-divident-history', },
    '#unpaid-and-unclaimed-divident': { id: 3, key: 'subMenuTabData3_3', name: 'Unpaid & Unclaimed Dividend', bgColor: '#fff', hashValue: 'unpaid-and-unclaimed-divident', },
    '#analyst-coverage': { id: 4, key: 'subMenuTabData3_4', name: 'Analyst Coverage', bgColor: '#fff', hashValue: 'analyst-coverage', },
    '#shareholding-pattern': { id: 5, key: 'subMenuTabData3_5', name: 'Shareholding Pattern', bgColor: '#fff', hashValue: 'shareholding-pattern', },
    '#share-transfer-information': { id: 6, key: 'subMenuTabData3_6', name: 'Share Transfer Information', bgColor: '#fff', hashValue: 'share-transfer-information', },
    '#buyback': { id: 7, key: 'subMenuTabData3_7', name: 'Buyback', bgColor: '#fff', hashValue: 'buyback', },
    '#details-of-shares': { id: 8, key: 'subMenuTabData3_8', name: 'IEPF Details', bgColor: '#fff', hashValue: 'details-of-shares', },

    '#internal-control-systems': { id: 1, key: 'subMenuTabData4_1', name: 'Internal Control Systems', bgColor: '#fff', hashValue: 'internal-control-systems', },
    '#committees-of-the-board': { id: 2, key: 'subMenuTabData4_2', name: 'Committees of the Board', bgColor: '#fff', hashValue: 'committees-of-the-board', },
    '#code-of-business-conduct-and-ethics': { id: 3, key: 'subMenuTabData4_3', name: 'Code of Business Conduct and Ethics (COBE)', bgColor: '#fff', hashValue: 'code-of-business-conduct-and-ethics', },
    '#ombudsperson-policy': { id: 5, key: 'subMenuTabData4_5', name: 'Ombudsperson Policy', bgColor: '#fff', hashValue: 'ombudsperson-policy', },
    '#compliance-with-nyse': { id: 7, key: 'subMenuTabData4_7', name: 'Compliance with NYSE', bgColor: '#fff', hashValue: 'compliance-with-nyse', },
    '#information-to-stakeholders': { id: 8, key: 'subMenuTabData4_8', name: 'Information to Stakeholders', bgColor: '#fff', hashValue: 'information-to-stakeholders', },
    '#corporate-governance-reports': { id: 9, key: 'subMenuTabData4_9', name: 'Corporate Governance Reports', bgColor: '#fff', hashValue: 'corporate-governance-reports', },
    '#policies-and-documents': { id: 10, key: 'subMenuTabData4_10', name: 'Policies and Documents', bgColor: '#fff', hashValue: 'policies-and-documents', },

    '#amalgamation': { id: 1, key: 'subMenuTabData5_1', name: 'Amalgamation', bgColor: '#fff', hashValue: 'amalgamation', },
    '#statutory-communication': { id: 2, key: 'subMenuTabData5_2', name: 'Statutory Communication', bgColor: '#fff', hashValue: 'statutory-communication', },
    '#shareholders-contacts': { id: 3, key: 'subMenuTabData5_3', name: 'Shareholder’s Contacts', bgColor: '#fff', hashValue: 'shareholders-contacts', },
    '#shareholder-information': { id: 4, key: 'subMenuTabData5_4', name: 'Shareholder Information', bgColor: '#EDEDED', hashValue: 'shareholder-information', },
    '#investor-handbook': { id: 6, key: 'subMenuTabData5_6', name: 'Investor Handbook', bgColor: '#fff', hashValue: 'investor-handbook', },
    '#other-stock-exchange-intimations': { id: 7, key: 'subMenuTabData5_7', name: 'Other Stock Exchange Intimations', bgColor: '#fff', hashValue: 'other-stock-exchange-intimations', },
    '#credit-rating': { id: 8, key: 'subMenuTabData5_8', name: 'Credit Rating', bgColor: '#fff', hashValue: 'credit-rating', },
    '#complaints': { id: 8, key: 'subMenuTabData5_9', name: 'Complaints', bgColor: '#fff', hashValue: 'complaints', },
};

class DetailSubMenuPage extends React.Component {

    state = {
        renderPageBool: false,
        cookieBool: true,
        hashKey: this.props.location.hash,
        selectedSubTab: GetSubTabData.hasOwnProperty(this.props.location.hash) ? GetSubTabData[this.props.location.hash] : {},
    }

    async componentWillMount() {

    }

    componentDidMount() {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e265c96c1fde14'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

                return next
            })
            if (active.lastOne) {
                this.setState({ renderPageBool: true })
            }
            return active.isAsync ? next : loading
        }))
    }

    renderMainComponents = () => {
        switch (HashData[this.state.hashKey].renderComponentCaseId) {
            case 1:
                return <PressRelease state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 2:
                return <MediaKit state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 3:
                return <GlobalLocationView state={this.state} updateState={this.setState} />;
            case 4:
                return <KeyContactsView state={this.state} updateState={this.setState} />;
            case 5:
                return <MedicalInformationView state={this.state} updateState={this.setState} />;
            case 6:
                return <ProspectivePartnersView state={this.state} updateState={this.updateState} />;
            case 7:
                return <PostAnEnquiryView state={this.state} updateState={this.updateState} />;
            case 8:
                return <LoginForCurrentPartnerView state={this.state} updateState={this.updateState} />;
            case 9:
                return <ReportsAndFillingView state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 10:
                return <PresentationsView state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 11:
                return <SharesView state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 12:
                return <GovernanceView state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 13:
                return <InvestorServiceView state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            case 14:
                return <Presentations state={this.state} updateState={(changeState) => this.setState(changeState)} />;
            default:
                return <p></p>;
        }
    }

    render() {

        const { cookies } = this.props;
        const { cookieBool } = this.state;
        var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };


        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
                {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
                <span id="Navbar" className="af-view">
                    <div>
                        <MenuSection backButton={true} />
                        <div id="back-to-top" className="af-class-section" style={{ marginBottom: (this.state.hashKey !== '#shareholder-information' || this.state.hashKey !== '#post-an-enquiry') && isTab ? '0px' : '10px' }} >
                            <div className="af-class-container">
                                <div style={{ marginTop: '40px' }} >
                                    {HashData.hasOwnProperty(this.state.hashKey) && HashData[this.state.hashKey].breadCrumbData.map((item, index) => {
                                        return <a onClick={(ev) => {
                                            if (HashData[this.state.hashKey].breadCrumbData.length - 1 === index) {
                                                ev.preventDefault();
                                            }
                                        }} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: HashData[this.state.hashKey].breadCrumbData.length - 1 === index ? "bold" : '300', color: HashData[this.state.hashKey].breadCrumbData.length - 1 === index ? '#4B2EAE' : '#909090' }} href={item.hrefPath} >{item.name} {HashData[this.state.hashKey].breadCrumbData.length - 1 === index ? "" : " / "}</a>
                                    })}
                                </div>
                                <div style={{ backgroundColor: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') ? "#ededed" : 'white', width: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? 'calc(calc(100% + 10vw + 10vw))' : 'calc(calc(100% + 20px + 20px))', marginLeft: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? '-10vw' : '-20px', marginRight: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? '-10vw' : '-20px', padding: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? '0 10vw' : '0 20px', paddingTop: '20px', marginTop: '20px', marginBottom: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? '-15vw' : '-70px', paddingBottom: (this.state.hashKey === '#shareholder-information' || this.state.hashKey === '#post-an-enquiry') && isTab ? '10vw' : '40px' }} >
                                    {HashData.hasOwnProperty(this.state.hashKey) && <div style={{ fontSize: '36px', lineHeight: '48px', marginBottom: '50px', textAlign: 'center' }} >{HashData[this.state.hashKey].screenTitle}</div>}
                                    {this.renderMainComponents()}
                                </div>
                            </div>
                        </div>
                        {(this.state.hashKey === "#key-contacts") && <div className="af-class-section" style={{ backgroundColor: '#EDEDED' }} >
                            <div className="af-class-container">
                                <Heading>Post an Enquiry</Heading>
                                {
            window.matchMedia('(max-width: 820px)').matches
              ?
              <div style={{ borderTopColor: "#ff5046", borderTopWidth: '1px', borderTopStyle: 'solid', marginBottom: '40px' }} ></div>
              :
              <HR />
          }
                                <EnquiryForm />
                            </div>
                        </div>
                        }
                        <div className="af-class-section af-class-knowledge-bank" style={{ padding: 0, margin: 0 }} >
              <a href="#back-to-top" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
                        {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
                            const { cookies } = this.props;
                            cookies.set('EnableCookie', JSON.stringify(data));
                        }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
                        <FooterSection />
                        {/* [if lte IE 9]><![endif] */}
                    </div>
                </span>
            </span>
        )
    }
}

export default withCookies(DetailSubMenuPage)

/* eslint-enable */