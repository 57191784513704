import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../config";
import { downloadFile } from "../../../../Utils/Utils";
import { ViewFile } from "../../../../Utils/view";
import { Dropdown } from "../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import { SortMonthNames } from '../../../../Utils/Utils';

import { InternalControlSystems } from './SubModules/InternalControlSystems';
import { CommitteesOfTheBoard } from './SubModules/CommitteesOfTheBoard';
import { CodeOfBusinessConductAndEthics } from './SubModules/CodeOfBusinessConductAndEthics';
import { OmbudspersonPolicy } from './SubModules/OmbudspersonPolicy';
import { ComplianceWithNyse } from './SubModules/ComplianceWithNyse';
import { InformationToStakeholders } from './SubModules/InformationToStakeholders';
import { CorporateGovernanceReports } from './SubModules/CorporateGovernanceReports';
import { PoliciesAndDocuments } from './SubModules/PoliciesAndDocuments';
import { Certificates } from "./SubModules/Certificates";
import { SocialMediaCommunityGuidelines } from "./SubModules/SocialMediaCommunityGuidelines";

const Main = styled.div`
  width: 100%;
  min-height: 50vh;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  @media (max-width: 900px) {
    padding: 10%;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  @media (max-width: 1030px) {
    font-size: 12px;
    width: 40%;
  }
`;
const Text = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  background-color: #f5f569;
  padding: 20px;
`;
const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBook = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;
const TextClick = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 750px) {
    font-size: 12px;
    margin-bottom: 25px;
  }
`;
const CGRSection = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
`;

const LabelValue = styled.p`
  font-size: 22px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
`;

const ReportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    margin-bottom: 15px;
    font-size: 12px;
  }
`;

const ReportHeading = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
  width: 15%;
  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
  }
`;

const ICSSection = styled.div`
  width: 90%;
`;

const content = styled.p`
  display: flex;
  flex-direction: column;
`;

export function GovernanceView(props) {

    const action = (e) => {
        e.preventDefault();
        console.log("The link was clicked.");
    };

    const renderSubMenuContent = () => {
        switch (props.state.selectedSubTab.id) {
            case 1:
                return <InternalControlSystems state={props.state} updateState={(changeState) => props.setState(changeState)}/>;
            case 2:
                return <CommitteesOfTheBoard state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 3:
                return <CodeOfBusinessConductAndEthics state={props.state} updateState={(changeState) => props.setState(changeState)}/>;
            case 5:
                return <OmbudspersonPolicy state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 7:
                return <ComplianceWithNyse state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 8:
                return <InformationToStakeholders state={props.state} updateState={(changeState) => props.setState(changeState)}/>;
            case 9:
                return <CorporateGovernanceReports state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 10:
                return <PoliciesAndDocuments state={props.state} updateState={(changeState) => props.setState(changeState)} />;
            case 12:
                return <Certificates state={props.state} updateState={(changeState) => props.setState(changeState)} />
            case 13:
                return <SocialMediaCommunityGuidelines state={props.state} updateState={(changeState) => props.setState(changeState)} />
            default:
                return <p>{props.state.selectedSubTab.name}</p>;
        }
    }

    return (
        <Main>

            {renderSubMenuContent()}

        </Main>
    );
}
