/* eslint-disable */

import React from 'react'

import axios from 'axios';

import FooterSection from '../Common/FooterSection'
import MenuSection from '../Common/MenuSection'

import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';
import styled from 'styled-components';

import Utils, { makeHashKeyFromName } from '../Utils/Utils';

import { HR } from "./common/horizontalLine";

import ManufacturingMarkers from './ContactUs/MainSection/StaticLocations/ManufacturingMarkers';
import RAndDMarkers from './ContactUs/MainSection/StaticLocations/RAndDMarkers';
import globalMarkers from './ContactUs/MainSection/StaticLocations/GlobalMarkers';

const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
`;

let Controller

const AnchorTag = styled.a`
    &:hover{
      color: #000;
      text-decoration: underline !important;
  }
`;

const DefBreadCrumbDataForGlobalMarkers = [
    { id: 'breadCrumbData:Contact us', name: 'Contact us', hrefPath: '/contact-us' },
    { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/detail-sub-menu#global-locations' },
    { id: 'breadCrumbData:Global offices', name: 'Global offices', hrefPath: '/detail-sub-menu#global-locations#1' },
];
const DefBreadCrumbDataForRAndDMarkers = [
    { id: 'breadCrumbData:Contact us', name: 'Contact us', hrefPath: '/contact-us' },
    { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/detail-sub-menu#global-locations' },
    { id: 'breadCrumbData:R&D Locations', name: 'R&D Locations', hrefPath: '/detail-sub-menu#global-locations#2' },
]
const DefBreadCrumbDataForManufacturingMarkers = [
    { id: 'breadCrumbData:Contact us', name: 'Contact us', hrefPath: '/contact-us' },
    { id: 'breadCrumbData:Global Locations', name: 'Global Locations', hrefPath: '/detail-sub-menu#global-locations' },
    { id: 'breadCrumbData:Manufacturing locations', name: 'Manufacturing locations', hrefPath: '/detail-sub-menu#global-locations#3' },
]

const globalMarkers1 = [
    {
        markerOffset: -15,
        name: "INDIA",
        mName: "India",
        hashName: 'in',
        coordinates: [78.44351609220966, 17.492441287638922],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:India', name: 'India', hrefPath: '/' },
        ],
        show: true,
        tooltipData: {
            title: "INDIA",
            address: [
                { text: "Corporate Office<br/>Dr. Reddy’s Laboratories Ltd.<br/>8-2-337, Road No. 3,<br/>Banjara Hills, Hyderabad<br/>Telangana 500034, INDIA", telNo: '+91 4049002900' },
                { text: "API Hyderabad Plant 1<br/> Plot No. 137 & 138 IDA Bollaram,<br/> Jinnaram Manglobaladddal Medak District Telangana,<br/> 502 325. INDIA", telNo: "+91 8458283200" },
                { text: "API Hyderabad Plant 2<br/> Dr. Reddy’s Laboratories Limited,<br/> Chemical Technical operations,<br/> Unit - II, Plot No. 1, 75A, 75B, 105, 110, 111 & 112, Sri Venkateswara Co-operative Industrial Estate,<br/> Bollaram, Jinnaram (Mandal),Sangareddy District,<br/> Telangana, India, <br/>Pin Code: - 502325." },
                { text: "API Hyderabad 3 <br/>Plot No. 116, 116A & 126C & SY No. 157 IDA Bollaram,<br/> Jinnaram Mandal Medak District <br/>Telangana 502 325.<br/> INDIA", telNo: '+91 8458283700' },
                { text: "API Nalgonda Plant Peddadevulapally<br/> Tripuraram Mandal Nalgonda District<br/> Telangana 508 207.<br/> INDIA ", telNo: '+91 8689253150' },
                { text: "API Srikakulam Plant IDA Pydibheemavaram<br/> Ransthal Mandal Srikakulam District<br/> Andhra Pradesh 532 409.<br/> INDIA ", telNo: "+91 8942288131" },
                { text: "API Srikakulam Plant (SEZ) <br/>Sector No. 28 & 34 Devunipalavalasa Village<br/> Ranastalam Mandal Srikakulam District <br/>Andhra Pradesh 532 409.<br/> INDIA ", telNo: '+91 8942304141' },
                { text: "Formulations Hyderabad Plant 1<br/>Plot No. 146<br/>IDA Bollaram, Jinnaram Mandal<br/>Medak District<br/>Telangana 502 320. INDIA", telNo: '+91 8458279669' },
                { text: "Formulations Hyderabad Plant 2<br/>S Y No. 42, 45, 46 & 54<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana, 500090. INDIA", telNo: '+91 4044642200' },
                { text: "Formulations Hyderabad Plant 3<br/>S Y No. 41<br/>Bachupally, Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500090. INDIA", telNo: '+91 4044643440' },
                { text: "Formulations Baddi Plant 1<br/>Khol, Nalagarh<br/>Solan District, Nalagarh Road<br/>Baddi, Himachal Pradesh 173 205. INDIA", telNo: '+91 179556001' },
                { text: "Formulations Baddi Plant 2<br/>Village Mauja Thana<br/>Nalagarh Baddi Road, Baddi, Solan District<br/>Himachal Pradesh 173 205. INDIA", telNo: '+91 1795650761' },
                { text: "Formulations Vizag SEZ Plant 1<br/>Plot No. P1-P9, Phase III<br/>Duvvada, VSEZ, Visakapatanam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913013400' },
                { text: "Formulations Vizag SEZ Plant 2<br/>Plot No. Q1 to Q5, Phase III<br/>Duvvada, VSEZ, Visakhapatnam<br/>Andhra Pradesh 530 046. INDIA", telNo: '+91 8913013400' },
                { text: "Formulations Srikakulam SEZ Plant<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942200046' },
                { text: "Formulations Srikakulam SEZ Plant Unit II<br/>Sector No. 9-13 & 17-20<br/>Devunipalavalasa Village<br/>Ranastalam Mandal<br/>Srikakulam District<br/>Andhra Pradesh 532 409. INDIA", telNo: '+91 8942304300' },
                { text: "Biologics<br/>Survey No. 47, Bachupally Village<br/>Qutubullapur Mandal, Ranga Reddy District<br/>Andhra Pradesh 500090. INDIA" },
                { text: "Integrated Product Development Organization (IPDO)<br/>Bachupally Village<br/>Qutubullapur Mandal<br/>Ranga Reddy District<br/>Telangana 500 123. INDIA", telNo: '+91 4044346200' },
                { text: "IPDO, BANGALORE<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA", telNo: '+91 8071025444' },
                { text: "Aurigene Discovery Technologies Limited<br/>39-40, KIADB Industrial Area<br/>Electronic City Phase II, Hosur Road,<br/>Bangalore 560 100. INDIA" },
                { text: "ADTL, HYDERABAD<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049. INDIA" },
                { text: "Technology Development Center I<br/>Bollaram Road, Miyapur<br/>Hyderabad 500 049, INDIA" },
                { text: "Technology Development Center II<br/>Plot 31A IDA,<br/>Jeedimetla<br/>Hyderabad 500 050, INDIA", telNo: '+91 4023095272' },
            ]
        }
    },
    {
        markerOffset: -15,
        name: "SRI LANKA",
        mName: "Sri Lanka",
        hashName: 'sl',
        coordinates: [80.944590, 7.546888],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Sri Lanka', name: 'Sri Lanka', hrefPath: '/' },
        ],
        tooltipData: {
            title: "SRI LANKA",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>No.60, Maligawatta Road,<br/>Colombo -10<br/>Sri Lanka", telNo: '009411 2676436' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "UNITED KINGDOM",
        mName: "United Kingdom",
        hashName: 'uk',
        coordinates: [-3.585901210996002, 55.92909145687116],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:United Kingdom', name: 'United Kingdom', hrefPath: '/' },
        ],
        tooltipData: {
            title: "UNITED KINGDOM",
            address: [
                { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6 Riverview Road,<br/>Beverley, East Yorkshire HU17 0LD<br/>UNITED KINGDOM", telNo: '+44(0)1223 728010' },
                { text: "Dr. Reddy's Laboratories (UK) Limited<br/>6, Riverview Road<br/>Beverly, East Yorkshire<br/>HU 17 OLD<br/>UNITED KINGDOM" },
                { text: "Dr. Reddy's Laboratories (EU) Limited<br/>Steanard Lane<br/>Mirfield, West Yorkshire<br/>WF 14, 8HZ<br/>UNITED KINGDOM" },
                { text: "Chirotech Technology Limited<br/>410 Cambridge Science Park<br/>Milton Road, Cambridge<br/>CB4 0PE, UNITED KINGDOM" },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "USA",
        hashName: 'us',
        coordinates: [-74.66761139608748, 40.36021222873629],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:USA', name: 'USA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "USA",
            address: [
                { text: "Dr. Reddy's Laboratories Inc.<br/>107 College Road East<br/>Princeton, NJ 08540. USA<br/>www.drreddys.com/united-states", telNo: '+1 609 375 9900' },
                { text: "Dr. Reddy's Laboratories Louisiana LLC<br/>8800 Line Avenue<br/>Shreveport<br/>Louisiana 71106. USA", telNo: '+1 318861 8070' },
                { text: "Dr. Reddy's Laboratories New York Inc.<br/>1974 Route 145<br/>P.O. Box 500, Middleburgh<br/>New York 12122. USA", telNo: '+1 518 827 7702' },
                { text: "Technology Development Centre, Princeton<br/>303, College Road East<br/>Princeton, New Jersey 08540. USA" },
                { text: "Brunswick Research Center, Princeton<br/>2031 US Highway 130. Unit D<br/>Monmouth Junction<br/>New Jersey 08852. USA", telNo: '+1 732 422 7042' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "KAZAKHSTAN",
        hashName: 'kaz',
        coordinates: [66.61656149940534, 48.34646547613248],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:KAZAKHSTAN', name: 'KAZAKHSTAN', hrefPath: '/' },
        ],
        tooltipData: {
            title: "KAZAKHSTAN",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representation office<br/>Almaty, 22nd line, 45<br/>(crossing of Zhandosova-Rozubakaeva),<br/>2nd floor, cab. 10, KAZAKHSTAN", telNo: '+7 727 245 7179' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "UZBEKISTAN",
        hashName: 'uz',
        coordinates: [63.445564890942244, 42.09776265891074],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:UZBEKISTAN', name: 'UZBEKISTAN', hrefPath: '/' },
        ],
        tooltipData: {
            title: "UZBEKISTAN",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>Tashkent, Yunus Abadsky District,<br/>Niyazbek Yuli str. 1,<br/>5th floor, Room 10, UZBEKISTAN", telNo: '+998 71 235 59 33' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "RUSSIA",
        hashName: 'ru',
        coordinates: [92.83987121422074, 62.43880088628713],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:RUSSIA', name: 'RUSSIA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "RUSSIA",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>115035, 20, Building 1, Ovchinnikovskaya emb.,<br/>Business Centre Central City Tower,<br/>6th floor, Moscow, RUSSIA", telNo: '+7 495 783 29 01' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "JAPAN",
        hashName: 'ja',
        coordinates: [138.63280083336645, 36.87696011137428],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:JAPAN', name: 'JAPAN', hrefPath: '/' },
        ],
        tooltipData: {
            title: "JAPAN",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>3rd Floor, Metlife Kabutocho Building (New)<br/>5-1 Nihonbashi Kabutocho,<br/>Chuo-ku Tokyo 113-0026 JAPAN", telNo: '+81 35847 7928' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "SPAIN",
        hashName: 'sp',
        coordinates: [-3.391235689938627, 39.56497855774158],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:SPAIN', name: 'SPAIN', hrefPath: '/' },
        ],
        tooltipData: {
            title: "SPAIN",
            address: [
                { text: "Reddy Pharma Iberia<br/>S.A. Avenida Josep Tarradellas, 3808029<br/>Barcelona (España)<br/>Fax : +34 93 355 49 61", telNo: '+34 93 355 49 16' },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "FRANCE",
        hashName: 'fr',
        coordinates: [2.4415505883137696, 46.63157885776489],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:FRANCE', name: 'FRANCE', hrefPath: '/' },
        ],
        tooltipData: {
            title: "FRANCE",
            address: [
                { text: "Reddy Pharma SAS<br/>9 avenue Edouard Belin<br/>92500 Rueil-Malmaison<br/>Fax : +33 (0)1 47 51 38 26", telNo: '+33 (0) 1 85 78 1734', },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "ITALY",
        hashName: 'it',
        coordinates: [12.463017047732619, 43.06097215179718],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:ITALY', name: 'ITALY', hrefPath: '/' },
        ],
        tooltipData: {
            title: "ITALY",
            address: [
                { text: "Dr.Reddy’s Srl<br/>Via L.Settala, 20<br/>20124 MILANO (Italy)<br/>Fax : +39 02 73951592", telNo: "+39 02 70106808", },
            ]
        }
    },
    {
        markerOffset: -5,
        name: "SWITZERLAND",
        hashName: 'sw',
        coordinates: [7.433926327548433, 46.952559403766095],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:SWITZERLAND', name: 'SWITZERLAND', hrefPath: '/' },
        ],
        tooltipData: {
            title: "SWITZERLAND",
            address: [
                { text: "Dr. Reddy's Laboratories SA<br/>Elisabethenanlage 11<br/>CH-4051 Basel, SWITZERLAND", telNo: '+41 61 271 4754' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "GERMANY",
        hashName: 'ge',
        coordinates: [10.296970835111791, 51.08302214413828],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:GERMANY', name: 'GERMANY', hrefPath: '/' },
        ],
        tooltipData: {
            title: "GERMANY",
            address: [
                { text: "betapharm Arzneimittel GmbH.<br/>Kobelweg 95<br/>86156 Augsburg<br/>Germany", telNo: '+49 821-74881-0' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "COLOMBIA",
        hashName: 'col',
        coordinates: [-72.39904094668036, 3.360434451796628],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:COLOMBIA', name: 'COLOMBIA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "COLOMBIA",
            address: [
                { text: "Av. Cra 9 #113-52 Ofc 1701,<br/>Edf. Torres Unidas 2, Bogota,<br/>Colombia", telNo: '+57 1 6371524' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "JAMAICA",
        hashName: 'jm',
        coordinates: [-77.34495347738796, 18.139405768877598],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:JAMAICA', name: 'JAMAICA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "JAMAICA",
            address: [
                { text: "Dr. Reddy's Laboratories Jamaica Ltd. <br/> Ground Floor, ICWI Building <br/> 2 St Lucia Avenue, New Kingston, Kingston-5 <br/> Jamaica", telNo: '+1- 876 - 906 1692/906 7662' }
            ]
        }
    },
    {
        markerOffset: -5,
        name: "CANADA",
        hashName: 'canada',
        coordinates: [-111.49991657118608, 59.6938923450828],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:CANADA', name: 'CANADA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "CANADA",
            address: [
                { text: "Dr. Reddy's Laboratories Canada Inc.<br/>2425 Matheson Blvd. East, 7th Floor<br/>Mississauga, Ontario<br/>L4W 5K4", telNo: '289-201-2299' }
            ]
        }
    },


    {
        markerOffset: -5,
        name: "NEW ZEALAND",
        mName: 'New Zealand',
        hashName: 'nz',
        coordinates: [172.04249521437066, -42.84833541664011],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:NEW ZEALAND', name: 'NEW ZEALAND', hrefPath: '/' },
        ],
        tooltipData: {
            title: "NEW ZEALAND",
            address: [
                { text: "Dr. Reddy's New Zealand Ltd.<br/>Level 6, AMI Building, 63 Albert Street<br/>Auckland 1142, NEW ZEALAND", telNo: "+64 9 356 7000" },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "AUSTRALIA",
        mName: 'Australia',
        hashName: 'aus',
        coordinates: [132.95078847154568, -24.549767936138213],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:AUSTRALIA', name: 'AUSTRALIA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "AUSTRALIA",
            address: [
                { text: "Dr Reddy's Laboratories Australia Pty Ltd<br/>Suite 3.03, Level 3/390<br/>St Kilda Road, Melbourne Vic 3004, AUSTRALIA", telNo: '+61.383941888' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "MEXICO",
        mName: 'Mexico',
        hashName: 'mex',
        coordinates: [-102.16719899537985, 24.182522619180787],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:MEXICO', name: 'MEXICO', hrefPath: '/' },
        ],
        tooltipData: {
            title: "MEXICO",
            address: [
                { text: "Dr. Reddy's de Mexico<br/>Km 4.5 Carr. Fed. Cuernavaca-Cuautla,<br/>62578 CIVAC Jiutepec Morelos. MEXICO", telNo: '+52.777.329 3450' },
                { text: "API Cuernavaca Plant<br/>Industrias Quimicas Falcon De Mexico S.A. de C.V.<br/>Carretera Federal Cuernavaca-Cuautla<br/>KM 4.5 CIVAC, Jiutepec<br/>Morelos, Mexico 62578", },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "VENEZUELA",
        mName: 'Venezuela',
        hashName: 'ven',
        coordinates: [-66.43269335263254, 7.2039160157855875],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:VENEZUELA', name: 'VENEZUELA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "VENEZUELA",
            address: [
                { text: "Dr. Reddy's Venezuela C.A.<br/>Oficina 3A, Piso – 3<br/>Edif. Centro Empresarial Premium.<br/>Av. Orinoco, Urb. Las Mercedes<br/>Caracas – 1080 Venezuela", telNo: '0058212 994 25 68' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "BRAZIL",
        mName: 'Brazil',
        hashName: 'br',
        coordinates: [-55.537197315614385, -8.095278623580795],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:BRAZIL', name: 'BRAZIL', hrefPath: '/' },
        ],
        tooltipData: {
            title: "BRAZIL",
            address: [
                { text: "DR.REDDY´S FARMACÊUTICA DO BRASIL LTDA<br/>Av.Guido Caloi, 1.985 - Cj.11 - Jd.São Luis<br/>Cep.: 05802-140 - São Paulo - SP - Brasil", telNo: '(+55 11) 5894-2343 / 5894-2158' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "SOUTH AFRICA",
        mName: 'South Africa',
        hashName: 'sa',
        coordinates: [24.487927251304868, -31.042350005931578],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:SOUTH AFRICA', name: 'SOUTH AFRICA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "SOUTH AFRICA",
            address: [
                { text: "Dr. Reddy's Laboratories (Pty) Ltd<br/>South Wing, The Place, 1 Sandton Drive<br/>Sandton, 2196<br/>P O Box 784746, Sandton City, Sandton, 2146<br/>www.drreddys.co.za", telNo: '+27 11 783 0104' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "BELARUS",
        mName: 'Belarus',
        hashName: 'be',
        coordinates: [27.99716265730534, 53.161439724214326],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:BELARUS', name: 'BELARUS', hrefPath: '/' },
        ],
        tooltipData: {
            title: "BELARUS",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Representative office<br/>V. Khoruzey Street 22, Office 32,<br/>Minsk, 220123, BELARUS", telNo: '+375 17 283 1724' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "UKRAINE",
        mName: 'Ukraine',
        hashName: 'ukr',
        coordinates: [31.06447364087334, 48.989305609228964],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:UKRAINE', name: 'UKRAINE', hrefPath: '/' },
        ],
        tooltipData: {
            title: "UKRAINE",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Europa Business Centre, Office 11B,<br/>Stolichnoye Shosse, 103<br/>Kyiv 03131 UKRAINE<br/>Fax: +38 (044) 07-51-96", telNo: '+38 (044) 207-51-97/98' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "VIETNAM",
        mName: 'Vietnam',
        hashName: 'vi',
        coordinates: [107.80725036324665, 14.623852373747498],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:VIETNAM', name: 'VIETNAM', hrefPath: '/' },
        ],
        tooltipData: {
            title: "VIETNAM",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Room No. 10 , M Floor,<br/>E town Building No.1,<br/>364 Cong Hoa St, Ward -13,<br/>Tan Binh District,<br/>Ho Chi Minh City<br/>Vietnam<br/>Fax: (+84) 3 8125691", telNo: '(+84) 3 8120545 – Ext 218/219' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "ROMANIA",
        mName: 'Romania',
        hashName: 'rom',
        coordinates: [24.776718693931276, 45.805195928194],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:ROMANIA', name: 'ROMANIA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "ROMANIA",
            address: [
                { text: "Dr. Reddy's Laboratories Romania SRL<br/>Nicolae Caramfil st, No. 71 - 73, 5th floor,<br/>Bucharest 1<br/>Fax: +40 21 224 0032<br/>E-mail: office@drreddys.ro", telNo: '+40 21 224 0032' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "MYANMAR",
        mName: "Myanmar",
        hashName: 'my',
        coordinates: [96.16241099986688, 21.83015031554986],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:MYANMAR', name: 'MYANMAR', hrefPath: '/' },
        ],
        tooltipData: {
            title: "MYANMAR",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>F-01 to F-05, Block-A<br/>Pearl Condominium<br/>Kabar Aye Pagoda Road<br/>Yangon, Myanmar", telNo: '+95-1-557477 & 557448' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "NETHERLANDS",
        mName: "Netherlands",
        hashName: 'ne',
        coordinates: [5.409965522017349, 52.164787278841196],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:NETHERLANDS', name: 'NETHERLANDS', hrefPath: '/' },
        ],
        tooltipData: {
            title: "NETHERLANDS",
            address: [
                { text: "Dr. Reddy’s Research & Development B.V.<br/>Zernikedreef 12, 2333 CL Leiden,<br/>The Netherlands", telNo: '' },
            ]
        },
    },
    {
        markerOffset: -5,
        name: "CHINA",
        mName: "China",
        hashName: 'ch',
        coordinates: [102.84413692391112, 35.13746209622783],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:CHINA', name: 'CHINA', hrefPath: '/' },
        ],
        tooltipData: {
            title: "CHINA",
            address: [
                { text: "Dr. Reddy's Laboratories Ltd.<br/>Beijing Representative Office<br/>Room 1509-1515, Tower A, Gemdale Center,<br/>No. 91, Jianguo Road Chaoyang District 100026<br/>Beijing, P.R. CHINA", telNo: '+86 10 6562 3000/ 6562 3001' },
                { text: "Kunshan Rotam Reddy Pharmaceutical Co. Limited<br/>No.258, Huang Pu Jiang (M) Road<br/>Kunshan Development Zone, Jiangsu Province 215 300<br/>P.R. CHINA<br/>www.rotamreddy.com" },
            ]
        },
    },
    {
        markerOffset: -15,
        name: "Algeria",
        mName: "algeria",
        hashName: 'al',
        coordinates: [1.5828550717739438, 28.478093864671905],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Algeria', name: 'Algeria', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Algeria",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Austria",
        mName: "austria",
        hashName: 'at',
        coordinates: [14.682642130810335, 47.540255454222205],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Austria', name: 'Austria', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Austria",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Bahrain",
        mName: "bahrain",
        hashName: 'bh',
        coordinates: [50.53230986710154, 26.006125413921207],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Bahrain', name: 'Bahrain', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Bahrain",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Bangladesh",
        mName: "Bangladesh",
        hashName: 'bn',
        coordinates: [89.98923959058244, 23.94026822451018],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Bangladesh', name: 'Bangladesh', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Bangladesh",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Barbados",
        mName: "Barbados",
        hashName: 'ba',
        coordinates: [-59.57235641963852, 13.182107137869405],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Barbados', name: 'Barbados', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Barbados",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Cambodia",
        mName: "Cambodia",
        hashName: 'cm',
        coordinates: [105.23780536003257, 12.658690837130374],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Cambodia', name: 'Cambodia', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Cambodia",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Chile",
        mName: "Chile",
        hashName: 'cl',
        coordinates: [-69.99798638839374, -26.665710340383843],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Chile', name: 'Chile', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Chile",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Czech Republic",
        mName: "Czech Republic",
        hashName: 'cz',
        coordinates: [14.988321065329636, 49.63166426190726],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Czech Republic', name: 'Czech Republic', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Czech Republic",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Dominican Republic",
        mName: "Dominican Republic",
        hashName: 'dr',
        coordinates: [-70.1315128300175, 18.935696309152508],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Dominican Republic', name: 'Dominican Republic', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Dominican Republic",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "Ecuador",
        mName: "Ecuador",
        hashName: 'eq',
        coordinates: [-79.17335665737419, -1.1168017002887702],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:Ecuador', name: 'Ecuador', hrefPath: '/' },
        ],
        tooltipData: {
            title: "Ecuador",
            address: []
        }
    },
    {
        markerOffset: -15,
        name: "El Salvador",
        mName: "ElSalvador",
        hashName: 'el',
        coordinates: [-89.14598652196324, 13.846830743748516],
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:El Salvador', name: 'El Salvador', hrefPath: '/' },
        ],
        tooltipData: {
            title: "El Salvador",
            address: []
        }
    },
];

// const GetSubTabData = {
//     '#1#in': globalMarkers[0],
//     '#1#sl': globalMarkers[1],
//     '#1#uk': globalMarkers[2],
//     '#1#us': globalMarkers[3],
//     '#1#kaz': globalMarkers[4],
//     '#1#uz': globalMarkers[5],
//     '#1#ru': globalMarkers[6],
//     '#1#ja': globalMarkers[7],
//     '#1#sp': globalMarkers[8],
//     '#1#fr': globalMarkers[9],
//     '#1#it': globalMarkers[10],
//     '#1#sw': globalMarkers[11],
//     '#1#ge': globalMarkers[12],
//     '#1#col': globalMarkers[13],
//     '#1#jm': globalMarkers[14],
//     '#1#canada': globalMarkers[15],
//     '#1#nz': globalMarkers[16],
//     '#1#aus': globalMarkers[17],
//     '#1#mex': globalMarkers[18],
//     '#1#ven': globalMarkers[19],
//     '#1#br': globalMarkers[20],
//     '#1#sa': globalMarkers[21],
//     '#1#be': globalMarkers[22],
//     '#1#ukr': globalMarkers[23],
//     '#1#vi': globalMarkers[24],
//     '#1#rom': globalMarkers[25],
//     '#1#my': globalMarkers[26],
//     '#1#ne': globalMarkers[27],
//     '#1#ch': globalMarkers[28],
//     '#1#al': globalMarkers[29],
//     '#1#at': globalMarkers[30],
//     '#1#bh': globalMarkers[31],
//     '#1#ba': globalMarkers[32],
//     '#1#cm': globalMarkers[33],
//     '#1#cl': globalMarkers[34],
//     '#1#cz': globalMarkers[35],
//     '#1#dr': globalMarkers[36],
//     '#1#eq': globalMarkers[37],
//     '#1#el': globalMarkers[38],

//     '#2#in': RAndDMarkers[0],
//     '#2#us': RAndDMarkers[1],
//     '#2#ne': RAndDMarkers[2],
//     '#2#uk': RAndDMarkers[3],

//     '#3#in': ManufacturingMarkers[0],
//     '#3#uk': ManufacturingMarkers[1],
//     '#3#us': ManufacturingMarkers[2],
//     '#3#me': ManufacturingMarkers[3],
//     '#3#ch': ManufacturingMarkers[4],
// };

let GetSubTabData = {};

globalMarkers.map((item, index) => {
    GetSubTabData["#1#" + makeHashKeyFromName(item.mName)] = {
        ...item,
        breadCrumbData: [
            ...DefBreadCrumbDataForGlobalMarkers,
            { id: 'breadCrumbData:' + item.name, name: item.name, hrefPath: '/' },
        ],
    }
})

RAndDMarkers.map((item, index) => {
    GetSubTabData["#2#" + makeHashKeyFromName(item.mName)] = {
        ...item,
        breadCrumbData: [
            ...DefBreadCrumbDataForRAndDMarkers,
            { id: 'breadCrumbData:' + item.name, name: item.name, hrefPath: '/' },
        ],
    }
})

ManufacturingMarkers.map((item, index) => {
    GetSubTabData["#3#" + makeHashKeyFromName(item.mName)] = {
        ...item,
        breadCrumbData: [
            ...DefBreadCrumbDataForManufacturingMarkers,
            { id: 'breadCrumbData:' + item.name, name: item.name, hrefPath: '/' },
        ],
    }
})

class DetailGlobalLocationMobile extends React.Component {

    state = {
        renderPageBool: false,
        cookieBool: true,
        hashKey: this.props.location.hash,
        selectedSubTab: GetSubTabData.hasOwnProperty(this.props.location.hash) ? GetSubTabData[this.props.location.hash] : {},
    }

    componentDidMount() {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e265c96c1fde14'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

                return next
            })
            if (active.lastOne) {
                this.setState({ renderPageBool: true })
            }
            return active.isAsync ? next : loading
        }))
    }


    render() {

        const { cookies } = this.props;
        const { cookieBool } = this.state;
        var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };


        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
                {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
                <span id="Navbar" className="af-view">
                    <div>
                        <MenuSection backButton={true} />
                        <div className="af-class-section" style={{ marginBottom: '10px' }} >
                            <div className="af-class-container">
                                <div style={{ marginTop: '40px' }} >
                                    {this.state.selectedSubTab.hasOwnProperty('name') && this.state.selectedSubTab.breadCrumbData.map((item, index) => {
                                        return <a onClick={(ev) => {
                                            if (this.state.selectedSubTab.breadCrumbData.length - 1 === index) {
                                                ev.preventDefault();
                                            }
                                        }} style={{ textDecoration: 'none', fontSize: '14px', fontWeight: this.state.selectedSubTab.breadCrumbData.length - 1 === index ? "bold" : '300', color: this.state.selectedSubTab.breadCrumbData.length - 1 === index ? '#4B2EAE' : '#909090' }} href={item.hrefPath} >{this.state.selectedSubTab.breadCrumbData.length - 1 === index ? item.name.toProperCase() : item.name} {this.state.selectedSubTab.breadCrumbData.length - 1 === index ? "" : " / "}</a>
                                    })}
                                </div>
                                <div style={{ backgroundColor: 'white', paddingTop: '20px', marginTop: '20px', }} >
                                    {this.state.selectedSubTab.hasOwnProperty('name') && <div style={{ fontSize: '36px', lineHeight: '48px', marginBottom: '60px', textAlign: 'center' }} >{this.state.selectedSubTab.name.toProperCase()}</div>}
                                </div>
                                <div style={{ marginTop: '24px' }} >
                                    {
                                        this.state.selectedSubTab.hasOwnProperty('name') && this.state.selectedSubTab.tooltipData.mAddress.map((item, index) => {
                                            return <>
                                                <span style={{ margin: '24px 0' }} >
                                                    <div style={{ fontSize: '14px', lineHeight: '24px', width: '70%' }} dangerouslySetInnerHTML={{
                                                        __html: item.text
                                                    }} />
                                                    {item?.telNo && <a href={"tel:" + item.telNo} style={{ fontSize: '14px', lineHeight: '24px', fontWeight: 'bold', color: '#4B2EAE', textDecoration: 'none' }} >Tel: {item.telNo}</a>}
                                                </span>
                                                <HR />
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
                            const { cookies } = this.props;
                            cookies.set('EnableCookie', JSON.stringify(data));
                        }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
                        <FooterSection />
                        {/* [if lte IE 9]><![endif] */}
                    </div>
                </span>
            </span>
        )
    }
}

export default withCookies(DetailGlobalLocationMobile)

/* eslint-enable */