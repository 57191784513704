/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from '../helpers'

import { Link } from 'react-router-dom';
// import ScrollMenu from 'react-horizontal-scroll-menu';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import './App.css';

import MenuSection from '../../Common/MenuSection';
import YearFilterScroller from './YearFilterScroller';
import FooterSection from '../../Common/FooterSection';
import { HR } from '../../pages/common/horizontalLine';
import PurpleBack from "../../Common/images/backArrowFullPrimary.svg";


import axios from 'axios';
import config from '../../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../../Common/Cookiespopup';

const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
    { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class AllNewsView extends React.Component {
    state = {
        newsData: {},
        selectedYear: '',
        yearsList: [],
        cookieBool: true,
    }
    static get Controller() {
        if (Controller) return Controller

        try {
            Controller = require('../controllers/IndexController')
            Controller = Controller.default || Controller

            return Controller
        }
        catch (e) {
            if (e.code == 'MODULE_NOT_FOUND') {
                Controller = AllNewsView

                return Controller
            }

            throw e
        }
    }

    async componentWillMount() {
        var tempObj = {};
        var tempYears = [];
        var tempNews = {};
        axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/landing_page_news?include=field_file&&sort=-field_date_for_order2", })
            .then((res) => {
                var temp = [];
                var imgPathsObj = {};
                if (res.data?.included) {
                    for (var imgObj of res.data.included) {
                        imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
                    }
                }
                for (let news of res.data.data) {
                    if (tempYears.indexOf(news.attributes.field_landing_news_year) === -1) {
                        tempYears.push(news.attributes.field_landing_news_year);
                    }
                    if (news.relationships.field_file.data === null) {
                        tempObj = {
                            id: news.id,
                            contentId: '1',
                            fileBool: false,
                            title: news.attributes.title,
                            content: news.attributes.field_content ? news.attributes.field_content.processed : "",
                            sourceName: news.attributes.field_source_name,
                            publishDate: news.attributes.field_date_and_time,
                            sourceUrl: news.attributes.field_source_url ? news.attributes.field_source_url.uri : '',
                            yearCategory: news.attributes.field_landing_news_year,
                            description: news.attributes.field_short_description,
                        }
                    } else {
                        tempObj = {
                            fileBool: true,
                            title: news.attributes.title,
                            sourceName: news.attributes.field_source_name ? news.attributes.field_source_name : "",
                            publishDate: news.attributes.field_date_and_time ? news.attributes.field_date_and_time : "",
                            description: news.attributes.field_short_description,
                            fileUrl: config.serverUrl.imageBase + imgPathsObj[news.relationships.field_file.data.id]
                        }
                    }
                    if (tempNews.hasOwnProperty(news.attributes.field_landing_news_year)) {
                        var tempArr = [...tempNews[news.attributes.field_landing_news_year]];
                        tempArr.push(tempObj);
                        tempNews[news.attributes.field_landing_news_year] = [...tempArr]
                    } else {
                        tempNews[news.attributes.field_landing_news_year] = [tempObj]
                    }
                }
                tempYears = tempYears.sort().reverse();
                this.setState({ newsData: { ...tempNews }, selectedYear: tempYears[0], yearsList: [...tempYears] })
                console.log(res, "-=-=-=-", [...temp]);
            })
            .catch((err) => {
                console.log(err, "-=-=-=  ERR")
            })

    }

    componentDidMount() {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '60952b8da0e26514531fde11'
        htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                console.log("CDM  =-=---=   ")
                new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

                return next
            })
            if (active.lastOne) {
                this.setState({ renderPageBool: true })
            }
            return active.isAsync ? next : loading
        }))

    }

    // LeftArrow() {

    //     return (
    //         <a href={""} disabled={isFirstItemVisible} onClick={() => scrollPrev()}
    //             className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
    //             <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-left-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_left.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
    //         </a>
    //     );
    // }

    // RightArrow() {

    //     return (
    //         <a href={""} disabled={isLastItemVisible} onClick={() => scrollNext()}
    //             className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
    //             <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
    //         </a>
    //     );
    // }

    render() {
        const proxies = AllNewsView.Controller !== AllNewsView ? transformProxies(this.props.children) : {

        }

        const { cookies } = this.props;
        const { cookieBool } = this.state;
        var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd3\"] {opacity:0;display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd0\"] {height:100%;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd8\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64cccf\"] {opacity:0;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
                {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
                <span id="back-to-top" className="af-view">
                    <div>
                        {/* {!window.matchMedia('(max-width: 820px)').matches ? <MenuSection/> : <MenuSection backButton={true}/>}  */}
                        <MenuSection/>
                        <div className="af-class-section af-class-headlines-section" style={{ marginTop: !window.matchMedia('(max-width: 820px)').matches && '60px'}}>
                            <div className="af-class-container" >
                                {!window.matchMedia('(max-width: 820px)').matches &&
                                <a onClick={() => {
                                    this.props.history.goBack();
                                }} style={{ position: 'absolute', left: 0, top: 0, zIndex: 999, display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
                                    <img loading="lazy" src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
                                    <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
                                </a>
                                }
                                <h1 className="af-class-h2-section-heading" style={{ textAlign: 'center', fontSize: window.matchMedia('(max-width: 820px)').matches ? '36px' :'80px', marginBottom: window.matchMedia('(max-width: 820px)').matches?'70px':'12%',marginTop:window.matchMedia('(max-width: 820px)').matches && '75px' }}>We’re in the news</h1>


                                {/* <YearFilterScroller data={this.state.yearsList} selectedYear={this.state.selectedYear} onChange={(selectedYear) => {
                                    this.setState({ selectedYear })
                                }} id="news" /> */}

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                                    {this.state.yearsList.map((item, index) => {
                                        return <div key={index + "news"} style={{ width: window.matchMedia('(max-width: 820px)').matches ? '16.5vw' :'8.4vw', marginTop: '15px', textAlign: 'center' }} >
                                            <p onClick={() => {
                                                this.setState({ selectedYear: item })
                                            }} style={{ fontSize:window.matchMedia('(max-width: 820px)').matches? '24px': '40px', color: this.state.selectedYear === item ? '#5225B5' : '#909090', cursor: 'pointer',fontWeight:this.state.selectedYear === item?'500':'300' }} >{item}</p>
                                        </div>
                                    })}
                                </div>

                                <HR />

                                {
                                    this.state.selectedYear && this.state.selectedYear.length !== 0 && this.state.newsData[this.state.selectedYear].map((item, index) => {
                                        return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '15px 0', alignItems: 'center', borderBottomColor: '#FF5046', borderBottomWidth: '1px', borderBottomStyle: 'solid' }} >
                                            <div style={{flex:1,marginRight:window.matchMedia('(max-width: 820px)').matches?'30px':'unset'}} >
                                                <a target={item.fileBool ? "_blank" : "_self"} href={item.fileBool ? item.fileUrl : `/detail-news?${item.contentId}#${item.id}`} style={{ color: 'black', textDecoration: 'none' }}>
                                                    <div className='af-class-headlines-heading' style={{ fontSize:window.matchMedia('(max-width: 820px)').matches?'16px': '18px', fontWeight: '700', marginBottom: '10px' }} >{item.title}</div>
                                                    {/* <div className="af-class-menu-link-underline" /> */}
                                                </a>
                                                <p style={{ fontSize: window.matchMedia('(max-width: 820px)').matches?'14px':'18px', fontWeight: '200', margin: 0 }} >{item.sourceName + ", " + item.publishDate}</p>
                                            </div>
                                            {/* <a href={`/detail-news?${item.contentId}#${item.id}`}
                                                className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block"
                                            // onClick={() => {
                                            //     localStorage.setItem("newsData", JSON.stringify(item))
                                            // }}
                                            >
                                                <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                            </a> */}
                                            <a target={item.fileBool ? "_blank" : "_self"} style={{marginRight:window.matchMedia('(max-width: 820px)').matches?'-20px':'unset'}} href={item.fileBool ? item.fileUrl : `/detail-news?${item.contentId}#${item.id}`}
                                                className="af-class-link-arrow af-class-left-side w-inline-block">
                                                <div className="af-class-link-circle af-class-purple" />
                                                <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                                                <img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                                            </a>
                                        </div>
                                    })
                                }
                            </div>


                        </div>
                    </div>
                    <div className="af-class-section af-class-knowledge-bank" style={{ padding: '0px' }}>
                        <a href="#back-to-top" data-w-id="1e93b0bc-4d73-ee38-8cbc-f2757ef7c754" className="af-class-back-to-top-wrapper w-inline-block">
                            <div className="af-class-back-top-top-circle"><img src="images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                            <div>Back to Top</div>
                        </a>
                    </div>
                    {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
                        const { cookies } = this.props;
                        cookies.set('EnableCookie', JSON.stringify(data));
                    }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
                    <FooterSection />
                    {/* [if lte IE 9]><![endif] */}
                </span>
            </span>
        )
    }
}

export default withCookies(AllNewsView);

/* eslint-enable */