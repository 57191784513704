/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import MenuSection from '../Common/MenuSection';
import FooterSection from '../Common/FooterSection';

import ImageTextSlider from '../Common/PeopleStories/ImageTextSlider';
import SectionOneMobile from '../Common/PeopleStories/SectionOneMobile';
import ImgSlider from '../Common/PeopleStories/ImgSlider';
import ImageSliderMobile from '../Common/PeopleStories/ImageSliderMobile';
import HcpStoriesMobile from '../Common/PeopleStories/HcpStoriesMobile';

import axios from 'axios';
import config from '../config';

import { EnquiryForm } from '../Common/ContactForm';
// import ImageSliderGsap from '../Common/PeopleStories/ImageSliderGsap';
import OurPatientInitiativesSlider from '../Common/MobileSlider/OurPatientInitiativesSlider';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';

import { isTab,mediaQueryRangeTab } from '../Utils/Utils';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60952b8da0e265187c1fde0e").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("/webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class PeopleStoriesView extends React.Component {

  constructor(props) {
    super(props);
    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.state = {
      articleData: {},
      renderPageBool: false,
      currentPage: 0,
      cookieBool: true,
    }
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/PeopleStoriesController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PeopleStoriesView

        return Controller
      }

      throw e
    }
  }


  handleLeftClick() {
    var pageCount = this.state.currentPage - 1;
    this.setState({ currentPage: pageCount });
  }

  handleRightClick() {
    var pageCount = this.state.currentPage + 1;
    this.setState({ currentPage: pageCount });
  }

  async componentWillMount() {

    var link = document.createElement('meta');  
    link.setAttribute('name', 'keywords');  
    link.content = "pharmaceutical company, affordable medicine, pharmaceutical services, pharmaceutical industry, drugs manufacturer, dr reddys";  
    document.getElementsByTagName('head')[0].appendChild(link);

    var tempObj = {};
    // var cacheData = sessionStorage.getItem("cacheData-peopleStories");
    // if (cacheData) {
    //   var tempCacheData = JSON.parse(cacheData);
    //   this.setState({
    //     articleData: { ...tempCacheData }
    //   }, () => {
    //     this.loadScript();
    //   })
    // } else {
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/peoplestoriesarticles?sort=created&&include=field_thumbnail,field_coverimage", })
      .then((res) => {
        const { data, included } = res.data;
        var section1 = [];
        var section2 = [];
        var imgPathsObj = {};
        for (var imgObj of included) {
          imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
        }
        for (var item of data) {
          tempObj = {
            id: item.id,
            contentId: '6',
            title: item.attributes.title,
            coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
            thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
            description: item.attributes.field_description ? item.attributes.field_description.processed : "",
            content: item.attributes.field_content ? item.attributes.field_content.processed : "",
          }
          if (item.attributes.field_peoplestories === "people-stories-1") {
            section1.push(tempObj);
          } else if (item.attributes.field_peoplestories === "people-stories-2") {
            section2.push(tempObj);
          }
        }
        console.log({
          section1, section2
        })
        this.setState({
          articleData: {
            section1, section2
          }
        }, () => {
          // sessionStorage.setItem("cacheData-peopleStories", JSON.stringify({
          //   section1, section2
          // }))
          this.loadScript();
        })
      }).catch((Err) => {
        this.loadScript();
        console.log(Err, "-=-=-=  ERR")
      })
    // }
  }

  componentDidMount() {
    // window.scrollTo({top: window.scrollY-1,left: 0,behavior: 'smooth'});
    // window.scrollTo({top: window.scrollY+1,left: 0,behavior: 'smooth'});
  }

  loadScript() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60d955a08a295b5eb3513499'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true })
        // if(!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches){
        window.scrollTo({ top: window.scrollY - 5, left: 0, });
        window.scrollTo({ top: window.scrollY + 5, left: 0, });
        // }
      }
      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = PeopleStoriesView.Controller !== PeopleStoriesView ? transformProxies(this.props.children) : {

    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f887d200-5ac6-9488-d1b1-a054378cd6f3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a0f728b7-bfc2-0e3b-728c-8ed71323e510\"] {display:none;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f887d200-5ac6-9488-d1b1-a054378cd6f3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a0f728b7-bfc2-0e3b-728c-8ed71323e510\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f887d200-5ac6-9488-d1b1-a054378cd6f3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a0f728b7-bfc2-0e3b-728c-8ed71323e510\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f887d200-5ac6-9488-d1b1-a054378cd6f3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a0f728b7-bfc2-0e3b-728c-8ed71323e510\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar, 
            		.delivering-health-wrapper::-webkit-scrollbar, 
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }
        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span className="af-view">
          <div>
            <MenuSection className="af-class-transparent" />
            <section id="People-Stories-Hero" data-w-id="f887d200-5ac6-9488-d1b1-a054378cd6a8" className="af-class-section af-class-peple-stories-hero" style={{ paddingTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '80px' }} >
              <div id="patient-stories" className="af-class-container w-container">
                {
                  window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.articleData?.section1 && this.state.articleData?.section1.length &&
                  <SectionOneMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
                }
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <ImageTextSlider data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />}
              </div>
            </section>
            <div id="patient-programmes" className="af-class-section af-class-z-index-2">
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Our Patient Initiatives</h2>
                <OurPatientInitiativesSlider />
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                  <div className="af-class-drag-slider-wrapper">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                      {/* <div className="af-class-mask af-class-_350 w-slider-mask"> */}
                      <div className="af-class-mask w-slider-mask">
                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/acidityfree.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Acidityfree</h3>
                              <div>Billions of people suffer from chronic acidity but don't take it seriously enough. This initiative is for patients who are only getting short-term relief.</div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/mintop.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Mintop Privilege Club</h3>
                              <div>Hair loss can be tough. So, we help patients through their hair recovery journeys.</div>
                              {/* <div>In a world where looking good is everything, hair loss is tough. So, we hand-hold patients through their hair recovery journeys.</div> */}
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"93c978e6-0303-b12f-999a-8a97597897a7"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/pexels-thirdman-7659367.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Circle of Trust</h3>
                              <div>Cancer changes the lives of patients and those closest to them. Through this programme, we raise awareness among cancer patients and caregivers screening and diagnosis.</div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/dietlogues.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Dietlogues</h3>
                              <div>Everywhere you look, there's diet advice, which can be overwhelming. With this initiative, we facilitate nutrition-focused tele-counselling.</div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/healthy-bones.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Healthy Bones</h3>
                              <div>There are a bunch of things to do to stay fit. This programme tells you how to keep your bones healthy and strong.</div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"ae2c330c-3b91-6fe2-999a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/manage-before-damage.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Manage Before Damage</h3>
                              <div>Diabetes can be dangerous, but it doesn't have to be. This initiative educates diabetics and encourages them to get regular checks and keep readings under control.</div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id={"ae2c330c-3b91-6fe2-999b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/hepatitis-a.jpg" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Hepatitis A Awareness Drive</h3>
                              <div>We take our fight against Hep A to the masses through this programme to make young parents aware of the disease and consider vaccination.</div>
                            </div>
                          </div>
                        </div>
                      </div>


                      {/* <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50a" className="af-class-left-arrow af-class-lower af-class-down-90px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50e" className="af-class-right-arrow af-class-lower af-class-down-90px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e510" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div> */}

                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: this.state.currentPage === 0 ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={this.handleLeftClick}>
                        {
                          this.state.currentPage === 0 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }

                      </div>
                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: this.state.currentPage === 2 ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={this.handleRightClick}>
                        {
                          this.state.currentPage === 2 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }
                      </div>

                      <div className="af-class-slide-nav w-slider-nav w-round" />
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar">
                    <div className="af-class-drag-progress-fill af-class-forty" />
                  </div>
                </>}
              </div>
              {/* <a href="#" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e515" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="/images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="/images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a> */}
            </div>
            <div id="hcp-stories" data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ea" className="af-class-section af-class-always-innovating" style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '100vh' }} >
              <div className="af-class-always-innovating-heading-block">
                <div className="af-class-container">
                  <h1 data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ed" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '46px' : '36px' : '60px' }} className="af-class-h2-section-heading af-class-title af-class-center-align af-class-_1000px">We enable healthcare professionals to understand their patients better</h1>
                </div>
              </div>
              {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div className="af-class-science-research-fullbg-wrapper">
                <div className="af-class-full-bg-block">
                  <div className="af-class-section af-class-form-formulations af-class-typeb">
                    <div className="af-class-container w-container">
                      <div className="w-layout-grid af-class-_2-col-grid">
                        <div>
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="e40d7c91-3cfc-49f6-6381-4757e49152cf" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“We have hosted numerous sessions of DRFHE’s Sarathi and Abhilasha programmes and our employees are satisfied with the learnings, especially with regard to aspects like time management and anger management. We also conducted a Nursing Leadership Programme and have seen a great difference in the way our nurses have been taking care of their teams. And last year, DRFHE rolled out new initiatives tailored to the pandemic situation and people really liked it.”<br /><br /><strong>Vaishali Panchal<br />Training and Development | Rainbow Hospitals, Hyderabad</strong></p>
                        </div>
                        <div>
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“Mahatma Gandhi once said that unless we educate our people, we will not get full freedom. The Awareness of Life initiative by Dr. Reddy’s to educate people is a novel programme, raising awareness among them on issues like diabetes, hypertension, obesity, cancer and osteoporosis. These are well-chosen topics and as a local practitioner who is part of the programme, I am happy to do this work because it will help humanity in the long run.” <br /><br /><strong>Dr. R Govindharajan<br />MD | Madurai</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-section af-class-focused-market af-class-type-a">
                    <div className="af-class-container w-container">
                      <div className="w-layout-grid af-class-_2-col-grid">
                        <div>
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="6e51dac0-158a-08be-971b-e997baea8bc9" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“Trainings are integral to employees’ skill development. They help in the areas of communication, leadership, time management, motivation, empathy for patients, team building, etc — all essential for healthcare workers. We are fortunate to work with Dr. Reddy’s through trainings like Abhilasha, Sarathi, Mantra and Manthan. Additionally, thanks to their outbound training programme for the senior leadership team, they helped us hone our leadership skills.”  <br /><br /><strong>Nithya Kalyani<br />HR Head | MS Ramaiah Memorial Hospital, Bangalore</strong></p>
                        </div>
                        <div id="w-node-dbce085d-578a-b8c3-d4be-9efe94e545fe-b3513499">
                          <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                              el.style.setProperty('line-height', '30px', 'important');
                            }
                          }} data-w-id="dbce085d-578a-b8c3-d4be-9efe94e545ff" style={{ opacity: 0, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '18px' : 'auto' }} className="af-class-paragraph af-class-_572">“DRFHE is doing fantastic work with us, such as the Sarathi module for customer care executives, Sanjeevani for pharmacists, Inner Circle for doctors, Abhilasha for nurses, and Manthan and Mantra for heads of departments and managerial staff. These programmes consist of real examples, role plays, etc, which are very effective. They’re building soft skills in our staff and making them more confident. Our patient feedback is positive, and complaints have reduced.”<br /><br /><strong>Megha Gaikwad<br />Deputy HR Manager | Ruby Hall Clinic, Pune</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>

            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.articleData?.section1 && this.state.articleData.section1.length !== 0 && <div className="af-class-section" style={{ marginTop: isTab ? '-120px' :'-60px', marginBottom: isTab ? '-120px' :'-60px' }} >
              <HcpStoriesMobile data={this.state.articleData?.section1 ? this.state.articleData.section1 : []} history={this.props.history} />
            </div>}

            <div id="partner-stories" data-w-id="19cc00fd-e1b3-336b-5837-c6cf2129d595" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  {/* <h2 className="af-class-h2-section-heading" style={{ textAlign: 'center', marginBottom: '40px' }} >PARTNER STORIES</h2> */}
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      isTab
                      ?
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize:isTab ? '48px' : '36px' }} >Collaborating with partners <br /> for good health</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize:isTab ? '48px' : '36px' }} >Collaborating with partners for <br /> good health</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '36px' : '60px' }} >Collaborating with partners<br />for good health</h1>
                  }
                </div>
              </div>
            </div>

            {
              window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && this.state.articleData?.section1 && this.state.articleData?.section1.length &&
              <ImageSliderMobile data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} />
            }
            {
              !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <ImgSlider loading="lazy" data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} />
            }
            {/* {
              this.state.articleData.section2 && this.state.articleData.section2.length !== 0 && <ImageSliderGsap data={this.state.articleData?.section2 ? this.state.articleData.section2 : []} />
            } */}

            <div id="be-our-partner" data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef28" className="af-class-section af-class-our-values">
              <div className="af-class-values-section-title-block">
              {
                isTab
                ?
                <h2 className="af-class-h2-section-heading af-class-title af-class-values af-class-full-width" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Benefits of being our partner</h2>
                :
                <h2 className="af-class-h2-section-heading af-class-title af-class-values af-class-full-width" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >Benefits of being<br />our partner</h2>
                }
              </div>
              <div className="af-class-values-background-wrapper">
                <div className="af-class-value-bg-wrapper af-class-quality af-class-_4 af-class-partners-4" style={{backgroundPosition:isTab && '70% 50%'}} />
                <div className="af-class-value-bg-wrapper af-class-safety af-class-_3 af-class-partners-3" />
                <div className="af-class-value-bg-wrapper af-class-integrity af-class-_2 af-class-partners-2" />
                <div className="af-class-value-bg-wrapper af-class-sustainability af-class-_1 af-class-partners-1" />
                {/* <div className="af-class-value-bg-wrapper af-class-collaboration af-class-_5" />
                <div className="af-class-value-bg-wrapper af-class-productivity af-class-_6" /> */}
                <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef37" className="af-class-container w-container">
                  <div style={{ backgroundColor: 'white', width: '100%', height: '100%', position: 'absolute', marginLeft: '-50vw', overflow: 'visible' }} />
                  <div className="af-class-values-wrapper af-class-tenet af-class-partners" style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '370px' }} >
                    <div className="af-class-tenet-values-wrapper">
                      <div className="af-class-value-line-breaker af-class-fixed-height" style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '300px' }} />
                      <div>
                        <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef3e" className="af-class-values-block af-class-tenet">
                          <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef3f" style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              {
                                isTab
                                ?
                                <h3 data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef42" style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-1">We get what you need and engage <br />meaningfully</h3>
                                :
                                <h3 data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef42" style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-1">We get what you need <br />and engage meaningfully</h3>
                                }
                            </div>
                          </div>
                        </div>
                        <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef4c" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              {
                                isTab
                                ?
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-2">We think, communicate and act with <br />urgency</h3>
                                :
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-2">We think, communicate <br />and act with urgency</h3>
                                }
                            </div>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                        </div>
                        <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef5a" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              {
                                isTab
                                ?
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-3">We collaborate with you to create <br />shared value</h3>
                                :
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-3">We collaborate with you <br />to create shared value</h3>
                                }
                            </div>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                        </div>
                        <div data-w-id="2aabbea5-6b94-e31c-f3bf-28e3d7cbef68" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              {
                                isTab
                                ?
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-4">We equip and empower you to <br />succeed</h3>
                                :
                                <h3 style={{ color: 'rgb(144,144,144)', fontWeight: 700, fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '14px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} className="af-class-values-heading af-class-text af-class-partners-4">We equip and empower <br />you to succeed</h3>
                                }
                            </div>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-z-index-2" style={{ backgroundColor: '#EDEDED' }} >
              <div className="af-class-container w-container" >
                <h2 className="af-class-h2-section-heading" >Partner with us</h2>
                <div style={{ borderTopColor: "#ff5046", borderTopWidth: '1px', borderTopStyle: 'solid', marginBottom: '40px' }} ></div>
                <EnquiryForm />
              </div>
            </div>
            <div className="af-class-section af-class-knowledge-bank" style={{ backgroundColor: '#EDEDED', margin: 0, padding: 0 }}>
              <a href="#People-Stories-Hero" data-w-id="04a46715-23e5-b19a-5f96-16ffc4c13fe1" className="af-class-back-to-top-wrapper w-inline-block">
                <div className="af-class-back-top-top-circle"><img src="/images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="/images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
              {/* <div className="af-class-container w-container" >
                <div>OUR KNOWLEDGE BANK</div>
                <div className="af-class-why-spacer" />
                <div className="af-class-media-kit-links-wrapper">
                  <div>
                    <div className="af-class-knowledge-bank-text">Our experience and our resources are available to help you achieve more in your field.</div>
                  </div>
                  <div className="af-class-mediakit-links-inner-block">
                    <a href="#" className="af-class-media-kit-links">Knowledge Centre</a>
                    <a href="#" className="af-class-media-kit-links">Community programmes</a>
                    <a href="#" className="af-class-media-kit-links">Doctor portals</a>
                    <a href="#" className="af-class-media-kit-links af-class-no-right-margin">Learning tools</a>
                  </div>
                </div>
              </div> */}
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(PeopleStoriesView)

/* eslint-enable */