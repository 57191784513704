import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import BackIcon from '../../Common/images/backArrow.svg';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 8%;
`;

const Header = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    align-self: flex-start;
`;

const BackImg = styled.img`
    width: 20px;
    height: 20px;
`;

const BackText = styled.h6`
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    font-family: Drlcircular;
    margin: 0;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 300;
    margin-top: 60px;
    margin-bottom: 30px;
`;

const TextSection = styled.p`
    width: 90%;
    font-size: 18px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 300;
    margin: 40px 0;
    line-height: 1.8;
`;

const TextBold = styled.p`
    font-size: 18px;
    color: #000000;
    font-family: Drlcircular;
    font-weight: 700;
`;

const TextLink = styled.a`
    cursor: pointer;
    color: #5225B5;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    text-decoration: none;
`;

const OL = styled.ol`
    margin: 0;
    padding-left: 30px;
`;

export function MainSection(props) {
    const history = useHistory();
    return <MainContainer>
        <Header onClick={() => {
            history.goBack();
        }} >
            <BackImg src={BackIcon} />
            <BackText>Back</BackText>
        </Header>
        <BodySection>
            <Heading>Committees of the Board</Heading>
            <HR />
            <TextSection>
                Committees appointed by the Board focus on specific areas and take informed decisions within the framework of delegated authority, and make specific recommendations to the Board on matters in their areas or purview. All decisions and recommendations of the committees are placed before the Board for information or for approval.<br /><br />
                <TextBold>COMPOSITION OF BOARD & BOARD COMMITTEES OF DR. REDDY’S</TextBold><br /><br />
                <TextBold> Board of Directors of Dr. Reddy’s </TextBold>
                <OL>
                    <li> Mr. Satish Reddy (Chairman) </li>
                    <li> Mr. G V Prasad (Co-Chairman & MD) </li>
                    <li> Ms. Kalpana Morparia </li>
                    <li> Mr. Sridar Iyengar </li>
                    <li> Mr. Prasad Raghava Menon </li>
                    <li> Mr. Leo Puri </li>
                    <li> Ms. Shikha Sharma </li>
                    { /* <li> Mr. Allan Oberman</li>*/ }<br /><br />
                </OL>
                <TextBold>Board Committee </TextBold>
                We have seven Board-level Committees, namely:
                <OL>
                    <li> Audit Committee </li>
                    <li> Nomination, Governance & Compensation Committee </li>
                    <li> Risk Management Committee </li>
                    <li> Science, Technology and Operations Committee </li>
                    <li> Stakeholders’ Relationship Committee </li>
                    <li> Banking and Authorisations Committee (formerly known as Management Committee) </li>
                    <li> Corporate Social Responsibility (CSR) Committee</li><br /><br />
                </OL>
                The members of the Committees of Board are as under:
                <TextBold> Audit Committee </TextBold>
                <OL>
                    <li>Mr. Sridar Iyengar(Chairman) </li>
                    <li>Ms. Kalpana Morparia </li>
                    <li>Ms. Shikha Sharma </li><br /><br />
                </OL>
                <TextBold> Nomination, Governance & Compensation Committee </TextBold>
                <OL>
                    <li>Mr. Prasad Raghava Menon (Chairman) </li>
                    <li>Ms. Kalpana Morparia </li>
                    {/*<li>Mr. Allan Oberman </li>*/ }<br /><br />
                </OL>
                <TextBold>Science, Technology And Operations Committee </TextBold>
                <OL>
                    { /*<li>Mr. Allan Oberman (Chairman) </li> */ }
                    <li>Mr. Prasad Raghava Menon </li>
                    <li>Mr. Leo Puri </li> <br /><br />
                </OL>
                <TextBold> Stakeholders’ Relationship Committee </TextBold>
                <OL>
                    <li>Ms. Kalpana Morparia (Chairperson) </li>
                    <li>Mr. G V Prasad </li>
                    <li>Mr. Satish Reddy </li><br /><br />
                </OL>
                <TextBold> Banking and Authorizations Committee (earlier known as Management Committee) </TextBold>
                <OL>
                    <li>Mr. Satish Reddy (Chairman) </li>
                    <li>Mr. G V Prasad </li><br /><br />
                </OL>
                <TextBold>Corporate Social Responsibility Committee </TextBold>
                <OL>
                    <li>Mr. Prasad Raghava Menon (Chairman) </li>
                    <li>Mr. G V Prasad </li>
                    <li>Mr. Satish Reddy</li><br /><br />
                </OL>
                <TextBold>Please click on the links below to view the charters of our various board committees: </TextBold>
                <OL>
                    <li><TextLink> Charter of the Audit Committee </TextLink></li>
                    <li><TextLink> Charter of the Nomination, Governance & Compensation Committee </TextLink></li>
                    <li><TextLink> Charter of the Risk Management Committee </TextLink></li>
                    <li><TextLink> Charter of the Science, Technology and Operations Committee </TextLink></li>
                    <li><TextLink> Charter of the Stakeholders’ Relationship Committee </TextLink></li>
                    <li><TextLink> Charter of the Corporate Social Responsibility Committee</TextLink></li>
                </OL>
            </TextSection>
        </BodySection>
    </MainContainer>
}
