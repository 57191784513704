import React from "react";
import styled from "styled-components";

const Main = styled.div`
  /* width: 100%; */
`;

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const KeyContactHeading = styled.h4`
  font-size: 18px!important ;
  color: #000000!important ;
  font-weight: 700!important ;
  font-family: Drlcircular!important ;
  margin:45px 0 !important;
  @media (max-width: 900px) {
    font-size: 14px!important ;
    margin:24px 0 !important;
  }
`;

const SubBoldText = styled.p`
  font-size: 18px!important ;
  color: #000000!important ;
  font-weight: 600!important ;
  font-family: Drlcircular!important ;
  @media (max-width: 900px) {
    font-size: 14px!important ;
  }
`;

const SubText = styled.p`
  font-size: 18px!important ;
  color: #000000!important ;
  font-weight: 400!important ;
  font-family: Drlcircular!important ;
  line-height: 1.9!important ;
  @media (max-width: 900px) {
    font-size: 14px!important ;
    line-height: 24px !important ;
  }
`;

const SubLinks = styled.a`
  color: #000 !important;
  font-size: 18px!important;
  font-weight: 400!important;
  font-family: Drlcircular!important;
  cursor:pointer!important;
  text-decoration:none !important;
  &:hover{
  color: #000 !important;
    text-decoration:underline !important;
  }
  @media (max-width: 900px) {
    font-size: 14px!important;
  }
`;

export function KeyContactsView(props) {
  return (
    <Main>
      <TextContainer>
        <KeyContactHeading>
          For more information on specific businesses or areas, please do get in touch with the following people:
        </KeyContactHeading>
        <SubText>
          <SubBoldText>
            Corporate Office <br />
          </SubBoldText>
          Dr. Reddy’s Laboratories Ltd. <br />
          8-2-337, Road No. 3, <br />
          Banjara Hills, Hyderabad <br />
          Telangana 500034, INDIA <br />
          Tel: +91.40.4900 2900 <br />
          <br />
          <br />
          <SubBoldText>
            Retail investor and Shareholder Relations:
          </SubBoldText>
          <SubLinks href="mailto:shares@drreddys.com">shares@drreddys.com</SubLinks>
          <br />
          <br />
          <SubBoldText>
            Institutional Investors & Financial Analysts:
          </SubBoldText>
          Richa Periwal, <SubLinks href="mailto:richaperiwal@drreddys.com" > richaperiwal@drreddys.com</SubLinks> <br />
          Ph: +040 - 4900 2135 <br />
          Aishwarya Sitharam, <SubLinks href="mailto:aishwaryasitharam@drreddys.com" > aishwaryasitharam@drreddys.com </SubLinks> <br />
          Ph: +040 - 4900 2180
          { /* Ashish Girotra, <SubLinks href="mailto:ashishg@drreddys.com" > ashishg@drreddys.com</SubLinks> <br />
          Saunak Savla, <SubLinks href="mailto:saunaks@drreddys.com" > saunaks@drreddys.com</SubLinks> <br /> 
  Ph: +040 - 4900 2135*/ }
          <br />
          <br />
          <SubBoldText>
            Media Relations: <br />
          </SubBoldText>
          { /*<SubLinks href="mailto:mediarelations@drreddys.com" >mediarelations@drreddys.com </SubLinks> <br /> */ }
          <SubLinks href="mailto:ushaiyer@drreddys.com">ushaiyer@drreddys.com</SubLinks> <br />
          <br />
          <br />
          { /* <SubBoldText>
            Corporate Social Responsibility/Community Initiatives: <br />
          </SubBoldText>
          <SubLinks href="mailto:csr@drreddys.com">csr @drreddys.com</SubLinks> <br />
  <br /> */ }
          General Data Protection Regulation (GDPR) – European Representative. <br />
          Pursuant to Article 27 of the General Data Protection Regulation (GDPR), Dr. Reddy’s has appointed European Data Protection Office (EDPO) as its GDPR Representative in the EU.<br />
          <br />
          <strong>You can contact EDPO regarding matters pertaining to the GDPR:</strong> <br />
          -by using EDPO’s online request form: <strong>https://edpo.com/gdpr-data-request/ </strong> <br />
          -by writing to EDPO at <strong>Avenue Huart Hamoir 71, 1030 Brussels, Belgium </strong><br/>
          <br />
          We would like to hear from you. If you have questions, comments or
          enquiries, please complete the form below and we will get back to
          you.
          <br />
        </SubText>
      </TextContainer>
    </Main>
  );
}
