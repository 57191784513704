import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";

import { scroller } from 'react-scroll';

import rightArrowPrimary from '../../../assets/rightArrowPrimary.svg';

import ManufacturingMarkers from './StaticLocations/ManufacturingMarkers';
import RAndDMarkers from './StaticLocations/RAndDMarkers';
import globalMarkers from './StaticLocations/GlobalMarkers';

import { HR } from "../../common/horizontalLine";
import { arrangeArrObjInAlphabeticalOrder, makeHashKeyFromName } from "../../../Utils/Utils";

const geoUrl =
  // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
  //"https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
  "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const MainContainer = styled.div`
width: 100%;
`;

const MapSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LegendSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top:60px;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
`;

const LegendIndicator = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin-right: 15px;
`;

const LegendText = styled.a`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  text-transform: uppercase;
  text-decoration:${({ selectedBool }) => selectedBool ? "underline !important" : "none !important"};
  margin-right:40px !important;
  &:hover {
    text-decoration: underline !important;
  }
`;

// const WorldMapContainer = styled.div`
// width:100%;
// height:100vh;
// margin-top:50px;
//   svg {
//     display: inline-block;
//     vertical-align: middle;
//   }
// `;

const WorldMapContainer = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 50%;
  margin-top:50px;
`;
const WorldMapContainerContent = styled.div`
position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  `;

const ToolTipContainer = styled.div`
    padding:8px 12px;
    background: #EDEDED;
    height:auto;
    max-height:350px;
    width:220px;
  `;

const TooltipHeader = styled.div`
  border-radius: 5px;
  padding: 10px 20px;
  padding-left: 10px !important;
  font-family: Drlcircular;
  font-size:16px!important;
  font-weight:400!important;
  line-height:22px !important;
  background-color:#5225B5;
  color:#fff;
  `;

const DropDownList = styled("ul")`
padding: 0 !important;
margin: 0 !important;
/* padding-left: 1em; */
/* border: 2px solid #e5e5e5; */
box-sizing: border-box;
color: #000;
font-size: 1.3rem;
font-weight: 500;
height:auto;
max-height:200px;
overflow: scroll;
&:first-child {
  padding-top: 0.4em;
}
`;

const ListItem = styled("li")`
  list-style: none;
  display:flex;
  border-radius: 5px;
  padding: 10px 20px;
  padding-left: 10px !important;
  font-family: Drlcircular;
  font-size:14px!important;
  font-weight:400!important;
  line-height:18px !important;
  flex:1;
  flex-direction:row;
  background-color:${props => props.selected ? '#5225B5' : '#EDEDED'};
  color:${props => props.selected ? '#fff' : '#000'};
    /* following code for disabling copy text */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export function GlobalLocationView(props) {

  let urlHash = window.location.hash.split("#");
  //it has 3 props. They are 0,1,2
  const [locationTypes, setLocationTypes] = useState(urlHash.length === 3 ? Number(urlHash[2]) - 1 : 0);
  let initialMarkers = [];
  if (locationTypes === 0) {
    initialMarkers = [...globalMarkers];
  } else if (locationTypes === 1) {
    initialMarkers = [...RAndDMarkers];
  } else if (locationTypes === 2) {
    initialMarkers = [...ManufacturingMarkers];
  }
  const [markers, setMarkers] = useState([...initialMarkers])
  const [tooltipData, setTooltipData] = useState("");
  const [popupCoord, setpopupCoord] = useState({ left: 0, top: 0, data: {} });
  // const [popupCoord, setpopupCoord] = useState({ left: 860, top: 420, data: markers[0].tooltipData });

  // scroller.scrollTo("mainMapComponent", {
  //   duration: 500,
  //   delay: 100,
  //   smooth: true,
  // });


  var indicatorColor = null;
  if (locationTypes === 0) {
    indicatorColor = "#5225B5";
  } else if (locationTypes === 1) {
    indicatorColor = "#00C7FF";
  } else {
    indicatorColor = "#FF5B94";
  }

  useEffect(() => {
    // document.getElementById("GeoMarker:USA").dispatchEvent(new Event("click"));
    setTimeout(() => {
      var mainLayout = document.getElementById("mainMapComponent") && document.getElementById("mainMapComponent").getBoundingClientRect();
      var markerLayout = document.getElementById("GeoMarker:INDIA") && document.getElementById("GeoMarker:INDIA").getBoundingClientRect();
      console.log(mainLayout, "-=-.-=-", markerLayout)
      console.log(mainLayout, markerLayout, {
        left: (markerLayout.x - mainLayout.x) + markerLayout.width, top: (markerLayout.y - mainLayout.y) + markerLayout.height, data: markers[0].tooltipData
      });
      setpopupCoord({
        left: (markerLayout.x - mainLayout.x) + markerLayout.width, top: (markerLayout.y - mainLayout.y) + markerLayout.height, data: markers[0].tooltipData
      })
    }, 800)
  }, [])

  const setupDefaultTooltip = (markerId, tooltipData) => {
    var mainLayout = document.getElementById("mainMapComponent") && document.getElementById("mainMapComponent").getBoundingClientRect();
    var markerLayout = document.getElementById(markerId) && document.getElementById(markerId).getBoundingClientRect();
    console.log(mainLayout, "-=-.-=-", markerLayout)
    console.log(mainLayout, markerLayout, {
      left: (markerLayout.x - mainLayout.x) + markerLayout.width, top: (markerLayout.y - mainLayout.y) + markerLayout.height, data: { ...tooltipData }
    });
    setpopupCoord({
      left: (markerLayout.x - mainLayout.x) + markerLayout.width, top: (markerLayout.y - mainLayout.y) + markerLayout.height, data: { ...tooltipData }
    })
  }

  return (
    window.matchMedia('(max-width: 820px)').matches
      ?
      <MainContainer>
        <div className="disable-scroll-indicator" style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', width: '100%', width: 'calc(calc(100% + 20px + 20px))', marginLeft: '-20px', marginRight: '-20px', marginBottom: '25px' }} >
          {['Global offices', 'R&D Locations', 'Manufacturing locations'].map((LegendItem, LegendIndex) => {
            return <a onClick={() => {
              setLocationTypes(LegendIndex)
              setMarkers(LegendIndex === 0 ? [...globalMarkers] : LegendIndex === 1 ? [...RAndDMarkers] : [...ManufacturingMarkers])
            }} key={"SubMenu:" + LegendIndex} style={{ textDecoration: locationTypes === LegendIndex ? 'underline' : 'none', textUnderlineOffset: '4px', marginRight: LegendIndex === 2 ? '20px' : '12px', marginLeft: LegendIndex === 0 && '20px' }} >
              <p style={{ fontSize: '14px', fontWeight: 'bold', color: locationTypes === LegendIndex ? '#000' : '#909090', margin: '0px', whiteSpace: 'nowrap', textTransform: 'uppercase' }} >{LegendItem}</p>
            </a>
          })}
        </div>
        <div>
          {arrangeArrObjInAlphabeticalOrder(markers, 'name').map((mItem, mIndex) => {
            return <>
              <HR />
              <a href={"/detail-global-location#" + Number(locationTypes + 1) + "#" + makeHashKeyFromName(mItem.mName)} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0', justifyContent: 'space-between', color: 'black' }} >
                <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0px', }} >{mItem.mName}</p>
                <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                {/* <img  loading="lazy" src="images/rightPurpleArrow.png" style={{ margin: '0 10px' }} /> */}
              </a>
            </>
          })}
        </div>
      </MainContainer>
      :
      <MainContainer>
        <MapSection>
          <LegendSection>
            <LegendItem>
              <LegendIndicator bgColor="#5225B5" />
              <LegendText selectedBool={locationTypes === 0} onClick={() => {
                setpopupCoord({ left: 0, top: 0, data: {} })
                setLocationTypes(0)
                setMarkers([...globalMarkers])
                // setTimeout(() => {
                // setupDefaultTooltip("GeoMarker:INDIA",globalMarkers[0].tooltipData)
                // },300)
              }} >Global locations</LegendText>
            </LegendItem>
            <LegendItem>
              <LegendIndicator bgColor="#00C7FF" />
              <LegendText selectedBool={locationTypes === 1} onClick={() => {
                setpopupCoord({ left: 0, top: 0, data: {} })
                setLocationTypes(1)
                setMarkers([...RAndDMarkers])
                // setTimeout(() => {
                // setupDefaultTooltip("GeoMarker:INDIA",RAndDMarkers[0].tooltipData)
                // },300)
              }} >R&D Locations</LegendText>
            </LegendItem>
            <LegendItem>
              <LegendIndicator bgColor="#FF5B94" />
              <LegendText selectedBool={locationTypes === 2} onClick={() => {
                setpopupCoord({ left: 0, top: 0, data: {} })
                setLocationTypes(2)
                setMarkers([...ManufacturingMarkers])
                // setTimeout(() => {
                // setupDefaultTooltip("GeoMarker:INDIA",ManufacturingMarkers[0].tooltipData)
                // },300)
              }} >Manufacturing locations</LegendText>
            </LegendItem>
          </LegendSection>
          <WorldMapContainer>
            {/* <WorldMapContainerContent> */}
            <ComposableMap
              projection="geoMercator"
              projectionConfig={{
                scale: 125,
                // scale: 100,
                rotation: [-11, 0, 0],
              }}
              width={800} height={630}
              id="mainMapComponent"
              style={{
                width: "100%", height: 'auto'
                // height: "200%",
              }}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#ccc"
                    />
                  ))
                }
              </Geographies>
              {markers.map(({ name, coordinates, markerOffset, tooltipData }) => (
                <Marker
                  onClick={(ev) => {
                    if (popupCoord.data.title !== tooltipData.title) {
                      setpopupCoord({ left: Number(ev.nativeEvent.offsetX) + 5, top: Number(ev.nativeEvent.offsetY) + 5, data: tooltipData })
                      setTooltipData("Hyderabad")
                    }
                  }}
                  key={name}
                  id={"GeoMarker:" + name}
                  coordinates={coordinates}
                >
                  {
                    (popupCoord.data?.title && popupCoord.data.title === tooltipData.title)
                      ?
                      <>
                        <circle r={3.5} fill={indicatorColor} strokeWidth={2} style={{ zIndex: '99' }} />
                        <svg xmlns="http://www.w3.org/2000/svg" style={{ zIndex: '99' }} x={-5.6} y={-5.6} width="10.893" height="11.061" viewBox="0 0 10.893 11.061">
                          <ellipse id="Ellipse_52" data-name="Ellipse 52" cx="5.447" cy="5.531" rx="5.447" ry="5.531" fill={indicatorColor} opacity="0.32" />
                        </svg>
                      </>
                      :
                      <circle r={3} fill={indicatorColor} strokeWidth={2} style={{ zIndex: '99' }} />
                  }
                  {(popupCoord.data?.title && popupCoord.data.title === tooltipData.title) && <svg xmlns="http://www.w3.org/2000/svg" x={-5.5} y={-15} style={{ zIndex: '9999' }} width="10.948" height="15.916" viewBox="0 0 19.948 27.916" >
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M9.974,27.916h0a54.2,54.2,0,0,1-4.987-5.9,34.883,34.883,0,0,1-3.429-5.682,14.545,14.545,0,0,1-1.558-6A11.284,11.284,0,0,1,1.01,5.468,9.057,9.057,0,0,1,3.525,2.277,10.587,10.587,0,0,1,9.974,0a10.586,10.586,0,0,1,6.449,2.277,9.057,9.057,0,0,1,2.515,3.191,11.285,11.285,0,0,1,1.01,4.867,14.545,14.545,0,0,1-1.558,6,34.884,34.884,0,0,1-3.428,5.682,54.194,54.194,0,0,1-4.986,5.9Zm.014-23.35a5.422,5.422,0,1,0,5.423,5.421A5.428,5.428,0,0,0,9.989,4.566Z" fill="#ff5046" />
                  </svg>}

                  {/* <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                >
                  {name}
                </text> */}
                </Marker>
              ))}
            </ComposableMap>
            {popupCoord.left !== 0 && <ToolTipContainer style={{ position: 'absolute', left: popupCoord.left, top: popupCoord.top, }} >
              <TooltipHeader>{popupCoord.data.title}</TooltipHeader>
              <DropDownList>
                {popupCoord.data.address.map((address, index) => (
                  <ListItem key={"Tooltip render:" + popupCoord.data.title + index}>
                    <div style={{ flex: 1, }} dangerouslySetInnerHTML={{
                      __html: address
                    }} />
                  </ListItem>
                ))}
              </DropDownList>
            </ToolTipContainer>}
            {/* </WorldMapContainerContent> */}
          </WorldMapContainer>
        </MapSection>
        <div style={{ height: '1px', width: '100%', zIndex: '99', position: 'absolute', bottom: 0 }} />
        <ReactTooltip>{tooltipData}</ReactTooltip>
      </MainContainer>
  );
}
