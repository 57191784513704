import React from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";

const SelectDiv = styled.div`
  width: 100%;
  height: 58px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #909090;
  border: 1px solid #5225B5;
  border-radius: 5px;
  opacity: 1;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
`;

const Label = styled.p`
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  font-family: Drlcircular;
  text-align: left;
  letter-spacing: 0px;
  margin: 0 !important;
  /* font: normal normal normal 14px/30px DRL Circular; */
  color: #909090;
  text-transform: capitalize;
  opacity: 1;
`;

const Select = styled.select`
  width: 100%;
  font-size: 20px;
  color: #000000;
  font-weight: 400;
  font-family: Drlcircular;
  border: 0px;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
`;

const Option = styled.option`
background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-family: Drlcircular;
    &:hover {
      background-color:#969696;
  }
  &:checked{
    background-color:#5225B5;
  }
`;

export function Dropdown(props) {
  return (
    <SelectDiv>
      <span style={{ flex: 1 }}>
        <Label>{props.placeholder}</Label>
        <Select
          name={props.id}
          id={props.id}
          value={props.selectedValue}
          onChange={(ev) => {
            var e = document.getElementById(props.id);
            var strUser = e.value;
            props.onChangeValue(strUser);
          }}
        >
          <Option disabled style={{ color: "#fff", backgroundColor: "red" }}>
            {props.placeholder}
          </Option>
          {props.data.map((item, index) => {
            return (
              <Option key={props.id + item + ":-" + index} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </span>
      <span>
        <FiChevronDown size={25} color="#5225B5" />
      </span>
    </SelectDiv>
  );
}
