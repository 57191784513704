import React from "react";
import styled from "styled-components";

const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ff5046;
  margin: ${({ margin }) => (margin ? margin : "1em 0")};
  padding: 0;
`;

export function HR(props) {
  return <HorizontalLine {...props} />;
}
