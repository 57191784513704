import React, { useEffect,useState } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top: 40px;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }

`;

const DropDownDiv = styled.div`
width:30%;
margin-bottom: 60px;
@media (max-width: 900px) {
      width:60%;
    margin-bottom: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
    flex-direction: column;
`;

const TextHeading = styled.h5`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  margin-bottom: 40px !important;
  @media (max-width: 900px) {
      font-size: 24px !important;
      margin-bottom: 20px !important;
  }
`;

const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  align-self:start;
  &:hover{
      color: #000;
      text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    text-decoration:underline !important;
    margin-bottom: 15px;
  }
`;

// const scripts = [
//     { loading: fetch("webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
// ]

export const AnnualReports = (props) => {

    const [reports, setReports] = useState({});
  const [dropdownData, setDropdowndata] = useState([]);
  const [selectedFilterDate, setSelectedFilterDate] = useState("");
  const [Annexurereports, setAnnexurereports] = useState([]);

    useEffect(() => {
        var tempIncludedObj = {};
        var tempReports = {};
        var tempDropdownData = [];
        var tempObj = {};
        var tempArr = [];
        var tempProperty = "";
        axios({
          method: "get",
          url:
            config.serverUrl.base +
            "/jsonapi/node/investorreports?include=field_file",
        })
          .then((res) => {
            console.log(res);
            for (const item of res.data.included) {
              tempIncludedObj[item.id] =
                config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
              tempProperty =
                report.attributes.field_inventoryfiletype === "Annual Report"
                  ? "annual"
                  : "subsidiary";
              tempObj = {};
              tempArr = [];
              if (
                tempDropdownData.indexOf(report.attributes.field_academicyear) ===
                -1
              ) {
                tempDropdownData.push(report.attributes.field_academicyear);
              }
              tempDropdownData = tempDropdownData.sort().reverse();
              if (
                tempReports?.[report.attributes.field_academicyear] &&
                tempReports[report.attributes.field_academicyear]?.[tempProperty]
              ) {
                tempArr =
                  tempReports[report.attributes.field_academicyear]?.[tempProperty];
                tempArr.push({
                  title: report.attributes.title,
                  url: tempIncludedObj[report.relationships.field_file.data.id],
                });
                tempObj[tempProperty] = tempArr;
                tempReports[report.attributes.field_academicyear][tempProperty] =
                  tempArr;
              } else {
                tempArr = [];
                tempArr.push({
                  title: report.attributes.title,
                  url: tempIncludedObj[report.relationships.field_file.data.id],
                });
                tempObj[tempProperty] = tempArr;
                if (tempReports?.[report.attributes.field_academicyear]) {
                  tempReports[report.attributes.field_academicyear][tempProperty] =
                    tempArr;
                } else {
                  tempReports[report.attributes.field_academicyear] = tempObj;
                }
              }
            }
            if (tempDropdownData && tempDropdownData.length) {
              setSelectedFilterDate(tempDropdownData[0]);
            }
            setDropdowndata(tempDropdownData);
            setReports(tempReports);
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }, []);

      useEffect(() => {
        var tempIncludedObj = {};
        var tempReports = [];
        axios({
          method: "get",
          url:
            config.serverUrl.base +
            "/jsonapi/node/annexure_to_board_s_report?include=field_file_",
        })
          .then((res) => {
            console.log(res);
            for (const item of res.data.included) {
              tempIncludedObj[item.id] =
                config.serverUrl.imageBase + item.attributes.uri.url;
            }
            for (const report of res.data.data) {
              tempReports.push({
                title: report.attributes.title,
                url: tempIncludedObj[report.relationships.field_file_.data.id],
              });
            }
            setAnnexurereports([...tempReports]);
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }, []);

    // useEffect(() => {
    //     const htmlEl = document.querySelector('html')
    //     htmlEl.dataset['wfPage'] = '60952b8da0e26580fc1fde12'
    //     htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    //     scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
    //         const loading = active.loading.then((script) => {
    //             new Function(`
    //           with (this) {
    //             eval(arguments[0])
    //           }
    //         `).call(window, script)
    //             return next
    //         })
    //         // if (active.lastOne) {
    //         //     this.setState({ renderPageBool: true })
    //         // }
    //         return active.isAsync ? next : loading
    //     }))
    // });

    const minWidthBool = useMediaQuery({ query: "(min-device-width: 900px)" });

    return <SubContainer>

        <TextContainer>
            {minWidthBool ? (
                <TextHeading>
                    Annual Report {selectedFilterDate}
                </TextHeading>
            ) : (
                <TextHeading>Annual Report {selectedFilterDate}</TextHeading>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
                {reports?.[selectedFilterDate]?.annual &&
                    reports?.[selectedFilterDate]?.annual.map((item, index) => {
                        return (<TextContent
                            key={selectedFilterDate + index}
                            to={{ pathname: item.url }}
                            target="_blank"
                        // onClick={() => {
                        //     downloadFile(item.url);
                        // }}
                        >
                            {item.title}
                        </TextContent>
                        );
                    })}
                {reports?.[selectedFilterDate]?.subsidiary &&
                    reports?.[selectedFilterDate]?.subsidiary.map((item, index) => {
                        return (<TextContent
                            key={selectedFilterDate + index}
                            // onClick={() => {
                            //     downloadFile(item.url);
                            // }}
                            to={{ pathname: item.url }}
                            target="_blank"
                        >
                            {item.title}
                        </TextContent>
                        );
                    })}
            </div>
        {Annexurereports.length > 0 && <div style={{ marginTop: '50px' }} >
          <TextHeading>Annexures</TextHeading>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Annexurereports.map((item, index) => {
              return (<TextContent
                key={"Annexurereports" + index}
                // onClick={() => {
                //     downloadFile(item.url);
                // }}
                to={{ pathname: item.url }}
                target="_blank"
              >
                {item.title}
              </TextContent>
              );
            })}
          </div>
        </div>}
        </TextContainer>

        <DropDownDiv>
            <Cdropdown
                data={dropdownData}
                placeholder="Year"
                id="reports"
                selectedValue={selectedFilterDate}
                onChangeValue={setSelectedFilterDate} />
        </DropDownDiv>

    </SubContainer>
}
