import React from "react";
import styled from "styled-components";

import { HR } from "../../common/horizontalLine";
import { EnquiryForm } from "../../../Common/ContactForm";

const MainContainer = styled.div`
  padding: 0% 0%;
  margin-top:40px;
  background-color: #ededed;
`;

// const Heading = styled.h2`
//   margin: 0;
//   color: #000000;
//   font-size: 40px;
//   font-family: Drlcircular;
//   font-weight: 300;
//   line-height: 1;
//   margin-bottom: 35px;
// `;

export function PostAnEnquiryView(props) {
  return (
    <MainContainer>
      <EnquiryForm />
    </MainContainer>
  );
}
