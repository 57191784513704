import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { EnquiryForm } from "./ContactForm";

const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;
const Main = styled.div`
  width: 100%;
  margin-top:40px;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 5px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 40px;
  padding-left: 25px;
  // justify-content: space-between;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.8;
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1x solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
    }
  }
`;

export const ShareholderInformation = (props) => {

  return (<Main>
    <EnquiryForm />
  </Main>
  )

}