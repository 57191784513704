import React from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";

const TextInputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

const TextInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
`;

const TextInputEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormHeading = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 70px;
`;

const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  margin: 0;
  margin-top: 10px;
`;

const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;

const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
  margin-right: 40px;
  width: 140px;
`;

const Input = styled.input`
  border: 0px;
  background-color: #ffffff99;
  height: 50px;
  width: 50%;
  font-family: Drlcircular;
  &:focus {
    outline: none !important;
    border: 1px solid #5225b5;
    border-radius: 2px;
  }
`;

// const TextArea = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 100%;
// `;

// const TextAreaInput = styled.textarea`
//   border: 0px;
//   background-color: #ffffff99;
//   height: 250px;
//   font-family: Drlcircular;
//   resize: none;
//   width: 82%;
//   padding: 10px;
//   &:focus {
//     outline: none !important;
//     border: 1px solid #5225b5;
//     border-radius: 2px;
//   }
// `;

// const DropDown = styled.a`
//   background-color: #fff;
//   height: 50px;
//   width: 50%;
//   display: flex;
//   flex-direction: row;
//   padding: 15px;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;
//   /* border:1px solid #5225B5;
//     border-radius: 2px; */
// `;

// const DropDwonLabel = styled.p`
//   font-size: 16px;
//   color: #000000;
//   font-weight: 500;
//   font-family: Drlcircular;
//   margin: 0;
// `;

const AcceptPolicySection = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  cursor: pointer;
  margin-bottom: 40px;
  margin-top: 8%;
`;

const AcceptTextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  margin: 0;
`;

const AcceptTextLink = styled.a`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
`;

const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: #5225b5 solid 0.5px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  margin-right: 15px;
`;

const RadioActive = styled.div`
  background-color: #ff5046;
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

const RobotText = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  margin: 0;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15% 0;
`;

// const ClearButton = styled.a`
//   font-size: 22px;
//   color: #000000;
//   font-weight: 500;
//   font-family: Drlcircular;
//   background-color: #9090905d;
//   border-radius: 5px;
//   padding: 25px 120px;
//   cursor: pointer;
//   margin-right: 25px;
// `;

const SubmitButton = styled.a`
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
  font-family: Drlcircular;
  background-color: #5225b5;
  border-radius: 5px;
  padding: 25px 110px;
  cursor: pointer;
`;

export function ShareholderInformationform(props) {
  return (
    <FormSection>
      <FormHeading>
        <TextBold>NAME OF THE SOLE/FIRST SHAREHOLDER:</TextBold>
        <TextLight>* Required fields</TextLight>
      </FormHeading>

      {/* <TextInputRow> */}
      {/* <TextInput>
          <Label style={{ fontSize: "18px" }}>Title</Label>
          <DropDown>
            <DropDwonLabel>SELECT YEAR</DropDwonLabel>
            <FiChevronDown size={25} />
          </DropDown>
        </TextInput> */}
      {/* <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Organization</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow> */}

      {/* <TextInputRow> */}
      {/* <TextInput>
          <Label style={{ fontSize: "18px" }}>
            Relationship With Dr. Reddy’s
          </Label>
          <DropDown>
            <DropDwonLabel>SELECT YEAR</DropDwonLabel>
            <FiChevronDown size={25} />
          </DropDown>
        </TextInput> */}
      {/* <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Address 1</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow> */}

      {/* <TextInputRow> */}
      {/* <TextInput>
          <Label style={{ fontSize: "18px" }}>
            Purpose for contacting Dr. Reddy’s
          </Label>
          <DropDown>
            <DropDwonLabel>SELECT YEAR</DropDwonLabel>
            <FiChevronDown size={25} />
          </DropDown>
        </TextInput> */}
      {/* <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Address 2</Label>
          <Input type="input" />
        </TextInputEnd> */}
      {/* </TextInputRow> */}

      <TextInputRow>
        <TextInput>
          <Label style={{ fontSize: "18px" }}>First Name*</Label>
          <Input type="input" />
        </TextInput>
        <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Mobile No*</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow>

      <TextInputRow>
        <TextInput>
          <Label style={{ fontSize: "18px" }}>Last Name*</Label>
          <Input type="input" />
        </TextInput>
        <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Office/Residence*</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow>

      <TextInputRow>
        <TextInput>
          <Label style={{ fontSize: "18px" }}>Address*</Label>
          <Input type="input" />
        </TextInput>
        <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>Email*</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow>

      <TextInputRow>
        <TextInput>
          <Label style={{ fontSize: "18px" }}>DP ID/Client ID/Folio No*</Label>
          <Input type="input" />
        </TextInput>
        <TextInputEnd>
          <Label style={{ fontSize: "18px" }}>PAN*</Label>
          <Input type="input" />
        </TextInputEnd>
      </TextInputRow>

      <TextInputRow>
        <AcceptPolicySection>
          <Label style={{ fontSize: "18px" }}>Mode of holding</Label>
          <RadioButton>
            <RadioActive />
          </RadioButton>
          <AcceptTextLight> Physical</AcceptTextLight>
          <RadioButton />
          <AcceptTextLight>Electronic</AcceptTextLight>
        </AcceptPolicySection>
      </TextInputRow>

      {/* <TextArea>
        <Label style={{ fontSize: "18px" }}>Your Message Or question*</Label>
        <TextAreaInput placeholder="Type your msg here" />
      </TextArea> */}

      <AcceptPolicySection>
        <RadioButton>
          <RadioActive />
        </RadioButton>
        <AcceptTextLight>
          I agree and accept the{" "}
          <AcceptTextLink href="/privacy-policy">Privacy Notice</AcceptTextLink>{" "}
          and <AcceptTextLink href="terms-of-use">Terms of Use</AcceptTextLink>{" "}
          of this website
        </AcceptTextLight>
      </AcceptPolicySection>
      <RobotText>Please confirm that you are not a robot</RobotText>
      <ButtonSection>
        <SubmitButton>Submit</SubmitButton>
      </ButtonSection>
    </FormSection>
  );
}
