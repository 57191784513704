import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const WeAreReddys = ({ data }) => {

    const [currentPage, SetCurrentPage] = React.useState(0);

    const [leftDisabled, SetLeftDisabled] = React.useState(true);
    const [rightDisabled, SetRightDisabled] = React.useState(false);

    const isMobile = useMediaQuery({ query: "(max-width: 820px)" });

    return (
        !isMobile && <div id="we-are-drreddys" data-w-id="ac2b7f71-6c4e-be07-c418-51677fd64972" className="af-class-section af-class-drag-slider-section" style={{ paddingTop:'120px', paddingBottom: '120px',  backgroundColor: '#EDEDED' }}>
            <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" style={{ opacity: 0 }} className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">#WeAreDrReddys</h2>
                <div className="af-class-drag-slider-wrapper">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask w-slider-mask">
                            {
                                data.map((item, index) => {

                                    var pageId = "93c978e6-0303-b12f-c2a5-8a97597897a7";

                                    if (index > 5) {
                                        {/* <div key={"OurBusinesses:" + index} > */ }
                                        pageId = "ae2c330c-3b91-6fe2-999b-e87e004b1901";
                                    }
                                    else if (index > 2) {
                                        pageId = "ae2c330c-3b91-6fe2-293a-e87e004b1901";
                                    }
                                    else {
                                        pageId = "93c978e6-0303-b12f-c2a5-8a97597897a7";
                                    }

                                    return (
                                        <div data-w-id={pageId} className="af-class-drag-slider-item af-class-promise w-slide">
                                            <a
                                                href={`/articles?${item.contentId}#${item.id}`}
                                                // href={"/articles"}
                                                //     onClick={() => {
                                                //         localStorage.setItem("data", JSON.stringify(item))
                                                //     }} 
                                                className="af-class-slide-div w-inline-block">
                                                <div data-w-id="cf56d6bd-601b-bd42-cc39-d6ef188cbc79" className="af-class-slide-image-and-text-block">
                                                    <div className="af-class-drag-slider-item-thumbnail"><img src={item.thumbnail} loading="lazy" alt className="af-class-drag-slider-item-image-animation" /></div>
                                                    <div className="af-class-drag-slider-item-heading">{item.title}</div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                    {/* </div> */ }
                                })
                            }
                        </div>



            <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: leftDisabled ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={(e) => { SetRightDisabled(false); SetLeftDisabled(true); }}>
              {
                leftDisabled ?
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                  </>
                  :
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
              }

            </div>
            <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: rightDisabled ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={(e) => { SetRightDisabled(true); SetLeftDisabled(false); }}>
              {
                rightDisabled ?
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
                  :
                  <>
                    <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                  </>
              }
            </div>


                 <div className="af-class-slide-nav w-slider-nav w-round" />
                    </div>
                </div>
                <div className="af-class-drag-slider-progress-bar">
          <div className="af-class-drag-progress-fill af-class-fifty" />
        </div>
            </div>
        </div>
    )
}

export default WeAreReddys;