/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

import MenuSection from '../Common/MenuSection';
import FooterSection from '../Common/FooterSection';

import HeroBannder from '../Common/LifeAtDrl/HeroBanner';
import HumansOfDrReddysMobile from '../Common/LifeAtDrl/HumansOfDrReddysMobile';
import EmploymentEnrichmentProgrammesSlider from '../Common/MobileSlider/EmploymentEnrichmentProgrammesSlider';

import axios from 'axios';
import config from '../config';

import { withCookies, Cookies } from 'react-cookie';
import { CookiesPopup } from '../Common/Cookiespopup';
import { Carousel } from 'react-responsive-carousel';

import { isTab, mediaQueryRangeTab } from '../Utils/Utils';
import styled from 'styled-components';
import { HR } from "../pages/common/horizontalLine";

const isTabsEnable = true

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;

const MainTabContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isTabsEnable }) => (isTabsEnable ? "0%" : "15%")};
  justify-content: space-between;
`;


const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 40px !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
`;

const TabsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  margin-right: 4%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: ${({ selected }) => (selected ? '#5225B5' : '#909090')};
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  min-width: 260px;
  flex-grow: 1;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const MobileTabItem = styled.a`
  cursor: pointer;
  text-decoration:none !important;
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : 400)} ;
  /* opacity: ${({ selected }) => (selected ? 1 : 0.7)}; */
  // padding-bottom: 5px;
  // border-bottom-width: ${({ selected }) => (selected ? "1px" : 0)};
  // border-bottom-style: solid;
  // border-bottom-color: #000000;
  &:hover {
    text-decoration: none;
      color:  #5225B5;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
`;


const HambergerButton = styled.a`
  /* using following code for increasing clickable area */
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 2em;
  margin: -2em;
  cursor: pointer;
`;

const HambergerIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  display: block;
  background-color: #ededed;
  top: 0;
  left: 0;
  padding-top: 20%;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  margin-top: 10px;
  &::before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(45deg);
  }
  &::after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
    transform: rotate(-45deg);
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TabMenuItem = styled.a`
  cursor: pointer;
  font-size: 16px;
  color:  ${({ selected }) => (selected ? "#5225B5" : "#000")};;
  text-decoration: none;
  font-family: Drlcircular;
  font-weight: ${({ selected }) => (selected ? "bold" : "300")};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  padding-bottom: 5px;
  text-decoration:none !important;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

// const isTabsEnable = useMediaQuery({ query: "(min-device-width: 1000px)" });

const TabData = [
  {
    id: 1, name: "Gold Medal status by EcoVadis", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 2, name: "Dow Jones Sustainability Index", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 3, name: "ET HR Employee Experience Award", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 4, name: "Great Place to Work – Myanmar", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 5, name: "Great Place to Work – Malaysia", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 6, name: "Top 20 employers in Pharma/Biotech globally", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 7, name: "Company of the Year – Americas", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 8, name: "Best Places to Work in New Jersey", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 9, name: "Great Place to Work", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 10, name: "Candidate Experience Award", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 11, name: "Great Place to Work - China", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 12, name: "Asia-Pacific Climate Leader 2024 by the Financial Times (London)", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 13, name: "Winner in the ‘Learning Champion’ category at the LinkedIn Talent Awards India 2022", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 14, name: "Great Place to Work - Brazil", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 15, name: "Among Best Organisations for Women 2023", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 16, name: "Great Place To Work – Thailand", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 17, name: "Best-in-Class Employee Benefits Provider in North America", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 18, name: "Among Top 3 in pharma in Russia", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 19, name: "Top Employer South Africa", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 20, name: "ICAI Awards – for Excellence in Financial Reporting for 2021-2022", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 21, name: "Recognised as Masters of Risk - Healthcare and Pharma", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 22, name: "Bloomberg GEI", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 23, name: "Sustainability Yearbook", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 24, name: "Great Place to Work - Colombia", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 25, name: "Dr. Reddy's Foundation recognised by the President of India for GROW for Persons with Disabilities!", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 26, name: "'Best Firm For Data Scientists' to work for by Analytics India Magazine (AIM)!", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 27, name: "AmbitionBox Best Places to Work in India", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 28, name: "IGBC Green Champions", bgColor: '#fff', hashValue: '', subMenuTabData: []
  },
  {
    id: 29, name: "UN Women Empowerment Principles Awards", bgColor: '#fff', hashValue: '', subMenuTabData: []
  }
];

const scripts = [
  { loading: fetch("/webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: fetch("/webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
  { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
]

let Controller

class LifeAtDrlView extends React.Component {
  state = {
    articleData: {},
    renderPageBool: false,
    currentPage: 0,
    cookieBool: true,
    selectedCarousalTab: 0,
    leftDisabled: false,
    rightDisabled: false,
    selectedTab: TabData[0],
    selectedSubTab: 1,
    tabData: TabData,
  }

  constructor(props) {
    super(props);
    this.handleLeftClick = this.handleLeftClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.getCarouselItems = this.getCarouselItems.bind(this);
    this.handleLeftTabClick = this.handleLeftTabClick.bind(this);
    this.handleRightTabClick = this.handleRightTabClick.bind(this);
    this.handleLeftAwardsClick = this.handleLeftAwardsClick.bind(this);
    this.handleRightAwardsClick = this.handleRightAwardsClick.bind(this);
    this.getTabContent = this.getTabContent.bind(this);
    this.getTabImage = this.getTabImage.bind(this);
    this.handleLeftMenuClick = this.handleLeftMenuClick.bind(this);
    this.handleRightMenuClick = this.handleRightMenuClick.bind(this);
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/LifeAtDrlController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = LifeAtDrlView

        return Controller
      }
      throw e
    }
  }


  handleLeftMenuClick() {
    document.getElementById('tab-section').scrollLeft -= 285;
  };

  handleRightMenuClick() {
    document.getElementById('tab-section').scrollLeft += 285;
  };


  handleLeftAwardsClick() {
    document.getElementById('tab-section').scrollLeft -= 285;

    var newTab = this.state.selectedTab.id - 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;
    if (newTab < 1) { newTab = 1 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab <= 1) {
      right = false;
      left = true;
    }

    if (newTab == 9 || newTab == 10) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };

  handleRightAwardsClick() {
    document.getElementById('tab-section').scrollLeft += 285;
    var newTab = this.state.selectedTab.id + 1;
    var right = this.state.rightDisabled, left = this.state.leftDisabled;

    if (newTab > 29) { newTab = 29 };
    this.setState({ selectedTab: this.state.tabData[newTab - 1] });

    if (newTab == 29) {
      right = true;
      left = false;
    }

    if (newTab == 2 || newTab == 3) {
      right = false;
      left = false;
    }

    this.setState({ leftDisabled: left, rightDisabled: right });
  };

  handleLeftTabClick() {
    console.log('came here...');

    var newTab = this.state.selectedCarousalTab - 1;
    // var right = this.state.rightDisabled, left = this.state.leftDisabled;
    if (newTab < 0) { newTab = 0 };
    this.setState({ selectedCarousalTab: newTab });

    // if (newTab == 0) {
    //   right = false;
    //   left = true;
    // }

    // if (newTab == 1 || newTab == 2) {
    //   right = false;
    //   left = false;
    // }

    // this.setState({ leftDisabled: left, rightDisabled: right });
  };

  handleRightTabClick() {
    console.log('came to right click...');
    var newTab = this.state.selectedCarousalTab + 1;
    // var right = this.state.rightDisabled, left = this.state.leftDisabled;

    if (newTab > 3) { newTab = 3 };
    this.setState({ selectedCarousalTab: newTab });

    // if (newTab == 3) {
    //   right = true;
    //   left = false;
    // }

    // if (newTab == 1 || newTab == 2) {
    //   right = false;
    //   left = false;
    // }

    // this.setState({ leftDisabled: left, rightDisabled: right });
  };



  handleLeftClick() {
    var pageCount = this.state.currentPage - 1;
    this.setState({ currentPage: pageCount });
  }

  handleRightClick() {
    var pageCount = this.state.currentPage + 1;
    this.setState({ currentPage: pageCount });
  }

  getTabContent(selectedTab) {

    switch (selectedTab.id) {
      case 1: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Gold Medal status by EcoVadis
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been awarded ‘Gold Medal’ status by EcoVadis, the global sustainability ratings agency, for its score of 72 out of 100 in its scorecard for 2023.
        </p>
      </>)
      case 2: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Dow Jones Sustainability Index
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have become the first Indian pharma company to earn a place in the Standard & Poor Dow Jones Sustainability World Index (DJSI World) for 2023. Alongside our debut in DJSI World, we also retain our place in the Dow Jones Sustainability Index for Emerging Markets (DJSI EM) for the 8th year in a row.
        </p>
      </>)
      case 3: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          ET HR Employee Experience Award
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been awarded the ET HR Employee Experience Award in the Large Enterprises category! The award recognises organisations that craft meaningful experiences for their employees. This achievement is a reflection of our ongoing commitment to creating a workplace that encourages colleagues to realise their full potential and contribute to our collective success, guided by our employee experience tenets of being connected, efficient, and joyful.
        </p>
      </>)
      case 4: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work – Myanmar
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to our Myanmar team for having earned the 'Great Place to Work' certification!
        </p>
      </>)
      case 5: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work – Malaysia
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to our Malaysia team for having earned the 'Great Place to Work' Certification! This recognition is a testament to the inclusive people practices, talent and capability of our Malaysia team.
        </p>
      </>)
      case 6: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Top 20 employers in Pharma/Biotech globally
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been recognised by the prestigious Science Magazine for the second year in a row in its global top 20 pharma employers list. This year, we are placed at number 16, marking a year-on-year improvement of two places from last year's rank of 18. 
        </p>
      </>)
      case 7: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Company of the Year – Americas
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          For the second year in a row Team Dr. Reddy's Latin America (part of our Emerging Markets business) has been recognised as ‘Company of the Year – Americas’ at the prestigious Global Generics & Biosimilars Awards 2023!
        </p>
      </>)
      case 8: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Best Places to Work in New Jersey
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been recognised as one of the 'Best Places to Work in New Jersey'. The award was given by the Best Companies Group based on two surveys - an employer questionnaire and an employee engagement and satisfaction survey. We were recognised in the 'Large Companies' category. The award is a recognition of our work culture and environment, and of the employer brand that we have created.
        </p>
      </>);
      case 9: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to all our teams for having earned the 'Great Place to Work' certification!
        </p>
      </>);
      case 10: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Candidate Experience Award
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have won the Candidate Experience Award 2023 in the U.S. for the 5th year in a row.<br />
          The Candidate Experience Award is a recognition by the Talent Board Council Research Program of North America. Talent Board and the Candidate Experience Awards focus on the elevation and promotion of a quality candidate experience with industry benchmarks that highlight accountability, fairness, and business impact.
        </p>
      </>);
      case 11: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work - China
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to our China team for having earned the 'Great Place to Work' Certification! This recognition is a testament to the inclusive people practices, talent and capability of our China team.
        </p>
      </>);
      case 12: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Asia-Pacific Climate Leader 2024 by the Financial Times (London)
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          The Financial Times (London) has named us an ‘Asia-Pacific Climate Leader' in its 2024 list. The annual study is compiled by Financial Times and Statista. Asia-Pacific Climate Leaders 2024 is a list of 350 Asia-Pacific companies that have achieved the greatest reduction in their core emissions intensity over the 5-year period between 2017 and 2022.
        </p>
      </>);
      case 13: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Winner in the ‘Learning Champion’ category at the LinkedIn Talent Awards India 2022
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been adjudged as the winner in the ‘Learning Champion’ category at the LinkedIn Talent Awards India 2022! This achievement reflects our dedication in driving capability development across the organisation, benchmarked against the best organisations across the country.
        </p>
      </>);
      case 14: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work - Brazil
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to our Brazil team for having earned the 'Great Place to Work' Certification! This recognition is a testament to the inclusive people practices, talent and capability of our Brazil team.
        </p>
      </>);
      case 15: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Among Best Organisations for Women 2023
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been recognised as one of the ‘Best Organisations for Women 2023’ by The Economic Times, powered by Femina! The recognition spotlights businesses that have shown a commitment to advancing diversity and inclusion in corporate India.
        </p>
      </>);
      case 16: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place To Work – Thailand
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to Team Thailand on earning the Great Place To Work certification. This recognition is a testament to the inclusive people practices, talent and capability of our Thailand team.
        </p>
      </>);
      case 17: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Best-in-Class Employee Benefits Provider in North America
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          In North America, we have been recognised as a Best-in-Class Employee Benefits Provider by Gallagher, a global insurance and consulting company.
        </p>
      </>);
      case 18: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          HeadHunter's Russia Best Employer Ranking for 2023
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been recognised as the number 1 employer in HeadHunter's Russia Best Employer Ranking for 2023 in the medium-sized companies category in medicine and pharmaceuticals. We are also among the top 20 employers among all mid-sized companies. We have climbed from the 69th position in 2022 to an 11th position in 2023, in keeping with our commitment to continuous improvement.
        </p>
      </>);
      case 19: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Top Employer South Africa
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been recognised as a Top Employer 2024 in South Africa. Being certified as a top employer showcases our dedication to building a holistic work culture with excellent people practices. Congratulations to Team South Africa!
        </p>
      </>);
      case 20: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          ICAI Awards – for Excellence in Financial Reporting for 2021-2022
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have won the prestigious Gold Shield in our category from the Institute of Chartered Accountants of India (ICAI) for Excellence in Financial Reporting for 2021-22! Our Annual Report and financial statement for FY'22 was adjudged as the Gold Shield winner in its category on the basis of compliance with Indian Accounting Standards, statutory guidelines and regulations.
        </p>
      </>);
      case 21: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Recognised as Masters of Risk - Healthcare and Pharma
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have won the 'Masters of Risk - Healthcare and Pharma' category award at the India Risk Management Awards! This achievement comes as a recognition of our innovative and strategic approach to risk management even in times of uncertainty.
        </p>
      </>);
      case 22: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Bloomberg GEI
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          For the 6th year in a row, we have been included in the Bloomberg LP Gender-Equality Index (GEI) 2023, featuring as the only Indian pharma company. The index measures gender equality across internal company statistics, employee policies, external community support, engagement, and gender-conscious product offerings.
        </p>
      </>);
      case 23: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Sustainability Yearbook
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          We have been included in S&P Global's Sustainability Yearbook in the Top 10% category for the first time based on our 2023 Corporate Sustainability Assessment (CSA) score. We are placed 6th globally in pharma in the CSA. We have been a member of the S&P Sustainability Yearbook for four years in a row.
        </p>
      </>);
      case 24: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Great Place to Work - Colombia
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Congratulations to Team Dr. Reddy’s Colombia on earning the Great Place To Work certification second year in a row. This recognition is a testament to the inclusive people practices, talent and capability of our Colombia Team.
        </p>
      </>)
      case 25: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Dr. Reddy's Foundation recognised by the President of India for GROW for Persons with Disabilities!
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Dr. Reddy’s Foundation, a not-for-profit organisation, was conferred with the prestigious National Award for the Empowerment of Persons with Disabilities in New Delhi by the Honourable President of India. Dr. Reddy’s Foundation received the award under the category 'Best Placement Agency for Persons with Disabilities' as recognition of its contribution to the empowerment of persons with disabilities through its community initiative ‘GROW for Persons with Disabilities’ (GROW PwD).
        </p>
      </>);
      case 26: return (
        <>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            'Best Firm For Data Scientists' to work for by Analytics India Magazine (AIM)!
          </p>
          <p ref={(el) => {
            if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
              if (isTab) {
                el.style.setProperty('font-size', '20px', 'important');
                el.style.setProperty('line-height', '32px', 'important');
                el.style.setProperty('max-width', '100%', 'important');
              } else {
                el.style.setProperty('line-height', '24px', 'important');
                el.style.setProperty('font-size', '14px', 'important');
              }
            }
          }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
            The Best Firm For Data Scientists certification is based on a survey that was rolled out to our colleagues in analytics. AIM analyses the survey data to gauge employees’ approval ratings and uncover actionable insights. The aim of the exercise is to identify and recognise organisations with great company cultures.
          </p>
        </>
      )
      case 27: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          AmbitionBox Best Places to Work in India
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Recognised by the AmbitionBox Best Places to Work in India Award 2022! We have been ranked among the top 25 in the 'Large Companies’ category. The 2022 award is based on reviews and ratings by our own colleagues on the AmbitionBox platform in 2021.
        </p>
      </>);
      case 28: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          IGBC Green Champions
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          Our manufacturing team has won the prestigious and highest-possible Indian Green Building Council (IGBC) Green Champion Award 2022 under the category 'Pioneer in large-scale adoption of green factory buildings in India’!
        </p>
      </>);
      case 29: return (<>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ fontWeight: 'bold', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          UN Women Empowerment Principles Awards
        </p>
        <p ref={(el) => {
          if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
            if (isTab) {
              el.style.setProperty('font-size', '20px', 'important');
              el.style.setProperty('line-height', '32px', 'important');
              el.style.setProperty('max-width', '100%', 'important');
            } else {
              el.style.setProperty('line-height', '24px', 'important');
              el.style.setProperty('font-size', '14px', 'important');
            }
          }
        }} className="af-class-paragraph af-class-_450" style={{ marginTop: '24px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >
          In October 2021, we were 2nd runner-up in the United Nations Women’s Empowerment Principles (WEPs) Gender-inclusive Workplace category in Asia-Pacific. We recently joined the United Nations Women Empowerment Principles as a signatory.
        </p>
      </>);
      default: return (<div></div>)
    }
  }

  getTabImage(selectedTab) {
    switch (selectedTab.id) {
      case 1: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/EcoVadis_Story.png" : "/images/EcoVadis_Story.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 2: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/DJSI_Story.png" : "/images/DJSI_Story.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 3: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/ET_HR_Award.png" : "/images/ET_HR_Award.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 4: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Myanmar_GPTW.png" : "/images/Myanmar_GPTW.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 5: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/GPTW_Malaysia.png" : "/images/GPTW_Malaysia.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 6: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Science_Mag.png" : "/images/Science_Mag.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 7: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/GGB_Awards.png" : "/images/GGB_Awards.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 8: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/new_jersey_2023.png" : "/images/new_jersey_2023.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 9: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/WADR_Stories.png" : "/images/WADR_Stories.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 10: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Group_1264.png" : "/images/Group_1264.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 11: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/greate-work-china.png" : "/images/greate-work-china.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 12: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Asia_Pacific_Leader_2024.png" : "/images/Asia_Pacific_Leader_2024.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 13: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/LnDAward.png" : "/images/LnDAward.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 14: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/GPTW_Brazil_2024.jpg" : "/images/GPTW_Brazil_2024.jpg"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : {maxWidth: "250px", margin: "auto"}} />
        </div>
      );
      case 15: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Best_Org_For_Women_Badge.png" : "/images/Best_Org_For_Women_Badge.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 16: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Thailand_2023_Certification_Badge.png" : "/images/Thailand_2023_Certification_Badge.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 17: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/NA_logo.png" : "/images/NA_logo.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 18: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Russia_Award.png" : "/images/Russia_Award.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 19: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Top_Employer_South_Africa_2024.png" : "/images/Top_Employer_South_Africa_2024.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 20: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/ICAI.png" : "/images/ICAI.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 21: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/IRMA.png" : "/images/IRMA.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 22: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Bloomberg.png" : "/images/Bloomberg.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 23: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/sustainability_yearbook_member.jpg" : "/images/sustainability_yearbook_member.jpg"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 24: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Group_1275_1.png" : "/images/Group_1275_1.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 25: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Story_awards.jpeg" : "/images/Story_awards.jpeg"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 26: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Group_1262.png" : "/images/Group_1262.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      )
      case 27: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Group_1266.png" : "/images/Group_1266.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 28: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/IGBC.png" : "/images/IGBC.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      case 29: return (
        <div ref={(el) => {
          if (el && isTab) {
            el.style.setProperty('height', '280px', 'important')
          }
        }} className='af-class-scroll-video-block' style={{
          height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "4%",
          marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
          marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
        }}>
          <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/Group_1270.png" : "/images/Group_1270.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
        </div>
      );
      default: return (<div></div>)
    }
  };

  UNSAFE_componentWillMount() {

    var link = document.createElement('meta');
    link.setAttribute('name', 'keywords');
    link.content = "pharmaceutical company, affordable medicine, pharmaceutical services, pharmaceutical industry, drugs manufacturer, dr reddys";
    document.getElementsByTagName('head')[0].appendChild(link);

    var tempObj = {};
    var section1 = [];
    var section2 = [];
    axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/lifeatdrlarticles?sort=created&&include=field_coverimage", })
      .then((res) => {
        const { data, included } = res.data;
        var imgPathsObj = {};
        for (var imgObj of included) {
          imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
        }
        for (var item of data) {
          tempObj = {
            id: item.id,
            contentId: '9',
            title: item.attributes.title,
            descTitle: item.attributes.field_descriptiontitle,
            coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
            // thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
            description: item.attributes.field_description ? item.attributes.field_description.processed : "",
            content: item.attributes.field_content ? item.attributes.field_content.processed : "",
          }
          // if (item.attributes.field_lifeatdrlcategories === "lifeAtDRL-1") {
          section1.push(tempObj);
          // } else if (item.attributes.field_lifeatdrlcategories === "lifeAtDRL-2") {
          //   section2.push(tempObj);
          // }
        }




        axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/lifeatdrlcarousalarticles?sort=created&&include=field_coverimage", })
          .then((res1) => {
            const { data, included } = res1.data;
            var carousal1 = [];
            var carousal2 = [];
            var carousal3 = [];
            var imgPathsObj = {};
            for (var imgObj of included) {
              imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
            }
            for (var item of data) {
              tempObj = {
                id: item.id,
                contentId: '7',
                title: item.attributes.title,
                coverImage: item.relationships.field_coverimage.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_coverimage.data.id] : "",
                // thumbnail: item.relationships.field_thumbnail.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_thumbnail.data.id] : "",
                // description: item.attributes.field_description ? item.attributes.field_description.processed : "",
                content: item.attributes.field_content ? item.attributes.field_content.processed : "",
              }
              if (item.attributes.field_lifeatdrlcategories === "carousal1") {
                carousal1.push(tempObj);
              } else if (item.attributes.field_lifeatdrlcategories === "carousal2") {
                carousal2.push(tempObj);
              } else if (item.attributes.field_lifeatdrlcategories === "carousal3") {
                carousal3.push(tempObj);
              }
            }
            console.log({
              section1, section2: { carousal1, carousal2, carousal3 }
            })
            this.setState({
              articleData: {
                section1, section2: { carousal1, carousal2, carousal3 }
              }
            }, () => {
              this.loadScripts();
            })
          }).catch((Err1) => {
            this.loadScripts();
            console.log(Err1, "-=-=-=  ERR")
          })



      }).catch((Err) => {
        this.loadScripts();
        console.log(Err, "-=-=-=  ERR")
      })
  }


  loadScripts() {

    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60d18d617d2b4368bdd5ac66'
    htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
            with (this) {
              eval(arguments[0])
            }
          `).call(window, script)

        return next
      })
      if (active.lastOne) {
        this.setState({ renderPageBool: true })

        window.scrollTo({ top: window.scrollY - 1, left: 0, });
        window.scrollTo({ top: window.scrollY + 1, left: 0, });

      }
      return active.isAsync ? next : loading
    }))
    // window.Webflow && window.Webflow.destroy(); window.Webflow && window.Webflow.ready(); window.Webflow && window.Webflow.require('ix2').init(); document.dispatchEvent(new Event('readystatechange')) window.Webflow && window.Webflow.destroy(); window.Webflow && window.Webflow.ready(); window.Webflow && window.Webflow.require('ix2').init(); document.dispatchEvent(new Event('readystatechange'))

  }

  componentDidMount() {
    // window.scrollTo({top: window.scrollY-1,left: 0,behavior: 'smooth'});
    // window.scrollTo({top: window.scrollY+1,left: 0,behavior: 'smooth'});

  }

  getCarouselItems(id) {
    // console.log('------------selected tab = ', id);
    switch (id) {
      case 0:
        return <>
          <p className="af-class-paragraph af-class-_450">We don’t just pay attention to the work our people do; we also embrace their challenges as our own. Because a family that sticks together stays together.</p>
          <div className="w-dyn-list">
            <div role="list" className="w-dyn-items">
              {
                this.state.articleData.section2 && this.state.articleData.section2.carousal1 && this.state.articleData.section2.carousal1.length !== 0
                  ?
                  this.state.articleData.section2.carousal1.map((item, index) => {
                    return (
                      <div key={"section2__carousal1" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                        <div className="af-class-testimonial-text-block">
                          <a className='underline'
                            href={`/articles?${item.contentId}#${item.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            {/* <div className="af-class-testimonial-text">{item.title}</div> */}
                            <span style={{ color: 'black', fontWeight: 700 }}>{item.title}</span><svg viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg>
                          </a>
                          <div />
                        </div>
                      </div>
                    )
                  })
                  :
                  <div></div>
              }
            </div>
          </div>
        </>
      case 1:
        return <> <p className="af-class-paragraph af-class-_450">The key to healthy living is balancing work and life outside work in individual ways that bring out the best in us.</p>
          <div className="w-dyn-list">
            <div role="list" className="w-dyn-items">
              {
                this.state.articleData.section2 && this.state.articleData.section2.carousal2 && this.state.articleData.section2.carousal2.length !== 0
                  ?
                  this.state.articleData.section2.carousal2.map((item, index) => {
                    return (
                      <div key={"section2__carousal2" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                        <div className="af-class-testimonial-text-block">
                          <a className='underline'
                            href={`/articles?${item.contentId}#${item.id}`}
                            // href={"/articles"}
                            //   onClick={() => {
                            //     localStorage.setItem("data", JSON.stringify(item))
                            //   }}
                            style={{ textDecoration: 'none' }} >
                            {/* <div className="af-class-testimonial-text">{item.title}</div> */}
                            <span style={{ color: 'black', fontWeight: 700 }}>{item.title}</span><svg viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg>
                          </a>
                          <div />
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="w-dyn-empty">
                  </div>
              }
            </div>
          </div>
        </>
      case 2:
        return <> <p className="af-class-paragraph af-class-_450">Dr. Reddy’s is a melting pot of diverse people and cultures. Inclusivity is one of our core values and we celebrate each employee’s uniqueness with pride.</p>
          <div className="w-dyn-list">
            <div role="list" className="w-dyn-items">
              {
                this.state.articleData.section2 && this.state.articleData.section2.carousal3 && this.state.articleData.section2.carousal3.length !== 0
                  ?
                  this.state.articleData.section2.carousal3.map((item, index) => {
                    return (
                      <div key={"section2__carousal3" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                        <div className="af-class-testimonial-text-block">
                          <a className='underline'
                            href={`/articles?${item.contentId}#${item.id}`}
                            // href={"/articles"}
                            //   onClick={() => {
                            //     localStorage.setItem("data", JSON.stringify(item))
                            //   }}
                            style={{ textDecoration: 'none' }} >
                            {/* <div className="af-class-testimonial-text">{item.title}</div> */}
                            <span style={{ color: 'black', fontWeight: 700 }}>{item.title}</span><svg viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg>
                          </a>
                          <div />
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="w-dyn-empty">
                  </div>
              }
            </div>
          </div>
        </>
    }
  }


  render() {
    const proxies = LifeAtDrlView.Controller !== LifeAtDrlView ? transformProxies(this.props.children) : {
    }

    const { cookies } = this.props;
    const { cookieBool } = this.state;
    var cookiesAcceptanceBool = cookies.get('EnableCookie') || { enable: false };

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/webflow_css/normalize.css);
          @import url(/webflow_css/components.css);
          @import url(/webflow_css/dr-reddy.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e3c58b8d-72b2-285d-6c06-07ece69048e3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cfb63fcd-ad8f-2404-107c-7b76aa553d19\"] {display:none;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e3c58b8d-72b2-285d-6c06-07ece69048e3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cfb63fcd-ad8f-2404-107c-7b76aa553d19\"] {display:none;}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e3c58b8d-72b2-285d-6c06-07ece69048e3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cfb63fcd-ad8f-2404-107c-7b76aa553d19\"] {display:none;}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e3c58b8d-72b2-285d-6c06-07ece69048e3\"] {display:none;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cfb63fcd-ad8f-2404-107c-7b76aa553d19\"] {display:none;}}


          	body {
            	overflow-x: hidden;
            }
          	 .founder-scroll-wrapper::-webkit-scrollbar,
            		.delivering-health-wrapper::-webkit-scrollbar,
            		.side-headlines-wrapper::-webkit-scrollbar {
               width: 2px;
           	}
          	.founder-scroll-wrapper::-webkit-scrollbar-track {
               background-color: rgba(255, 255, 255, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.founder-scroll-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #fff;
           	}
            	.delivering-health-wrapper::-webkit-scrollbar-track, .side-headlines-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.delivering-health-wrapper::-webkit-scrollbar-thumb, .side-headlines-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #ff5046;
           	}
            .drag-section-circle {
             -webkit-backdrop-filter: blur(5px);
            	backdrop-filter: blur(10px);
            }
            .drag-section-circle {pointer-events: none;}
            .drag-slider-wrapper::-webkit-scrollbar {
               height: 3px;
           	}
          	.drag-slider-wrapper::-webkit-scrollbar-track {
               background-color: rgba(0, 0, 0, .35);
               -webkit-border-radius: 4px;
               border-radius: 4px;
           	}
           	.drag-slider-wrapper::-webkit-scrollbar-thumb {
               -webkit-border-radius: 4px;
               border-radius: 4px;
               background: #000;
           	}
            .w-slider-dot {
              margin: 0 12px;
              background-color: rgba(82,37,181,0.30);
            }
            .w-slider-dot.w-active {
              background-color: rgba(82,37,181,0.80);
            }

            .carousel.carousel-slider {
              width: 60% !important;
              height: 100% !important;
            }

          .carousel .control-dots {
            bottom: 11% !important;
            text-align: right;
            padding-right: 60px;
          }

          @media only screen and (max-width: 768px) {
            .carousel .control-dots {
              bottom: 20vh !important;
              margin-left: -18px;
            }

            .carousel .control-dots .dot {
              width: 12px !important;
              height: 12px !important;
              box-shadow: none;
              z-index: 99999;
            }
          }

          .carousel .control-dots .dot {
            width: 15px !important;
            height: 15px !important;
            box-shadow: none;
            z-index: 99999;
          }

          .underline {
            --line: black;
            --color: black;
            text-decoration: none;
            color: var(--color);
            position: relative;
            margin: 16px 0;
          }
          .underline span {
            background-image: linear-gradient(0deg, var(--line) 0%, var(--line) 100%);
            background-position: 100% 100%;
            background-repeat: no-repeat;
            background-size: var(--background-size, 100%) 1px;
            transition: background-size 0.2s linear var(--background-delay, 0.15s);
            transform: translateZ(0);
          }
          .underline svg {
            vertical-align: top;
            display: inline;
            line-height: 1;
            width: 26px;
            height: 30px;
            position: relative;
            left: -2px;
            top: -2px;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1px;
            stroke: var(--line);
            stroke-dasharray: 7.95 30;
            stroke-dashoffset: var(--stroke-dashoffset, 46);
            transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
          }
          .underline:hover {
            --background-size: 0%;
            --background-delay: 0s;
            --stroke-dashoffset: 26;
            --stroke-duration: .3s;
            --stroke-easing: cubic-bezier(.3, 1.5, .5, 1);
            --stroke-delay: .195s;
          }

          .reversed {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }

          .af-class-white-text-overlay2 {
            position: absolute;
            left: 0%;
            top: 100px;
            right: 50px;
            bottom: -30px;
            max-width: 800px;
            padding-top: 40px;
            padding-right: 40px;
            padding-bottom: 0px;
            background-color: #fff;
            animation: 500ms  ease-in slideInFromLeft;
          }

          @keyframes slideInFromLeft {
            0% {
              width: 0;
              height: 100%
            }
            100% {
              width: 570px;
            }
          }

          .carousal-image-overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0F0F0F00 0%, #000000C9 100%) 0% 0% no-repeat padding-box !important;
          }



        ` }} />
        {!this.state.renderPageBool && <span style={{ width: "100vw", height: "100vh", backgroundColor: '#fff', position: 'absolute', zIndex: 99999 }} ></span>}
        <span className="af-view">
          <div>
            <MenuSection />
            <HeroBannder />

            <div id="aspire-framework" data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085ce" className="af-class-section af-class-our-values">
              <div className="af-class-values-section-title-block">
                <h2 className="af-class-h2-section-heading af-class-title af-class-values" style={{ marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} >ASPIRE Leadership Behaviours</h2>
                <p ref={(el) => {
                  if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                    if (isTab) {
                      el.style.setProperty('font-size', '24px', 'important');
                      el.style.setProperty('line-height', '40px', 'important');
                      el.style.setProperty('max-width', '70%', 'important');
                    } else {
                      el.style.setProperty('line-height', '30px', 'important');
                    }
                  }
                }} className="af-class-paragraph af-class-_572" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px', maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '320px' }} >We believe in setting up our people for success with progressive people practices, empowering them to lead from the front and equipping them with the right skills to be efficient and effective. We do this through our ASPIRE leadership model, which highlights key leadership behaviours we encourage all our employees to imbibe and follow as a way of life. Because at Dr. Reddy's, everyone aspires to make a difference. When we aspire, we achieve.</p>
              </div>
              <div className="af-class-values-background-wrapper">
                <div className="af-class-value-bg-wrapper af-class-productivity af-class-_6" />
                <div className="af-class-value-bg-wrapper af-class-collaboration af-class-_5" />
                <div className="af-class-value-bg-wrapper af-class-quality af-class-_4" style={{ backgroundPosition: isTab && '75% 50%' }} />
                <div className="af-class-value-bg-wrapper af-class-safety af-class-_3" />
                <div className="af-class-value-bg-wrapper af-class-integrity af-class-_2" style={{ backgroundPosition: isTab && '65% 50%' }} />
                <div className="af-class-value-bg-wrapper af-class-sustainability af-class-_1" style={{ backgroundPosition: isTab && '60% 50%' }} />
                <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085da" className="af-class-container w-container">
                  <div style={{ backgroundColor: 'white', width: '100%', height: '100%', position: 'absolute', marginLeft: '-50vw', overflow: 'visible' }} />
                  <div ref={(el) => {
                    if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                      if (isTab) {
                        el.style.setProperty('bottom', '-43vh', 'important');
                        el.style.setProperty('margin-bottom', '10px', 'important');
                        el.style.setProperty('height', '450px', 'important');
                        el.style.setProperty('max-width', '700px', 'important');
                      }
                    }
                  }} className="af-class-values-wrapper af-class-tenet" style={{ maxWidth: '640px', height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '420px', bottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-50vh' }} >
                    {/* <h2 className="af-class-h2-section-heading af-class-margin-left-30">About Each Tenet</h2> */}
                    <div className="af-class-tenet-values-wrapper">
                      <div className="af-class-value-line-breaker" />
                      <div>
                        {
                          this.state.articleData.section1 && this.state.articleData.section1.map((item, index) => {
                            const ids = ["507f6a09-1a7f-cfed-0e6d-6a72ab9085de", "507f6a09-1a7f-cfed-0e6d-6a72ab9085e8", "507f6a09-1a7f-cfed-0e6d-6a72ab9085f2", "507f6a09-1a7f-cfed-0e6d-6a72ab9085fc", "507f6a09-1a7f-cfed-0e6d-6a72ab908606", "507f6a09-1a7f-cfed-0e6d-6a72ab908610"];
                            const classes = ["af-class-sustainability af-class-_1", "af-class-integrity af-class-_2", "af-class-safety af-class-_3", "af-class-quality af-class-_4", "af-class-collaboration af-class-_5", "af-class-productivity af-class-_6"];
                            return (
                              index === 0
                                ?
                                <div key={ids[index]} data-w-id={ids[index]} className="af-class-values-block af-class-tenet">
                                  <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085df" style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                                  <div className="af-class-values-heading-wrapper">
                                    <div className="af-class-values-heading-block">
                                      <h3 className={"af-class-values-heading " + classes[index]} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '18px' }} >{item.descTitle}</h3>
                                      <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085e4" style={{ width: '0%' }} className="af-class-values-underline" />
                                    </div>
                                  </div>
                                  <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085e5" style={{ height: 0 }} className="af-class-values-text-wrapper">
                                    <p className="af-class-paragraph af-class-_350" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '18px' }} dangerouslySetInnerHTML={{
                                      __html: item.description
                                    }} />
                                  </div>
                                  {/* <a data-w-id="3230a4ff-082f-34d8-36c7-25a4a87662a8" style={{ opacity: 0, paddingRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '40px', paddingBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px' }}
                                    href={`/articles?${item.contentId}#${item.id}`}
                                    className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                                    <div className="af-class-link-circle af-class-purple af-class-hide" /><img  src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                  </a> */}
                                </div>
                                :
                                <div key={ids[index]} data-w-id={ids[index]} className="af-class-values-block af-class-tenet">
                                  <div className="af-class-values-heading-wrapper">
                                    <div className="af-class-values-heading-block">
                                      <h3 className={"af-class-values-heading " + classes[index]} style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '18px' }} >{item.descTitle}</h3>
                                      <div style={{ width: '0%' }} className="af-class-values-underline" />
                                    </div>
                                  </div>
                                  <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                                    <p className="af-class-paragraph af-class-_350" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '16px' : '18px' }} dangerouslySetInnerHTML={{
                                      __html: item.description
                                    }} />
                                  </div>
                                  <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                                  {/* <a style={{ opacity: 0, paddingRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '40px', paddingBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '20px' }}
                                    href={`/articles?${item.contentId}#${item.id}`}
                                    className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                                    <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                                  </a> */}
                                </div>
                            )
                          })
                        }

                        {/* <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085de" className="af-class-values-block af-class-tenet">
                          <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085df" style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">Aspirational Growth Mindset</h3>
                              <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085e4" style={{ width: '0%' }} className="af-class-values-underline",paddingRight:window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '40px' />
                            </div>
                          </div>
                          <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085e5" style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We target industry-leading growth through innovation, cost leadership and taking risks.</p>
                          </div>
                          <a data-w-id="3230a4ff-082f-34d8-36c7-25a4a87662a8" style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/aiming-high-without-fear-of-failure" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>

                        <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085e8" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">Speed and Rigour in Execution</h3>
                              <div style={{ width: '0%' }} className="af-class-values-underline" />
                            </div>
                          </div>
                          <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We act with agility; we are disciplined and rigorous in execution.</p>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <a style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/racing-against-time-to-meet-patient-needs" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>

                        <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085f2" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">People Leadership</h3>
                              <div style={{ width: '0%' }} className="af-class-values-underline" />
                            </div>
                          </div>
                          <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We inspire people to reach their full potential through work and continuous learning.</p>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <a style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/leading-the-charge-without-giving-up" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>

                        <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab9085fc" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">Innovation</h3>
                              <div style={{ width: '0%' }} className="af-class-values-underline" />
                            </div>
                          </div>
                          <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We drive patient and customer-focused innovation in all areas using cutting-edge science, technology & digital tools.</p>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <a style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/solving-problems-in-creative-ways" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>

                        <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab908606" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">Results Driven</h3>
                              <div style={{ width: '0%' }} className="af-class-values-underline" />
                            </div>
                          </div>
                          <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We take responsibility for outcomes and own end results for our patients.</p>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <a style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/focusing-on-details-for-the-best-outcomes" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div>

                        <div data-w-id="507f6a09-1a7f-cfed-0e6d-6a72ab908610" className="af-class-values-block af-class-tenet">
                          <div className="af-class-values-heading-wrapper">
                            <div className="af-class-values-heading-block">
                              <h3 className="af-class-values-heading">Excellence Focus</h3>
                              <div style={{ width: '0%' }} className="af-class-values-underline" />
                            </div>
                          </div>
                          <div style={{ height: 0 }} className="af-class-values-text-wrapper">
                            <p className="af-class-paragraph af-class-_350">We excel by combining deep professional expertise and disciplined execution.</p>
                          </div>
                          <div style={{ opacity: 0 }} className="af-class-purple-circle af-class-values" />
                          <a style={{ opacity: 0 }} href="https://dr-reddy.webflow.io/articles/going-for-perfection-at-every-turn" className="af-class-link-arrow af-class-absolute-top-right w-inline-block">
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                          </a>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="our-people" data-w-id="949ffdc1-306c-9a87-4c0c-1a171636e8eb" className="af-class-section af-class-vision">
              <div className="af-class-vision-heading-block">
                <div className="af-class-container af-class-vision w-container">
                  {
                    window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                      ?
                      isTab
                        ?
                        <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '48px' : '60px' }} >Our people make us who we are</h1>
                        :
                        <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '48px' : '60px' }} >Our people <br /> make us<br />who we are</h1>
                      :
                      <h1 className="af-class-h2-section-heading af-class-title af-class-wide-center" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '48px' : '60px' }} >Our people make us<br />who we are</h1>
                  }
                </div>
              </div>
            </div>



            {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece690487a" className="af-class-section af-class-life-at-drl-horizontal">
              <div className="af-class-horizontal-scroll-wrapper">
                <div className="af-class-container w-container">
                  <div className="af-class-horizontal-scroll-content af-class-_3-artciles">

                    {/* <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid af-class-left-margin-0">
                      <div className="af-class-horintal-scroll-grid-block"><img src="/images/humans-of-drreddy2x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="/images/humans-of-drreddy2x-p-500.jpeg 500w, /images/humans-of-drreddy2x-p-800.jpeg 800w, /images/humans-of-drreddy2x-p-1080.jpeg 1080w, /images/humans-of-drreddy2x-p-1600.jpeg 1600w, /images/humans-of-drreddy2x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece6904881" className="af-class-horizontal-scroll-text-block af-class-auto-height" style={{ padding: '60px' }} >
                        <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece6904882" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Humans of Dr. Reddy’s</h3>
                          <p className="af-class-paragraph af-class-_450">We don’t just pay attention to the work our people do; we also embrace their challenges as our own. Because a family that sticks together stays together.</p>
                          <div className="w-dyn-list">
                            <div role="list" className="w-dyn-items">
                              {
                                this.state.articleData.section2 && this.state.articleData.section2.carousal1 && this.state.articleData.section2.carousal1.length !== 0
                                  ?
                                  this.state.articleData.section2.carousal1.map((item, index) => {
                                    return (
                                      <div key={"section2__carousal1" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                        <div className="af-class-testimonial-text-block">
                                          <a
                                            href={`/articles?${item.contentId}#${item.id}`}
                                            style={{ textDecoration: 'none' }} >
                                            <div className="af-class-testimonial-text">{item.title}</div>
                                          </a>
                                          <div />
                                        </div>
                                      </div>
                                    )
                                  })
                                  :
                                  <div className="w-dyn-empty">
                                    <div>No items found.</div>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/*
                    <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="/images/Mask_Group_269.png" loading="lazy" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece690488f" className="af-class-horizontal-scroll-text-block af-class-auto-height" style={{ padding: '60px' }} >
                        <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece6904890" className="af-class-horizontal-scroll-text-div">
                          <p className="af-class-paragraph af-class-_450">The key to healthy living is balancing work and life outside work in individual ways that bring out the best in us.</p>
                          <div className="w-dyn-list">
                            <div role="list" className="w-dyn-items">
                              {
                                this.state.articleData.section2 && this.state.articleData.section2.carousal2 && this.state.articleData.section2.carousal2.length !== 0
                                  ?
                                  this.state.articleData.section2.carousal2.map((item, index) => {
                                    return (
                                      <div key={"section2__carousal2" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                        <div className="af-class-testimonial-text-block">
                                          <a
                                            href={`/articles?${item.contentId}#${item.id}`}
                                            style={{ textDecoration: 'none' }} >
                                            <div className="af-class-testimonial-text">{item.title}</div>
                                          </a>
                                          <div />
                                        </div>
                                      </div>
                                    )
                                  })
                                  :
                                  <div className="w-dyn-empty">
                                    <div>No items found.</div>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="w-layout-grid af-class-_2-col-grid af-class-horizontal-content-grid">
                      <div className="af-class-horintal-scroll-grid-block"><img src="/images/everyone-is-equal2x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="/images/everyone-is-equal2x-p-500.jpeg 500w, /images/everyone-is-equal2x-p-800.jpeg 800w, /images/everyone-is-equal2x-p-1080.jpeg 1080w, /images/everyone-is-equal2x-p-1600.jpeg 1600w, /images/everyone-is-equal2x.jpg 1618w" alt className="af-class-horizontal-grid-image" /></div>
                      <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece690489e" className="af-class-horizontal-scroll-text-block af-class-auto-height" style={{ padding: '60px' }} >
                        <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece690489f" className="af-class-horizontal-scroll-text-div">
                          <h3 className="af-class-h2-section-heading">Diversity and Us</h3>
                          <p className="af-class-paragraph af-class-_450">Dr. Reddy’s is a melting pot of diverse people and cultures. Inclusivity is one of our core values and we celebrate each employee’s uniqueness with pride.</p>
                          <div className="w-dyn-list">
                            <div role="list" className="w-dyn-items">
                              {
                                this.state.articleData.section2 && this.state.articleData.section2.carousal3 && this.state.articleData.section2.carousal3.length !== 0
                                  ?
                                  this.state.articleData.section2.carousal3.map((item, index) => {
                                    return (
                                      <div key={"section2__carousal3" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                        <div className="af-class-testimonial-text-block">
                                          <a
                                            href={`/articles?${item.contentId}#${item.id}`}
                                            // href={"/articles"}
                                            //   onClick={() => {
                                            //     localStorage.setItem("data", JSON.stringify(item))
                                            //   }}
                                            style={{ textDecoration: 'none' }} >
                                            <div className="af-class-testimonial-text">{item.title}</div>
                                          </a>
                                          <div />
                                        </div>
                                      </div>
                                    )
                                  })
                                  :
                                  <div className="w-dyn-empty">
                                    <div>No items found.</div>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <HumansOfDrReddysMobile data={this.state.articleData.section2} />}

                    {/* {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                      <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                        <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                          <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                            <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                              <div className="af-class-slide-div">
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/humans-of-drreddy2x.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 84vw, (max-width: 991px) 42vw, 100vw" srcSet="/images/humans-of-drreddy2x-p-500.jpeg 500w, /images/humans-of-drreddy2x-p-800.jpeg 800w, /images/humans-of-drreddy2x-p-1080.jpeg 1080w, /images/humans-of-drreddy2x-p-1600.jpeg 1600w, /images/humans-of-drreddy2x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                                <div className="af-class-horizontal-scroll-text-div">
                                  <h3 className="af-class-h2-section-heading">Humans of Dr. Reddy’s</h3>
                                  <p className="af-class-paragraph af-class-_450">We don’t just pay attention to the work our people do; we also embrace their challenges as our own. Because a family that sticks together stays together.</p>
                                  <div className="w-dyn-list">
                                    <div role="list" className="w-dyn-items">

                                      {
                                        this.state.articleData.section2 && this.state.articleData.section2.carousal1 && this.state.articleData.section2.carousal1.length !== 0
                                          ?
                                          this.state.articleData.section2.carousal1.map((item, index) => {
                                            return (
                                              <div key={"section2__carousal1" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                                <div className="af-class-testimonial-text-block">
                                                  <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    style={{ textDecoration: 'none' }} >
                                                    <div className="af-class-testimonial-text">{item.title}</div>
                                                  </a>
                                                  <div />
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <div className="w-dyn-empty">
                                            <div>No items found.</div>
                                          </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                              <div className="af-class-slide-div">
                                <div className="af-class-drag-slider-item-thumbnail"><img  loading="lazy" src="/images/Mask_Group_269.png" alt className="af-class-drag-slider-item-image" /></div>
                                <div className="af-class-horizontal-scroll-text-div">
                                  <p className="af-class-paragraph af-class-_450">The key to healthy living is balancing work and life outside work in individual ways that bring out the best in us.</p>
                                  <div className="w-dyn-list">
                                    <div role="list" className="w-dyn-items">

                                      {
                                        this.state.articleData.section2 && this.state.articleData.section2.carousal2 && this.state.articleData.section2.carousal2.length !== 0
                                          ?
                                          this.state.articleData.section2.carousal2.map((item, index) => {
                                            return (
                                              <div key={"section2__carousal2" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                                <div className="af-class-testimonial-text-block">
                                                  <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    style={{ textDecoration: 'none' }} >
                                                    <div className="af-class-testimonial-text">{item.title}</div>
                                                  </a>
                                                  <div />
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <div className="w-dyn-empty">
                                            <div>No items found.</div>
                                          </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                              <div className="af-class-slide-div">
                                <div className="af-class-drag-slider-item-thumbnail"><img src="/images/everyone-is-equal2x.jpg" loading="lazy" sizes="(max-width: 1618px) 100vw, 1618px" srcSet="/images/everyone-is-equal2x-p-500.jpeg 500w, /images/everyone-is-equal2x-p-800.jpeg 800w, /images/everyone-is-equal2x-p-1080.jpeg 1080w, /images/everyone-is-equal2x-p-1600.jpeg 1600w, /images/everyone-is-equal2x.jpg 1618w" alt className="af-class-drag-slider-item-image" /></div>
                                <div className="af-class-horizontal-scroll-text-div">
                                  <h3 className="af-class-h2-section-heading">Diversity and Us</h3>
                                  <p className="af-class-paragraph af-class-_450">Dr. Reddy’s is a melting pot of diverse people and cultures. Inclusivity is one of our core values and we celebrate each employee’s uniqueness with pride.</p>
                                  <div className="w-dyn-list">
                                    <div role="list" className="w-dyn-items">

                                      {
                                        this.state.articleData.section2 && this.state.articleData.section2.carousal3 && this.state.articleData.section2.carousal3.length !== 0
                                          ?
                                          this.state.articleData.section2.carousal3.map((item, index) => {
                                            return (
                                              <div key={"section2__carousal3" + index} role="listitem" className="af-class-testimonial-wrapper w-dyn-item">
                                                <div className="af-class-testimonial-text-block">
                                                  <a
                                                    href={`/articles?${item.contentId}#${item.id}`}
                                                    style={{ textDecoration: 'none' }} >
                                                    <div className="af-class-testimonial-text">{item.title}</div>
                                                  </a>
                                                  <div />
                                                </div>
                                              </div>
                                            )
                                          })
                                          :
                                          <div className="w-dyn-empty">
                                            <div>No items found.</div>
                                          </div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece69048dd" className="af-class-left-arrow w-slider-arrow-left">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                          </div>
                          <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece69048e1" className="af-class-right-arrow w-slider-arrow-right">
                            <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="e3c58b8d-72b2-285d-6c06-07ece69048e3" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                          </div>
                          <div className="af-class-slide-nav w-slider-nav w-round" />
                        </div>
                      </div>
                      <div className="af-class-drag-slider-progress-bar af-class-mobile-only af-class-margin-130">
                        <div className="af-class-drag-progress-fill af-class-fifty" />
                      </div>
                    </>} */}
                  </div>
                </div>
              </div>
            </div>
            }
            {/* <div data-w-id="72519104-07ab-39b7-0eb1-409c973e1c74" className="af-class-section af-class-people-of-drl">
              <div className="af-class-container w-container">
                <div className="af-class-lottie-with-text-wrapper">
                  <div className="af-class-lottie-with-text-block">
                    <div className="af-class-lottie-digit-wrapper">
                      <div data-w-id="39877a46-9904-34e7-9f9a-00782d5bbbba" data-animation-type="lottie" data-src="documents/48.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                    </div>
                    <div>Nationalities</div>
                  </div>
                  <div className="af-class-lottie-with-text-block">
                    <div className="af-class-lottie-digit-wrapper">
                      <div data-w-id="baabb6bc-ee59-dfc7-e723-9c99d262e096" data-animation-type="lottie" data-src="documents/22.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                      <div>,</div>
                      <div data-w-id="1c85f2fe-598a-699b-dc8d-286daf7f43cb" data-animation-type="lottie" data-src="documents/0.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                      <div data-w-id="82bcf5e9-39ff-15f7-b290-adb31c067ed5" data-animation-type="lottie" data-src="documents/0.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                      <div data-w-id="fc693096-7442-e082-b7ef-906c70b9f484" data-animation-type="lottie" data-src="documents/0.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                      <div>+</div>
                    </div>
                    <div>Employees</div>
                  </div>
                  <div className="af-class-lottie-with-text-block">
                    <div className="af-class-lottie-digit-wrapper">
                      <div data-w-id="f74ccd51-4f82-11f3-a603-258163ba9c46" data-animation-type="lottie" data-src="documents/17.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin af-class-right-negative-margin" />
                      <div>.</div>
                      <div data-w-id="40f9586b-57c4-2465-ddbc-a84909d8a323" data-animation-type="lottie" data-src="documents/4.json" data-loop={0} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="0.5166666666666667" data-duration={0} className="af-class-big-number-lottie af-class-no-side-margin" />
                      <div>%</div>
                    </div>
                    <div>Gender Diversity</div>
                  </div>
                </div>
              </div>
            </div> */}



            {/* carousal */}

            {
              !window.matchMedia('(max-width: 767px)').matches
              &&
              <div id="main" className="af-class-section" style={{ padding: 0, width: '100%', height: '100%', backgroundColor: '#EDEDED' }}  >
                <div className="af-class-container af-class-vision w-container">
                  <Carousel showArrows={false} showStatus={false} showThumbs={false} autoPlay={false} infiniteLoop={true} dynamicHeight={false}
                    selectedItem={this.state.selectedCarousalTab}
                    onChange={(index, item) => { this.setState({ selectedCarousalTab: index }) }}>
                    <div>
                      <img loading="lazy" src="/images/wearedrdreddys3.png" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img loading="lazy" src="/images/wearedrdreddys1.png" />
                      <div className='carousal-image-overlay' />
                    </div>
                    <div>
                      <img loading="lazy" src="/images/wearedrdreddys2.png" />
                      <div className='carousal-image-overlay' />
                    </div>

                  </Carousel>
                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{
                      pointerEvents: this.state.leftDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '45%', left: '10%', marginLeft: '-60px', padding: 0
                    }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={() => this.handleLeftTabClick()}>

                      {this.state.leftDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-black-arrow reversed" />
                        </>
                      }
                    </div>}
                  {this.state.selectedCarousalTab == 0 ?
                    <h3 data-w-id="6fe2b9ca-886a-a1ea-2d95-0e3855ef232d" style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'center',
                      position: 'absolute', left: '12%', bottom: '34%', fontSize: '80px', fontWeight: '300', color: 'white', lineHeight: '100px',
                      WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
                    }} >
                      Humans of <br /> Dr. Reddy's
                    </h3>
                    :
                    <h3 data-w-id="6fe2b9ca-886a-a1ea-2d95-0e3855ef232d" style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'center',
                      position: 'absolute', left: '12%', bottom: '34%', fontSize: '80px', fontWeight: '300', color: 'white', lineHeight: '100px',
                      WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform: 'translate3d(0, 0, 0) scale3d(1, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
                    }} >
                      We Are<br />Dr. Reddy's
                    </h3>

                  }

                  <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'absolute', minHeight: '550px', left: '48%', paddingLeft: '60px', paddingRight: '60px', paddingTop: '60px', paddingBottom: '60px', bottom: '17%',
                    // WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(90) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(90) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(90) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(90) rotateY(0) rotateZ(0) skew(0, 0)'
                  }} className="af-class-white-text-overlay2">
                    {/* <div data-w-id="e3c58b8d-72b2-285d-6c06-07ece6904882" className="af-class-horizontal-scroll-text-div"> */}
                    {this.getCarouselItems(this.state.selectedCarousalTab)}
                    {/* </div> */}
                  </div>
                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{
                    pointerEvents: this.state.rightDisabled ? 'none' : 'auto',
                    position: 'absolute', top: '45%', left: '96%'
                  }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => this.handleRightTabClick()}>

                    {this.state.rightDisabled ?
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                      :
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                    }
                  </div>}

                  {/* <h1 style={{ textAlign: 'right', zIndex: 99999, position: 'absolute', left: '85%', bottom: window.matchMedia('(max-width: 820px)').matches ? '5%' : '15%', }} onClick={() => {
                    window.location.href = "#about-us";
                  }} >
                    <Lottie options={defaultOptions}
                      height={150}
                      width={150}
                    />
                  </h1> */}
                </div>
              </div>
              // :
              // <div id="main" className="af-class-section" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, width: '100%', maxHeight: '100vh' }}  >
              //   <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={10} showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={false}>
              //     <div>
              //       <img  loading="lazy" src="/images/1m.png" />
              //       <div className='carousal-image-overlay' />
              //     </div>
              //     <div>
              //       <img loading="lazy" src="/images/2m.png" />
              //       <div className='carousal-image-overlay' />
              //     </div>
              //     <div>
              //       <img loading="lazy"  src="/images/3m.png" />
              //       <div className='carousal-image-overlay' />
              //     </div>

              //   </Carousel>
              //   <h1 style={{ zIndex: 99999, position: 'absolute', left: '65vw', right: 0, top: window.matchMedia('(max-width: 820px)').matches ? '71vh' : 0, }} onClick={() => {
              //     window.location.href = "#about-us";
              //   }} >
              //     <Lottie options={defaultOptions}
              //       height={120}
              //       width={120}
              //     />
              //   </h1>
              // </div>
            }


            <div id="awards" className="af-class-section">
              <div className="af-class-container" data-w-id="1986479c-99bf-d07a-d4b0-85569b0a09b7">

                <h2 ref={(el) => {
                  if (el && isTab) {
                    el.style.setProperty('font-size', '36px', 'important')
                    el.style.setProperty('margin-bottom', '20px', 'important')
                  }
                }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin af-class-center"
                  style={{
                    marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '-10px' : '-10px',
                    marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px',
                    fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', textAlign: 'center'
                  }} >Awards and Accreditations</h2>


                {!window.matchMedia('(max-width: 820px)').matches && <MainContainer isTabsEnable={isTabsEnable}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '110%' }}>

                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{
                      pointerEvents: this.state.leftDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '30%', left: '1%', marginTop: '-30px', marginLeft: '-60px', padding: 0
                    }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={() => this.handleLeftAwardsClick()}>

                      {this.state.leftDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                      }
                    </div>

                    <TabsSection id="tab-section" style={{ width: '85%' }}>
                      {isTabsEnable &&
                        this.state.tabData.map((item, index) => {
                          return (
                            <TabItem
                              href={"#" + item.hashValue}
                              key={"Rendering_Tab_contactUs_" + item.id + item.name + index}
                              onClick={() => {
                                this.setState({ selectedTab: item, selectedSubTab: 0 });
                              }}
                              selected={this.state.selectedTab.id === item.id}
                            >
                              {item.name}
                            </TabItem>
                          );
                        })}
                      {!isTabsEnable && (
                        <HambergerButton
                          onClick={() => {
                            this.setState({ openMenuBool: !this.state.openMenuBool });
                          }}
                        >
                          <HambergerIcon />
                        </HambergerButton>
                      )}
                    </TabsSection>

                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{
                      pointerEvents: this.state.rightDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '30%', marginTop: '-30px', left: '80%'
                    }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => this.handleRightAwardsClick()}>


                      {this.state.rightDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                      }
                    </div>

                  </div>

                </MainContainer>}

                {!window.matchMedia('(max-width: 820px)').matches && <HR />}

                {window.matchMedia('(max-width: 820px)').matches &&
                  <MainContainer style={{ flexDirection: 'column', marginTop: 0, marginBottom: '10px' }}>
                    <TabsSection style={{ marginLeft: 0 }}>
                      {isTabsEnable &&
                        <><MobileTabItem
                          href={"#" + this.state.selectedTab.hashValue}
                          key={"Rendering_Tab_contactUs_" + this.state.selectedTab.id + this.state.selectedTab.name}
                          selected={this.state.selectedTab.id}
                        >
                          {this.state.selectedTab.name}
                        </MobileTabItem>
                          <a
                            onClick={() => {
                              var selectedValue = this.state.selectedTab.id + 1;
                              if (selectedValue > 9) selectedValue = 1;

                              this.setState({ selectedTab: this.state.tabData[selectedValue - 1], selectedSubTab: 0 });
                            }}
                          >
                            <div style={{ width: '10px', height: '16px', marginTop: '-10px' }} >
                              <img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" /></div>
                          </a>
                        </>
                      }
                    </TabsSection>

                    <div style={{ marginBottom: '20px', display: 'flex', height: '2px', width: '100%', flexDirection: 'row', backgroundColor: '#a8a8a8', position: 'relative', marginTop: '20px' }} >
                      {
                        isTab
                          ?
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1 - 1) * (100 / (this.state.tabData.length - 1))) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / (this.state.tabData.length - 1)) + "%" }} />
                          :
                          <div style={{ height: '3px', backgroundColor: '#ff5046', position: 'absolute', left: ((this.state.selectedTab.id - 1) * (100 / this.state.tabData.length)) + '%', top: '-0.5px', right: 'auto', bottom: '0%', width: (100 / this.state.tabData.length) + "%" }} />
                      }
                    </div>

                  </MainContainer>
                }

                <div className="af-class-delivering-health-wrapper" style={{ alignItems: 'center', display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >

                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches &&
                    <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{
                      pointerEvents: this.state.leftDisabled ? 'none' : 'auto',
                      position: 'absolute', top: '30%', left: '1%', marginLeft: '-60px', padding: 0
                    }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={() => this.handleLeftAwardsClick()}>

                      {this.state.leftDisabled ?
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                        </>
                        :
                        <>
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </>
                      }
                    </div>}

                  <div className="af-class-scroll-video-block" style={{
                    width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'unset' : 'unset',
                    marginLeft: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginRight: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '4%',
                    marginTop: !window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '1%',
                  }}>

                    <div style={{
                      margin: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '12px 12px 0px 12px'
                    }}>
                      {this.getTabContent(this.state.selectedTab)}
                      {/* <a href="/business-responsibility-and-sustainability" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block"
                            style={{marginTop: '30px', marginLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-20px'}}>
                            <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                            <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{fontSize: isTab && '18px', fontWeight: isTab && '600'
                              }} >LEARN&nbsp;MORE</div>
                          </a> */}
                    </div>
                  </div>
                  {this.getTabImage(this.state.selectedTab)}


                  {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{
                    pointerEvents: this.state.rightDisabled ? 'none' : 'auto',
                    position: 'absolute', top: '30%', left: '96%'
                  }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={() => this.handleRightAwardsClick()}>

                    {this.state.rightDisabled ?
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                      :
                      <>
                        <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                      </>
                    }
                  </div>}
                </div>

              </div>
            </div>

            <div id="employee-enrichment-programmes" data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e56" className="af-class-section af-class-always-innovating" style={{ height: '200vh' }}>
              <div className="af-class-always-innovating-heading-block" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }} >
                {/* <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container"> */}
                <h1 data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e59" style={{ opacity: 0, maxWidth: '100%', textAlign: 'center', marginBottom: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '24px' : '40px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? isTab ? '48px' : '36px' : '60px' }} className="af-class-title af-class-become-the-best af-class-h2-section-heading" >Onwards and upwards</h1>
                {/* style={{ maxWidth: '800px', marginBottom: '40px' }}  */}
                {/* style={{ textAlign: 'center', marginLeft: '22%', marginRight: '22%' }}  */}
                {
                  window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches
                    ?
                    <p ref={(el) => {
                      if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                        if (isTab) {
                          el.style.setProperty('font-size', '24px', 'important');
                          el.style.setProperty('line-height', '40px', 'important');
                        } else {
                          el.style.setProperty('line-height', '30px', 'important');
                        }
                      }
                    }} data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e59" className="af-class-paragraph af-class-_572" style={{ opacity: 0, maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : '60%', textAlign: 'center', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px' }} >Our success rests on our employees across all spheres of work, who push themselves continually to set new benchmarks. They stay on top of their game through a diverse series of Capability Building Programmes designed to equip them with the tools and skills to reach great heights. We offer something for everyone, including opportunities for new career avenues.</p>
                    :
                    <p data-w-id="faef8a71-a975-b9d7-3c09-ee032bc46e59" className="af-class-paragraph af-class-_572" style={{ opacity: 0, maxWidth: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : '60%', textAlign: 'center', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '18px' }} >Our success rests on our employees across all spheres of work, who push<br />themselves continually to set new benchmarks. They stay on top of their game<br />through a diverse series of Capability Building Programmes designed to<br />equip them with the tools and skills to reach great heights. We offer something for<br />everyone, including opportunities for new career avenues.</p>
                }
              </div>
              {/* </div> */}
            </div>

            <div className="af-class-section af-class-z-index-2" style={{ backgroundColor: '#EDEDED', height: 'auto' }} >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <h2 className="af-class-h2-section-heading">Capability Building Programmes</h2>
                <EmploymentEnrichmentProgrammesSlider />
                {!window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && <>
                  <div className="af-class-drag-slider-wrapper">
                    <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                      {/* <div className="af-class-mask af-class-_350 w-slider-mask"> */}
                      <div className="af-class-mask w-slider-mask">

                        <div data-w-id={"ae2c330c-3b91-6fe2-998a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Chrysalis_1.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Chrysalis</h3>
                              <div>Our flagship leadership programme for women in mid-management, Chrysalis upskills high-potential employees for senior roles. Each batch goes through rigorous training for nine months, including group projects, soft skills enhancement, real-time simulation exercises, mentoring by senior leaders and sessions on self-awareness and personal effectiveness.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/YLPweb.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Young Leadership Programme</h3>
                              <div>One of our best-known courses, the Young Leaders Programme (YLP) grooms fresh hires from India’s top business schools and engineering colleges. It enables us to develop a talent pool capable of taking up general management roles through stint-based rotations in multiple business units. The YLP is also open to our employees under the Internal Young Leadership Programme.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998a-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/_DSC2209-Edit.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Fantastic Five</h3>
                              <div>In an effort to balance the gender ratio of women in manufacturing, Dr. Reddy’s Biologics division runs the Fantastic Five programme to groom potential pathbreakers for lead roles. Over the course of a year, five women employees undergo technical and behavioural training so they are equipped to handle shift operations, compliance and documentation, inspections, investigations and people management.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/bram-naus-oqnVnI5ixHg-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Assurance Leadership Programme</h3>
                              <div>This year-long programme has been designed to build employee competency in risk management, corporate governance, compliance,data privacy and cybersecurity. Inductees train via rotational stints in the internal audit, risk and information security departments.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/Chrysalisweb.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">New Horizons Programmes</h3>
                              <div>The New Horizons Leadership Programme prepares candidates to lead teams and businesses. The New Horizons Management Programme offers customised all-round coaching through classes and digital sessions for seven months. New Horizons Well-being Programme focuses on 'holistic well-being' through four key pillars – physical, psychological, relational and spiritual.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998b-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/krakenimages-376KN_ISplE-unsplash-copy.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Surge</h3>
                              <div>An intensive year-long module, Surge is focused on building business development capabilities across geographies. Led by internal and external experts, it includes key aspects such as negotiations, relationship building and business intelligence.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998c-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/jason-goodman-IQY_q-RqaIo-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Soar</h3>
                              <div>Designed for country heads and potential general managers, Soar is a year-long programme that trains candidates in strategic leadership and marketing, and ways to accelerate business. Assessments and high-impact projects are assigned per the course framework.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998c-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/muhammad-faiz-zulkeflee-alw-CwGFmwQ-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Key Account Managers</h3>
                              <div>This nine-month programme targets capability building for key account managers. It offers insights into customer centricity, financial and service orientation, cross-functionality and creating influence.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998c-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/jason-goodman-4-iZ147pSAE-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">I-Develop</h3>
                              <div>With I-Develop, HR business partners undergo a year-long training for capability building across functions. They’re helped by external experts to develop expertise in various fields, from data analytics to talent development, career planning and employee experience.</div>
                            </div>
                          </div>
                        </div>

                        <div data-w-id={"ae2c330c-3b91-6fe2-998d-e87e004b1901"} className="af-class-drag-slider-item af-class-promise w-slide">
                          <div className="af-class-slide-div">
                            <div>
                              <div className="af-class-drag-slider-item-thumbnail"><img src="/images/docusign-BbSBf5uv50A-unsplash.png" loading="lazy" alt className="af-class-drag-slider-item-image" /></div>
                              <h3 className="af-class-h3-heading af-class-normal-typcase">Digital Capability Building Programmes</h3>
                              <div>As a future-forward company, enabling our employees to be digital savvy is one of our priorities. This aligns with our digitisation campaign to build a business that will flourish for decades. The Digital Ninja Programme and DnA Business Translator Programme are curated to maximise learning.</div>
                            </div>
                          </div>
                        </div>

                      </div>


                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897a9" style={{ pointerEvents: this.state.currentPage === 0 ? 'none' : 'auto' }} className="af-class-left-arrow af-class-lower w-slider-arrow-left" onClick={this.handleLeftClick}>
                        {
                          this.state.currentPage === 0 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/Grey-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />

                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }

                      </div>
                      <div data-w-id="93c978e6-0303-b12f-c2a5-8a97597897ab" style={{ pointerEvents: this.state.currentPage === 3 ? 'none' : 'auto' }} className="af-class-right-arrow af-class-lower w-slider-arrow-right" onClick={this.handleRightClick}>
                        {
                          this.state.currentPage === 3 ?
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Grey_arrow.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/Grey_arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                            :
                            <>
                              <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="f310ebf6-8ae9-1d03-d2e5-ef3c0cd40578" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                            </>
                        }
                      </div>


                      {/* <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50a" className="af-class-left-arrow af-class-lower af-class-down-90px w-slider-arrow-left">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div>
                    <div data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e50e" className="af-class-right-arrow af-class-lower af-class-down-90px w-slider-arrow-right">
                      <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="a0f728b7-bfc2-0e3b-728c-8ed71323e510" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                    </div> */}



                      <div className="af-class-slide-nav w-slider-nav w-round" />
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar">
                    <div className="af-class-drag-progress-fill af-class-thirty" />
                  </div>
                </>}
              </div>
            </div>

            {/* <section className="af-class-section af-class-padding-120-mobile">
              <div className="af-class-container w-container">
                <div className="af-class-delivering-health-wrapper">
                  <div className="af-class-scroll-video-block">
                    <div data-poster-url="videos/Testimonials-poster-00001.jpg" data-video-urls="videos/Testimonials-transcode.mp4,videos/Testimonials-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-making-good-health-video w-background-video w-background-video-atom"><video autoPlay loop style={{ backgroundImage: 'url("videos/Testimonials-poster-00001.jpg")' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                      <source src="videos/Testimonials-transcode.mp4" data-wf-ignore="true" />
                      <source src="videos/Testimonials-transcode.webm" data-wf-ignore="true" />
                    </video></div>
                  </div>
                  <div className="af-class-scroll-text-wrapper">
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <p className="af-class-paragraph">My journey at Dr. Reddy’s began in the corporate strategy team. As my perspective changed from meeting short-term goals to fulfilling a long-term vision, I developed the ability to look at projects on multiple levels. I have learned technical and people skills on the job, and today, Dr. Reddy’s is a platform for me to challenge myself and succeed in creative ways.</p>
                          <div className="af-class-paragraph"><strong>Rahul Yelisetti</strong></div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <p className="af-class-paragraph">I started off at Dr. Reddy’s as a field sales executive, and over the past 25 years have taken up every unique opportunity offered to me. I have led several geographies and built my knowledge of the entire pharmaceutical value chain. My progress has been parallel to Dr. Reddy’s global expansion — you could say we have grown older and wiser together!</p>
                          <div className="af-class-paragraph"><strong>Karun Gaur</strong></div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <p className="af-class-paragraph">I came into Dr. Reddy’s in 2007 as an Assistant Manager at the Centre for Excellence, and joined the Viable Vision project about two years later. It was a wonderful foundation upon which I have built my career. Today, as a senior manager handling an important portfolio, I can say the company has helped me every step of the way to become the professional I am.</p>
                          <div className="af-class-paragraph"><strong>Jyotsna Rath</strong></div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-drag-slider-wrapper af-class-mobile-only">
                      <div data-animation="slide" data-duration={500} className="af-class-about-us-slider w-slider">
                        <div className="af-class-mask af-class-full af-class-max-400 w-slider-mask">
                          <div data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553ced" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div>My journey at Dr. Reddy’s began in the corporate strategy team. As my perspective changed from meeting short-term goals to fulfilling a long-term vision, I developed the ability to look at projects on multiple levels. I have learned technical and people skills on the job, and today, Dr. Reddy’s is a platform for me to challenge myself and succeed in creative ways.<br /><br /><strong>Rahul Yelisetti</strong><br /></div>
                            </div>
                          </div>
                          <div data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553cf9" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div>I started off at Dr. Reddy’s as a field sales executive, and over the past 25 years have taken up every unique opportunity offered to me. I have led several geographies and built my knowledge of the entire pharmaceutical value chain. My progress has been parallel to Dr. Reddy’s global expansion — you could say we have grown older and wiser together!<br /><br /><strong>Karun Gaur</strong><br /></div>
                            </div>
                          </div>
                          <div data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553d00" className="af-class-drag-slider-item af-class-full af-class-max-400 w-slide">
                            <div className="af-class-slide-div">
                              <div>I came into Dr. Reddy’s in 2007 as an Assistant Manager at the Centre for Excellence, and joined the Viable Vision project about two years later. It was a wonderful foundation upon which I have built my career. Today, as a senior manager handling an important portfolio, I can say the company has helped me every step of the way to become the professional I am.<br /><br /><strong>Jyotsna Rath</strong><br /></div>
                            </div>
                          </div>
                        </div>
                        <div data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553d13" className="af-class-left-arrow af-class-lower w-slider-arrow-left">
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" alt className="af-class-white-arrow af-class-reversed" /><img src="/images/chevron_left_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553d17" className="af-class-right-arrow af-class-lower w-slider-arrow-right">
                          <div className="af-class-link-circle af-class-purple" /><img src="/images/Path-237.svg" loading="lazy" data-w-id="cfb63fcd-ad8f-2404-107c-7b76aa553d19" alt className="af-class-white-arrow" /><img src="/images/chevron_arrow_purple.svg" loading="lazy" alt className="af-class-black-arrow" />
                        </div>
                        <div className="af-class-slide-nav w-slider-nav w-round" />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-drag-slider-progress-bar af-class-mobile-only">
                    <div className="af-class-drag-progress-fill af-class-fifty" />
                  </div>
                </div>
              </div>
            </section> */}

            <div className="af-class-about-hero-wrapper af-class-life-at-drl" style={{ backgroundImage: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'url("/images/jason-goodman-X8H8vPcelPk-mobile.png")' : 'url("/images/jason-goodman-X8H8vPcelPk-unsplash.png")', height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100vh' : 'auto' }} >
              <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute', width: '100%', height: '100%' }} />
              <div className="af-class-section af-class-life-at-drl-hero">
                <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                  <div className="w-layout-grid af-class-_2-col-grid af-class-_40px-gap" style={{ gridRowGap: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px', display: isTab && 'flex', flexDirection: isTab && 'column', alignItems: isTab && 'center' }} >
                    <h2 id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e1-bdd5ac66" className="af-class-h2-section-heading af-class-title af-class-negative-margin" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '36px', lineHeight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '48px', minWidth: isTab && '100%', textAlign: isTab && 'center' }} >Benefits of working with us</h2>
                    <ul style={{ listStyle: 'unset', marginLeft: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '-23px' }} >
                      <li><p className="af-class-paragraph af-class-lead">Become part of a team that cares</p></li>
                      <li><p className="af-class-paragraph af-class-lead">Expect to be treated with respect</p></li>
                      {
                        isTab
                          ?
                          <li><p className="af-class-paragraph af-class-lead">Enjoy professional freedom to create impact</p></li>
                          :
                          <li><p className="af-class-paragraph af-class-lead">Enjoy professional freedom to <br /> create impact</p></li>
                      }
                      <li><p className="af-class-paragraph af-class-lead">Learn continually, excel and grow</p></li>
                    </ul>
                    {/* <p id="w-node-d68d44e0-b690-6939-01bc-5295fef1e9e5-bdd5ac66" className="af-class-paragraph af-class-lead">Ask any of the 23,704 employees at Dr. Reddy’s why they come to work every day and they’ll say, because Good Health Can’t Wait. This is our credo as well as the guiding principle behind all our actions. We see healthcare solutions not only as scientific formulations, but as a means to help patients lead healthier lives, and we’re always attuned to the new and the next to empower people to stay fit. And to do this, we foster a culture of empathy and dynamism.</p> */}
                  </div>
                </div>
              </div>
            </div>

            <div id="work-with-us" className="af-class-section af-class-z-index-2" style={{}} >
              <div data-w-id="3de728df-e436-5d1f-ff86-77f8d7e66adf" className="af-class-container w-container">
                <div className="af-class-delivering-health-wrapper" style={{ display: 'flex', flexDirection: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? 'column-reverse' : 'row-reverse' }} >
                  <div className="af-class-scroll-video-block" style={{ width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "35%", height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && 'unset' }}>
                    <div>
                      <h2 ref={(el) => {
                        if (el && isTab) {
                          el.style.setProperty('font-size', '36px', 'important')
                          el.style.setProperty('margin-bottom', '20px', 'important')
                        }
                      }} className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin" style={{ marginTop: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '22px' : '20px', fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24px' }} >Work with us</h2>
                      <p ref={(el) => {
                        if (el && window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches) {
                          if (isTab) {
                            el.style.setProperty('font-size', '20px', 'important');
                            el.style.setProperty('line-height', '32px', 'important');
                            el.style.setProperty('max-width', '100%', 'important');
                          } else {
                            el.style.setProperty('line-height', '28px', 'important');
                          }
                        }
                      }} className="af-class-paragraph af-class-_450" style={{ fontSize: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '16px' }} >Over the past 39 years, talented and capable people have powered Dr. Reddy’s growth and built our reputation as a world-class pharmaceutical company. Because when people with diverse skills are bound together by a common purpose and values, they can make magic. <br /><br />As part of the Dr. Reddy’s family, you will make the world a healthier place as you address patient needs around the world. We empower our people to put their skills to use the way they see fit, learn and grow continually, and truly realise their potential. We care for them, ensure their safety, and treat them with respect. Our people have a voice, and so will you.</p>
                      <a href="/job-seeker#refer" className="af-class-link-arrow af-class-left-side af-class-wide-cta w-inline-block">
                        <div className="af-class-link-circle af-class-purple af-class-hide" /><img src="/images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" /><img src="/images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow af-class-hide" />
                        <div className="af-class-link-arrow-slide-text af-class-right-to-arrow" style={{ fontSize: isTab && '18px', fontWeight: isTab && '600' }} >VIEW&nbsp;JOB&nbsp;OPENINGS</div>
                      </a>
                    </div>
                  </div>
                  <div ref={(el) => {
                    if (el && isTab) {
                      el.style.setProperty('height', '280px', 'important')
                    }
                  }} className='af-class-scroll-video-block' style={{ height: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '180px', width: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '100%' : "52%", marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? '0' : "8%" }}>
                    <img loading="lazy" src={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? "/images/1991_omez_packaging_Mobile.jpg" : "/images/1991-omez_packaging.png"} style={window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches ? { height: '100%', width: '100%', objectFit: 'cover' } : null} />
                    {/* <div className="af-class-scroll-video-block">
                  </div> */}
                    {/* <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Become part of a team that cares</h2>
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* 1991-omez_packaging.png */}
                    {/* <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Expect to be treated with respect</h2>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Enjoy professional freedom to create impact</h2>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-scroll-text-block">
                      <div className="af-class-scroll-text-div">
                        <div className="af-class-scroll-animation-box">
                          <h2 className="af-class-h2-section-heading af-class-less-line-height af-class-more-margin">Learn continually, excel and grow</h2>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <a href="#life-at-drl-hero" data-w-id="636137ae-e691-df48-dd79-6170a91666b2" className="af-class-back-to-top-wrapper w-inline-block" >
                <div className="af-class-back-top-top-circle"><img src="/images/chevron-right.svg" loading="lazy" alt className="af-class-top-arrow af-class-black" /><img src="/images/Path-237.svg" loading="lazy" style={{ display: 'none' }} alt className="af-class-top-arrow af-class-white" /></div>
                <div>Back to Top</div>
              </a>
            </div>
            {/* {!(cookiesAcceptanceBool?.enable && cookiesAcceptanceBool.enable) && cookieBool && <CookiesPopup setCookeAccpet={(data) => {
              const { cookies } = this.props;
              cookies.set('EnableCookie', JSON.stringify(data));
            }} setCookieBool={(bool) => { this.setState({ cookieBool: bool }) }} />} */}
            <FooterSection />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default withCookies(LifeAtDrlView)

/* eslint-enable */
