import { useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { isTab } from '../../Utils/Utils';

const ImageSliderMobile = ({ data, history }) => {

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        // let images = document.querySelectorAll('img');
        // let z = -1;

        // images.forEach(image => {
        //     console.log(image.clientHeight);

        //     gsap.set(image, {yPercent: -100, zIndex: () => z});
        //     gsap.to(image, {
        //       scrollTrigger: {
        //         trigger: image,
        //         start: "top bottom",
        //         end: `+=${image.clientHeight}`,
        //         pin: true,
        //         pinSpacing: false,
        //         markers: true
        //       }
        //     });
        //     z = z - 1;
        // });

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#container_BusinessCapabilities",
                start: "top",
                end: "+=1500",
                pin: true,
                pinSpacing: true,
                // markers: true,
                scrub: true,
            }
        });

        // tl.set("#text_BusinessCapabilities-one", { opacity: 1, zIndex: 9999, })
        // tl.set("#text_BusinessCapabilities-two", { opacity: 0, zIndex: -140 })
        // tl.set("#text_BusinessCapabilities-three", { opacity: 0, zIndex: -140 })
        // tl.set("#img_BusinessCapabilities-two", { opacity: 0 })
        // tl.set("#img_BusinessCapabilities-three", { opacity: 0 })

        // // tl.to('#img_BusinessCapabilities-one', {
        // //   opacity: 0})

        // tl.to('#text_BusinessCapabilities-one', {
        //     opacity: 0,
        //     zIndex: -140,
        //     duration: 0.2
        // }, "<")

        // tl.set("#text_BusinessCapabilities-one", { opacity: 0, zIndex: -140 })
        // // tl.set("#img_BusinessCapabilities-one", {opacity: 0}, "<")
        // tl.set("#img_BusinessCapabilities-two", { opacity: 1 }, "<")

        // // tl.fromTo('#img_BusinessCapabilities-two', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text_BusinessCapabilities-two', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")

        // tl.set("#text_BusinessCapabilities-two", { opacity: 0, zIndex: -140 }, ">")
        // // tl.set("#img_BusinessCapabilities-two", {opacity: 0}, "<")
        // tl.set("#img_BusinessCapabilities-three", { opacity: 1 }, "<")


        // // tl.fromTo('#img_BusinessCapabilities-three', {
        // //     yPercent: 100
        // // },
        // //     { yPercent: 0 }, ">")

        // tl.fromTo('#text_BusinessCapabilities-three', {
        //     opacity: 0
        // },
        //     { opacity: 1, zIndex: 9999, duration: 0.2 }, "<")


        tl.set("#img_BusinessCapabilities-one", { opacity: 1 })
        tl.set("#text_BusinessCapabilities-one", { opacity: 1, zIndex: 9999, })

        tl.set("#img_BusinessCapabilities-two", { opacity: 0 })
        tl.set("#text_BusinessCapabilities-two", { opacity: 0, zIndex: -140 })
        tl.set("#img_BusinessCapabilities-two", { opacity: 1 }, "+=0.4")
        tl.set("#text_BusinessCapabilities-one", { opacity: 0, zIndex: -140 }, "<")
        tl.set("#text_BusinessCapabilities-two", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text_BusinessCapabilities-two", { opacity: 1, zIndex: 9999, }, "+=0.2")


        tl.set("#img_BusinessCapabilities-three", { opacity: 0 })
        tl.set("#text_BusinessCapabilities-three", { opacity: 0, zIndex: -140 })
        tl.set("#img_BusinessCapabilities-three", { opacity: 1 }, "+=0.4")
        tl.set("#text_BusinessCapabilities-two", { opacity: 0, zIndex: -140 }, "<")
        tl.set("#text_BusinessCapabilities-three", { opacity: 1, zIndex: 9999, }, "<")
        tl.set("#text_BusinessCapabilities-three", { opacity: 1, zIndex: 9999, }, "+=0.2")

    }, [data])


    return (
        data && data.length !== 0 && <>
            <div id="container_BusinessCapabilities" className="af-class-section" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
                <div id="img_BusinessCapabilities-container_BusinessCapabilities" style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
                    <div id="img_BusinessCapabilities-one" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0 }}><img  loading="lazy" id="img1" style={{ height: '100%', objectFit: 'cover',objectPosition:'35%' }} src={data[0].thumbnail} alt="image1" /></div>
                    <div id="img_BusinessCapabilities-two" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img  loading="lazy" id="img2" style={{ height: '100%', objectFit: 'cover', }} src={data[1].thumbnail} alt="image2" /></div>
                    <div id="img_BusinessCapabilities-three" class="img" style={{ position: 'absolute', height: '100%', top: 0, left: 0, opacity: 0 }}><img  loading="lazy" id="img3" style={{ height: '100%', objectFit: 'cover', }} src={data[2].thumbnail} alt="image3" /></div>
                </div>
                <div id="txt-container_BusinessCapabilities" style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: 'white', position: 'relative', bottom: 0 }}>
                    <div id="text_BusinessCapabilities-one" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if (isTab) {
                                    el.style.setProperty('line-height', '60px', 'important');
                                    el.style.setProperty('font-size', '40px', 'important');
                                } else {
                                    el.style.setProperty('line-height', '36px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', width: '100%' }} >{data[0].title}</p>
                        <a style={{marginTop:'20px'}} href={`/articles?${data[0].contentId}#${data[0].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[0].title} />
                        </a>
                    </div>
                    <div id="text_BusinessCapabilities-two" style={{ opacity: 0, position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if (isTab) {
                                    el.style.setProperty('line-height', '60px', 'important');
                                    el.style.setProperty('font-size', '40px', 'important');
                                } else {
                                    el.style.setProperty('line-height', '36px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', width: '100%' }} >{data[1].title}</p>
                        <a style={{marginTop:'20px'}} href={`/articles?${data[1].contentId}#${data[1].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[1].title} />
                        </a>
                    </div>
                    <div id="text_BusinessCapabilities-three" style={{ opacity: 0, position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <p ref={(el) => {
                            if (el && window.matchMedia('(max-width: 820px)').matches ) {
                                if (isTab) {
                                    el.style.setProperty('line-height', '60px', 'important');
                                    el.style.setProperty('font-size', '40px', 'important');
                                } else {
                                    el.style.setProperty('line-height', '36px', 'important');
                                }
                            }
                        }} style={{ fontSize: '24px', fontWeight: '200', marginTop: '18px', width: '100%' }} >{data[2].title}</p>
                        <a style={{marginTop:'20px'}} href={`/articles?${data[2].contentId}#${data[2].id}`} >
                            <img src="/images/purple-right-arrow.svg" loading="lazy" alt={data[2].title} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );

}

export default ImageSliderMobile;