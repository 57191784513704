import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

export const EquityDividendHistory = (props) => {

  const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
  const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 18px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      padding: 5px;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    overflow: auto;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px !important;
  color: #000000 !important; 
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  line-height: 1.7 !important;
  margin-bottom:100px !important;
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 10% 15%;
`;
  const DropDownDiv = styled.div`
  width:30%;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    width:60%;
    margin-bottom: 30px;
  }
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

  const Header = styled.h2`
  margin: 0 !important ;
  color: #000000 !important ;
  font-size: 18px !important ;
  font-family: Drlcircular !important ;
  font-weight: bold !important ;
  line-height: 1 !important ;
  margin-bottom: 30px !important ;
  @media (max-width: 900px) {
    font-size: 14px !important ;
    font-weight: 300 !important ;
    margin-bottom: 15px !important ;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
`;

  const TextContent = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: Drlcircular;
  margin-left: 50px;
  margin-bottom: 25px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
  margin-top:40px;
`;
  const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 4px;
  margin-left: 10px;
  justify-content: space-between;
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
  flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  text-transform: uppercase;
  @media (max-width: 1180px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
  const content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

  const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const minWidthBool = useMediaQuery({ query: "(min-device-width: 1180px)" });

  const [reports, setReports] = useState({});
  const [dropdownData, setDropdowndata] = useState([]);
  const [selectedFilterDate, setSelectedFilterDate] = useState("");

  useEffect(() => {
    var tempReports = {};
    var temp = [];
    var tempArr = [];
    var tempDropdownData = [];
    var tempObj = {};
    var tempProperty = "";
    axios({
      method: "get",
      url: config.serverUrl.base + "/jsonapi/node/equity_dividend_history",
    })
      .then((res) => {
        for (const report of res.data.data) {
          tempProperty =
            report.attributes.field_historytype;
          tempObj = {};
          tempArr = [];
          if (
            tempDropdownData.indexOf(report.attributes.field_year_history) ===
            -1
          ) {
            tempDropdownData.push(report.attributes.field_year_history);
          }
          tempDropdownData = tempDropdownData.sort().reverse();
          if (
            tempReports?.[report.attributes.field_year_history] &&
            tempReports[report.attributes.field_year_history]?.[tempProperty]
          ) {
            tempArr =
              tempReports[report.attributes.field_year_history]?.[tempProperty];
            tempArr.push({
              description:
                temp[report.attributes.field_equity_dividend_history_la.value],
            });
            tempReports[tempProperty] = tempArr;
            tempReports[report.attributes.field_year_history][tempProperty] =
              tempArr;
            tempReports[report.attributes.field_year_history]["desc"] =
              report.attributes.field_text1 ? report.attributes.field_text1.processed : "";
          } else {
            tempArr = [];
            tempArr.push({
              description:
                report.attributes.field_equity_dividend_history_la.value,
            });
            tempObj[tempProperty] = tempArr;
            if (tempReports?.[report.attributes.field_year_history]) {
              tempReports[report.attributes.field_year_history][tempProperty] =
                tempArr;
              tempReports[report.attributes.field_year_history]["desc"] =
                report.attributes.field_text1 ? report.attributes.field_text1.processed : "";
            } else {
              tempReports[report.attributes.field_year_history] = tempObj;
            }
          }
        }
        console.log(tempDropdownData);
        console.log(tempReports, "    Heyya there   ");
        if (tempDropdownData && tempDropdownData.length) {
          setSelectedFilterDate(tempDropdownData[0]);
        }
        setDropdowndata(tempDropdownData);
        setReports(tempReports);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  return (
    <SubContainer>
      <DropDownDiv>
        <Cdropdown
          data={dropdownData}
          placeholder="Year"
          id="reports"
          selectedValue={selectedFilterDate}
          onChangeValue={(val) => setSelectedFilterDate(val)}
        />
      </DropDownDiv>
      {reports?.[selectedFilterDate]?.["EQUITY HISTORY"] && reports?.[selectedFilterDate]?.["EQUITY HISTORY"].length !== 0 && <>
        <Header>EQUITY HISTORY</Header>
        <TableContainer>
          <table>
            {reports?.[selectedFilterDate]?.["EQUITY HISTORY"] &&
              reports?.[selectedFilterDate]?.["EQUITY HISTORY"].map(
                (item, index) => {
                  var result = [];
                  var tempArr = [];
                  for (const i in item.description) {
                    if (i !== "caption") {
                      tempArr = [];
                      item.description[i].map((item2, index) => {
                        if (i === "0") {
                          tempArr.push(<th style={{ padding: '20px 15px', width: index === 1 ? "28%" : index === 0 ? '21%' : '17%', textAlign: (index === 0 || index === 1) ? 'left' : 'right' }} >{item2}</th>);
                        } else {
                          tempArr.push(<td style={{ padding: '20px 15px', width: index === 1 ? "28%" : index === 0 ? '21%' : '17%', textAlign: (index === 0 || index === 1) ? 'left' : 'right' }} >{item2}</td>);
                        }
                      });
                      result.push(<tr>{tempArr}</tr>);
                    }
                  }
                  return result;
                }
              )}
          </table>
        </TableContainer>
        <br />
      </>}
      <TextLight>{reports?.[selectedFilterDate]?.desc}</TextLight>
      {reports?.[selectedFilterDate]?.["PROPOSED DIVIDEND"] && reports?.[selectedFilterDate]?.["PROPOSED DIVIDEND"].length !== 0 && <>
        <Header>PROPOSED DIVIDEND</Header>
        <TableContainer>
          <table>
            {reports?.[selectedFilterDate]?.["PROPOSED DIVIDEND"] &&
              reports?.[selectedFilterDate]?.["PROPOSED DIVIDEND"].map(
                (item, index) => {
                  var result = [];
                  var tempArr = [];
                  for (const i in item.description) {
                    if (i !== "caption") {
                      tempArr = [];
                      item.description[i].map((item2, index) => {
                        if (i === "0") {
                          tempArr.push(<th style={{ textAlign: 'left', padding: '20px 15px', }} >{item2}</th>);
                        } else {
                          tempArr.push(<td style={{ padding: '20px 15px', }} >{item2}</td>);
                        }
                      });
                      result.push(<tr>{tempArr}</tr>);
                    }
                  }
                  return result;
                }
              )}
          </table>
        </TableContainer>
        <br />
      </>}
      {reports?.[selectedFilterDate]?.["DIVIDEND HISTORY"] && reports?.[selectedFilterDate]?.["DIVIDEND HISTORY"].length !== 0 && <>
        <Header>DIVIDEND HISTORY</Header>
        <TableContainer>
          <table>
            {reports?.[selectedFilterDate]?.["DIVIDEND HISTORY"] &&
              reports?.[selectedFilterDate]?.["DIVIDEND HISTORY"].map(
                (item, index) => {
                  var result = [];
                  var tempArr = [];
                  for (const i in item.description) {
                    if (i !== "caption") {
                      tempArr = [];
                      item.description[i].map((item2, index) => {
                        if (i === "0") {
                          tempArr.push(<th style={{ textAlign: 'left', padding: '20px 15px', }} >{item2}</th>);
                        } else {
                          tempArr.push(<td style={{ padding: '20px 15px', }} >{item2}</td>);
                        }
                      });
                      result.push(<tr>{tempArr}</tr>);
                    }
                  }
                  return result;
                }
              )}
          </table>
        </TableContainer>
      </>}
    </SubContainer>
  )

}