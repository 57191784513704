import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile,SortMonthNames } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";
import { useMediaQuery } from "react-responsive";

const Label = styled.p`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-family: Drlcircular !important;
  margin: 0 !important;
  width:30% !important;
  margin-right:50px !important;
  @media (max-width: 900px) {
    font-size: 14px !important;
    width:60% !important;
    margin-right:0px !important;
  }
`;
const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 15%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 5px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownMain = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDownDiv = styled.div`
    width:30%;
    &:first-child {
      margin-right:18px;
    }
    @media (max-width: 900px) {
      width:60%;
      &:first-child {
      margin-top:20px;
      margin-bottom:20px;
    }
    }
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0;
    color: #000000;
    font-size: 18px;
    font-family: Drlcircular;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.8;
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const SubSubContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }
  }
    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }

    th,
    td {
      padding: 5px;
      opacity: 1;
      font-weight: 400;
      border: 0.1px solid #707070;
      font-size: 18px !important;
      line-height: 1.25 !important;
      text-align: left;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }

    th {
      border: 0.1x solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 18px !important;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1x solid #707070;
      }
    }
`;

export const InvestorMeetingDetails = (props) => {

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  const [reportsim, setReportsim] = useState({});
  const [dropdownDataim, setDropdowndataim] = useState([]);
  const [dropdownDataimm, setDropdowndataimm] = useState([]);
  const [selectedFilterDateim, setSelectedFilterDateim] = useState("");
  const [selectedFilterDateimm, setSelectedFilterDateimm] = useState("");

  useEffect(() => {
    var tempReports = {};
    var tempDropdownData = [];
    var tempDropdownDatam = [];
    var tempObj = {};
    var tempArr = [];
    axios({
      method: "get",
      url: config.serverUrl.base + "/jsonapi/node/investor_services_tables",
    })
      .then((res) => {
        // console.log(res);
        for (const report of res.data.data) {
          // console.log(report);
          tempObj = {};
          tempArr = [];
          if (
            tempDropdownData.indexOf(
              report.attributes.field_year_investormeeting
            ) === -1
          ) {
            tempDropdownData.push(report.attributes.field_year_investormeeting);
          }
          tempDropdownData = tempDropdownData.sort().reverse();
          if (
            tempDropdownDatam.indexOf(
              report.attributes.field_month_stockexchange
            ) === -1
          ) {
            tempDropdownDatam.push(report.attributes.field_month_stockexchange);
          }
          tempDropdownData = tempDropdownData.sort().reverse();
          tempDropdownDatam = tempDropdownDatam.sort().reverse();
          if (
            tempReports?.[report.attributes.field_year_investormeeting] &&
            tempReports[report.attributes.field_year_investormeeting]?.[
            report.attributes.field_month_stockexchange
            ]
          ) {
            tempArr =
              tempReports[report.attributes.field_year_investormeeting]?.[
              report.attributes.field_month_stockexchange
              ];
            tempArr.push({
              title: report.attributes.title,
              description: report.attributes.field_table.value,
            });
            tempObj[report.attributes.field_month_stockexchange] = tempArr;
            tempReports[report.attributes.field_year_investormeeting][
              report.attributes.field_month_stockexchange
            ] = tempArr;
          } else {
            tempArr = [];
            tempArr.push({
              title: report.attributes.title,
              description: report.attributes.field_table.value,
            });
            tempObj[report.attributes.field_month_stockexchange] = tempArr;
            if (tempReports?.[report.attributes.field_year_investormeeting]) {
              tempReports[report.attributes.field_year_investormeeting][
                report.attributes.field_month_stockexchange
              ] = tempArr;
            } else {
              tempReports[report.attributes.field_year_investormeeting] =
                tempObj;
            }
          }
        }
        if (tempDropdownData && tempDropdownData.length) {
          setSelectedFilterDateim(tempDropdownData[0]);
        }
        tempDropdownDatam = SortMonthNames(tempDropdownDatam);
        if (tempDropdownDatam && tempDropdownDatam.length) {
          setSelectedFilterDateimm(tempDropdownDatam[0]);
        }
        // console.log(tempDropdownData);
        // console.log(tempReports);
        setDropdowndataim(tempDropdownData);
        setReportsim(tempReports);
        setDropdowndataimm(tempDropdownDatam);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  return (<SubSubContainer>
    <div style={{ display: "flex", flexDirection: minWidthBool ? "column" : "row", alignItems: minWidthBool ? 'start' : 'center', width: '100%' }}>
      <Label>
        SELECT YEAR AND MONTH
      </Label>
      <DropDownMain>
        <DropDownDiv>
          <Cdropdown
            data={dropdownDataim}
            placeholder="Year"
            id="reportsim"
            selectedValue={selectedFilterDateim}
            onChangeValue={(val) => setSelectedFilterDateim(val)}
          />
        </DropDownDiv>
        <DropDownDiv>
          <Cdropdown
            data={dropdownDataimm}
            placeholder="Month"
            id="reportsimm"
            selectedValue={selectedFilterDateimm}
            onChangeValue={(val) => setSelectedFilterDateimm(val)}
            style={{ justifyContent: "space-between" }}
          />
        </DropDownDiv>
      </DropDownMain>
    </div>
    <div style={{ marginTop: '70px' }} >
      {reportsim?.[selectedFilterDateim]?.[selectedFilterDateimm] &&
        reportsim?.[selectedFilterDateim]?.[selectedFilterDateimm].map(
          (item) => {
            return (
              <TableContainer>
                <table>
                  {reportsim?.[selectedFilterDateim]?.[
                    selectedFilterDateimm
                  ] &&
                    reportsim?.[selectedFilterDateim]?.[
                      selectedFilterDateimm
                    ].map((item, index) => {
                      var result = [];
                      var tempArr = [];
                      for (const i in item.description) {
                        if (i !== "caption") {
                          tempArr = [];
                          item.description[i].map((item2, index) => {
                            if (i === "0") {
                              tempArr.push(<th style={{ textAlign: 'left', padding: '20px 15px' }} >{item2}</th>);
                            } else {
                              tempArr.push(<td style={{ padding: '20px 15px' }} >{item2}</td>);
                            }
                          });
                          result.push(<tr style={{ textAlign: 'left', padding: '20px 15px' }} >{tempArr}</tr>);
                        }
                      }
                      return result;
                    })}
                </table>
              </TableContainer>
            );
          }
        )}
    </div>
  </SubSubContainer>
  )
}