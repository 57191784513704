import React from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './index.css';

import EmpathyImg from '../../../assets/empathy1.png';
import DynamismImg from '../../../assets/Dynamism1.png';

const ImageTextOverlay = () => {

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        // REVEAL //
        gsap.utils.toArray(".revealUp").forEach(function (elem) {
            ScrollTrigger.create({
                trigger: elem,
                start: "top 40%",
                end: "bottom 0%",
                // markers: true,
                onEnter: function () {
                    gsap.fromTo(
                        elem,
                        { autoAlpha: 0 },
                        {
                            duration: 0.5,
                            autoAlpha: 1,
                            // ease: "back",
                            overwrite: "auto"
                        }
                    );
                },
                onLeave: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                },
                onEnterBack: function () {
                    gsap.fromTo(
                        elem,
                        { autoAlpha: 0 },
                        {
                            duration: 0.5,
                            autoAlpha: 1,
                            // ease: "back",
                            overwrite: "auto"
                        }
                    );
                },
                onLeaveBack: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                }
            });
        });
        // gsap.utils.toArray(".revealUp1").forEach(function (elem) {
        //     ScrollTrigger.create({
        //         trigger: elem,
        //         start: "top 20%",
        //         end: "bottom 30%",
        //         // markers: true,
        //         onEnter: function () {
        //             gsap.fromTo(
        //                 elem,
        //                 { y: 100, autoAlpha: 0 },
        //                 {
        //                     duration: 1.25,
        //                     y: 0,
        //                     autoAlpha: 1,
        //                     // ease: "back",
        //                     overwrite: "auto"
        //                 }
        //             );
        //         },
        //         onLeave: function () {
        //             gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
        //         },
        //         onEnterBack: function () {
        //             gsap.fromTo(
        //                 elem,
        //                 { y: -100, autoAlpha: 0 },
        //                 {
        //                     duration: 1.25,
        //                     y: 0,
        //                     autoAlpha: 1,
        //                     // ease: "back",
        //                     overwrite: "auto"
        //                 }
        //             );
        //         },
        //         onLeaveBack: function () {
        //             gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
        //         }
        //     });
        // });
    }, [])

    return <>
        <div style={{ marginTop: '8px', paddingTop: "32px", borderTop: "1px solid #ff5046", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} >
            <div style={{ backgroundImage: `url(${EmpathyImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '100vh', width: '49%', }}  >
                <span className='revealUp' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                    {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                    <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)' }} >Empathy</h2>
                    <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '18%', paddingRight: '18%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >We understand the needs of our patients<br/>and partners better than others.</h6>
                </span>
            </div>
            <div style={{ backgroundImage: `url(${DynamismImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '100vh', width: '49%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                <span className='revealUp' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                    {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                    <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)' }} >Dynamism</h2>
                    <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '18%', paddingRight: '18%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >We solve challenges that only a few<br/>can, and do this with agility.</h6>
                </span>
            </div>
        </div>
    </>
    // <div>
    //     {/* <div class="section">
    //         <h1>Scroll Down</h1>
    //     </div> */}

    //     <div class="section bg1">
    //         <h1 class="revealUp">Animation 1</h1>
    //     </div>

    //     {/* <div class="section bg2">
    //         <h1 class="revealUp">Animation 2</h1>
    //     </div>

    //     <div class="spacer"></div> */}
    // </div>
}

export default ImageTextOverlay;