import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import BackIcon from '../../Common/images/backArrow.svg';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px !important;
    color: #000000 !important;
    font-family: Drlcircular !important;
    font-weight: 300 !important;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        font-size: 24px !important;
    }
`;

const TextSection = styled.p`
    width: 90%!important;
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 300!important;
    margin-top: 40px !important;
    line-height: 1.8!important;
    @media (max-width: 900px) {
        margin-top: 24px !important;
        width: 100%!important;
        font-size: 16px !important;
        line-height: 24px!important;
    }
`;

const TextHeader = styled.p`
    font-size: 22px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;

const TextBold = styled.p`
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;

const TextLink = styled.a`
    cursor: pointer !important;
    color: #000 !important;
    font-size: 18px !important;
    font-family: Drlcircular !important;
    font-weight: 700 !important;
    text-decoration: underline !important;
    &:hover{
      color: #000;
      text-decoration: underline !important;
  }
    @media (max-width: 900px) {
        font-size: 14px !important;
    }
`;

const UL = styled.ul`
    margin: 0!important;
    padding-left: 30px!important ;
`;

const LI = styled.li`
    padding-bottom: 10px;
`;

export function MainSection(props) {
    return <div className="af-class-section" style={{paddingTop: '180px'}}>
        <div className="af-class-container">
            <MainContainer>
                <BodySection>
                    <Heading>Data Privacy Center</Heading>
                    <HR />
                    <TextSection>
                        <TextHeader>Our Commitment and Approach towards Data Privacy </TextHeader>
                        We at Dr Reddy's recognize the fundamental privacy rights of all individuals we interact with. We are committed to responsibly using Personal Information in accordance with the Data Privacy laws in all the countries we operate in. <br /><br />
                        <TextHeader>Our Data Privacy Principles: </TextHeader>
                        We strive to honor our commitment with the help of the following globally recognised and accepted principles of privacy, by: <br />
                        <UL>
                            <LI>
                                <TextBold>Transparency</TextBold>
                                Ensuring that before collecting Personal Information from anyone, we inform each individual about how we will manage their data.
                            </LI>
                            <LI>
                                <TextBold>Purpose Limitation:</TextBold>
                                Collecting Personal Information only for specific and legitimate business purpose(s). We also ensure that such data is used (collected, stored, shared etc.) responsibly.
                            </LI>
                            <LI>
                                <TextBold>Data Minimization: </TextBold>
                                Making certain that Personal Information is collected only if it's directly relevant and necessary to fulfil our legitimate business purpose.
                            </LI>
                            <LI>
                                <TextBold>Security, Integrity, and Accuracy: </TextBold>
                                Protecting all Personal Information in a manner that is consistent with Dr. Reddy's standards. This includes ensuring that all third parties providing services to us, meet our Data Privacy and Protection standards.
                            </LI>
                            <LI>
                                <TextBold>Minimal Retention: </TextBold>
                                Retaining Personal Information only as long as it is necessary to fulfil the purpose for which it was originally collected.
                            </LI>
                            <LI>
                                <TextBold>Accountability</TextBold>
                                Taking responsibility for collection, use and transfer of Personal Information and have appropriate measures and records in place to demonstrate compliance with all the above principles.
                            </LI>
                        </UL>
                        <br />
                        <TextHeader>Privacy Notices</TextHeader>
                        As part of our commitment to ensure ‘transparency’, we have made available the below notices to help you understand how your Personal Information is processed based on the relationship you have with us. Please select the notice most relevant to you.  <br />
                        <UL>
                            <LI> 
                                <TextBold> <TextLink href='https://drreddys.com/cms/sites/default/files/privacy/Dr-Reddys-Global-Privacy-Notice.pdf' target="_blank">Global Privacy Notice</TextLink></TextBold>
                                This notice provides a general description of how we collect and manage the Personal Information of all individuals with whom we interact. 
                            </LI>
                            <LI> 
                                <TextBold> <TextLink href='https://drreddys.com/cms/sites/default/files/privacy/Dr-Reddys-Website-Privacy-Notice.pdf' target="_blank"> Website Privacy Notice </TextLink> </TextBold>
                                This notice is addressed to the users of our websites. 
                            </LI>
                            <LI>
                                <TextBold> <TextLink href='https://drreddys.com/cms/sites/default/files/privacy/Dr-Reddys-Pharmacovigilance-Privacy-Notice.pdf' target="_blank"> Pharmacovigilance Privacy Notice </TextLink> </TextBold>
                                This notice is addressed to individuals who report any adverse events concerning the safety and quality of our products. 
                            </LI>
                            <LI>
                                <TextBold> <TextLink href='https://drreddys.com/cms/sites/default/files/privacy/Dr-Reddys-Privacy-Notice-for-Job-Applicatants.pdf' target="_blank">Job Applicants and Prospects Privacy Notice</TextLink></TextBold>
                                This notice is addressed to any individuals who wish to pursue career opportunities with us. 
                            </LI>
                            <LI>
                                <TextBold> <TextLink href='https://drreddys.com/cms/sites/default/files/privacy/Privacy-Notice-for-Third-Parties.pdf' target="_blank">Privacy Notice for Third Parties</TextLink></TextBold>
                                This notice is addressed to ‘external’ third-party entities whom we partner with during the course of our business, their representatives as well as to visitors of any our facilities.
                            </LI>
                        </UL>
                    </TextSection>
                </BodySection>
            </MainContainer>
        </div>
    </div>
}
