import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { HR } from '../../common/horizontalLine';
import { AnimeButton } from '../../common/AnimeButton';
import AnimeArrowFull from './AnimeArrowFull';
// import { AnimeDownloadButton } from '../../common/AnimeDownloadButton';

import YearFilterScroller from './YearFilterScroller';

import axios from 'axios';
import config from '../../../config';

import Results from './results.json';

import Lottie from 'react-lottie';
import * as loaderLottie from '../../../assets/Lottie/loader.json';

const Main = styled.div`
    width:100%;
    min-height:50vh;
    display: flex;
    flex-direction:column;
`;

const FileMain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`;

const TextHeading = styled.a`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-bottom:8px !important;
  line-height:30px !important;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
      color: #000;
      text-decoration: underline !important;
  }
  @media (max-width: 1030px) {
    font-size: 16px !important;
    line-height:24px !important;
    width: 40% !important;
  }
`;

const TextLight = styled.p`
  margin: 0 !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  @media (max-width: 1030px) {
    font-size: 12px !important;
    width: 40% !important;
  }
`;

// const scripts = [
//     { loading: fetch("webflow_javascript/jquery-3.5.1.min.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: fetch("webflow_javascript/dr-reddy.js").then(body => body.text()), isAsync: false, lastOne: false },
//     { loading: Promise.resolve("document.addEventListener(\"DOMContentLoaded\",()=>{document.querySelectorAll(\".menu-button\").forEach(e=>{e.addEventListener(\"click\",function(){this.x=((this.x||0)+1)%2,this.x?document.querySelectorAll(\"body\").forEach(e=>e.classList.add(\"no-scroll\")):document.querySelectorAll(\"body\").forEach(e=>e.classList.remove(\"no-scroll\"))})})});"), isAsync: false, lastOne: true },
// ]

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const PressRelease = (props) => {

    const [pressReleases, setPressReleases] = useState({});
    // const [yearsList, setYearsList] = useState(["2022","2021","2020","2019","2018","2022","2021","2020","2019","2018"]);
    // const [selectedYear, setSelectedYear] = useState("2022");
    let currentYear = new Date().getFullYear();
    let tempArr = [];
    for (let i = Number(currentYear); i >= 2005; i--) {
        tempArr.push(i.toString());
    }
    tempArr.push('2001')
    const [yearsList, setYearsList] = useState([...tempArr]);
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    useEffect(() => {

        // const htmlEl = document.querySelector('html')
        // htmlEl.dataset['wfPage'] = '60952b8da0e26580fc1fde12'
        // htmlEl.dataset['wfSite'] = '60952b8da0e265187c1fde0e'

        // scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
        //     const loading = active.loading.then((script) => {
        //         new Function(`
        //       with (this) {
        //         eval(arguments[0])
        //       }
        //     `).call(window, script)
        //         return next
        //     })
        //     // if (active.lastOne) {
        //     //     this.setState({ renderPageBool: true })
        //     // }
        //     return active.isAsync ? next : loading
        // }))

        triggerApiCall(selectedYear);

    }, [])

    const triggerApiCall = (year) => {
        if(!pressReleases.hasOwnProperty(year)){
        var tempObj = {};
        var tempYears = [];
        var tempPR = {};
        axios({ method: "get", url: config.serverUrl.base + "/jsonapi/node/pressreleases?include=field_file&sort=-field_date_for_order2&filter[field_covid_news_years]=" + year, })
            .then((res) => {
                const { data, included } = res.data;
                // const { data, included } = Results;
                var imgPathsObj = {};
                for (var imgObj of included) {
                    imgPathsObj[imgObj.id] = imgObj.attributes.uri.url;
                }
                for (var item of data) {
                    if (tempYears.indexOf(item.attributes.field_covid_news_years) === -1) {
                        tempYears.push(item.attributes.field_covid_news_years);
                    }
                    tempObj = {
                        title: item.attributes.title,
                        year: item.attributes.field_covid_news_years,
                        dateAndTime: item.attributes.field_date_and_time,
                        file: item.relationships.field_file.data ? config.serverUrl.imageBase + imgPathsObj[item.relationships.field_file.data.id] : "",
                    }
                    if (tempPR.hasOwnProperty(item.attributes.field_covid_news_years)) {
                        var tempArr = [...tempPR[item.attributes.field_covid_news_years]];
                        tempArr.push(tempObj);
                        tempPR[item.attributes.field_covid_news_years] = [...tempArr]
                    } else {
                        tempPR[item.attributes.field_covid_news_years] = [tempObj]
                    }
                }
                tempYears = tempYears.sort().reverse();
                // setYearsList(tempYears);
                setPressReleases(tempPR);
                // setSelectedYear(tempYears[0]);
                console.log(tempPR, "  -=-  ");
            }).catch((Err) => {
                console.log(Err, "-=-=-=  ERR")
            })
        }
    }


    return <Main>
        {selectedYear && selectedYear.length !== 0 && <>
            <YearFilterScroller id="PressRelease" selectedYear={selectedYear} onChange={(year) => {
                setSelectedYear(year)
                triggerApiCall(year);
            }} data={yearsList} />
            <HR />
        </>}

        {/* <>
            <FileMain>
                <span style={{ flex: 1, maxWidth: '80%' }} >
                    <TextHeading target="_blank" href={"https:www.google.co.in"} >Google</TextHeading>
                    <TextLight>new date</TextLight>
                </span> */}

                {/* <AnimeButton target="_blank" href={"https:www.google.co.in"} /> */}
                {/* <AnimeArrowFull target="_blank" href={"https:www.google.co.in"} />

            </FileMain>
            <HR />
        </> */}

        {
            selectedYear && selectedYear.length !== 0 && pressReleases[selectedYear] && pressReleases[selectedYear].map((item, index) => {
                return <>
                    <FileMain>
                        <span style={{ flex: 1, maxWidth: '80%' }} >
                            <TextHeading target="_blank" href={item.file} >{item.title}</TextHeading>
                            <TextLight>{item.dateAndTime}</TextLight>
                        </span>
                        {/* <AnimeButton target="_blank" href={item.file} /> */}
                        <AnimeArrowFull target="_blank" href={item.file} />
                        {/* <a target="_blank" href={item.file} style={{ marginRight: '30px', marginLeft: '50px',alignSelf:'center' }} {...props}
                            className="af-class-link-arrow af-class-left-side w-inline-block">
                            <div className="af-class-link-circle af-class-purple" />
                            <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                            <img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                        </a> */}
                    </FileMain>
                    <HR />
                </>
            })
        }

        {
            !(selectedYear && selectedYear.length !== 0 && pressReleases[selectedYear] && pressReleases[selectedYear].length !== 0) && <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Lottie options={defaultOptions}
                    // height={150}
                    width={"40vw"}
                />
            </div>
        }

    </Main>
}