import React from 'react';
import styled from 'styled-components';
import { HR } from '../common/horizontalLine';

const MainContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`;

const BodySection = styled.div`
    width: 100%;
`;

const Heading = styled.h2`
    font-size: 40px !important;
    color: #000000 !important;
    font-family: Drlcircular !important;
    font-weight: 300 !important;
    margin-bottom: 30px !important;
    @media (max-width: 900px) {
        font-size: 24px !important;
    }
`;

const TextSection = styled.p`
    width: 90%!important;
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 300!important;
    margin-top: 40px !important;
    line-height: 1.8!important;
    @media (max-width: 900px) {
        margin-top: 24px !important;
        width: 100%!important;
        font-size: 16px !important;
        line-height: 24px!important;
    }
`;

const TextBold = styled.span`
    font-size: 18px!important;
    color: #000000!important;
    font-family: Drlcircular!important;
    font-weight: 700!important;
    @media (max-width: 900px) {
        font-size: 16px!important;
    }
`;

const TextLink = styled.a`
    cursor: pointer !important;
    color: #000 !important;
    font-size: 18px !important;
    font-family: Drlcircular !important;
    font-weight: 700 !important;
    text-decoration: none !important;
    &:hover{
      color: #000;
      text-decoration: underline !important;
  }
    @media (max-width: 900px) {
        font-size: 14px !important;
    }
`;

const UL = styled.ul`
    margin: 0!important;
    padding-left: 20px!important ;
`;

export const ContentSection = (props) => {
  return (
    <div className="af-class-section" style={{ paddingTop: '180px' }}>
      <div className="af-class-container">
        <MainContainer>
          <BodySection>
            <Heading>Caution Notice - "Recruitment Fraud"</Heading>
            <HR />
            <TextSection>
              <p>
                It is hereby cautioned to the general public that in recent past, some individuals and recruitment agencies have tried to mislead the public by fictitiously claiming to be employees of Dr. Reddy’s Laboratories Ltd. (and/or its group companies) or posing as authorised recruitment agencies of Dr. Reddy’s Laboratories Ltd. (and/or its group companies).
              </p>
              <p>
                It has been brought to our notice that they send fake e-mails to the potential job seekers luring them with job offers and appointment letters using the details of Dr. Reddy’s Laboratories Ltd. (and/or its group companies). These individuals/recruitment agencies are misguiding the job-seekers by promising them well-placed jobs with the company and asking them for a security deposit for providing return air-fare for the final round of interview. Further, they are corresponding through fake letterheads and fake mail id's of @drreddys.com domain name.
              </p>
              <p>
                Kindly note that Dr. Reddy’s Laboratories Ltd. (and/or its group companies) follows a formal recruitment process through its own HR department. Further, Dr. Reddy’s Laboratories Ltd. (and/or its group companies) does not ask for any security deposit or fees (refundable or non-refundable) at any stage of recruitment process.
              </p>
              <p>
                Please be advised that any potential job-seeker willingly corresponding with such individuals and recruitment agencies in any manner whether by e-mails or otherwise will be doing so at their own risks and consequences. The company strongly recommends that the potential job-seekers should not respond to such solicitations.
              </p>
              <p>
                Kindly note that Dr. Reddy’s Laboratories Ltd. (and/or its group companies) will not accept any liability for any loss or damage that may be suffered or incurred directly or indirectly through correspondence with fraudulent individuals or recruitment agencies and that such communication should not be treated as an offer or representation from Dr. Reddy’s Laboratories Ltd. (and/or its group companies).
              </p>
              <p>
                Kindly also verify the email id from which the job offer is received - in most cases the domain of the email id used is fake and does not follow the Dr. Reddy’s domain email. Should you come across any such fraudulent incident or have any information regarding solicitation for recruitment or employment with Dr. Reddy’s Laboratories Ltd. (and/or its group companies), please assist us in taking appropriate action to curb such mala fide activities.
              </p>
              <p>
                <TextBold>You may raise your concern by:</TextBold>
                <UL>
                  <li>Accessing <TextLink href='https://drreddys.ethicspoint.com' target="_blank">https://drreddys.ethicspoint.com</TextLink> .</li>
                  <li>Calling on the hotline number. List of hotline numbers are available in <TextLink href='https://drreddys.ethicspoint.com' target="_blank">https://drreddys.ethicspoint.com</TextLink>. The hotline is available 24x7 in multiple languages</li>
                  <li>Writing to <TextLink href='mailto:complianceofficer@drreddys.com'>complianceofficer@drreddys.com</TextLink> or <TextLink href='mailto:chiefombudsperson@drreddys.com'>chiefombudsperson@drreddys.com</TextLink></li>
                  <li>By Post / Courier to: Chief Compliance Officer / Chief Ombudsperson, 8-2-337, Road No. 3, Banjara Hills, Hyderabad 500 034, Telangana, India</li>
                </UL>
              </p>
            </TextSection>
          </BodySection>
        </MainContainer>
      </div>
    </div>
  );
}
