import React from "react";
import styled from "styled-components";

import { HR } from "../../common/horizontalLine";

const MainContainer = styled.div`
  // padding: 5%;
  margin-top:40px;
`;

const SubHeading = styled.h5`
  font-size: 18px  !important;
  color: #000000  !important;
  font-weight: bold  !important;
  font-family: Drlcircular  !important;
  margin-top: 50px  !important;
  @media (max-width: 900px) {
    font-size: 14px  !important;
    margin-top: 24px  !important;
  }
`;

const Content = styled.p`
  color: #000000!important;
  font-weight: 300!important;
  font-family: Drlcircular!important;
  font-size: 18px!important;
  margin-bottom: 40px!important;
  line-height: 2 !important;
  @media (max-width: 900px) {
    font-size: 14px!important;
    margin-bottom: 24px!important;
    line-height: 24px !important;
  }
`;

const CButton = styled.a`
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 700 !important;
  font-family: Drlcircular !important;
  cursor: pointer !important;
  margin-top:30px;
  text-decoration:none !important;
  &:hover{
    text-decoration:underline !important;
  }
  @media (max-width: 820px) {
    font-size: 14px !important;
    font-weight: 300 !important;
    text-decoration:underline !important;
  }
`;

export function ProspectivePartnersView(props) {
  return (
    <MainContainer>
      <Content>
        Making a holistic impact on the health of patients worldwide requires us
        to work with partners across the healthcare value chain. From other
        pharmaceutical companies, doctors, healthcare professionals, hospitals,
        distribution networks and retailers, we work with all of them always
        keeping the end consumer – the patient at the center of all we do. We
        focus on our partners with a high-value strategic mindset and who place
        a premium on quality, compliance and relationships.{" "}
      </Content>
      <SubHeading>Business Partners</SubHeading>
      <Content>
        Ensuring a continuous supply, remaining cost-competitive and
        guaranteeing highest quality are vital to provide affordable medicines
        to patients worldwide. To achieve this, we work with business partners
        in various ways across the value chain. We emphasize fair, transparent
        and ethical practices and seek partners who share the same commitment
        towards compliance with laws, regulations, published standards and
        environmental practices.If you’re interested in partnering with Dr.
        Reddy’s, we’d love to hear of your products and services. Please do
        register with us.
      </Content>
      <CButton target="_blank" href="https://vikretaconnect.mydrreddys.com/irj/portal" >Register with us</CButton>
      <SubHeading>R&D Partners </SubHeading>
      <Content>
        Research and development is at the heart of all we do for patients. As
        an R&D driven organization focused on innovation, we understand the
        value of shared knowledge and leveraged skills. This is why we actively
        seek out partnerships and collaboration within the industry that can
        help us successfully make high quality and affordable medicines. Our R&D
        efforts have been leveraging external partnerships for one or more parts
        of the value chain – development, manufacturing or both. Currently we
        work with nearly 50 partners spread across the world, with the three
        major clusters being USA, Western Europe and India. We partner with
        organizations, which complement our strengths in development and/or
        manufacturing.If there’s something that we could help you with, please
        do post your query.
      </Content>
      <CButton target="_blank" href="/business-partner#post-an-enquiry" >Post your query</CButton>
    </MainContainer>
  );
}
