import React from "react";
import styled from "styled-components";

import { HR } from "../common/horizontalLine";
import config from "../../config";

import IndiaImg from "../../Common/images/India.jpg";
import AmericaImg from "../../Common/images/America.jpg";

import SarahImg from "../../Common/images/Sarah.jpg";
import AjayImg from "../../Common/images/Ajay.jpg";
import RichardImg from "../../Common/images/Richard.jpg";
import KrishImg from "../../Common/images/Krishna.jpg";

import rightArrowPrimary from '../../assets/rightArrowPrimary.svg';
import PurpleBack from "../../Common/images/backArrowFullPrimary.svg";


const Main = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 5% 5%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Heading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-family: Drlcircular !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 1 !important;
  margin-bottom: 35px !important;
  @media (max-width: 900px) {
    font-size: 24px !important;
    line-height: 24px !important;
    margin-bottom: 20px !important;
  }
`;

const ContentText = styled.p`
  color: #000000!important;
  font-weight: 300!important;
  font-family: Drlcircular!important;
  margin: 1% 0%!important;
  font-size: 18px!important;
  line-height: 1.9!important;
  @media (max-width: 900px) {
    font-size: 16px!important;
    line-height: 28px!important;
  }
  `;

const BoldTextContent = styled.span`
  font-size: 18px!important;
  color: #000000!important;
  font-weight: 700!important;
  font-family: Drlcircular!important;
  @media (max-width: 900px) {
    font-size: 16px!important;
    line-height: 28px!important;
  }
`;

const OpeningAtDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpenAtText = styled.h2`
margin: 0 !important;
color: #000000 !important;
font-family: Drlcircular !important;
font-weight: 300 !important;
font-size: 40px !important;
line-height: 1 !important;
margin-top:40px !important;
margin-bottom: 15px !important;
@media (max-width: 900px) {
  font-size: 24px !important;
  line-height: 33px !important;
  margin-top:20px !important;
  margin-bottom: 32px !important;
}
`;

const CountriesDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

const CountryImgItem = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CountryItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 30vh;
  background-image: ${({ imgSrc }) => imgSrc};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 40px;
  text-decoration:none !important;
  cursor:pointer !important;
`;

const CountryItemText = styled.p`
  margin: 0;
  color: #ffffff;
  font-size: 40px;
  font-family: Drlcircular;
  opacity: 1;
  font-weight: 200;
  position: relative;
  z-index: 2;
`;

const TestimonialDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TestimonialText = styled.h4`
  margin: 0;
  font-size: 40px !important;
  font-weight: 200 !important;
  font-family: Drlcircular;
  line-height: 1.5 !important;
  max-width: 60%;
`;

const TestimonialImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TestimonialImg = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 150px;
  background-color: #ddd;
`;
const TestimonialImgName = styled.p`
  margin: 0;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: Drlcircular !important;
  margin-top: 12px !important;
`;

const FileMain = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`;

const TextHeading = styled.a`
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  margin-bottom:8px !important;
  line-height:30px !important;
  color: #522585;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
      color: #522585;
      text-decoration: underline !important;
  }
  @media (max-width: 1030px) {
    font-size: 16px !important;
    width: 40% !important;
  }
`;

export function ContentSection(props) {
  return (
    <>
      <div className="af-class-section">
        <div className="af-class-container">
        {!window.matchMedia('(max-width: 820px)').matches && <HR /> }
            <FileMain>
              <span style={{ flex: 1 }} >
                <TextHeading target="_blank" href={config.externalLinks.IndiaLink} >Explore our Global Career Page</TextHeading>
              </span>
              {
                window.matchMedia('(max-width: 820px)').matches
                  ?
                  <a target="_blank" href={config.externalLinks.IndiaLink} >
                    <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                  </a>
                  :
                  <a target="_blank" href={config.externalLinks.IndiaLink} style={{ marginLeft: '50px', alignSelf: 'center' }} {...props}
                    className="af-class-link-arrow af-class-left-side w-inline-block">
                    <div className="af-class-link-circle af-class-purple" />
                    <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                    <img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                  </a>
              }
            </FileMain>
            <HR />
            <br />
          {!window.matchMedia('(max-width: 820px)').matches && props.state.refer && 
          <a onClick={() => {
             window.history.go(-1)
          }} style={{ position: 'absolute', left: 0, top: 0, zIndex: 999, marginTop: '-80px', display: 'flex', flexDirection: 'row', textDecoration: 'none', cursor: 'pointer', alignItems: 'center' }} >
              <img  loading="lazy"src={PurpleBack} style={{ height: '16px', width: '20px', marginRight: '13px', }} />
              <p style={{ color: '#5224B5', fontSize: '14px', fontWeight: 'bold', padding: 0, margin: 0 }} >BACK</p>
          </a>}
          <Heading>Life at Dr.Reddy’s</Heading>
          {/* <HR /> */}
          <ContentText>
            Talented people have played a major role in powering and defining the growth of Dr. Reddy’s over the past four-odd decades. They have been supported by an enabling environment that buoys individual ability while fostering teamwork and shared success. We believe that when people with diverse skills are bound together by a common purpose and value system, they can make magic. {<br />}
            {<br />}
            As a member of our team, you will: {<br />}
            {<br />}
            <BoldTextContent>
              Make the world a healthier place {<br />}
            </BoldTextContent>
            Every day, patients across the world trust us with their health. We have earned this trust through integrity, quality and transparency. We invite you to become part of a company that does not compromise its purpose and values. {<br />}
            {<br />}
            <BoldTextContent>
              Become part of a team that cares
              {<br />}
            </BoldTextContent>
            We are committed to each other's safety and well-being. In each of our plants, worksites, offices and out in the field, our people believe in taking care of each other. Collectively, we are committed to taking care of the communities where we operate. {<br />}
            {<br />}
            <BoldTextContent>
              Enjoy professional freedom to create impact {<br />}
            </BoldTextContent>
            We will provide the space and freedom for you to experiment, innovate and put your talents to work. You will have the opportunity to unleash your potential and create impact in any area you work.{" "}
            {<br />}
            {<br />}
            <BoldTextContent>
              Learn continually, excel and grow {<br />}
            </BoldTextContent>
            We work in the frontiers of knowledge, and irrespective of the field you choose, you will be exposed to some of the finest thinking in the world. You will be actively supported in your development and have access to cutting-edge resources.{<br />}
            {<br />}
            <BoldTextContent>
              Expect to be treated with respect {<br />}
            </BoldTextContent>
            We believe in respecting every individual, regardless of position. You will be heard and you will have the opportunity to express your opinion. You will have the freedom to interact with our leaders. In turn, you’re expected to be equally accessible and respectful.{<br />}
            {<br />}
          </ContentText>
          <OpeningAtDiv>
          { /* <TextHeading target="_blank" href={config.externalLinks.IndiaLink} >Current openings</TextHeading> */ }
            <OpenAtText></OpenAtText>
            {/* !window.matchMedia('(max-width: 820px)').matches && <HR /> */}
            { /* <FileMain>
              <span style={{ flex: 1 }} >
                <TextHeading target="_blank" href={config.externalLinks.IndiaLink} >India</TextHeading>
              </span>
              {
                window.matchMedia('(max-width: 820px)').matches
                  ?
                  <a target="_blank" href={config.externalLinks.IndiaLink} >
                    <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                  </a>
                  :
                  <a target="_blank" href={config.externalLinks.IndiaLink} style={{ marginLeft: '50px', alignSelf: 'center' }} {...props}
                    className="af-class-link-arrow af-class-left-side w-inline-block">
                    <div className="af-class-link-circle af-class-purple" />
                    <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                    <img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                  </a>
              }
            </FileMain>
            <HR />
            <FileMain>
              <span style={{ flex: 1 }} >
                <TextHeading target="_blank" href={config.externalLinks.NorthAmericaLink} >North America</TextHeading>
              </span>
              {
                window.matchMedia('(max-width: 820px)').matches
                  ?
                  <a target="_blank" href={config.externalLinks.NorthAmericaLink} >
                    <img loading="lazy" src={rightArrowPrimary} style={{ margin: '0 10px' }} />
                  </a>
                  :
                  <a target="_blank" href={config.externalLinks.NorthAmericaLink} style={{ marginLeft: '50px', alignSelf: 'center' }} {...props}
                    className="af-class-link-arrow af-class-left-side w-inline-block">
                    <div className="af-class-link-circle af-class-purple" />
                    <img src="images/purple-right-arrow.svg" loading="lazy" alt className="af-class-black-arrow" />
                    <img src="images/white-arrow_right.svg" loading="lazy" alt className="af-class-white-arrow" />
                  </a>
              }
            </FileMain>
            <HR /> */ }
          </OpeningAtDiv>
          {/* <OpeningAtDiv>
            <OpenAtText>Current openings</OpenAtText>
            <CountriesDiv>
              <CountryItem target="_blank" href={config.externalLinks.IndiaLink} imgSrc={`url(${IndiaImg})`}>
                <CountryImgItem>
                  <CountryItemText>India</CountryItemText>
                </CountryImgItem>
              </CountryItem>
              <CountryItem target="_blank" href={config.externalLinks.NorthAmericaLink} imgSrc={`url(${AmericaImg})`}>
                <CountryImgItem>
                  <CountryItemText>North America</CountryItemText>
                </CountryImgItem>
              </CountryItem>
            </CountriesDiv>
          </OpeningAtDiv> */}
        </div>
      </div>
      {/* <div className="af-class-section" style={{ backgroundColor: "#EDEDED" }} >
        <div className="af-class-container" style={{ backgroundColor: "#EDEDED" }} >
          <TestimonialDiv>
            <TestimonialText>
              “Everyone here is valued for the contributions they make”
            </TestimonialText>
            <TestimonialImgDiv>
              <TestimonialImg src={SarahImg} />
              <TestimonialImgName>Sarah Warren</TestimonialImgName>
            </TestimonialImgDiv>
          </TestimonialDiv>
          <HR margin="50px 0" />
          <TestimonialDiv>
            <TestimonialText>
              “Dr. Reddy’s is an organisation that really cares for people.”
            </TestimonialText>
            <TestimonialImgDiv>
              <TestimonialImg src={AjayImg} />
              <TestimonialImgName>Ajay Prakash Dhanak</TestimonialImgName>
            </TestimonialImgDiv>
          </TestimonialDiv>
          <HR margin="50px 0" />
          <TestimonialDiv>
            <TestimonialText>
              “The chairman speaking to us made<br />us feel respected.”
            </TestimonialText>
            <TestimonialImgDiv>
              <TestimonialImg src={RichardImg} />
              <TestimonialImgName>Richard Heri</TestimonialImgName>
            </TestimonialImgDiv>
          </TestimonialDiv>
          <HR margin="50px 0" />
          <TestimonialDiv>
            <TestimonialText>
              “The chairman speaking to us made<br />us feel respected.”
            </TestimonialText>
            <TestimonialImgDiv>
              <TestimonialImg src={KrishImg} />
              <TestimonialImgName>Krishna Venkatesh</TestimonialImgName>
            </TestimonialImgDiv>
          </TestimonialDiv>
        </div>
      </div> */}
    </>
  );
}
