import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";

export const UnpaidUnclaimedDividend = (props) => {

  const IcsTextContainer = styled.div`
  width: 80%;
  margin-top: 40px;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 24px;
  }
`;
  const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1px solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px !important;
  line-height: 1.5 !important;
  color: #000000 !important;
  font-weight: 300 !important;
  font-family: Drlcircular !important;
  @media (max-width: 980px) {
    font-size: 14px !important;
  line-height: 24px !important;
  }
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 10% 15%;
`;
  const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

  const Header = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 570px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
`;

  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
`;
  const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 4px;
  margin-left: 10px;
  justify-content: space-between;
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
  flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  text-transform: uppercase;
  @media (max-width: 1180px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
  const content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

  const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const TextContent = styled(Link)`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  text-decoration:none !important;
  &:hover{
    color: #000;
    text-decoration: underline !important;
  }
  @media (max-width: 900px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    text-decoration:underline !important;
  }
`;

  const minWidthBool = useMediaQuery({ query: "(min-device-width: 1180px)" });
  const [unpaidreports, setunpaidreports] = useState([]);

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = [];
    var tempArr = [];
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/year_2021_details_of_unpaid_and_?include=field_file_unpaid",
    })
      .then((res) => {
        for (const item of res.data.included) {
          tempIncludedObj[item.id] =
            config.serverUrl.imageBase + item.attributes.uri.url;
        }
        for (const report of res.data.data) {
          if (tempReports?.[report.attributes.title]) {
            tempArr = tempReports[report.report.attributes.title];
            tempArr.push({
              title: report.attributes.title,
              url: tempIncludedObj[
                report.relationships.field_file_unpaid.data.id
              ],
            });
          } else {
            tempArr = [];
            tempArr.push({
              title: report.attributes.title,
              url: tempIncludedObj[
                report.relationships.field_file_unpaid.data.id
              ],
            });
          }
        }
        setunpaidreports([...tempArr]);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  return (
    <IcsTextContainer>
      <TextLight>
        “The Dividend declared and interest/redemption amount distributed by
        the Company to its share/debenture-holders which remains unclaimed
        gets accumulated with the Company. The Ministry of Corporate Affairs
        (MCA) proactively addressed the issue by introducing Section 125 of
        the Companies Act, 2013, thereby making it compulsory to deposit
        unclaimed dividend, interest/redemption amount lying with the
        Companies for a period of seven years to the Investor Education and
        Protection Fund (IEPF), administered by the Central Government.<br />
        <br />
        In terms of “Investor Education and Protection Fund Authority
        (Accounting, Audit, Transfer and Refund) Rules, 2016”, companies
        have to identify and upload details of unclaimed dividend,
        interest/redemption amount on their website.<br />
        <br />
        Accordingly, information(as on March 31, 2021) is provided for the benefit of our
        share/debenture-holders.<br />
        <br />
        Note: The unclaimed and unpaid dividend and
        debentures interest/redemption amount for FY21 has been transferred
        to IEPF
        <br />
        <br />
        {unpaidreports.map((item, index) => {
          return (
            <TextContent
              to={{ pathname: item.url }}
              target="_blank"
              key={index}
            /* onClick={() => {
              downloadFile(item.url);
            }} */
            >
              {item.title}
            </TextContent>
          );
        })}
      </TextLight>
    </IcsTextContainer>
  )

}