import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FiChevronDown } from "react-icons/fi";
import { MainSection } from "../../../../../pages/OmbudspersonPolicy/mainSection";

import { HR } from "../../../../common/horizontalLine";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { Dropdown } from "../../../../common/dropdown";
import { useMediaQuery } from "react-responsive";

const Main = styled.div`
  width: 100%;
`;

const IcsTextContainer = styled.div`
    width: 80%;
    margin-top: 40px;
    @media (max-width: 900px) {
    width: 100%;
    margin-top: 24px;
  }
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 1040px) {
    font-size: 30px;
    margin-bottom: 25px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
  @media (max-width: 750px) {
    font-size: 14px;
  }
`;

export const InformationToStakeholders = (props) => {

  const [internalcontrol, setinternalcontrol] = useState({});

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempArr = [];
    axios({
        method: "get",
        url: config.serverUrl.base + "/jsonapi/node/internal_control_systems",
    })
        .then((res) => {
            console.log(res);
            for (const report of res.data.data) {
                if (tempReports?.[report.attributes.title]) {
                    tempArr = tempReports[report.attributes.title];
                    tempArr.push({
                        title: report.attributes.title,
                        description: report.attributes.field_description_internalcontro,
                    });
                    tempReports[report.attributes.title] = tempArr;
                } else {
                    tempArr = [];
                    tempArr.push({
                        title: report.attributes.title,
                        description: report.attributes.field_description_internalcontro,
                    });
                    tempReports[report.attributes.title] = tempArr;
                }
            }
            console.log(tempReports);
            setinternalcontrol(tempReports);
        })
        .catch((err) => {
            console.log(err, "err");
        });
}, []);

  return (
    <IcsTextContainer>
      {internalcontrol?.["Information to Stakeholders"] &&
        internalcontrol?.["Information to Stakeholders"].map(
          (item, index) => {
            return <TextLight>{item.description}</TextLight>;
          }
        )}
    </IcsTextContainer>
  )
}