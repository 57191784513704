import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HR } from "../../../../common/horizontalLine";
import { Dropdown } from "../../../../common/dropdown";
import Cdropdown from "../../../../common/Cdropdown";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../../../../../config";
import { downloadFile } from "../../../../../Utils/Utils";
import { Link } from "react-router-dom";

import { SortMonthNames } from '../../../../../Utils/Utils';
import apidata from './BuybackAPIDATA.json';

export const Buyback = (props) => {

  const IcsTextContainer = styled.div`
  width: 90%;
  margin: 40px 0;
`;
  const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    overflow:auto;
    table {
      border-spacing: 0;
      border: 1px solid #707070;
      opacity: 1;
      width: 100%;

      tr {
        :last-child {
          td {
            border-bottom: 1px;
          }
        }
      }

      th,
      td {
        padding: 5px;
        border: 1px solid #707070;
        opacity: 1;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        line-height: 1.25;
        line-width: 1.25;
      }

      th {
        border: 1x solid #707070;
        opacity: 1;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
      }
    }
  }
`;

  const TextLight = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const Main = styled.div`
  width: 100%;
`;
  const TextBold = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: Drlcircular;
  line-height: 1.7;
`;
  const MainContainer = styled.div`
    width: 100%;
    margin-top:40px;
`;
  const DropDownDiv = styled.div`
  width:30%;
  @media (max-width: 980px) {
    width:60%;
  }
`;
  const ICSSection = styled.div`
  width: 100%;
`;

  const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

  const Header = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 35px;
  @media (max-width: 570px) {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;
  const Headertable = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 400;
  line-height: 1;
  margin-top: 35px;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
  const Label = styled.p`
  font-size: 40px !important ;
  color: #000000 !important ;
  font-weight: 300 !important ;
  font-family: Drlcircular !important ;
  margin: 40px 0 !important;
  @media (max-width: 900px) {
    font-size: 24px !important ;
  }
`;

  const TextContent = styled(Link)`
margin: 0 !important;
font-weight: bold !important;
/* font-weight: 400 !important; */
font-size: 18px !important;
font-family: Drlcircular !important;
margin-bottom: 20px !important;
text-decoration: none !important;
color: #000 !important;
cursor: pointer !important;
text-decoration:none !important;
&:hover{
    color: #000;
    text-decoration: underline !important;
  }
@media (max-width: 900px) {
  font-size: 16px !important;
  font-weight: 300 !important;
  margin-bottom: 15px !important;
    text-decoration:underline !important;
}
`;

  const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
   @media (max-width: 1180px) {
    flex-direction: column;
     @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextContainerbuy = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const SubContainer = styled.div`
  width: 100%;
`;
  const DropDownMain = styled.div`
  display:flex;
  flex-direction:row;
  @media (max-width: 900px) {
    flex-direction:column;
  }
`;
  const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
     @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 300;
     display: flex, 
  flex-direction: column
    margin-left: 0px;
    margin-bottom: 15px;
`;
  const TextHeading = styled.h5`
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  font-family: Drlcircular;
  width: 20%;
  text-transform: uppercase;
  @media (max-width: 1180px) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
  const content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;
  const TextClickable = styled.a`
  color: #5225b5;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  margin-left: 50px;
  @media (max-width: 1180px) {
    font-size: 16px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 15px;
  }
`;

  const Clickable = styled.a`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Drlcircular;
  line-height: 1.7;
  text-decoration: underline;
  text-align: left;
`;
  const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  flex-wrap: wrap;
`;

  const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

  const AddressHeading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 50px;
`;
  const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;
  const UL = styled.ul`
  font: normal normal normal 18px/30px DRL Circular;
  padding: 10px;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  line-height: 1.8;
  opacity: 1;
`;

  const [reports, setReports] = useState({});
  const [monthList, setMonthList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [dateList, setDateList] = useState({});
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
        var tempIncludedObj = {};
        var tempReports = {};
        var tempArr = [];
        var tempProperty = "";
        axios({
            method: "get",
            url:
                config.serverUrl.base +
                "/jsonapi/node/buyback?include=field_file_buyback&sort=field_sequence_order_",
        })
            .then((res) => {
                for (const item of res.data.included) {
                    tempIncludedObj[item.id] =
                        config.serverUrl.imageBase + item.attributes.uri.url;
                }
                for (const report of res.data.data) {
                    tempProperty = report.attributes.field_buybacktype;
                    if (tempReports?.[tempProperty]) {
                        tempArr = tempReports[tempProperty];
                        tempArr.push({
                            title: report.attributes.title,
                            url: tempIncludedObj[
                                report.relationships.field_file_buyback.data.id
                            ],
                        });
                        tempReports[tempProperty] = tempArr;
                    } else {
                        tempArr = [];
                        tempArr.push({
                            title: report.attributes.title,
                            url: tempIncludedObj[
                                report.relationships.field_file_buyback.data.id
                            ],
                        });
                        tempReports[tempProperty] = tempArr;
                    }
                }
                setReportsgv(tempReports);
            })
            .catch((err) => {
                console.log(err, "err");
            });
    }, []);

  useEffect(() => {
    var tempIncludedObj = {};
    var tempReports = {};
    var tempDropdownData = [];
    var tempObj = {};
    var tempArr = [];
    var tempArr1 = [];
    var filterDataMap = {};
    axios({
      method: "get",
      url:
        config.serverUrl.base +
        "/jsonapi/node/daily_reporting_to_stock_exchang?include=field_file_dailyreporting",
    })
      .then((res) => {
        const { data, included } = res.data;
        /* const { data, included } = apidata; */
        for (const item of included) {
          tempIncludedObj[item.id] =
            config.serverUrl.imageBase + item.attributes.uri.url;
        }
        for (const report of data) {
          tempArr = [];
          tempArr1 = [];
          if (
            tempDropdownData.indexOf(
              report.attributes.field_month_dailyreporting
            ) === -1
          ) {
            tempDropdownData.push(report.attributes.field_month_dailyreporting);
          }
          if (filterDataMap?.[report.attributes.field_month_dailyreporting]) {
            tempArr1 = filterDataMap[report.attributes.field_month_dailyreporting];
            if (tempArr1.indexOf(report.attributes.field_month_dailyreporting) === -1) {
              tempArr1.push(report.attributes.field_date);
            }
            tempArr1 = tempArr1.sort();
            filterDataMap[report.attributes.field_month_dailyreporting] = [...tempArr1]
          } else {
            filterDataMap[report.attributes.field_month_dailyreporting] = [report.attributes.field_date];
          }
          tempDropdownData = SortMonthNames(tempDropdownData);
          if (tempReports?.[report.attributes.field_month_dailyreporting]) {
            if (tempReports[report.attributes.field_month_dailyreporting]?.[report.attributes.field_date]) {
              tempArr = tempReports[report.attributes.field_month_dailyreporting][report.attributes.field_date];
              tempArr.push({
                title: report.attributes.title,
                url: tempIncludedObj[
                  report.relationships.field_file_dailyreporting.data.id
                ],
              });
              var temp = tempReports[report.attributes.field_month_dailyreporting];
              temp[report.attributes.field_date] = tempArr;
              tempReports[report.attributes.field_month_dailyreporting] = temp;
            } else {
              tempArr = [];
              tempArr.push({
                title: report.attributes.title,
                url: tempIncludedObj[
                  report.relationships.field_file_dailyreporting.data.id
                ],
              });
              var temp = tempReports[report.attributes.field_month_dailyreporting];
              temp[report.attributes.field_date] = tempArr;
              tempReports[report.attributes.field_month_dailyreporting] = temp;
            }
          } else {
            tempArr = [];
            tempArr.push({
              title: report.attributes.title,
              url: tempIncludedObj[
                report.relationships.field_file_dailyreporting.data.id
              ],
            });
            var temp = {};
            temp[report.attributes.field_date] = tempArr;
            tempReports[report.attributes.field_month_dailyreporting] = temp;
          }
        }
        if (tempDropdownData && tempDropdownData.length) {
          setSelectedMonth(tempDropdownData[0]);
          setSelectedDate(filterDataMap[tempDropdownData[0]][0])
        }

        setReports(tempReports);
        setMonthList(tempDropdownData);
        setDateList(filterDataMap);

      }).catch((err) => {
        console.log(err, "  Err   ")
      })
  }, [])

  const minWidthBool = useMediaQuery({ query: "(max-device-width: 900px)" });

  const [reportsgv, setReportsgv] = useState({});

  return (<MainContainer>
    <TextContainerbuy>
      {reportsgv?.["Buyback"] &&
        reportsgv?.["Buyback"].map((item, index) => {
          return (
            <TextContent
            to={{ pathname: item.url }}
              target="_blank"
              key={index}
              /* onClick={() => {
                downloadFile(item.url);
              }} */
            >
              {item.title}
            </TextContent>
          );
        })}
      <SubSubContainer>
        <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <Label
            style={{
              fontSize: "18px",
            }}
          >
            Daily Reporting to Stock Exchanges - 2016
          </Label>
          <DropDownMain
            style={{
              display: "flex",
              flexdirection:minWidthBool?'column': "row",
              width: minWidthBool?'100%':'80%',
              marginBottom:'35px'
            }}
          >
            <DropDownDiv style={{ marginRight:minWidthBool?'0': '20px',marginBottom:minWidthBool?'20px':'0' }} >
              <Cdropdown
                data={monthList}
                placeholder="Month"
                id="Month"
                selectedValue={selectedMonth}
                onChangeValue={(val) => {
                  setSelectedMonth(val)
                  setSelectedDate(dateList[val][0])
                }}
              ></Cdropdown>
            </DropDownDiv>
            <DropDownDiv>
              <Cdropdown
                data={selectedMonth && selectedMonth.length !== 0 ? dateList[selectedMonth] : []}
                placeholder="Date"
                id="Date"
                selectedValue={selectedDate}
                onChangeValue={(val) => setSelectedDate(val)}
              ></Cdropdown>
            </DropDownDiv>
          </DropDownMain>
        </div>
        {reports?.[selectedMonth] && reports[selectedMonth]?.[selectedDate] &&
          reports[selectedMonth][selectedDate].map((item, index) => {
            return (
              <TextContent
              to={{ pathname: item.url }}
               target="_blank"
                key={index}
                /* onClick={() => {
                  downloadFile(item.url);
                }} */
              >
                {item.title}
              </TextContent>
            );
          })}
      </SubSubContainer>
      <Label style={{marginBottom:'35px'}} >Closure Of Buyback</Label>
      {reportsgv?.["Closure of Buyback"] &&
        reportsgv?.["Closure of Buyback"].map((item, index) => {
          return (
            <TextContent
            to={{ pathname: item.url }}
            target="_blank"
              key={index}
              /* onClick={() => {
                downloadFile(item.url);
              }} */
            >
              {item.title}
            </TextContent>
          );
        })}
    </TextContainerbuy>
  </MainContainer>
  )

}