import React from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './index.css';

import Img1 from '../../assets/CHIP.jpg';
import Img2 from '../../assets/img2.png';
import Img3 from '../../assets/img3.jpg';

import {isTab,mediaQueryRangeTab} from '../../Utils/Utils';

const Health = () => {

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        // REVEAL //
        gsap.utils.toArray(".revealUp1").forEach(function (elem) {
            ScrollTrigger.create({
                trigger: elem,
                start: "top 40%",
                end: "bottom 0%",
                // markers: true,
                onEnter: function () {
                    gsap.fromTo(
                        elem,
                        { autoAlpha: 0 },
                        {
                            duration: 0.5,
                            autoAlpha: 1,
                            // ease: "back",
                            overwrite: "auto"
                        }
                    );
                },
                onLeave: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                },
                onEnterBack: function () {
                    gsap.fromTo(
                        elem,
                        { autoAlpha: 0 },
                        {
                            duration: 0.5,
                            autoAlpha: 1,
                            // ease: "back",
                            overwrite: "auto"
                        }
                    );
                },
                onLeaveBack: function () {
                    gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
                }
            });
        });



        // gsap.utils.toArray(".revealUp1").forEach(function (elem) {
        //     ScrollTrigger.create({
        //         trigger: elem,
        //         start: "top 20%",
        //         end: "bottom 30%",
        //         // markers: true,
        //         onEnter: function () {
        //             gsap.fromTo(
        //                 elem,
        //                 { y: 100, autoAlpha: 0 },
        //                 {
        //                     duration: 1.25,
        //                     y: 0,
        //                     autoAlpha: 1,
        //                     // ease: "back",
        //                     overwrite: "auto"
        //                 }
        //             );
        //         },
        //         onLeave: function () {
        //             gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
        //         },
        //         onEnterBack: function () {
        //             gsap.fromTo(
        //                 elem,
        //                 { y: -100, autoAlpha: 0 },
        //                 {
        //                     duration: 1.25,
        //                     y: 0,
        //                     autoAlpha: 1,
        //                     // ease: "back",
        //                     overwrite: "auto"
        //                 }
        //             );
        //         },
        //         onLeaveBack: function () {
        //             gsap.fromTo(elem, { autoAlpha: 1 }, { autoAlpha: 0, overwrite: "auto" });
        //         }
        //     });
        // });
    }, [])

    return (
        <>
            <div style={{ marginTop: '8px', paddingTop: "32px", borderTop: "1px solid #ff5046", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} >
                <div style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '100vh', width: '32%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                    <span className='revealUp1' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', opacity: 0 }} >
                        {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                        <h2  style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)', textAlign: 'center', paddingLeft: '20%', paddingRight: '20%', marginBottom: '15px', lineHeight: '1.1' }} >Community Health Intervention Programme (CHIP)</h2>
                        <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '20%', paddingRight: '20%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >Community Health Intervention Programme or CHIP focuses on providing primary healthcare services in rural communities at the doorstep of people who do not have access to healthcare. Implemented in partnership with NICE Foundation, CHIP is operational in 155 villages of Andhra Pradesh, extending services to a population of around 2 lakhs.</h6>
                    </span>
                </div>
                <div style={{ backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '100vh', width: '32%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                    <span className='revealUp1' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', opacity: 0 }} >
                        {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                        <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)', textAlign: 'center', paddingLeft: '20%', paddingRight: '20%', marginBottom: '15px', textAlign: 'center', lineHeight: '1.1' }} >Roshni Helpline</h2>
                        <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '20%', paddingRight: '20%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >In partnership with the Roshni Trust, our Roshni Helpline extends tele-counselling support for emotional and mental well-being. Need-based in-person counselling is also provided at Roshni’s care centre.</h6>
                    </span>
                </div>
                <div style={{ backgroundImage: `url(${Img3})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '100vh', width: '32%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                    <span className='revealUp1' style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '100vh', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', opacity: 0 }} >
                        {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} /> */}
                        <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)', textAlign: 'center', paddingLeft: '20%', paddingRight: '20%', marginBottom: '15px', textAlign: 'center', lineHeight: '1.1' }} >Life at your doorstep </h2>
                        <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '20%', paddingRight: '20%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >The Home-Based Palliative Care Program provides round the clock palliative care for patients with advanced illnesses at door step. Comprehensive medical, emotional, and spiritual support is provided to the patients with cancer and other chronic illness. This program is implemented through the Pain Relief and Palliative Care Society.</h6>
                    </span>
                </div>
            </div>
        </>
    );
}

export default Health;
// import React from 'react';

// import Img1 from '../../assets/Mask-Group-296.png';
// import Img2 from '../../assets/img2.png';

// const Health = () => {
//     return (
//         <div className='af-class-section'
//         // style={{ display: 'flex', paddingTop: "40px", paddingBottom: '40px', flexDirection: 'column', marginLeft: '12vw', marginRight: '12vw',zIndex:99999 }} 
//         >
//             <h2 className="af-class-h2-section-heading">Health</h2>
//             <div style={{ marginTop: '8px', paddingTop: "32px", borderTop: "1px solid #ff5046", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} >
//                 <div style={{ backgroundImage: `url(${Img1})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '85vh', width: '39.5vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
//                     <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} />
//                     <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)', paddingLeft: '20%', paddingRight: '20%', marginBottom: '15px', textAlign: 'center',lineHeight:'1.1' }} >Community Health Intervention Programme (CHIP)</h2>
//                     <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '20%', paddingRight: '20%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >CHIP was started with the objective of providing primary healthcare services to people living in rural areas with no access to healthcare. Run in partnership with NICE Foundation, CHIP is operational in 155 villages in Andhra Pradesh, India.</h6>
//                 </div>
//                 <div style={{ backgroundImage: `url(${Img2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '85vh', width: '39.5vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
//                     <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: '85vh', width: '39.5vw', position: 'absolute', }} />
//                     <h2 style={{ color: '#fff', fontSize: '40px', fontWeight: 300, filter: 'opacity(1)', paddingLeft: '20%', paddingRight: '20%', marginBottom: '15px', textAlign: 'center',lineHeight:'1.1' }} >Roshni Helpline</h2>
//                     <h6 style={{ color: '#fff', fontSize: '18px', fontWeight: 200, paddingLeft: '20%', paddingRight: '20%', textAlign: 'center', filter: 'opacity(1)', lineHeight: 1.6 }} >In partnership with the Roshni Trust, our Roshni Helpline extends tele-counselling support for emotional and mental well-being. Need-based in-person counselling is also provided at Roshni’s care centre.</h6>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Health;