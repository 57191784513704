import React from 'react';

import Config from '../config';
import { useMediaQuery } from 'react-responsive'

import { isTab, mediaQueryRangeTab } from '../Utils/Utils';

const FooterSection = () => {

  const isMobileScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const isPhoneScreen = useMediaQuery({ query: '(max-width: 500px)' })

  return <footer id="common_footer" className="af-class-section af-class-footer">
    <div className="af-class-container w-container">
      {
        isPhoneScreen && <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'flex-start' }} >
          <a id="w-node-f3bf3626-7fae-7d0a-e267-a3cedb32a0e0-db32a0dd" href="#" className="af-class-footer-logo w-inline-block" style={{ justifyContent: 'space-evenly', height: '40px' }}><img src="/images/Dr._Reddys_Laboratories_Logo.svg" style={{ marginRight: window.matchMedia('(max-width: ' + mediaQueryRangeTab + ')').matches && '24%' }} loading="lazy" alt /></a>
        </div>
      }

      <div style={{ display: 'flex', justifyContent: !isTab && 'space-between', rowGap: isTab && '50px', columnGap: isTab && '4%', flexWrap: 'wrap', flexFlow: isPhoneScreen ? 'column wrap' : 'row wrap' }}>
        <div className="af-class-footer-link-block">
          <a href="/" className="af-class-footer-link-category">HOME
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/#about-us" className="af-class-footer-link">About Us
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/#sustainability" className="af-class-footer-link">Building A Sustainable Future
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/#we-are-drreddys" className="af-class-footer-link">#WeAreDrReddys
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/#we-are-in-the-news" className="af-class-footer-link">Latest News
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div>

        <div className="af-class-footer-link-block">
          <a href="/who-we-are" className="af-class-footer-link-category">WHO&nbsp;WE&nbsp;ARE
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#purpose" className="af-class-footer-link">
            Purpose
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#promises" className="af-class-footer-link">Promises
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#principles" className="af-class-footer-link">Principles
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#values" className="af-class-footer-link">Values
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#leadership" className="af-class-footer-link">Leadership
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#our-founder" className="af-class-footer-link">Our Founder
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/who-we-are#Journey-so-far" className="af-class-footer-link">Our Journey
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div>
        <div className="af-class-footer-link-block" >
          <a href="/business-and-capabilities" className="af-class-footer-link-category">OUR&nbsp;BUSINESSES
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-and-capabilities#our-businesses" className="af-class-footer-link">Our Businesses
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-and-capabilities#our-capabilities" className="af-class-footer-link">Our Capabilities
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-and-capabilities#stories" className="af-class-footer-link">Stories
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-and-capabilities#jobs" className="af-class-footer-link">Jobs
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-and-capabilities#contact-us" className="af-class-footer-link">Contact Us
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div>
        <div className="af-class-footer-link-block" >
          {
            // isMobileScreen
            //   ?
            <a href="/business-responsibility-and-sustainability" className="af-class-footer-link-category">SUSTAINABILITY
              <div className="af-class-menu-link-underline-white" />
            </a>
            // :
            // <a href="/business-responsibility-and-sustainability" className="af-class-footer-link-category">SUSTAINABILITY
            //   <div className="af-class-menu-link-underline-white" />
            //   <div style={{ width: '70%' }} >
            //     &amp;  SUSTAINABILITY
            //     <div className="af-class-menu-link-underline-white" />
            //   </div>
            // </a>
          }
          <a href="/business-responsibility-and-sustainability#milestones" className="af-class-footer-link">Milestones
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-responsibility-and-sustainability#esg-goals" className="af-class-footer-link">Our ESG Goals
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-responsibility-and-sustainability#disclosures" className="af-class-footer-link">Reports and Disclosures
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-responsibility-and-sustainability#community-initiatives" className="af-class-footer-link">Community Initiatives
            <div className="af-class-menu-link-underline-white" />
          </a>
          { /*<a href="/business-responsibility-and-sustainability#community-initiatives" className="af-class-footer-link">Environment Sustainability
            <div className="af-class-menu-link-underline-white" />
        </a> */ }
          <a href="/business-responsibility-and-sustainability#community-initiatives" className="af-class-footer-link">Employee Volunteering
            <div className="af-class-menu-link-underline-white" />
          </a>
          {/* <a href="/business-responsibility-and-sustainability#foundation-for-health-education" className="af-class-footer-link">Dr. Reddy's Foundation For Health Education                <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/business-responsibility-and-sustainability#our-affiliates" className="af-class-footer-link">Our Affiliates
            <div className="af-class-menu-link-underline-white" />
          </a> */}
        </div>
        {/* <div className="af-class-footer-link-block" >
          <a href="/PeopleStories" className="af-class-footer-link-category">PEOPLE &amp; STORIES
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/PeopleStories#patient-stories" className="af-class-footer-link">Patient Stories
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/PeopleStories#patient-programmes" className="af-class-footer-link">Patient Programmes
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/PeopleStories#hcp-stories" className="af-class-footer-link">HCP Stories
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/PeopleStories#partner-stories" className="af-class-footer-link">Partner Stories
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/PeopleStories#be-our-partner" className="af-class-footer-link">Be Our Partner
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div> */}
        <div className="af-class-footer-link-block">
          <a href="/LifeAtDrReddys" className="af-class-footer-link-category">LIFE AT DR. REDDY'S
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/LifeAtDrReddys#our-culture" className="af-class-footer-link">Our Culture
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/LifeAtDrReddys#aspire-framework" className="af-class-footer-link">ASPIRE framework
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/LifeAtDrReddys#our-people" className="af-class-footer-link">Our People
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/LifeAtDrReddys#employee-enrichment-programmes" className="af-class-footer-link">Capability Building Programmes
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/LifeAtDrReddys#work-with-us" className="af-class-footer-link">Work With Us
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div>
        {/* <div className="af-class-footer-link-block">
          <a href="/ScienceResearch" className="af-class-footer-link-category">SCIENCE &amp; research</a>
          <a href="#" className="af-class-footer-link">Development Streams</a>
          <a href="#" className="af-class-footer-link">Research Philosophy</a>
          <a href="#" className="af-class-footer-link">Capabilities</a>
        </div>
        <div className="af-class-footer-link-block">
          <a href="/who-we-are" className="af-class-footer-link-category">ABOUT&nbsp;US</a>
          <a href="#" className="af-class-footer-link">Who We Are</a>
          <a href="#" className="af-class-footer-link">Our Values</a>
          <a href="#" className="af-class-footer-link">Our Heritage</a>
          <a href="#" className="af-class-footer-link">Our Journey and Vision</a>
          <a href="#" className="af-class-footer-link">Our Founder and Leaders</a>
        </div> */}
      </div>
      {!isPhoneScreen && <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'flex-end' }} >
        <a id="w-node-f3bf3626-7fae-7d0a-e267-a3cedb32a0e0-db32a0dd" href="#" className="af-class-footer-logo w-inline-block" style={{ justifyContent: 'space-evenly', paddingLeft: '35px' }}><img src="/images/Dr._Reddys_Laboratories_Logo.svg" loading="lazy" alt /></a>
      </div>}
      <div className="af-class-secondary-footer-links-wrapper">
        <a href="/investor" className="af-class-secondary-footer-links">INVESTORS
          <div className="af-class-menu-link-underline-white" />
        </a>
        <a href="/drreddys-media" className="af-class-secondary-footer-links">MEDIA
          <div className="af-class-menu-link-underline-white" />
        </a>
        <a href="/business-partner" className="af-class-secondary-footer-links">BUSINESS PARTNERS
          <div className="af-class-menu-link-underline-white" />
        </a>
        <a href="/job-seeker" className="af-class-secondary-footer-links">JOB SEEKERS
          <div className="af-class-menu-link-underline-white" />
        </a>
        <a href="/contact-us" className="af-class-secondary-footer-links">CONTACT US
          <div className="af-class-menu-link-underline-white" />
        </a>
      </div>
      <div className="af-class-policies-and-social-wrapper">
        <div className="af-class-polices-links-wrapper">
          <a href="/terms-of-use" className="af-class-policies-links">Terms Of Use
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/privacy-policy" className="af-class-policies-links">Privacy
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/accessibility" className="af-class-policies-links">Accessibility
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/cookie-policy" className="af-class-policies-links">Cookie Policy
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a className="cmp-revoke-consent af-class-policies-links">Cookie Preferences Centre
            <div className="af-class-menu-link-underline-white" />
          </a>
          <a href="/caution-notice-recruitment-fraud" className="af-class-policies-links">Caution Notice - "Recruitment Fraud"
            <div className="af-class-menu-link-underline-white" />
          </a>
        </div>
        <div className="af-class-social-icons-wrapper">
          <a target="_blank" href={Config.externalLinks.fbLink} className="af-class-social-icon w-inline-block"><img src="/images/facebook-logo-png-white-i6-300x300.svg" loading="lazy" alt /></a>
          <a target="_blank" href={Config.externalLinks.linkedInLink} className="af-class-social-icon w-inline-block"><img src="/images/1300173_linkedin-logo-white-png.svg" loading="lazy" alt /></a>
          <a target="_blank" href={Config.externalLinks.twitterLink} className="af-class-social-icon w-inline-block"><img src="/images/46-461780_twitter-twitter-logo-round-vector.svg" loading="lazy" alt /></a>
          <a target="_blank" href={Config.externalLinks.instaLink} className="af-class-social-icon w-inline-block"><img src="/images/instagram.svg" loading="lazy" alt /></a>
          <a target="_blank" href={Config.externalLinks.youtubeLink} className="af-class-social-icon w-inline-block"><img src="/images/46-462383_youtube-youtube-play-icon-white.svg" loading="lazy" alt /></a>
        </div>
      </div>
      <div className="af-class-footer-note">© {new Date().getFullYear()} Dr. Reddy’s Laboratories Ltd. All rights reserved.</div>
    </div>
  </footer>
}

export default FooterSection;
