import React from "react";
import styled from "styled-components";
// import "./table.css";
import { Dropdown } from "../../../../common/dropdown";
import { HR } from "../../../../common/horizontalLine";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../config";

import { BsArrowRight } from "react-icons/bs";
import { downloadFile } from "../../../../../Utils/Utils";
import { ViewFile } from "../../../../../Utils/view";
import { ShareholderInformationform } from "../../../../common/ShareholderInformationform";

const Label = styled.p`
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  font-family: Drlcircular;
  margin: 0;
`;

const Main = styled.div`
width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top:40px;
`;

const Heading = styled.h2`
  margin: 0;
  color: #000000;
  font-size: 40px;
  font-family: Drlcircular;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 35px;
`;

const SubContainer = styled.div`
  width: 100%;
`;

const DropDownDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;
const DropDown = styled.a`
  background-color: #ffffff 0% 0% no-repeat;
  margin-bottom: 40px;
  padding-left: 25px;
  // justify-content: space-between;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-family: Drlcircular;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const AddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddressHeading = styled.h2`
  margin: 0 !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-family: Drlcircular !important;
  font-weight: bold !important;
  line-height: 1 !important;
  margin-bottom: 20px !important;
`;

const UL = styled.ul`
  margin: 0;
  padding-left: 20px;
  li {
    margin: 0 !important;
    color: #000000 !important;
    font-size: 18px !important;
    font-family: Drlcircular !important;
    font-weight: 400 !important;
    margin-bottom: 50px !important;
    line-height: 1.8 !important;
    letter-spacing:0 !important;
    @media (max-width: 900px) {
      font-size: 14px !important ;
      line-height: 24px !important ;
      margin-bottom: 10px !important;
  }
  }
`;
const FileLinks = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-family: Drlcircular;
  font-weight: 5;
  align-items: center;
`;

const SubSubContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
`;

const IHSubContainer = styled.div`
  width: 100%;
  padding-top: 70px;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border-spacing: 0;
    border: 0.1px solid #707070;
    opacity: 1;
    width: 100%;
    overflow-x: 40px;
    @media (max-width: 820px) {
      border: 1px solid #707070;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0.1px;
          @media (max-width: 820px) {
            border-bottom: 1px;
          }
        }
      }
    }

    th,
    td {
      padding: 5px;
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: 400;
      font-size: 15px;
      text-align: left;
      line-height: 1.25;
      line-width: 1.25;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }

    th {
      border: 0.1px solid #707070;
      opacity: 1;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 5px;
      @media (max-width: 820px) {
        border: 1px solid #707070;
      }
    }
  }
`;

export const ShareholderContacts = (props) => {

  return (<MainContainer>
    <AddressContainer>
      <AddressDiv>
        <AddressHeading>
          For Institutional Investors & Financial Analysts:
        </AddressHeading>
        <UL>
          <li>
            Richa Periwal <br />
            Ph: +040 - 4900 2135 <br />
            Email: richaperiwal@drreddys.com
          </li>
          <li>
            Aishwarya Sitharam <br />
            Ph: +040 - 4900 2180 <br />
            Email: aishwaryasitharam@drreddys.com
          </li>
          { /* <li>
            Saunak Savla (US) <br />
            Ph: +1 609 375 9905 <br />
            Email: saunaks@drreddys.com
          </li>
          <li>
            Ashish Girotra (US) <br />
            Ph: +1 609 375 9805 <br />
            Email: ashishg@drreddys.com
  </li> */ }
        </UL>
      </AddressDiv>
      <AddressDiv>
        <AddressHeading>
          For Retail Investors & Shareholders:
        </AddressHeading>
        <UL>
          <li>
            Secretarial Team <br />
            8-2-337, Road No 3, <br />
            Banjara Hills, <br />
            Hyderabad - 500034. <br />
            Telangana. <br />
            Ph: +91 040 4900 2222 <br />
            Fax: +91-040-4900 2999 <br />
            Email: shares@drreddys.com
          </li>
        </UL>
      </AddressDiv>
    </AddressContainer>
  </MainContainer>
  )
}
